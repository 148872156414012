import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  MessageIcon,
  EditIcon,
  CloseIcon
} from 'src/icons';
import {
  Button,
  LinkButton,
} from 'src/widgets/Button';
import { Input } from 'src/widgets/Input';
import { CreatorDetail } from 'src/common/CreatorDetail';

import { ISocialAccount } from 'src/common/models/socialAccount';

import styles from './ProposalListItem.scss';

interface IProps {
  socialAccount: ISocialAccount;
  onCommentChange(text: string);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  comment: string;
  newComment: string;
  editMode: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ProposalListItem extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      comment: '',
      newComment: '',
      editMode: true,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { socialAccount, classNames } = this.props;

    return (
      <div className={cx(classNames.concat(styles.ProposalListItem))}>
        <CreatorDetail
          socialAccount={socialAccount}
          className={styles.creatorDetail}
          header={
            <React.Fragment>
              <div className={styles.message}>(Optional) Leave notes for the brand:</div>
              {this.renderCommentSection()}
            </React.Fragment>
          }
          selfServeExperiment={false}
        />
      </div>
    );
  }

  private renderCommentSection = () => {
    const { comment, newComment, editMode } = this.state;

    return (
      <div className={styles.commentSection}>
        {(editMode || isEmpty(comment)) && (
          <div className={styles.leaveFeedbackSection}>
            <Input
              className={styles.commentInput}
              theme="info"
              value={newComment}
              placeholder="Write your notes..."
              onChange={this.onCommentChange}
              onPressEnter={this.leaveComment}
            />
            <Button
              theme="info"
              label="Leave Notes"
              round={true}
              className={styles.button}
              onClick={this.leaveComment}
              disabled={comment === newComment}
            />
            <LinkButton
              className={styles.button}
              onClick={this.onCancel}
              disabled={isEmpty(newComment)}
            >
              Cancel
            </LinkButton>
          </div>
        )}
        {!isEmpty(comment) && !editMode && (
          <div className={styles.body}>
            <MessageIcon className={styles.messageIcon} />
            <div className={styles.comment}>
              <div className={styles.commentTitle}>Your notes:</div>
              {comment}
            </div>
            <div className={styles.actions}>
              <EditIcon size={18} className={styles.icon} onClick={this.setEditMode} />
              <CloseIcon size={22} className={styles.icon} onClick={this.deleteComment} />
            </div>
          </div>
        )}
      </div>
    );
  };

  private onCommentChange = (newComment: string) => {
    this.setState({
      newComment,
    });
  };

  private deleteComment = () => {
    const { onCommentChange } = this.props;

    this.setState({
      newComment: '',
      comment: '',
      editMode: true,
    });

    onCommentChange('');
  };

  private onCancel = () => {
    const { comment } = this.state;

    this.setState({
      newComment: comment,
      editMode: false,
    });
  };

  private leaveComment = () => {
    const { onCommentChange } = this.props;
    const { newComment, comment } = this.state;

    if (newComment === comment) {
      return;
    }

    this.setState({
      editMode: false,
      comment: newComment,
    });

    onCommentChange(newComment);
  };

  private setEditMode = () => {
    this.setState({
      editMode: true,
    });
  };
}

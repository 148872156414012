import * as React from 'react';

const { createContext, useContext } = React;

export interface IAnalyzeContext {
  apiEndpoint: string;
  filters: TFilter;
  organizationId?: string;
  setFilters(filters: TFilter): void;
  currentPage?: TAnalyzePage;
  isAspirex: boolean;
  isQa: boolean;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
}

export const AnalyzeContext = createContext<IAnalyzeContext>(null);

export const useAnalyze = () => useContext(AnalyzeContext);

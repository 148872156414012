import * as React from 'react';
import cx from 'classnames';
import { isFunction, isString } from 'lodash';

import {
  InviteIcon,
  InvitedIcon,
  MessageIcon,
  RejectUserIcon,
} from 'src/icons';
import {
  Button,
  SubmitButton,
} from 'src/widgets/Button';
import { IconSize } from 'src/widgets/Icon';
import campaignIneligibleReason from 'src/common/config/campaignIneligibleReason';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { TButtonTheme } from 'src/widgets/Button/Button';

import styles from './CreatorActionButtonGroup.scss';

export interface ICreatorActionButtonGroupProps {
  className?: string;
  /**
   * Is sending an invite
   * This sets the status/styling to the invite button
   */
  isInviting?: boolean;
  /**
   * Social account model
   */
  socialAccount: ISocialAccount;

  /**
   * Show 'Hide' button
   * default: true
   */
  showHideButton?: boolean;
  /**
   * 'Hide' button props
   */
  hideButtonType?: TButtonTheme;
  /**
   * Callback function on the Hide button
   */
  hideCallback?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  /**
   * Show 'Invite' button
   * default: true
   */
  showInviteButton?: boolean;
  /**
   * 'Invite' button props
   */
  inviteButtonType?: TButtonTheme;
  /**
   * Callback function on the Invite button
   */
  inviteCallback: () => void;

  /**
   * Show 'Offer' button
   * default: true
   */
  showOfferButton?: boolean;
  /**
   * 'Offer' button props
   */
  offerButtonType?: TButtonTheme;
  /**
   * Callback function on the Manage button
   */
  manageCallback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /**
   * Callback function on the Manage button
   */
  offerCallback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const CreatorActionButtonGroup: React.FunctionComponent<ICreatorActionButtonGroupProps> = React.memo((props) => {
  const {
    className,
    hideButtonType,
    hideCallback,
    inviteButtonType,
    inviteCallback,
    isInviting,
    manageCallback,
    offerButtonType,
    offerCallback,
    showHideButton,
    showInviteButton,
    showOfferButton,
    socialAccount,
  } = props;
  const ineligibleReason = socialAccount
    ? campaignIneligibleReason[socialAccount.ineligible_reason]
    : socialAccount.ineligible_reason;
  const isIneligible = isString(ineligibleReason);
  const isInviteApproved = socialAccount.invite && socialAccount.invite.approved;
  const isInviteDisabled = isIneligible || isInviteApproved;
  const isOfferDisabled = isIneligible;

  if (showHideButton && !isFunction(hideCallback)) {
    throw Error('No callback provided for Hide button');
  } else if (showInviteButton && !isFunction(inviteCallback)) {
    throw Error('No callback provided for Invite button');
  } else if (showOfferButton && (!isFunction(offerCallback) && !isFunction(manageCallback))) {
    throw Error('No callback provided for Offer/Manage button');
  }

  return (
    <div className={cx(className, styles.CreatorActionButtonGroup)}>
      {/* Hide */}
      {showHideButton && (
        <Button
          className={cx(styles.button, styles.hideButton)}
          disabled={isInviteDisabled}
          icon={<RejectUserIcon size={IconSize.SMALL} />}
          label={'Hide'}
          onClick={(event) => {
            offerCallback(event);
          }}
          theme={hideButtonType}
        />
      )}

      {/* Invite */}
      {showInviteButton && (
        <SubmitButton
          className={cx(styles.button, styles.inviteButton, {
            [styles.isInviting]: isInviting,
          })}
          label={isInviteApproved ? 'Invited' : 'Invite'}
          submittingLabel="Inviting..."
          icon={
            isInviteDisabled ? (
              <InvitedIcon className={styles.icon} />
            ) : (
              <InviteIcon className={styles.icon} />
            )
          }
          isSubmitting={isInviting}
          theme={inviteButtonType}
          onClick={inviteCallback}
          disabled={isInviteDisabled || isInviting}
          title={ineligibleReason}
        />
      )}

      {/* Offer / Manage */}
      {showOfferButton && (
        <Button
          className={cx(styles.button, styles.offerManageButton)}
          label={!socialAccount.has_relationship ? 'Offer' : 'Manage'}
          icon={<MessageIcon className={styles.icon} />}
          theme={offerButtonType}
          onClick={(event) => {
            if (!socialAccount.has_relationship) {
              if (isFunction(offerCallback)) {
                offerCallback(event);
              } else {
                throw Error('No callback provided for Offer button');
              }
            } else {
              if (isFunction(manageCallback)) {
                manageCallback(event);
              } else {
                throw Error('No callback provided for Manage button');
              }
            }
          }}
          disabled={isOfferDisabled || isInviting}
          title={ineligibleReason}
        />
      )}
    </div>
  );
});

CreatorActionButtonGroup.defaultProps = {
  hideButtonType: 'info',
  inviteButtonType: 'info',
  offerButtonType: 'info',
  showHideButton: false, // TODO: Enable when 'Hide' button is implemented
  showInviteButton: true,
  showOfferButton: true,
};

import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty, toString } from 'lodash';

import {
  ShareIcon,
  SendOfferIcon,
  LikedCircleBlueIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import {
  Table,
  ITableColumnConfig,
  ITableRefHandles,
} from 'src/widgets/Table';

import CampaignInviteButton from './CampaignInviteButton';
import AddToListButton from './AddToListButton';
import MultiDeleteButton from './MultiDeleteButton';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteListPage } from './redux/favoriteListPageModel';

import countries from 'src/common/config/countries';
import styles from './TableView.scss';

interface IOwnProps {
  onCreatorSelected(socialAccount: ISocialAccount);

  sendBulkOffer(socialAccounts: ISocialAccount[]);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  exportCsv(socialAccounts: ISocialAccount[]);

  classNames?: string[];
}
interface IStateProps {
  socialAccounts: ISocialAccount[];
}
type IProps = IOwnProps & IStateProps;
type TDefaultProp = 'classNames';
interface IState {
  // selected social accounts
  selectedSocialAccounts: ISocialAccount[];
}

/**
 * @class
 * @extends {React.PureComponent}
 */
class TableView extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private tableRef: React.RefObject<ITableRefHandles>;
  private columnConfig: ITableColumnConfig[];

  /**
   * @inheritdoc
   */
  public constructor(props: IProps) {
    super(props);

    this.tableRef = React.createRef();

    this.columnConfig = [
      {
        cellType: 'link',
        headerName: 'Name',
        field: 'username',
        handlerField: 'usernameHandler',
      },
      {
        cellType: 'media',
        headerName: 'Content Preview',
        field: 'contents',
      },
      {
        cellType: 'numeric',
        headerName: 'Reach',
        field: 'reach',
        formatStr: '0,0',
      },
      {
        cellType: 'numeric',
        headerName: 'Engagement',
        field: 'engagement',
        formatStr: '0,0',
      },
      {
        cellType: 'network',
        headerName: 'Network',
        field: 'networks',
      },
      {
        cellType: 'numeric',
        headerName: 'Engagement %',
        field: 'engagement_ratio',
        formatStr: '0.0%',
      },
      {
        headerName: 'Country',
        field: 'country',
      },
      {
        headerName: 'Invited',
        field: 'invited',
      },
      {
        cellType: 'link',
        headerName: 'Relationship',
        field: 'relationId',
        handlerField: 'relationHandler',
      },
      {
        headerName: 'Added by',
        field: 'addedBy',
      },
    ];

    this.state = {
      selectedSocialAccounts: [],
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { onCreatorSelected, socialAccounts, classNames, goToManage } = this.props;

    // construct data for table
    const data = socialAccounts.map((socialAccount) => {
      return {
        id: toString(socialAccount.id),
        username: socialAccount.username || socialAccount.name,
        usernameHandler: onCreatorSelected.bind(this, socialAccount),
        contents: socialAccount.images,
        reach: socialAccount.reach,
        engagement: socialAccount.engagement,
        engagement_ratio: socialAccount.engagement_ratio,
        networks: [
          {
            type: socialAccount.network_identifier,
          },
        ],
        country: countries[socialAccount.country_code],
        invited:
          socialAccount.invite && socialAccount.invite.approved ? <LikedCircleBlueIcon /> : '',
        relationId: socialAccount.has_relationship && socialAccount.relation_id,
        relationHandler: goToManage.bind(this, socialAccount.relation_id),
        addedBy: socialAccount.favorite_element && socialAccount.favorite_element.by_user_name,
        _raw: socialAccount,
      };
    });

    console.log(data);

    return (
      <div className={cx(classNames.concat(styles.TableView))}>
        {!isEmpty(socialAccounts) && (
          <Table
            ref={this.tableRef}
            columns={this.columnConfig}
            config={{
              rowHeight: 56,
            }}
            paddingBottom={0}
            headerActions={!isEmpty(socialAccounts) && this.renderActions()}
            data={data}
            onSelectedDataChange={this.onSelectedRowsChange}
            classNames={styles.table}
          />
        )}
      </div>
    );
  }

  /**
   * @private
   * Renders the action buttons.
   *
   * @return {JSX}
   */
  private renderActions = () => {
    const { socialAccounts } = this.props;
    const { selectedSocialAccounts } = this.state;
    // TODO: call onchange from data table instead
    const accountIds = selectedSocialAccounts.map((socialAccount) => socialAccount.id);
    const updatedSelectedSocialAccounts = socialAccounts.filter(
      (socialAccount) => accountIds.includes(socialAccount.id),
    );

    const elementIds = updatedSelectedSocialAccounts.map(
      (socialAccount) => socialAccount.favorite_element && socialAccount.favorite_element.id,
    );
    const uninvitedAccounts = updatedSelectedSocialAccounts.filter(
      (socialAccount) => !(socialAccount.invite && socialAccount.invite.approved),
    );

    return (
      <>
        <Button
          label={`Send ${accountIds.length > 1000 ? '999+' : accountIds.length} Offers`}
          icon={<SendOfferIcon size={20} />}
          disabled={isEmpty(accountIds)}
          className={cx(styles.button, styles.sendBulkOffer)}
          onClick={this.sendBulkOffer}
        />
        <CampaignInviteButton classNames={[styles.button]} socialAccounts={uninvitedAccounts} />
        <MultiDeleteButton
          classNames={[styles.button]}
          onDeleteElements={this.unsetSelectedRows}
          elementIds={elementIds}
        />
        <Button
          label="Export"
          icon={<ShareIcon size={18} />}
          className={styles.button}
          theme="info"
          disabled={isEmpty(selectedSocialAccounts)}
          onClick={this.exportCsv}
        />
        <AddToListButton accountIds={accountIds} classNames={[styles.button]} />
      </>
    );
  };

  /**
   * @private
   * Set selected social accounts when selected rows changed.
   *
   * @param {ISocialAccount[]} selectedSocialAccounts the selected social accounts.
   */
  private onSelectedRowsChange = (selectedSocialAccounts: ISocialAccount[]) => {
    this.setState({
      selectedSocialAccounts,
    });
  };

  /**
   * @private
   * Unset selected rows after deleting elements.
   */
  private unsetSelectedRows = () => {
    const dataTable = this.tableRef.current;

    if (dataTable) {
      dataTable.unsetSelectedRows();
    }
  };

  /**
   * @private
   * Send bulk offer to selected accounts.
   */
  private sendBulkOffer = () => {
    const { sendBulkOffer } = this.props;
    const { selectedSocialAccounts } = this.state;

    sendBulkOffer(selectedSocialAccounts);
  };

  /**
   * @private
   * Exports selected social accounts as csv.
   */
  private exportCsv = () => {
    const { exportCsv } = this.props;
    const { selectedSocialAccounts } = this.state;

    exportCsv(selectedSocialAccounts);
  };
}

const mapStateToProps = (
  state: IFavoriteListPage,
  ownProps: IOwnProps,
): IStateProps & IOwnProps => {
  return {
    socialAccounts: state.socialAccounts,

    onCreatorSelected: ownProps.onCreatorSelected,
    goToManage: ownProps.goToManage,
    sendBulkOffer: ownProps.sendBulkOffer,
    exportCsv: ownProps.exportCsv,
    classNames: ownProps.classNames,
  };
};

export default connect<IStateProps, unknown, IOwnProps>(
  mapStateToProps,
  {},
)(TableView);

import * as React from 'react';
import cx from 'classnames';
import { map, pickBy, } from 'lodash';

import { Checkbox } from 'src/widgets/Checkbox';
import { Popover } from 'src/widgets/Popover';

import styles from './SelectDropdown.scss';

const { useRef, useState, useEffect } = React;

interface IProps {
  options: ISelectableObject[];
  setValue(identifier: string, values: string[]): void;
  title: string;
  identifier: string;
  singleSelect?: boolean;
  className?: string;
  isDisabled?: boolean;
}

export interface ISelectableObject {
  id: string;
  displayName: string;
  selected?: boolean;
}


export const SelectDropdown: React.FunctionComponent<IProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  const { options, title, identifier, setValue, singleSelect, isDisabled } = props;

  const toggleValue = value => {
    if (singleSelect) {
      setSelected({
        [value]: true
      });
    } else {
      setSelected(Object.assign(selected, {
        [value]: !selected[value]
      }));
    }
    setSelectedCount(Object.keys(pickBy(selected)).length);
    setValue(identifier, Object.keys(pickBy(selected)));
  };

  useEffect(() => {
    const initialState = {};
    for (const option of options) {
      if (option.selected) {
        initialState[option.id] = true;
      }
    }
    setSelected(initialState);
    setSelectedCount(Object.keys(pickBy(initialState)).length);
  }, [options]);

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, props.className, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>{selectedCount}</span>
        <span className={styles.filterText}>{title}</span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <ul className={styles.MenuList}>
          {map(options, option => {
            return (
              <li
                key={option.id}
                className={styles.MenuItem}
                onClick={() => toggleValue(option.id)}
              >
                <Checkbox
                  onClick={() => toggleValue(option.id)}
                  checked={selected[option.id]}
                />
                <span>{option.displayName}</span>
              </li>
            );
          })}
        </ul>
      </Popover>
    </>
  );
};

import * as React from 'react';
import cx from 'classnames';

import { NetworkIcon } from 'src/common/NetworkIcon';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import {
  isWaitingForApproval,
  isRejected,
  isSoftRejected,
  isWaitingForSubmission,
  isCompleted,
  isApproved,
  isSoftApproved,
  isGCRPending,
} from 'src/common/utils/contentReviewUtils';
import { IContentRequirement } from 'src/common/models/contentRequirement';
import { IContentReview } from 'src/common/models/contentReview';
import endpoints from 'src/common/config/endpoints';
import {
  HelpIcon,
  RefreshIcon,
} from 'src/icons';
import { Badge } from 'src/widgets/Badge';
import { Button } from 'src/widgets/Button';
import { TooltipIcon } from 'src/widgets/Icon';
import { LazyImage } from 'src/widgets/Image';
import { Video } from 'src/widgets/Video';
import { useMobileStatus } from 'src/utils/hooks';

import { getButtonLabel, getActionText } from './utils/contentReviewUtils';

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

import styles from './ContentReviewItem.scss';

const { useState } = React;

interface IProps {
  apiEndpoint: string;
  projectId: string;
  requirement: IContentRequirement;
  contentReview: IContentReview;
  brandName: string;
  className?: string;
  isManager: boolean;

  // actions
  onSelectContentReview(contentReviewId: number);
  goToGroupContentReview();
}

/**
 * @type {React.FunctionComponent}
 */
export const ContentReviewItem: React.FunctionComponent<IProps> = (props) => {
  const {
    apiEndpoint,
    projectId,
    className,
    requirement,
    brandName,
    onSelectContentReview,
    goToGroupContentReview,
    isManager,
  } = props;

  const [contentReview, setContentReview] = useState(props.contentReview);

  const mobileType = useMobileStatus();
  const action = getActionText(requirement, contentReview, brandName, isManager);
  const isVideo = requirement.description.toLowerCase().includes('video');
  const isTiktok = requirement.description.toLowerCase().includes('tiktok');
  const isSubmitted = isWaitingForApproval(contentReview);
  const isContentReviewRejected = isRejected(contentReview);
  const isBrandRejected = isSoftRejected(contentReview);
  const isContentReviewWaitingForSubmission = isWaitingForSubmission(contentReview);
  const isContentReviewCompleted = isCompleted(contentReview);
  const isContentReviewApproved = isApproved(contentReview);
  const initialManagerAccepted = isSoftApproved(contentReview);
  const isWaitingForGroupReview = isGCRPending(contentReview);
  const isCompletedStyle =
    isContentReviewCompleted ||
    (isSubmitted && !isManager) ||
    isBrandRejected ||
    isWaitingForGroupReview ||
    (!isManager && initialManagerAccepted) ||
    (isManager && (isContentReviewApproved || isContentReviewRejected));
  const isCreatorButtonShown =
    !isManager &&
    (isContentReviewRejected || isContentReviewApproved ||
      isContentReviewWaitingForSubmission || isContentReviewCompleted);
  const isReviewButtonShown = isManager && (isSubmitted || isBrandRejected);
  const isAddToGroupReviewButtonGroupShown = isManager && initialManagerAccepted;
  let thumbnailMediaUrl = defaultImageSrc;
  if (requirement.thumbnail_url) {
    thumbnailMediaUrl = requirement.thumbnail_url;
  }
  let poster;
  if (thumbnailMediaUrl === defaultImageSrc) {
    poster = defaultImageSrc;
  }

  const clickContentReview = () => {
    onSelectContentReview(contentReview.id);
  };

  const clickReviewButton = () => {
    if (isSubmitted) {
      return clickContentReview();
    } else if (isBrandRejected) {
      return goToGroupContentReview();
    } else {
      console.error('Unknown state for review button');
    }
  };

  const clickAddToGroupReview = async () => {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.projectEndpoint}/${projectId}/${endpoints.contentReviewEndpoint}/${contentReview.id}/share_for_gcr`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          iteration: contentReview.iteration,
          project_id: projectId,
          content_review_id: contentReview.id,
        }),
        credentials: 'include',
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      const updatedContentReview = json.data;
      setContentReview(updatedContentReview);
    } else {
      throw new Error();
    }
  };

  return (
    <div
      className={cx(styles.ContentReviewItem, className, {
        [styles.completed]: isCompletedStyle,
        [styles.rejected]: isContentReviewRejected && !isManager,
      })}
    >
      {isContentReviewRejected && mobileType && !isManager && (
        <div className={styles.mobileRejectContainer}>
          <Badge theme="danger" className={styles.mobileRejectBadge}>
            Changes Requested
          </Badge>
        </div>
      )}
      <div className={styles.contentReviewInfoContainer}>
        {isContentReviewRejected && !isManager && !mobileType && (
          <div className={styles.rejectedContainer}>
            <Badge theme="danger" className={styles.badge}>
              Changes Requested
            </Badge>
          </div>
        )}
        {!(isVideo && !isTiktok) ? (
          <LazyImage src={thumbnailMediaUrl} className={styles.mediaThumbnail} />
        ) : (
          <Video
            src={thumbnailMediaUrl}
            classNames={[styles.mediaThumbnail]}
            showDuration={true}
            poster={poster}
          />
        )}
        <div className={styles.contentInfoContainer}>
          <div className={styles.header}>{action}</div>
          <div className={styles.accountInfoContainer}>
            <div className={styles.networkIcon}>
              <NetworkIcon identifier={getNetworkByPostType(requirement.post_type)} size={16} />
            </div>
            <div className={styles.creatorName}>
              {requirement.account_name || contentReview.publisher_username}
            </div>
          </div>
          <div className={styles.actionInfoContainer}>
            {isCreatorButtonShown && (
              <Button
                className={styles.action}
                label={getButtonLabel(contentReview)}
                onClick={clickContentReview}
              />
            )}
            {isReviewButtonShown && (
              <Button className={styles.action} label={'Review'} onClick={clickReviewButton} />
            )}
            {isAddToGroupReviewButtonGroupShown && (
              <div className={styles.addToGroupReviewContainer}>
                <Button
                  className={styles.addToGroupReviewAction}
                  label={'Add to Group Review'}
                  onClick={clickAddToGroupReview}
                />
                <TooltipIcon
                  icon={<HelpIcon size={20} />}
                  tooltipText={`Help for Group Review`}
                  className={styles.helpIcon}
                />
                <div className={styles.verticalLine} />
                <Button
                  className={styles.reviewContentAction}
                  theme={'info'}
                  label={'View Content'}
                  onClick={clickContentReview}
                />
              </div>
            )}
            {isManager && isContentReviewRejected && (
              <div className={styles.remakingContainer}>
                <Button
                  className={styles.remakingStatusAction}
                  icon={<RefreshIcon />}
                  label={'Re-making'}
                  onClick={clickContentReview}
                />
                <Button
                  className={styles.transparentReviewContentAction}
                  theme={'info'}
                  label={'View Content'}
                  onClick={clickContentReview}
                />
              </div>
            )}
            {(
              isManager &&
              (isWaitingForGroupReview || isBrandRejected || isContentReviewCompleted)
            ) && (
              <Button
                className={styles.transparentReviewContentAction}
                theme={'info'}
                label={'View Content'}
                onClick={clickContentReview}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

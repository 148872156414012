import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map, isEmpty } from 'lodash';

import { KeyboardArrowLeftIcon } from 'src/icons';
import { LoadSpinner } from 'src/widgets/LoadSpinner';

import { IRawMetricsData, subChannelName } from './models/channel';

import styles from './ChannelBreakdown.scss';

interface IProps {
  channels: IRawMetricsData[];
  channelInfo: any[];
  dataFieldInfo: any[];

  isLoading?: boolean;
  classNames?: string[];
}
type TDefaultProp = 'isLoading' | 'classNames';
interface IState {
  startIndex: number;
  fieldDisplayName: {
    [identifier: string]: string;
  };
  channelFields: {
    [identifier: string]: string[];
  };
}

/**
 * @class
 * @extends {React.Component}
 */
export class ChannelBreakdown extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
    isLoading: false,
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      startIndex: 0,
      fieldDisplayName: null,
      channelFields: null,
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps) {
    const { channelInfo, dataFieldInfo } = nextProps;

    const fieldDisplayName = {};
    const channelFields = {};

    if (!isEmpty(dataFieldInfo)) {
      dataFieldInfo.forEach((info) => {
        fieldDisplayName[info.identifier] = info.name;
      });
    } else {
      console.error('Missing data field info.');
    }

    if (!isEmpty(channelInfo)) {
      channelInfo.forEach((channel) => {
        channelFields[channel.identifier] = (channel.key_columns_identifiers || '').split('|');
      });
    } else {
      console.error('Missing sub channel info.');
    }

    return {
      fieldDisplayName,
      channelFields,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames, channels, isLoading } = this.props;
    const { startIndex } = this.state;

    return (
      <div className={cx(classNames.concat(styles.ChannelBreakdown))}>
        <div className={styles.header}>
          <div className={styles.divider} />
          <div className={styles.text}>Breakdown by Channel</div>
          <div className={styles.divider} />
        </div>
        {isLoading && <LoadSpinner />}
        {!isLoading && !isEmpty(channels) && (
          <div className={styles.content}>
            {channels.length > 3 && (
              <div className={styles.action} onClick={this.onPrevClick}>
                <KeyboardArrowLeftIcon
                  className={cx(styles.arrow, {
                    [styles.disabled]: startIndex === 0,
                  })}
                />
              </div>
            )}
            <div className={styles.listWrapper}>{this.renderList()}</div>
            {channels.length > 3 && (
              <div className={styles.action} onClick={this.onNextClick}>
                <KeyboardArrowLeftIcon
                  className={cx(styles.arrow, styles.arrowRight, {
                    [styles.disabled]: startIndex + 2 >= channels.length - 1,
                  })}
                />
              </div>
            )}
          </div>
        )}
        {!isLoading && isEmpty(channels) && (
          <div className={styles.message}>No channels available.</div>
        )}
      </div>
    );
  }

  private renderList = () => {
    const { channels } = this.props;
    const { startIndex } = this.state;

    return (
      <div
        className={styles.list}
        style={{
          transform: `translateX(-${startIndex * 370}px)`,
        }}
      >
        {map(channels, (channel, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.header}>
              <div
                className={cx(styles.icon, {
                  [styles.instagram]: channel.subchannel === 'social_instagram',
                  [styles.facebook]: channel.subchannel === 'fb_ads',
                  [styles.youtube]: channel.subchannel === 'social_youtube',
                })}
              />
              <div className={styles.title}>{subChannelName[channel.subchannel]}</div>
            </div>
            {this.renderDetail(channel)}
          </div>
        ))}
      </div>
    );
  };

  private onPrevClick = () => {
    const { startIndex } = this.state;

    this.setState({
      startIndex: Math.max(startIndex - 1, 0),
    });
  };

  private onNextClick = () => {
    const { channels } = this.props;
    const { startIndex } = this.state;

    this.setState({
      startIndex: Math.min(startIndex + 1, channels.length - 3),
    });
  };

  private renderDetail = (channel: IRawMetricsData) => {
    const { fieldDisplayName, channelFields } = this.state;
    const { subchannel } = channel;

    return (
      <div className={styles.detail}>
        {map(channelFields[subchannel], (field) => {
          if (!channel[field]) {
            return null;
          }

          return (
            <div key={field} className={styles.field}>
              <div className={styles.value}>
                {numeral(channel[field])
                  .format('0.00a')
                  .toUpperCase()}
              </div>
              <div className={styles.key}>{fieldDisplayName[field]}</div>
            </div>
          );
        })}
      </div>
    );
  };
}

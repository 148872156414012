import * as React from 'react';
import cx from 'classnames';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

import styles from './SpinnerIcon.scss';

export const SpinnerIcon = React.memo((props: ISvgIconProps) => {
  const {
    className,
    fill,
    ...restProps
  } = props;
  return (
    <SvgIcon
      className={cx(styles.SpinnerIcon, className)}
      viewBox="0 0 34 34"
      {...restProps}
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-783.000000, -481.000000)" fillRule="nonzero">
          <path
            d="
              M800,514.5 C799.171573,514.5 798.5,513.828427 798.5,513 C798.5,512.171573 799.171573,511.5 800,511.5
              C807.455844,511.5 813.5,505.455844 813.5,498 C813.5,490.544156 807.455844,484.5 800,484.5 C792.544156,484.5
              786.5,490.544156 786.5,498 C786.5,498.828427 785.828427,499.5 785,499.5 C784.171573,499.5 783.5,498.828427
              783.5,498 C783.5,488.887302 790.887302,481.5 800,481.5 C809.112698,481.5 816.5,488.887302 816.5,498
              C816.5,507.112698 809.112698,514.5 800,514.5 Z
            "
            transform="translate(800.000000, 498.000000) rotate(-360.000000) translate(-800.000000, -498.000000)"
            fill={fill}
          />
        </g>
      </g>
    </SvgIcon>
  );
});

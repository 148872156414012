import { isString } from 'lodash';

import { ILicensedContent } from 'src/common/models/licensedContent';

/**
 * Finds the proper video url within licensed content.
 *
 * @param {ILicensedContent} content the licensed content.
 *
 * @return {String}
 */
export default function(content: ILicensedContent) {
  if (isString(content.video)) {
    return content.video;
  }

  // look for video from social post
  if (content.social_post) {
    if (isString(content.social_post.video)) {
      return content.social_post.video;
    }

    if (isString(content.social_post.embed_link)) {
      return content.social_post.embed_link;
    }

    if (content.social_post.media && content.social_post.media.length > 0) {
      const videoMedia = content.social_post.media.find((media) => media.media_type === 'video');

      if (videoMedia) {
        return videoMedia.url;
      }
    }
  }

  if (content.media && content.media.length > 0) {
    const videoMedia = content.media.find((media) => media.media_type === 'video');

    if (videoMedia) {
      return videoMedia.url;
    }
  }

  return null;
}

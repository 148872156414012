import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { isEmpty, isFunction, isNumber, map, sortBy, uniq } from 'lodash';

import {
  CheckCircleIcon,
  HelpIcon,
  InstagramIcon,
} from 'src/icons';
import { TooltipIcon } from 'src/widgets/Icon';
import {
  Image,
  LazyImage,
} from 'src/widgets/Image';
import { Invite } from 'src/widgets/Invite';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import {
  Tabs,
  Tab,
} from 'src/widgets/Tabs';
import { CreatorActionButtonGroup } from 'src/common/CreatorActionButtonGroup';
import { FavoriteListButton } from 'src/common/FavoriteListButton';
import { GroupCreatorReviewActionButtonGroup } from 'src/common/GroupCreatorReviewActionButtonGroup';
import { NetworkIcon } from 'src/common/NetworkIcon';

import networkConfig from 'src/common/config/networkConfig';
import failedImage from 'src/common/utils/failedImage';

import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';

import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { getDetailConfig } from './creatorDetailUtils';

import styles from './CreatorDetail.scss';
const { useEffect, useState } = React;

export interface IDetailSection {
  show: boolean;
  icon: string;
  title: string;
  value: any;
}

interface ICreatorDetailProps {
  className?: string;
  socialAccount: ISocialAccount;

  isFetchingDetail?: boolean;
  header?: JSX.Element;
  footer?: JSX.Element;

  // used for displaying advanced metrics
  campaign?: ICampaign;
  org?: IOrganization;
  additionalSocialAccounts?: ISocialAccount[];

  // whether to show invite button
  inviteToCampaign?(socialAccount: ISocialAccount);
  // whether to show favorite list popover
  allowFavorite?: boolean;
  favoriteLists?: IFavoriteList[];
  addToFavoriteList?(listId: number);
  createFavoriteList?(name: string);
  updateSelectedSocialAccountID?(id: number);
  hideCreator?();
  sendOffer?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);

  showCreateFeatures?: boolean;
  selfServeExperiment: boolean;
}

export const CreatorDetail: React.FunctionComponent<ICreatorDetailProps> = (
  props: ICreatorDetailProps,
) => {
  const {
    addToFavoriteList,
    allowFavorite,
    updateSelectedSocialAccountID,
    campaign,
    className,
    createFavoriteList,
    favoriteLists,
    footer,
    goToManage,
    header,
    hideCreator,
    inviteToCampaign,
    isFetchingDetail,
    org,
    sendOffer,
    socialAccount,
    additionalSocialAccounts,
    selfServeExperiment,
    showCreateFeatures = true, // failsafe
  } = props;

  // Favorites check
  if (allowFavorite && (!addToFavoriteList || !createFavoriteList)) {
    throw new Error(
      `'addToFavoriteList' and 'createFavoriteList' are needed when 'allowFavorite' is 'true'.`,
    );
  }

  const waitingForDetail = isEmpty(socialAccount.images) && isFetchingDetail;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isInviting, setIsInviting] = useState(false);

  if (!socialAccount) {
    setSelectedImageIndex(0);
  }

  // selectedImageIndex cleanup
  useEffect(() => {
    return () => {
      setSelectedImageIndex(0);
    };
  }, []);

  /**
   * Renders the image list.
   */
  const renderImageList = () => {
    const images = uniq(socialAccount.images || [])
      // remove failed images
      .filter((url) => !failedImage.contains(url));
    const mainImage = images[selectedImageIndex];

    return (
      <div className={styles.images}>
        <div className={styles.mainImageContainer}>
          <Image
            src={mainImage}
            onError={() => handleImageError(mainImage)}
            className={styles.mainImage}
          />
        </div>
        <div className={styles.imageList}>
          {images.slice(0, 8).map((url, index) => (
            <LazyImage
              key={url}
              src={url}
              onError={() => handleImageError(url)}
              className={cx(styles.imageListItem, {
                [styles.active]: selectedImageIndex === index,
              })}
              onClick={() => selectImage(index)}
            />
          ))}
        </div>
      </div>
    );
  };

  /**
   * Render details
   */
  const renderDetails = () => {
    const { engagementNamePlural, audienceNamePlural, contentName } = networkConfig[
      socialAccount.network_identifier
    ];
    const detailConfig = getDetailConfig({ campaign, org, socialAccount });
    const handleChangeTab = (index: number) => {
      updateSelectedSocialAccountID(additionalSocialAccounts[index].id)
    };
    return (
      <div className={styles.details}>
        {!isEmpty(additionalSocialAccounts) && (
          <Tabs onChangeTab={handleChangeTab}>
            {additionalSocialAccounts.map((account, idx) => {
              return <Tab
                key={account.id + idx}
                title={
                  <div className={styles.userInfo}>
                    {account.network_identifier && (
                      <span className={styles.socialIcon}>
                        <NetworkIcon size={20} identifier={account.network_identifier} />
                      </span>
                    )}
                    <span className={styles.name}>{account.username || account.name}</span>
                  </div>
                }
                selected={account.id === socialAccount.id}
              />
            })}
          </Tabs>
        )}
        {isEmpty(additionalSocialAccounts) && (
          <div className={styles.summary}>
            <div className={styles.userInfo}>
              {socialAccount.network_identifier && (
                <span className={styles.socialIcon}>
                  <NetworkIcon size={20} identifier={socialAccount.network_identifier} />
                </span>
              )}
              <span className={styles.name}>{socialAccount.username || socialAccount.name}</span>
            </div>
            {socialAccount.network_identifier !== 'pinterest' &&
              socialAccount.engagement !== undefined &&
              socialAccount.engagement !== null && (
              <div className={styles.engagement}>
                {numeral(socialAccount.engagement)
                  .format('0.[0]a')
                  .toUpperCase()}
                <span className={styles.unit}>
                  {engagementNamePlural}/{contentName}
                </span>
              </div>
            )}
            <div className={styles.followers}>
              {numeral(socialAccount.reach)
                .format('0.[0]a')
                .toUpperCase()}
              <div className={styles.unit}>{audienceNamePlural}</div>
            </div>
            {socialAccount.network_identifier === 'pinterest' && (
              <div className={styles.followers}>
                {numeral(socialAccount.follower_count)
                  .format('0.[0]a')
                  .toUpperCase()}
                <span className={styles.unit}>
                  followers
                </span>
              </div>
            )}
          </div>
        )}
        <div className={styles.content}>
          {map(detailConfig.filter((config) => config.show), (config, index) => (
            <div className={styles.contentItem} key={index}>
              <div className={cx(styles.icon, config.icon)} />
              <div className={styles.metrics}>
                <strong className={styles.metricsTitle}>{config.title}</strong>
                <div className={styles.metricsValue}>{config.value}</div>
              </div>
            </div>
          ))}
        </div>
        {isFetchingDetail ? <LoadSpinner /> :
          <React.Fragment>
            {renderInsights()}
            {renderAuthenticity()}
          </React.Fragment>
        }
      </div>
    );
  };

  /**
   * Renders the account insights section.
   */
  const renderInsights = () => {
    const report = socialAccount.demographics_report;
    const hasAdvancedDemographics =
      hasFeature(org, 'enterprise_data') || hasFeature(org, 'advanced_demographics');

    if (!hasAdvancedDemographics || !report) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <div className={styles.divider} />
          <div className={styles.headerText}>
            Audience Insights
            <TooltipIcon
              className={styles.igInsightsIcon}
              icon={<VerifiedIGInsights />}
              tooltipText="Verified data from IG Insights"
            />
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.sectionContent}>
          {report.city && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.cityIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>Audience City</div>
                <div className={styles.metricsValue}>
                  {sortBy(report.city, (city) => -city[1])
                    .slice(0, 3)
                    .map((city, index) => (
                      <div key={index}>
                        {numeral(city[1]).format('0.00%')} {city[0]}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {report.country && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.flagIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>Audience Country</div>
                <div className={styles.metricsValue}>
                  {sortBy(report.country, (country) => -country[1])
                    .slice(0, 3)
                    .map((country, index) => (
                      <div key={index}>
                        {numeral(country[1]).format('0.00%')} {country[0]}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {report.age && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.ageIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>Audience Age</div>
                <div className={styles.metricsValue}>
                  {sortBy(
                    map(report.age, (count, range) => [count, range]),
                    (value) => -value[0],
                  ).map((value) => (
                    <div key={value[1]}>
                      {value[1]}: {numeral(value[0]).format('0.00%')}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {report.gender && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.genderIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>Audience Gender</div>
                <div className={styles.metricsValue}>
                  <div>{numeral(report.gender.MALE).format('0.00%')} Male</div>
                  <div>{numeral(report.gender.FEMALE).format('0.00%')} Female</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * Renders the account audience authenticity section.
   */
  const renderAuthenticity = () => {
    const report = socialAccount.demographics_report;
    const hasAdvancedDemographics =
      hasFeature(org, 'enterprise_data') || hasFeature(org, 'advanced_demographics');
    const isInstagramAndHasAuthenticity =
      hasFeature(org, 'follower_authenticity') && socialAccount.network_identifier === 'instagram';
    if (!hasAdvancedDemographics || !isInstagramAndHasAuthenticity || !report) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <div className={styles.divider} />
          <div className={styles.headerText}>Audience Authenticity</div>
          <div className={styles.divider} />
        </div>
        <div className={styles.sectionContent}>
          {isNumber(socialAccount.follower_score) && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.followerAuthIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>
                  Follower Authenticity
                  <TooltipIcon
                    icon={<HelpIcon size={18} />}
                    tooltipText="Estimated follower authenticity percentage"
                    className={styles.helpIcon}
                  />
                </div>
                <div className={styles.metricsValue}>
                  <div
                    className={
                      socialAccount.follower_score > 85
                        ? styles.positive
                        : socialAccount.follower_score > 75
                          ? styles.neutral
                          : styles.negative
                    }
                  >
                    <span className={styles.score}>
                      {numeral(socialAccount.follower_score / 100).format('0.00%')}
                    </span>
                    (
                    {socialAccount.follower_score > 93
                      ? 'Very High'
                      : socialAccount.follower_score > 85
                        ? 'High'
                        : socialAccount.follower_score > 75
                          ? 'Medium'
                          : 'Low'}
                    )
                  </div>
                </div>
              </div>
            </div>
          )}
          {isNumber(socialAccount.impressions_ratio) && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.impressionIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>
                  Impression Ratio
                  <TooltipIcon
                    icon={<HelpIcon size={18} />}
                    tooltipText={`Average percentage of @${socialAccount.username}'s followers that see their posts`}
                    className={styles.helpIcon}
                  />
                </div>
                <div className={styles.metricsValue}>
                  <div
                    className={
                      socialAccount.impressions_ratio > 13
                        ? styles.positive
                        : socialAccount.impressions_ratio > 8
                          ? styles.neutral
                          : styles.negative
                    }
                  >
                    <span className={styles.score}>
                      {numeral(socialAccount.impressions_ratio / 100).format('0.00%')}
                    </span>
                    (
                    {socialAccount.impressions_ratio > 18
                      ? 'Very High'
                      : socialAccount.impressions_ratio > 13
                        ? 'High'
                        : socialAccount.impressions_ratio > 8
                          ? 'Medium'
                          : 'Low'}
                    )
                  </div>
                </div>
              </div>
            </div>
          )}
          {isNumber(socialAccount.engagement_quality) && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.engagementQualityIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>
                  Engagement Quality
                  <TooltipIcon
                    icon={<HelpIcon size={18} />}
                    tooltipText="Score out of 10 based off AspireIQ analysis of engagement quality"
                    className={styles.helpIcon}
                  />
                </div>
                <div className={styles.metricsValue}>
                  <div
                    className={
                      socialAccount.engagement_quality > 6
                        ? styles.positive
                        : socialAccount.engagement_quality > 3
                          ? styles.neutral
                          : styles.negative
                    }
                  >
                    <span className={styles.score}>
                      {numeral(socialAccount.engagement_quality).format('0.[0]')}
                    </span>
                    (
                    {socialAccount.engagement_quality > 8
                      ? 'Very High'
                      : socialAccount.engagement_quality > 6
                        ? 'High'
                        : socialAccount.engagement_quality > 3
                          ? 'Medium'
                          : 'Low'}
                    )
                  </div>
                </div>
              </div>
            </div>
          )}
          {isNumber(socialAccount.stories_impressions_ratio) && (
            <div className={styles.contentItem}>
              <div className={cx(styles.icon, styles.engagementIcon)} />
              <div className={styles.metrics}>
                <div className={styles.metricsTitle}>
                  Stories Impressions Ratio
                  <TooltipIcon
                    icon={<HelpIcon size={18} />}
                    tooltipText={`Average percentage of @${socialAccount.username}'s followers that see their stories`}
                    className={styles.helpIcon}
                  />
                </div>
                <div className={styles.metricsValue}>
                  <div
                    className={
                      socialAccount.stories_impressions_ratio > 3
                        ? styles.positive
                        : socialAccount.stories_impressions_ratio > 1.5
                          ? styles.neutral
                          : styles.negative
                    }
                  >
                    <span className={styles.score}>
                      {numeral(socialAccount.stories_impressions_ratio / 100).format('0.00%')}
                    </span>
                    (
                    {socialAccount.stories_impressions_ratio > 6
                      ? 'Very High'
                      : socialAccount.stories_impressions_ratio > 3
                        ? 'High'
                        : socialAccount.stories_impressions_ratio > 1.5
                          ? 'Medium'
                          : 'Low'}
                    )
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * Invites to campaign.
   */
  const inviteCreatorToCampaign = () => {
    setIsInviting(true);
    inviteToCampaign(socialAccount).finally(() => {
      setIsInviting(false);
    });
  };

  /**
   * Handler for when image load failed.
   */
  const handleImageError = (url: string) => {
    failedImage.add(url);
  };

  const selectImage = (imageIndex: number) => {
    setSelectedImageIndex(imageIndex);
  };

  const renderActionButtonGroup = () => isEmpty(additionalSocialAccounts)
    ? (
      <CreatorActionButtonGroup
        className={styles.actionButtonGroup}
        socialAccount={socialAccount}
        isInviting={isInviting}
        showInviteButton={isFunction(inviteToCampaign)}
        inviteCallback={inviteCreatorToCampaign}
        inviteButtonType={'primary'}
        showOfferButton={isFunction(sendOffer)}
        offerCallback={sendOffer}
        manageCallback={goToManage}
        showHideButton={false && isFunction(hideCreator)} // TODO: Will be done via DT-350
        hideCallback={hideCreator}
      />
    )
    : (
      // TODO: attach the correct actions for creatorReview
      <GroupCreatorReviewActionButtonGroup
        className={styles.actionButtonGroup}
        isApproving={isInviting}
        showApproveButton={isFunction(inviteToCampaign)}
        approveCallback={inviteCreatorToCampaign}
        approveButtonType={'primary'}
        showRejectButton={isFunction(sendOffer)}
        rejectCallback={sendOffer}
      />
    );

  return (
    <div className={cx(className, styles.CreatorDetail)}>
      <div className={styles.detailHeader}>
        <div className={styles.creatorName}>
          <h2>{socialAccount.username || socialAccount.name}</h2>
          {allowFavorite && (
            <FavoriteListButton
              className={styles.favoriteButton}
              favoriteLists={favoriteLists}
              addToFavoriteList={addToFavoriteList}
              createFavoriteList={createFavoriteList}
              size={28}
            />
          )}
        </div>

        {showCreateFeatures
          ? !selfServeExperiment && renderActionButtonGroup()
          : (
            <Invite
              className={styles.invite}
              prospect={{ socialAccount }}
            />
          )}
      </div>
      {header && <header className={styles.header}>{React.Children.toArray(header)}</header>}
      {waitingForDetail ? (
        <LoadSpinner />
      ) : (
        <div className={styles.info}>
          {renderImageList()}
          {renderDetails()}
        </div>
      )}
      {footer && <footer className={styles.footer}>{React.Children.toArray(footer)}</footer>}
    </div>
  );
};

CreatorDetail.defaultProps = {
  addToFavoriteList: null,
  allowFavorite: false,
  createFavoriteList: null,
  favoriteLists: [],
  footer: null,
  header: null,
  inviteToCampaign: null,
  isFetchingDetail: false,
};

const VerifiedIGInsights = () => (
  <div className={styles.verifiedIgInsights}>
    <CheckCircleIcon size={18} className={styles.checkIcon} />
    <div className={styles.instagramIconWrapper}>
      <InstagramIcon size={8} className={styles.instagramIcon} />
    </div>
  </div>
);

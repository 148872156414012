import * as React from 'react';
import cx from 'classnames';
import { map, findIndex } from 'lodash';

const { useState } = React;

import styles from './Tabs.scss';

type TTabTitle = JSX.Element | string;

interface ITabProps {
  title: TTabTitle;
  disabled?: boolean;
  selected?: boolean;
  minHeight?: number;
  className?: string;
}

export const Tab: React.FunctionComponent<ITabProps> = React.memo((props) => {
  return (
    <div
      className={cx(props.className, styles.tabContent, {
        [styles.selected]: props.selected,
      })}
      style={{ minHeight: props.minHeight || 0 }}
    >
      {props.children}
    </div>
  );
});

Tab.defaultProps = {
  disabled: false,
};

interface ITabsProps {
  minHeight?: number;
  fullWidth?: boolean;
  onChangeTab?(index: number);
  className?: string;
  children: Array<React.ReactElement<ITabProps>>;
}

export const Tabs: React.FunctionComponent<ITabsProps> = React.memo((props) => {
  const [selectedIndexState, setSelectedIndexState] = useState(0);

  const controlledSelectedIdx = findIndex(
    props.children,
    (child: React.ReactElement<ITabProps>) => child.props.selected,
  );

  const isControlled = controlledSelectedIdx > -1;

  const handleClickTab = (idx: number) => {
    if (!isControlled) {
      setSelectedIndexState(idx);
    }

    if (props.onChangeTab) {
      props.onChangeTab(idx);
    }
  };

  const tabProps = map(props.children, (child: React.ReactElement<ITabProps>) => child.props);

  const selectedIndex = isControlled ? controlledSelectedIdx : selectedIndexState;

  return (
    <div className={cx(props.className, styles.Tabs)}>
      <div className={cx(styles.tabWrapper, { [styles.fullWidth]: props.fullWidth })}>
        {map(tabProps, (tab, idx) => (
          <div
            key={idx}
            className={cx(styles.tab, {
              [styles.selected]: selectedIndex === idx,
              [styles.disabled]: tab.disabled,
            })}
            onClick={() => handleClickTab(idx)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {React.Children.map(props.children as React.ReactElement[], (child, idx) =>
        React.cloneElement(child, { selected: selectedIndex === idx, minHeight: props.minHeight || 0 }),
      )}
    </div>
  );
});

Tabs.defaultProps = {
  fullWidth: true,
};

import * as React from 'react';
import { cloneDeep, filter, find, forEach, isArray, isFunction, map, uniqBy } from 'lodash';

import { IProspect } from 'src/common/models/member';
import { IMemberProgram } from 'src/common/models/program';

import { useCommunities } from './useCommunities';
import { useInviteContext } from './useInviteContext';
import { useInviteMember } from './useInviteMember';
import { usePrograms } from './usePrograms';
import { useResources } from './useResources';
import { useUpdateMemberPrograms } from './useUpdateMemberPrograms';
import { IInviteProps } from '../Invite';

const { useCallback, useEffect } = React;

type TProps = Pick<IInviteProps, 'onInvite' | 'preloadProspects' | 'prospect'>;

export const useInvite = (props: TProps) => {
  const {
    onInvite,
    preloadProspects,
    prospect,
  } = props;

  const { updateMemberPrograms } = useInviteContext();
  const {
    loading: isFetchingResources,
    resourceId,
    error: fetchingResourcesError,
  } = useResources();
  const {
    isFetching: isFetchingCommunities,
    communities,
    error: fetchingCommunitiesError,
  } = useCommunities();
  const {
    isFetching: isFetchingPrograms,
    programs,
    error: fetchingProgramsError,
  } = usePrograms();
  const {
    isFetching: isFetchingProspectPrograms,
    error: fetchingProspectProgramsError,
  } = useUpdateMemberPrograms(preloadProspects);
  const {
    sendInvite,
    ...inviteStatus
  } = useInviteMember({ prospect });

  /**
   * Update programs on invite
   */
  const programIds = map(programs, (p) => p.id).sort().join(',');
  const updateProgramStatusForProspects = useCallback(
    (
      invitedProspect: IInviteProps['prospect'],
      programId: IMemberProgram['id'],
    ) => {
      const prospects: IProspect[] = isArray(invitedProspect) ? invitedProspect : [invitedProspect];
      const updatedPrograms: IMemberProgram[] = map(prospects, (prospect) => {
        const program = find(programs, (program) => program.id === programId) as IMemberProgram;
        return {
          ...cloneDeep(program),
          username: prospect.socialAccount.username,
          status: program.status || 'invited',
        };
      });
      if (updatedPrograms.length > 0) {
        if (isFunction(onInvite)) {
          onInvite(invitedProspect, uniqBy(updatedPrograms, (p) => p.title));
        }
        forEach(
          map(prospects, (p) => p.socialAccount.username),
          (username) => updateMemberPrograms(
            username,
            filter(updatedPrograms, (p) => p.username === username),
          ),
        );
      }
    },
    [programIds],
  );

  useEffect(() => {
    if (inviteStatus && inviteStatus.isInvited) {
      updateProgramStatusForProspects(inviteStatus.prospect, inviteStatus.programId); // Update the status to 'invited'
    }
  }, [inviteStatus.isInvited]);

  return {
    communities,
    programs,
    resourceId,
    inviteStatus,
    sendInvite,

    isFetching: (
      isFetchingCommunities ||
      isFetchingPrograms ||
      isFetchingResources ||
      isFetchingProspectPrograms
    ),
    error: (
      fetchingCommunitiesError ||
      fetchingProgramsError ||
      fetchingResourcesError ||
      fetchingProspectProgramsError ||
      inviteStatus.error
    ),
  };
};

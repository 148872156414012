import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const ImageNoBorderIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="
        M2.8,21.1c-0.6,0-1-0.7-0.7-1.2l3.7-6.2c0.3-0.5,1-0.5,1.3-0.1L9.9,17c0.3,0.4,1,0.4,1.3-0.1L16.4,9
        c0.4-0.5,1.2-0.5,1.4,0.2l4.1,10.8c0.2,0.5-0.2,1.1-0.8,1.1H2.8z
        M8.3,5.4c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5
        c0-1.4,1.1-2.5,2.5-2.5C7.1,2.9,8.3,4,8.3,5.4z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

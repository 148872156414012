import { IContentReview } from 'src/common/models/contentReview';

/**
 * @private
 * Sort function for content reviews.
 *
 * @param {IContentReview} review1 the first content info.
 * @param {IContentReview} review2 the second content info.
 *
 * @return {Number}
 */
export default (review1: IContentReview, review2: IContentReview): number => {
  // first check approved/rejected status
  if (
    ['ACCEPTED', 'COMPLETED_WITHOUT_PAYMENT', 'COMPLETED', 'REJECTED'].includes(review1.state)
  ) {
    return 1;
  } else if (
    ['ACCEPTED', 'COMPLETED_WITHOUT_PAYMENT', 'COMPLETED', 'REJECTED'].includes(review2.state)
  ) {
    return -1;
  }

  // check due date
  if (!review1.automatic_approval_expire_time_ts) {
    return 1;
  } else if (!review2.automatic_approval_expire_time_ts) {
    return -1;
  }

  if (review1.automatic_approval_expire_time_ts < review2.automatic_approval_expire_time_ts) {
    return -1;
  }

  return 1;
};

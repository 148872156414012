import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const LikedCircleBlueIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <circle stroke="#E2E2E2" fill="#FFFFFF" cx="10" cy="10" r="10" />
    <path
      d="M13.8586299,11.4595742 L13.8586299,11.4595742 C14.3272164,11.4595742 14.7070062,11.839364 14.7070062,12.3079505 C14.7070062,12.776537 14.3272164,13.1563268 13.8586299,13.1563268 L13.2930457,13.1563268 C13.7616322,13.1563268 14.141422,13.5361166 14.141422,14.0047031 C14.141422,14.4732896 13.7616322,14.5702873 13.2930457,14.5702873 L10.1823326,14.5702873 C8.57041759,14.5702873 7.52210726,13.7462312 5.54256255,13.4634391 L5.60053493,9.46362755 C6.98649902,9.46362755 9.89954047,7.21769268 9.89954047,4.95535586 C9.89954047,4.06116723 11.1376043,3.72832092 11.5962931,5.3620109 C11.8790852,6.36931637 11.0307089,7.78327688 11.0307089,7.78327688 L14.9897983,7.78327688 C15.4583848,7.78327688 15.8381746,8.16306668 15.8381746,8.63165319 C15.8381746,9.1002397 15.4583848,9.7628216 14.9897983,9.7628216 L14.4242141,9.7628216 C14.8928006,9.7628216 15.2725904,10.1426114 15.2725904,10.6111979 C15.2725904,11.0797844 14.8928006,11.4595742 14.4242141,11.4595742 L13.8586299,11.4595742"
      fill="#3996e0"
    />
  </SvgIcon>
));

export { LikedCircleBlueIcon };

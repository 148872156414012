import * as React from 'react';
import { findIndex } from 'lodash';

import { RadioGroup } from 'src/widgets/RadioGroup';

import { FilterSection } from '../FilterSection';

import { IActivityFilters } from '../models';

const ACTIVITY_OPTIONS = [
  { label: 'Last week', value: 7 },
  { label: 'Last month', value: 30 },
  { label: 'Last year', value: 365 },
];

const { useMemo } = React;

interface IProps extends IActivityFilters {
  onChangeLastPostActivity(value?: number);
  hide?: boolean;
}

const ActivitySection: React.FunctionComponent<IProps> = (props) => {
  const selectedIndex = useMemo(() => {
    return findIndex(ACTIVITY_OPTIONS, { value: props.lastPostActivity });
  }, [props.lastPostActivity]);

  const handleClear = () => {
    props.onChangeLastPostActivity();
  };

  return (
    <FilterSection
      header="Last Post Activity"
      hide={props.hide}
      isSubsection={true}
      showClearButton={selectedIndex >= 0}
      onClear={handleClear}
    >
      <RadioGroup
        options={ACTIVITY_OPTIONS}
        onChange={props.onChangeLastPostActivity}
        selectedIndex={selectedIndex}
      />
    </FilterSection>
  );
};

export default ActivitySection;

import * as React from 'react';
import { each, clone, isEmpty } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ProposalReviewPage, IReview } from './ProposalReviewPage';

import { IClientProposal } from 'src/common/models/clientProposal';
import { ISocialAccount } from 'src/common/models/socialAccount';

import endpoints from 'src/common/config/endpoints';
import addEventLog from 'src/common/utils/addEventLog';

interface IProps {
  // api endpoint
  apiEndpoint: string;

  proposalId: number | string;
}
interface IState {
  proposal: IClientProposal;
  isFetchingProposal: boolean;

  socialAccounts: ISocialAccount[];
  isFetchingSocialAccounts: boolean;
  reviews: {
    [socialAccountId: number]: IReview;
  };
}

/**
 * @class
 * @extends {React.Component}
 */
export default class ProposalReviewPageWrapper extends React.Component<IProps, IState> {
  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    // check proposal id
    if (!props.proposalId) {
      throw new Error('Proposal id is needed.');
    }
    // check endpoint
    if (!props.apiEndpoint) {
      throw new Error('API endpoint is needed.');
    }

    this.state = {
      proposal: null,
      isFetchingProposal: false,

      socialAccounts: [],
      isFetchingSocialAccounts: false,
      reviews: [],
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    this.setState({
      isFetchingProposal: true,
    });
    this.fetchProposal()
      .then((proposal) => {
        console.log(proposal);

        const reviews: {
          [socialAccountId: number]: IReview;
        } = {};
        // update reviews
        each(proposal.reviews, (review) => {
          reviews[review.social_account_id] = {
            comment: review.comment || '',
            approved: !!review.approved,
          };
        });

        this.setState(
          {
            proposal,
            reviews,
            isFetchingProposal: false,
          },
          () => {
            this.setState({
              isFetchingSocialAccounts: true,
            });

            // start fetching first page
            this.fetchSocialAccountsForPage(0);
          },
        );
      })
      .catch(() => {
        this.setState({
          isFetchingProposal: false,
        });
      });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      proposal,
      isFetchingProposal,
      socialAccounts,
      isFetchingSocialAccounts,
      reviews,
    } = this.state;

    return (
      <React.Fragment>
        {isFetchingProposal && <LoadSpinner />}
        {!isFetchingProposal && proposal && (
          <ProposalReviewPage
            proposal={proposal}
            socialAccounts={socialAccounts}
            isFetchingSocialAccounts={isFetchingSocialAccounts}
            reviews={reviews}
            updateFeedback={this.updateFeedback}
            updateApproved={this.updateApproved}
          />
        )}
      </React.Fragment>
    );
  }

  /**
   * @private
   * Fetches the proposal.
   *
   * @return {Promise}
   */
  private fetchProposal = (): Promise<IClientProposal> =>
    new Promise(async (resolve, reject) => {
      const { proposalId, apiEndpoint } = this.props;

      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.proposalEndpoint}/${proposalId}`, {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        });

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const proposal: IClientProposal = json.data;

          addEventLog('open_agency_proposal', {
            count: proposal.favorite_list && proposal.favorite_list.element_count,
          });

          resolve(proposal);
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Fetches social accounts of given page, associated with the proposal.
   *
   * @param {Number} page indicates which page to fetch.
   *
   * @return {Promise}
   */
  private fetchSocialAccountsForPage = async (page: number) => {
    const { apiEndpoint } = this.props;
    const { proposal } = this.state;
    const { campaign, favorite_list: favoriteList } = proposal;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialAccountEndpoint}?campaign_id=${campaign.id}&favorites_list_id=${favoriteList.id}&page=${page}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      if (json.status && json.status.code === 200) {
        const { socialAccounts } = this.state;
        const { data: newSocialAccounts, has_next: hasNext } = json.data;

        this.setState(
          {
            socialAccounts: [...socialAccounts, ...newSocialAccounts],
          },
          () => {
            if (hasNext) {
              this.fetchSocialAccountsForPage(page + 1);
            } else {
              this.setState({
                isFetchingSocialAccounts: false,
              });
            }
          },
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @private
   * Updates the comment of a specific review.
   *
   * @param {Number} socialAccountId the social account id of the review.
   * @param {String} feedback the updated feedback.
   *
   * @return {Promise}
   */
  private updateFeedback = (socialAccountId: number, feedback: string) =>
    new Promise(async (resolve, reject) => {
      const { proposalId, apiEndpoint } = this.props;
      const { socialAccounts } = this.state;

      if (!isEmpty(feedback)) {
        addEventLog('reject_creator_agency_proposal', {
          proposal_id: proposalId,
          percentage: (1 / socialAccounts.length).toFixed(2),
        });
      }

      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.proposalEndpoint}/${proposalId}`, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            social_account_id: socialAccountId,
            comment: feedback,
          }),
        });

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const { reviews } = this.state;
          const newReviews = clone(reviews);
          if (newReviews[socialAccountId]) {
            newReviews[socialAccountId].comment = feedback;
          } else {
            newReviews[socialAccountId] = {
              comment: feedback,
            };
          }

          this.setState(
            {
              reviews: newReviews,
            },
            resolve,
          );
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Updates the approved status of a specific review.
   *
   * @param {Number} socialAccountId the social account id of the review.
   * @param {Boolean} approved the updated approved status.
   *
   * @return {Promise}
   */
  private updateApproved = (socialAccountId: number, approved: boolean) =>
    new Promise(async (resolve, reject) => {
      const { proposalId, apiEndpoint } = this.props;
      const { socialAccounts } = this.state;

      if (approved) {
        addEventLog('approve_creator_agency_proposal', {
          proposal_id: proposalId,
          percentage: (1 / socialAccounts.length).toFixed(2),
        });
      }

      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.proposalEndpoint}/${proposalId}`, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            social_account_id: socialAccountId,
            approved,
          }),
        });

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const { reviews } = this.state;
          const newReviews = clone(reviews);

          if (newReviews[socialAccountId]) {
            newReviews[socialAccountId].approved = approved;
          } else {
            newReviews[socialAccountId] = {
              approved: approved,
            };
          }

          this.setState(
            {
              reviews: newReviews,
            },
            resolve,
          );
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });
}

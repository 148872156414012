import * as React from 'react';

import styles from './ErrorBoundary.scss';

interface IProps {
  message?: string | Element;
}

interface IState {
  hasError: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ErrorBoundary extends React.Component<IProps, IState> {
  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { message, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      if (message) {
        return <div className={styles.message}>{message}</div>;
      }

      return null;
    }

    return children;
  }
}

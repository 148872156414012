import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const FlagIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M3.16,2.73C3.16,2.33,3.49,2,3.89,2s0.73,0.32,0.73,0.73v18.55c0,0.4-0.32,0.73-0.73,0.73
      s-0.73-0.32-0.73-0.73V2.73z M19.92,4.2c0.46-0.13,0.92,0.22,0.92,0.7v9.71c0,0.33-0.22,0.62-0.54,0.7
      c-0.08,0.02-0.23,0.06-0.43,0.11c-0.33,0.08-0.7,0.16-1.08,0.23c-0.68,0.13-1.35,0.23-1.98,0.29c-2.52,0.23-4.17-0.21-4.17-1.93
      c0-0.34-0.85-0.49-2.53-0.19c-0.49,0.09-1.02,0.21-1.57,0.36c-0.43,0.11-0.84,0.24-1.22,0.36c-0.23,0.07-0.39,0.13-0.48,0.16
      c-0.47,0.17-0.97-0.18-0.97-0.68V4.34c0-0.31,0.19-0.58,0.48-0.68c0.1-0.04,0.28-0.1,0.52-0.18c0.4-0.13,0.84-0.26,1.29-0.38
      c0.58-0.16,1.15-0.29,1.69-0.38c2.54-0.46,4.23-0.15,4.23,1.62c0,0.4,0.88,0.63,2.58,0.46c0.57-0.06,1.19-0.15,1.82-0.28
      c0.37-0.07,0.72-0.15,1.04-0.23C19.71,4.25,19.85,4.22,19.92,4.2z M18.77,5.95c-0.68,0.13-1.34,0.24-1.96,0.3
      c-2.52,0.24-4.17-0.19-4.17-1.91c0-0.34-0.85-0.49-2.53-0.19C9.61,4.24,9.08,4.36,8.54,4.5C8.12,4.62,7.71,4.74,7.33,4.86v8.16
      c0.27-0.08,0.55-0.16,0.84-0.24c0.58-0.16,1.15-0.29,1.69-0.38c2.54-0.46,4.23-0.15,4.23,1.62c0,0.4,0.88,0.64,2.59,0.48
      c0.58-0.05,1.2-0.15,1.84-0.27c0.31-0.06,0.6-0.12,0.88-0.19V5.82C19.19,5.87,18.98,5.91,18.77,5.95z"
    />
  </SvgIcon>
));

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const CheckCircleYellowIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g fillRule="nonzero" transform="translate(2 2)">
      <circle cx="10" cy="10" r="9.773" fill="#EACD60" />
      <path
        fill="#FFF"
        d="M8.243 14.01l.743.757.635-.85 5.062-6.782a.91.91 0 0 0-1.457-1.088L8.826 12 6.421 9.558a.91.91 0 0 0-1.298 1.274l3.12 3.178z"
      />
    </g>
  </SvgIcon>
));

export { CheckCircleYellowIcon };

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const NavigationIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    viewBox='0 0 16 16'
    {...props}
  >
    <g id='navigation-2-fill'>
      <path
        id='NavigationIcon'
        d='
          M9.11081 14.6664C9.09681 14.6664 9.08348 14.6658 9.07015 14.6651C8.76881 14.6471 8.51748 14.4284 8.45748
          14.1324L7.43216 9.08778C7.37883 8.82511 7.17416 8.62044 6.9115 8.56711L1.86687 7.54111C1.57087 7.48178 1.35221
          7.23044 1.33421 6.92911C1.31621 6.62711 1.50221 6.35111 1.78887 6.25644L12.4555 2.70111C12.6948 2.61978
          12.9588 2.68244 13.1374 2.86178C13.3161 3.04044 13.3781 3.30444 13.2988 3.54378L9.74281 14.2104C9.65214
          14.4844 9.39614 14.6664 9.11081 14.6664Z
        '
        fill={props.fill}
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='2'
        width='13'
        height='13'
      >
        <path
          id='NavigationIcon_2'
          d='
            M9.11081 14.6664C9.09681 14.6664 9.08348 14.6658 9.07015 14.6651C8.76881 14.6471 8.51748 14.4284 8.45748
            14.1324L7.43216 9.08778C7.37883 8.82511 7.17416 8.62044 6.9115 8.56711L1.86687 7.54111C1.57087 7.48178
            1.35221 7.23044 1.33421 6.92911C1.31621 6.62711 1.50221 6.35111 1.78887 6.25644L12.4555 2.70111C12.6948
            2.61978 12.9588 2.68244 13.1374 2.86178C13.3161 3.04044 13.3781 3.30444 13.2988 3.54378L9.74281
            14.2104C9.65214 14.4844 9.39614 14.6664 9.11081 14.6664Z
          '
          fill='white'
        />
      </mask>
      <g mask='url(#mask0)' />
    </g>
  </SvgIcon>
));

export { NavigationIcon };

import * as React from 'react';
import cx from 'classnames';
import { subDays, format, subMonths } from 'date-fns';

import { CalendarIcon as DateRangeIcon } from 'src/icons';
import { Accordion } from 'src/widgets/Accordion';
import { DatePicker } from 'src/widgets/DatePicker';
import { Popover } from 'src/widgets/Popover';

import styles from './Filters.scss';
const { useRef, useState } = React;

export const DateFilter = ({ filters, setFilters, isDisabled }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { datePosted: dateState } = filters;

  const toggleOpen = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const close = () => {
    setOpen(false);
  };

  const selectStartDate = date => {
    setFilters({ type: 'SELECT_START_DATE', payload: date });
  };

  const selectEndDate = date => {
    setFilters({ type: 'SELECT_END_DATE', payload: date });
  };

  const menuItems: { type: string; label: string }[] = [
    {
      type: 'SELECT_YESTERDAY',
      label: 'Yesterday',
    },
    {
      type: 'SELECT_LAST_SEVEN_DAYS',
      label: 'Last 7 days',
    },
    {
      type: 'SELECT_LAST_THIRTY_DAYS',
      label: 'Last 30 days',
    },
    {
      type: 'SELECT_LAST_THREE_MONTHS',
      label: 'Last 3 months',
    },
    {
      type: 'SELECT_LAST_SIX_MONTHS',
      label: 'Last 6 months',
    },
    // {
    //   type: 'SELECT_ALL_TIME',
    //   label: 'All Time',
    // },
  ];

  const isDateRange = (date: string): boolean => (
    /^\d{2}\/\d{2}\/\d{2} - \d{2}\/\d{2}\/\d{2}$/.test(date)
  );

  return <>
    <div
      ref={ref}
      className={cx(styles.filter, {
        [styles.disabled]: isDisabled,
      })}
      onClick={toggleOpen}
    >
      <span className={styles.filterCircle}>
        <DateRangeIcon size={16} />
      </span>
      <span className={styles.filterText}>{dateState.text}</span>
    </div>
    <Popover
      mountRef={ref}
      show={open}
      onRequestClose={close}
      className={styles.DateFilterPopover}
    >
      <ul className={styles.MenuList}>
        {menuItems.map((item, i) => (
          <li
            className={cx(styles.MenuItem, {
              [styles.selected]: dateState.text === item.label,
            })}
            onClick={() => {
              setFilters({ type: item.type });
              setOpen(false);
            }}
            key={i}
          >
            {item.label}
          </li>
        ))}
        <li className={cx(styles.MenuItem, styles.customRange, {
          [styles.selected]: isDateRange(dateState.text),
        })}>
          <Accordion
            className={styles.accordion}
            labelClassName={styles.customRangeLabel}
            contentsClassName={styles.customRangeContent}
            isOpen={dateState.customDateOpen}
            label="Custom Range"
            onToggle={() => setFilters({ type: 'OPEN_CUSTOM_DATE' })}
            arrowPlacement='right'
          >
            <div className={styles.dateRange}>
              <div>
                <p>Start Date</p>
                <DatePicker
                  classNames={styles.datePicker}
                  defaultDate={dateState.date.start}
                  onDateSelected={selectStartDate}
                />
              </div>
              <div>
                <p>End Date</p>
                <DatePicker
                  classNames={styles.datePicker}
                  defaultDate={dateState.date.end}
                  onDateSelected={selectEndDate}
                />
              </div>
            </div>
          </Accordion>
        </li>
      </ul>
    </Popover>
  </>;
};

export const initialDateState = {
  text: 'Last 30 days',
  customDateOpen: false,
  date: {
    start: subDays(new Date(), 30),
    end: new Date(),
  },
};

const createDateString = date =>
  `${date.start ? format(date.start, 'MM/dd/yy') : 'Please Select'} - ${
    date.end ? format(date.end, 'MM/dd/yy') : 'Please Select'
  }`;

export const dateReducer = (state, action) => {
  const localState = state.datePosted;
  switch (action.type) {
    case 'SELECT_START_DATE': {
      const date = { ...state.date, start: action.payload };
      return {
        ...localState,
        text: createDateString(date),
        date,
      };
    }
    case 'SELECT_END_DATE': {
      const date = { ...localState.date, end: action.payload };
      return {
        ...localState,
        date,
        text: createDateString(date),
      };
    }
    case 'SELECT_YESTERDAY': {
      const date = { start: subDays(new Date(), 1), end: new Date() };
      return {
        ...localState,
        date,
        text: 'Yesterday',
        customDateOpen: false,
      };
    }
    case 'SELECT_LAST_SEVEN_DAYS': {
      const date = { start: subDays(new Date(), 7), end: new Date() };
      return {
        ...localState,
        date,
        text: 'Last 7 days',
        customDateOpen: false,
      };
    }
    case 'SELECT_LAST_THIRTY_DAYS': {
      const date = { start: subDays(new Date(), 30), end: new Date() };
      return {
        ...localState,
        date,
        text: 'Last 30 days',
        customDateOpen: false,
      };
    }
    case 'SELECT_LAST_THREE_MONTHS': {
      const date = { start: subMonths(new Date(), 3), end: new Date() };
      return {
        ...localState,
        date,
        text: 'Last 3 months',
        customDateOpen: false,
      };
    }
    case 'SELECT_LAST_SIX_MONTHS': {
      const date = { start: subMonths(new Date(), 6), end: new Date() };
      return {
        ...localState,
        date,
        text: 'Last 6 months',
        customDateOpen: false,
      };
    }
    // case 'SELECT_ALL_TIME': {
    //   const date = { start: null, end: null };
    //   return {
    //     ...localState,
    //     date,
    //     text: 'All Time',
    //     customDateOpen: false,
    //   };
    // }
    case 'OPEN_DROPDOWN': {
      return { ...localState, open: true };
    }
    case 'CLOSE_DROPDOWN': {
      return { ...localState, open: false };
    }
    case 'OPEN_CUSTOM_DATE': {
      return { ...localState, customDateOpen: true };
    }
    default:
      return localState;
  }
};

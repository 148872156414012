import * as React from 'react';
import cx from 'classnames';

import { Button } from 'src/widgets/Button';
import { Modal } from 'src/widgets/Modal';
import { ContentUploader, IContent, IContentUploaderState } from 'src/widgets/ContentUploader';

import styles from './UploadModal.scss';

interface IProps {
  show: boolean;
  title: string;
  onRequestClose();
  postEndpoint: string;
  uploadFolder: string;

  onConfirmClick(contents: IContent[]);
}

interface IState {
  isUploading: boolean;
  contents: IContent[];
}

/**
 * @class
 * @extends {React.Component}
 */
export class UploadModal extends React.Component<IProps, IState> {
  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      contents: [],
      isUploading: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { show, title, onRequestClose, uploadFolder, postEndpoint } = this.props;
    const { contents, isUploading } = this.state;

    return (
      <Modal
        className={styles.UploadModal}
        title={title}
        show={show}
        onRequestClose={onRequestClose}
        footer={
          <>
            <Button
              label="Cancel"
              theme="light"
              onClick={this.onCancelClick}
              className={cx(styles.button, styles.left)}
            />
            <Button
              label="OK"
              onClick={this.onConfirmClick}
              disabled={contents.length === 0 || isUploading}
              className={styles.button}
            />
          </>
        }
      >
        {show && (
          <ContentUploader
            classNames={[styles.ContentUploader]}
            uploadFolder={uploadFolder}
            postEndpoint={postEndpoint}
            onStoreChange={this.onUploadedContentsChange}
          />
        )}
      </Modal>
    );
  }

  private onConfirmClick = () => {
    const { onConfirmClick } = this.props;
    const { contents } = this.state;

    onConfirmClick(contents);
  };

  private onCancelClick = () => {
    const { onRequestClose } = this.props;

    onRequestClose();
  };

  private onUploadedContentsChange = (uploaderState: IContentUploaderState) => {
    const { contents, isUploading } = uploaderState;

    this.setState({
      contents,
      isUploading,
    });
  };
}

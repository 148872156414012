import * as React from 'react';
import cx from 'classnames';
import { IBrand } from 'src/common/models/brand';

import ShareContentLibraryModal from './ShareContentLibraryModal';

import {
  GoogleDriveIcon,
  GoogleDriveVerifiedIcon,
  KeyboardArrowLeftIcon,
  ShareIcon,
  UploadIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';

import styles from './ContentFiltersHeader.scss';

const { useState } = React;

interface IProps {
  contentShareURL: string;
  activeBrand: IBrand;

  onClickAddContent();
  className?: string;
}

const ContentFiltersHeader: React.FunctionComponent<IProps> = React.memo((props) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const handleOpenShareModal = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  let driveURL = props.activeBrand ? props.activeBrand.drive_url : null;
  const verified = !!driveURL;
  if (!verified) {
    driveURL = `/api/google_drive/oauth?brand_id=${props.activeBrand.id}`;
  }

  return (
    <div className={cx(styles.ContentFiltersHeader, props.className)}>
      <a
        className={styles.googleDrive}
        href={driveURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {verified ? (
          <Button
            theme="info"
            label={
              <>
                <span className={styles.syncedLabel}>Synced with Google Drive</span>
                <KeyboardArrowLeftIcon size={14} className={styles.syncedArrow} />
              </>
            }
            icon={<GoogleDriveVerifiedIcon size={20} className={styles.syncedIcon} />}
            fullWidth={true}
          />
        ) : (
          <Button label="Sync with Google Drive" icon={<GoogleDriveIcon />} fullWidth={true} />
        )}
      </a>

      <div className={styles.separator} />

      <Button
        label="Share Content Library"
        icon={<ShareIcon size={20} />}
        onClick={handleOpenShareModal}
        fullWidth={true}
        className={styles.button}
        theme="info"
      />

      <Button
        label="Upload Content"
        icon={<UploadIcon size={20} />}
        onClick={props.onClickAddContent}
        fullWidth={true}
        theme="info"
      />

      <div className={styles.separator} />

      <ShareContentLibraryModal
        show={showShareModal}
        contentShareURL={props.contentShareURL}
        onRequestClose={handleCloseShareModal}
      />
    </div>
  );
});

export default ContentFiltersHeader;

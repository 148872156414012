import * as React from 'react';
import cx from 'classnames';
import { isUndefined, size, map, constant } from 'lodash';

import { Modal } from 'src/widgets/Modal';

import { IntroModalStep } from './IntroModalStep';
import { IIntroModal, IStepConfig } from './introModalModel';

const { useState, useEffect } = React;

import styles from './IntroModal.scss';

interface IProps extends IIntroModal {
  steps: IStepConfig[];
}

export const IntroModal: React.FunctionComponent<IProps> = (props) => {
  const {
    classNames,
    defaultShow,
    onFinish,
    onRequestClose,
    show: showProp,
    showCloseIcon,
    steps,
  } = props;

  const [showState, setShowState] = useState(defaultShow);
  const [step, setStep] = useState(0);
  const [contentHeights, setContentHeights] = useState({});
  const [bannerHeights, setBannerHeights] = useState({});
  const isControlled = !isUndefined(showProp);
  const show = isControlled ? showProp : showState;

  const getMaxHeight = (heights: { [key: string]: number }) => {
    return Math.max(...Object.values(heights)) || 0;
  };

  const getMaxContentHeight = () => getMaxHeight(contentHeights);

  const getMaxBannerHeight = () => getMaxHeight(bannerHeights);

  const handleResizeStepAtIndex = (index: number, height: number, bannerHeight: number) => {
    if (bannerHeight !== bannerHeights[index]) {
      const newBannerHeights = { ...bannerHeights };
      newBannerHeights[index] = bannerHeight;
      setBannerHeights(newBannerHeights);
    }

    if (height !== contentHeights[index]) {
      const newContentHeights = { ...contentHeights };
      newContentHeights[index] = height;
      setContentHeights(newContentHeights);
    }
  };

  useEffect(() => {
    // Reset the step to 0 after modal closes.
    if (!show && step !== 0) {
      setTimeout(() => setStep(0), 400);
    }
  }, [show, step]);

  const handleClose = () => {
    if (!isControlled) {
      setShowState(false);
    }
    if (onFinish) {
      onFinish();
    }
  };

  const handleRequestClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const numSteps = size(steps);

  const nextStep = () => {
    if (step < numSteps - 1) {
      setStep(step + 1);
    } else {
      handleClose();
    }
  };

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const contentHeight = getMaxContentHeight();
  const bannerHeight = getMaxBannerHeight();

  return (
    <Modal
      className={styles.IntroModal}
      dialogClassName={cx(styles.dialog, classNames)}
      showCloseIcon={showCloseIcon}
      onRequestClose={handleRequestClose}
      show={show}
      width={null}
    >
      <IntroModalStep
        key={String(show)}
        config={steps[step]}
        onClickNext={nextStep}
        onClickBack={goBack}
        step={step}
        numberOfSteps={numSteps}
        minHeight={contentHeight}
        minBannerHeight={bannerHeight}
        animated={true}
      />
      {map(steps, (config, index) => (
        <IntroModalStep
          key={index}
          classNames={[styles.hidden]}
          config={config}
          onClickNext={nextStep}
          step={step}
          numberOfSteps={numSteps}
          minBannerHeight={bannerHeight}
          onResize={(height, bannerHeight) => handleResizeStepAtIndex(index, height, bannerHeight)}
        />
      ))}
    </Modal>
  );
};

IntroModal.defaultProps = {
  defaultShow: false,
  showCloseIcon: false,
  onRequestClose: constant(false),
  classNames: [],
};

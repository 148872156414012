import * as React from 'react';
import { forEach, isEmpty, map, isNil, reduce, filter } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';

import { CampaignList } from './components/CampaignList/CampaignList';
import { CampaignDetail } from './components/CampaignDetail/CampaignDetail';
import { ICampaign, ICampaignGroupContentReviewInfo } from 'src/common/models/campaign';
import { IManager } from 'src/common/models/manager';

import { hasAccessToBrand } from './utils/managerUtils';
import { fetchCampaignData, fetchContentReviewDataForCampaign, ICampaignDataInfo } from './utils/campaignUtils';

const { useState, useEffect } = React;

import styles from './GroupContentReviewPage.scss';

type TCampaignData = { [key: string]: ICampaign };

interface IProps {
  apiEndpoint: string;
  brandId: number;
  user: IManager;

  viewRelationship(relationId: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const GroupContentReviewPage: React.FunctionComponent<IProps> = (props) => {
  const { apiEndpoint, brandId, user, viewRelationship } = props;

  const [campaignData, setCampaignData] = useState<TCampaignData>({});
  const [isFetchingCampaignData, setIsFetchingCampaignData] = useState(true);
  const [isFetchingGCRData, setIsFetchingGCRData] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaign>(null);
  const [hasNext, setHasNext] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCampaignClick = (campaignId: number) => {
    setSelectedCampaign(campaignData[campaignId]);
  };

  const userHasAccessToBrand: boolean = hasAccessToBrand(user, brandId);

  useEffect(() => {
    if (!brandId || !userHasAccessToBrand || !hasNext) {
      return;
    }
    (async () => {
      try {
        const data: ICampaignDataInfo = await fetchCampaignData(apiEndpoint, brandId, currentPage);
        if (!data || !data.campaigns) {
          console.error(`No campaigns for brand ${brandId} on page ${currentPage}.`);
          return;
        }
        const parsedData: TCampaignData = reduce(
          data.campaigns,
          (result: TCampaignData, campaign: ICampaign) => {
            result[campaign.id] = campaign;
            return result;
          },
          campaignData,
        );
        setHasNext(data.hasNext);
        setCurrentPage(currentPage + 1);
        setCampaignData(parsedData);
        if (!data.hasNext) {
          if (!selectedCampaign) {
            setSelectedCampaign(filter(Object.values(parsedData), ['deactivated', false])[0]);
          }
          setIsFetchingGCRData(true);
          setIsFetchingCampaignData(false);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [brandId, currentPage]);

  // TODO: Can you use reduce instead of forEach, then that’s what you use for setCampaignData so we only call
  //  the setter once
  useEffect(() => {
    if (isFetchingGCRData) {
      const campaignIds = Object.keys(campaignData);
      Promise.all(
        map(campaignIds, (campaignId) =>
          fetchContentReviewDataForCampaign(apiEndpoint, campaignId),
        ),
      )
        .then((gcrInfo) => {
          // Consolidate GCR info
          const allCampaignGCRInfo: { [key: string]: ICampaignGroupContentReviewInfo } = {};
          forEach(campaignIds, (campaignId, index) => {
            allCampaignGCRInfo[campaignId] = gcrInfo[index];
          });

          // Set new campaignData that has all the gcr_info
          const newCampaignData: TCampaignData = reduce(
            campaignData,
            (newCampaignData: TCampaignData, campaign: ICampaign, campaignId) => {
              newCampaignData[campaignId] = {
                ...campaign,
                gcr_info: allCampaignGCRInfo[campaignId],
              };
              return newCampaignData;
            },
            {},
          );
          setCampaignData(newCampaignData);

          // Set selectedCampaign
          if (
            !isEmpty(newCampaignData) &&
            !isEmpty(selectedCampaign) &&
            selectedCampaign.id &&
            (isNil(selectedCampaign.gcr_info) || isEmpty(selectedCampaign.gcr_info))
          ) {
            setSelectedCampaign(newCampaignData[selectedCampaign.id.toString()]);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsFetchingGCRData(false);
        });
    }
  }, [isFetchingGCRData]);

  const isFetchingData = isFetchingCampaignData || isFetchingGCRData;
  const selectedCampaignIsReady = !isFetchingData && selectedCampaign && selectedCampaign.gcr_info;

  return (
    <div className={styles.GroupContentReviewPage}>
      {!userHasAccessToBrand
        ? <h3>User does not have access to brand!</h3>
        : isFetchingData
          ? <LoadSpinner className={styles.loadSpinner}/>
          : <>
            <div className={styles.campaignListContainer}>
              <CampaignList
                campaigns={Object.values(campaignData)}
                selectedCampaign={selectedCampaign ? selectedCampaign.id : null}
                handleCampaignClick={handleCampaignClick}
              />
            </div>
            <div className={styles.campaignDetailContainer}>
              {!selectedCampaignIsReady ? (
                <LoadSpinner className={styles.loadSpinner}/>
              ) : (
                <CampaignDetail
                  apiEndpoint={apiEndpoint}
                  user={user}
                  campaign={selectedCampaign}
                  viewRelationship={viewRelationship}
                />
              )}
            </div>
          </>}
    </div>
  );
};

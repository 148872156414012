type IActionTypes =
  | '@favoriteListPage/SET_FAVORITE_LISTS'
  | '@favoriteListPage/ADD_FAVORITE_LIST'
  | '@favoriteListPage/UPDATE_FAVORITE_LIST'
  | '@favoriteListPage/REMOVE_FAVORITE_LIST'
  | '@favoriteListPage/SET_IS_FETCHING_FAVORITE_LISTS'
  | '@favoriteListPage/SET_IS_EDITING_FAVORITE_LIST'
  | '@favoriteListPage/SET_SELECTED_LIST_ID'
  | '@favoriteListPage/SET_SOCIAL_ACCOUNTS'
  | '@favoriteListPage/SET_IS_FETCHING_SOCIAL_ACCOUNTS'
  | '@favoriteListPage/INVITED_TO_CAMPAIGN'
  | '@favoriteListPage/REMOVE_FROM_FAVORITE_LIST';

import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';

/**
 * Action.
 */
export interface IFavoriteListPageAction {
  type: IActionTypes;
  payload?: {
    id?: number;
    accountIds?: number[];
    elementIds?: number[];
    socialAccounts?: ISocialAccount[];
    favoriteList?: IFavoriteList;
    favoriteLists?: IFavoriteList[];
    isFetchingFavoriteLists?: boolean;
    isEditingFavoriteList?: boolean;
    isInvitingToCampaign?: boolean;
  };
}

/* States */
export interface IFavoriteListPage {
  // brand id and campaign id
  brandId: number;
  managerId: number;
  campaign: ICampaign;
  org: IOrganization;
  isQa: boolean;

  // endpoints
  apiEndpoint: string;

  // list items and socialAccounts
  favoriteLists: IFavoriteList[];
  selectedListId: number;
  socialAccounts: ISocialAccount[];

  // loading flags
  isFetchingFavoriteLists: boolean;
  isEditingFavoriteList: boolean;
}

/**
 * Initial state.
 */
export const initialState: IFavoriteListPage = {
  brandId: null,
  managerId: null,
  campaign: null,
  org: null,
  isQa: false,

  apiEndpoint: null,

  favoriteLists: [],
  selectedListId: null,
  socialAccounts: [],

  isFetchingFavoriteLists: false,
  isEditingFavoriteList: false,
};

import * as React from 'react';
import { isNil } from 'lodash';

import { ArrowDownFilledIcon, CheckIcon } from 'src/icons';
import { Popover } from 'src/widgets/Popover';

import { Input } from './Input';

import styles from './BooleanEditInput.scss';

const { useCallback, useRef, useState, useEffect, useMemo } = React;

export interface IProps {
  defaultValue: boolean;
  nullStr?: string;
  trueStr?: string;
  falseStr?: string;
  onBlur?(value: boolean);
}

export const BooleanEditInput: React.FC<IProps> = (props) => {
  const {
    defaultValue,
    falseStr,
    nullStr,
    onBlur,
    trueStr,
    ...restProps
  } = props;

  const [show, setShow] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();

  const strValue = useMemo(() => {
    if (isNil(defaultValue)) {
      return nullStr;
    }
    return defaultValue ? trueStr : falseStr;
  }, [defaultValue, nullStr, trueStr, falseStr]);

  const handleChange = useCallback((value: boolean) => {
    if (onBlur) {
      onBlur(value);
    }
  }, [onBlur]);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <Input {...restProps} ref={inputRef} value={strValue} readOnly={true} />
      <Popover
        mountRef={inputRef}
        show={show}
        onRequestClose={() => handleChange(props.defaultValue)}
        minWidth={180}
        arrowPosition='end'
      >
        <div className={styles.list}>
          <div className={styles.item} onClick={() => handleChange(null)}>
            <span className={styles.noValueLabel}>{nullStr}</span>
            <span className={styles.checkmark}>
              {isNil(defaultValue) && <CheckIcon size={18} />}
            </span>
          </div>
          <div className={styles.item} onClick={() => handleChange(true)}>
            <span>{trueStr}</span>
            <span className={styles.checkmark}>
              {defaultValue && <CheckIcon size={18} />}
            </span>
          </div>
          <div className={styles.item} onClick={() => handleChange(false)}>
            <span>{falseStr}</span>
            <span className={styles.checkmark}>
              {!isNil(defaultValue) && !defaultValue && <CheckIcon size={18} />}
            </span>
          </div>
        </div>
      </Popover>
      <div className={styles.dropDownArrow}>
        <ArrowDownFilledIcon size={10} />
      </div>
    </>
  );
};

BooleanEditInput.defaultProps = {
  nullStr: 'No value',
  trueStr: 'True',
  falseStr: 'False',
};

import * as React from 'react';
import cx from 'classnames';

import { Notice } from './Notice';

import styles from './FeatureNotice.scss';

interface IProps {
  text: string;
  link: string;
  classNames?: string[];
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class FeatureNotice extends React.Component<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { text, link, classNames } = this.props;

    return (
      <Notice className={cx(styles.FeatureNotice, classNames)} type="feature" showDivider={true}>
        <div>
          <span>New: </span>
          {text}.
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Learn more{' '}
          </a>
        </div>
      </Notice>
    );
  }
}

import { assign } from 'lodash';
import { ActionTypes, IRecommendedPage, IRecommendedPageAction } from './models';

const reducer = (state: IRecommendedPage, action: IRecommendedPageAction): IRecommendedPage => {
  switch (action.type) {
    case ActionTypes.SET_SOCIAL_ACCOUNTS: {
      const { socialAccounts } = action.payload;

      return assign({}, state, {
        socialAccounts,
      });
    }

    case ActionTypes.SET_FAVORITE_LISTS: {
      const { favoriteLists } = action.payload;

      return assign({}, state, {
        favoriteLists,
      });
    }

    case ActionTypes.ADD_FAVORITE_LIST: {
      const { favoriteList } = action.payload;
      const { favoriteLists } = state;

      return assign({}, state, {
        favoriteLists: [...favoriteLists, favoriteList],
      });
    }

    case ActionTypes.UPDATE_FAVORITE_LIST: {
      const { id, favoriteList } = action.payload;
      const { favoriteLists } = state;
      const index = favoriteLists.findIndex((favoriteList) => favoriteList.id === id);

      return assign({}, state, {
        favoriteLists: [
          ...favoriteLists.slice(0, index),
          {
            ...favoriteList,
          },
          ...favoriteLists.slice(index + 1),
        ],
      });
    }

    default:
      return state;
  }
};

export default reducer;

import { IAdvertiserPublisherRelation } from 'src/common/models/advertiserPublisherRelation';

export default function getSource(relation: IAdvertiserPublisherRelation) {
  const referrer = relation.referrer;
  let source = 'Unknown';
  if (
    [
      'email_invite',
      'email_invite_follow_up',
      'marketplace_liked',
      'campaign-signup-autoreply',
    ].includes(referrer)
  ) {
    source = 'Invited by you';
  } else if (
    ['hybrid_email_invite', 'marketplace_hybrid_liked', 'campaign-signup-qa-autoreply'].includes(
      referrer,
    )
  ) {
    source = 'Invited by AspireIQ';
  } else if (['marketplace', 'featured_brand', 'broadcast_invite_2'].includes(referrer)) {
    source = 'Marketplace';
  } else if (referrer === 'new_project_offer') {
    source = 'Direct Offer';
  } else if (['auto_apply', 'auto_apply_proposal'].includes(referrer)) {
    source = 'Imported';
  } else if (referrer === 'personal_invite') {
    source = 'Personal Invite';
  }

  return source;
}

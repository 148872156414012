import * as React from 'react';
import cx from 'classnames';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Table } from 'src/widgets/Table';

import { map } from 'lodash';

import genericAddEventLog from 'src/common/utils/genericAddEventLog';

import { getColumns, GroupBySetting } from './columns';
import { useFetchBreakdownData } from '../useFetchAnalyzeData';

import { useAnalyze } from '../useAnalyze';
import { POST_TYPE_NAME } from 'src/common/models/postType';
import { NETWORK_NAME } from 'src/common/models/network';

import endpoints from 'src/common/config/endpoints';
import { ErrorBoundary } from 'src/utils';

const { useState, useCallback, useMemo } = React;

import styles from './BreakdownView.scss';

interface IProps {
  className?: string;
}

interface IBreakdownRow {
  post_type: string;
  tmv: string;
  reach: string;
  likes: string;
  comments: string;
  views: string;
  clicks: string;
  post_count: string;
  sales_amount: string;
  sales: string;
  image_url: string;
  creator: string;
  last_posted: string;
  datetime_posted: string;
  engagements: string;
  engagement_rate: string;
  unique_engagement_rate: string;
  social_account_name: string;
  country_code: string;
  brand_name: string;
  brands: string;
  campaign_name: string;
  campaigns: string;
  network: string;
  networks: [string];
  creator_count: string;
  cost: string;
  product_cost: string;
  owners: [string];
  coupons: [string];
  favorites: string;
  closeups: string;
  roi_pct: string;
  impressions: string;
  unique_impressions: string;
  cost_per_engagement: string;
  impression_cpm: string;
  taps: string;
  exits: string;
  text: string;
}

interface IBreakdownResponse {
  total_count: number;
  data: IBreakdownRow[];
}

interface IBreakdownParameters {
  groupBy: string;
  sortBy: string;
}

interface IBreakdownControlButtonProps {
  id: GroupBySetting;
  displayName: string;
  displayNamePlural: string;
}

const frontEndToBackendGroupMapping = {
  [GroupBySetting.post]: 'post',
  [GroupBySetting.creator]: 'creator',
  [GroupBySetting.network]: 'network',
  [GroupBySetting.aspirexPost]: 'post',
  [GroupBySetting.aspirexNetwork]: 'network',
  [GroupBySetting.member]: 'member',
};

/**
 * @type {React.FunctionComponent}
 */
const BreakdownView: React.FunctionComponent<IProps> = React.memo(props => {
  // Set up Constants
  const defaultSortOrder = '-date_posted';
  const emptyMessage = 'You have no data that matches those filters.';

  const { filters, apiEndpoint, isAspirex, aspirexAnalytics } = useAnalyze();
  const [groupBy, setGroupBy] = useState<GroupBySetting>(
    isAspirex ? GroupBySetting.aspirexPost : GroupBySetting.post
  );
  const [rowDisplayName, setRowDisplayName] = useState<string>('Posts');

  // Set up state + Prettier and eslint are fighting.
  /* eslint-disable */

  const [breakdownParameters, setBreakdownParameters] = useState<
    IBreakdownParameters
  >({
    groupBy: groupBy,
    sortBy: defaultSortOrder,
  });
  /* eslint-enable */

  // Front-end has different identifiers for grouping in order to allow
  // different columns to show even though the group by is actually the same
  // to backend
  const { loading, data, error } = useFetchBreakdownData<IBreakdownResponse>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/breakdown`,
    {
      ...breakdownParameters,
      groupBy: frontEndToBackendGroupMapping[breakdownParameters.groupBy],
    },
    isAspirex,
    filters
  );

  const columns = useMemo(() => {
    return getColumns(groupBy);
  }, [groupBy]);

  const onGroupBySelected = useCallback(
    (newGroupBy: GroupBySetting, displayName: string) => {
      setBreakdownParameters({ ...breakdownParameters, groupBy: newGroupBy });
      setGroupBy(newGroupBy);
      setRowDisplayName(displayName);
      genericAddEventLog(
        'analyze_breakdown_group_by',
        {
          previous_group_by: groupBy,
          current_group_by: newGroupBy,
        },
        aspirexAnalytics
      );
    },
    [aspirexAnalytics, breakdownParameters, groupBy]
  );

  /**
   * Massage data into state expected by Table
   */
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return map(data.data, (breakdownRow, index) => {
      return {
        id: index.toString(),
        _raw: breakdownRow,
        post_type: POST_TYPE_NAME[breakdownRow.post_type],
        tmv: parseInt(breakdownRow.tmv, 10),
        reach: parseInt(breakdownRow.reach, 10),
        likes: parseInt(breakdownRow.likes, 10),
        views: parseInt(breakdownRow.views, 10),
        clicks: parseInt(breakdownRow.clicks, 10),
        post_count: parseInt(breakdownRow.post_count, 10),
        comments: parseInt(breakdownRow.comments, 10),
        engagement_pct: parseFloat(breakdownRow.engagement_rate),
        sales: parseInt(breakdownRow.sales, 10),
        sales_amount: parseFloat(breakdownRow.sales_amount),
        media: [breakdownRow.image_url],
        creator: breakdownRow.creator,
        last_posted: breakdownRow.last_posted,
        datetime_posted: breakdownRow.datetime_posted,
        engagement_rate: parseFloat(breakdownRow.engagement_rate),
        engagements: parseInt(breakdownRow.engagements, 10),
        social_account_name: breakdownRow.social_account_name,
        country_code: breakdownRow.country_code,
        brand_name: breakdownRow.brand_name,
        brands: breakdownRow.brands,
        campaign_name: breakdownRow.campaign_name,
        campaigns: breakdownRow.campaigns,
        network: NETWORK_NAME[breakdownRow.network],
        networks: `${breakdownRow.networks ? breakdownRow.networks.join(', ') : ''}`,
        creator_count: parseInt(breakdownRow.creator_count, 10),
        cost: parseFloat(breakdownRow.cost),
        product_cost: parseFloat(breakdownRow.product_cost),
        owners: `${breakdownRow.owners ? breakdownRow.owners.join(', ') : ''}`,
        coupons: `${breakdownRow.coupons ?  breakdownRow.coupons.join(', ') : ''}`,
        favorites: parseInt(breakdownRow.favorites, 10),
        closeups: parseInt(breakdownRow.closeups, 10),
        roi_pct: parseFloat(breakdownRow.roi_pct) / 100,
        impressions: parseInt(breakdownRow.impressions, 10),
        unique_impressions: parseInt(breakdownRow.unique_impressions, 10),
        unique_engagement_rate: parseFloat(breakdownRow.unique_engagement_rate),
        cost_per_engagement: parseFloat(breakdownRow.cost_per_engagement),
        impression_cpm: parseFloat(breakdownRow.impression_cpm),
        taps: parseInt(breakdownRow.taps, 10),
        exits: parseInt(breakdownRow.exits, 10),
        text: breakdownRow.text,
      };
    });
  }, [data]);

  /**
   * Handle the group by control actions
   */
  const buttons: IBreakdownControlButtonProps[] = [
    {
      id: isAspirex ? GroupBySetting.aspirexPost : GroupBySetting.post,
      displayName: '🖼 Post',
      displayNamePlural: '🖼 Posts',
    },
    {
      id: isAspirex ? GroupBySetting.member : GroupBySetting.creator,
      displayName: '👩 Creator',
      displayNamePlural: '👩 Creators',
    },
    {
      id: isAspirex ? GroupBySetting.aspirexNetwork : GroupBySetting.network,
      displayName: '🖥 Network',
      displayNamePlural: '🖥 Networks',
    },
  ];

  /**
   * Actually renders the actions.
   *
   * @return {JSX.Element}
   */
  function renderActions(groupBy): JSX.Element {
    return (
      <div className={cx(props.className)}>
        <span className={styles.GroupByLabel}>Grouping by</span>
        {map(buttons, buttonData => (
          <button
            className={cx(
              styles.GroupByButton,
              groupBy === buttonData.id ? styles.active : ''
            )}
            key={buttonData.id}
            onClick={() =>
              onGroupBySelected(buttonData.id, buttonData.displayNamePlural)
            }
          >
            {buttonData.displayName}
          </button>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <Notice className={styles.notice} type="error">
        There is an error when trying to fetch your breakdowns. Please try
        refreshing and contact support@aspireiq.com if the problem continues.
      </Notice>
    );
  }

  const exportCallback = (exportOptions) => {
    genericAddEventLog(
      'analyze_breakdown_export',
      {
        export_columns: exportOptions.headers,
        current_group_by: groupBy,
      },
      aspirexAnalytics
    );
  };

  return (
    <ErrorBoundary>
      <div className={cx(styles.BreakdownView, props.className)}>
        {!loading && (
          <Table
            ref={null}
            data={tableData}
            columns={columns}
            disabled={false}
            emptyMessage={emptyMessage}
            paddingBottom={0}
            rowDisplayName={rowDisplayName}
            headerActions={renderActions(groupBy)}
            config={{
              allowSearch: false,
              configurableColumns: true,
              rowHeight: 58,
              pageSize: 20,
              striped: true,
              rowBorder: true,
              selectable: false,
            }}
            exportable={true}
            onExportCallback={exportCallback}
          />
        )}
        {loading && <LoadSpinner centered={true} />}
      </div>
    </ErrorBoundary>
  );
});

export default BreakdownView;

import * as React from 'react';

import {
  ProfileCard,
  SummaryCard,
} from '../Card';

import styles from './Content.scss';

export const BlogContent: React.FC = () => (
  <div className={styles.summary}>
    <ProfileCard className={styles.ProfileCard} />
    <SummaryCard className={styles.SummaryCard} />
  </div>
);

import { TPostType } from 'src/common/models/postType';

import networkConfig from 'src/common/config/networkConfig';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';

export default function(count: number, postType: TPostType): string {
  const network = getNetworkByPostType(postType);
  let networkName = networkConfig[network] && networkConfig[network].networkName;
  if (network === 'additional_images' || network === 'additional_videos') {
    networkName = 'Additional';
  }

  let description = null;

  const plural = count !== 1;
  switch (postType) {
    case 'youtube_mention':
    case 'blog_mention':
      description = networkName + ' Mention' + (plural ? 's' : '');
      break;
    case 'additional_images':
      description = 'Additional Image' + (plural ? 's' : '');
      break;
    case 'additional_videos':
    case 'instagram_video':
      description = networkName + ' Video' + (plural ? 's' : '');
      break;
    case 'youtube_dedicated':
      description = 'Dedicated Youtube Video' + (plural ? 's' : '');
      break;
    case 'instagram':
    case 'twitter_post':
    case 'facebook_post':
      description = networkName + ' Post' + (plural ? 's' : '');
      break;
    case 'instagram_story':
      description = plural ? 'Instagram Stories' : 'Instagram Story';
      break;
    case 'blog_dedicated':
      description = 'Dedicated Blog Post' + (plural ? 's' : '');
      break;
    case 'pinterest':
      description = 'Pinterest Pin' + (plural ? 's' : '');
      break;
    case 'tiktok_video':
      description = 'TikTok Video' + (plural ? 's' : '');
      break;
    case 'other':
      description = 'Piece' + (plural ? 's' : '') + ' of custom work';
      break;
  }

  return count + ' ' + description;
}

import { connect } from 'react-redux';
import { IConnectSearchPage, ICreatorSearchOptions, ICreatorSearchFilters } from '../models';
import { updateSearchFilters } from '../redux/textSearchActions';
import { CreatorSearchFilters } from '../TextSearchFilters/CreatorSearchFilters';
import { textSearchOptionsSelector } from '../redux/selectors';
import { hasFeature } from 'src/common/utils/organizationHasFeature';

interface IState {
  options: ICreatorSearchOptions;
  filters: ICreatorSearchFilters;
  selfServeExperiment: boolean;
}

interface IDispatchProps {
  onChange(newFilters: ICreatorSearchFilters);
}

const mapStateToProps = (state: IConnectSearchPage) => ({
  options: textSearchOptionsSelector(state),
  filters: state.textSearch,
  selfServeExperiment: hasFeature(state.external.organization, 'self_serve_experiment')
});

const mapDispatchToProps = {
  onChange: updateSearchFilters,
};

export const ConnectedTextSearch = connect<IState, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreatorSearchFilters);

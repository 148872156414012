import { createSelector } from 'reselect';
import { size } from 'lodash';
import { IContentUploader, IContent } from './contentUploaderModel';

export const limitReachedSelector = createSelector(
  (state: IContentUploader) => state.contents,
  (state: IContentUploader) => state.maxContents,
  (contents: IContent[], maxContents: number) => {
    return maxContents && size(contents) >= maxContents;
  },
);

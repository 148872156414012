import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';
import { isFunction, isNull } from 'lodash';

import { CloseIcon, ArrowLeftIcon } from 'src/icons';
import { IconButton } from 'src/widgets/Button';

import styles from './Modal.scss';

export interface IModalProps {
  className?: string;
  contentClassName?: string;
  dialogClassName?: string;
  dialogRef?: React.RefObject<HTMLDivElement>;
  disableCloseOnMaskClick?: boolean;
  footer?: JSX.Element;
  modalRef?: React.RefObject<HTMLDivElement>;
  onRequestBack?();
  onRequestClose(e?: React.MouseEvent<HTMLDivElement, MouseEvent>);
  show: boolean;
  showCloseIcon?: boolean;
  title?: string;
  width?: number;
}

type TDefaultProp =
  | 'contentClassName'
  | 'dialogClassName'
  | 'disableCloseOnMaskClick'
  | 'footer'
  | 'onRequestBack'
  | 'showCloseIcon'
  | 'title'
  | 'width'
;

/**
 * @class
 * @extends {React.Component}
 */
export class Modal extends React.Component<IModalProps> {
  public static defaultProps: Pick<IModalProps, TDefaultProp> = {
    title: null,
    footer: null,
    width: 840,

    // set onRequestBack as null by default
    // and do not render back button if it's none
    onRequestBack: null,
    showCloseIcon: true,

    contentClassName: null,
    dialogClassName: null,

    disableCloseOnMaskClick: false,
  };

  /**
   * @inheritdoc
   */
  constructor(props: IModalProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    // skip server side rendering
    if (typeof window === 'undefined') {
      return null;
    }

    const {
      children,
      className,
      contentClassName,
      dialogClassName,
      dialogRef,
      disableCloseOnMaskClick,
      footer,
      modalRef,
      onRequestBack,
      show,
      showCloseIcon,
      title,
      width,
    } = this.props;

    return ReactDOM.createPortal(
      <div
        className={cx(styles.Modal, className, {
          [styles.show]: show,
        })}
        ref={modalRef}
      >
        <div
          className={styles.mask}
          onClick={(e) => {
            if (!disableCloseOnMaskClick) {
              this.closeModal(e);
            }
          }}
        />
        <div
          className={cx(styles.dialog, dialogClassName)}
          style={{
            width: isNull(width) ? undefined : `${width}px`,
          }}
          ref={dialogRef}
        >
          {title && <div className={styles.title}>{title}</div>}
          {showCloseIcon && (
            <IconButton
              icon={<CloseIcon size={20} />}
              className={styles.closeButton}
              onClick={this.closeModal}
            />
          )}
          {isFunction(onRequestBack) && (
            <IconButton
              icon={<ArrowLeftIcon size={22} />}
              className={styles.backButton}
              onClick={onRequestBack}
            />
          )}
          <div className={cx(styles.content, contentClassName)}>
            {React.Children.toArray(children)}
          </div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>,
      document.body,
    );
  }

  /**
   * @private
   * Closes the modal.
   */
  private closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onRequestClose } = this.props;
    if (isFunction(onRequestClose)) {
      onRequestClose(e);
    }
  };
}

import * as React from 'react';

import { Image } from 'src/widgets/Image';
import { Content, IContentProps } from './Content';

import styles from './YouTubeContent.scss';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class YouTubeContent extends React.PureComponent<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames, ...props } = this.props;

    return (
      <Content
        content={content}
        classNames={classNames.concat(styles.YouTubeContent)}
        downloadable={false}
        showInfo={true}
        {...props}
      >
        <Image className={styles.image} src={content.imageLink} />
      </Content>
    );
  }
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const ImageIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="M20.9,11.6c-0.1-0.2-0.3-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.6,0.3l-5.6,8.4l-3.2-3.9c-0.1-0.2-0.3-0.3-0.6-0.2
      c-0.2,0-0.4,0.1-0.5,0.3l-4.6,7.6c-0.1,0.2-0.1,0.5,0,0.7c0.1,0.2,0.3,0.3,0.6,0.3h19c0.2,0,0.4-0.1,0.6-0.3
      c0.1-0.2,0.2-0.4,0.1-0.6L20.9,11.6z M6.9,23.3l3.5-5.8l3.2,3.8c0.1,0.2,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.3l5.4-8.1l3.8,10.1
      H6.9z"
    />
    <path
      d="M8,10.9c1.6,0,3-1.3,3-3S9.7,5,8,5s-3,1.3-3,3S6.4,10.9,8,10.9z M8,6.4c0.9,0,1.6,0.7,1.6,1.6S8.9,9.6,8,9.6
      C7.1,9.6,6.4,8.9,6.4,8S7.1,6.4,8,6.4z"
    />
    <path
      d="M26.5,0h-23C1.6,0,0,1.6,0,3.5v23C0,28.4,1.6,30,3.5,30h23c1.9,0,3.5-1.6,3.5-3.5v-23C30,1.6,28.4,0,26.5,0z
      M28.7,26.5c0,1.2-1,2.2-2.2,2.2h-23c-1.2,0-2.2-1-2.2-2.2v-23c0-1.2,1-2.2,2.2-2.2h23c1.2,0,2.2,1,2.2,2.2V26.5z"
    />
  </SvgIcon>
));

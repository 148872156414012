import { filter, size, includes, chain, concat } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';

import addEventLog from 'src/common/utils/addEventLog';

import { IContentReview, GroupContentReviewMessageType } from 'src/common/models/contentReview';
import { IManager } from 'src/common/models/manager';
import { hasBrandPermission } from '../utils/managerUtils';

import {
  isApproved,
  isCompleted,
  isGCRPending,
  isRejected,
  isSoftApproved,
  isSoftRejected,
} from 'src/common/utils/contentReviewUtils';
import endpoints from 'src/common/config/endpoints';

const SOCIAL_POST_TYPES = [
  'youtube_mention',
  'youtube_dedicated',
  'instagram',
  'instagram_video',
  'instagram_story',
  'twitter_post',
  'facebook_post',
  'pinterest',
  'tiktok_video',
  'snapchat_story',
];

export function isPostForSocialNetwork(contentReview: IContentReview): boolean {
  return includes(SOCIAL_POST_TYPES, contentReview.product.post_type);
}

export function getNumberOfUnreadMessages(contentReview: IContentReview, user: IManager): number {
  return size(
    filter(contentReview.group_content_review_messages, (message) => {
      return !message.read_by.includes(user.id);
    }),
  );
}

export function getDaysSinceUpload(contentReview: IContentReview): number {
  return differenceInCalendarDays(new Date(), new Date(contentReview.date_first_upload * 1000));
}

export function sortContentReviews(
  contentReviews: IContentReview[],
  isBrandUser: boolean,
): IContentReview[] {
  if (isBrandUser) {
    return sortContentReviewsBrand(contentReviews);
  } else {
    return sortContentReviewsMT(contentReviews);
  }
}

function sortContentReviewsBrand(contentReviews: IContentReview[]): IContentReview[] {
  const waitingForApproval = chain(contentReviews)
    .filter((contentReview) => isGCRPending(contentReview))
    .orderBy((contentReview) => contentReview.automatic_approval_expire_time_ts, 'desc')
    .value();
  const rejected = filter(
    contentReviews,
    (contentReview) => isSoftRejected(contentReview) || isRejected(contentReview),
  );
  const softApproved = filter(contentReviews, (contentReview) => isSoftApproved(contentReview));
  const approved = filter(contentReviews, (contentReview) => isApproved(contentReview));
  const completed = filter(contentReviews, (contentReview) => isCompleted(contentReview));

  return concat(waitingForApproval, rejected, softApproved, approved, completed);
}

function sortContentReviewsMT(contentReviews: IContentReview[]): IContentReview[] {
  const brandRejected = chain(contentReviews)
    .filter((contentReview) => isSoftRejected(contentReview))
    .orderBy((contentReview) => contentReview.automatic_approval_expire_time_ts, 'desc')
    .value();
  const mtRejected = filter(contentReviews, (contentReview) => isRejected(contentReview));
  const pending = filter(contentReviews, (contentReview) => isGCRPending(contentReview));
  const softApproved = filter(contentReviews, (contentReview) => isSoftApproved(contentReview));
  const approved: IContentReview[] = filter(contentReviews, (contentReview) =>
    isApproved(contentReview),
  );
  const completed: IContentReview[] = filter(contentReviews, (contentReview) =>
    isCompleted(contentReview),
  );

  return concat(brandRejected, mtRejected, pending, softApproved, approved, completed);
}

export const fetchContentReviews = async (
  apiEndpoint: string,
  campaignId: number,
  page: number,
  signal: AbortSignal,
): Promise<IContentReview[]> => {
  const resp = await fetch(
    `${apiEndpoint}/${endpoints.contentReviewEndpoint}?campaign_id=${campaignId}&page=${page}`,
    {
      signal,
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    // TODO: fix
    return json.data;
  } else {
    throw new Error();
  }
};

export const approveContentReview = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  brandApproveComment: string,
): Promise<IContentReview> => {

  const numberOfGuidelinesComplete = filter(contentReview.product.creator_guideline_checkoff, Boolean).length;

  addEventLog('content_review_approve_content', {
    number_of_guidelines_incomplete:
      contentReview.product.creator_guideline_checkoff.length - numberOfGuidelinesComplete,
    number_of_guidelines_complete: numberOfGuidelinesComplete,
    deliverable_type: contentReview.deliverable_type,
    content_review_id: contentReview.id,
    days_since_upload: getDaysSinceUpload(contentReview),
    group_review: true
  });

  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/approve`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        brand_approve_comment: brandApproveComment,
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

export type TRejectFunction = (
  apiEndpoint: string,
  contentReview: IContentReview,
  brandGuidelineCheckoff: boolean[],
  brandRejectComment: string,
  bypass: boolean,
) => Promise<IContentReview>;

export const mtRejectContentReview = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  brandGuidelineCheckoff: boolean[],
  brandRejectComment: string,
  bypass: boolean,
): Promise<IContentReview> => {

  addEventLog('content_review_request_changes', {
    number_of_changes_requested : filter(brandGuidelineCheckoff, Boolean).length,
    total_number_guidelines : brandGuidelineCheckoff.length,
    deliverable_type: contentReview.deliverable_type,
    content_review_id: contentReview.id,
    days_since_upload: getDaysSinceUpload(contentReview),
    group_review: true
  });

  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/reject`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        brand_guideline_checkoff: brandGuidelineCheckoff,
        brand_reject_comment: brandRejectComment,
        gcr_bypass_soft_reject: bypass,
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

export const brandRejectContentReview = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  brandGuidelineCheckoff: boolean[],
  brandRejectComment: string,
): Promise<IContentReview> => {

  addEventLog('content_review_brand_request_changes_gcr', {
    number_of_changes_requested : filter(brandGuidelineCheckoff, Boolean).length,
    total_number_guidelines : brandGuidelineCheckoff.length,
    deliverable_type: contentReview.deliverable_type,
    content_review_id: contentReview.id,
    days_since_upload: getDaysSinceUpload(contentReview),
    group_review: true
  });

  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/soft_reject`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        brand_guideline_checkoff: brandGuidelineCheckoff,
        brand_reject_comment: brandRejectComment,
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

export const revertReject = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  comment: string,
): Promise<IContentReview> => {
  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/revert_reject`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        project_id: contentReview.project_id,
        comment,
      }),
    }
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

export const commentContentReview = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  messageType: GroupContentReviewMessageType,
  comment: string,
  isBrandUser: boolean,
  brandId: number,
  user: IManager,
): Promise<IContentReview> => {

  addEventLog('content_review_comment_gcr', {
    deliverable_type: contentReview.deliverable_type,
    content_review_id: contentReview.id,
    comment: comment,
    message_type: messageType,
    brand_user: isBrandUser,
    gcr_role: hasBrandPermission(user, brandId, 3) ? 'Write' : 'Comment',
    group_review: true
  });


  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/comment`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        project_id: contentReview.project_id,
        comment,
        userId: user.id,
        messageType,
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

export const markContentReviewCommentsRead = async (
  apiEndpoint: string,
  contentReview: IContentReview,
  userId: number,
): Promise<IContentReview> => {
  const resp = await fetch(
    /* tslint:disable-next-line */
    `${apiEndpoint}/${endpoints.projectEndpoint}/${contentReview.project_id}/content_review/${contentReview.id}/comment_read`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        iteration: contentReview.iteration,
        userId,
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

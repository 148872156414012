import * as React from 'react';

import { useMobileStatus } from 'src/utils/hooks';

import { IInboxTableDesktopProps, InboxTableDesktop } from './InboxTable/InboxTableDesktop';
import { IInboxTableMobileProps, InboxTableMobile } from './InboxTable/InboxTableMobile';
type IProps = IInboxTableDesktopProps & IInboxTableMobileProps;

/**
 * @type {React.FunctionComponent}
 */
export const InboxTable: React.FunctionComponent<IProps> = React.memo((props) => {
  const mobileType = useMobileStatus();

  if (mobileType) {
    return <InboxTableMobile {...props} />;
  }

  return <InboxTableDesktop {...props} />;
});

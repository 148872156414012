import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map } from 'lodash';

import { LazyImage } from 'src/widgets/Image';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';

import endpoints from 'src/common/config/endpoints';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { useAnalyze } from '../../../useAnalyze';
import { useFetchSummaryData } from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

import styles from './TopPostsSection.scss';

export interface ITopPostsData {
  network: TNetworkIdentifier;
  image_url: string;
  link: string;
  likes: number;
  engagements: number;
  reach: number;
  impressions: number;
  profile_image_url: string;
}

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const TopPostsSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const { loading, data, error } = useFetchSummaryData<ITopPostsData[]>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/top-posts`,
      isAspirex,
      filters
    );
    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    const openLink = (url: string) => {
      window.open(url, '_blank');
    };

    const dataReturnedEmpty = data && data.length === 0;

    return (
      <div className={cx(styles.TopPostsSection, props.className)}>
        {loading && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>Top Post{data && data.length > 1 ? 's' : ''}</h3>
              </div>
            </div>
            <div className={styles.main}>
              {map(data, (record) => (
                <div key={record.link} className={styles.item}>
                  <div
                    className={styles.imageSection}
                    onClick={() => openLink(record.link)}
                  >
                    <LazyImage
                      className={styles.image}
                      src={record.image_url}
                    />
                    <LazyImage
                      className={styles.profileImage}
                      src={record.profile_image_url}
                    />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.likes}>
                      {numeral(record.likes).format('0.[0]a').toUpperCase()}
                      &nbsp;Likes
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.type}>Engagements</div>
                      <div className={styles.count}>
                        {numeral(record.engagements).format('0.[0]a').toUpperCase()}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.type}>Impressions</div>
                      <div className={styles.count}>
                        {numeral(record.impressions).format('0.[0]a').toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

TopPostsSection.defaultProps = {
  className: null,
};

import { map } from 'lodash';

import getSocialAccounts from './getSocialAccounts';

import { IAdvertiserPublisherRelation } from 'src/common/models/advertiserPublisherRelation';

export default function getNetworks(relation: IAdvertiserPublisherRelation, $state: any) {
  const accounts = getSocialAccounts(relation);

  return map(accounts, (account) => ({
    type: account.network_identifier,
    link:
      account.link ||
      $state.href('manage.relationship.main.mainView.creator_detail', {
        relationId: relation.id,
        accountId: account.id,
      }),
  }));
}

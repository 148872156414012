import * as React from 'react';
import cx from 'classnames';

import { Toggle } from 'src/widgets/Checkbox';

import styles from './AccessUsageCell.scss';

const { useState, useEffect } = React;

interface IProps {
  selectedForUse: boolean;
  pending: boolean;
  onToggle(enabled: boolean);
}

export const AccessUsageCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    onToggle,
    pending,
    selectedForUse,
  } = props;

  const [toggleChecked, setToggleChecked] = useState<boolean>(selectedForUse);

  useEffect(() => {
    if (selectedForUse !== toggleChecked) {
      const timeout = setTimeout(() => {
        setToggleChecked(selectedForUse);
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [selectedForUse, toggleChecked]);

  useEffect(() => {
    setToggleChecked(selectedForUse);
  }, [selectedForUse]);

  const handleToggle = () => {
    const checked = !toggleChecked;
    setToggleChecked(checked);
    onToggle(checked);
  };

  return (
    <div className={styles.AccessUsageCell}>
      <Toggle
        classNames={[
          styles.toggle,
          cx({ [styles.pending]: selectedForUse && pending }),
        ]}
        checked={toggleChecked}
        onChange={handleToggle}
      />
      {selectedForUse && !pending && (
        <span className={styles.toggleTitle}>Enabled</span>
      )}
      {selectedForUse && pending && (
        <>
          <span className={styles.toggleTitle}>Enable pending...</span>
          <div className={styles.toggleDescription}>This creator will be available next month</div>
        </>
      )}
      {!selectedForUse && !pending && (
        <span className={cx(styles.toggleTitle, styles.disabled)}>Disabled</span>
      )}
      {!selectedForUse && pending && (
        <>
          <span className={cx(styles.toggleTitle, styles.disabled)}>Disable pending...</span>
          <div className={styles.toggleDescription}>
            You will lose access to this creator next month
          </div>
        </>
      )}
    </div>
  );
});

import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  Button,
  SubmitButton,
} from 'src/widgets/Button';
import { Checkbox } from 'src/widgets/Checkbox';
import { DatePicker } from 'src/widgets/DatePicker';
import { Input } from 'src/widgets/Input';
import { Notice } from 'src/widgets/Notice';
import { RadioGroup } from 'src/widgets/RadioGroup';
import { Select } from 'src/widgets/Select';
import {
  ITimeInput,
  TimePicker,
} from 'src/widgets/TimePicker';
import {
  Toast,
  IToastRefHandles,
} from 'src/widgets/Toast';

import endpoints from 'src/common/config/endpoints';

import styles from './ShopifyDiscountCoupon.scss';

type TMinRequirment = 'none' | 'min-purchase-amount' | 'min-quantity';
type TDiscountType = 'percentage' | 'fixed_amount';
type TDefaultProp = 'classNames';

interface IProps {
  // api endpoint
  creatorName: string;
  apiEndpoint: string;
  projectId: number;
  shopifyStoreId: number;

  onSuccess(response);
  onCancel();

  classNames?: string[];
}

interface IState {
  discountCode: string;

  discountType: TDiscountType;
  selectedDiscountTypeOptionIndex: number;
  discountValue: string;

  minRequirement: TMinRequirment;
  selectedMinRequirementOptionIndex: number;
  minPurchaseAmount: string;
  minQuantity: string;

  usageLimitChecked: boolean;
  usageLimit: string;
  oneUnitUsagePerCustomerChecked: boolean;

  startDate: Date;
  endDate: Date;
  startTimeInput: ITimeInput;
  endTimeInput: ITimeInput;

  isCreatingShopifyDiscountCoupon: boolean;
  errorMessage: string;
  showError: boolean;
}

const initialState = {
  discountCode: '',

  discountType: 'percentage',
  selectedDiscountTypeOptionIndex: 0,
  discountValue: '',

  minRequirement: 'none',
  selectedMinRequirementOptionIndex: 0,
  minPurchaseAmount: '',
  minQuantity: '',

  usageLimitChecked: false,
  usageLimit: '',
  oneUnitUsagePerCustomerChecked: false,

  startDate: null,
  endDate: null,
  startTimeInput: {
    hourIn24HourFormat: null,
    minuteIn24HourFormat: null,
  },
  endTimeInput: {
    hourIn24HourFormat: null,
    minuteIn24HourFormat: null,
  },

  isCreatingShopifyDiscountCoupon: false,
  errorMessage: '',
  showError: false,
} as IState;

/**
 * @private
 * Checks if value is in the range [0, 100]
 *
 * @param {Number} value to be checked
 *
 * @return {Boolean}
 */
const isPercentage = (value: string): boolean => {
  const valueInt = parseInt(value, 10);

  return 0 <= valueInt && valueInt <= 100;
};

/**
 * Parses the local Date object and
 * return the timezone in the
 * form of a string.
 * Eg: Pacific Standard Time
 *
 * @return {String}
 */
const getUserTimezone = (): string => {
  return new Date().toString().replace(/.*[(](.*)[)].*/, '$1');
};

/**
 * @private
 * Convert Date object to
 * epoch seconds.
 *
 * @param {Date} date which is required to be converted
 *
 * @return {Number}
 */
const getEpochSeconds = (date: Date): number => {
  return date.getTime() / 1000;
};

/**
 * @class
 * @extends {React.Component}
 */
export class ShopifyDiscountCoupon extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private toastRef: React.RefObject<IToastRefHandles>;
  private ref: React.RefObject<HTMLDivElement>;
  private userTimeZone: string;

  /**
   * @inheritdoc
   */
  public constructor(props) {
    super(props);

    this.toastRef = React.createRef();
    this.ref = React.createRef();
    this.userTimeZone = getUserTimezone();

    this.state = initialState;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { creatorName, onCancel, classNames } = this.props;
    const {
      usageLimitChecked,
      oneUnitUsagePerCustomerChecked,
      discountCode,
      selectedDiscountTypeOptionIndex,
      discountValue,
      selectedMinRequirementOptionIndex,
      usageLimit,
      isCreatingShopifyDiscountCoupon,
      errorMessage,
      showError,
    } = this.state;

    return (
      <div className={cx(classNames.concat(styles.ShopifyDiscountCoupon))} ref={this.ref}>
        <div className={styles.title}>Create a discount code for {creatorName}</div>
        {!isEmpty(errorMessage) && (
          <Notice className={styles.message} type="error" showDivider={false}>
            {errorMessage}
          </Notice>
        )}
        <div className={styles.item}>
          <div className={styles.subtitle}>Discount code</div>
          <Input
            className={styles.input}
            placeholder="eg. SPRINGSALE"
            value={discountCode}
            hasError={showError && !this.isValidDiscountCode()}
            errorMessage={'Enter a discount code'}
            onChange={this.onDiscountCodeChange}
          />
          <div className={styles.hint}>Customers will enter this code at checkout</div>
        </div>

        <div className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.subtitle}>Options</div>
          <div className={styles.options}>
            <div className={styles.discountType}>
              <div className={styles.subheading}>
                Discount type ({this.state.discountType === 'percentage' && '%'}
                {this.state.discountType === 'fixed_amount' && 'currency of your store'}
                ):
              </div>
              <Select
                hintText="Select Network..."
                options={this.getDiscountTypeOptions()}
                selectedIndex={selectedDiscountTypeOptionIndex}
                onChange={this.onSelectedDiscountTypeChange}
              />
            </div>
            <div className={styles.discountValue}>
              <div className={styles.subheading}>Discount value:</div>
              <Input
                type="integer"
                className={styles.input}
                placeholder={this.state.discountType === 'fixed_amount' ? '0.00' : '%'}
                value={discountValue}
                hasError={showError && !this.isValidDiscountValue()}
                errorMessage={'Enter a valid discount value'}
                onChange={this.onDiscountValueChange}
              />
            </div>
          </div>
        </div>

        <div className={styles.divider} />

        <div>
          <div className={styles.item}>
            <div className={styles.subtitle}>Minimum requirements</div>
            <RadioGroup
              selectedIndex={selectedMinRequirementOptionIndex}
              options={this.getMinRequirementOptions()}
              onChange={this.onSelectedMinRequirementChange}
            />
          </div>
        </div>

        <div className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.subtitle}>Usage limits</div>
          <Checkbox
            checked={usageLimitChecked}
            label="Limit number of times this discount can be used in total"
            onChange={this.toggleUsageLimitChecked}
          />
          {usageLimitChecked && (
            <Input
              className={styles.editUsageCount}
              value={usageLimit === null ? '' : usageLimit.toString()}
              hasError={showError && !this.isValidUsageLimit()}
              errorMessage={'Enter an usage limit'}
              onChange={this.onUsageLimitChange}
              placeholder="Enter number..."
            />
          )}
          <Checkbox
            checked={oneUnitUsagePerCustomerChecked}
            label="Limit to one use per customer"
            onChange={this.toggleOneUnitUsagePerCustomerChecked}
          />
        </div>

        <div className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.subtitle}>Active dates ({this.userTimeZone}):</div>

          <div className={styles.startDateTime}>
            <div className={styles.startDate}>
              <div className={styles.label}>Start date:</div>
              <DatePicker
                hasError={showError && !this.isValidStartDate()}
                errorMessage={'Enter a valid start date'}
                onDateSelected={this.onStartDateSelected}
              />
            </div>
            <div className={styles.startTime}>
              <div className={styles.label}>Start time:</div>
              <TimePicker
                hasError={showError && !this.isValidStartTime()}
                errorMessage={'Enter a valid start time'}
                onTimeSelected={this.onStartTimeSelected}
              />
            </div>
          </div>
          <div className={styles.endDateTime}>
            <div className={styles.endDate}>
              <div className={styles.label}>End date:</div>
              <DatePicker
                hasError={showError && !this.isValidEndDate()}
                errorMessage={'Enter a valid end date'}
                onDateSelected={this.onEndDateSelected}
              />
            </div>
            <div className={styles.endTime}>
              <div className={styles.label}>End time:</div>
              <TimePicker
                hasError={showError && !this.isValidEndTime()}
                errorMessage={'Enter a valid end time'}
                onTimeSelected={this.onEndTimeSelected}
              />
            </div>
          </div>
        </div>

        <div className={styles.divider} />

        <div className={styles.button}>
          <SubmitButton
            label="Create Discount"
            theme="primary"
            submittingLabel="Creating Discount Coupon..."
            isSubmitting={isCreatingShopifyDiscountCoupon}
            round={true}
            className={cx(styles.button, styles.left)}
            disabled={isCreatingShopifyDiscountCoupon}
            onClick={this.handleOnCreateDiscount}
          />
          <Button label="Cancel" theme="info" className={styles.cancelButton} onClick={onCancel} />
        </div>
        <Toast ref={this.toastRef} />
      </div>
    );
  }

  /**
   * @private
   * Set the current discount code.
   *
   * @param {String} discountCode
   */
  private getDiscountTypeOptions = () => {
    return [
      {
        label: <div>Percentage</div>,
        value: 'percentage',
      },
      {
        label: <div>Fixed amount</div>,
        value: 'fixed_amount',
      },
    ];
  };

  private getMinRequirementOptions = () => {
    const { minPurchaseAmount, minQuantity, showError } = this.state;
    return [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Mininum purchase amount',
        value: 'min-purchase-amount',
        selectedElement: (
          <Input
            type="integer"
            className={styles.editMinAmount}
            value={minPurchaseAmount === null ? '' : minPurchaseAmount.toString()}
            hasError={showError && !this.isValidMinPurchaseAmount()}
            errorMessage={'Enter a valid min purchase amount'}
            placeholder="$"
            onChange={this.onMinPurchaseAmountChange}
          />
        ),
      },
      {
        label: 'Minimum quantity of items',
        value: 'min-quantity',
        selectedElement: (
          <Input
            type="integer"
            className={styles.editMinQuantity}
            value={minQuantity === null ? '' : minQuantity.toString()}
            hasError={showError && !this.isValidMinQuantity()}
            errorMessage={'Enter a valid min quantity'}
            placeholder="$"
            onChange={this.onMinQuantityChange}
          />
        ),
      },
    ];
  };

  /**
   * @private
   * Set the current discount code.
   *
   * @param {String} discountCode
   */
  private onDiscountCodeChange = (discountCode: string) => {
    this.setState({
      discountCode,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the discount type.
   *
   * @param {TDiscountType} discountType type of discount coupon to create.
   */
  private onSelectedDiscountTypeChange = (discountType: TDiscountType) => {
    const selectedDiscountTypeOptionIndex = this.getDiscountTypeOptions().findIndex(
      (option) => option.value === discountType,
    );

    this.setState({
      discountType,
      selectedDiscountTypeOptionIndex,
      discountValue: '',
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the discount value.
   *
   * @param {String} discountValue
   */
  private onDiscountValueChange = (discountValue: string) => {
    this.setState({
      discountValue,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the minimum requirment.
   *
   * @param {TMinRequirment} minRequirement
   */
  private onSelectedMinRequirementChange = (minRequirement: TMinRequirment) => {
    const selectedMinRequirementOptionIndex = this.getMinRequirementOptions().findIndex(
      (option) => option.value === minRequirement,
    );
    this.setState({
      minRequirement,
      selectedMinRequirementOptionIndex,
      errorMessage: '',
      minPurchaseAmount: null,
      minQuantity: null,
    });
  };

  /**
   * @private
   * Set the minimum purchase amount.
   *
   * @param {String} minPurchaseAmount
   */
  private onMinPurchaseAmountChange = (minPurchaseAmount: string) => {
    this.setState({
      minPurchaseAmount,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the minimum purchase amount.
   *
   * @param {String} minQuantity
   */
  private onMinQuantityChange = (minQuantity: string) => {
    this.setState({
      minQuantity,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Toggle the usage limit checkbox.
   */
  private toggleUsageLimitChecked = () => {
    const { usageLimitChecked } = this.state;
    this.setState({
      usageLimitChecked: !usageLimitChecked,
      usageLimit: '',
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the maximum number of times this
   * discount coupon can be used in total.
   *
   * @param {String} usageLimit
   */
  private onUsageLimitChange = (usageLimit: string) => {
    this.setState({
      usageLimit,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Toggle the one unit usage per customer checkbox.
   */
  private toggleOneUnitUsagePerCustomerChecked = () => {
    const { oneUnitUsagePerCustomerChecked } = this.state;

    this.setState({
      oneUnitUsagePerCustomerChecked: !oneUnitUsagePerCustomerChecked,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the start date.
   *
   * @param {String} dateSelected
   */
  private onStartDateSelected = (dateSelected: Date) => {
    this.setState({
      startDate: dateSelected,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the start time.
   *
   * @param {String} timeSelected
   */
  private onStartTimeSelected = (timeInput: ITimeInput) => {
    this.setState({
      startTimeInput: timeInput,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the end date.
   *
   * @param {Date} dateSelected
   */
  private onEndDateSelected = (dateSelected: Date) => {
    this.setState({
      endDate: dateSelected,
      errorMessage: '',
    });
  };

  /**
   * @private
   * Set the end time.
   *
   * @param {String} timeSelected
   */
  private onEndTimeSelected = (timeInput: ITimeInput) => {
    this.setState({
      endTimeInput: timeInput,
      errorMessage: '',
    });
  };

  /**
   * @private
   * When the form is submmitted.
   */
  private handleOnCreateDiscount = () => {
    if (this.validate()) {
      this.setState({
        isCreatingShopifyDiscountCoupon: true,
      });
      this.createShopifyDiscountCoupon();
    }
  };

  private isValidDiscountCode = () => {
    const { discountCode } = this.state;

    return !isEmpty(discountCode);
  };

  private isValidDiscountValue = () => {
    const { discountValue, discountType } = this.state;

    return (
      !isEmpty(discountValue) &&
      (discountType === 'fixed_amount' ||
        (discountType === 'percentage' && isPercentage(discountValue)))
    );
  };

  private isValidMinPurchaseAmount = () => {
    const { minRequirement, minPurchaseAmount } = this.state;

    return (
      minRequirement === 'min-quantity' ||
      minRequirement === 'none' ||
      (minRequirement === 'min-purchase-amount' && !isEmpty(minPurchaseAmount))
    );
  };

  private isValidMinQuantity = () => {
    const { minRequirement, minQuantity } = this.state;

    return (
      minRequirement === 'min-purchase-amount' ||
      minRequirement === 'none' ||
      (minRequirement === 'min-quantity' && !isEmpty(minQuantity))
    );
  };

  private isValidUsageLimit = () => {
    const { usageLimitChecked, usageLimit } = this.state;

    return !usageLimitChecked || !isEmpty(usageLimit);
  };

  private isValidStartDate = () => {
    const { startDate } = this.state;

    return startDate !== null;
  };

  private isValidEndDate = () => {
    const { startDate, endDate, startTimeInput, endTimeInput } = this.state;

    if (
      startDate != null &&
      endDate !== null &&
      startTimeInput.hourIn24HourFormat !== null &&
      endTimeInput.hourIn24HourFormat !== null
    ) {
      startDate.setHours(startTimeInput.hourIn24HourFormat);
      startDate.setMinutes(startTimeInput.minuteIn24HourFormat);

      endDate.setHours(endTimeInput.hourIn24HourFormat);
      endDate.setMinutes(endTimeInput.minuteIn24HourFormat);

      return endDate > startDate;
    }

    return (
      (endDate === null && endTimeInput.hourIn24HourFormat === null) ||
      (endDate !== null && endTimeInput.hourIn24HourFormat !== null)
    );
  };

  private isValidStartTime = () => {
    const { startTimeInput } = this.state;

    return startTimeInput.hourIn24HourFormat !== null;
  };

  private isValidEndTime = () => {
    const { endTimeInput, endDate } = this.state;

    return (
      (endDate === null && endTimeInput.hourIn24HourFormat === null) ||
      (endDate !== null && endTimeInput.hourIn24HourFormat !== null)
    );
  };

  /**
   * @private
   * Validate all the form fields.
   *
   *
   * @return {Boolean} Return true if validation was successfull otherwise false.
   */
  private validate = () => {
    let showError = false;

    showError =
      showError ||
      !this.isValidDiscountCode() ||
      !this.isValidDiscountValue() ||
      !this.isValidMinPurchaseAmount() ||
      !this.isValidMinQuantity() ||
      !this.isValidUsageLimit() ||
      !this.isValidStartDate() ||
      !this.isValidEndDate() ||
      !this.isValidStartTime() ||
      !this.isValidEndTime();

    if (showError) {
      this.setState({
        showError: true,
        errorMessage: 'Sorry, there are errors below. Please fix them and re-submit.',
      });
      this.ref.current.scrollIntoView();
    }

    return !showError;
  };

  /**
   * @private
   * Creates a new shopify discount coupon.
   *
   * @return {Promise}
   */
  private createShopifyDiscountCoupon = (): Promise<{
    clientReviewId: string;
  }> =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint, projectId, shopifyStoreId, onSuccess } = this.props;
      const {
        discountCode,
        discountType,
        discountValue,
        minRequirement,
        minPurchaseAmount,
        minQuantity,
        usageLimit,
        oneUnitUsagePerCustomerChecked,
        startDate,
        startTimeInput,
        endDate,
        endTimeInput,
      } = this.state;

      startDate.setHours(startTimeInput.hourIn24HourFormat);
      startDate.setMinutes(startTimeInput.minuteIn24HourFormat);

      const startDateEpoch = getEpochSeconds(startDate);
      let endDateEpoch = null;

      if (endDate) {
        endDate.setHours(endTimeInput.hourIn24HourFormat);
        endDate.setMinutes(endTimeInput.minuteIn24HourFormat);
        endDateEpoch = getEpochSeconds(endDate);
      }

      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.shopifyDiscountCoupon}`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            project_id: projectId,
            shopify_store_id: shopifyStoreId,

            discount_code: discountCode,
            discount_type: discountType,
            discount_value: parseInt(discountValue, 10),

            min_requirement: minRequirement,
            min_purchase_amount: parseInt(minPurchaseAmount, 10),
            min_quantity: parseInt(minQuantity, 10),

            usage_limit: parseInt(usageLimit, 10),
            once_per_customer: oneUnitUsagePerCustomerChecked,

            start_date_epoch_secs: startDateEpoch,
            end_date_epoch_secs: endDateEpoch,
          }),
        });

        const json = await resp.json();

        const code = json.status.code;
        const toast = this.toastRef.current;

        this.setState({
          isCreatingShopifyDiscountCoupon: false,
        });

        if (code !== 200) {
          this.setState({
            errorMessage: json.status.error_msg,
          });
          return;
        }

        toast.showMessage({
          content: <div>Added a new coupon code: {discountCode}</div>,
        });

        onSuccess(json.data);

        resolve();
      } catch (err) {
        console.log(err);

        reject();
      }
    });
}

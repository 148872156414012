import * as React from 'react';
import cx from 'classnames';

import { GraphContainer } from '../utils/GraphContainer';

import { GaugeChart as GaugeChartInternal, IGaugeChartProps } from './GaugeChart';

interface IProps extends IGaugeChartProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const GaugeChart: React.FunctionComponent<IProps> = React.memo((props) => {
  const { className, ...restProps } = props;

  return (
    <GraphContainer className={cx(className)}>
      <GaugeChartInternal {...restProps} />
    </GraphContainer>
  );
});

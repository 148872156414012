import * as React from 'react';
import cx from 'classnames';
import { isNumber, map, reduce, slice, sortBy, sumBy, uniqueId } from 'lodash';
import numeral from 'numeral';

import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { LinearGradient } from '@vx/gradient';
import { schemeCategory10 } from 'd3-scale-chromatic';


const { useMemo, useRef } = React;
import styles from './PieChart.scss';
const MAX_RECORDS = 6;

export interface IData {
  label: string;
  value: number;
  color?: string | string[];
}
export interface IPieChartProps {
  className?: string;
  data: IData[];
  innerRadius?: number;
  showTotal?: boolean;
  vertical?: boolean;
  gradientColors?: boolean;
  showLegend?: boolean;
  sortByValue?: boolean;

  width?: number;
  height?: number;
}

export const PieChart: React.FunctionComponent<IPieChartProps> = React.memo((props) => {
  const {
    className,
    data: rawData,
    gradientColors,
    showLegend,
    sortByValue = true,
    width,
    height,
  } = props;

  const colorRefs = useRef<{ [key: string]: string }>({});

  const radius = props.vertical
    ? Math.min(width, height - (showLegend ? 60 : 0)) / 2
    : Math.min(width / 2, height) / 2;

  const innerRadius = isNumber(props.innerRadius)
    ? props.innerRadius
    : Math.max(60, radius - 120);

  const [records, totalValue] = useMemo(() => {
    const sortedRawData = sortByValue ? sortBy(rawData, (d) => -d.value) : rawData;
    const sum = sumBy(rawData, 'value');
    if (sortedRawData.length > MAX_RECORDS) {
      return [
        [
          ...slice(sortedRawData, 0, MAX_RECORDS - 1),
          {
            label: 'Other',
            value: sumBy(slice(sortedRawData, MAX_RECORDS - 1), (d) => d.value),
            color: '#63686e'
          }
        ],
        sum,
      ];
    } else {
      return [sortedRawData, sum];
    }
  }, [rawData, sortByValue]);

  // This allows colors like 'rgba(255, 255, 255, 0)' to be formatted as an id
  const getColorId = (color: IData['color']): string => (
    `linear-gradient-${(Array.isArray(color) ? color[0] : color).replace(/[ .,()]/g, '')}`
  );

  const labelToColor: { [key: string]: IData['color'] } = useMemo(() => (
    reduce(records, (colorMap, d, index) => {
      colorMap[d.label] = d.color || schemeCategory10[index];
      return colorMap;
    }, {})
  ), [records]);
  return (
    <div className={cx(className, styles.PieChart, {
      [styles.vertical]: props.vertical,
    })}>
      <svg
        className={styles.graph}
        width={radius * 2}
        height={radius * 2}
        style={{
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
        }}
      >
        {map(labelToColor, (c) => {
          const fromColour = Array.isArray(c) ? c[0] : c;
          const toColour = Array.isArray(c) ? c[1] : c;

          // Declare colors to colorRefs
          const colorId = colorRefs.current[getColorId(c)] || uniqueId(`${getColorId(c)}_`);
          if (colorRefs.current[getColorId(c)] === undefined) {
            colorRefs.current[getColorId(c)] = colorId;
          }

          return (
            <LinearGradient
              id={colorId}
              key={colorId}
              fromOpacity={gradientColors ? 0.2 : 1}
              from={fromColour}
              to={toColour}
              toOpacity={gradientColors ? 0.7 : 1}
              vertical={false}
              rotate={90}
            />
          );
        })}
        <Group top={radius} left={radius}>
          <Pie
            data={records}
            pieValue={(d: IData) => d.value}
            outerRadius={radius}
            innerRadius={innerRadius}
            padAngle={0}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { data } = arc;
                const color = labelToColor[data.label];

                return (
                  <g key={index}>
                    <path
                      d={pie.path(arc)}
                      fill={`url(#${colorRefs.current[getColorId(color)]})`}
                    />
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
        {
          props.showTotal &&
          <text
            dx={radius}
            dy={radius + 6}
            style={{
              textAnchor: 'middle',
              fontSize: '16px',
              fontWeight: 'bold',
              letterSpacing: '0.15px',
              fill: '#2E426D',
            }}
          >
            {numeral(totalValue).format('$0.[0]a').toUpperCase()}
          </text>
        }
      </svg>
      {
        showLegend && props.vertical &&
        <div className={styles.labelVertical}>
          {map(records, (record) => {
            return (
              <div className={styles.labelItem} key={record.label}>
                <div
                  className={styles.dot}
                  style={{
                    backgroundColor: Array.isArray(labelToColor[record.label])
                      ? labelToColor[record.label][0]
                      : labelToColor[record.label] as string,
                  }}
                />
                <div className={styles.info}>
                  <div className={styles.value}>
                    {numeral(record.value / totalValue).format('0.0%')}
                  </div>
                  <div className={styles.label}>{record.label}</div>
                </div>
              </div>
            )
          })}
        </div>
      }
      {
        showLegend && !props.vertical &&
        <div className={styles.labelHorizontal}>
          {map(records, (record) => {
            return (
              <div className={styles.labelItem} key={record.label}>
                <div
                  className={styles.dot}
                  style={{
                    backgroundColor: Array.isArray(labelToColor[record.label])
                      ? labelToColor[record.label][0]
                      : labelToColor[record.label] as string,
                  }}
                />
                <div className={styles.label}>{record.label}</div>
                <div
                  className={styles.value}
                  style={{
                    color: Array.isArray(labelToColor[record.label])
                      ? labelToColor[record.label][0]
                      : labelToColor[record.label] as string,
                  }}
                >
                  {numeral(record.value / totalValue).format('0.0%')}
                </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
});

PieChart.defaultProps = {
  gradientColors: true,
  showLegend: true,
  showTotal: true,
  vertical: false,
}

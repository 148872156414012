import cx from 'classnames';
import * as React from 'react';

import failedImage from 'src/common/utils/failedImage';
import { LazyImage } from 'src/widgets/Image';
import { Tooltip } from 'src/widgets/Tooltip';

import { IIconProps, IconSize } from './Icon';

const { useState, useRef } = React;
import styles from './AvatarIcon.scss';
const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

export interface IAvatarIconProps extends IIconProps {
  /**
   * URL of the avatar image
   */
  imageUrl: string;

  /**
   * Initials of the user -- displayed when `imageUrl` cannot be retrieved
   */
  initials?: string;
  /**
   * Icon height (see IconSize)
   */
  size?: IconSize;
}

/**
 * @type {React.FunctionComponent}
 */
export const AvatarIcon: React.FunctionComponent<IAvatarIconProps> = React.memo((props) => {
  const { className, imageUrl, initials, size, tooltipPlacement, tooltipText } = props;
  const url = !imageUrl || failedImage.contains(imageUrl) ? null : imageUrl;

  const [showInitial, setShowInitial] = useState(false);

  let tooltipRef;

  if (tooltipText) {
    tooltipRef = useRef();
  }

  return (
    <div className={cx(className, styles.AvatarIcon, styles[size])} ref={tooltipRef}>
      {url && (!showInitial || !initials) ? (
        <LazyImage
          className={styles.image}
          fallbackSrc={defaultAvatar} // ultimate fallback
          src={url}
          onError={() => {
            setShowInitial(true);
          }}
        />
      ) : (
        <div className={styles.initial}>{initials}</div>
      )}
      {tooltipText && tooltipRef && (
        <Tooltip mountRef={tooltipRef} placement={tooltipPlacement} tooltipColor={'black'}>
          {tooltipText}
        </Tooltip>
      )}
    </div>
  );
});

AvatarIcon.defaultProps = {
  size: IconSize.MEDIUM,
  tooltipPlacement: 'top',
};

import * as React from 'react';
import cx from 'classnames';
import { debounce, defer, map, size } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { MasonryGrid } from 'src/widgets/MasonryGrid';
import { browserHasScrollbar } from 'src/utils/uiUtils';

import { ContentTile, IContentTileProps } from './ContentTile';

import { ILicensedContent } from 'src/common/models/licensedContent';

import styles from './ContentLibraryList.scss';

const defaultColumnWidth = 330;

interface IProps {
  contents: ILicensedContent[];

  onSearchClick?(content: ILicensedContent, event: React.MouseEvent);
  onAssignClick?(content: ILicensedContent, event: React.MouseEvent);
  onEditTagsClick(content: ILicensedContent);
  onRemoveTagClick(tag: string, content: ILicensedContent);
  onTagClick(content: ILicensedContent, tag: string, event: React.MouseEvent);

  showVisitManageLink: boolean;
  onVisitManageClick?(content: ILicensedContent, event: React.MouseEvent);
  onVisitProfileClick?(content: ILicensedContent, event: React.MouseEvent);
  onViewDetailClick(content: ILicensedContent, event: React.MouseEvent);

  selectedContent: {
    [contentId: string]: boolean;
  };
  onSelectedContentChange(contentId: string, selected: boolean);

  isQa: boolean;
  onVisitAdminClick(content: ILicensedContent, event: React.MouseEvent);

  toggleLikedContent(content: ILicensedContent);

  likedContent?: {
    [contentId: string]: boolean;
  };
  isLoading?: boolean;
  onReachBottom?();
  classNames?: string[];
  showCreateFeatures: boolean;

  emptyListElement?: JSX.Element;
}
type TDefaultProp = 'likedContent' |
'isLoading' |
'onReachBottom' |
'classNames' |
'onSearchClick' |
'onAssignClick' |
'onVisitProfileClick' |
'onVisitManageClick';

/**
 * @class
 * @extends {React.Component}
 */
export class ContentLibraryList extends React.Component<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
    likedContent: {},
    onReachBottom: () => undefined,
    isLoading: false,
    onSearchClick: () => undefined,
    onVisitManageClick: () => undefined,
    onVisitProfileClick: () => undefined,
  };

  private ref: React.RefObject<HTMLDivElement>;
  private columnWidth: number;

  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();

    this.columnWidth = null;
  }

  /**
   * @inheritDoc
   */
  public componentDidMount() {
    window.addEventListener('resize', debounce(this.onWindowResize, 200), { passive: true });

    // trigger a window resize to calculate the columns
    defer(this.onWindowResize);
  }

  /**
   * @inheritDoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  private onWindowResize = () => {
    const node = this.ref.current;

    if (node) {
      // Consider browser's scrollbar width (usually 15px)
      const nodeWidth = node.offsetWidth - (browserHasScrollbar() ? 15 : 0);
      const minColumnWidth = Math.max(
        Math.floor(nodeWidth / Math.ceil(nodeWidth / defaultColumnWidth)),
        280,
      );
      const maxColumnWidth = Math.min(
        Math.floor(nodeWidth / Math.floor(nodeWidth / defaultColumnWidth)),
        380,
      );

      // choose column width closer to default column width
      if (
        Math.abs(minColumnWidth - defaultColumnWidth) <=
        Math.abs(maxColumnWidth - defaultColumnWidth)
      ) {
        this.columnWidth = minColumnWidth;
      } else {
        this.columnWidth = maxColumnWidth;
      }
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      contents,
      classNames,
      isLoading,
      onEditTagsClick,
      onSearchClick,
      onAssignClick,
      showVisitManageLink,
      onVisitManageClick,
      onVisitProfileClick,
      onViewDetailClick,
      selectedContent,
      onSelectedContentChange,
      likedContent,
      toggleLikedContent,
      isQa,
      onVisitAdminClick,
      emptyListElement,
      onTagClick,
    } = this.props;

    const itemProps: IContentTileProps[] = map(contents, (content) => {
      return {
        key: content.id,
        content: content,
        onSearchClick: onSearchClick.bind(this, content),
        onAssignClick: onAssignClick ? onAssignClick.bind(this, content) : null,
        onEditTagsClick: onEditTagsClick.bind(this, content),
        onRemoveTagClick: this.onRemoveTagClick.bind(this, content),
        onTagClick: onTagClick.bind(this, content),
        // whether to show visit manage link or not
        showVisitManageLink,
        onVisitManageClick: onVisitManageClick.bind(this, content),
        // visit profile view
        onVisitProfileClick: onVisitProfileClick.bind(this, content),
        // visit detail view
        onViewDetailClick: onViewDetailClick.bind(this, content),
        // content selected state
        selected: !!selectedContent[content.id],
        onSelectedChange: onSelectedContentChange.bind(this, content.id),
        // content like/unlike
        liked: likedContent[content.id],
        toggleLikedContent: toggleLikedContent.bind(this, content),
        // visit admin page
        isQa,
        onVisitAdminClick: onVisitAdminClick.bind(this, content),
        showCreateFeatures: this.props.showCreateFeatures,
      };
    });

    return (
      <div className={cx(classNames.concat(styles.ContentLibraryList))} ref={this.ref}>
        {/* only mounts masonry grid after column width is calculated */}
        {this.columnWidth && (
          <MasonryGrid
            columnWidth={this.columnWidth}
            itemComponent={ContentTile}
            itemProps={itemProps}
            defaultItemHeight={ContentTile.defaultHeight}
            onReachBottom={this.handleReachBottom}
          />
        )}
        {isLoading ? (
          <LoadSpinner className={styles.loadSpinner} />
        ) : size(contents) === 0 ? (
          emptyListElement
        ) : null}
      </div>
    );
  }

  /**
   * @private
   * Handler for when the remove tag icon is clicked.
   *
   * @param {ILicensedContent} content the content config.
   * @param {String} tag the tag to remove.
   */
  private onRemoveTagClick = (content: ILicensedContent, tag: string) => {
    const { onRemoveTagClick } = this.props;

    onRemoveTagClick(tag, content);
  };

  /**
   * @private
   * Callback for reaching bottom.
   * Triggers infinite loading if applicable.
   */
  private handleReachBottom = () => {
    const { isLoading, onReachBottom } = this.props;

    if (isLoading) {
      return;
    }

    onReachBottom();
  };
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const AspireIQLogoGreyIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="M0 140.449H10.4584L74.7586 0H64.3002L0 140.449ZM137.814 124.037C142.173 136.178 149.588 137.268 149.588 137.268V140.226H125.541C112.013 140.226 104.861 133.022 101.189 124.35C97.4328 115.477 90.6663 98.6679 86.3098 86.5266C81.9505 74.3853 74.536 73.2958 74.536 73.2958V70.3373H98.5834C112.111 70.3373 119.263 77.5449 122.932 86.2165C126.691 95.0864 133.455 111.896 137.814 124.037Z"
      fill="black"
    />
  </SvgIcon>
));

export { AspireIQLogoGreyIcon };

import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { map, xor, isEmpty } from 'lodash';

import {
  ArrowLeftIcon,
  NextIcon,
  RefreshIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
} from 'src/widgets/Button';
import { InfiniteList } from 'src/widgets/InfiniteList';
import { LazyImage } from 'src/widgets/Image';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Modal } from 'src/widgets/Modal';
import { Video } from 'src/widgets/Video';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { ISocialPost } from 'src/common/models/socialPost';
import { IPostProject } from 'src/common/models/postProject';
import { useMobileStatus } from 'src/utils/hooks';

import { StoryItem } from './StoryItem';

const { useCallback, useEffect, useRef, useState } = React;
import styles from './SelectStoryModal.scss';
const STORY_ITEM_HEIGHT = 140;
const STORY_ITEM_HEIGHT_MOBILE = 94;

interface IProps {
  socialAccount: ISocialAccount;
  project: IPostProject;
  onRequestUpdate();
  isUpdating: boolean;
  toggleStorySelected(storyId: string);
  selectedStoryIds: string[];
  previousSelectedIds: string[];
  onContactSupport();

  show?: boolean;
  onRequestClose?(shouldRestore?: boolean);
}

/**
 * @type {React.FunctionComponent}
 */
export const SelectStoryModal: React.FunctionComponent<IProps> = React.memo((props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const {
    socialAccount,
    project,
    onRequestUpdate,
    isUpdating,
    toggleStorySelected,
    selectedStoryIds,
    previousSelectedIds,
    show,
    onRequestClose,
    onContactSupport,
  } = props;

  const storyListRef = useRef<HTMLDivElement>(null);

  const mobileType = useMobileStatus();
  const compactView = mobileType === 'phone';
  // sorted stories
  const [stories, setStories] = useState<ISocialPost[]>([]);
  const [selectedStory, setSelectedStory] = useState<ISocialPost>(null);
  // keep track of mentions to fast scroll to next one
  const [mentionIndexes, setMentionIndexes] = useState<number[]>([]);
  const [nextMentionIndex, setNextMentionIndex] = useState<number>(0);

  // only sort when stories changed
  useEffect(() => {
    // stories could be null/undefined
    if (!socialAccount.stories) {
      return;
    }

    // sort stories
    const sortedStories = socialAccount.stories.sort((a, b) => b.posted_ts - a.posted_ts);

    const newMentionIndexes = [];

    if (!isEmpty(project.brand_instagram_username)) {
      const brandMention = '@' + (project.brand_instagram_username || '').toLowerCase();
      sortedStories.forEach((story, index) => {
        const text = story.caption || story.text || '';
        if (text.toLowerCase().includes(brandMention)) {
          newMentionIndexes.push(index);
        }
      });
    }

    setStories(sortedStories);
    setMentionIndexes(newMentionIndexes);
    setNextMentionIndex(0);
  }, [socialAccount.stories, project.brand_instagram_username]);

  const showStoryDetail = (story: ISocialPost) => {
    setSelectedStory(story);
  };

  const hideStoryDetail = () => {
    setSelectedStory(null);
  };

  const requestUpdateStories = () => onRequestUpdate();

  const onSave = () => {
    onRequestClose();
    scrollToTop();
  };

  const onCancel = () => {
    setSelectedStory(null);
    onRequestClose(true);
    scrollToTop();
  };

  const scrollToTop = () => {
    const storyList = storyListRef.current;

    if (storyList) {
      storyList.scrollTop = 0;
    }
  };

  const scrollToNextMention = useCallback((event: React.MouseEvent) => {
    event.preventDefault();

    const storyList = storyListRef.current;
    const storyItems = storyList.querySelectorAll(`.${styles.storyItem}`);
    const itemHeight = mobileType ? STORY_ITEM_HEIGHT_MOBILE : STORY_ITEM_HEIGHT;
    const skippedItemCount = Math.floor(storyList.scrollTop / itemHeight);
    let index = nextMentionIndex;
    // don't skip items when reset to top
    if (index > 0) {
      while (index < mentionIndexes.length - 1 && mentionIndexes[index] < skippedItemCount) {
        index++;
      }
    }
    const nextStoryItem = storyItems[mentionIndexes[index]];

    nextStoryItem.scrollIntoView({
      block: 'start',
      behavior: index === 0 ? 'auto' : 'smooth',
    });
    nextStoryItem.classList.add(styles.highlighted);
    setTimeout(() => {
      nextStoryItem.classList.remove(styles.highlighted);
    }, 1000);

    setNextMentionIndex(index === mentionIndexes.length - 1 ? 0 : index + 1);
  }, [mentionIndexes, mobileType, nextMentionIndex]);

  const renderList = useCallback((options: {
    isCompact: boolean;
    isMobile: boolean;
  }) => (
    <InfiniteList ref={storyListRef} className={styles.storyList}>
      {map(stories, (story) => (
        <StoryItem
          isCompact={options.isCompact}
          isMobile={options.isMobile}
          key={story.media_id}
          story={story}
          brandInstagramName={project.brand_instagram_username}
          selected={selectedStoryIds.includes(story.media_id)}
          toggleStorySelected={toggleStorySelected}
          showStoryDetail={showStoryDetail}
          classNames={[styles.storyItem]}
        />
      ))}
    </InfiniteList>
  ), [project.brand_instagram_username, selectedStoryIds, stories, toggleStorySelected]);

  const selectedStoryCount = selectedStoryIds.length;
  const selectedStoryText = selectedStory && (selectedStory.caption || selectedStory.text || '');

  return mobileType ? renderMobileview() : renderNormalView();

  /**
   * Renders the desktop(normal) view.
   */
  function renderNormalView() {
    return (
      <Modal
        className={styles.SelectStoryModal}
        show={show}
        onRequestClose={onCancel}
        onRequestBack={selectedStory ? hideStoryDetail : null}
        width={1100}
      >
        <div className={styles.content}>
          <div
            className={styles.leftContent}
            style={{
              height: `${0.7 * window.innerHeight}px`,
            }}
          >
            <div className={styles.actions}>
              <Button
                label="Update Stories"
                theme="info"
                icon={
                  <RefreshIcon
                    className={cx({
                      [styles.updating]: isUpdating,
                    })}
                  />
                }
                className={styles.updateButton}
                onClick={requestUpdateStories}
                disabled={isUpdating}
              />
              <Button
                label={`Next ${project && project.brand_name} Mention`}
                theme="info"
                icon={<NextIcon size={20} />}
                onClick={scrollToNextMention}
                disabled={isUpdating || mentionIndexes.length === 0}
              />
            </div>
            {isUpdating
              ? <LoadSpinner className={styles.loadSpinner} />
              : renderList({
                isCompact: false,
                isMobile: false,
              })}
          </div>
          <div className={styles.rightContent}>
            <div className={styles.title}>{selectedStoryCount} Instagram Stories Selected</div>
            <div className={styles.helpText}>
              Select stories to be sent to {project && project.brand_name}. If you don’t see your
              story below,
              <span className={styles.contactUs} onClick={onContactSupport}>
                {' '}
                please let us know
              </span>
              .
            </div>
            <div className={styles.actions}>
              <Button
                label="Save"
                className={cx(styles.button, styles.saveButton)}
                disabled={xor(selectedStoryIds, previousSelectedIds).length === 0}
                onClick={onSave}
              />
              <Button
                label="Cancel"
                theme="info"
                className={styles.button}
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.detailOverlay, {
            [styles.active]: selectedStory,
          })}
        >
          {selectedStory && (
            <div className={styles.content}>
              {selectedStory.media_type === 'video' && (
                <Video
                  src={selectedStory.video}
                  controls={true}
                  autoPlay={true}
                  loop={true}
                  classNames={[styles.media]}
                />
              )}
              {selectedStory.media_type === 'image' && (
                <LazyImage src={selectedStory.image} className={styles.media} />
              )}
              <div className={styles.text}>{selectedStoryText}</div>
              <div className={styles.date}>
                {format(selectedStory.posted_ts * 1000, 'MMM dd, yyyy hh:mm a').toUpperCase()}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }

  /**
   * Renders the mobile view.
   */
  function renderMobileview() {
    return (
      <Modal
        className={cx(styles.SelectStoryModal, styles.mobile, {
          [styles.compact]: compactView,
        })}
        contentClassName={styles.modalContent}
        dialogClassName={styles.dialog}
        show={show}
        showCloseIcon={compactView ? true : false}
        onRequestClose={onCancel}
        onRequestBack={selectedStory ? hideStoryDetail : null}
        width={null}
      >
        <div className={styles.mobileContent}>
          {!compactView && (
            <div className={styles.header}>
              <IconButton
                icon={<ArrowLeftIcon size={22} />}
                className={styles.backButton}
                onClick={onCancel}
              />
              Submit Live Story
            </div>
          )}
          <div className={styles.title}>{selectedStoryCount} Instagram Stories Selected</div>
          <div className={styles.helpText}>
            Select stories to be sent to {project && project.brand_name}. If you don’t see your
            story below,
            <span className={styles.contactUs} onClick={onContactSupport}>
              {' '}
              please let us know
            </span>
            .
          </div>
          <div className={styles.topActions}>
            <Button
              label="Update"
              theme="info"
              icon={
                <RefreshIcon
                  className={cx({
                    [styles.updating]: isUpdating,
                  })}
                />
              }
              className={cx(styles.button, styles.updateButton)}
              onClick={requestUpdateStories}
              disabled={isUpdating}
            />
            <Button
              label="Next @ Mention"
              theme="info"
              icon={<NextIcon size={20} />}
              onClick={scrollToNextMention}
              className={styles.button}
              disabled={isUpdating || mentionIndexes.length === 0}
            />
          </div>
          {isUpdating
            ? <LoadSpinner className={styles.loadSpinner} />
            : renderList({
              isCompact: compactView,
              isMobile: true,
            })}
          <div className={styles.bottomActions}>
            <Button
              label="Save"
              className={cx(styles.button, styles.saveButton)}
              disabled={xor(selectedStoryIds, previousSelectedIds).length === 0}
              onClick={onSave}
            />
            <Button label="Cancel" theme="info" className={styles.button} onClick={onCancel} />
          </div>
        </div>
        <div
          className={cx(styles.detailOverlay, {
            [styles.active]: selectedStory,
          })}
        >
          {selectedStory && (
            <div className={styles.content}>
              {selectedStory.media_type === 'video' && (
                <Video
                  src={selectedStory.video}
                  controls={true}
                  autoPlay={true}
                  loop={true}
                  classNames={[styles.media]}
                />
              )}
              {selectedStory.media_type === 'image' && (
                <LazyImage src={selectedStory.image} className={styles.media} />
              )}
              <div className={styles.text}>{selectedStoryText}</div>
              <div className={styles.date}>
                {format(selectedStory.posted_ts * 1000, 'MMM dd, yyyy hh:mm a').toUpperCase()}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
});

SelectStoryModal.defaultProps = {
  show: false,
  onRequestClose: () => undefined,
};

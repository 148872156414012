import * as React from 'react';
import cx from 'classnames';
import { map, forEach } from 'lodash';

import { CampaignListItem } from '../CampaignListItem/CampaignListItem';
import { ICampaign } from 'src/common/models/campaign';

const { useState, useEffect } = React;

import styles from './CampaignList.scss';

interface IProps {
  campaigns: ICampaign[];
  selectedCampaign?: number;

  // actions
  handleCampaignClick(campaignId: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const CampaignList: React.FunctionComponent<IProps> = (props) => {
  const { campaigns, handleCampaignClick, selectedCampaign } = props;

  const [selectedCampaignId, setSelectedCampaignId] = useState(selectedCampaign);
  const [isViewingActive, setViewingActive] = useState(true);
  const [activeCampaigns, setActiveCampaigns] = useState({});
  const [deactivatedCampaigns, setDeactivatedCampaigns] = useState({});

  useEffect(() => {
    const aCampaigns = {};
    const dCampaigns = {};
    forEach(campaigns, (campaign: ICampaign, campaignId: number) => {
      if (!campaign.deactivated) {
        aCampaigns[campaignId] = campaign;
      } else {
        dCampaigns[campaignId] = campaign;
      }
    });
    setActiveCampaigns(aCampaigns);
    setDeactivatedCampaigns(dCampaigns);
  }, [campaigns]);

  const onCampaignClick = (campaignId) => {
    setSelectedCampaignId(campaignId);
    handleCampaignClick(campaignId);
  };

  const onSwitchViewingActive = (viewingActive) => {
    setViewingActive(viewingActive);
  };

  // TODO: filter campaigns if the count of content to review is greater than 0
  return (
    <div className={styles.CampaignList}>
      <div className={styles.viewContainer}>
        <div
          className={cx(styles.header, { [styles.inactive]: !isViewingActive })}
          onClick={() => onSwitchViewingActive(true)}
        >
          Active Campaigns
        </div>
        <div
          className={cx(styles.header, { [styles.inactive]: isViewingActive })}
          onClick={() => onSwitchViewingActive(false)}
        >
          Completed
        </div>
      </div>
      <div className={styles.viewableCampaigns}>
        {map(isViewingActive ? activeCampaigns : deactivatedCampaigns, (campaign: ICampaign, campaignId: number) => {
          return (
            <CampaignListItem
              key={campaignId}
              campaign={campaign}
              selected={campaignId === selectedCampaignId}
              onClick={onCampaignClick}
            />
          );
        })}
      </div>
    </div>
  );
};

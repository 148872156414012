import {
  assignIn,
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  has,
  isEmpty,
  isNil,
  map,
  maxBy,
  uniqBy,
} from 'lodash';

import { ICommunity } from 'src/common/models/community';
import { IProspect } from 'src/common/models/member';
import { IMemberProgramMap, IProgram, TProspectProgramStatus } from 'src/common/models/program';
import { isMemberProgram, IMemberProgram, TProgram } from 'src/common/models/program';

/**
 * Get program status
 * @param program
 */
export const getProgramStatus = (program: TProgram): IMemberProgram['status'] => (
  isMemberProgram(program) ? program.status : null
);

/**
 * Extract all socialAccount objects from a list of prospects
 * @param prospects
 */
export const getSocialAccountsFromProspects = (...prospects: IProspect[]): IProspect['socialAccount'][] => (
  !isEmpty(prospects)
    ? map(
      filter(prospects, (p) => !isEmpty(p)),
      (p) => p.socialAccount,
    )
    : []
);

/**
 * Check if the social account belongs to the provided program ID
 * @param socialAccount
 * @param programId
 * @param memberPrograms
 */
export const getProgramStatusOfAccount = (
  socialAccount: IProspect['socialAccount'],
  programId: TProgram['id'],
  memberPrograms: IMemberProgramMap,
): TProspectProgramStatus => {
  if (isEmpty(socialAccount) || isEmpty(memberPrograms)) {
    return null;
  } else if (!has(memberPrograms, socialAccount.username)) {
    return null;
  }
  const program = find(
    get(memberPrograms, socialAccount.username),
    (p) => p.id === programId
  );
  return !isEmpty(program) ? program.status : null;
};

/**
 * Simpler version of `getProgramStatusOfAccount` but only checks if a status is available or not
 */
export const doesAccountBelongToProgram = (
  socialAccount: IProspect['socialAccount'],
  programId: TProgram['id'],
  memberPrograms: IMemberProgramMap,
): boolean => (
  !isNil(getProgramStatusOfAccount(socialAccount, programId, memberPrograms))
);

/**
 * Get most recently created program and return its id
 */
export const getRecentlyCreatedProgramId = (programs: readonly IProgram[]): IProgram['id'] => {
  const program = maxBy(programs, (program) => program.id);
  return program ? program.id : null;
};

/**
 * Consolidated list of communities with respective programs
 * - map programs to communities
 */
export const mapProgramsToCommunities = (
  communities: readonly ICommunity[],
  programs: readonly IProgram[],
): readonly ICommunity[] => Object.freeze(
  map(communities, (c) => ({
    ...c,
    programs: filter(programs, (p) => p.communityId === c.id),
  }))
);

/**
 * Consolidated list of communities with respective programs and statuses
 * - map prospect's programs to programs;
 * - map programs to communities
 */
export const getCommunitiesAndProgramsOfProspect = (
  communities: readonly ICommunity[],
  memberPrograms: Readonly<IMemberProgramMap>,
  programs: readonly IProgram[],
  prospect: IProspect,
): readonly ICommunity[] => {
  if (isEmpty(communities)) {
    return [];
  }

  // local list of programs for this prospect
  const thisProspectsPrograms = cloneDeep(programs);
  const username = prospect && prospect.socialAccount ? prospect.socialAccount.username : null;
  forEach(
    get(memberPrograms, username),
    (program) => {
      assignIn(
        find((thisProspectsPrograms), (p) => p.id === program.id),
        program,
      );
    },
  );

  return Object.freeze(
    map(communities, (c) => ({
      ...cloneDeep(c),
      programs: filter(thisProspectsPrograms, (p) => p.communityId === c.id),
    }))
  );
};

/**
 * Remove duplicate programs
 */
export const uniqueMemberPrograms = (...programs: IMemberProgram[]): IMemberProgram[] => (
  uniqBy(programs, 'id')
);

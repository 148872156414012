import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const AddImageIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M4.25,19.8l2.14-3.63,2,2.41a.39.39,0,0,0,.34.15A.38.38,0,0,0,9,18.55l3.29-5.06,2.32,6.31Zm8.54-7.38a.42.42,0,0,0-.34-.27.44.44,0,0,0-.39.19L8.63,17.61l-2-2.44A.44.44,0,0,0,6.3,15a.41.41,0,0,0-.32.21L3.17,20a.45.45,0,0,0,0,.42.4.4,0,0,0,.36.22H15.21a.42.42,0,0,0,.39-.57Z" />
    <path d="M4.93,9.17a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0,2.87a1.86,1.86,0,0,0,0-3.72,1.86,1.86,0,0,0,0,3.72" />
    <path d="M18,12.33l-.44,0V21.8a1.34,1.34,0,0,1-1.33,1.36H2.15A1.35,1.35,0,0,1,.82,21.8V7.37A1.35,1.35,0,0,1,2.15,6h9.91a6,6,0,0,1,.07-.85h-10A2.19,2.19,0,0,0,0,7.37V21.8A2.18,2.18,0,0,0,2.15,24h14.1a2.19,2.19,0,0,0,2.16-2.2V12.31Z" />
    <path
      d="M21,5.87H18.32V3.08a.3.3,0,1,0-.59,0V5.87H15a.3.3,0,1,0,0,.59h2.69V9.25a.3.3,0,1,0,.59,0V6.46H21a.3.3,0,0,0,0-.59Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M18,0a6.07,6.07,0,0,0-6,6.16,6.07,6.07,0,0,0,6,6.17,6.08,6.08,0,0,0,6-6.17A6.08,6.08,0,0,0,18,0Zm3,6.46H18.32V9.25a.3.3,0,1,1-.59,0V6.46H15a.3.3,0,1,1,0-.59h2.69V3.08a.3.3,0,1,1,.59,0V5.87H21a.3.3,0,0,1,0,.59Z"
      style={{ fill: '#3996E0' }}
    />
  </SvgIcon>
));

export { AddImageIcon };

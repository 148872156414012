import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { get } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Tooltip } from 'src/widgets/Tooltip';

import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../../useAnalyze';
import { useFetchSummaryData } from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

const { useMemo, useRef } = React;
import styles from './ROISection.scss';

export interface IROIData {
  cost: number;
  tmv: number;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ROISection: React.FunctionComponent<IProps> = React.memo(props => {
  const { apiEndpoint, filters, isAspirex } = useAnalyze();
  const { loading, data, error } = useFetchSummaryData<IROIData>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/roi`,
    isAspirex,
    filters
  );
  const ref = useRef<HTMLDivElement>();

  const roiFactor = useMemo(() => {
    if (data) {
      if (data.cost == 0) {
        return 0;
      }
      return data.tmv / data.cost;
    }

    return 1;
  }, [data]);
  const costBarHeight = roiFactor >= 1 ? 1 / roiFactor : 1;
  const tmvBarHeight = roiFactor >= 1 ? 1 : roiFactor;

  if (error) {
    return (
      <Notice className={styles.notice} type="error">
        There is an error when trying to fetch the reports.
      </Notice>
    );
  }

  const dataReturnedEmpty = data && data.cost === null && data.tmv === null;
  return (
    <div className={cx(styles.ROISection, props.className)}>
      {loading && <LoadSpinner centered={true} />}
      <ShouldSectionShow
        loading={loading}
        data={data}
        dataReturnedEmpty={dataReturnedEmpty}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h3>ROI {numeral(roiFactor).format('0%')}</h3>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.graph}>
              <div
                className={cx(styles.bar, styles.cost)}
                style={{
                  height: `${costBarHeight * 100}%`,
                }}
              />
              <div
                className={cx(styles.bar, styles.tmv)}
                style={{
                  height: `${tmvBarHeight * 100}%`,
                }}
              />
            </div>
            <div className={styles.labelSection}>
              <div ref={ref} className={cx(styles.item, styles.cost)}>
                <div className={styles.dot} />
                <div className={styles.info}>
                  <div className={styles.value}>
                    {numeral(get(data, 'cost')).format('$0.[00]a')}
                  </div>
                  <div className={styles.label}>Cost</div>
                  <Tooltip
                    mountRef={ref}
                    placement="bottom"
                    tooltipColor="black"
                  >
                    <div className={styles.tooltipBody}>
                      Based on agreed upon terms and payments made, including
                      product cost if applicable. May be an estimate if multiple
                      posts were made or agreed upon
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className={cx(styles.item, styles.tmv)}>
                <div className={styles.dot} />
                <div className={styles.info}>
                  <div className={styles.value}>
                    {numeral(get(data, 'tmv')).format('$0.[00]a')}
                  </div>
                  <div className={styles.label}>TMV</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShouldSectionShow>
    </div>
  );
});

ROISection.defaultProps = {
  className: null,
};

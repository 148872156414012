import * as React from 'react';
import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const OutlookIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M19.2212 3.5293H9.36228C8.92058 3.5293 8.55713 3.89275 8.55713 4.33445V5.24963L14.1139 6.97013L20.0264 5.24963V4.33445C20.0264 3.89275 19.6629 3.5293 19.2212 3.5293Z" fill="#0364B8" />
    <path d="M20.9729 12.3634C21.0569 12.0994 21.124 11.8303 21.1736 11.5576C21.1737 11.4213 21.1008 11.2949 20.9826 11.2267L20.9752 11.2227L20.9729 11.2216L14.7577 7.68104C14.7309 7.66369 14.7031 7.64776 14.6746 7.63341C14.4339 7.51412 14.1508 7.51412 13.9101 7.63341C13.8816 7.64776 13.8538 7.66361 13.827 7.68104L7.61184 11.2216L7.6095 11.2227L7.60199 11.2267C7.48396 11.2949 7.41105 11.4213 7.41113 11.5576C7.46077 11.8303 7.52784 12.0994 7.61184 12.3634L14.202 17.1833L20.9729 12.3634Z" fill="#0A2767" />
    <path d="M16.5863 5.24902H12.5721L11.4131 6.96952L12.5721 8.68985L16.5863 12.1306H20.0271V8.68985L16.5863 5.24902Z" fill="#28A8EA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.55811 5.25H12.5724V8.69075H8.55811V5.25Z" fill="#0078D4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5859 5.25H20.0267V8.69075H16.5859V5.25Z" fill="#50D9FF" />
    <path d="M16.5866 12.1312L12.5724 8.69043H8.55811V12.1312L12.5724 15.572L18.7841 16.5859L16.5866 12.1312Z" fill="#0364B8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5718 8.69043H16.586V12.1312H12.5718V8.69043Z" fill="#0078D4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.55811 12.1309H12.5724V15.5716H8.55811V12.1309Z" fill="#064A8C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5859 12.1309H20.0267V15.5716H16.5859V12.1309Z" fill="#0078D4" />
    <path d="M14.4021 16.8447L7.63867 11.913L7.92196 11.4146C7.92196 11.4146 14.0838 14.9242 14.1778 14.977C14.2557 15.0083 14.3433 15.0058 14.4193 14.9701C14.507 14.9208 20.6884 11.3975 20.6884 11.3975L20.9728 11.8958L14.4021 16.8447Z" fill="#0A2767" fillOpacity="0.498039" />
    <path d="M20.9845 11.8885L20.977 11.893L20.9753 11.8942L14.7601 15.4348C14.5094 15.5965 14.1917 15.6162 13.9229 15.4869L16.0876 18.3892L20.821 19.4198V19.4221C21.0442 19.2607 21.1767 19.0013 21.1766 18.7259V11.5576C21.1767 11.6939 21.1037 11.8204 20.9856 11.8885H20.9845Z" fill="#1490DF" />
    <path d="M21.1764 18.7261V18.3029L15.4516 15.041L14.7605 15.4344C14.5099 15.5961 14.1922 15.6159 13.9233 15.4866L16.0881 18.3889L20.8215 19.4194V19.4217C21.0446 19.2602 21.177 19.001 21.177 18.7255L21.1764 18.7261Z" fill="black" fillOpacity="0.047059" />
    <path d="M21.1478 18.946L14.8724 15.3711L14.7605 15.4347C14.5099 15.5964 14.1922 15.6163 13.9233 15.4869L16.0881 18.3892L20.8215 19.4197V19.422C20.9821 19.3056 21.0976 19.1372 21.1483 18.9455L21.1478 18.946Z" fill="black" fillOpacity="0.098039" />
    <path d="M7.61185 11.896V11.8902H7.60618L7.58891 11.8788C7.47763 11.8103 7.41006 11.6882 7.41115 11.5576V18.7271C7.41115 19.1983 7.79896 19.5861 8.27027 19.5861H20.3142C20.3857 19.5853 20.4571 19.5758 20.5263 19.5574C20.5622 19.5511 20.597 19.5396 20.6296 19.523C20.6417 19.5218 20.6534 19.5179 20.6639 19.5115C20.7108 19.4923 20.7552 19.4673 20.7958 19.4369L20.8188 19.4198L7.61185 11.896Z" fill="#28A8EA" />
    <path d="M13.1458 16.5283V7.16191C13.1445 6.74298 12.8003 6.39871 12.3813 6.39746H8.5759V10.6731L7.61184 11.2225L7.60958 11.2237L7.60216 11.2277C7.48404 11.2959 7.41113 11.4223 7.41113 11.5585V17.295V17.2926H12.3813C12.8003 17.2915 13.1445 16.9471 13.1458 16.5283Z" fill="black" fillOpacity="0.098039" />
    <path d="M12.5724 17.1015V7.73515C12.5711 7.3163 12.2268 6.97204 11.8079 6.9707H8.5759V10.673L7.61184 11.2224L7.60958 11.2235L7.60216 11.2275C7.48404 11.2958 7.41113 11.4221 7.41113 11.5584V17.8683V17.8659H11.8079C12.2268 17.8647 12.5711 17.5204 12.5724 17.1015ZM12.5724 15.9546V7.73515C12.5711 7.3163 12.2268 6.97204 11.8079 6.9707H8.5759V10.673L7.61184 11.2224L7.60958 11.2235L7.60216 11.2275C7.48404 11.2958 7.41113 11.4221 7.41113 11.5584V16.7213V16.719H11.8079C12.2268 16.7178 12.5711 16.3735 12.5724 15.9546ZM11.9989 15.9546V7.73515C11.9976 7.3163 11.6534 6.97204 11.2345 6.9707H8.5759V10.673L7.61184 11.2224L7.60958 11.2235L7.60216 11.2275C7.48404 11.2958 7.41113 11.4221 7.41113 11.5584V16.7213V16.719H11.2345C11.6534 16.7178 11.9976 16.3735 11.9989 15.9546Z" fill="black" fillOpacity="0.2" />
    <path d="M3.58817 6.9707H11.2341C11.6534 6.9707 11.9985 7.31572 11.9985 7.73506V15.3811C11.9985 15.8004 11.6534 16.1455 11.2341 16.1455H3.58817C3.16883 16.1455 2.82373 15.8004 2.82373 15.3811V7.73506C2.82373 7.31572 3.16883 6.9707 3.58817 6.9707Z" fill="#0078D4" />
    <path d="M5.04102 10.1059C5.24414 9.67305 5.57214 9.31077 5.98264 9.06568C6.43752 8.80525 6.95546 8.67554 7.47941 8.69064C7.96466 8.67996 8.44364 8.803 8.86374 9.04616C9.25905 9.28157 9.57746 9.62692 9.78008 10.04C10.0008 10.4951 10.1109 10.996 10.1012 11.5017C10.112 12.0304 9.99864 12.5542 9.77041 13.0312C9.56328 13.4589 9.23528 13.8167 8.82703 14.06C8.39059 14.3108 7.89375 14.4372 7.39049 14.4253C6.89474 14.4371 6.40516 14.3127 5.97522 14.0657C5.57681 13.8299 5.25457 13.4842 5.04736 13.0702C4.82522 12.6215 4.71385 12.1261 4.72278 11.6256C4.71294 11.1015 4.82196 10.582 5.0416 10.1059H5.04102ZM6.04454 12.5477C6.1529 12.8214 6.33667 13.0589 6.57449 13.2324C6.81649 13.4019 7.10662 13.4893 7.40192 13.4819C7.71657 13.4943 8.0268 13.4042 8.28565 13.225C8.52055 13.0515 8.69949 12.8127 8.80009 12.5385C8.9132 12.2329 8.96893 11.909 8.96467 11.5832C8.96818 11.2545 8.91579 10.9275 8.80985 10.6163C8.71642 10.3362 8.54391 10.089 8.31318 9.9047C8.06117 9.71651 7.75177 9.62091 7.43754 9.63401C7.13581 9.62617 6.83918 9.71409 6.59051 9.88518C6.34826 10.0593 6.16041 10.2988 6.04913 10.5756C5.80304 11.2095 5.80163 11.9129 6.04512 12.5477H6.04454Z" fill="white" />
  </SvgIcon>
));

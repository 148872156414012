import { assign, sortBy, uniqBy } from 'lodash';
import { Middleware, Dispatch } from 'redux';

import { ActionTypes, IInvitedListPage, IInvitedListPageAction } from './models';

const processSocialAccounts: Middleware<unknown, IInvitedListPage> = ({ getState }) => (
  next: Dispatch<IInvitedListPageAction>,
) => (action: IInvitedListPageAction) => {
  if (action.type === ActionTypes.SET_SOCIAL_ACCOUNTS) {
    const { campaign } = getState();
    const { socialAccounts } = action.payload;
    const sortedSocialAccounts = sortBy(uniqBy(socialAccounts, 'id'), (socialAccount) => {
      if (socialAccount.invite && socialAccount.invite.campaign_id === campaign.id) {
        return -socialAccount.invite.manager_acted_ts;
      }

      return 0;
    });

    action.payload = assign(
      {},
      {
        ...action.payload,
        socialAccounts: sortedSocialAccounts,
      },
    );
  }

  return next(action);
};

export default [processSocialAccounts];

import * as React from 'react';
import cx from 'classnames';
import { map, findIndex } from 'lodash';

import { AreaChart } from 'src/widgets/charts';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Select } from 'src/widgets/Select';

import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../../useAnalyze';
import { useFetchSummaryData } from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

const { useMemo } = React;
import styles from './InsightSection.scss';

export interface IInsightData {
  date: string;
  value: number;
  engagements: number;
  impressions: number;
  reach: number;
}
interface IProps {
  className?: string;
}

const dropDownValues = [
  {
    value: 'impressions',
    label: 'Impressions',
  },
  {
    value: 'engagements',
    label: 'Engagements',
  },
  {
    value: 'reach',
    label: 'Reach',
  },
];

/**
 * @type {React.FunctionComponent}
 */
export const InsightSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const [selection, setSelection] = React.useState('engagements');
    const { loading, data, error } = useFetchSummaryData<IInsightData[]>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/insight`,
      isAspirex,
      filters
    );

    const records = useMemo(
      () =>
        map(data, record => ({
          x: new Date(record.date),
          y: record[selection],
        })),
      [data, selection]
    );

    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const selectedIndex = useMemo(() => {
      return findIndex(dropDownValues, p => p.value === selection);
    }, [selection]);

    const dataReturnedEmpty = data && data.length === 0;
    const onChange = value => setSelection(value);
    const getTitle = value => {
      if (value === 'engagements') {
        return 'Engagement';
      }
      if (value === 'impressions') {
        return 'Impression';
      }
      if (value === 'reach') {
        return 'Reach';
      }
    };
    return (
      <div className={cx(styles.InsightSection, props.className)}>
        {loading && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>{getTitle(selection)} Over Time</h3>
              </div>
              <Select
                theme="info"
                className={styles.select}
                options={dropDownValues}
                onChange={onChange}
                selectedIndex={selectedIndex}
              />
            </div>
            <div className={styles.main}>
              <AreaChart data={records} />
            </div>
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

InsightSection.defaultProps = {
  className: null,
};

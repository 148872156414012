import * as React from 'react';
import cx from 'classnames';

import { ContentTable } from './ContentTable';
import { getAdvertiserAccessTableRowData } from './utils/tableData';

import { useFetch } from 'src/utils/hooks';

import { IClientAdvertiserAccess } from 'src/common/models/clientAdvertiserAccess';

import endpoints from 'src/common/config/endpoints';
import styles from './ClientAdvertiserAccessPage.scss';

const { useMemo } = React;

interface IProps {
  apiEndpoint: string;
  accessId: number;
  onContactSupport();
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ClientAdvertiserAccessPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const [isLoading, data, error] = useFetch<IClientAdvertiserAccess>({
    url: `${props.apiEndpoint}/${endpoints.advertiserAccessEndpoint}?access_id=${props.accessId}`,
  });

  const tableData = useMemo(() => {
    if (data) {
      return getAdvertiserAccessTableRowData(data.requirements);
    } else {
      return [];
    }
  }, [data]);

  return (
    <div className={cx(styles.ClientAdvertiserAccessPage, props.className)}>
      <div className={styles.header}>
        <div className={styles.title}>Advertiser Access</div>
        <div className={styles.text}>
          View all creator social accounts that you have advertiser access for.
        </div>
      </div>
      <ContentTable
        isLoading={isLoading}
        data={tableData}
        hasError={!!error}
        onContactSupport={props.onContactSupport}
      />
    </div>
  );
});

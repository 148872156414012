import * as React from 'react';
import cx from 'classnames';
import * as d3 from 'd3';
import numeral from 'numeral';
import { format } from 'date-fns';
import memoize from 'memoize-one';
import { chain, isEqual, map, sumBy } from 'lodash';

import { Tooltip } from 'src/widgets/Tooltip';

import styles from './Histogram.scss';

const barPadding = 0;
const contentMargin = {
  top: 80,
  right: 50,
  bottom: 90,
  left: 90,
};

interface IData {
  x: number;
  y: number;
}
interface IProps {
  title: string;
  data: IData[];

  height?: number;
  isDollar?: boolean;
  classNames?: string[];
}
type TDefaultProp = 'height' | 'isDollar' | 'classNames';

interface IState {
  prevData: IData[];
  groupedData: IData[];
  updatedRectIndex: number;
  contentWidth: number;

  rectRefs: Array<React.RefObject<SVGRectElement>>;
  selectedRectIndex: number;
  showTooltip: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Histogram extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
    isDollar: false,
    height: 340,
  };

  private ref: React.RefObject<HTMLDivElement>;
  private rectGroupRef: React.RefObject<SVGGElement>;
  private xAxisRef: React.RefObject<SVGGElement>;
  private yAxisRef: React.RefObject<SVGGElement>;

  private rectTransitionTimer: number;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();
    this.rectGroupRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.yAxisRef = React.createRef();

    this.rectTransitionTimer = null;

    this.state = {
      prevData: null,
      groupedData: null,
      updatedRectIndex: -1,
      contentWidth: 0,

      rectRefs: [],
      selectedRectIndex: null,
      showTooltip: false,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidMount() {
    const node = this.ref.current;

    // only calculate content width once when component is mounted.
    const contentWidth = node.clientWidth - contentMargin.left - contentMargin.right;

    this.setState({
      contentWidth,
    });
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { data } = nextProps;
    const { prevData } = prevState;

    // check if data has changed
    if (isEqual(data, prevData)) {
      return null;
    }

    const rectRefs = map(data, () => React.createRef());
    const groupedData: IData[] = chain(data)
      .groupBy('x')
      .map((values, x) => ({
        y: sumBy(values, 'y'),
        x: parseInt(x, 10),
      }))
      .value();

    return {
      prevData: [...data],
      groupedData,
      updatedRectIndex: -1,
      rectRefs,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidUpdate() {
    const { height } = this.props;
    const { contentWidth, updatedRectIndex, groupedData } = this.state;
    const { xAxis, yAxis } = this.calculateState(height, groupedData, contentWidth);

    // clears previous timer
    if (this.rectTransitionTimer) {
      window.clearTimeout(this.rectTransitionTimer);
      this.rectTransitionTimer = null;
    }

    if (updatedRectIndex < groupedData.length - 1) {
      this.rectTransitionTimer = window.setTimeout(() => {
        this.setState({
          updatedRectIndex: updatedRectIndex + 1,
        });
      }, 10);
    }

    // update x axis and y axis
    d3.select(this.xAxisRef.current).call(xAxis);
    d3.select(this.yAxisRef.current).call(yAxis);
  }

  /**
   * @private
   * Calculates the state needed to render the histogram, based on given height, data and content width.
   */
  private calculateState = memoize((height: number, data: IData[], contentWidth: number) => {
    // save those as state to avoid calculating it again
    const contentHeight = height - contentMargin.top - contentMargin.bottom;
    const rectWidth = contentWidth / data.length - barPadding;
    const minDate = new Date(d3.min(data, (d) => d.x));

    // scale and axis config
    const xScale = d3
      .scaleTime()
      .domain([minDate, new Date(d3.max(data, (d) => d.x))])
      .rangeRound([0, contentWidth]);
    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%m/%d'));
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y)])
      .rangeRound([contentHeight, 0]);
    const yAxis = d3.axisLeft(yScale).tickFormat(d3.format('.0s'));

    return {
      minDate,
      contentHeight,
      rectWidth,
      xScale,
      xAxis,
      yScale,
      yAxis,
    };
  });

  /**
   * @inheritdoc
   */
  public render() {
    const { title, isDollar, classNames } = this.props;
    const { rectRefs, selectedRectIndex, showTooltip, groupedData } = this.state;

    const selectedData = groupedData[selectedRectIndex];

    return (
      <div ref={this.ref} className={cx(classNames.concat(styles.Histogram))}>
        <div className={styles.title}>{title}</div>
        {this.renderHistogram()}
        <Tooltip
          placement="top"
          mountRef={rectRefs[selectedRectIndex]}
          autoRegisterListener={false}
          show={showTooltip}
          tooltipColor="black"
        >
          {selectedData && (
            <div className={styles.tooltip}>
              <div className={styles.amount}>
                {numeral(selectedData.y).format(isDollar ? '$0,0' : '0,0')}
              </div>
              <div className={styles.date}>{format(selectedData.x, 'MM/dd')}</div>
            </div>
          )}
        </Tooltip>
      </div>
    );
  }

  /**
   * @private
   * Renders the histogram.
   *
   * @return {JSX}
   */
  private renderHistogram = () => {
    const { height } = this.props;
    const { rectRefs, contentWidth, updatedRectIndex, groupedData } = this.state;
    const { rectWidth, contentHeight, xScale, minDate, yScale } = this.calculateState(
      height,
      groupedData,
      contentWidth,
    );

    // content width is detected in componentDidMount
    // which is called after initial render
    // contentWidth will be negative during initial render, skip it
    if (contentWidth <= 0) {
      return null;
    }

    return (
      <svg width="100%" height={height}>
        <g ref={this.rectGroupRef} className={styles.rectGroup}>
          {map(groupedData, (d, index) => {
            const rectHeight = index <= updatedRectIndex ? contentHeight - yScale(d.y) : 0;
            const rectY =
              index <= updatedRectIndex
                ? contentMargin.top + yScale(d.y)
                : contentMargin.top + contentHeight;

            return (
              <rect
                key={d.x}
                ref={rectRefs[index]}
                x={contentMargin.left + (xScale(new Date(d.x)) - xScale(minDate) - rectWidth / 2)}
                y={rectY}
                width={rectWidth}
                height={rectHeight}
                onMouseEnter={this.showTooltip.bind(this, index)}
                onMouseLeave={this.hideTooltip}
              />
            );
          })}
        </g>
        <g
          ref={this.xAxisRef}
          transform={`translate(${contentMargin.left}, ${contentMargin.top + contentHeight + 20})`}
          className={cx(styles.axis, styles.xAxis)}
        />
        <g
          ref={this.yAxisRef}
          transform={`translate(${contentMargin.left - rectWidth / 2 - 10}, ${contentMargin.top})`}
          className={cx(styles.axis, styles.yAxis)}
        />
      </svg>
    );
  };

  /**
   * @private
   * Sets the selected rect, and shows the tooltip.
   *
   * @param {Number} selectedRectIndex the selected rect index.
   */
  private showTooltip = (selectedRectIndex) => {
    this.setState({
      selectedRectIndex,
      showTooltip: true,
    });
  };

  /**
   * @private
   * Hides the tooltip.
   */
  private hideTooltip = () => {
    this.setState({
      showTooltip: false,
    });
  };
}

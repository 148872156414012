import * as React from 'react';
import cx from 'classnames';
import { map, range } from 'lodash';

import styles from './StepIndicator.scss';

interface IProps {
  step: number;
  numberOfSteps: number;
  classNames?: string[];
}

export const StepIndicator: React.FunctionComponent<IProps> = React.memo((props) => (
  <div className={cx(styles.StepIndicator, props.classNames)}>
    {map(range(props.numberOfSteps), (index) => (
      <span key={index} className={cx(styles.dash, { [styles.selected]: props.step === index })} />
    ))}
  </div>
));

import { filter, reject, isEmpty, maxBy, includes, split, last, size } from 'lodash';
import { ILicensedContent } from 'src/common/models/licensedContent';
import { IMedia } from 'src/common/models/media';

export const PREVIEW_RESOLUTION = 'aiq_preview_resolution';
export const PREVIEW_RESOLUTION_ORIGINAL = 'aiq_preview_resolution_original';
export const AIQ_PREVIEW_RESOLUTIONS = [PREVIEW_RESOLUTION, PREVIEW_RESOLUTION_ORIGINAL];
const PREVIEWABLE_FILE_TYPES = ['jpg', 'jpeg', 'png'];

const getHighestQualityMedia = (mediaList: IMedia[], contentType: 'image' | 'video'): IMedia => {
  // Keep only corresponding media types.
  mediaList = filter(mediaList, { media_type: contentType });
  // Filter out the preview resolution.
  mediaList = reject(mediaList, { resolution: PREVIEW_RESOLUTION });

  if (isEmpty(mediaList)) {
    return;
  }

  const originalBySize = maxBy(mediaList, 'size');

  if (originalBySize && originalBySize.size !== null) {
    return originalBySize;
  } else {
    return maxBy(mediaList, (media) => media.width || 0);
  }
};

export const getDownloadableMedia = (content: ILicensedContent): IMedia => {
  if (!isEmpty(content.downloadable_media)) {
    return getHighestQualityMedia(content.downloadable_media, content.content_type);
  } else {
    return getHighestQualityMedia(content.media, content.content_type);
  }
};

export const getDownloadableMediaURL = (content: ILicensedContent): string => {
  const media = getDownloadableMedia(content);
  if (media) {
    return media.url;
  } else if (content.social_post) {
    return content.social_post[content.content_type];
  }
};

export const getImage = (content: ILicensedContent): string => {
  if (content.social_account) {
    return content.social_post.image;
  }
  if (size(content.image) > 0) {
    return content.image;
  }
  if (size(content.image_link) > 0) {
    return content.image_link;
  }
  if (size(content.media) > 0) {
    return content.media[0].url;
  }
  return null;
};

const canBrowserPreviewContent = (content: ILicensedContent): boolean => {
  return includes(PREVIEWABLE_FILE_TYPES, last(split(content.image, '.')));
};

export const getPreviewableMediaUrl = (content: ILicensedContent, resolution: string): string => {
  /** We check against canBrowserPreviewContent(content) because some file types cannot be displayed in browser.
   *  If it cannot, we need to find a media that is previewable (based on the resolution provided)
   */
  if (resolution && size(content.media) > 0 && !canBrowserPreviewContent(content)) {
    const media = filter(content.media, { resolution });
    if (size(media) > 0) {
      return media[0].url;
    }
  }
  return getImage(content);
};

export const getDetailedMediaUrl = (content: ILicensedContent): string =>
  getPreviewableMediaUrl(content, PREVIEW_RESOLUTION_ORIGINAL);

export enum MediaTypes {
  MEDIA_IMAGE,
  MEDIA_VIDEO,
  SOCIAL_POST_INSTAGRAM,
  SOCIAL_POST_YOUTUBE,
  SOCIAL_POST_SNAPCHAT,
}

export const getMediaTypeForContent = (content: ILicensedContent): MediaTypes => {
  if (!content) {
    return null;
  }

  if (!content.social_post && content.content_type === 'image') {
    return MediaTypes.MEDIA_IMAGE;
  } else if (!content.social_post && content.content_type === 'video') {
    return MediaTypes.MEDIA_VIDEO;
  } else if (content.social_post && content.social_post.network_identifier === 'youtube') {
    return MediaTypes.SOCIAL_POST_YOUTUBE;
  } else if (content.social_post && content.social_post.network_identifier === 'instagram') {
    return MediaTypes.SOCIAL_POST_INSTAGRAM;
  } else if (content.social_post && content.social_post.network_identifier === 'snapchat') {
    return MediaTypes.SOCIAL_POST_SNAPCHAT;
  }

  // Default show image
  return MediaTypes.MEDIA_IMAGE;
};

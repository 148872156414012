import * as React from 'react';
import { find, get, isFinite, nth, pick, startCase } from 'lodash';

import { MultiRangeSlider } from 'src/widgets/Slider';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { FilterSection } from '../FilterSection';
import { IEngagementFilters, IEngagementOptions, TRange } from '../models';
import { engagementRatioLimits } from '../utils/searchParams';

const { useMemo } = React;
import styles from './EngagementSection.scss';

const FILTERS = ['reachRange', 'engagementRange', 'impressionRange', 'engagementRatioRange'];

const pickFilters = (obj: any): IEngagementFilters => pick(obj, FILTERS);

interface IProps extends IEngagementFilters, IEngagementOptions {
  network?: TNetworkIdentifier;
  onChange(engagement: IEngagementFilters);
}

const isRangeValid = (range: any) => {
  return range && isFinite(range[0]) && isFinite(range[1]);
};

const EngagementSection: React.FunctionComponent<IProps> = (props) => {
  const {
    engagementLimitsByNetwork,
    engagementRange: engagementRangeProp,
    engagementStep,
    hideEngagement,
    hide: hideProp,
    isExpanded,
    network = 'instagram',
    networkInfos,
    onChange,
    reachLimitsByNetwork,
    reachRange: reachRangeProp,
    reachStep,
    impressionLimitsByNetwork,
    impressionRange: impressionRangeProp,
    impressionStep,
    hideImpression,
    engagementRatioRange: engagementRatioRangeProp,
    hideEngagementRatio,
  } = props;

  const reachLimits = get(reachLimitsByNetwork, network);
  const engagementLimits = get(engagementLimitsByNetwork, network);
  const impressionLimits = get(impressionLimitsByNetwork, network);

  const reachRange = reachRangeProp || reachLimits;
  const engagementRange = engagementRangeProp || engagementLimits;
  const impressionRange = impressionRangeProp || impressionLimits;
  const engagementRatioRange = engagementRatioRangeProp || engagementRatioLimits as TRange;

  const hideReachSlider = !isRangeValid(reachRange);
  const hideEngagementSlider = hideEngagement || !isRangeValid(engagementRange);
  const hideImpressionSlider = hideImpression || !isRangeValid(impressionRange);
  const hideEngagementRatioSlider = hideEngagementRatio || !isRangeValid(engagementRatioRange);

  const handleChangeReach = (reachRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      reachRange,
    };
    onChange(newFilters);
  };

  const handleChangeEngagement = (engagementRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      engagementRange,
    };
    onChange(newFilters);
  };

  const handleChangeImpression = (impressionRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      impressionRange,
    };
    onChange(newFilters);
  };

  const handleChangeEngagementRatio = (engagementRatioRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      engagementRatioRange,
    };
    onChange(newFilters);
  }

  const {
    audienceNamePlural,
    engagementNamePlural,
    contentName
  } = useMemo(() => (
    find(networkInfos, { identifier: network }) || {
      audienceNamePlural: '',
      engagementNamePlural: '',
      contentName: ''
    }
  ), [networkInfos, network]);

  const hide = hideProp || 
    (hideEngagementSlider && hideReachSlider && hideImpressionSlider && hideEngagementRatioSlider);

  return (
    <FilterSection
      header="Reach & Engagement"
      hide={hide}
      collapsible={true}
      defaultIsOpen={isExpanded}
    >
      <FilterSection isSubsection={true} hide={hideReachSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(audienceNamePlural)}
          defaultValue={reachRange}
          step={reachStep}
          min={nth(reachLimits, 0)}
          max={nth(reachLimits, 1)}
          onChange={handleChangeReach}
          onStoppedDragging={handleChangeReach}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection={true} hide={hideEngagementSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(`${engagementNamePlural} per ${contentName}`)}
          defaultValue={engagementRange}
          step={engagementStep}
          min={nth(engagementLimits, 0)}
          max={nth(engagementLimits, 1)}
          onChange={handleChangeEngagement}
          onStoppedDragging={handleChangeEngagement}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection={true} hide={hideImpressionSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(`${engagementNamePlural} per ${contentName}`)}
          defaultValue={impressionRange}
          step={impressionStep}
          min={nth(impressionLimits, 0)}
          max={nth(impressionLimits, 1)}
          onChange={handleChangeImpression}
          onStoppedDragging={handleChangeImpression}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection={true} hide={hideEngagementRatioSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          formatStr={'0%'}
          label={startCase(`Engagement Rate`)}
          defaultValue={engagementRatioRange}
          step={0.01}
          min={nth(engagementRatioLimits, 0)}
          max={nth(engagementRatioLimits, 1)}
          onChange={handleChangeEngagementRatio}
          onStoppedDragging={handleChangeEngagementRatio}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
    </FilterSection>
  );
};

export default EngagementSection;

import { map, isNumber, size, chain } from 'lodash';
import { createSelector } from 'reselect';
import { IAdvertiserAccountAccessRequirement } from 'src/common/models/advertiserAccountAccessRequirement';
import { ISuggestedRecipient } from 'src/common';
import { IStore, IAdvertiserAccessTableRowData } from './models';
import { getAccessIsPending, getPublishersSelectedForUse } from '../utils/usage';
import { getAdvertiserAccessTableRowData } from '../utils/tableData';

export const shareLinkSelector = createSelector(
  (state: IStore) => state.accessId,
  (state: IStore) => state.brand,
  (state: IStore) => state.organization,
  (state: IStore) => state.managerId,
  (accessId, brand, organization, managerId) =>
    accessId
      ? `${window.location.origin}/brands/client_advertiser_access?access_id=${accessId}\
&bid=${brand.id}&token=${organization.id}&mid=${managerId}`
      : '',
);

const advertiserAccessUsageLimitSelector = (state: IStore) =>
  state.brand.advertiser_access_usage_limit;

export const publishersSelectedForUseSelector = createSelector(
  (state: IStore) => state.requirements,
  (requirements: IAdvertiserAccountAccessRequirement[]) =>
    getPublishersSelectedForUse(requirements),
);

export const currentMonthlyUsageSelector = createSelector(
  (state: IStore) => state.numberOfPublishersSelectedForUse,
  (state: IStore) => state.currentMonthlyUsage,
  (numberOfPublishersSelectedForUse, currentMonthlyUsage) => {
    return Math.max(numberOfPublishersSelectedForUse, currentMonthlyUsage);
  },
);

export const selectionLimitReachedSelector = createSelector(
  publishersSelectedForUseSelector,
  advertiserAccessUsageLimitSelector,
  (publishersSelectedForUse, limit) => isNumber(limit) && size(publishersSelectedForUse) >= limit,
);

export const advertiserAccessTableRowDataSelector = createSelector(
  (state: IStore) => state.requirements,
  (state: IStore) => state.currentMonthlyUsage,
  advertiserAccessUsageLimitSelector,
  (requirements, currentMonthlyUsage, limit) =>
    map(
      getAdvertiserAccessTableRowData(requirements),
      (rowData): IAdvertiserAccessTableRowData => ({
        ...rowData,
        pending:
          isNumber(limit) &&
          getAccessIsPending(
            rowData.selectedForUse,
            rowData.readyForUse,
            currentMonthlyUsage >= limit,
          ),
      }),
    ),
);

export const suggestedRecipientsSelector = createSelector(
  (state: IStore) => state.organization,
  (organization) =>
    chain(organization.managers)
      .filter((manager) => !!(manager.email && manager.full_name))
      .map((manager) => ({
        email: manager.email,
        name: manager.full_name,
      }))
      .value() as ISuggestedRecipient[],
);

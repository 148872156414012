import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const OfferBlueIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 18.4">
    <path
      d="M22,10.93c0,4.48-4.48,8.12-10,8.12a12.09,12.09,0,0,1-3.5-.51L2.75,21.2,5,16.73a7.46,7.46,0,0,1-3-5.8C2,6.44,6.48,2.8,12,2.8s10,3.64,10,8.13"
      transform="translate(-2 -2.8)"
      fill="#fdfdfd"
    />
    <path
      d="M14.51,8.42H9.4a.44.44,0,0,1,0-.88h5.11a.44.44,0,1,1,0,.88Z"
      transform="translate(-2 -2.8)"
      fill="#3996e0"
    />
    <path
      d="M14.51,14.33H9.4a.44.44,0,0,1,0-.87h5.11a.44.44,0,1,1,0,.87Z"
      transform="translate(-2 -2.8)"
      fill="#3996e0"
    />
    <path
      d="M16.44,11.42H7.54a.44.44,0,1,1,0-.88h8.9a.44.44,0,0,1,0,.88Z"
      transform="translate(-2 -2.8)"
      fill="#3996e0"
    />
  </SvgIcon>
));

export { OfferBlueIcon };

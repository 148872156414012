import * as React from 'react';
import cx from 'classnames';

import { Notice } from 'src/widgets/Notice';

import styles from './MassTermsFeatureNotice.scss';

interface IProps {
  classNames?: string[];
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class MassTermsFeatureNotice extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;

    return (
      <Notice
        className={cx(styles.MassTermsFeatureNotice, classNames)}
        type="feature"
        showDivider={true}
      >
        <div>
          <span>New: </span>
          Send multiple terms at once with mass terms.
          <a
            href="https://www.aspireiq.com/blog/speed-up-your-collaborations-with-mass-terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Read our blog post{' '}
          </a>
          for more information.
        </div>
      </Notice>
    );
  }
}

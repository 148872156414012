import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const RecentlyLikedIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polyline
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="0.5 4.6368 2.409 7.4998 4.636 4.9548"
      ></polyline>
      <path
        d="M2.4131,7.5001 C1.9321,3.6281 4.8951,0.6271 8.2321,0.5041 C11.5691,0.3811 14.3731,2.9861 14.4961,6.3231 C14.5051,6.5691 14.4991,6.8131 14.4791,7.0531"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <polyline
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="8.4546 3.3595 8.4546 6.8595 11.6366 6.8595"
      ></polyline>
      <path
        d="M14.625,20.8644 C14.625,20.8644 19.75,16.8964 19.75,13.1424 C19.75,9.3874 15.203,9.0984 14.625,12.8534 C14.047,9.0984 9.5,9.3874 9.5,13.4304 C9.5,17.4744 14.625,20.8644 14.625,20.8644 Z"
        fill="#E50035"
      ></path>
    </g>
  </SvgIcon>
));

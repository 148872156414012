import * as React from 'react';
import { isFunction } from 'lodash';

import { FetchContext } from '../context/FetchContext';

const { useEffect, useCallback, useContext, useMemo, useState } = React;

interface IFetchingResult {
  loading: boolean;
  data: any;
  error: Error;
}

export interface IFetchResult<T> {
  status?: {
    code: number;
  };
  data?: T;
}

const InitialFetching: IFetchingResult = {
  loading: false,
  data: null,
  error: null,
};

type TRefetchFunction<T> = () => Promise<T>;

interface IExtraResults<T> {
  refetch: TRefetchFunction<T>;
}

interface IFetchOptions<T> {
  url: string;
  skip?: boolean;
  cache?: boolean;
  onFetch?: () => void;
  onSuccess?: (data: T) => void;
  onFail?: (error: Error) => void;
}

export function useGet<T extends any = any>(params: IFetchOptions<T>): {
  loading: boolean;
  data: T;
  error: Error;
  refetch(): Promise<T>;
} {
  const {
    url,
    skip = false,
    cache = true,
    onFetch,
    onSuccess,
    onFail,
  } = params;

  const {
    fetching,
    fetchingResult,
    updateFetchingResult,
  } = useContext(FetchContext);

  const [extraResults, setExtraResults] = useState<IExtraResults<T>>();

  const setByKey = useCallback((key: string, value: any) => {
    if (url) {
      updateFetchingResult((r) => ({
        ...r,
        [url]: {
          ...(r[url] || InitialFetching),
          [key]: value,
        },
      }));
    }
  }, [url, updateFetchingResult]);

  const setLoading = useCallback((loading: boolean) => setByKey('loading', loading), [setByKey]);
  const setData = useCallback((data: any) => setByKey('data', data), [setByKey]);
  const setError = useCallback((error: Error) => setByKey('error', error), [setByKey]);

  const options = useMemo<RequestInit>(() => {
    return {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
  }, []);

  useEffect(() => {
    const hasCache = !!fetching[url];
    const skipLoading = !url || skip || (cache && hasCache);

    if (!skipLoading) {
      fetching[url] = true;

      updateFetchingResult((r) => ({
        ...r,
        [url]: InitialFetching,
      }));

      const fetchResults = () => {
        setLoading(true);
        if (isFunction(onFetch)) {
          onFetch();
        }
        return fetch(url, options)
          .then((resp): Promise<IFetchResult<T>> => resp.json())
          .then((json) => {
            setLoading(false);

            if (json.status && json.status.code === 200) {
              const { data } = json;
              setData(data);
              if (isFunction(onSuccess)) {
                onSuccess(data);
              }
              return data;
            } else {
              throw new Error(`Unexpected response: ${json}`);
            }
          })
          .catch((err) => {
            setLoading(false);
            setError(err);
            if (isFunction(onFail)) {
              onFail(err);
            }
            throw err;
          });
      };

      fetchResults();
      setExtraResults((r) => ({ ...r, refetch: fetchResults }));
    }
  }, [url, skip, options]);

  const result = fetchingResult[url];

  return useMemo(() => ({
    ...(result || InitialFetching),
    ...extraResults,
  }), [result, extraResults]);
}

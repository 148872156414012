import * as React from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import { GaugeChart } from 'src/widgets/charts';
import { Notice } from 'src/widgets/Notice';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../../useAnalyze';
import { useAnalyzeCompare } from '../../../useAnalyzeCompare';
import {
  useFetchSummaryData,
  useFetchSummaryDataCompare,
} from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

import styles from './AudienceAuthSection.scss';
export interface IAudienceAuthData {
  audience_authenticity_pct: number;
}

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const AudienceAuthSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const { filters: compareFilters, isComparing } = useAnalyzeCompare();
    const { loading, data, error } = useFetchSummaryData<IAudienceAuthData>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-auth`,
      isAspirex,
      filters,
    );

    const {
      data: dataCompare,
      loading: loadingCompare,
    } = useFetchSummaryDataCompare<IAudienceAuthData>(
      isComparing,
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-auth`,
      isAspirex,
      compareFilters
    );

    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const dataReturnedEmpty = data && data.audience_authenticity_pct === null;

    return (
      <div className={cx(styles.AudienceAuthSection, props.className)}>
        {(loading || loadingCompare) && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading || loadingCompare}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>Audience Authenticity</h3>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className={styles.main}>
                <GaugeChart
                  percentage={get(data, 'audience_authenticity_pct')}
                />
              </div>
              {isComparing && (
                <div className={styles.main}>
                  <GaugeChart
                    percentage={get(dataCompare, 'audience_authenticity_pct')}
                    color={{ from: '#ffe9d9', to: '#EA92AF' }}
                  />
                </div>
              )}
            </div>
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

AudienceAuthSection.defaultProps = {
  className: null,
};

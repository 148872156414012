import * as React from 'react';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';
import cx from 'classnames';
import { find, isEmpty, isNil, times, uniq } from 'lodash';

import {
  ArrowLeftIcon,
  CloseIcon,
  EditIcon,
  ExportIcon,
  ImageIcon,
  LayoutGridIcon,
  LayoutListIcon,
  SearchIcon,
  SpinnerIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
  SubmitButton,
} from 'src/widgets/Button';
import { CreatorDetailOverlay } from 'src/widgets/CreatorDetailOverlay';
import { Input } from 'src/widgets/Input';
import { LazyImage } from 'src/widgets/Image';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Progress } from 'src/widgets/Progress';
import {
  Select,
  MultiSelect,
} from 'src/widgets/Select';
import {
  Toast,
  IToastRefHandles,
} from 'src/widgets/Toast';
import { SocialProfileOverlay } from 'src/widgets/SocialProfile/SocialProfileOverlay';
import { willShowSPv2 } from 'src/utils/connectUtils';
import { ErrorBoundary } from 'src/utils';

import GridView from './GridView';
import TableView from './TableView';
import SocialPostView from './SocialPostView';
import { ProposalView, IComment } from './ProposalView';
import { CsvSelector } from './CsvSelector';

import getManagerName from 'src/common/utils/getManagerName';
import connectNames from 'src/common/utils/connectNames';
import addEventLog from 'src/common/utils/addEventLog';

/**
 * types
 */
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { IClientProposal } from 'src/common/models/clientProposal';
import { hasFeature } from 'src/common/utils/organizationHasFeature';

import { IFavoriteListPage } from './redux/favoriteListPageModel';
import favoriteListPageActions, { FLPThunkDispatch } from './redux/favoriteListPageActions';

import styles from './FavoriteListPage.scss';
const ASSETS = process.env.ASSETS;
const noFavoriteListImage = `${ASSETS}/no_favorites_visual.png`;

export interface IOwnProps {
  allowSharing: boolean;

  sendOffer(accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  sendBulkOffer(socialAccounts: ISocialAccount[]);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToSearch();
  exportCsv(socialAccounts: ISocialAccount[]);
  uploadCsv(file: File);
  reportAsIncorrect(accountName: string);

  initialListId?: number;
  classNames?: string[];
}
interface IStateProps {
  apiEndpoint: string;
  isQa: boolean;
  brandId: number;
  managerId: number;
  campaign: ICampaign;
  org: IOrganization;

  favoriteLists: IFavoriteList[];
  selectedListId: number;
  socialAccounts: ISocialAccount[];

  // loading flags
  isFetchingFavoriteLists: boolean;
  isEditingFavoriteList: boolean;
}
interface IDispatchProps {
  setSelectedListId(id: number);
  fetchFavoriteList(): Promise<IFavoriteList[]>;
  fetchListContents(listId: number, page: number): Promise<boolean>;
  fetchProposalsForList(listId: number): Promise<IClientProposal[]>;
  inviteToCampaign(socialAccount: ISocialAccount): Promise<void>;
  createFavoriteList(name: string): Promise<void>;
  addToFavoriteList(accountId: number, listId: number): Promise<void>;
  renameFavoriteList(id: number, name: string): Promise<void>;
  deleteFavoriteList(id: number): Promise<void>;
  createProposal(listId: number, comments: IComment[]): Promise<IClientProposal>;
  sendProposal(proposalId: number, emails: string[], note: string): Promise<void>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;
type TDefaultProp = 'initialListId' | 'classNames';

type TDisplayMode = 'grid' | 'table' | 'post';
type TEditListMode = 'add' | 'rename' | 'delete';
interface IState {
  selectedMode: TDisplayMode;
  editMode: TEditListMode;
  isFetchingSocialAccounts: boolean;
  isFetchingProposals: boolean;
  proposals: IClientProposal[];
  selectedProposalIndices: number[];

  newListName: string;
  editingList: IFavoriteList;
  // keep a copy of flags, to reset state
  isEditingFavoriteList: boolean;

  // selected social accounts
  selectedSocialAccounts: ISocialAccount[];

  // detail view
  selectedSocialAccountId: number | null;
  showDetailView: boolean;

  isSendingProposal: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
class FavoriteListPage extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    initialListId: null,
    classNames: [],
  };

  private toastRef: React.RefObject<IToastRefHandles>;
  private fetchProposalsPromise: Promise<void>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();

    this.state = {
      selectedMode: 'grid',
      editMode: null,
      selectedProposalIndices: [],
      isFetchingSocialAccounts: false,
      isFetchingProposals: false,
      proposals: [],

      newListName: '',
      editingList: null,

      isEditingFavoriteList: false,

      selectedSocialAccounts: [],

      selectedSocialAccountId: null,
      showDetailView: false,

      isSendingProposal: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const { fetchFavoriteList, initialListId } = this.props;

    fetchFavoriteList().then((list) => {
      if (!isEmpty(list)) {
        if (initialListId && list.find((favoriteList) => favoriteList.id === initialListId)) {
          // try initialize with specified list id
          return this.fetchContentsForList(initialListId);
        } else {
          // if not, select the first list
          return this.fetchContentsForList(list[0].id);
        }
      }
    });
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps, prevState: IFavoriteListPage) {
    if (prevState.isEditingFavoriteList && !nextProps.isEditingFavoriteList) {
      return {
        isEditingFavoriteList: nextProps.isEditingFavoriteList,
        editMode: null,
        newListName: '',
        editingList: null,
      };
    }

    return {
      isEditingFavoriteList: nextProps.isEditingFavoriteList,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      brandId,
      org,
      managerId,
      socialAccounts,
      favoriteLists,
      selectedListId,
      createProposal,
      sendProposal,
      classNames,
    } = this.props;
    const { isSendingProposal } = this.state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === selectedListId);

    return (
      <div className={cx(classNames.concat(styles.FavoriteListPage))}>
        {!isSendingProposal && this.renderCreatorView()}
        {isSendingProposal && (
          <ProposalView
            org={org}
            brandId={brandId}
            managerId={managerId}
            goBackToCreatorView={this.goToCreatorView}
            socialAccounts={socialAccounts}
            createProposal={createProposal}
            sendProposal={sendProposal}
            favoriteList={favoriteList}
          />
        )}
        <Toast ref={this.toastRef} />
      </div>
    );
  }

  /**
   * @private
   * Renders the creator list view.
   */
  private renderCreatorView = () => {
    const {
      allowSharing,
      apiEndpoint,
      campaign,
      exportCsv,
      favoriteLists,
      goToManage,
      isEditingFavoriteList,
      isFetchingFavoriteLists,
      org,
      reportAsIncorrect,
      selectedListId,
      sendBulkOffer,
      sendOffer,
      socialAccounts,
      uploadCsv,
    } = this.props;
    const {
      selectedMode,
      editMode,
      selectedProposalIndices,
      proposals,
      newListName,
      isFetchingSocialAccounts,
      isFetchingProposals,
    } = this.state;
    const selectedListIndex = favoriteLists.findIndex(
      (favoriteList) => favoriteList.id === selectedListId,
    );
    const selectedList = favoriteLists[selectedListIndex];

    const options = (favoriteLists || []).map((favoriteList, index) => ({
      label: `${favoriteList.name} (${favoriteList.element_count})`,
      value: favoriteList.id,
      actions: [
        <EditIcon
          key={`EditIcon-${index}`}
          size={12}
          onClick={this.setEditModeRename.bind(this, favoriteList)}
        />,
        <CloseIcon
          key={`CloseIcon-${index}`}
          size={14}
          onClick={this.setEditModeDelete.bind(this, favoriteList)}
        />,
      ],
    }));
    const allowFavorite = hasFeature(org, 'advanced_connect');
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const hasFavoriteList = options.length > 0;
    const selectedReviewers = [];
    const proposalOptions = proposals
      .filter((proposal) => !isEmpty(proposal.reviews))
      .map((proposal, index) => {
        if (selectedProposalIndices.includes(index)) {
          selectedReviewers.push(...proposal.reviews.map((review) => review.user.id));
        }
        const reviewerNames = uniq(proposal.reviews.map((review) => getManagerName(review.user)));

        return {
          label: isEmpty(reviewerNames)
            ? `Proposal created on ${proposal.date_created}`
            : `${connectNames(reviewerNames)} (${proposal.date_created})`,
          value: proposal.date_created,
        };
      });

    return (
      <div>
        {isFetchingFavoriteLists && <LoadSpinner />}
        {!isFetchingFavoriteLists && hasFavoriteList && (
          <ErrorBoundary>
            <div className={styles.header}>
              <Select
                options={options}
                hideOptions={!!editMode}
                selectedIndex={selectedListIndex}
                onChange={this.fetchContentsForList}
                onMenuClose={this.unsetEditMode}
                theme="info"
                disabled={isFetchingSocialAccounts}
                className={styles.select}
              >
                <div className={styles.SelectFooter}>
                  {!editMode && (
                    <div className={styles.addList} onClick={this.setEditModeAdd}>
                      <div className={styles.addIconWrapper}>
                        <CloseIcon size={12} className={styles.addIcon} />
                      </div>
                      New List...
                    </div>
                  )}
                  {editMode === 'add' && (
                    <div className={styles.editList}>
                      <div className={styles.back}>
                        <IconButton
                          className={styles.goBackButton}
                          onClick={this.unsetEditMode}
                          icon={<ArrowLeftIcon className={styles.arrowIcon} />}
                        />
                        Back to Existing Lists
                      </div>
                      <Input
                        className={styles.editContent}
                        onChange={this.onNewListNameChange}
                        onPressEnter={this.confirmAddList}
                        defaultValue={newListName}
                        placeholder="Name your list..."
                      />
                      <SubmitButton
                        className={styles.button}
                        label="Create"
                        submittingLabel="Creating..."
                        isSubmitting={isEditingFavoriteList}
                        onClick={this.confirmAddList}
                        disabled={isEmpty(newListName) || isEditingFavoriteList}
                        fullWidth={true}
                        round={false}
                      />
                    </div>
                  )}
                  {editMode === 'rename' && (
                    <div className={styles.editList}>
                      <div className={styles.back}>
                        <IconButton
                          className={styles.goBackButton}
                          onClick={this.unsetEditMode}
                          icon={<ArrowLeftIcon className={styles.arrowIcon} />}
                        />
                        Rename Your List
                      </div>
                      <Input
                        className={styles.editContent}
                        onChange={this.onNewListNameChange}
                        onPressEnter={this.confirmRenameList}
                        theme="info"
                        defaultValue={newListName}
                      />
                      <SubmitButton
                        className={styles.button}
                        label="Save"
                        submittingLabel="Saving..."
                        isSubmitting={isEditingFavoriteList}
                        onClick={this.confirmRenameList}
                        disabled={isEmpty(newListName) || isEditingFavoriteList}
                        fullWidth={true}
                      />
                    </div>
                  )}
                  {editMode === 'delete' && (
                    <div className={styles.editList}>
                      <div className={styles.back}>
                        <IconButton
                          className={styles.goBackButton}
                          onClick={this.unsetEditMode}
                          icon={<ArrowLeftIcon className={styles.arrowIcon} />}
                        />
                        Delete Your List?
                      </div>
                      <div className={styles.editContent}>
                        Are you sure you want to delete list{' '}
                        <span className={styles.listName}>{newListName}</span>?
                      </div>
                      <SubmitButton
                        className={styles.button}
                        label="Yes, delete"
                        submittingLabel="Deleting..."
                        isSubmitting={isEditingFavoriteList}
                        onClick={this.confirmDeleteList}
                        theme="danger"
                        disabled={isEditingFavoriteList}
                        fullWidth={true}
                      />
                    </div>
                  )}
                </div>
              </Select>
              {!selfServeExperiment && <CsvSelector classNames={[styles.csvUpload]} uploadCsv={uploadCsv} />}
              {allowSharing && (
                <Button
                  className={styles.shareButton}
                  label="Share"
                  theme="info"
                  round={true}
                  icon={<ExportIcon size={16} />}
                  disabled={isEmpty(socialAccounts) || isFetchingSocialAccounts}
                  onClick={this.goToProposalView}
                />
              )}
              <div className={styles.admin} onClick={this.openAdminPage}>
                Admin Page
              </div>
              {this.renderModeSelection()}
            </div>
            {isFetchingSocialAccounts && (
              <Progress
                percentage={Math.round((socialAccounts.length / selectedList.element_count) * 100)}
                type="info"
                label={`Fetching creators...(${socialAccounts.length}/${selectedList.element_count})`}
                className={styles.progress}
              />
            )}
            {!isFetchingSocialAccounts && (
              <div className={styles.body}>
                {selectedMode === 'grid' && (
                  <ErrorBoundary>
                    {isFetchingProposals && this.renderFetchingProposalsNotice()}
                    {!isFetchingProposals && !isEmpty(proposalOptions) && (
                      <div className={styles.selectProposal}>
                        <div className={styles.selectMessage}>Showing Feedback From</div>
                        <MultiSelect
                          classNames={[styles.proposalSelect]}
                          selectedIndices={selectedProposalIndices}
                          options={proposalOptions}
                          onChange={this.setSelectedProposalIndices}
                          label={`${uniq(selectedReviewers).length} collaborators...`}
                          theme="info"
                        />
                      </div>
                    )}
                    <GridView
                      addToFavoriteList={this.addToFavoriteList}
                      allowFavorite={allowFavorite}
                      apiEndpoint={apiEndpoint}
                      campaign={campaign}
                      createFavoriteList={this.createFavoriteList}
                      goToManage={goToManage}
                      inviteToCampaign={this.inviteToCampaign}
                      onCreatorSelected={this.onCreatorSelected}
                      proposals={proposals}
                      reportAsIncorrect={reportAsIncorrect}
                      selectedProposalIndices={selectedProposalIndices}
                      sendOffer={sendOffer}
                      showCreateFeatures={true}
                      selfServeExperiment={selfServeExperiment}
                    />
                  </ErrorBoundary>
                )}
                {selectedMode === 'table' && (
                  <ErrorBoundary>
                    <TableView
                      onCreatorSelected={this.onCreatorSelected}
                      goToManage={goToManage}
                      sendBulkOffer={sendBulkOffer}
                      exportCsv={exportCsv}
                    />
                  </ErrorBoundary>
                )}
                {selectedMode === 'post' && (
                  <ErrorBoundary>
                    <SocialPostView />
                  </ErrorBoundary>
                )}
                {isEmpty(socialAccounts) && (
                  <Notice className={styles.notice} type="disabled">
                    You have not added any creators to this favorites list yet.
                  </Notice>
                )}
              </div>
            )}
          </ErrorBoundary>
        )}
        {!isFetchingFavoriteLists && !hasFavoriteList && this.renderNoFavoriteListMessage()}
        {this.renderDetailOverlay()}
      </div>
    );
  };

  private renderDetailOverlay = () => {
    const {
      apiEndpoint,
      campaign,
      org,
      isQa,
      favoriteLists,
      socialAccounts,
      sendOffer,
      goToManage,
      reportAsIncorrect,
    } = this.props;
    const { showDetailView, selectedSocialAccountId } = this.state;

    const selectedSocialAccount = find(
      socialAccounts,
      (socialAccount) => socialAccount.id === selectedSocialAccountId,
    );
    const allowFavorite = hasFeature(org, 'advanced_connect');
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const show = showDetailView && (!isNil(selectedSocialAccountId) || !isEmpty(selectedSocialAccount));

    return willShowSPv2(selectedSocialAccount)
      ? (
        <SocialProfileOverlay
          campaign={campaign}
          socialAccount={selectedSocialAccount}
          inviteToCampaign={this.inviteToCampaign}
          sendOffer={sendOffer}
          goToManage={goToManage}
          allowFavorite={allowFavorite}
          show={show}
          isQa={isQa}
          apiEndpoint={apiEndpoint}
          showSimilarCreators={true}
          toastRef={this.toastRef}
          reportAsIncorrect={reportAsIncorrect}
          onRequestClose={this.closeDetailView}
          selfServeExperiment={selfServeExperiment}
        />
      )
      : (
        <CreatorDetailOverlay
          org={org}
          campaign={campaign}
          apiEndpoint={apiEndpoint}
          loadDetail={true}
          loadRelated={true}
          show={showDetailView}
          socialAccount={selectedSocialAccount}
          onRequestClose={this.closeDetailView}
          favoriteLists={favoriteLists}
          isQa={isQa}
          sendOffer={sendOffer}
          goToManage={goToManage}
          reportAsIncorrect={reportAsIncorrect}
          inviteToCampaign={this.inviteToCampaign}
          addToFavoriteList={this.addToFavoriteList}
          createFavoriteList={this.createFavoriteList}
          selfServeExperiment={selfServeExperiment}
        />
      );
  };

  /**
   * @private
   * Renders the no favorite list visual, when there's no favorite list.
   *
   * @return {JSX}
   */
  private renderNoFavoriteListMessage = () => {
    const { goToSearch, uploadCsv } = this.props;

    return (
      <div className={styles.noFavoriteList}>
        <div className={styles.title}>Add Creators to a Favorites List!</div>
        <div className={styles.text}>
          If you’d like to get more organized, look for the star icon near creator names in search
          and other Connect tabs to add creators to your very own Favorite Creators lists.
        </div>
        <div className={styles.actions}>
          <Button
            label="Search Creators"
            className={styles.searchButton}
            onClick={goToSearch}
            round={true}
            icon={<SearchIcon size={20} />}
          />
          <CsvSelector uploadCsv={uploadCsv} />
        </div>
        <LazyImage className={styles.image} src={noFavoriteListImage} />
      </div>
    );
  };

  /**
   * @private
   * Renders the notice when is fetching proposals for list.
   *
   * @return {JSX}
   */
  private renderFetchingProposalsNotice = () => {
    return (
      <div className={styles.fetchingProposalsNotice}>
        <div className={styles.message}>Fetching collaborator feedback...</div>
        <SpinnerIcon className={styles.spinnerIcon} />
      </div>
    );
  };

  /**
   * @private
   * Renders the mode selection.
   */
  private renderModeSelection = () => {
    const { selectedMode } = this.state;

    return (
      <div className={styles.mode}>
        <div
          className={cx(styles.modeItem, {
            [styles.active]: selectedMode === 'grid',
          })}
          onClick={this.selectMode.bind(this, 'grid')}
        >
          <LayoutGridIcon size={14} />
        </div>
        <div
          className={cx(styles.modeItem, {
            [styles.active]: selectedMode === 'table',
          })}
          onClick={this.selectMode.bind(this, 'table')}
        >
          <LayoutListIcon size={14} />
        </div>
        <div
          className={cx(styles.modeItem, {
            [styles.active]: selectedMode === 'post',
          })}
          onClick={this.selectMode.bind(this, 'post')}
        >
          <ImageIcon size={14} />
        </div>
      </div>
    );
  };

  private onCreatorSelected = (socialAccount: ISocialAccount) => {
    this.setState({
      selectedSocialAccountId: socialAccount.id,
      showDetailView: true,
    });
  };

  private closeDetailView = () => {
    this.setState({
      showDetailView: false,
    });
  };

  private goToProposalView = () => {
    const { socialAccounts } = this.props;

    addEventLog('create_agency_proposal', {
      count: socialAccounts.length,
    });

    this.setState({
      isSendingProposal: true,
    });
  };

  private goToCreatorView = () => {
    this.setState({
      isSendingProposal: false,
    });
  };

  private setEditModeAdd = () => {
    this.setState({
      editMode: 'add',
    });
  };

  private setEditModeRename = (favoriteList: IFavoriteList) => {
    this.setState({
      editMode: 'rename',
      newListName: favoriteList.name,
      editingList: favoriteList,
    });
  };

  private setEditModeDelete = (favoriteList: IFavoriteList) => {
    this.setState({
      editMode: 'delete',
      newListName: favoriteList.name,
      editingList: favoriteList,
    });
  };

  private unsetEditMode = () => {
    this.setState({
      editMode: null,
      newListName: '',
      editingList: null,
    });
  };

  /**
   * @private
   * Change the display mode.
   *
   * @param {TDisplayMode} selectedMode the new selected mode.
   */
  private selectMode = (selectedMode: TDisplayMode) => {
    this.setState({
      selectedMode,
    });
  };

  private setSelectedProposalIndices = (_, selectedProposalIndices) => {
    this.setState({
      selectedProposalIndices,
    });
  };

  /**
   * @private
   * Fetch new social accounts when selected list changes.
   *
   * @param {Number} selectedListId the new selected list index.
   */
  private fetchContentsForList = async (selectedListId: number) => {
    const { fetchListContents, setSelectedListId } = this.props;

    // cancel existing proposals fetching promise
    if (this.fetchProposalsPromise) {
      this.fetchProposalsPromise = null;
    }

    setSelectedListId(selectedListId);
    // also reset page
    this.setState({
      isFetchingSocialAccounts: true,
      isFetchingProposals: false,
      proposals: [],
      selectedProposalIndices: [],
    });

    let hasNext = true;
    let page = 0;
    try {
      // keeps fetching until there's no more
      while (hasNext) {
        console.log('fetching page', page);
        hasNext = await fetchListContents(selectedListId, page);

        page += 1;
      }
    } catch (err) {
      console.log(err);

      return;
    }

    this.setState({
      isFetchingSocialAccounts: false,
    });

    await this.fetchProposalsForList();
  };

  private fetchProposalsForList = () => {
    const { fetchProposalsForList, socialAccounts, selectedListId } = this.props;

    // no need to fetch proposals if list is empty
    if (isEmpty(socialAccounts)) {
      return;
    }

    this.setState({
      isFetchingProposals: true,
    });
    this.fetchProposalsPromise = fetchProposalsForList(selectedListId)
      .then((proposals: IClientProposal[]) => {
        this.setState({
          proposals,
          isFetchingProposals: false,
          selectedProposalIndices: times(proposals.length),
        });
      })
      .catch(() => {
        this.setState({
          isFetchingProposals: false,
        });
      });

    // avoid run-away promise
    return this.fetchProposalsPromise;
  };

  private onNewListNameChange = (newListName) => {
    this.setState({
      newListName,
    });
  };

  private confirmAddList = () => {
    const { newListName } = this.state;

    this.createFavoriteList(newListName);
  };

  private inviteToCampaign = (socialAccount: ISocialAccount) => {
    const { socialAccounts, inviteToCampaign } = this.props;
    const toast = this.toastRef.current;
    const socialAccountToBeInvited = socialAccounts.find(
      (account) => account.id === socialAccount.id,
    );

    return inviteToCampaign(socialAccountToBeInvited)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Invited creator
                {socialAccountToBeInvited && (
                  <span>
                    {' '}
                    {socialAccountToBeInvited.name || socialAccountToBeInvited.username}{' '}
                  </span>
                )}
                to campaign.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to invite the creator to campaign.',
            type: 'error',
          });
        }
      });
  };

  private addToFavoriteList = (accountId: number, listId: number) => {
    const { socialAccounts, favoriteLists, addToFavoriteList } = this.props;
    const toast = this.toastRef.current;
    const socialAccount = socialAccounts.find((socialAccount) => socialAccount.id === accountId);
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    return new Bluebird((resolve, reject) => {
      return addToFavoriteList(accountId, listId)
        .then(() => {
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Added creator
                  {socialAccount && (
                    <span> {socialAccount.name || socialAccount.username} </span>
                  )}{' '}
                  to favorite list <span>{favoriteList.name}</span>.
                </div>
              ),
            });
          }

          resolve();
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to add creator to the favorite list.',
              type: 'error',
            });
          }

          reject();
        });
    });
  };

  private createFavoriteList = (name: string) => {
    const { createFavoriteList } = this.props;
    const toast = this.toastRef.current;

    return new Bluebird((resolve, reject) => {
      return createFavoriteList(name)
        .then(() => {
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Created new favorite list <span>{name}</span>.
                </div>
              ),
            });
          }

          resolve();
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to create new favorite list.',
              type: 'error',
            });
          }

          reject(new Error());
        });
    });
  };

  private confirmRenameList = () => {
    const { renameFavoriteList } = this.props;
    const { newListName, editingList } = this.state;
    const toast = this.toastRef.current;

    renameFavoriteList(editingList.id, newListName)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Renamed favorite list to <span>{newListName}</span>.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to rename the favorite list.',
            type: 'error',
          });
        }
      });
  };

  private confirmDeleteList = () => {
    const { deleteFavoriteList, selectedListId } = this.props;
    const { editingList } = this.state;
    const toast = this.toastRef.current;

    deleteFavoriteList(editingList.id)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Deleted favorite list <span>{editingList.name}</span>.
              </div>
            ),
          });
        }

        // if deleted list is current list
        if (selectedListId === editingList.id) {
          const { favoriteLists } = this.props;

          if (!isEmpty(favoriteLists)) {
            return this.fetchContentsForList(favoriteLists[0].id);
          }
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to delete the favorite list.',
            type: 'error',
          });
        }
      });
  };

  /**
   * @private
   * Opens the admin page of favorite list.
   */
  private openAdminPage = () => {
    const { selectedListId } = this.props;

    window.open(`/admin/social_account?favorites_list_id=${selectedListId}`, '_blank');
  };
}

const mapStateToProps = (state: IFavoriteListPage): IStateProps => {
  return {
    apiEndpoint: state.apiEndpoint,
    isQa: state.isQa,
    brandId: state.brandId,
    managerId: state.managerId,
    campaign: state.campaign,
    org: state.org,

    favoriteLists: state.favoriteLists,
    selectedListId: state.selectedListId,
    socialAccounts: state.socialAccounts,
    isFetchingFavoriteLists: state.isFetchingFavoriteLists,
    isEditingFavoriteList: state.isEditingFavoriteList,
  };
};
const mapDispatchToProps = (dispatch: FLPThunkDispatch): IDispatchProps => {
  return {
    setSelectedListId: (...args) => dispatch(favoriteListPageActions.setSelectedListId(...args)),
    fetchFavoriteList: (...args) => dispatch(favoriteListPageActions.fetchFavoriteList(...args)),
    fetchListContents: (...args) => dispatch(favoriteListPageActions.fetchListContents(...args)),
    fetchProposalsForList: (...args) =>
      dispatch(favoriteListPageActions.fetchProposalsForList(...args)),
    inviteToCampaign: (...args) => dispatch(favoriteListPageActions.inviteToCampaign(...args)),
    createFavoriteList: (...args) => dispatch(favoriteListPageActions.createFavoriteList(...args)),
    addToFavoriteList: (...args) =>
      dispatch(favoriteListPageActions.addSocialAccountsToFavoriteList(...args)),
    renameFavoriteList: (...args) => dispatch(favoriteListPageActions.renameFavoriteList(...args)),
    deleteFavoriteList: (...args) => dispatch(favoriteListPageActions.deleteFavoriteList(...args)),
    createProposal: (...args) => dispatch(favoriteListPageActions.createProposal(...args)),
    sendProposal: (...args) => dispatch(favoriteListPageActions.sendProposal(...args)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteListPage);

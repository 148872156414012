import * as React from 'react';
import cx from 'classnames';
import { find } from 'lodash';
import numeral from 'numeral';

import { Button } from 'src/widgets/Button';
import { Tooltip } from 'src/widgets/Tooltip';
import { NetworkIcon } from 'src/common';

import { IRelationState, INetworkInfo, IProductTypeInfo } from './MassTermsTypes';
import { TPostType } from 'src/common/models/postType';

import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import productDescription from 'src/common/utils/productDescription';

import styles from './MassTermsSummary.scss';

interface IProps {
  relationStates: IRelationState[];
  handleSend: () => void;
  networkInfo: INetworkInfo[];
  productTypeInfo: IProductTypeInfo[];
  sendingTerms: boolean;

  classNames?: string[];
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class MassTermsSummary extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  // FIXME: When I try to apply to make this the correct type (HTMLDivElement) I get an error, not sure if this is a
  // typescript problem.
  private readonly sendButtonRef: React.RefObject<any>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.sendButtonRef = React.createRef();
  }

  public render() {
    const { sendingTerms, relationStates, classNames } = this.props;

    const countByProduct = new Map();
    let totalCost = 0;
    let allRelationsOffers = true;
    let allSelected = true;
    for (const relationState of relationStates) {
      if (
        relationState.state === 'not_selected' ||
        relationState.state === 'no_accounts' ||
        relationState.state === 'already_collaborating'
      ) {
        // Only enable sending once the user has made a decision on already_collaborating relations.
        allSelected = relationState.state !== 'already_collaborating';
        continue;
      }
      for (const deliverable of relationState.deliverables) {
        if (deliverable.accounts.length === 0) {
          continue;
        }
        const selected = find(deliverable.accounts, (account) => account.selected);
        if (deliverable.accounts.length > 0) {
          if (countByProduct.has(deliverable.post_type)) {
            const productCounts = countByProduct.get(deliverable.post_type);
            countByProduct.set(deliverable.post_type, {
              count: productCounts.count + deliverable.count,
              engagement: productCounts.engagement + selected.engagement,
              reach: productCounts.reach + selected.reach,
            });
          } else {
            countByProduct.set(deliverable.post_type, {
              count: deliverable.count,
              engagement: selected.engagement,
              reach: selected.reach,
            });
          }
        }
      }

      if (relationState.offer !== null) {
        totalCost += relationState.offer;
      } else {
        allRelationsOffers = false;
      }
    }

    const summaryItems = [];
    const detailItems = [];
    for (const [postType, { count, engagement, reach }] of countByProduct) {
      summaryItems.push(
        <div className={styles.productSummaryItem}>
          <span className={styles.icon}>
            <NetworkIcon identifier={getNetworkByPostType(postType)} size={20} />
          </span>
          {productDescription(count, postType)}
        </div>,
      );

      detailItems.push(
        <div className={styles.productDetailItem}>
          <span className={styles.type}>{productDescription(count, postType)}</span>
          {this.engagementAndReachDescription(postType, engagement, reach)}
        </div>,
      );
    }

    const sendDisabled = !allSelected || !allRelationsOffers;

    return (
      <div className={cx(classNames.concat(styles.MassTermsSummary))}>
        <div className={styles.content}>
          <div className={styles.title}>Summary</div>
          {this.renderProductInfo(countByProduct)}
          <div className={styles.cost}>Total cost: {numeral(totalCost).format('$0,0')}</div>
          <div className={styles.tooltipWrapper} ref={this.sendButtonRef}>
            <Button
              onClick={this.props.handleSend}
              label="Send"
              disabled={sendingTerms || sendDisabled}
              fullWidth={true}
            />
          </div>
          {sendDisabled && (
            <Tooltip mountRef={this.sendButtonRef} tooltipColor="black">
              Please fill out all offer fields before sending (enter 0 if unpaid)
              {allSelected
                ? ''
                : ' and all existing publishers with collaborations have been removed or terms sent.'}
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  private renderProductInfo = (countByProduct) => {
    const summaryItems = [];
    const detailItems = [];
    for (const [postType, { count, engagement, reach }] of countByProduct) {
      summaryItems.push(
        <div className={styles.productSummaryItem}>
          <span className={styles.icon}>
            <NetworkIcon identifier={getNetworkByPostType(postType)} size={20} />
          </span>
          {productDescription(count, postType)}
        </div>,
      );

      detailItems.push(
        <div className={styles.productDetailItem}>
          <span className={styles.type}>{productDescription(count, postType)}</span>
          {this.engagementAndReachDescription(postType, engagement, reach)}
        </div>,
      );
    }

    return (
      <React.Fragment>
        <div className={styles.productSummary}>{React.Children.toArray(summaryItems)}</div>
        <div className={styles.productDetail}>{React.Children.toArray(detailItems)}</div>
      </React.Fragment>
    );
  };

  private engagementAndReachDescription(postType: TPostType, engagement: number, reach: number) {
    const productTypeInfo = find(
      this.props.productTypeInfo,
      (productTypeInfo) => productTypeInfo.identifier === postType.toLowerCase(),
    );

    const networkInfo = find(
      this.props.networkInfo,
      (networkInfo) => networkInfo.identifier === productTypeInfo.network_identifier,
    );
    if (networkInfo && engagement && reach) {
      // TODO: Disable engagement for now, since it doesn't work for things like instagram stories.
      // const engagementName = engagement === 1 ? networkInfo.engagementName : networkInfo.engagementNamePlural;
      return (
        <span>
          with expected {numeral(reach).format('Oa')} in reach
          {/*and {numeral(engagement).format('Oa')} {engagementName}*/}
        </span>
      );
    }
    return '';
  }
}

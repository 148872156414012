import * as React from 'react';
import cx from 'classnames';
import { findIndex, get, range, map } from 'lodash';

import { ArrowDownFilledIcon, GroupIcon } from 'src/icons';
import { Select } from 'src/widgets/Select';

import styles from './AudienceDropdown.scss';

const { useMemo, useEffect, useRef } = React;

interface IProps {
  step?: number;
  selectedPercentage?: number;
  lessThan?: boolean;
  disabled?: boolean;
  classNames?: string[];

  onChangePercentage(percentage: number);
  onChangeLessThan(lessThan: boolean);
}

interface ICustomLabelProps {
  label: string;
}

interface IPercentageOption {
  label: string;
  value: number;
}

interface ILogicalOperatorOption {
  label: string;
  value: number;
}

const generatePercentages = (step: number): IPercentageOption[] => {
  return map(range(step, 100, step), (value) => ({ label: `${value}%`, value }));
};

const LogicalOperators: ILogicalOperatorOption[] = [
  { label: 'Less than', value: 1 },
  { label: 'Greater than', value: 0 },
];

const CustomLabel: React.FunctionComponent<ICustomLabelProps> = (props) => {
  return (
    <div className={styles.customLabel}>
      <button>{props.label}</button>
      <ArrowDownFilledIcon className={styles.arrow} size={10} />
    </div>
  );
};

function cancelMouseEvent(ev: MouseEvent) {
  ev.preventDefault();
  ev.stopPropagation();
}

const AudienceDropdown: React.FunctionComponent<IProps> = (props) => {
  const {
    classNames,
    disabled,
    lessThan,
    onChangeLessThan,
    onChangePercentage,
    selectedPercentage,
    step,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const percentageOptions = useMemo(() => generatePercentages(step), [step]);

  const selectedPercentageIndex = useMemo(() => {
    return findIndex(percentageOptions, { value: selectedPercentage });
  }, [percentageOptions, selectedPercentage]);

  const logicalOperatorIndex = useMemo(() => {
    return findIndex(LogicalOperators, { value: lessThan ? 1 : 0 });
  }, [lessThan]);

  useEffect(() => {
    const current = ref.current;
    if (disabled) {
      current.addEventListener('mousedown', cancelMouseEvent);
    }

    return () => {
      current.removeEventListener('mousedown', cancelMouseEvent);
    };
  }, [disabled]);

  const handleChangeLessThan = (lessThan: number) => {
    onChangeLessThan(lessThan === 1);
  };

  return (
    <div
      ref={ref}
      className={cx(styles.AudienceDropdown, classNames, {
        [styles.disabled]: disabled,
      })}
    >
      <GroupIcon className={styles.audienceIcon} size={18} />
      <div>Audience</div>
      <Select
        className={styles.select}
        options={LogicalOperators}
        onChange={handleChangeLessThan}
        selectedIndex={logicalOperatorIndex}
        customLabelElement={
          <CustomLabel label={get(LogicalOperators[logicalOperatorIndex], 'label')} />
        }
        popoverProps={{
          minWidth: 100,
        }}
      />
      <Select
        className={styles.select}
        options={percentageOptions}
        onChange={onChangePercentage}
        selectedIndex={selectedPercentageIndex}
        customLabelElement={
          <CustomLabel label={get(percentageOptions[selectedPercentageIndex], 'label')} />
        }
        popoverProps={{
          minWidth: 100,
        }}
      />
    </div>
  );
};

AudienceDropdown.defaultProps = {
  step: 5,
  selectedPercentage: 50,
  lessThan: false,
  disabled: false,
};

export default AudienceDropdown;

import { ICampaign, ICampaignGroupContentReviewInfo } from 'src/common/models/campaign';
import endpoints from 'src/common/config/endpoints';

export interface ICampaignDataInfo {
  hasNext: boolean;
  campaigns: ICampaign[];
}

export const fetchCampaignData = async (
  apiEndpoint: string,
  brandId: number,
  page: number
): Promise<ICampaignDataInfo> => {
  const resp = await fetch(
    `${apiEndpoint}/${endpoints.campaignEndpoint}?brand_id=${brandId}&gcr_campaigns=true&page=${page}&additional_fields=brand_users`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return {
      hasNext: json.data.has_next,
      campaigns: json.data.data,
    }
  } else {
    throw new Error();
  }
};

export const fetchContentReviewDataForCampaign = async (
  apiEndpoint: string,
  campaignId: string,
): Promise<ICampaignGroupContentReviewInfo> => {
  const resp = await fetch(
    `${apiEndpoint}/${endpoints.contentReviewEndpoint}/gcr_info/campaign/${campaignId}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

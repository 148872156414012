import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import { CheckIcon, SearchIcon } from 'src/icons';
import { LazyImage } from 'src/widgets/Image';

import { useHover } from 'src/utils/hooks';

import { ISocialPost } from 'src/common/models/socialPost';

const { useRef } = React;
import styles from './StoryItem.scss';

interface IProps {
  isMobile: boolean;
  isCompact: boolean;

  story: ISocialPost;
  brandInstagramName: string;
  selected: boolean;
  toggleStorySelected(mediaId: string);
  showStoryDetail(story: ISocialPost);

  visible?: boolean;
  classNames?: string[];
}

/**
 * @type {React.FunctionComponent}
 */
export const StoryItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    isMobile,
    isCompact,
    story,
    brandInstagramName,
    selected,
    toggleStorySelected,
    showStoryDetail,
    classNames,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [hovered, listeners] = useHover();

  const onStoryItemClick = (event: React.MouseEvent) => {
    event.preventDefault();

    toggleStorySelected(story.media_id);
  };
  const onSearchButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    showStoryDetail(story);
  };

  const text = (story.caption || story.text || '').replace(
    new RegExp(`${brandInstagramName}`, 'gi'),
    `<b>${brandInstagramName}</b>`,
  );
  const maxLength = isCompact ? 80 : 180;
  const truncatedText = text.length < maxLength ? text : text.substr(0, maxLength - 3) + '...';

  return (
    <div
      className={cx(classNames.concat(styles.StoryItem), {
        [styles.mobile]: isMobile,
      })}
      onClick={onStoryItemClick}
      ref={ref}
    >
      {props.visible && (
        <>
          <div className={styles.imageWrapper} {...listeners}>
            <LazyImage
              src={story.image}
              className={styles.image}
              onClick={onSearchButtonClick}
            />
            {hovered && <SearchIcon size={32} className={styles.zoomIcon} />}
          </div>
          <div className={styles.captionAndDate}>
            <div className={styles.caption} dangerouslySetInnerHTML={{ __html: truncatedText }} />
            <div className={styles.date}>
              {format(new Date(story.posted_ts * 1000), 'MMM dd, yyyy hh:mm a').toUpperCase()}
            </div>
          </div>
          <div className={styles.checkboxWrapper}>
            <div
              className={cx(styles.circle, {
                [styles.selected]: selected,
              })}
            >
              {selected && <CheckIcon />}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

StoryItem.defaultProps = {
  classNames: [],
};

import * as React from 'react';
import cx from 'classnames';

import { ViewIcon } from 'src/icons';
import { LazyImage } from 'src/widgets/Image';
import { Video } from 'src/widgets/Video';
import { ILicensedContent } from 'src/common/models/licensedContent';
import { getDetailedMediaUrl, getMediaTypeForContent, MediaTypes } from 'src/common/utils/media';
import getSafeURL from 'src/common/utils/getSafeURL';

import styles from './Media.scss';

const { useState, useEffect } = React;

interface IProps {
  content: ILicensedContent;
  className: string;
}

export const Media: React.FunctionComponent<IProps> = (props) => {
  const {
    className,
    content,
  } = props;

  const [embedHTML, setEmbedHTML] = useState<string>();

  const mediaType = getMediaTypeForContent(content);

  // Embed instagram post.
  useEffect(() => {
    if (mediaType === MediaTypes.SOCIAL_POST_INSTAGRAM) {
      (async () => {
        try {
          const res = await fetch(
            `https://api.instagram.com/oembed/?omitscript=1&url=${content.social_post.link}`,
          );
          const json = await res.json();
          setEmbedHTML(json.html);
        } catch (err) {
          console.warn(err);
          setEmbedHTML('');
        }
      })();
    } else {
      setEmbedHTML(null);
    }
  }, [content, mediaType]);

  // Process embedded instagram post using script.
  useEffect(() => {
    setTimeout(() => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    }, 500);
  }, [embedHTML]);

  let contentNode;
  switch (mediaType) {
    case MediaTypes.MEDIA_IMAGE: {
      const url = getDetailedMediaUrl(content);
      contentNode = <LazyImage key={url} src={url} className={styles.mediaImage} />;
      break;
    }

    case MediaTypes.MEDIA_VIDEO:
      contentNode = (
        <Video
          key={content.video}
          src={content.video}
          loop={true}
          controls={true}
          classNames={[styles.mediaVideo]}
          poster={content.image}
        />
      );
      break;

    case MediaTypes.SOCIAL_POST_YOUTUBE:
      contentNode = (
        <>
          <iframe
            width="560"
            height="315"
            src={getSafeURL(content.social_post.embed_link)}
            frameBorder="0"
            allowFullScreen={true}
          />
          <ul className={styles.engagementList}>
            <li className={styles.engagementItem}>
              <ViewIcon />
              <span>{content.social_post.engagement} Views</span>
            </li>
          </ul>
        </>
      );
      break;

    case MediaTypes.SOCIAL_POST_INSTAGRAM:
      const showLazyImage = (
        embedHTML === null ||
        (embedHTML === '' && content.social_post.media_type !== 'video')
      );
      contentNode = (
        <>
          {showLazyImage
            ? (
              <LazyImage
                key={content.social_post.image}
                src={content.social_post.image}
                className={styles.mediaImage}
              />
            ) : embedHTML
              ? <div dangerouslySetInnerHTML={{ __html: embedHTML }} />
              : (
                <Video
                  key={content.social_post.link}
                  src={content.social_post.link}
                  loop={true}
                  autoPlay={true}
                  controls={true}
                  classNames={[styles.mediaVideo]}
                  poster={content.social_post.image}
                />
              )}
        </>
      );
      break;

    case MediaTypes.SOCIAL_POST_SNAPCHAT:
      contentNode = (
        <>
          {content.social_post.media_type === 'video' ? (
            <Video
              key={content.social_post.link}
              src={content.social_post.link}
              loop={true}
              autoPlay={true}
              controls={true}
              classNames={[styles.mediaVideo]}
              poster={content.social_post.image}
            />
          ) : (
            <LazyImage
              key={content.social_post.link}
              src={content.social_post.link}
              className={styles.mediaImage}
            />
          )}
          <ul className={styles.engagementList}>
            <li className={styles.engagementItem}>
              <ViewIcon />
              <span>{content.social_post.engagement || 'N/A'}</span>
            </li>
          </ul>
        </>
      );
      break;

    default:
      return null;
  }

  return (
    <div
      className={cx(styles.Media, className, {
        [styles.instagram]: !!embedHTML,
        [styles.youtube]: mediaType === MediaTypes.SOCIAL_POST_YOUTUBE,
      })}
    >
      {contentNode}
    </div>
  );
};

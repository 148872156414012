import * as React from 'react';

import {
  FileIcon,
  FileTypeDocColored,
  FileTypePdfColored,
  FileTypeZipColored,
  FileTypeAviColored,
  FileTypeMp4Colored,
} from 'src/icons';

/**
 * @class
 * @extends {React.FunctionComponent}
 *
 * @param {String} filename the filename, used to determine file type.
 * @param {Number} size the icon size.
 *
 * @return {SvgIcon}
 */
export const FileTypeIcon: React.FunctionComponent<{
  filename: string;
  size: number;
  defaultIcon?: any;
}> = ({ filename, size, defaultIcon }) => {
  const ext = (filename || '')
    .split('.')
    .pop()
    .toLowerCase();

  let Icon = defaultIcon || FileIcon;
  if (['doc', 'docx', 'gdoc'].includes(ext)) {
    Icon = FileTypeDocColored;
  } else if (['zip', 'rar', '7z', 'tar'].includes(ext)) {
    Icon = FileTypeZipColored;
  } else if (ext === 'pdf') {
    Icon = FileTypePdfColored;
  } else if (ext === 'mp4') {
    Icon = FileTypeMp4Colored;
  } else if (ext === 'avi') {
    Icon = FileTypeAviColored;
  }

  return <Icon size={size} />;
};

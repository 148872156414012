import { combineReducers } from 'redux';
import { IConnectSearchPage } from '../models';
import { imageSearchReducer } from './imageSearchReducer';
import { externalVarReducer } from './externalVarReducer';
import { searchResultsReducer } from './searchResultsReducer';
import { textSearchReducer } from './textSearchReducer';
import { savedSearchesReducer } from './savedSearchesReducer';

export default combineReducers<IConnectSearchPage>({
  external: externalVarReducer,
  imageSearch: imageSearchReducer,
  searchResults: searchResultsReducer,
  textSearch: textSearchReducer,
  savedSearches: savedSearchesReducer,
});

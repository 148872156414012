import { ISocialAccount } from 'src/common/models/socialAccount';

export enum Tab {
  SUMMARY = 'Summary',
  ANALYZE = 'Analyze',
}

/**
 * State
 */
export interface IState {
  activeTab: Tab;
  detailedSocialAccount: ISocialAccount;
  fetchingDetailForId: number;
  history: ISocialAccount[];
  isInviting: boolean;
  socialAccount: ISocialAccount;
}

export const initialState: IState = Object.freeze({
  activeTab: Tab.SUMMARY,
  detailedSocialAccount: undefined,
  fetchingDetailForId: undefined,
  history: [],
  isInviting: false,
  socialAccount: undefined,
});

/**
 * Actions
 */
export enum Action { // Text value for debugging
  HISTORY_CLEAR = 'HISTORY_CLEAR',
  HISTORY_POP = 'HISTORY_POP',
  HISTORY_PUSH = 'HISTORY_PUSH',
  RESET = 'RESET',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_DETAILED_ACCOUNT = 'SET_DETAILED_ACCOUNT',
  SET_FETCHING_FOR_ID = 'SET_FETCHING_FOR_ID',
  SET_INVITING = 'SET_INVITING',
  SET_TAB = 'SET_TAB',
}

export interface IAction<T extends any = any> {
  type: Action;
  payload: T;
}

export type TAction =
  { type: Action.HISTORY_CLEAR } |
  { type: Action.HISTORY_POP } |
  {
    type: Action.HISTORY_PUSH;
    socialAccount: ISocialAccount;
  } |
  { type: Action.RESET } |
  {
    type: Action.SET_ACCOUNT;
    socialAccount: ISocialAccount;
  } |
  {
    type: Action.SET_DETAILED_ACCOUNT;
    socialAccount: ISocialAccount;
  } |
  {
    type: Action.SET_FETCHING_FOR_ID;
    id: ISocialAccount['id'];
  } |
  {
    type: Action.SET_INVITING;
    isInviting: boolean;
  } |
  {
    type: Action.SET_TAB;
    tab: Tab;
  }
;

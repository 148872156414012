import { first, isArray, isEmpty } from 'lodash';
import { parse } from 'date-fns';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { IClientProposal } from 'src/common/models/clientProposal';
import { IFavoriteListPageAction, IFavoriteListPage } from './favoriteListPageModel';

type FLPThunkAction<T> = ThunkAction<Promise<T>, IFavoriteListPage, unknown, IFavoriteListPageAction>;
export type FLPThunkDispatch = ThunkDispatch<IFavoriteListPage, unknown, IFavoriteListPageAction>;

import endpoints from 'src/common/config/endpoints';
import addEventLog from 'src/common/utils/addEventLog';

/***************************
 ***** Private actions *****
 **************************/
const setFavoriteLists = (favoriteLists: IFavoriteList[]): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/SET_FAVORITE_LISTS',
    payload: {
      favoriteLists,
    },
  };
};

const addFavoriteList = (favoriteList: IFavoriteList): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/ADD_FAVORITE_LIST',
    payload: {
      favoriteList,
    },
  };
};

const updateFavoriteList = (id: number, favoriteList: IFavoriteList): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/UPDATE_FAVORITE_LIST',
    payload: {
      id,
      favoriteList,
    },
  };
};

const removeFavoriteList = (id: number): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/REMOVE_FAVORITE_LIST',
    payload: {
      id,
    },
  };
};

const setIsFetchingFavoriteLists = (isFetchingFavoriteLists: boolean): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/SET_IS_FETCHING_FAVORITE_LISTS',
    payload: {
      isFetchingFavoriteLists,
    },
  };
};

const setIsEditingFavoriteList = (isEditingFavoriteList: boolean): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/SET_IS_EDITING_FAVORITE_LIST',
    payload: {
      isEditingFavoriteList,
    },
  };
};

const setSocialAccounts = (socialAccounts: ISocialAccount[]): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/SET_SOCIAL_ACCOUNTS',
    payload: {
      socialAccounts,
    },
  };
};

const setInvitedToCampaign = (accountIds: number[]): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/INVITED_TO_CAMPAIGN',
    payload: {
      accountIds,
    },
  };
};

const removeSocialAccountsByElementIds = (elementIds: number[]): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/REMOVE_FROM_FAVORITE_LIST',
    payload: {
      elementIds,
    },
  };
};

/***************************
 ***** public actions *****
 **************************/
const setSelectedListId = (id: number): IFavoriteListPageAction => {
  return {
    type: '@favoriteListPage/SET_SELECTED_LIST_ID',
    payload: {
      id,
    },
  };
};

const fetchFavoriteList = (): FLPThunkAction<IFavoriteList[]> => {
  return async (dispatch, getState): Promise<IFavoriteList[]> => {
    const state = getState();
    const { brandId, apiEndpoint } = state;

    try {
      dispatch(setIsFetchingFavoriteLists(true));
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}?brand_id=${brandId}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );
      dispatch(setIsFetchingFavoriteLists(false));

      const json = await resp.json();
      const list: IFavoriteList[] = json.data.data || [];
      dispatch(setFavoriteLists(list));

      return list;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const createFavoriteList = (name: string): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { brandId, apiEndpoint } = state;

    const payload = {
      brand_id: brandId,
      name,
    };

    try {
      dispatch(setIsEditingFavoriteList(true));
      const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      dispatch(setIsEditingFavoriteList(false));

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(addFavoriteList(json.data));

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const renameFavoriteList = (id: number, name: string): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { brandId, favoriteLists, apiEndpoint } = state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === id);

    const payload = {
      brand_id: brandId,
      name,
    };

    try {
      dispatch(setIsEditingFavoriteList(true));
      const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${id}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      dispatch(setIsEditingFavoriteList(false));

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(
          updateFavoriteList(id, {
            ...favoriteList,
            name,
          }),
        );

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
};

const deleteFavoriteList = (id: number): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { apiEndpoint } = state;

    try {
      dispatch(setIsEditingFavoriteList(true));
      const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      dispatch(setIsEditingFavoriteList(false));

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(removeFavoriteList(id));

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const fetchListContents = (favoriteListId: number, page: number): FLPThunkAction<boolean> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state: IFavoriteListPage = getState();
    const { campaign, apiEndpoint, socialAccounts: existingSocialAccounts } = state;

    try {
      // reset social accounts when switching list
      if (page === 0) {
        dispatch(setSocialAccounts([]));
      }

      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialAccountEndpoint}?campaign_id=${campaign.id}&favorites_list_id=${favoriteListId}&page=${page}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      // decide whether to reset or append
      dispatch(
        setSocialAccounts(
          page === 0 ? socialAccounts : [...existingSocialAccounts, ...socialAccounts],
        ),
      );

      return hasNext;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const fetchProposalsForList = (listId: number): FLPThunkAction<IClientProposal[]> => {
  return async (_, getState): Promise<IClientProposal[]> => {
    const state: IFavoriteListPage = getState();
    const { apiEndpoint } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.proposalEndpoint}?favorite_list_id=${listId}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        const proposals: IClientProposal[] = (json.data.proposals || []).sort(
          (proposal: IClientProposal) => parse(proposal.date_created, 'yyyy-MM-dd', new Date()),
        );

        return proposals;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const inviteToCampaign = (...socialAccounts: ISocialAccount[]): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const count = socialAccounts.length;
    if (count === 0) {
      return;
    }
    const accountIds = socialAccounts.map((socialAccount) => socialAccount.id);

    const state: IFavoriteListPage = getState();
    const { campaign, apiEndpoint } = state;

    const payload: any = {
      approved: true,
      campaign_id: campaign.id,
      state: 'connect.favorite_creators',
      source: 'connect.favorite_creators',
    };

    if (count > 1) {
      payload.account_ids = accountIds;
    } else {
      payload.account_id = first(accountIds);
    }

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.campaignInviteEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(setInvitedToCampaign(accountIds));

        if (count > 1) {
          addEventLog('bulk_invite_creators', {
            count,
            state: 'connect.favorite_creators',
            source: 'connect.favorite_creators',
          });
        } else {
          const socialAccount = first(socialAccounts);
          addEventLog('invite_creator', {
            account_id: socialAccount.id,
            can_contact: socialAccount.can_contact,
            campaign_id: campaign.id,
            state: 'connect.favorite_creators',
            source: 'connect.favorite_creators',
          });
        }

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const deleteElementsFromFavoriteList = (elementIds: number[]): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { apiEndpoint, selectedListId, favoriteLists } = state;

    const payload = {
      ids: elementIds,
    };

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.favoritesListElementEndpoint}/delete_multi`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(removeSocialAccountsByElementIds(elementIds));

        const favoriteList = favoriteLists.find(
          (favoriteList) => favoriteList.id === selectedListId,
        );
        dispatch(
          updateFavoriteList(selectedListId, {
            ...favoriteList,
            element_count: favoriteList.element_count - elementIds.length,
          }),
        );

        if (elementIds.length === 1) {
          addEventLog('remove_to_favorites_list', {
            state: 'connect.browse_creators_v2',
          });
        } else if (elementIds.length > 1) {
          addEventLog('bulk_remove_to_favorites_list', {
            count: elementIds.length,
            state: 'connect.browse_creators_v2',
          });
        }

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const addSocialAccountsToFavoriteList = (
  accountIds: number | number[],
  listId: number,
): FLPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { campaign, favoriteLists, apiEndpoint } = state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    const payload: any = {
      list_id: listId,
      campaign_id: campaign.id,
    };

    let count;
    if (isArray(accountIds)) {
      payload.account_ids = accountIds;
      count = accountIds.length;
    } else {
      payload.account_id = accountIds;
      count = 1;
    }

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.favoritesListElementEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(
          updateFavoriteList(listId, {
            ...favoriteList,
            element_count: favoriteList.element_count + count,
          }),
        );

        addEventLog('add_to_favorites_list', {
          state: 'connect.browse_creators_v2',
        });

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const createProposal = (
  listId: number,
  comments: Array<{
    accountId: number;
    text: string;
  }>,
): FLPThunkAction<IClientProposal> => {
  return async (_, getState): Promise<IClientProposal> => {
    const state: IFavoriteListPage = getState();
    const { apiEndpoint, campaign, socialAccounts } = state;

    const payload: any = {
      favorite_list_id: listId,
      campaign_id: campaign.id,
      comments: comments.map((comment) => ({
        social_account: comment.accountId,
        comment: comment.text,
      })),
    };

    addEventLog('share_agency_proposal', {
      count: socialAccounts.length,
      commented_count: comments.length,
    });

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.proposalEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        const proposal: IClientProposal = json.data;

        return proposal;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const sendProposal = (proposalId: number, emails: string[], note: string): FLPThunkAction<void> => {
  return async (_, getState): Promise<void> => {
    const state: IFavoriteListPage = getState();
    const { apiEndpoint } = state;

    const payload: any = {
      proposal_id: proposalId,
      email_addresses: emails,
      note,
    };

    addEventLog('share_by_email', {
      type: 'agency_proposal',
      count: emails.length,
      note_length: isEmpty(note) ? 0 : note.length,
    });

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.proposalInviteEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

export default {
  setSelectedListId,
  fetchFavoriteList,
  fetchListContents,
  fetchProposalsForList,
  createFavoriteList,
  renameFavoriteList,
  deleteFavoriteList,
  addSocialAccountsToFavoriteList,
  deleteElementsFromFavoriteList,
  inviteToCampaign,
  createProposal,
  sendProposal,
};

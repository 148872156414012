import { first, includes, isEmpty, map } from 'lodash';

import countries from 'src/common/config/countries';
import languages from 'src/common/config/languages';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { ICreatorSearchFilters, IImageSearch } from '../models';

const getDefaultCountryFilters = (acceptedCountryCodes: string[]) =>
  map(acceptedCountryCodes, (code) => {
    return {
      label: countries[code],
      value: code,
    };
  });

const getDefaultLocationFilters = (placeIds: any[]) =>
  map(placeIds, (place_id) => {
    return {
      label: place_id.label,
      value: place_id.value,
    }
  });

const getDefaultLanguageFilters = (acceptedLanguageCodes: string[]) =>
  map(acceptedLanguageCodes, (code) => {
    return {
      label: languages[code],
      value: code,
    };
  });

export const getInitialTextSearchFilters = (
  options: {
    networkTypes: string[];
    placesIds: any[];
    acceptedCountryCodes: string[];
    acceptedLanguageCodes: string[];
  }
): ICreatorSearchFilters => {
  const channel: TNetworkIdentifier = includes(options.networkTypes, 'instagram')
    ? 'instagram'
    : !isEmpty(options.networkTypes)
      ? (first(options.networkTypes) as TNetworkIdentifier)
      : null;

  return {
    query: '',
    creatorDemographics: {
      locations: getDefaultLocationFilters(options.placesIds),
      countries: getDefaultCountryFilters(options.acceptedCountryCodes),
      languages: getDefaultLanguageFilters(options.acceptedLanguageCodes),
    },
    network: {
      channel,
    },
    other: {
      hideInvited: true,
    },
  };
};

export const getInitialImageSearchFilters = (
  countryCodes: string[]
): IImageSearch => {
  return {
    imageURL: null,
    countries: getDefaultCountryFilters(countryCodes),
    hideInvited: true,
    hideInvitedToggle: true,
    isLoadingImage: false,
    contentLists: [],
    contentByListId: {},
    isLoadingContentLists: false,
    hasContentListError: false,
    isLoadingContent: false,
    hasContentError: false,
    clientId: null,
    selfServeExperiment: false,
  };
};

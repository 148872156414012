import * as React from 'react';
import cx from 'classnames';
import { isEmpty, filter, size, clone, pull, map } from 'lodash';
import { differenceInDays } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { NetworkIcon } from 'src/common/NetworkIcon';
import { IClientContentReview } from 'src/common/models/clientContentReview';
import { IOrganization } from 'src/common/models/organization';
import { CopyLinkInput, EmailInput, IEmailRecipient } from 'src/common';
import {
  AlertIcon,
  ClockIcon,
} from 'src/icons';
import {
  Button,
  SubmitButton,
} from 'src/widgets/';
import { Checkbox } from 'src/widgets/Checkbox';
import { LazyImage } from 'src/widgets/Image';
import { Modal } from 'src/widgets/Modal';
import { Textarea } from 'src/widgets/Textarea/Textarea';
import { Video } from 'src/widgets/Video';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';

import { IContentInfo } from './model';

import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';

import styles from './ShareModal.scss';

interface IProps {
  brandId: number;
  org: IOrganization;
  managerId: number;

  contents: IContentInfo[];
  show: boolean;
  onRequestClose();

  createClientContentReview(
    contentReviewIds: number[],
    realtime: boolean,
  ): Promise<IClientContentReview>;
  sendEmailInvite(reviewId: number, emails: string[], note: string);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
type TStep = 1 | 2;
type TShareMode = 'all' | 'partial';
interface IState {
  step: TStep;
  shareMode: TShareMode;

  // new client content review related
  selecetedContentIndexes: number[];
  isCreatingClientReview: boolean;

  // send proposal
  clientReview: IClientContentReview;
  isSendingEmail: boolean;
  note: string;
  emails: string[];
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class ShareModal extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();

    this.state = {
      step: 1,
      shareMode: 'all',

      selecetedContentIndexes: [],
      isCreatingClientReview: false,

      clientReview: null,
      isSendingEmail: false,
      note: '',
      emails: [],
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { show, classNames } = this.props;
    const { step } = this.state;

    return (
      <Modal
        show={show}
        onRequestClose={this.closeShareModal}
        className={cx(classNames.concat(styles.ShareModal))}
        title={this.renderTitle()}
        footer={this.renderFooter()}
      >
        <div className={styles.modalContent}>
          <div className={styles.subtitle}>
            Select the content which you&apos;d like to include when sharing
          </div>
          {step === 1 && this.renderStepOneContent()}
          {step === 2 && this.renderStepTwoContent()}
        </div>
        <Toast ref={this.toastRef} />
      </Modal>
    );
  }

  /**
   * @private
   * Renders the modal title.
   *
   * @return {JSX}
   */
  private renderTitle = () => {
    const { step } = this.state;

    if (step === 1) {
      return 'Choose content to share';
    } else if (step === 2) {
      return 'Share with others';
    }

    return null;
  };

  private renderFooter = () => {
    const {
      step,
      emails,
      isSendingEmail,
      isCreatingClientReview,
      shareMode,
      selecetedContentIndexes,
    } = this.state;

    if (step === 1) {
      return (
        <React.Fragment>
          <SubmitButton
            className={cx(styles.button, styles.left)}
            isSubmitting={isCreatingClientReview}
            disabled={
              isCreatingClientReview ||
              (shareMode === 'partial' && isEmpty(selecetedContentIndexes))
            }
            submittingLabel="Creating..."
            label="Next"
            onClick={this.createClientContentReview}
          />
          <Button
            className={styles.button}
            disabled={isCreatingClientReview}
            label="Cancel"
            theme="info"
            onClick={this.closeShareModal}
          />
        </React.Fragment>
      );
    } else if (step === 2) {
      return (
        <React.Fragment>
          <SubmitButton
            label="Send"
            submittingLabel="Sending..."
            isSubmitting={isSendingEmail}
            round={true}
            className={cx(styles.button, styles.left)}
            disabled={isEmpty(emails) || isSendingEmail}
            onClick={this.sendEmails}
          />
          <Button
            label="Cancel"
            theme="info"
            round={true}
            className={styles.button}
            disabled={isSendingEmail}
            onClick={this.closeShareModal}
          />
        </React.Fragment>
      );
    }

    return null;
  };

  private renderStepOneContent = () => {
    const { shareMode } = this.state;

    return (
      <div className={styles.stepOne}>
        <div className={styles.selectMode}>
          <div
            className={cx(styles.selectItem, styles.left)}
            onClick={this.selectMode.bind(this, 'all')}
          >
            <div
              className={cx(styles.dot, {
                [styles.selected]: shareMode === 'all',
              })}
            >
              {shareMode === 'all' && <div className={styles.selectedDot} />}
            </div>
            <div className={styles.selectContent}>
              <div className={styles.selectTitle}>Share all content in real-time</div>
              <div>
                Allow your collaborators to see all content that needs approval at any time.
              </div>
            </div>
          </div>
          <div className={styles.selectItem} onClick={this.selectMode.bind(this, 'partial')}>
            <div
              className={cx(styles.dot, {
                [styles.selected]: shareMode === 'partial',
              })}
            >
              {shareMode === 'partial' && <div className={styles.selectedDot} />}
            </div>
            <div className={styles.selectContent}>
              <div className={styles.selectTitle}>Choose content</div>
              <div>Explicitly share select content for review.</div>
            </div>
          </div>
        </div>
        {shareMode === 'partial' && this.renderContentList()}
      </div>
    );
  };

  private renderStepTwoContent = () => {
    const { brandId, org, managerId } = this.props;
    const { clientReview } = this.state;

    const recipients = map(
      filter(org.managers, (manager) => !!(manager.email && manager.full_name)),
      (manager) => ({
        email: manager.email,
        name: manager.full_name,
      }),
    );

    return (
      <div className={styles.stepTwo}>
        <CopyLinkInput
          className={styles.linkSection}
          shareLink={`${
            window.location.origin
          }/brands/client_content_review?client_content_review_id=${
            clientReview.id
          }&bid=${brandId}&token=${org.id}&mid=${managerId}&count=${size(
            clientReview.content_reviews,
          )}`}
        />
        <div className={styles.text}>Share by email:</div>
        <EmailInput suggestedRecipients={recipients} onRecipientsChange={this.onRecipientsChange} />
        <div className={styles.text}>(Optional) Add a message:</div>
        <Textarea onChange={this.onNoteChange} />
      </div>
    );
  };

  private renderContentList = () => {
    const { contents } = this.props;
    const { selecetedContentIndexes } = this.state;

    return (
      <div className={styles.contentList}>
        {map(contents, (content, index) => {
          if (content.approved) {
            return null;
          }

          // show image for first content
          let firstContent = content.contents && content.contents[0];
          if (!firstContent) {
            // fallback for blog posts and youtube videos
            firstContent = {
              url: content.imageLink,
              media_type: 'image',
            };
          }

          const showImageLayer = content.contents && content.contents.length > 1;
          const diffDays = differenceInDays(new Date(content.dueTs * 1000), Date.now());
          const showAlert = !content.dueTs || diffDays <= 0;

          return (
            <div
              key={index}
              className={styles.contentItem}
              onClick={this.toggleSelectedContentIndex.bind(this, index)}
            >
              <Checkbox
                className={styles.checkbox}
                checked={selecetedContentIndexes.includes(index)}
              />
              <div className={styles.imageWrapper}>
                {firstContent.media_type === 'image' && (
                  <LazyImage
                    className={cx(styles.image, {
                      [styles.hasLayer]: showImageLayer,
                    })}
                    src={firstContent.url}
                  />
                )}
                {firstContent.media_type === 'video' && (
                  <Video
                    classNames={[
                      cx(styles.image, {
                        [styles.hasLayer]: showImageLayer,
                      }),
                    ]}
                    src={firstContent.url}
                  />
                )}
                {showImageLayer && (
                  <React.Fragment>
                    {content.contents.length > 1 && <div className={styles.layerOne} />}
                    {content.contents.length > 2 && <div className={styles.layerTwo} />}
                  </React.Fragment>
                )}
              </div>
              <div className={styles.info}>
                <div className={styles.networkAndDescription}>
                  <div className={styles.network}>
                    <NetworkIcon identifier={getNetworkByPostType(content.postType)} size={17} />
                  </div>
                  <div className={styles.description}>{content.productDescription}</div>
                </div>
                <div className={styles.campaignAndCreator}>
                  for {content.campaign} from {content.creatorName}
                </div>
              </div>
              <div
                className={cx(styles.timeLeft, {
                  [styles.alert]: showAlert,
                })}
              >
                {diffDays > 0 && <ClockIcon className={styles.icon} />}
                {diffDays <= 0 && <AlertIcon className={cx(styles.alertIcon, styles.icon)} />}
                {diffDays >= 0 && (
                  <div>
                    {formatDistanceToNow(new Date(content.dueTs * 1000))} left to review
                  </div>
                )}
                {!content.dueTs && diffDays < 0 && 'You requested changes'}
                {content.dueTs && diffDays < 0 && 'Already past due'}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  private closeShareModal = () => {
    const { onRequestClose } = this.props;

    this.setState({
      step: 1,
      note: '',
    });

    onRequestClose();
  };

  private selectMode = (shareMode: TShareMode) => {
    this.setState({
      shareMode,
    });
  };

  private toggleSelectedContentIndex = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    const { selecetedContentIndexes } = this.state;
    const newIndexes = clone(selecetedContentIndexes);

    // prevent selecting underlying text
    event.preventDefault();

    if (!newIndexes.includes(index)) {
      newIndexes.push(index);
    } else {
      pull(newIndexes, index);
    }

    this.setState({
      selecetedContentIndexes: newIndexes,
    });
  };

  private createClientContentReview = () => {
    const { contents, createClientContentReview } = this.props;
    const { shareMode, selecetedContentIndexes } = this.state;
    const toast = this.toastRef.current;

    const contentReviewIds = contents
      .filter((_, index) => selecetedContentIndexes.includes(index))
      .map((content) => content.id);
    const realtime = shareMode === 'all';

    this.setState({
      isCreatingClientReview: true,
    });
    createClientContentReview(contentReviewIds, realtime)
      .then((clientReview) => {
        console.log(clientReview);
        this.setState({
          step: 2,
          isCreatingClientReview: false,
          clientReview,
        });
      })
      .catch(() => {
        this.setState({
          isCreatingClientReview: false,
        });

        toast.showMessage({
          content: 'There was an error when trying to share the contents.',
          type: 'error',
        });
      });
  };

  /**
   * @private
   * Notifies the parent with new value.
   */
  private onNoteChange = (note: string) => {
    this.setState({
      note,
    });
  };

  private onRecipientsChange = (recipients: IEmailRecipient[]) => {
    const validEmails = recipients
      .filter((recipient) => recipient.isValid)
      .map((recipient) => recipient.email);

    this.setState({
      emails: validEmails,
    });
  };

  private sendEmails = () => {
    const { sendEmailInvite, onRequestClose } = this.props;
    const { clientReview, emails, note } = this.state;
    const toast = this.toastRef.current;

    this.setState({
      isSendingEmail: true,
    });
    sendEmailInvite(clientReview.id, emails, note)
      .then(() => {
        toast.showMessage({
          content: (
            <div>
              Contents review has been sent to <span>{emails.length} </span>
              client{emails.length > 1 ? 's' : ''}.
            </div>
          ),
          duration: 1000,
        });

        setTimeout(() => {
          this.setState(
            {
              isSendingEmail: false,
            },
            onRequestClose,
          );
        }, 1000);
      })
      .catch(() => {
        this.setState({
          isSendingEmail: false,
        });

        toast.showMessage({
          content: 'There was an error when trying to send the contents review.',
          type: 'error',
        });
      });
  };
}

import * as React from 'react';
import numeral from 'numeral';
import { isEmpty, map, sortBy, startCase } from 'lodash';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { IDetailSection } from 'src/common/CreatorDetail/CreatorDetail';
import countries from 'src/common/config/countries';
import networkConfig from 'src/common/config/networkConfig';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { formatCurrency } from 'src/common/utils/formatCurrency';
import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { LazyImage } from 'src/widgets/Image';

import styles from './CreatorDetail.scss';

export const getDetailConfig = (details: {
  campaign: ICampaign;
  org: IOrganization;
  socialAccount: ISocialAccount;
}): IDetailSection[] => {
  const { campaign, org, socialAccount } = details;
  const { engagementNamePlural, audienceNamePlural, contentName } = networkConfig[
    socialAccount.network_identifier
  ];

  return [
    {
      show: !!socialAccount.reach,
      icon: socialAccount.network_identifier === 'pinterest' ? styles.monthlyViewsIcon : styles.followerIcon,
      title: startCase(audienceNamePlural),
      value: (socialAccount.reach > 0) ? numeral(socialAccount.reach)
        .format('0.[0]a')
        .toUpperCase() : '-',
    },
    {
      show: !!socialAccount.follower_count && socialAccount.network_identifier === 'pinterest',
      icon: styles.followerIcon,
      title: 'Followers',
      value: numeral(socialAccount.follower_count)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.engagement && socialAccount.network_identifier !== 'pinterest',
      icon: styles.likeIcon,
      title: `${startCase(engagementNamePlural)}/${startCase(contentName)}`,
      value: numeral(socialAccount.engagement)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.engagement_ratio,
      icon: styles.engagementIcon,
      title: 'Engagement Ratio',
      value: numeral(socialAccount.engagement_ratio).format('0.00%'),
    },
    {
      show: !!socialAccount.original_pins_percentage,
      icon: styles.clickIcon,
      title: 'Original Content Ratio',
      value: numeral(socialAccount.original_pins_percentage).format('0%'),
    },
    {
      show: !!socialAccount.saves,
      icon: styles.saveIcon,
      title: `Saves/${startCase(contentName)}`,
      value: numeral(socialAccount.saves)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.average_clicks,
      icon: styles.clickIcon,
      title: 'Clicks/Pin',
      value: socialAccount.average_clicks,
    },
    {
      show: !!socialAccount.gender,
      icon: styles.genderIcon,
      title: 'Gender',
      value: socialAccount.gender ? startCase(socialAccount.gender.toLowerCase()) : null,
    },
    {
      show: !!socialAccount.country_code,
      icon: styles.flagIcon,
      title: 'Country',
      value: countries[socialAccount.country_code],
    },
    {
      show: !!socialAccount.predicted_emv && hasFeature(org, 'enterprise_data'),
      icon: styles.expectedValueIcon,
      title: 'Expected Media Value',
      value: formatCurrency(
        socialAccount.predicted_emv,
        campaign && campaign.currency_code,
        campaign && campaign.currency_xrate,
      ),
    },
    {
      show: !!socialAccount.average_like_ratio,
      icon: styles.likeRatioIcon,
      title: 'Like/Dislike Ratio',
      value: `${numeral(socialAccount.average_like_ratio).format('0.00%')} Likes`,
    },
    {
      show: !!socialAccount.bounce_rate,
      icon: styles.bounceRateIcon,
      title: 'Bounce Rate',
      value: `${socialAccount.bounce_rate}%`,
    },
    {
      show: !!socialAccount.average_comments_count,
      icon: styles.commentIcon,
      title: `Comments/${startCase(contentName)}`,
      value: numeral(socialAccount.average_comments_count)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.impressions,
      icon: styles.impressionIcon,
      title: `${socialAccount.network_identifier === 'pinterest' ? 'Views' : 'Impressions'}/${startCase(contentName)}`,
      value: numeral(socialAccount.impressions)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.recent_stories_impressions,
      icon: styles.impressionIcon,
      title: 'Views/Story',
      value: numeral(socialAccount.recent_stories_impressions)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.page_views,
      icon: styles.pageViewIcon,
      title: 'Page Views/Visit',
      value: numeral(socialAccount.page_views)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !!socialAccount.time_on_site,
      icon: styles.timeIcon,
      title: 'Seconds on Site/Visit',
      value: numeral(socialAccount.time_on_site)
        .format('0.[0]a')
        .toUpperCase(),
    },
    {
      show: !isEmpty(socialAccount.top_hashtags),
      icon: styles.hashtagIcon,
      title: 'Top Hashtags',
      value: map(socialAccount.top_hashtags, (hashtag, index) => (
        <div className={styles.subValue} key={index}>
          {hashtag}
        </div>
      )),
    },
    {
      show: socialAccount.report && !isEmpty(socialAccount.report.top_brands),
      icon: styles.mentionIcon,
      title: 'Top Brands Mentioned',
      value: map(socialAccount.report && socialAccount.report.top_brands, (brandInfo, index) => (
        <div key={index} className={styles.brandMention}>
          <LazyImage src={brandInfo.logo_url} className={styles.brandLogo} />
          {brandInfo.name}
        </div>
      )),
    },
    {
      show: socialAccount.report && !!socialAccount.report.comment_sentiment,
      icon: styles.sentimentIcon,
      title: 'Comment Sentiment',
      value: sentimentScoreToText(
        socialAccount.report &&
          socialAccount.report.comment_sentiment &&
          socialAccount.report.comment_sentiment.score,
      ),
    },
    {
      show: !isEmpty(socialAccount.playlists),
      icon: styles.engagementIcon,
      title: 'Top Playlists',
      value: map(socialAccount.playlists, (playlist, index) => (
        <div className={styles.subValue} key={index}>
          {playlist.title} ({playlist.count})
        </div>
      )),
    },
    {
      show: !isEmpty(socialAccount.posts) && socialAccount.network_identifier === 'youtube',
      icon: styles.likeIcon,
      title: 'Recent Video Views',
      value: sortBy(socialAccount.posts || [], (post) => -post.created_ts)
        .slice(0, 5)
        .map((post, index) => (
          <div className={styles.subValue} key={index}>
            {numeral(post.view_count)
              .format('0.[0]a')
              .toUpperCase()}{' '}
            {startCase(engagementNamePlural)} ({formatDistanceToNow(new Date(post.created_ts * 1000))}{' '}
            ago)
          </div>
        )),
    },
  ];
};

const sentimentScoreToText = (score: number) => (
  <div className={score > 0.2 ? styles.positive : score > -0.2 ? styles.neutral : styles.negative}>
    <span className={styles.score}>{numeral(score).format('0%')}</span>&nbsp;(
    {score > 0.6
      ? 'Very Positive'
      : score > 0.2
        ? 'Mostly Positive'
        : score > -0.2
          ? 'Neutral'
          : 'Negative'}
    )
  </div>
);

import * as React from 'react';
import { pick, indexOf, map } from 'lodash';

import { RadioGroup } from 'src/widgets/RadioGroup';

import AudienceDropdown from './AudienceDropdown';
import { FilterSection } from '../FilterSection';
import { IAudienceEthnicityFilters, IAudienceEthnicityOptions } from '../models';

const { useMemo } = React;

export const AUDIENCE_ETHNICITY_FILTERS = ['ethnicity', 'ethnicityLessThan', 'ethnicityPercentage'];

const DEFAULT_AUDIENCE_ETHNICITIES = ['White / Caucasian', 'African American', 'Asian', 'Hispanic'];
const pickFilters = (obj: any) => pick(obj, AUDIENCE_ETHNICITY_FILTERS);

interface IProps extends IAudienceEthnicityFilters, IAudienceEthnicityOptions {
  onChange(ethnicityFilters: IAudienceEthnicityFilters);
  hide?: boolean;
  hidePercentage?: boolean;
}

const AudienceEthnicitySection: React.FunctionComponent<IProps> = (props) => {
  const {
    ethnicity,
    ethnicityChoices: ethnicityChoicesProp,
    hide,
    onChange,
  } = props;

  const ethnicityChoices = useMemo(() => {
    return map(ethnicityChoicesProp, (range) => ({
      value: range,
      label: range === 'African American' ? 'Black / African American' : range,
    }));
  }, [ethnicityChoicesProp]);

  const ethnicityIndex = useMemo(() => {
    return indexOf(ethnicityChoicesProp, ethnicity);

  }, [ethnicityChoicesProp, ethnicity]);

  const handleChangeEthnicity = (ethnicity: string) => {
    const newFilters = {
      ...pickFilters(props),
      ethnicity,
    };
    onChange(newFilters);
  };

  const handleChangeEthnicityPercentage = (percentage: number) => {
    const newFilters = {
      ...pickFilters(props),
      ethnicityPercentage: percentage,
    };
    props.onChange(newFilters);
  };

  const handleChangeEthnicityLessThan = (lessThan: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      ethnicityLessThan: lessThan,
    };
    props.onChange(newFilters);
  };

  const handleClear = () => {
    handleChangeEthnicity('');
  };

  return (
    <FilterSection
      header="Ethnicity"
      hide={hide}
      isSubsection={true}
      showClearButton={ethnicityIndex >= 0}
      onClear={handleClear}
    >
      <RadioGroup
        alignment="vertical"
        onChange={handleChangeEthnicity}
        options={ethnicityChoices}
        selectedIndex={ethnicityIndex}
      />
      {props.hidePercentage ? null : (
        <AudienceDropdown
          onChangePercentage={handleChangeEthnicityPercentage}
          onChangeLessThan={handleChangeEthnicityLessThan}
          selectedPercentage={props.ethnicityPercentage}
          lessThan={props.ethnicityLessThan}
          disabled={!props.ethnicity}
        />
      )}
    </FilterSection>
  );
};

AudienceEthnicitySection.defaultProps = {
  ethnicityChoices: DEFAULT_AUDIENCE_ETHNICITIES,
  hide: false,
  ethnicityLessThan: false,
  ethnicityPercentage: 50,
  hidePercentage: false,
};

export default AudienceEthnicitySection;

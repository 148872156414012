import * as React from 'react';
import cx from 'classnames';


import styles from './Badge.scss';

type TTheme = 'primary' | 'info' | 'light' | 'danger';

interface IBadgeProps {
  className?: string;
  theme?: TTheme;
  customTheme?: Partial<Pick<React.CSSProperties, 'color' | 'backgroundColor'>>;
}

export const Badge: React.FunctionComponent<IBadgeProps> = (props) => {
  const {
    children,
    className,
    customTheme = {},
    theme = 'primary',
  } = props;
  return (
    <div
      className={cx(styles.Badge, className, styles[theme])}
      style={customTheme}
    >
      {children}
    </div>
  );
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const StarBorderIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="M23.2,30c-0.5,0-1-0.2-1.4-0.5l-6.3-4.6c-0.3-0.2-0.7-0.2-1,0l-6.3,4.6c-0.8,0.6-1.9,0.6-2.7,0
      c-0.8-0.6-1.2-1.6-0.8-2.6l2.6-7.7c0.1-0.3,0-0.7-0.3-0.9l-6-4.7C0.3,13,0,12,0.3,11.1c0.3-0.9,1.2-1.6,2.2-1.6h7
      c0.3,0,0.7-0.2,0.8-0.5l2.6-7.4C13.2,0.6,14.1,0,15.1,0c0,0,0,0,0,0c1,0,1.9,0.6,2.2,1.5v0L19.9,9c0.1,0.3,0.4,0.5,0.8,0.5h7
      c1,0,1.9,0.6,2.2,1.6c0.3,0.9,0,2-0.8,2.6l-6,4.7c-0.3,0.2-0.4,0.6-0.3,0.9l2.6,7.7c0.3,1,0,2-0.8,2.6C24.2,29.8,23.7,30,23.2,30z
       M15.1,23.3c0.5,0,1,0.1,1.4,0.5l6.3,4.6c0.4,0.3,0.8,0.1,1,0c0.1-0.1,0.5-0.4,0.3-0.9l-2.6-7.7c-0.3-0.9,0-2,0.8-2.6l6-4.7
      c0.4-0.3,0.3-0.8,0.3-0.9c0-0.1-0.2-0.5-0.8-0.5h-7c-1,0-1.9-0.6-2.2-1.5L15.9,2v0c-0.2-0.5-0.6-0.5-0.8-0.5c0,0,0,0,0,0
      c-0.1,0-0.6,0-0.8,0.5l-2.6,7.4c-0.3,0.9-1.2,1.5-2.2,1.5h-7c-0.5,0-0.7,0.4-0.8,0.5c0,0.1-0.2,0.6,0.3,0.9l6,4.7
      c0.8,0.6,1.1,1.6,0.8,2.6l-2.6,7.7c-0.2,0.5,0.2,0.8,0.3,0.9c0.1,0.1,0.5,0.3,1,0l6.3-4.6C14.1,23.4,14.6,23.3,15.1,23.3z"
    />
  </SvgIcon>
));

export { StarBorderIcon };

import * as React from 'react';
import cx from 'classnames';
import { merge, isEmpty, size, isNumber } from 'lodash';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';

import {
  AccessIcon,
  InviteIcon,
} from 'src/icons';
import { SubmitButton } from 'src/widgets/Button';
import { Notice } from 'src/widgets/Notice';
import { OverlaySpinner } from 'src/widgets/OverlaySpinner';
import { IToastRefHandles, Toast, TOAST_CLOSE_DURATION } from 'src/widgets/Toast';
import { ShareModal, ISuggestedRecipient } from 'src/common';

import { ContentTable } from './ContentTable';
import { configureStore } from './redux/store';
import {
  AAPThunkDispatch,
  fetchAgencyRequirements,
  createAccess,
  sendAccess,
  updateSelectedForUse,
} from './redux/actions';
import { IStore, IAdvertiserAccessTableRowData } from './redux/models';
import {
  shareLinkSelector,
  advertiserAccessTableRowDataSelector,
  currentMonthlyUsageSelector,
  suggestedRecipientsSelector,
} from './redux/selectors';

const { useState, useRef, useCallback, useMemo, useEffect } = React;

import styles from './AgencyAdvertiserAccessPage.scss';

interface IOwnProps {
  className?: string;
  onContactSupport?();
}

interface IStateProps extends IStore {
  shareLink?: string;
  tableData?: IAdvertiserAccessTableRowData[];
  suggestedRecipients?: ISuggestedRecipient[];
}

interface IDispatchProps {
  onToggleSelectedForUse(publisherId: number, selectedForUse: boolean);
  onCreateAccess();
  onSendAccess(emails: string[], note: string);
}

type TAgencyAdvertiserAccessPageProps = IStateProps & IDispatchProps & IOwnProps;

/**
 * @type {React.FunctionComponent}
 */
const AgencyAdvertiserAccessPage: React.FunctionComponent<TAgencyAdvertiserAccessPageProps> = React.memo((props) => {
  const {
    accessSent,
    brand,
    className,
    currentMonthlyUsage,
    errorMessage,
    isCreatingAccess,
    isLoading,
    isSendingAccess,
    onContactSupport,
    onCreateAccess,
    onSendAccess,
    onToggleSelectedForUse,
    requirements,
    shareLink,
    showSpinner,
    suggestedRecipients,
    tableData,
    toast,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const toastRef = useRef<IToastRefHandles>(null);

  if (typeof window === 'undefined') {
    return null;
  }

  const closeModal = useCallback(() => setShowModal(false), []);

  useEffect(() => {
    if (toast && toastRef.current) {
      toastRef.current.showMessage(toast);
    }
  }, [toast]);

  useEffect(() => {
    if (shareLink) {
      setShowModal(true);
    }
  }, [shareLink]);

  useEffect(() => {
    if (accessSent && toastRef.current) {
      const { emails } = accessSent;

      toastRef.current.showMessage({
        content: (
          <div>
            Access link has been sent to <span>{size(emails)}</span> client
            {size(emails) > 1 ? 's' : ''}.
          </div>
        ),
        duration: 1000,
      });

      setTimeout(closeModal, 1000 + TOAST_CLOSE_DURATION);
    }
  }, [accessSent, closeModal]);

  const usageLimit = brand.advertiser_access_usage_limit;
  const hasLimit = isNumber(usageLimit);

  return (
    <div className={cx(styles.AgencyAdvertiserAccessPage, className)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <AccessIcon size={42} /> <span>Advertiser Access</span>
        </div>
        <div className={styles.text}>
          View {hasLimit && 'and configure'} all creator social accounts that you have advertiser
          access for.
        </div>
      </div>
      {hasLimit && currentMonthlyUsage >= usageLimit && (
        <Notice showDivider={true} className={styles.notice} type="info">
          <div>
            <div className={styles.title}>
              You have reached your monthly quota of {usageLimit} creators for advertiser access.
            </div>
            <div className={styles.subtitle}>
              Please reach out to your Customer Success Manager to update your plan and get
              advertiser access to more creators.
            </div>
          </div>
        </Notice>
      )}
      <ContentTable
        isLoading={isLoading}
        data={tableData}
        hasError={!!errorMessage}
        onContactSupport={onContactSupport}
        onToggleAccess={onToggleSelectedForUse}
        hideAccessColumn={!hasLimit}
        headerActions={
          <>
            {hasLimit && (
              <div className={styles.usage}>
                Current monthly usage:&nbsp;
                {currentMonthlyUsage}/{usageLimit} creators
              </div>
            )}
            <SubmitButton
              theme="info"
              className={styles.shareButton}
              icon={<InviteIcon />}
              label="Share with Others"
              submittingLabel="Sharing..."
              isSubmitting={isCreatingAccess}
              disabled={isEmpty(requirements) || isCreatingAccess}
              onClick={onCreateAccess}
            />
          </>
        }
      />
      <ShareModal
        shareLink={shareLink}
        suggestedRecipients={suggestedRecipients}
        show={showModal}
        isSubmitting={isSendingAccess || !!accessSent}
        onRequestClose={closeModal}
        onRequestShare={onSendAccess}
      />
      <Toast ref={toastRef} />
      {showSpinner && <OverlaySpinner />}
    </div>
  );
});

const mapStateToProps = (state) => ({
  ...state,
  shareLink: shareLinkSelector(state),
  tableData: advertiserAccessTableRowDataSelector(state),
  currentMonthlyUsage: currentMonthlyUsageSelector(state),
  suggestedRecipients: suggestedRecipientsSelector(state),
});

const mapDispatchToProps = {
  onToggleSelectedForUse: updateSelectedForUse,
  onCreateAccess: createAccess,
  onSendAccess: sendAccess,
};

const ConnectedPage = connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AgencyAdvertiserAccessPage);

const initialState: IStore = {
  apiEndpoint: null,
  brand: null,
  organization: null,
  managerId: null,
  requirements: [],
  isLoading: true,
};

type TPageProps =
  | 'className'
  | 'apiEndpoint'
  | 'brand'
  | 'organization'
  | 'managerId'
  | 'onContactSupport'
  ;

const Page: React.FunctionComponent<Pick<TAgencyAdvertiserAccessPageProps, TPageProps>> = (props) => {
  const { className, ...rest } = props;

  const store = useMemo(() => {
    return configureStore(merge(initialState, rest));
  }, [rest]);

  useEffect(() => {
    const dispatch: AAPThunkDispatch = store.dispatch;
    dispatch(fetchAgencyRequirements());
  }, [store.dispatch]);

  return (
    <Provider store={store}>
      <ConnectedPage className={className} />
    </Provider>
  );
};

export { Page as AgencyAdvertiserAccessPage };

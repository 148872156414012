import * as React from 'react';
import cx from 'classnames';

import {
  DownloadIcon,
  ExternalLinkIcon,
  FileTypeDoc,
  FileTypeImage,
  FileTypePdf,
  FileTypeVideo,
  YoutubeIcon,
  ZoomIcon,
} from 'src/icons';
import { IconButton } from 'src/widgets/Button';

import { IContent } from '../contentPreviewerModel';

import getFilenameFromUrl from 'src/common/utils/getFilenameFromUrl';
import { getDownloadableMedia, getPreviewMedia } from '../utils/getContentImage';

import styles from './Content.scss';

export type TContentSize = 'small' | 'medium' | 'large';
export interface IContentProps {
  content: IContent;

  showInfo?: boolean;
  downloadable?: boolean;
  size?: TContentSize;

  showZoom?: boolean;
  onZoomClick?();

  showExternalLink?: boolean;

  classNames?: string[];
}

type TDefaultProp =
  | 'showInfo'
  | 'size'
  | 'downloadable'
  | 'showZoom'
  | 'onZoomClick'
  | 'showExternalLink'
  | 'classNames';

interface IState {
  hover: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Content extends React.Component<IContentProps, IState> {
  public static defaultProps: Pick<IContentProps, TDefaultProp> = {
    size: 'small',
    downloadable: true,
    showInfo: true,

    showZoom: false,
    onZoomClick: () => undefined,
    showExternalLink: false,

    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      content,
      size,
      downloadable,
      showInfo,
      showZoom,
      onZoomClick,
      showExternalLink,
      children,
      classNames,
    } = this.props;
    const { hover } = this.state;

    const filename = downloadable ? getFilenameFromUrl(content.src) : content.src;
    let fileIconSize = 20;
    let actionIconSize = 24;
    if (size === 'medium') {
      fileIconSize = 24;
      actionIconSize = 44;
    } else if (size === 'large') {
      fileIconSize = 36;
      actionIconSize = 60;
    }

    return (
      <div
        className={cx(classNames.concat(styles.Content))}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={cx(styles.content, {
            [styles.hover]: hover,
            [styles.clickable]: showZoom,
          })}
          onClick={showZoom ? onZoomClick : undefined}
        >
          {children}
        </div>
        {hover && (
          <div className={cx(styles.info, styles[size])}>
            {showInfo && (
              <>
                <FileType content={content} size={fileIconSize} />
                {filename && (
                  <div className={styles.name}>
                    {filename.length < 34
                      ? filename
                      : filename.substr(0, 26) + '...' + filename.substr(-5)}
                  </div>
                )}
              </>
            )}
            <div className={styles.actions}>
              {showZoom && (
                <IconButton
                  className={styles.zoomIcon}
                  icon={<ZoomIcon size={actionIconSize} />}
                  theme="light"
                  onClick={onZoomClick}
                />
              )}
              {downloadable && (
                <IconButton
                  icon={<DownloadIcon size={actionIconSize} />}
                  theme="light"
                  onClick={this.downloadFile}
                />
              )}
              {showExternalLink && (
                <IconButton
                  icon={<ExternalLinkIcon size={actionIconSize} />}
                  theme="light"
                  onClick={this.visitExternalLink}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private downloadFile = (event: React.MouseEvent) => {
    const { content } = this.props;

    event.preventDefault();
    event.stopPropagation();

    const a = document.createElement('a');
    document.body.appendChild(a);

    const downloadUrl = getDownloadableMedia(content);
    a.download = getFilenameFromUrl(downloadUrl);
    a.href = downloadUrl;
    a.click();

    document.body.removeChild(a);
  };

  private visitExternalLink = (event: React.MouseEvent) => {
    const { content } = this.props;

    event.preventDefault();
    event.stopPropagation();

    window.open(getPreviewMedia(content), '_blank');
  };

  private handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  private handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };
}

/**
 * @class
 * @extends {React.FunctionComponent}
 */
const FileType: React.FunctionComponent<{
  content: IContent;
  size: number;
}> = ({ content, size }) => {
  const filename = getFilenameFromUrl(content.src);
  const ext = (filename || '')
    .split('.')
    .pop()
    .toLowerCase();

  let Icon;
  if (content.type === 'image') {
    Icon = FileTypeImage;
  } else if (content.type === 'video') {
    Icon = FileTypeVideo;
  } else if (content.type === 'youtube') {
    Icon = YoutubeIcon;
  } else {
    // application
    if (ext === 'pdf') {
      Icon = FileTypePdf;
    } else {
      // default as doc
      Icon = FileTypeDoc;
    }
  }
  return (
    <div
      className={cx(styles.fileType, {
        [styles.image]: content.type === 'image',
        [styles.video]: content.type === 'video',
        [styles.pdf]: ext === 'pdf',
      })}
    >
      <Icon size={size} />
    </div>
  );
};

import * as React from 'react';

import { Accordion } from 'src/widgets/Accordion';
import { Checkbox } from 'src/widgets/Checkbox';

import { CampaignItem } from './CampaignItem';

import styles from '../Filters.scss';
const { useState } = React;

export const BrandItem = ({ brand, dispatch }) => {
  const campaigns = brand.campaigns;
  const [isOpen, setOpen] = useState(true);
  return (
    <li
      className={styles.MenuItem}
      style={{ display: 'flex', alignItems: 'flex-start', padding: 0 }}
    >
      <Checkbox
        checked={brand.selected}
        onChange={() => dispatch({ type: 'TOGGLE_BRAND', payload: brand })}
      />
      <div style={{ paddingTop: '10px', width: '100%' }}>
        <Accordion
          isOpen={isOpen}
          label={brand.name}
          onToggle={() => setOpen(!isOpen)}
        >
          <ul className={styles.MenuList} onClick={e => e.stopPropagation()}>
            {campaigns.map(camp => (
              <CampaignItem
                key={camp.id}
                brandId={brand.id}
                campaign={camp}
                dispatch={dispatch}
              />
            ))}
          </ul>
        </Accordion>
      </div>
    </li>
  );
};

import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import networkConfig from 'src/common/config/networkConfig';
import { NetworkIcon } from 'src/common/NetworkIcon';
import {
  DefaultAvatarIcon,
} from 'src/icons';
import { AvatarIcon } from 'src/widgets/Icon';
import { largeNumberAbbreviator } from 'src/utils/formatNumbers';

import { ISocialPost } from './PostCard';

import styles from './AccountSection.scss';

interface IProps {
  post: ISocialPost;
  isQa: boolean;
}

/**
 * @type {React.FunctionComponent}
 */

const AccountSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const { post, isQa } = props;

  return (
    <div className={cx(styles.accountSection)}>
      <div className={styles.avatarContainer}>
        <a href={post.link} target="_blank" rel="noopener noreferrer">
          {SocialIcon(post.network)}

          {post.profile_image_url ? (
            <AvatarIcon imageUrl={post.profile_image_url} />
          ) : (
            <span style={{ color: '#d4d4d4' }}>
              <DefaultAvatarIcon size={40} />
            </span>
          )}
        </a>
      </div>
      <div className={styles.txtContainer}>
        <div className={styles.accountNameContainer}>
          <a
            href={post.social_account_link}
            className={cx(styles.accountName)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {post.social_account_name || 'Unknown'}
          </a>
        </div>

        <div className={styles.accountFollowersContainer}>
          <div className={cx(styles.accountFollowersTxt, styles.truncate)}>
            {largeNumberAbbreviator(post.reach)} {networkConfig[post.network] ? networkConfig[post.network].audienceNamePlural : 'followers'}
          </div>
        </div>

        <div className={styles.dateContainer}>
          <span className={styles.dateTxt}>
            {format(new Date(post.datetime_posted), 'MMM dd, yyyy')}
          </span>
          {isQa && (
            <a
              className={styles.adminLink}
              href={`/admin/social_post/${post.post_id}`}
            >
              Admin Page
            </a>
          )}
        </div>
      </div>
    </div>
  );
});

const SocialIcon = (network) => {
  return (
    <div className={styles.accountNetworkIconContainer}>
      <NetworkIcon identifier={network} size={18} className={styles.account_network_icon} />
    </div>
  );
};
export default AccountSection;

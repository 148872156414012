import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';

import { AlertIcon, CheckCircleIcon, CloseIcon } from 'src/icons';

import { useMobileStatus } from 'src/utils/hooks';

const { useState, useEffect, useRef, useCallback, useImperativeHandle } = React;
import styles from './Toast.scss';

export const TOAST_CLOSE_DURATION = 300;
type TToastMessageType = 'info' | 'success' | 'error';
export interface IToastMessage {
  type?: TToastMessageType;
  content: string | JSX.Element;
  duration?: number;
}
export interface IToastRefHandles {
  showMessage(message: IToastMessage);
}
interface IProps {
  className?: string;
}

/**
 * @type {React.RefForwardingComponent}
 */
const ToastComponent: React.RefForwardingComponent<IToastRefHandles, IProps> = (
  props,
  forwardedRef,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const showTimer = useRef<number>(null);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<IToastMessage>(null);
  const mobileType = useMobileStatus();

  // clears timer unmount
  useEffect(() => {
    return () => clearTimeout(showTimer.current);
  }, []);

  const closeToast = useCallback(() => setShow(false), []);

  useImperativeHandle(forwardedRef, () => ({
    showMessage: (message: IToastMessage) => {
      const messageDuration = message.duration || 3000;

      if (showTimer.current) {
        clearTimeout(showTimer.current);

        showTimer.current = null;
      }

      if (show) {
        setShow(false);

        setTimeout(() => {
          setShow(true);
          setMessage(message);

          showTimer.current = window.setTimeout(closeToast, messageDuration);
        }, TOAST_CLOSE_DURATION);
      } else {
        setShow(true);
        setMessage(message);

        showTimer.current = window.setTimeout(closeToast, messageDuration);
      }
    },
  }));

  // skip server side rendering
  if (typeof window === 'undefined') {
    return null;
  }

  const messageContent = (message && message.content) || '';
  const messageType = (message && message.type) || 'info';

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cx(styles.Toast, props.className, {
        [styles.mobile]: mobileType === 'phone',
        [styles.active]: show,
        [styles.info]: messageType === 'info' || messageType === 'success',
        [styles.error]: messageType === 'error',
      })}
    >
      <div className={styles.content}>
        {messageType === 'error' && (
          <AlertIcon size={20} className={cx(styles.icon, styles.alertIcon)} />
        )}
        {messageType !== 'error' && (
          <CheckCircleIcon size={20} className={cx(styles.icon, styles.infoIcon)} />
        )}
        <div className={styles.message}>{React.Children.toArray(messageContent)}</div>
      </div>
      <CloseIcon className={styles.close} onMouseDown={closeToast} size={16} />
    </div>,
    document.body,
  );
};

export const Toast = React.forwardRef(ToastComponent);

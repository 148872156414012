import numeral from 'numeral';

import { TCurrencyCode } from 'src/common/models/campaign';

const DEFAULT_CURRENCY_FORMAT = '0,0[.]00';

const currencySymbol: {
  [key in TCurrencyCode]: string;
} = {
  USD: '$',
  EUR: '£',
};

/**
 * Formats the currency with given currency code and exchange rate.
 *
 * @param {Number} value the input value.
 * @param {TCurrencyCode} currencyCode the currency code.
 * @param {Number} currencyXRate the exchange rate.
 *
 * @return {String}
 */
export const formatCurrency = (
  value: number,
  currencyCode = 'USD',
  currencyXRate: number = 1,
  formatStr: string = DEFAULT_CURRENCY_FORMAT,
): string => {
  return (
    currencySymbol[currencyCode] +
    numeral(value * currencyXRate)
      .format(formatStr)
      .toUpperCase()
  );
};

import * as React from 'react';
import { times } from 'lodash';

import { Loading } from 'src/widgets/Skeleton';

import { Card } from '../Card/Card';

import styles from './PlaceholderContent.scss';

export const PlaceholderContent: React.FC = () => {
  const loadingElem = (
    <Loading
      show
      theme='grey'
    />
  );

  return (
    <div className={styles.Placeholder}>
      <Card className={styles.PapaCard}>
        <div className={styles.accountInfo}>
          <div className={styles.image}>
            {loadingElem}
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              {loadingElem}
            </div>
            <div className={styles.name2}>
              {loadingElem}
            </div>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.posts}>
          {times(9).map((i) => (
            <div
              className={styles.post}
              key={i}
            >
              {loadingElem}
            </div>
          ))}
        </div>
      </Card>
      <Card
        className={styles.MamaCard}
        header={<div className={styles.title}>{loadingElem}</div>}
      />
      <Card
        className={styles.BabyCard}
        header={<div className={styles.title}>{loadingElem}</div>}
      />
    </div>
  );
};

import * as React from 'react';
import { Tooltip } from 'src/widgets/Tooltip';
import { HelpIcon } from 'src/icons';

import { ISocialPost } from './PostCard';
import { percentage, dollar } from 'src/utils/formatNumbers';

import styles from './PostCard.scss';

interface IProps {
  post: ISocialPost;
}

/**
 * @type {React.FunctionComponent}
 */
const ValueSection: React.FunctionComponent<IProps> = React.memo(props => {
  const { post } = props;

  const costRef: React.RefObject<HTMLDivElement> = React.createRef();
  const mediaValueRef: React.RefObject<HTMLDivElement> = React.createRef();
  const roiRef: React.RefObject<HTMLDivElement> = React.createRef();
  const total_cost = (post.cost || post.product_cost) ?
    (Number(post.cost) + Number(post.product_cost)) : 0

  const isShowable = (value, formatter) => {
    if (!value || !formatter(value)) {
      return false
    }
    return true;
  }
  return (
    <ul className={styles.ValueSection}>
      {isShowable(total_cost, dollar) && <li className={styles.ValueSectionItem}>
        <span className={styles.ValueSectionItemLabel}>
          <span className={styles.Text}>Cost</span>

          <div className={styles.tooltip_container}>
            <span ref={costRef}>
              <HelpIcon size={20} />
            </span>
            <Tooltip mountRef={costRef} placement="top">
              <div className="tooltip_bottomLeft tooltip">
                <span className="tooltip_txt">
                  Based on agreed upon terms and payments made. May be an
                  estimate if multiple posts were made or agreed upon.
                </span>
              </div>
            </Tooltip>
          </div>
        </span>
        <span className="valueAmount">{total_cost === 0 ? '--' : dollar(total_cost) }</span>
      </li>}
      {isShowable(post.tmv, dollar) && <li className={styles.ValueSectionItem}>
        <span className={styles.ValueSectionItemLabel}>
          <span className={styles.Text}>TMV</span>
          <div className={styles.tooltip_container}>
            <span ref={mediaValueRef}>
              <HelpIcon size={20} />
            </span>
            <Tooltip mountRef={mediaValueRef} placement="top">
              <div className="tooltip_bottomLeft tooltip">
                <span className="tooltip_txt">
                  Calculated based on impressions, views, likes, comments and
                  clicks.
                </span>
              </div>
            </Tooltip>
          </div>
        </span>
        <span className="valueAmount">
          {post.tmv ? `${dollar(post.tmv)}` : '--'}
        </span>
      </li>}
      {isShowable(post.roi_pct, percentage) && <li className={styles.ValueSectionItem}>
        <span className={styles.ValueSectionItemLabel}>
          <span className={styles.Text}>ROI</span>
          <div className={styles.tooltip_container}>
            <span ref={roiRef}>
              <HelpIcon size={20} />
            </span>
            <Tooltip mountRef={roiRef} placement="top">
              <div className="tooltip_bottomLeft tooltip">
                <span className="tooltip_txt">
                  Return calculated based on cost and the resulting media value.
                </span>
              </div>
            </Tooltip>
          </div>
        </span>
        <span className="valueAmount">
          {post.roi_pct === '0' ? '--' : percentage(post.roi_pct)}
        </span>
      </li>}
    </ul>
  );
});

export default ValueSection;

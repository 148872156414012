import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import { Input } from 'src/widgets/Input/Input';

import styles from './RangeSlider.scss';

export interface IRangeInputProps {
  className?: string;
  formatStr?: string;
  max?: number;
  onChange(value: string);
  showPlusOnMaxRange?: boolean;
  value: number;
}

export const RangeInput: React.FC<IRangeInputProps> = (props) => {
  const {
    className,
    formatStr: formatStrProp,
    max,
    onChange,
    showPlusOnMaxRange,
    value,
  } = props;

  const formatStr = (formatStrProp || '0,0[.][00]');

  return (
    <Input
      className={cx(styles.Input, className)}
      value={numeral(value).format(formatStr) + (showPlusOnMaxRange && value === max ? '+' : '')}
      triggerChangeOnBlur
      onChange={onChange}
    />
  );
};

RangeInput.defaultProps = {
  showPlusOnMaxRange: false,
};

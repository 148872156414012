export enum ActionTypes {
  SET_SOCIAL_ACCOUNTS = '@watchListPage/SET_SOCIAL_ACCOUNTS',
  SET_WATCH_LISTS = '@watchListPage/SET_WATCH_LISTS',
  SET_SELECTED_LIST_ID = '@watchListPage/SET_SELECTED_LIST_ID',
}

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { ISocialListeningWatchList } from 'src/common/models/socialListeningWatchList';

/**
 * Action.
 */
export interface IWatchListPageAction {
  type: ActionTypes;
  payload?: {
    count?: number;
    socialAccounts?: ISocialAccount[];
    watchlists?: ISocialListeningWatchList[];
    selectedListId?: number;
  };
}

/* States */
export interface IWatchListPage {
  // endpoints
  apiEndpoint: string;
  // brand id and campaign id
  brand: IBrand;
  campaign: ICampaign;
  org: IOrganization;
  isQa: boolean;

  // watchlists
  watchlists: ISocialListeningWatchList[];
  selectedListId: number;

  // social accounts and total count
  count: number;
  socialAccounts: ISocialAccount[];
}

/**
 * Initial state.
 */
export const initialState: IWatchListPage = {
  apiEndpoint: null,
  brand: null,
  campaign: null,
  org: null,
  isQa: false,

  watchlists: [],
  selectedListId: null,

  count: 0,
  socialAccounts: [],
};

import * as React from 'react';
import cx from 'classnames';
import { isEmpty, isEqual, map } from 'lodash';
import { format, differenceInDays } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import {
  ClockIcon,
  KeyboardArrowLeftIcon,
  CheckCircleIcon,
  AlertIcon,
  CheckCircleYellowIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
  LinkButton,
  SubmitButton,
} from 'src/widgets/Button';
import { ContentPreviewer } from 'src/widgets/ContentPreviewer';
import { Notice } from 'src/widgets/Notice';
import { Textarea } from 'src/widgets/Textarea/Textarea';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/';
import { NetworkIcon } from 'src/common/NetworkIcon';

import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import processContentItems from './utils/processContentItems';

import { IContentInfo } from './model';

import styles from './AgencyContentItem.scss';

interface IProps {
  content: IContentInfo;
  onApprove();
  onReject(checkoff: boolean[], comment: string);
  goToRelationView();

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  isVisible: boolean;
  height: number;

  brandGuidelineCheckoff: boolean[];
  requestChangeComment: string;

  collapsed: boolean;
  isApproving: boolean;
  isRejecting: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class AgencyContentItem extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private ref: React.RefObject<HTMLDivElement>;
  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();
    this.toastRef = React.createRef();

    this.state = {
      isVisible: false,
      height: null,

      brandGuidelineCheckoff: props.content.brandGuidelineCheckoff,
      requestChangeComment: '',

      collapsed: props.content.rejected ? true : false,
      isApproving: false,
      isRejecting: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    // disable observer for Safari and older browers.
    if (typeof IntersectionObserver === 'undefined') {
      this.setState({
        isVisible: true,
      });

      return;
    }

    const node = this.ref.current;
    const observer = new IntersectionObserver(this.intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    });

    observer.observe(node);
  }

  /**
   * @private
   * Callback for IntersectionObserver.
   *
   * @param {IntersectionObserverEntry} entries the intersection observer entries.
   */
  private intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const node = this.ref.current;

    if (entry && node) {
      if (entry.intersectionRatio > 0.1) {
        this.setState({
          isVisible: true,
          height: node.offsetHeight,
        });
      } else {
        this.setState({
          isVisible: false,
          height: node.offsetHeight,
        });
      }
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    const { isVisible, height } = this.state;

    return (
      <div
        className={cx(classNames.concat(styles.AgencyContentItem))}
        ref={this.ref}
        style={{
          height: !isVisible ? `${height}px` : undefined,
        }}
      >
        {this.renderHeader()}
        {isVisible && this.renderBody()}
        <Toast ref={this.toastRef} />
      </div>
    );
  }

  private renderHeader = () => {
    const { content } = this.props;
    const { collapsed } = this.state;

    const diffDays = differenceInDays(content.dueTs * 1000, Date.now());

    return (
      <div
        className={cx(styles.header, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.info}>
          <div className={styles.networkAndDescription}>
            <div className={styles.network}>
              <NetworkIcon identifier={getNetworkByPostType(content.postType)} size={24} />
            </div>
            <div className={styles.description}>{content.productDescription}</div>
          </div>
          <div className={styles.campaignAndCreator}>
            for
            <span className={styles.campaign}>{content.campaign}</span>
            {content.creatorName && (
              <React.Fragment>
                from
                <span className={styles.creator}>{content.creatorName}</span>
              </React.Fragment>
            )}
          </div>
        </div>
        {!content.approved && (
          <div className={styles.timeLeft}>
            {!content.dueTs && diffDays < 0 && <Notice type="alert">You requested changes</Notice>}
            {content.dueTs && diffDays < 0 && <Notice type="error">Already past due</Notice>}
            {diffDays === 0 && (
              <Notice type="error">
                {formatDistanceToNow(new Date(content.dueTs * 1000))} left to review
              </Notice>
            )}
            {diffDays > 0 && (
              <React.Fragment>
                <ClockIcon className={styles.clockIcon} />
                <div>
                  <span>Review before {format(content.dueTs * 1000, 'MMM dd')} </span>(
                  {formatDistanceToNow(new Date(content.dueTs * 1000))} remaining)
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        {content.approved && (
          <Notice className={styles.approvedNotice} type="success">
            You approved this content
          </Notice>
        )}
        <IconButton
          icon={
            <KeyboardArrowLeftIcon
              className={cx(styles.icon, {
                [styles.collapsed]: collapsed,
              })}
            />
          }
          className={styles.collapseButton}
          onClick={this.toggleCollapsed}
        />
      </div>
    );
  };

  private renderBody = () => {
    const { content, goToRelationView } = this.props;
    const { brandGuidelineCheckoff, collapsed, isApproving, isRejecting } = this.state;

    const contents = processContentItems(content);

    const isRequestingChange = !isEqual(brandGuidelineCheckoff, content.brandGuidelineCheckoff);

    return (
      <div
        className={cx(styles.body, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.contents}>
            {!collapsed && (
              <ContentPreviewer
                contents={contents}
                allowExpanding={true}
                enableDetailView={true}
                containerWidth={360}
              />
            )}
          </div>
          <div className={styles.info}>
            {this.renderClientFeedbacks()}
            {content.caption && (
              <div className={styles.caption}>
                <div className={styles.title}>Caption</div>
                <div className={styles.text}>{content.caption}</div>
              </div>
            )}
            {!isEmpty(content.guidelines) && (
              <div className={styles.guidelines}>
                <div className={styles.title}>Guidelines for this content</div>
                <div className={styles.text}>
                  {map(content.guidelines, (guideline, index) => {
                    const brandApprove = !brandGuidelineCheckoff[index];
                    const creatorApprove = content.creatorGuidelineCheckoff[index];

                    return (
                      <div
                        className={cx(styles.guideline, {
                          [styles.alert]: !brandApprove,
                        })}
                        key={index}
                      >
                        {brandApprove && creatorApprove && (
                          <CheckCircleYellowIcon size={22} className={styles.guidelineIcon} />
                        )}
                        {!(brandApprove && creatorApprove) && (
                          <AlertIcon size={22} className={styles.guidelineIcon} />
                        )}
                        <div className={styles.guidelineContent}>
                          <div className={styles.guidelineText}>
                            {!creatorApprove && (
                              <span className={styles.creatorCheckOff}>
                                The creator chose not to do:{' '}
                              </span>
                            )}
                            {guideline}
                          </div>
                          {brandApprove && !content.approved && !content.rejected && (
                            <div
                              className={styles.requestChange}
                              onClick={this.toggleBrandCheckoff.bind(this, index)}
                            >
                              Request Changes
                            </div>
                          )}
                          {!brandApprove && !content.approved && !content.rejected && (
                            <div
                              className={styles.requestChange}
                              onClick={this.toggleBrandCheckoff.bind(this, index)}
                            >
                              Cancel Request
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!isRequestingChange && (
              <div className={styles.actions}>
                <SubmitButton
                  label={
                    content.approved
                      ? 'Approved'
                      : content.rejected
                        ? 'Cancel Request And Approve'
                        : 'Approve'
                  }
                  disabled={isApproving || content.approved}
                  isSubmitting={isApproving}
                  submittingLabel="Approving"
                  icon={<CheckCircleIcon />}
                  className={styles.approveButton}
                  onClick={this.onApproveClick}
                />
                <LinkButton onClick={goToRelationView}>View Relationship</LinkButton>
              </div>
            )}
            {isRequestingChange && (
              <div className={styles.requestingChanges}>
                <div className={styles.label}>
                  (Optional) Add a friendly comment about the changes you want:
                </div>
                <Textarea
                  className={styles.textarea}
                  placeholder="Describe your request..."
                  onChange={this.handleRequestCommentChange}
                  onPressEnter={this.onRejectClick}
                />
                <div className={styles.requestActions}>
                  <SubmitButton
                    className={cx(styles.button, styles.left)}
                    label="Send Request"
                    disabled={isRejecting}
                    isSubmitting={isRejecting}
                    submittingLabel="Sending..."
                    onClick={this.onRejectClick}
                  />
                  <Button
                    className={styles.button}
                    theme="info"
                    label="Cancel"
                    disabled={isRejecting}
                    onClick={this.cancelRequestChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  private renderClientFeedbacks = () => {
    const { content } = this.props;

    return (
      <div className={styles.feedbacks}>
        {map(content.clientFeedbacks, (feedback, index) => {
          const username = feedback.user.display_name || feedback.user.full_name;

          if (feedback.approved) {
            return (
              <Notice key={index} className={styles.notice} type="success" showDivider={true}>
                <div className={styles.noticeContent}>
                  <div className={styles.noticeTitle}>Content Approved by Client ({username}).</div>
                  <div className={styles.noticeBody}>
                    <span className={styles.noticeInfo}>Note: </span>
                    The creator only sees feedback from you. You can still choose to accept this
                    content, or reject it based on this feedback from your client.
                  </div>
                </div>
              </Notice>
            );
          } else if (!isEmpty(feedback.comment)) {
            return (
              <Notice key={index} className={styles.notice} type="alert" showDivider={true}>
                <div className={styles.noticeContent}>
                  <div className={styles.noticeTitle}>
                    Changes Requested by Client ({username}):
                  </div>
                  {feedback.comment && (
                    <div className={styles.clientComment}>
                      <q>{feedback.comment}</q>
                    </div>
                  )}
                  <div className={styles.noticeBody}>
                    <span className={styles.noticeInfo}>Note: </span>
                    The creator only sees feedback from you. You can still choose to accept this
                    content, or reject it based on this feedback from your client.
                  </div>
                </div>
              </Notice>
            );
          }

          return null;
        })}
      </div>
    );
  };

  private toggleCollapsed = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed,
    });
  };

  private toggleBrandCheckoff = (guidelineIndex: number) => {
    const { brandGuidelineCheckoff } = this.state;

    this.setState({
      brandGuidelineCheckoff: [
        ...brandGuidelineCheckoff.slice(0, guidelineIndex),
        !brandGuidelineCheckoff[guidelineIndex],
        ...brandGuidelineCheckoff.slice(guidelineIndex + 1),
      ],
    });
  };

  private handleRequestCommentChange = (requestChangeComment: string) => {
    this.setState({
      requestChangeComment,
    });
  };

  private cancelRequestChange = () => {
    const { content } = this.props;

    this.setState({
      brandGuidelineCheckoff: content.brandGuidelineCheckoff,
      requestChangeComment: '',
    });
  };

  private onRejectClick = () => {
    const { onReject, content } = this.props;
    const { brandGuidelineCheckoff, requestChangeComment } = this.state;
    const toast = this.toastRef.current;

    this.setState({
      isRejecting: true,
    });
    onReject(brandGuidelineCheckoff, requestChangeComment)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Rejected content
                {content.creatorName && (
                  <React.Fragment>
                    {' '}
                    by <span>{content.creatorName}</span>
                  </React.Fragment>
                )}
                .
              </div>
            ),
          });
        }

        this.setState({
          isRejecting: false,
        });
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to reject the content.',
            type: 'error',
          });
        }

        this.setState({
          isRejecting: false,
        });
      });
  };

  private onApproveClick = () => {
    const { onApprove, content } = this.props;
    const toast = this.toastRef.current;

    this.setState({
      isApproving: true,
    });
    onApprove()
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Approved content
                {content.creatorName && (
                  <React.Fragment>
                    {' '}
                    by <span>{content.creatorName}</span>
                  </React.Fragment>
                )}
                .
              </div>
            ),
          });
        }

        this.setState({
          isApproving: false,
        });
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to approve the content.',
            type: 'error',
          });
        }

        this.setState({
          isApproving: false,
        });
      });
  };
}

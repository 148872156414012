import {
  isEmpty,
  reduce,
} from 'lodash';

import { IGenderData, ISocialAccount } from 'src/common/models/socialAccount';

/**
 * Get aggregated gender data
 */
export const getAggregatedGenderData = (socialAccount: ISocialAccount): Omit<IGenderData, 'MALE' | 'FEMALE'> => {
  if (isEmpty(socialAccount)) {
    return;
  } if (!isEmpty(socialAccount.demographics_report) && !isEmpty(socialAccount.demographics_report.gender)) {
    return {
      male: (
        socialAccount.demographics_report.gender.MALE ||
        socialAccount.demographics_report.gender.male
      ),
      female: (
        socialAccount.demographics_report.gender.FEMALE ||
        socialAccount.demographics_report.gender.female
      ),
      unknown: socialAccount.demographics_report.gender.unknown,
    };
  } else if (!isEmpty(socialAccount.demographics_report) && !isEmpty(socialAccount.demographics_report.gender_age)) {
    return reduce(
      socialAccount.demographics_report.gender_age,
      (result, data) => ({
        male: result.male + (data.MALE || data.male || 0),
        female: result.female + (data.FEMALE || data.female || 0),
        unknown: result.unknown + data.unknown,
      }),
      {
        male: 0,
        female: 0,
        unknown: 0,
      },
    );
  }
};

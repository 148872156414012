import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const EnvelopeOpenIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.24,21.11H4.77C3.24,21.11,2,19.87,2,18.34V9.03c0-0.45,0-0.75,4.97-3.52c3.12-1.74,4.87-2.62,5.21-2.62
      c0.11,0,0.46,0,5.01,2.62C22,8.28,22,8.58,22,9.03v9.31C22,19.87,20.76,21.11,19.24,21.11z M3.36,9.28v9.06
      c0,0.78,0.63,1.41,1.41,1.41h14.47c0.78,0,1.41-0.63,1.41-1.41V9.27c-1.02-0.86-7.12-4.34-8.48-4.97
      C10.82,4.89,4.44,8.39,3.36,9.28z"
    />
    <path
      d="M12.2,16.77c-0.14,0-0.28-0.04-0.4-0.13l-7.7-5.71c-0.3-0.22-0.36-0.65-0.14-0.95C4.18,9.68,4.6,9.62,4.9,9.84
      l7.29,5.4L19,10.06c0.3-0.23,0.72-0.17,0.95,0.13c0.23,0.3,0.17,0.72-0.13,0.95l-7.21,5.49C12.49,16.72,12.34,16.77,12.2,16.77z"
    />
    <path
      d="M5.64,19.33c-0.25,0-0.5-0.14-0.61-0.38c-0.16-0.34-0.02-0.74,0.32-0.91l3.59-1.73
      c0.34-0.16,0.74-0.02,0.91,0.32c0.16,0.34,0.02,0.74-0.32,0.91l-3.59,1.73C5.84,19.31,5.74,19.33,5.64,19.33z"
    />
    <path
      d="M18.71,19.33c-0.09,0-0.19-0.02-0.28-0.06c-3.62-1.62-3.74-1.78-3.86-1.95c-0.22-0.3-0.16-0.73,0.15-0.95
      c0.26-0.19,0.62-0.17,0.85,0.04c0.3,0.19,1.95,0.96,3.42,1.61c0.34,0.15,0.5,0.55,0.34,0.9C19.22,19.18,18.97,19.33,18.71,19.33z
      M15.67,16.52C15.67,16.52,15.67,16.52,15.67,16.52C15.67,16.52,15.67,16.52,15.67,16.52z"
    />
  </SvgIcon>
));

export { EnvelopeOpenIcon };

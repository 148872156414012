import { isEmpty } from 'lodash';
import qs from 'qs';

import endpoints from 'src/common/config/endpoints';
import { useGet } from 'src/utils/hooks/useGet';
import { ISocialAccount } from 'src/common/models/socialAccount';

import { useInviteContext } from 'src/widgets/Invite/hooks';

interface IProps {
  apiEndpoint: string;
  id: ISocialAccount['id'];
}

/**
 * Fetch similar creators
 */
export const useSimilarCreators = (props: IProps) => {
  const {
    apiEndpoint,
    id,
  } = props;

  const inviteContext = useInviteContext();
  const { clientId = undefined } = inviteContext || {};

  const url = apiEndpoint && id
    ? `${apiEndpoint}/${endpoints.socialAccountEndpoint}?${qs.stringify({
      client_id: clientId,
      lookalike_account_id: id,
    })}`
    : null;

  const {
    loading: isFetching,
    data,
    error,
  } = useGet<{ data: ISocialAccount[] }>({ url });
  const similarCreators = !isEmpty(data)
    ? Object.freeze(data.data)
    : null

  return {
    similarCreators,
    isFetching,
    error,
  };
};

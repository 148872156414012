import * as React from 'react';
import cx from 'classnames';
import { get, isUndefined, map } from 'lodash';


const { useMemo, useState } = React;
import styles from './IconSelect.scss';

interface IOption {
  icon: any;
  value: TOptionValue;
}

type TOptionValue = string | number;

interface IIconSelectProps {
  className?: string;
  onChange(selectedOption: TOptionValue);
  classNames?: string[];
  selectedOption?: TOptionValue;
  defaultSelectedOption?: TOptionValue;
  options: IOption[];
}

export const IconSelect: React.FunctionComponent<IIconSelectProps> = React.memo((props) => {
  const { classNames, defaultSelectedOption, onChange, options, selectedOption } = props;

  const defaultOption = useMemo(() => {
    let defaultOption = defaultSelectedOption;
    if (isUndefined(defaultSelectedOption) && options.length) {
      defaultOption = get(options, '0.value');
    }
    return defaultOption;
  }, [defaultSelectedOption, options]);

  const [selectedOptionState, setSelectedOptionState] = useState(defaultOption);

  const isControlled = !isUndefined(selectedOption);
  const selectedOptionValue = isControlled ? selectedOption : selectedOptionState;

  return (
    <div className={cx(styles.IconSelect, classNames)}>
      {map(options, (option) => (
        <div
          key={option.value}
          className={cx(styles.modeItem, {
            [styles.active]: selectedOptionValue === option.value,
          })}
          onClick={() => {
            if (!isControlled) {
              setSelectedOptionState(option.value);
            }
            onChange(option.value);
          }}
        >
          {option.icon}
        </div>
      ))}
    </div>
  );
});

import { size, intersection } from 'lodash';
import { ILicensedContent } from 'src/common/models/licensedContent';

export const getCommonTags = (content: ILicensedContent[]): string[] => {
  const contentSize = size(content);
  if (contentSize > 0) {
    let overlap = content[0].tags;
    for (let i = 1; i < contentSize && size(overlap) > 0; i++) {
      overlap = intersection(overlap, content[i].tags);
    }
    return overlap;
  }
  return [];
};

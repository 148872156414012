import { IManager } from 'src/common/models/manager';

/**
 * Returns the name of a manager.
 *
 * @param {IManager} user the manager.
 *
 * @return {String}
 */
export default function(user: IManager) {
  return user && (user.display_name || user.full_name);
}

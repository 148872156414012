import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { InviteIcon } from 'src/icons/InviteIcon';
import { SubmitButton } from 'src/widgets/Button/SubmitButton';
import { IToastRefHandles, Toast } from 'src/widgets/Toast/Toast';
import { ISocialAccount } from 'src/common/models/socialAccount';

import { ICreatorList } from './redux/creatorListModel';
import creatorListActions, { FLPThunkDispatch } from './redux/creatorListActions';

import styles from './CampaignInviteButton.scss';

interface IOwnProps {
  socialAccounts: ISocialAccount[];
  classNames?: string[];
}
interface IDispatchProps {
  inviteToCampaign(...socialAccounts: ISocialAccount[]): Promise<void>;
}
type IProps = IOwnProps & IDispatchProps;
type TDefaultProp = 'classNames';
interface IState {
  // add to list popover
  isInviting: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
class CampaignInviteButton extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();

    this.state = {
      isInviting: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { socialAccounts, classNames } = this.props;
    const { isInviting } = this.state;

    const count = socialAccounts.length || 0;

    return (
      <React.Fragment>
        <SubmitButton
          label={`Invite ${count >= 100 ? '99+' : count} Creators`}
          icon={<InviteIcon size={18} />}
          submittingLabel="Inviting..."
          isSubmitting={isInviting}
          className={cx(classNames.concat(styles.CampaignInviteButton))}
          onClick={this.inviteToCampaignMulti}
          disabled={isInviting || isEmpty(socialAccounts)}
        />
        <Toast ref={this.toastRef} />
      </React.Fragment>
    );
  }

  private inviteToCampaignMulti = () => {
    const { socialAccounts, inviteToCampaign } = this.props;
    const toast = this.toastRef.current;

    this.setState({
      isInviting: true,
    });
    inviteToCampaign(...socialAccounts)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Invited &nbsp;<span>{socialAccounts.length}</span>&nbsp; creator
                {socialAccounts.length > 1 ? 's ' : ' '}
                to campaign.
              </div>
            ),
          });
        }

        this.setState({
          isInviting: false,
        });
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to invite the creators to campaign.',
            type: 'error',
          });
        }

        this.setState({
          isInviting: false,
        });
      });
  };
}

const mapStateToProps = (_: ICreatorList, ownProps: IOwnProps): IOwnProps => {
  return {
    socialAccounts: ownProps.socialAccounts,
    classNames: ownProps.classNames,
  };
};

const mapDispatchToProps = (dispatch: FLPThunkDispatch): IDispatchProps => {
  return {
    inviteToCampaign: (...args) => dispatch(creatorListActions.inviteToCampaign(...args)),
  };
};

export default connect<unknown, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignInviteButton);

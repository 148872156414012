import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { ActionTypes, IMentionedListPageAction, IMentionedListPage } from './models';

type MLPThunkAction<T> = ThunkAction<Promise<T>, IMentionedListPage, unknown, IMentionedListPageAction>;
export type MLPThunkDispatch = ThunkDispatch<IMentionedListPage, unknown, IMentionedListPageAction>;

import endpoints from 'src/common/config/endpoints';

/***************************
 ***** Private actions *****
 **************************/
const setSocialAccounts = (
  socialAccounts: ISocialAccount[],
  count: number,
): IMentionedListPageAction => {
  return {
    type: ActionTypes.SET_SOCIAL_ACCOUNTS,
    payload: {
      socialAccounts,
      count,
    },
  };
};

/***************************
 ***** public actions *****
 **************************/
const fetchSocialAccountsForPage = (page: number): MLPThunkAction<boolean> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state: IMentionedListPage = getState();
    const { campaign, apiEndpoint, socialAccounts: existingSocialAccounts } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialAccountEndpoint}?mentioned=true&campaign_id=${campaign.id}&page=${page}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      const count = json.data.count || 0;
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      // decide whether to reset or append
      dispatch(
        setSocialAccounts(
          page === 0 ? socialAccounts : [...existingSocialAccounts, ...socialAccounts],
          count,
        ),
      );

      return hasNext;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

export default {
  fetchSocialAccountsForPage,
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const GoogleDriveIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.54,14.31L15.15,3.2h-6.3l6.39,11.11H21.54z M9.87,15.24L6.72,20.8h12.13L22,15.24H9.87z
      M8.02,4.59L2,15.24l3.15,5.56l6.11-10.65L8.02,4.59z"
    />
  </SvgIcon>
));

import * as React from 'react';
import { createSelector } from 'reselect';
import { map, sortBy, get, find } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import addEventLog from 'src/common/utils/addEventLog';
import { IContentList } from 'src/common/models/contentList';
import { IConnectSearchPage } from '../models';
import { fetchContent, selectImage, removeContentFromList } from '../redux/imageSearchActions';

import { ImageSelectList } from '../ImageSearchFilters/ImageSelectList';

const { useEffect } = React;

interface IStateProps {
  images: Array<{ key: any; url: string }>;
  isLoading: boolean;
  errorMessage?: string;
}

interface IDispatchProps {
  onFetchContent(contentListId: number);
  onSelectImage(imageURL: string);
  onRemoveImageForKey(key: any);
}

interface IOwnProps {
  contentList: IContentList;
  onSelectImage?(imageURL: string);
}

const contentSelector = createSelector(
  (state: IConnectSearchPage) => state.imageSearch.contentByListId,
  (_, ownProps: IOwnProps) => ownProps.contentList.id,
  (contentByListId, contentListId) => {
    const content = contentByListId[contentListId];
    if (content) {
      return sortBy(content, (c) => -c.created_ts);
    }
  },
);

const imagesSelector = createSelector(
  contentSelector,
  (content) => {
    if (content) {
      return map(content, (c) => ({
        key: c.id,
        url: get(c, 'media.0.url'),
      }));
    }
  },
);

const handleRemoveImageById = (ownProps: IOwnProps, id: any) => (dispatch, getState) => {
  const state = getState();
  const content = contentSelector(state, ownProps);
  const contentToRemove = find(content, { id });
  dispatch(removeContentFromList(contentToRemove, ownProps.contentList.id));
};

const mapStateToProps = (state: IConnectSearchPage, ownProps: IOwnProps) => {
  const images = imagesSelector(state, ownProps);
  const hasError = state.imageSearch.hasContentError && !images;
  return {
    images,
    isLoading: state.imageSearch.isLoadingContent && !images,
    errorMessage: hasError ? 'Error loading content' : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps: IOwnProps) => {
  const boundActions = bindActionCreators(
    {
      onFetchContent: fetchContent,
    },
    dispatch,
  );
  return {
    ...boundActions,
    onSelectImage: (imageURL: string) => {
      if (ownProps.onSelectImage) {
        ownProps.onSelectImage(imageURL);
      }
      dispatch(selectImage(imageURL));

      addEventLog('search_with_saved_image', {
        list_name: ownProps.contentList.name,
      });
    },
    onRemoveImageForKey: (key: any) => {
      dispatch(handleRemoveImageById(ownProps, key));
    },
  };
};

type TImageSelectListWrapperProps = IStateProps & IDispatchProps & IOwnProps;

const ImageSelectListWrapper: React.FunctionComponent<TImageSelectListWrapperProps> = React.memo(
  (props) => {
    const {
      contentList,
      images,
      onFetchContent,
    } = props;

    useEffect(() => {
      if (!images) {
        onFetchContent(contentList.id);
      }
    }, [images, onFetchContent, contentList]);

    const emptyMessage = 'No saved images. Upload or like an image to get started!';

    return <ImageSelectList {...props} emptyMessage={emptyMessage} />;
  },
);

export const ConnectedImageSelectList = connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ImageSelectListWrapper);

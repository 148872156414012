import * as React from 'react';
import cx from 'classnames';
import { isEmpty, map, reject } from 'lodash';

import { NetworkIcon } from 'src/common';
import { ArrowDownFilledIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Popover } from 'src/widgets/Popover';
import { Loading } from 'src/widgets/Skeleton';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { useFetchPublisherSocialAccounts } from '../hooks/useFetchPublisherSocialAccounts';
import { getDisplayName } from '../utils';

import styles from './NetworkPicker.scss';
const { useCallback, useMemo, useRef, useState } = React;

interface IProps {
  className?: string;
}

export const NetworkPicker: React.FC<IProps> = React.memo((props) => {
  const { className } = props;

  const [showPopover, setShowPopover] = useState(false);

  const {
    apiEndpoint,
    setSocialAccount,
    socialAccount,
    hasData,
  } = useSocialProfileContext();
  const { socialAccounts } = useFetchPublisherSocialAccounts({
    apiEndpoint,
    publisherId: socialAccount?.publisher_id,
  });
  const otherSocialAccounts = useMemo(
    () => reject(socialAccounts, (account) => account.username === socialAccount.username),
    [socialAccounts],
  );

  const buttonRef = useRef<HTMLDivElement>();
  const renderButton = useCallback(() => {
    const hasPublisherAccounts = !isEmpty(otherSocialAccounts);
    const label = hasData
      ? <>
        {getDisplayName({
          socialAccount,
          usernameOnly: true,
        })}
        {hasPublisherAccounts && (
          <ArrowDownFilledIcon
            size={10}
            className={styles.arrowDownIcon}
          />
        )}
      </>
      : (
        <div className={styles.placeholder}>
          <Loading show theme='grey' />
        </div>
      );
    return (
      <Button
        className={cx(styles.button, {
          [styles.disableClick]: !hasPublisherAccounts,
        })}
        label={label}
        icon={hasData && (
          <NetworkIcon
            identifier={socialAccount.network_identifier}
            className={styles.publisherNetworkIcon}
          />
        )}
        onClick={() => hasPublisherAccounts && setShowPopover((show) => !show)}
        theme="light"
        ref={buttonRef}
      />
    );
  }, [hasData, otherSocialAccounts, socialAccount]);

  const renderPopover = useCallback((show) => (
    <Popover
      contentClassName={styles.popoverContent}
      mountRef={buttonRef}
      anchorOrigin="end"
      arrowPosition="end"
      minWidth={200}
      maxWidth={300}
      show={show}
      onRequestClose={() => setShowPopover(false)}
      offset={{
        x: 0,
        y: 6,
      }}
    >
      {map(otherSocialAccounts, (account, index) => (
        <Button
          key={index}
          className={styles.publisherAccountsButton}
          label={getDisplayName({
            socialAccount: account,
            usernameOnly: true,
          })}
          theme="light"
          icon={(
            <NetworkIcon
              identifier={account.network_identifier}
              className={styles.publisherNetworkIcon}
            />
          )}
          onClick={() => {
            setShowPopover((show) => !show);
            setSocialAccount(account);
          }}
        />
      ))}
    </Popover>
  ), [otherSocialAccounts, socialAccount]);

  return (
    <div className={cx(styles.NetworkPicker, className)}>
      {renderButton()}
      {renderPopover(showPopover)}
    </div>
  );
});

import * as React from 'react';

import { FileTypeIcon } from 'src/common/FileTypeIcon';

import { Content, IContentProps } from './Content';

import getFilenameFromUrl from 'src/common/utils/getFilenameFromUrl';

import styles from './ApplicationContent.scss';

/**
 * @class
 * @extends {React.Component}
 */
export class ApplicationContent extends React.Component<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames } = this.props;

    return (
      <Content content={content} classNames={classNames.concat(styles.ApplicationContent)}>
        <div className={styles.icon}>
          <FileTypeIcon filename={content.name || getFilenameFromUrl(content.fileUrl)} size={70} />
        </div>
      </Content>
    );
  }
}

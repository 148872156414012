import * as React from 'react';
import { filter, get, has, isEmpty, indexOf, keys } from 'lodash';

import { IMemberProgram, IMemberProgramMap, TProgram } from 'src/common/models/program';
import { IResource } from 'src/common/models/resource';
import { FetchContextProvider } from 'src/utils/context/FetchContext';

import { uniqueMemberPrograms } from '../utils';

const { createContext, useEffect, useState } = React;

interface IResourceContext {
  resourceId: IResource['id'];
  onRequestAddEmail?(type: TEmailType);
}

type TEmailType = 'gmail' | 'outlook';

export interface IInviteContext {
  apiEndpoint: string;
  clientId: string;
  userId: string;
  resourceContext: IResourceContext;
  memberPrograms: IMemberProgramMap;
  selectedProgramId: TProgram['id'];
  fetchingMemberPrograms: string[];
  updateResourceContext: React.Dispatch<React.SetStateAction<IResourceContext>>;
  updateMemberPrograms: (username: string, programs: IMemberProgram[]) => void;
  updateSelectedProgramId: React.Dispatch<React.SetStateAction<TProgram['id']>>;
  updateFetchingMemberPrograms: (...usernames: string[]) => void;
}

export const InviteContext = createContext<IInviteContext>(null);

interface IProps {
  apiEndpoint?: string;
  clientId: string;
  userId: string;
  onRequestAddEmail?(type: TEmailType);
}

export const InviteContextProvider: React.FC<IProps> = (props) => {
  const [resourceContext, setResourceContext] = useState<IResourceContext>({
    resourceId: null,
    onRequestAddEmail: props.onRequestAddEmail,
  });
  const [fetchingMemberPrograms, setFetchingMemberPrograms] = useState<string[]>([]);
  const [memberPrograms, setMemberPrograms] = useState<IMemberProgramMap>({});
  const [selectedProgramId, setSelectedProgramId] = useState<TProgram['id']>();

  // Remove usernames in `fetchingMemberPrograms` when `memberPrograms` is updated
  const memberUsernames = keys(memberPrograms).sort().join();
  useEffect(() => {
    if (!isEmpty(fetchingMemberPrograms) && !isEmpty(memberPrograms)) {
      setFetchingMemberPrograms((fmp) => filter(
        fmp,
        (username) => !has(memberPrograms, username),
      ));
    }
  }, [memberUsernames]);

  const value: IInviteContext = {
    apiEndpoint: props.apiEndpoint || '/api',
    clientId: props.clientId,
    userId: props.userId,
    resourceContext,
    memberPrograms,
    selectedProgramId,
    fetchingMemberPrograms,
    updateResourceContext: setResourceContext,
    updateMemberPrograms: (username: string, programs: IMemberProgram[]) => {
      setMemberPrograms((mp) => ({
        ...mp,
        [username]: uniqueMemberPrograms(
          ...programs,
          ...get(mp, username, []),
        ),
      }));
    },
    updateFetchingMemberPrograms: (...usernames: string[]) => {
      if (usernames.length > 0) {
        const filteredUsernames = filter(usernames, (u) => (
          !has(memberPrograms, u) &&
          indexOf(fetchingMemberPrograms, u) < 0
        ));
        if (filteredUsernames.length > 0) {
          setFetchingMemberPrograms((fmp) => [...fmp, ...filteredUsernames]);
        }
      }
    },
    updateSelectedProgramId: setSelectedProgramId,
  };

  return (
    <FetchContextProvider>
      <InviteContext.Provider value={value}>
        {props.children}
      </InviteContext.Provider>
    </FetchContextProvider>
  );
};

import * as React from 'react';
import qs from 'qs';

import { isEmpty } from 'lodash';

const { useEffect, useState } = React;

const fetchAnalyzeData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export function useFetchSummaryData<T extends any = any>(
  url: string,
  isAspirex: boolean,
  filters?: any
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);

  url = `${url}?${filtersToQueryString(filters)}`;

  useEffect(() => {
    if (!isEmpty(filters.brands) || isAspirex) {
      setLoading(true);
      fetchAnalyzeData(url)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url, filters.brands, isAspirex]);

  return {
    loading,
    data,
    error,
  };
}
export function useFetchSummaryDataCompare<T>(
  isComparing: boolean,
  url: string,
  isAspirex: boolean,
  filters?: any
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);
  // const comparableFilters = omit(filters, ['meta']);

  url = `${url}?${filtersToQueryString(filters)}`;

  useEffect(() => {
    if (isComparing && (!isEmpty(filters.brands) || isAspirex)) {
      setLoading(true);
      fetchAnalyzeData(url)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url, isComparing, filters.brands, isAspirex]);

  return {
    loading,
    data,
    error,
  };
}

export function useFetchBreakdownData<T>(
  url: string,
  parameters: any,
  isAspirex: boolean,
  filters?: any
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);

  url = `${url}?group=${parameters.groupBy}&${filtersToQueryString(filters)}`;
  useEffect(() => {
    if (!isEmpty(filters.brands) || isAspirex) {
      setLoading(true);
      fetchAnalyzeData(url)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url, filters, filters.brands, isAspirex]);

  return {
    loading,
    data,
    error,
  };
}

export function filtersToQueryString(filters: any) {
  const filtersForQuery = {
    networks: networksFromFilters(filters),
    brand_ids: brandsFromFilters(filters),
    campaign_ids: campaignsFromFilters(filters),
    post_start_date: startDateFromFilters(filters),
    post_end_date: endDateFromFilters(filters),
    country_codes: countriesFromFilters(filters),
    creator_ids: creatorsFromFilters(filters),
    owners: ownersFromFilters(filters),
    payment: paymentFromFilters(filters),
    product_cost: productCostFromFilters(filters),
    total_cost: totalCostFromFilters(filters),
    organization_id: filters.organizationId,
    activation_ids: activationsFromFilters(filters),
    community_ids: communitiesFromFilters(filters),
    program_ids: programsFromFilters(filters),
    client_id: filters.clientId,
  };
  return qs.stringify(filtersForQuery);
}

function networksFromFilters(filters: any) {
  if (!filters.networks) {
    return [];
  }
  return Object.keys(filters.networks.networks)
    .filter(key => {
      return filters.networks.networks[key];
    })
    .join(',');
}

function campaignsFromFilters(filters: any) {
  let campaignIds = [];
  if (!filters.brands) {
    return [];
  }

  for (const brand of filters.brands) {
    if (brand.campaigns) {
      const selectedCampaigns = brand.campaigns.filter(value => {
        return value.selected;
      });
      const selectedCampaignIds = selectedCampaigns.map(value => {
        return value.id;
      });
      campaignIds = campaignIds.concat(selectedCampaignIds);
    }
  }
  return campaignIds.join(',');
}

function brandsFromFilters(filters: any) {
  if (!filters.brands) {
    return [];
  }

  const selectedBrandIds = filters.brands
    .filter(brand => brand.selected)
    .map(brand => brand.id)
    .join(',');
  return selectedBrandIds;
}

function startDateFromFilters(filters: any) {
  if (!filters.datePosted) {
    return null;
  }
  const startDate = filters.datePosted.date.start;
  if (startDate) {
    return formattedStringFromDate(startDate);
  }
  return null;
}

function endDateFromFilters(filters: any) {
  if (!filters.datePosted) {
    return null;
  }
  const endDate = filters.datePosted.date.end;
  if (endDate) {
    return formattedStringFromDate(endDate);
  }
  return null;
}

function formattedStringFromDate(date: Date) {
  return (
    date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  );
}

function countriesFromFilters(filters: any) {
  if (!filters.countries) {
    return [];
  }
  const countries = filters.countries.countries;
  return Object.keys(countries)
    .filter(key => {
      return countries[key].selected;
    })
    .join(',');
}

function creatorsFromFilters(filters: any) {
  if (!filters.creators) {
    return [];
  }
  const creators = filters.creators.creators;
  return Object.keys(creators)
    .filter(key => {
      return creators[key].selected;
    })
    .join(',');
}

function ownersFromFilters(filters: any) {
  if (!filters.owners) {
    return [];
  }
  const owners = filters.owners.owners;
  const selectedOwners = owners.filter(value => {
    return value.selected;
  });
  return selectedOwners
    .map(value => {
      return value.email;
    })
    .join(',');
}

const paymentValuesToApiOperator = {
  lessThan: 'lt',
  greaterThan: 'gt',
  equalTo: 'eq',
  isBetween: 'ib',
};

function paymentFromFilters(filters: any) {
  const payment = filters.payment;
  return getOperation(payment);
}

function productCostFromFilters(filters: any) {
  const payment = filters.productCost;
  return getOperation(payment);
}

function totalCostFromFilters(filters: any) {
  const payment = filters.paymentAndProductCost;
  return getOperation(payment);
}

function activationsFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.activationIds) {
    return [];
  }

  const selectedActivationIds = filters.generic.activationIds.join(',');
  return selectedActivationIds;
}

function communitiesFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.communityIds) {
    return [];
  }

  const selectedCommunityIds = filters.generic.communityIds.join(',');
  return selectedCommunityIds;
}

function programsFromFilters(filters: any) {
  if (!filters.generic || !filters.generic.programIds) {
    return [];
  }

  const selectedProgramIds = filters.generic.programIds.join(',');
  return selectedProgramIds;
}

function getOperation(collection: any) {
  if (!collection) {
    return null;
  }
  const operator = paymentValuesToApiOperator[collection.option.label];
  if (!operator) {
    return null;
  }
  return operator === 'ib'
    ? [`gt:${collection.amountOne}`, `lt:${collection.amountTwo}`]
    : `${operator}:${collection.amountOne}`;
}

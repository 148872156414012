import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const MessageIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 21">
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon_message_color" transform="translate(-4.000000, -4.000000)" stroke="#3996E0">
        <g transform="translate(5.000000, 5.000000)">
          <path
            d="M10,16.6799134 C8.7673913,16.6799134 7.58695652,16.498961 6.49652174,16.1673593 C6.49652174,16.1673593 0.0279541016,20.4310117 0.75,18.8301299 C1.4720459,17.229248 3,14.3574026 3,14.3574026 C1.14913043,12.8829437 0,10.8288312 0,8.5565368 C0,4.06995671 4.47695652,0.432727273 10,0.432727273 C15.5230435,0.432727273 20,4.06995671 20,8.5565368 C20,13.042684 15.5230435,16.6799134 10,16.6799134 Z"
            id="Fill-1"
          ></path>
          <path
            d="M6.08695652,5.5 L12.1808445,5.5"
            id="Stroke-4"
            strokeWidth="0.65"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M6.08695652,8.5 L14.9934082,8.5"
            id="Stroke-6"
            strokeWidth="0.65"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M6.08695652,11.5 L14.9934082,11.5"
            id="Stroke-8"
            strokeWidth="0.65"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export { MessageIcon };

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ViewIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g transform="translate(-4.000000, -8.000000)">
      <g transform="translate(5.000000, 9.000000)">
        <path
          d="M11,16.9c-5.4,0-9.7-5.3-9.9-5.5c-0.2-0.2-0.2-0.5,0-0.8c0.2-0.2,4.4-5.5,9.9-5.5s9.7,5.3,9.9,5.5
                c0.2,0.2,0.2,0.5,0,0.8C20.7,11.6,16.4,16.9,11,16.9z M2.4,11c1.1,1.2,4.5,4.7,8.6,4.7s7.5-3.5,8.6-4.7C18.5,9.8,15,6.3,11,6.3
                S3.5,9.8,2.4,11z"
        />
        <path
          d="M11,14.9c-2.1,0-3.9-1.7-3.9-3.9S8.9,7.1,11,7.1s3.9,1.7,3.9,3.9S13.1,14.9,11,14.9z M11,8.4
                c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S12.5,8.4,11,8.4z"
        />
        <path
          d="M11,13.3c-1.2,0-2.3-1-2.3-2.3c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6c0,0.6,0.5,1,1,1s1-0.5,1-1
                s-0.5-1-1-1c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c1.2,0,2.3,1,2.3,2.3S12.2,13.3,11,13.3z"
        />
      </g>
    </g>
  </SvgIcon>
));

export { ViewIcon };

import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { Notice } from 'src/widgets/Notice';
import CreatorList from 'src/common/CreatorList';

import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import actions, { ILPThunkDispatch } from './redux/actions';
import { IInvitedListPage } from './redux/models';

const { useEffect, useState, useRef } = React;
import styles from './InvitedListPage.scss';

export interface IOwnProps {
  $state: any;

  onCreatorSelected?(socialAccount: ISocialAccount);
  sendBulkOffer(socialAccounts: ISocialAccount[]): void;
  exportCsv(socialAccounts: ISocialAccount[]): void;
  reportAsIncorrect(accountName: string): void;

  className?: string;
}
interface IStateProps {
  apiEndpoint: string;
  isQa: boolean;
  brandId: string;
  campaign: ICampaign;
  org: IOrganization;

  count: number;
  socialAccounts: ISocialAccount[];
}
interface IDispatchProps {
  fetchSocialAccountsForPage(page: number, totalCount: number | null): Promise<[boolean, number]>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

const PageTitle: React.FunctionComponent = React.memo(() => {
  return (
    <div className={styles.pageTitle}>
      <div className={styles.main}>Invited Creators</div>
      <div className={styles.sub}>Creators you&apos;ve invited from Recommended and Search</div>
    </div>
  );
});

/**
 * @type {React.FunctionComponent}
 */
const InvitedListPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    $state,
    apiEndpoint,
    brandId,
    campaign,
    count,
    exportCsv,
    fetchSocialAccountsForPage,
    isQa,
    onCreatorSelected,
    org,
    reportAsIncorrect,
    sendBulkOffer,
    socialAccounts,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async () => {
      let page = 0;
      let hasNext = true;
      let count = null;

      setIsLoading(true);
      while (ref.current && hasNext) {
        try {
          [hasNext, count] = await fetchSocialAccountsForPage(page, count);
          if (hasNext) {
            page++;
          }
        } catch (err) {
          setHasError(true);
          break;
        }
      }
      setIsLoading(false);
    })();
  }, [fetchSocialAccountsForPage]);

  return (
    <div ref={ref} className={cx(styles.InvitedListPage, props.className)}>
      {!hasError && (
        <CreatorList
          apiEndpoint={apiEndpoint}
          brandId={brandId}
          campaign={campaign}
          org={org}
          title={<PageTitle />}
          emptyMessage={`No creators have been invited for this campaign. Try searching or browsing recommended creators!`}
          loadingStatus={{
            isLoading,
            total: count,
            showProgress: true,
          }}
          initialSocialAccounts={socialAccounts}
          isQa={isQa}
          $state={$state}
          sendBulkOffer={sendBulkOffer}
          exportCsv={exportCsv}
          reportAsIncorrect={reportAsIncorrect}
          onCreatorSelected={onCreatorSelected}
          showCreateFeatures={true}
        />
      )}
      {hasError && (
        <Notice showDivider={true} className={styles.errorNotice} type="error">
          There was an error when trying to fetch the accounts. If this continues to happen, please
          email support@aspireiq.com.
        </Notice>
      )}
    </div>
  );
});

const mapStateToProps = (state: IInvitedListPage): IStateProps => {
  return {
    apiEndpoint: state.apiEndpoint,
    isQa: state.isQa,
    brandId: state.brandId,
    campaign: state.campaign,
    org: state.org,

    count: state.count,
    socialAccounts: state.socialAccounts,
  };
};
const mapDispatchToProps = (dispatch: ILPThunkDispatch): IDispatchProps => {
  return {
    fetchSocialAccountsForPage: (...args) => dispatch(actions.fetchSocialAccountsForPage(...args)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(InvitedListPage);

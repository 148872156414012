import { IContent } from './content';
import { IDeliverable } from './deliverable';
import { IManager } from './manager';

export enum ContentReviewState {
  WAITING_FOR_SUBMISSION = 'PLACEHOLDER',
  SUBMITTED = 'NEW',
  GCR_PENDING = 'GCR_PENDING',
  SOFT_APPROVED = 'SOFT_APPROVED',
  APPROVED = 'ACCEPTED',
  SOFT_REJECTED = 'SOFT_REJECTED',
  REJECTED = 'REJECTED',
  AMENDED = 'AMENDED',
  UPLOADED = 'UPLOADED', // TODO: Is this still necessary?
  COMPLETED_WITHOUT_PAYMENT = 'COMPLETED_WITHOUT_PAYMENT',
  COMPLETED = 'COMPLETED',
}

export interface IClientFeedback {
  user: IManager;
  approved?: boolean;
  comment: string;
}

export enum GroupContentReviewMessageType {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export interface IGroupContentReviewMessage {
  message_type: GroupContentReviewMessageType;
  user: IManager;
  comment: string;
  read_by: number[];
  created_timestamp?: number;
}

export interface IContentReview {
  content: IContent;
  project_id: number;
  relation_id: number;
  brand_id: number;
  campaign_name: string;
  client_feedbacks: IClientFeedback[];
  automatic_approval_expire_time_ts: number;
  reupload_unlisted_count: number;
  brand_views_final_upload_without_payment: boolean;
  date_last_reject?: any;
  deliverable_type: string;
  live_upload_link?: any;
  brand_reject_comment?: any;
  collapse: boolean;
  date_payment_request_made?: any;
  date_first_upload: number;
  date_resubmitted?: number;
  product: IDeliverable;
  iteration: string;
  post?: any;
  state: ContentReviewState;
  id: number;
  content_requirement_id?: number;
  brand_needs_review?: boolean;
  group_content_review_messages?: IGroupContentReviewMessage[];
  hybrid_rejected_guidelines?: boolean[];
  hybrid_approved_feedback?: string;
  hybrid_rejected_feedback?: string;
  gcr_brand_has_rejected?: boolean;
  publisher_username?: string;
  publisher_profile_image?: string;
}

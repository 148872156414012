import * as React from 'react';
import cx from 'classnames';
import { map, isEqual, size } from 'lodash';
import { SortableContainer, SortEndHandler } from 'react-sortable-hoc';

import {
  IContentGuideline,
  IContentGuidelineSection,
  IGuidelinePosition,
} from './contentGuidelineModel';
import Guideline, { SortableGuideline } from './Guideline';

const { useRef, useState } = React;

import styles from './ContentGuidelines.scss';

interface IGuidelineListProps {
  onClickGuideline(guideline: IContentGuideline, position: IGuidelinePosition);
  onChangeGuideline(newGuideline: IContentGuideline, position: IGuidelinePosition);
  onPaste(pastedText: string, position: IGuidelinePosition);
  onBlur(position: IGuidelinePosition);
  onEnterKeyDown(position: IGuidelinePosition);

  section: IContentGuidelineSection;
  sectionIndex: number;
  guidelines: IContentGuideline[];
  selectedPosition: IGuidelinePosition;
  classNames?: string[];
}

interface ISortableGuidelineListProps extends IGuidelineListProps {
  onSortEnd: SortEndHandler;
}

const GuidelineList = React.forwardRef<HTMLDivElement, IGuidelineListProps>((props, ref) => {
  const { guidelines, sectionIndex, section, selectedPosition } = props;

  return (
    <div ref={ref} className={cx(styles.list, props.classNames)}>
      {map(guidelines, (guideline, listIndex) => {
        const position = { sectionIndex, listIndex };
        return (
          <SortableGuideline
            index={listIndex}
            key={`${sectionIndex},${listIndex}`}
            guideline={guideline}
            section={section}
            position={position}
            isSelected={isEqual(position, selectedPosition)}
            onChange={props.onChangeGuideline}
            onClick={props.onClickGuideline}
            onPaste={props.onPaste}
            onBlur={props.onBlur}
            onEnterKeyDown={props.onEnterKeyDown}
          />
        );
      })}
      <Guideline
        position={{ sectionIndex, listIndex: size(guidelines) }}
        section={section}
        isSelected={isEqual({ sectionIndex, listIndex: size(guidelines) }, selectedPosition)}
        onChange={props.onChangeGuideline}
        onClick={props.onClickGuideline}
        onPaste={props.onPaste}
        onBlur={props.onBlur}
        onEnterKeyDown={props.onEnterKeyDown}
      />
    </div>
  );
});

const GuidelinesContainer = SortableContainer(GuidelineList, { withRef: true });

export const SortableGuidelineList: React.FunctionComponent<ISortableGuidelineListProps> = (
  props,
) => {
  const ref = useRef<any>();
  const [isSorting, setIsSorting] = useState<boolean>();

  const getHelperContainer = () => {
    return ref.current.getWrappedInstance();
  };

  const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }, e) => {
    props.onSortEnd({ oldIndex, newIndex, collection: props.sectionIndex }, e);
    setIsSorting(false);
  };

  const handleSortStart = () => {
    setIsSorting(true);
  };

  return (
    <GuidelinesContainer
      {...props}
      classNames={isSorting ? [styles.sorting] : undefined}
      ref={ref}
      lockAxis="y"
      axis="y"
      helperClass={styles.dragging}
      helperContainer={getHelperContainer}
      distance={5}
      onSortEnd={handleSortEnd}
      lockToContainerEdges={true}
      lockOffset={['0px', '0px']}
      onSortStart={handleSortStart}
    />
  );
};

export default GuidelineList;

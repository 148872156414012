// import Bluebird from 'bluebird';
import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  find,
  isEmpty,
  isFunction,
  isNumber,
} from 'lodash';

import { CreatorDetailOverlay } from 'src/widgets/CreatorDetailOverlay';
import { Notice } from 'src/widgets/Notice';
import { IToastRefHandles, Toast } from 'src/widgets/Toast';
import { SocialProfileOverlay } from 'src/widgets/SocialProfile';
import CreatorList from 'src/common/CreatorList';
import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { willShowSPv2 } from 'src/utils/connectUtils';

import { OAuthNotice } from './OAuthNotice';
import actions, { MLPThunkDispatch } from './redux/actions';
import { IMentionedListPage } from './redux/models';

const { useEffect, useState, useRef } = React;

import styles from './MentionedListPage.scss';

export interface IOwnProps {
  $state: any;

  onCreatorSelected?(socialAccount: ISocialAccount);
  sendBulkOffer(socialAccounts: ISocialAccount[]): void;
  exportCsv(socialAccounts: ISocialAccount[]): void;
  reportAsIncorrect(accountName: string): void;

  className?: string;
}
interface IStateProps {
  apiEndpoint: string;
  isQa: boolean;
  brand: IBrand;
  campaign: ICampaign;
  org: IOrganization;

  count: number;
  socialAccounts: ISocialAccount[];
}
interface IDispatchProps {
  fetchSocialAccountsForPage(page: number): Promise<boolean>;
}
export type TMentionedListPageProps = IOwnProps & IStateProps & IDispatchProps;

const PageTitle: React.FunctionComponent<{
  brand: IBrand;
}> = React.memo(({ brand }) => {
  return (
    <div className={styles.pageTitle}>
      <div className={styles.main}>Creators that mentioned {brand.name || 'you'}</div>
      <div className={styles.sub}>
        Creators that have mentioned{' '}
        {brand.instagram_username ? `@${brand.instagram_username}` : 'you'} on Instagram
      </div>
    </div>
  );
});

/**
 * @type {React.FunctionComponent}
 */
const MentionedListPage: React.FunctionComponent<TMentionedListPageProps> = React.memo((props) => {
  const {
    $state,
    apiEndpoint,
    brand,
    campaign,
    count,
    exportCsv,
    fetchSocialAccountsForPage,
    isQa,
    onCreatorSelected: onCreatorSelectedProp,
    org,
    reportAsIncorrect,
    sendBulkOffer,
    socialAccounts,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const toastRef = useRef<IToastRefHandles>(null);
  const [selectedSocialAccountId, setSelectedSocialAccountId] = useState<number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);

  const oauthStatus =
    typeof $state !== 'undefined' && typeof $state.startParams !== 'undefined'
      ? $state.startParams.oauthStatus
      : '';
  const shouldDisplayOauthNotice =
    (hasFeature(org, 'tagged_mentions') &&
      brand.instagram_username &&
      !brand.has_tagged_mentions_access) ||
    oauthStatus !== '';

  const listeningDisabled = hasFeature(org, 'disable_listening');
  const invalidCampaign = !campaign.network_types || !campaign.network_types.includes('instagram');
  const instagramNotSetup = !brand.instagram_username;
  const featureDisabled = listeningDisabled || invalidCampaign || instagramNotSetup;

  useEffect(() => {
    if (!featureDisabled) {
      (async () => {
        let page = 0;
        let hasNext = true;

        setIsLoading(true);
        while (ref.current && hasNext) {
          try {
            hasNext = await fetchSocialAccountsForPage(page);

            if (hasNext) {
              page++;
            }
          } catch (err) {
            setFetchFailed(true);
            break;
          }
        }
        setIsLoading(false);
      })();
    }
  }, [featureDisabled, fetchSocialAccountsForPage]);

  const linkInstagram = () => {
    const url = $state.href('brand_edit', {
      brandId: brand.id,
    });

    window.location.replace(url);
  };

  const onCreatorSelected = (socialAccount: ISocialAccount) => {
    setSelectedSocialAccountId(socialAccount.id);

    if (isFunction(onCreatorSelectedProp)) {
      onCreatorSelectedProp(socialAccount);
    }
  };

  const closeDetailView = () => {
    setSelectedSocialAccountId(null);
  };

  const renderSocialProfileOverlay = () => {
    const selectedSocialAccount = find(
      socialAccounts,
      (socialAccount) => socialAccount.id === selectedSocialAccountId,
    );
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const show = isNumber(selectedSocialAccountId) || !isEmpty(selectedSocialAccount);

    return willShowSPv2(selectedSocialAccount)
      ? (
        <SocialProfileOverlay
          campaign={campaign}
          socialAccount={selectedSocialAccount}
          show={show}
          isQa={isQa}
          apiEndpoint={apiEndpoint}
          loadAccountDetails={true}
          showSimilarCreators={true}
          toastRef={toastRef}
          onRequestClose={closeDetailView}
          selfServeExperiment={selfServeExperiment}
          brandInstagramUsername={brand.instagram_username}
        />
      )
      : (
        <CreatorDetailOverlay
          org={org}
          campaign={campaign}
          apiEndpoint={apiEndpoint}
          loadDetail={true}
          loadRelated={true}
          show={show}
          socialAccount={selectedSocialAccount}
          onRequestClose={closeDetailView}
          isQa={isQa}
          selfServeExperiment={selfServeExperiment}
        />
      );
  };

  return (
    <div ref={ref} className={cx(styles.MentionedListPage, props.className)}>
      {!shouldDisplayOauthNotice && (
        <>
          {!featureDisabled && !fetchFailed && (
            <CreatorList
              apiEndpoint={apiEndpoint}
              brandId={brand.id}
              campaign={campaign}
              org={org}
              title={<PageTitle brand={brand} />}
              emptyMessage={`No creators have mentioned @${brand.instagram_username} recently. Try searching or browsing recommended creators for you instead!`}
              loadingStatus={{
                isLoading,
                total: count,
                showProgress: true,
              }}
              initialSocialAccounts={socialAccounts}
              isQa={isQa}
              $state={$state}
              sendBulkOffer={sendBulkOffer}
              exportCsv={exportCsv}
              reportAsIncorrect={reportAsIncorrect}
              showRelevantPostImage={true}
              onCreatorSelected={onCreatorSelected}
              selectedSocialAccountId={null}
              initialDisplayMode={'grid'}
              showSocialProfileOnCreatorSelect={false}
            />
          )}
          {listeningDisabled && (
            <div className={styles.featureDisabled}>
              <div className={styles.title}>Subscription Upgrade Required.</div>
              <div className={styles.text}>
                Contact your account representative to invite influencers that mention your brand to
                your campaign!
              </div>
            </div>
          )}
          {!listeningDisabled && invalidCampaign && (
            <div className={styles.featureDisabled}>
              <div className={styles.title}>Sorry!</div>
              <div className={styles.text}>
                Mentioned you is only available at this time for campaigns that are inviting
                creators from Instagram.
              </div>
            </div>
          )}
          {!listeningDisabled && !invalidCampaign && instagramNotSetup && (
            <div className={styles.featureDisabled}>
              <div className={styles.title}>Need brand instagram username</div>
              <div className={styles.text}>
                Set up your brand instagram
                <span className={styles.linkInstagram} onClick={linkInstagram}>
                  {' '}
                  here
                </span>{' '}
                to start tracking creator mentions!
              </div>
            </div>
          )}
          {!featureDisabled && fetchFailed && (
            <Notice showDivider={true} className={styles.errorNotice} type="error">
              There was an error when trying to fetch the accounts. If this continues to happen,
              please email support@aspireiq.com.
            </Notice>
          )}
        </>
      )}
      {shouldDisplayOauthNotice && (
        <>
          <PageTitle brand={brand} />
          <OAuthNotice campaign={campaign} oauthStatus={oauthStatus} />
        </>
      )}
      {renderSocialProfileOverlay()}
      <Toast ref={toastRef} />
    </div>
  );
});

const mapStateToProps = (state: IMentionedListPage): IStateProps => {
  return {
    apiEndpoint: state.apiEndpoint,
    isQa: state.isQa,
    brand: state.brand,
    campaign: state.campaign,
    org: state.org,

    count: state.count,
    socialAccounts: state.socialAccounts,
  };
};
const mapDispatchToProps = (dispatch: MLPThunkDispatch): IDispatchProps => {
  return {
    fetchSocialAccountsForPage: (...args) => dispatch(actions.fetchSocialAccountsForPage(...args)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MentionedListPage);

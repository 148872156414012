import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import { Button } from 'src/widgets/Button';
import { Invite } from 'src/widgets/Invite';
import { CreatorActionButtonGroup } from 'src/common/CreatorActionButtonGroup/CreatorActionButtonGroup';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';
import { FavoriteButton } from 'src/widgets/FavoriteButton/FavoriteButton';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { Tab } from '../models';
import { NetworkPicker } from '../NetworkPicker/NetworkPicker';

import styles from './Header.scss';

export interface ISocialProfileTab {
  type: Tab;
  networks: TNetworkIdentifier[];
  onClick: () => void;
}

export const Header: React.FC = () => {
  const {
    activeTab,
    allowFavorite,
    apiEndpoint,
    campaign,
    goToManage,
    hasData,
    hideActions,
    inviteToCampaign,
    isInviting,
    network,
    noDataPulled,
    selfServeExperiment,
    sendOffer,
    setActiveTab,
    setIsInviting,
    showCreateFeatures,
    socialAccount,
  } = useSocialProfileContext();

  const inviteCallback = () => {
    if (isFunction(inviteToCampaign)) {
      setIsInviting(true);
      inviteToCampaign(socialAccount)
        .finally(() => {
          setIsInviting(false);
        });
    }
  };
  const offerCallback = isFunction(sendOffer) && socialAccount
    ? sendOffer.bind({}, socialAccount.id)
    : null;
  const manageCallback = isFunction(goToManage) && socialAccount
    ? goToManage.bind({}, socialAccount.relation_id)
    : null;

  const tabs: ISocialProfileTab[] = [
    {
      type: Tab.SUMMARY,
      networks: ['instagram', 'youtube'],
      onClick: () => setActiveTab(Tab.SUMMARY),
    },
    {
      type: Tab.ANALYZE,
      networks: ['instagram', 'youtube'],
      onClick: () => setActiveTab(Tab.ANALYZE),
    },
  ];

  const renderTabs = () => tabs.length > 0 && !noDataPulled && (
    <nav className={styles.tabs}>
      {tabs
        .filter((tab) => tab.networks.includes(network))
        .map((tab, i) => (
          <Button
            key={`social-profile-tab-${i}`}
            className={cx(styles.tab, {
              [styles.activeTab]: tab.type === activeTab,
            })}
            label={tab.type}
            onClick={tab.onClick}
            theme='light'
          />
        ))}
    </nav>
  );

  const renderActionButtons = () => !selfServeExperiment && !hideActions && !noDataPulled && (
    <div className={styles.actions}>
      {showCreateFeatures
        ? <>
          <CreatorActionButtonGroup
            className={styles.actionButtonGroup}
            socialAccount={socialAccount}
            isInviting={isInviting}
            showInviteButton={isFunction(inviteToCampaign)}
            inviteCallback={inviteCallback}
            inviteButtonType={'primary'}
            showOfferButton={isFunction(sendOffer)}
            offerCallback={offerCallback}
            manageCallback={manageCallback}
          />
          {allowFavorite && campaign && (
            <FavoriteButton
              className={styles.favoriteButton}
              size={24}
              apiEndpoint={apiEndpoint}
              campaign={campaign}
              socialAccount={socialAccount}
            />
          )}
        </>
        : (
          <Invite
            className={styles.invite}
            prospect={{ socialAccount }}
          />
        )}
    </div>
  );

  return (
    <header className={styles.Header}>
      <NetworkPicker />
      {hasData && <>
        {renderTabs()}
        {renderActionButtons()}
      </>}
    </header>
  );
};

import { assign } from 'lodash';
import { ActionTypes, IInvitedListPage, IInvitedListPageAction } from './models';

const reducer = (state: IInvitedListPage, action: IInvitedListPageAction): IInvitedListPage => {
  switch (action.type) {
    case ActionTypes.SET_SOCIAL_ACCOUNTS: {
      const { socialAccounts, count = 0 } = action.payload;

      return assign({}, state, {
        count,
        socialAccounts,
      });
    }

    default:
      return state;
  }
};

export default reducer;

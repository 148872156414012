import * as React from 'react';
import { map, some } from 'lodash';

import { IContentReview } from 'src/common/models/contentReview';
import { IToastRefHandles, Toast } from 'src/widgets/Toast';
import { SubmitButton  } from 'src/widgets/Button';
import { Checkbox } from 'src/widgets/Checkbox';
import { Textarea } from 'src/widgets/Textarea/Textarea';

const { useState, useEffect, useRef } = React;

import styles from './RejectContent.scss';

interface IProps {
  contentReview: IContentReview;

  // actions
  submitReject(guidelineCheckoff: boolean[], comment: string): Promise<void>;
}

/**
 * @type {React.FunctionComponent}
 */
export const RejectContent: React.FunctionComponent<IProps> = (props) => {
  const { contentReview, submitReject } = props;

  const toastRef = useRef<IToastRefHandles>();

  const guidelineLength = contentReview.product.checkoff_guidelines.length;

  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rejectedGuidelines, setRejectedGuidelines] = useState(
    new Array(guidelineLength).fill(false),
  );
  const [hasRejectedGuidelines, setHasRejectedGuidelines] = useState(false);

  const handleCommentChange = (value: string) => setComment(value);

  useEffect(() => {
    setHasRejectedGuidelines(rejectedGuidelines.length == 0 || some(rejectedGuidelines));
  },[rejectedGuidelines]);

  const handleSubmitReject = async () => {
    if (hasRejectedGuidelines) {
      setIsSubmitting(true);
      try {
        await submitReject(rejectedGuidelines, comment);
        setIsSubmitting(false);
        setComment('');
        setRejectedGuidelines(
          new Array(guidelineLength).fill(false),
        );
      } catch (err) {
        setIsSubmitting(false);
      }
    } else {
      const toast = toastRef.current;
      if (toast) {
        toast.showMessage({
          content: 'Please select the guideline(s) that you are rejecting on.',
          type: 'error',
        });
      }
    }
  };

  const handleGuidelineCheckChanged = (index, checked) => {
    setRejectedGuidelines([
      ...rejectedGuidelines.slice(0, index),
      checked,
      ...rejectedGuidelines.slice(index + 1),
    ]);
  };

  const renderGuidelines = () => {
    return map(contentReview.product.checkoff_guidelines, (guideline, index) => {
      return (
        <div key={index} className={styles.guideline}>
          <div className={styles.guidelineCheckbox}>
            <Checkbox
              className={styles.checkbox}
              onChange={(checked) => handleGuidelineCheckChanged(index, checked)}
            />
          </div>
          <div className={styles.guidelineText}>{guideline}</div>
        </div>
      );
    });
  };

  return (
    <div className={styles.RejectContent}>
      <Toast ref={toastRef} />
      <div className={styles.header}>Reject this content</div>
      <div className={styles.commentBox}>
        <div className={styles.commentHeader}>Write your comment:</div>
        <Textarea className={styles.textArea} onChange={handleCommentChange}/>
      </div>
      <div className={styles.guidelineContainer}>
        <div className={styles.guidelineHeader}>Select guideline(s) not met:</div>
        {renderGuidelines()}
      </div>
      <div className={styles.actionContainer}>
        <SubmitButton
          className={styles.submitButton}
          isSubmitting={isSubmitting}
          submittingLabel="Submitting"
          label="Submit Rejection"
          onClick={handleSubmitReject}
          theme="danger"
        />
      </div>
    </div>
  );
};

import { assign, filter, uniqBy } from 'lodash';
import { Middleware, Dispatch } from 'redux';

import { ActionTypes, IRecommendedPage, IRecommendedPageAction } from './models';

const processSocialAccounts: Middleware<unknown, IRecommendedPage> = ({ getState }) => (
  next: Dispatch<IRecommendedPageAction>,
) => (action: IRecommendedPageAction) => {
  if (action.type === ActionTypes.SET_SOCIAL_ACCOUNTS) {
    const { campaign } = getState();
    const { socialAccounts } = action.payload;

    action.payload = assign(
      {},
      {
        ...action.payload,
        socialAccounts: filter(uniqBy(socialAccounts, 'id'), (socialAccount) => {
          return true;
          if (socialAccount.invite) {
            if (socialAccount.invite.approved || socialAccount.invite.rejected) {
              return false;
            }
            if (socialAccount.invite.campaign_id !== campaign.id) {
              return false;
            }
          } else {
            return false;
          }

          if (socialAccount.has_relationship) {
            return false;
          }

          return true;
        }),
      },
    );
  }

  return next(action);
};

export default [processSocialAccounts];

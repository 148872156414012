import * as React from 'react';
import cx from 'classnames';

import { CloseIcon } from 'src/icons';
import { Accordion } from 'src/widgets/Accordion';
import { Button } from 'src/widgets/Button';

import styles from './FilterSection.scss';

interface IFilterSectionProps {
  header?: string;
  collapsible?: boolean;
  defaultIsOpen?: boolean;
  hide?: boolean;
  classNames?: string[];
  isSubsection?: boolean;
  showClearButton?: boolean;
  onClear?();
}

export const FilterSection: React.FunctionComponent<IFilterSectionProps> = React.memo((props) => {
  const {
    children,
    classNames = [],
    collapsible = false,
    defaultIsOpen,
    header,
    hide = false,
    isSubsection = false,
    onClear,
    showClearButton = false,
  } = props;

  if (hide) {
    return null;
  }

  const contents = (() => {
    if (collapsible) {
      return (
        <Accordion
          className={styles.accordion}
          label={header}
          defaultIsOpen={defaultIsOpen}
        >
          {children}
        </Accordion>
      );
    } else {
      return <>
        {header && (
          <div className={styles.header}>
            {header}
            {showClearButton && (
              <Button
                label="Clear"
                className={styles.clear}
                onClick={onClear}
                border={false}
                theme="light"
                round={false}
                icon={<CloseIcon size={10} />}
              />
            )}
          </div>
        )}
        {children}
      </>;
    }
  })();

  return (
    <div
      className={cx(styles.FilterSection, classNames, {
        [styles.isSubsection]: isSubsection,
      })}
    >
      {contents}
    </div>
  );
});

import * as React from 'react';
import cx from 'classnames';
import { Draggable, DraggingStyle } from 'react-beautiful-dnd';
import { isNumber } from 'lodash';

import { ICellProps } from './Cell';

import styles from './ReorderableCell.scss';

interface IProps extends Pick<ICellProps, 'className' | 'config'> {
  /**
   * Column index
   */
  index?: number;
  /**
   * Set x offset because of react-dnd-beautiful's positioning issues
   * with ancestors having "transform" CSS property
   */
  xOffset: number;
}

/**
 * Reorderable cell -- only used as a wrapper for a Cell-type component
 */
export const ReorderableCell: React.FC<IProps> = React.memo((props) => {
  const {
    children,
    className,
    config,
    index,
    xOffset,
  } = props;

  return (
    <Draggable
      key={config.field}
      draggableId={config.field}
      index={index}
    >
      {(provided, snapshot) => {
        const left = (provided.draggableProps.style as DraggingStyle).left;
        return (
          <div
            className={cx(styles.ReorderableCell, className, {
              [styles.isDragging]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              /**
               * Override position due to react-dnd-beautiful's positioning issues
               * with ancestors having "transform" CSS property
               */
              top: 0,
              left: isNumber(left) ? left - xOffset : undefined,
            }}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
});

import { chain, map } from 'lodash';
import { IAdvertiserAccountAccessRequirement } from 'src/common/models/advertiserAccountAccessRequirement';
import { IAdvertiserAccessTableRowData } from '../redux/models';

export const getAdvertiserAccessTableRowData = (
  requirements: IAdvertiserAccountAccessRequirement[],
) =>
  chain(requirements)
    .groupBy('publisher')
    .map((values, publisher) => ({
      publisher_id: values[0].publisher_id,
      publisher,
      accounts: map(values, (value) => ({
        type: value.account_type,
        accountName: value.account_name,
      })),
      startTs: values[0].access_start_ts,
      endTs: values[0].access_end_ts,
      selectedForUse: values[0].selected_for_use,
      readyForUse: values[0].ready_for_use,
    }))
    .value() as IAdvertiserAccessTableRowData[];

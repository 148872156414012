import * as React from 'react';
import { isNumber, isDate } from 'lodash';
import { format } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { Cell, ICellProps } from './Cell';

const { useCallback, useMemo } = React;

const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';

interface IProps extends ICellProps {
  isUnix?: boolean;
  showDistance?: boolean;
  dateFormatStr?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const DateCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    isUnix,
    showDistance,
    dateFormatStr,
    config,
    value,
    ...restProps
  } = props;

  const dateValue = useMemo<Date>(() => {
    if (isDate(value)) {
      return value;
    }

    if (isNumber(value)) {
      const timestamp = isUnix ? value * 1000 : value;
      return new Date(timestamp);
    }

    return null;
  }, [value, isUnix]);

  const formatValue = useCallback((data: any) => {
    if (!data) {
      return '-';
    }

    return showDistance
      ? formatDistanceToNow(data)
      : format(data, dateFormatStr);
  }, [dateFormatStr, showDistance]);

  const cellConfig = useMemo(() => {
    const copy = { ...config };
    copy.formatValue = copy.formatValue || formatValue;
    return copy;
  }, [formatValue, config]);

  return <Cell {...restProps} config={cellConfig} value={dateValue} />;
});

DateCell.defaultProps = {
  isUnix: true,
  showDistance: false,
  dateFormatStr: DEFAULT_DATE_FORMAT,
};

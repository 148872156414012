import * as React from 'react';
import { isObject } from 'lodash';

type TFetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
interface IFetchResult<T> {
  status?: {
    code: number;
  };
  data?: T;
}

const { useState, useEffect, useRef } = React;

/**
 * Fetches the result from url.
 * Can only use it inside the body of a function component.
 *
 * @param {String} url the target url.
 * @param {TFetchMethod} method supported fetch method.
 * @param {Object} body POST body, if any.
 * @param {Boolean} skip Skip fetching data.
 *
 * @return [Boolean, T, Error]
 */
export function useFetch<T extends any>(params: {
  url: string;
  method?: TFetchMethod;
  body?: Record<string, any>;
  skip?: boolean;
}): [boolean, T, Error, () => void] {
  const {
    url,
    method = 'GET',
    body,
    skip = false,
  } = params;
  const [force, setForceUpdate] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState<Error>(null);
  const abortedRequest = useRef(false);
  const options = useRef<RequestInit>({
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (isObject(body)) {
    options.current.body = JSON.stringify(body);
  }

  useEffect(() => {
    const abortController = new AbortController();
    options.current.signal = abortController.signal;

    if (url && !skip) {
      setData(null);
      setFetching(true);
      fetch(url, options.current)
        .then((resp): Promise<IFetchResult<T>> => resp.json())
        .then((json) => {
          setFetching(false);
          if (json && json.status && json.status.code === 200) {
            const { data } = json;
            setData(data);
          } else {
            setError(new Error(`Unexpected response: ${json}`));
          }
        })
        .catch((err) => {
          setFetching(false);
          if (!abortedRequest.current) {
            setError(err);
          }
        });
    } else {
      setFetching(false);
    }

    return () => {
      abortedRequest.current = true;
      abortController.abort();
    };
  }, [force, url, skip]);

  const forceUpdate = () => {
    setForceUpdate((val) => val + 1);
  };

  return [fetching, data, error, forceUpdate];
}

import * as React from 'react';
import cx from 'classnames';
import {
  find,
  forEach,
  map,
  size,
} from 'lodash';

import { Button } from 'src/widgets/Button/Button';

import {
  ICardTabProps,
  CardTab,
} from './CardTab';

const {
  useEffect,
  useState,
} = React;
import styles from './Card.scss';
export const cardStyles = styles; // Parent stylesheet to be used by all cards

export interface ICardTabConfig {
  buttonLabel?: string;
  content: React.ReactNode;
  contentRef: React.RefObject<HTMLDivElement>;
  header?: ICardProps['header'];
  key: ICardTabProps['key'];
  onClick?: () => unknown;
  tabClassName?: string;
}

interface ICardProps {
  className?: string;
  currentTabRef?: ICardTabConfig['contentRef'];
  header?: React.ReactNode;
  tabConfigs?: readonly ICardTabConfig[];
}

export const Card: React.FC<ICardProps> = (props) => {
  const {
    children,
    className,
    currentTabRef,
    header,
    tabConfigs,
  } = props;

  const [activeTabRef, setActiveTabRef] = useState<ICardTabConfig['contentRef']>(null);

  // Cleanup
  useEffect(() => () => {
    setActiveTabRef(null);
  }, [])

  // Set current tab based on controlled currentTabRef
  useEffect(() => {
    if (currentTabRef) {
      setActiveTabRef(currentTabRef);
    } else if (size(tabConfigs) > 0) {
      setActiveTabRef(tabConfigs[0].contentRef);
    }
  }, [currentTabRef, tabConfigs]);

  // Show the active tab
  useEffect(() => {
    forEach(tabConfigs, (tab) => {
      if (tab && tab.contentRef && tab.contentRef.current) {
        tab.contentRef.current.classList.toggle(
          styles.visible,
          activeTabRef === tab.contentRef
        );
      }
    });
  }, [activeTabRef, tabConfigs]);

  // Render the tab buttons
  const renderTabs = () => size(tabConfigs) > 1 && (
    <div className={styles.tabs}>
      {map(tabConfigs, (tab, i) => (
        <Button
          key={`tab-${i}`}
          className={cx(styles.tabButton, {
            [styles.activeTab]: activeTabRef === tab.contentRef,
          })}
          label={tab.buttonLabel || tab.key}
          theme="light"
          onClick={() => setActiveTabRef(tab.contentRef)}
        />
      ))}
    </div>
  );

  // Render current tab contents if configs  exist
  const renderTabContents = () => {
    const tabConfig = find(tabConfigs, (tabConfig) => tabConfig.contentRef === activeTabRef);
    if (tabConfig) {
      return (
        <CardTab
          className={tabConfig.tabClassName}
          key={tabConfig.key}
          ref={tabConfig.contentRef}
        >
          {tabConfig.content}
        </CardTab>
      );
    }
  };

  // Will display current tab custom header, if available -- in that case the header prop is disregarded
  const renderHeaderTitle = () => {
    if (size(tabConfigs) > 0 && activeTabRef) {
      const activeTab = find(tabConfigs, (tab) => (
        tab.header && tab.contentRef === activeTabRef
      ));
      if (activeTab) {
        return typeof activeTab.header === 'string'
          ? <h4 className={styles.cardTitle}>{activeTab.header}</h4>
          : <>{activeTab.header}</>;
      }
    }
    return typeof header === 'string'
      ? <h4 className={styles.cardTitle}>{header}</h4>
      : <>{header}</>;
  };

  return (
    <section className={cx(className, styles.Card)}>
      <header className={styles.cardHeader}>
        {renderHeaderTitle()}
        {renderTabs()}
      </header>
      {renderTabContents()}
      {children}
    </section>
  );
};

import { ICreatorSearchFilters } from '../models';

export enum TextSearchActionTypes {
  UPDATE_SEARCH_FILTERS = '@connectTextSearch/UPDATE_SEARCH_FILTERS',
}

export interface ITextSearchAction {
  type: TextSearchActionTypes;
  payload?: {
    searchFilters?: ICreatorSearchFilters;
  };
}

export const updateSearchFilters = (newSearchFilters: ICreatorSearchFilters) => ({
  type: TextSearchActionTypes.UPDATE_SEARCH_FILTERS,
  payload: {
    searchFilters: newSearchFilters,
  },
});

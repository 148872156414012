import { assign, sortBy, uniqBy, filter } from 'lodash';
import { Middleware, Dispatch } from 'redux';

import { ActionTypes, IWatchListPage, IWatchListPageAction } from './models';

const processWatchLists: Middleware<unknown, IWatchListPage> = () => (
  next: Dispatch<IWatchListPageAction>,
) => (action: IWatchListPageAction) => {
  if (action.type === ActionTypes.SET_WATCH_LISTS) {
    const { watchlists } = action.payload;

    action.payload = assign(
      {},
      {
        ...action.payload,
        watchlists: filter(watchlists, (watchlist) => watchlist.active),
      },
    );
  }

  return next(action);
};

const processSocialAccounts: Middleware<unknown, IWatchListPage> = () => (
  next: Dispatch<IWatchListPageAction>,
) => (action: IWatchListPageAction) => {
  if (action.type === ActionTypes.SET_SOCIAL_ACCOUNTS) {
    const { socialAccounts } = action.payload;
    const sortedSocialAccounts = sortBy(uniqBy(socialAccounts, 'id'), (socialAccount) => {
      if (socialAccount.relevant_post) {
        return -socialAccount.relevant_post.created_ts;
      }

      return 0;
    });

    action.payload = assign(
      {},
      {
        ...action.payload,
        socialAccounts: sortedSocialAccounts,
      },
    );
  }

  return next(action);
};

export default [processWatchLists, processSocialAccounts];

import * as React from 'react';
import { isEmpty, isUndefined, chain } from 'lodash';


import { ITokenInputProps, TokenInput, TToken } from '../Input/TokenInput';
import { useGooglePlacesAutocomplete } from 'src/utils/hooks/useGooglePlacesAutocomplete';

const { useState } = React;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type TPlacesOptions = Omit<google.maps.places.AutocompletionRequest, 'input'>;

export type TPlace = TToken;

type TTokenInputProps = Pick<ITokenInputProps, 'maxOptionsToShow' | 'placeholder'>;

export interface IPlacesInputProps extends TTokenInputProps {
  className?: string;
  onChange(newPlaces: TPlace[]);

  places?: TPlace[];
  defaultPlaces?: TPlace[];
  debounceWait?: number;
  options: TPlacesOptions;
  formatPrediction(prediction: google.maps.places.AutocompletePrediction): TPlace;
  emptyOptionsMessage?: string;
}

const PlacesInput: React.FunctionComponent<IPlacesInputProps> = (props) => {
  const [getPlaces] = useGooglePlacesAutocomplete();
  const [placesState, setPlacesState] = useState(props.defaultPlaces);

  const isControlled = !isUndefined(props.places);

  const places = isControlled ? props.places : placesState;

  const getOptions = async (value: string) => {
    const predictions = await getPlaces({ ...props.options, input: value });
    return chain(predictions)
      .map(props.formatPrediction)
      .omitBy(isEmpty)
      .value();
  };

  const handleChange = (newTokens: TPlace[]): void => {
    props.onChange(newTokens);

    if (!isControlled) {
      setPlacesState(newTokens);
    }
  };

  return (
    <TokenInput
      className={props.className}
      tokens={places}
      placeholder={props.placeholder}
      debounceWait={props.debounceWait}
      onChange={handleChange}
      optionsForValue={getOptions}
      allowTokenCreation={false}
      emptyOptionsMessage={props.emptyOptionsMessage}
    />
  );
};

PlacesInput.defaultProps = {
  defaultPlaces: [],
  placeholder: 'Enter a location',
  debounceWait: 300,
};

export default PlacesInput;

import * as React from 'react';
import cx from 'classnames';
import { debounce, isString, isUndefined } from 'lodash';


import styles from './Textarea.scss';

interface IProps {
  className?: string;
  onChange(value: string);

  onPressEnter?();
  rows?: number;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  buffer?: number;
  autoFocus?: boolean;
  focusOnMount?: boolean;
  triggerChangeOnBlur?: boolean;
}

type TDefaultProp =
  | 'onPressEnter'
  | 'rows'
  | 'placeholder'
  | 'defaultValue'
  | 'buffer'
  | 'triggerChangeOnBlur'
  | 'autoFocus'
  | 'focusOnMount';

interface IState {
  value: string;
  focus: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Textarea extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    onPressEnter: () => undefined,
    placeholder: '',
    defaultValue: '',
    buffer: 0,
    rows: 3,
    autoFocus: false,
    focusOnMount: false,
    triggerChangeOnBlur: false,
  };

  private textAreaRef: React.RefObject<HTMLTextAreaElement>;
  private onChange: (value: string) => void;

  /**
   * @inheritdoc
   */
  public constructor(props: IProps) {
    super(props);

    this.textAreaRef = React.createRef();

    this.state = {
      value: isString(props.defaultValue) ? props.defaultValue : '',
      focus: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    this.onChange = this.props.buffer > 0 ? debounce((value: string) => {
      this.props.onChange(value);
    }, this.props.buffer) : (value) => {
      this.props.onChange(value);
    };

    window.addEventListener('keypress', this.handleKeyPress);
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeyPress);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { placeholder, className } = this.props;
    const value = this.getValue();
    const { focus } = this.state;

    return (
      <div className={cx(className, styles.Textarea, { [styles.focus]: focus })}>
        <textarea
          rows={this.props.rows}
          className={styles.textarea}
          ref={this.textAreaRef}
          value={value}
          onChange={this.handleChange}
          spellCheck={false}
          onBlur={this.handleInputBlur}
          onFocus={this.handleFocus}
          placeholder={placeholder}
          autoFocus={this.props.autoFocus || this.props.focusOnMount}
        />
      </div>
    );
  }

  /**
   * @public
   * Selects the input, used for copying to clipboard.
   */
  public select = () => {
    const input = this.textAreaRef.current;

    input.select();
  };

  /**
   * @private
   * Triggers a blur when pressing enter.
   */
  private handleKeyPress = (event) => {
    const { onPressEnter } = this.props;
    const { keyCode } = event;
    const input = this.textAreaRef.current;
    const { focus } = this.state;

    // do nothing if not focused
    if (!focus || !input) {
      return;
    }

    // trigger a blur
    if (keyCode === 13) {
      onPressEnter();

      input.blur();
    }
  };

  /**
   * @private
   * Returns the 'value' based on whether component is controlled or not.
   *
   * @return {String}
   */
  private getValue = () => {
    return this.controlled ? this.props.value : this.state.value;
  };

  /**
   * @private
   * Returns whether component is controlled or not.
   *
   * @return {Boolean}
   */
  private get controlled() {
    return !isUndefined(this.props.value);
  }

  /**
   * @private
   * Notifies the parent with new value.
   */
  private handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { triggerChangeOnBlur } = this.props;
    const { value } = event.target;

    this.setState({
      value,
    });

    if (!triggerChangeOnBlur) {
      this.onChange(value);
    }
  };

  /**
   * @private
   */
  private handleFocus = () => {
    this.setState({
      focus: true,
    });
  };

  /**
   * @private
   * Blurs the Textarea component when input is focused.
   */
  private handleInputBlur = () => {
    const { triggerChangeOnBlur } = this.props;
    const { value } = this.state;

    this.setState({
      focus: false,
    });

    if (triggerChangeOnBlur) {
      this.onChange(value);
    }
  };
}

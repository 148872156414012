import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bind, debounce, defer, flatMap, isEmpty, map } from 'lodash';

import { ICampaign } from 'src/common/models/campaign';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { IClientProposal } from 'src/common/models/clientProposal';
import {
  CreatorTile,
  ICreatorTileProps,
} from 'src/widgets/CreatorTile';
import { MasonryGrid } from 'src/widgets/MasonryGrid';
import { browserHasScrollbar } from 'src/utils/uiUtils';

import { IFavoriteListPage } from './redux/favoriteListPageModel';

import styles from './GridView.scss';

const defaultColumnWidth = 330;

interface IOwnProps {
  className?: string;
  apiEndpoint: string;
  campaign: ICampaign;

  onCreatorSelected(socialAccount: ISocialAccount);
  selectedProposalIndices: number[];
  proposals: IClientProposal[];

  sendOffer(accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  reportAsIncorrect(accountName: string);

  inviteToCampaign(socialAccount: ISocialAccount);
  addToFavoriteList(accountId: number, listId: number);
  createFavoriteList(name: string);
  allowFavorite: boolean;
  showCreateFeatures: boolean;
}
interface IStateProps {
  isQa: boolean;

  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}
type IProps = IOwnProps & IStateProps & {
  selfServeExperiment: boolean;
};

interface IState {
  columnWidth: number;
}

/**
 * @class
 * @extends {React.Component}
 */
class GridView extends React.PureComponent<IProps, IState> {
  private ref: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      columnWidth: null,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidMount() {
    window.addEventListener('resize', debounce(this.onWindowResize, 200), { passive: true });

    // trigger a window resize to calculate the columns
    defer(this.onWindowResize);
  }

  /**
   * @inheritDoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  private onWindowResize = () => {
    const node = this.ref.current;

    if (node) {
      // Consider browser's scrollbar width (usually 15px)
      const nodeWidth = node.offsetWidth - (browserHasScrollbar() ? 15 : 0);
      const minColumnWidth = Math.max(
        Math.floor(nodeWidth / Math.ceil(nodeWidth / defaultColumnWidth)),
        280,
      );
      const maxColumnWidth = Math.min(
        Math.floor(nodeWidth / Math.floor(nodeWidth / defaultColumnWidth)),
        380,
      );

      let columnWidth;
      // choose column width closer to default column width
      if (
        Math.abs(minColumnWidth - defaultColumnWidth) <=
        Math.abs(maxColumnWidth - defaultColumnWidth)
      ) {
        columnWidth = minColumnWidth;
      } else {
        columnWidth = maxColumnWidth;
      }

      this.setState({
        columnWidth,
      });
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      allowFavorite,
      apiEndpoint,
      campaign,
      isQa,
      socialAccounts,
      favoriteLists,
      proposals,
      onCreatorSelected,
      selectedProposalIndices,
      sendOffer,
      goToManage,
      reportAsIncorrect,
      inviteToCampaign,
      className,
      showCreateFeatures = true,
      selfServeExperiment
    } = this.props;
    const { columnWidth } = this.state;

    const reviews = flatMap(
      proposals
        .filter((proposal) => !isEmpty(proposal.reviews))
        .filter((_, index) => selectedProposalIndices.includes(index)),
      (proposal) => proposal.reviews,
    );
    const itemProps: ICreatorTileProps[] = map(socialAccounts, (socialAccount) => {
      const reviewsForAccount = reviews.filter(
        (review) => review.social_account_id === socialAccount.id,
      );

      return {
        key: socialAccount.id,
        isQa,
        allowFavorite,
        apiEndpoint,
        campaign,
        socialAccount,
        favoriteLists,
        showReview: true,
        reviews: reviewsForAccount,
        inviteToCampaign: bind(inviteToCampaign, this, socialAccount),
        sendOffer: bind(sendOffer, this, socialAccount.id),
        goToManage: bind(goToManage, this, socialAccount.relation_id),
        reportAsIncorrect: bind(reportAsIncorrect, this, socialAccount.name),
        onCreatorSelected: bind(onCreatorSelected, this, socialAccount),
        showCreateFeatures: showCreateFeatures,
        selfServeExperiment: selfServeExperiment
      };
    });

    return (
      <div className={cx(className, styles.GridView)} ref={this.ref}>
        {/* only mounts masonry grid after column width is calculated */}
        {columnWidth && (
          <MasonryGrid
            columnWidth={columnWidth}
            itemComponent={CreatorTile}
            itemProps={itemProps}
            defaultItemHeight={CreatorTile.defaultHeight}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: IFavoriteListPage,
  ownProps: IOwnProps,
): IStateProps & IOwnProps => {
  return {
    isQa: state.isQa,
    apiEndpoint: ownProps.apiEndpoint,
    campaign: ownProps.campaign,

    socialAccounts: state.socialAccounts,
    favoriteLists: state.favoriteLists,

    onCreatorSelected: ownProps.onCreatorSelected,
    selectedProposalIndices: ownProps.selectedProposalIndices,
    proposals: ownProps.proposals,

    sendOffer: ownProps.sendOffer,
    goToManage: ownProps.goToManage,
    reportAsIncorrect: ownProps.reportAsIncorrect,

    inviteToCampaign: ownProps.inviteToCampaign,
    addToFavoriteList: ownProps.addToFavoriteList,
    createFavoriteList: ownProps.createFavoriteList,
    allowFavorite: ownProps.allowFavorite,
    showCreateFeatures: ownProps.showCreateFeatures,

    className: ownProps.className,
  };
};

export default connect<IStateProps, unknown, IOwnProps>(
  mapStateToProps,
  {},
)(GridView);

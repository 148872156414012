import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const TagIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.29,13.17L10.24,2.37C10.13,2.26,9.98,2.2,9.82,2.2H2.6c-0.16,0-0.31,0.06-0.42,0.18S2,2.63,2,2.79
      l0.01,7.06c0,0.16,0.06,0.31,0.18,0.43l11.1,10.85c0.46,0.45,1.06,0.67,1.66,0.67c0.6,0,1.2-0.22,1.66-0.67l4.68-4.57c0,0,0,0,0,0
      c0.46-0.45,0.71-1.05,0.71-1.7C22,14.22,21.75,13.62,21.29,13.17z M20.45,15.71l-4.68,4.57c-0.45,0.44-1.19,0.44-1.64,0L3.2,9.6
      L3.2,3.39h6.38l10.87,10.63c0.23,0.22,0.35,0.52,0.35,0.84C20.8,15.18,20.68,15.48,20.45,15.71L20.45,15.71z"
    />
    <path
      d="M6.85,4.69c-1.27,0-2.3,1.01-2.3,2.26s1.03,2.26,2.3,2.26s2.3-1.01,2.3-2.26S8.12,4.69,6.85,4.69z
      M6.85,8.02c-0.61,0-1.1-0.48-1.1-1.07s0.5-1.07,1.1-1.07c0.61,0,1.1,0.48,1.1,1.07S7.46,8.02,6.85,8.02z"
    />
  </SvgIcon>
));

import * as React from 'react';
import cx from 'classnames';
import { map, findIndex } from 'lodash';

import { NetworkIcon } from 'src/common';
import { IRelation } from 'src/common/models/relation';
import { TPostType } from 'src/common/models/postType';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import productDescription from 'src/common/utils/productDescription';
import {
  AlertIcon,
  DollarIcon,
  KeyboardArrowLeftIcon,
  RoundAddCircleIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import { LazyImage } from 'src/widgets/Image';
import { Input } from 'src/widgets/Input';
import { Select } from 'src/widgets/Select';
import { Tooltip } from 'src/widgets/Tooltip';

import { IRelationState } from './MassTermsTypes';

import styles from './RelationRowItem.scss';
const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

function productDescriptionWithAccount(count: number, postType: TPostType, name: string) {
  const description = productDescription(count, postType);
  let descriptionName = null;
  if (
    !(postType === 'additional_images' || postType === 'additional_videos' || postType === 'other')
  ) {
    descriptionName = ' on @' + name;
  }

  return (
    <div className={styles.productDescription}>
      <div className={styles.productIcon}>
        <NetworkIcon identifier={getNetworkByPostType(postType)} size={14} />
      </div>
      <div className={styles.productName}>
        {description}
        {descriptionName}
      </div>
    </div>
  );
}

interface IProps {
  relationState: IRelationState;
  relation: IRelation;

  onRemove();
  onOfferChange(value: string);
  onSendTermsAnyway();
  onShowDetailOverlay(showDetailRelationId: string, showDetailAccountId: number);
  onSelectedAccountChange(relationId: string, deliverableIndex: number, selectedIndex: number);

  classNames?: string[];
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class RelationRowItem extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private alreadyCollaboratingRef: React.RefObject<HTMLDivElement>;
  // private disabledAccountRef: React.RefObject<HTMLDivElement>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.alreadyCollaboratingRef = React.createRef();
    // this.disabledAccountRef = React.createRef();
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { relation, relationState, onRemove, classNames } = this.props;

    return (
      <div
        className={cx(classNames.concat(styles.RelationRowItem), {
          [styles.gray]: relationState.state === 'no_accounts',
          [styles.pink]: relationState.state === 'already_collaborating',
        })}
        key={relation.id}
      >
        {relationState.state === 'already_collaborating' && (
          <React.Fragment>
            <div className={styles.alertIconWrapper} ref={this.alreadyCollaboratingRef}>
              <AlertIcon size={20} />
            </div>
            <Tooltip
              mountRef={this.alreadyCollaboratingRef}
              tooltipColor="black"
              maxWidth={440}
              className={styles.alertTooltip}
            >
              <div className={styles.header}>
                You&apos;re already collaborating with this creator on this campaign
              </div>
              It is fairly unusual to want to propose terms again to a creator that you are already
              working with. If you want to proceed, just click on the row or button below to dismiss
              this warning.
            </Tooltip>
          </React.Fragment>
        )}
        {relationState.state !== 'already_collaborating' && (
          <RoundAddCircleIcon className={styles.removeIcon} onClick={onRemove} size={20} />
        )}
        {this.renderProfileSelect()}
        {['force_new_project', 'selected_can_reply', 'selected'].includes(relationState.state) && this.renderRegular()}
        {relationState.state === 'no_accounts' && RelationRowItem.renderAccountsCantSatisfy()}
        {relationState.state === 'already_collaborating' && this.renderAlreadyCollaborating()}
      </div>
    );
  }

  private renderRegular() {
    const { relationState, onOfferChange } = this.props;

    // TODO: Get publishers full name for tooltip and hookup view profile link.
    return (
      <React.Fragment>
        <div className={styles.projectSelect}>
          {relationState.state === 'selected_can_reply'
            ? 'Reply to proposal'
            : 'Create new Project'}
        </div>
        <div className={styles.accountRows}>{this.renderAccountsAndOffers()}</div>
        <Input
          className={styles.offerInput}
          icon={<DollarIcon size={20} />}
          onChange={onOfferChange}
          value={relationState.offer === null ? '' : relationState.offer.toString()}
        />
      </React.Fragment>
    );
  }

  private static renderAccountsCantSatisfy() {
    return (
      <div className={styles.restContent}>
        This creator&apos;s accounts can&apos;t satisfy the requirements you&apos;ve requested.
      </div>
    );
  }

  private renderAlreadyCollaborating() {
    const { onRemove, onSendTermsAnyway } = this.props;

    return (
      <div className={styles.restContent}>
        <div className={styles.message}>
          You&apos;re already collaborating with this creator on this campaign.
        </div>
        <Button
          onClick={onSendTermsAnyway}
          label="Send Terms Anyway"
          className={styles.sendTermButton}
          theme="info"
        />
        <Button onClick={onRemove} label="Remove" className={styles.removeButton} />
      </div>
    );
  }

  private renderProfileSelect = () => {
    const { relation } = this.props;
    const accounts = relation.metadata && relation.metadata.accounts;
    const options = map(accounts, (account) => ({
      label: (
        <div className={styles.profileOption}>
          <div className={styles.icon}>
            <NetworkIcon identifier={account.network_identifier || 'instagram'} size={12} />
          </div>
          <div className={styles.info}>
            {/* Paul: also change test data, prioritize username maybe? */}
            <div className={styles.name}>
              {account.name || account.username || account.full_name}
            </div>
            <div className={styles.viewProfile}>View profile</div>
          </div>
          <KeyboardArrowLeftIcon className={styles.arrowRight} size={14} />
        </div>
      ),
      value: account.id,
    }));

    return (
      <Select
        options={options}
        onChange={this.handleSelectCreatorDetail.bind(this, relation.id)}
        customLabelElement={
          <div className={styles.profile}>
            <div className={styles.avatarWrapper}>
              <LazyImage
                className={styles.avatar}
                src={relation.publisher && relation.publisher.profile_picture}
                fallbackSrc={defaultAvatar}
              />
            </div>
          </div>
        }
      />
    );
  };

  private renderAccountsAndOffers = () => {
    const { relation, relationState, onSelectedAccountChange } = this.props;

    return map(relationState.deliverables, ({ accounts, count, post_type }, index) => {
      if (accounts.length === 0) {
        return (
          <div className={styles.accountRow} key={index}>
            <div className={cx([styles.accountSelect, styles.accountSelectDisabled])}>
              <div className={styles.productDescription}>
                <div className={styles.productIcon}>
                  <NetworkIcon identifier={getNetworkByPostType(post_type)} size={14} />
                </div>
                {productDescription(count, post_type)}
              </div>
            </div>
            {/* <Tooltip mountRef={this.disabledAccountRef} tooltipColor="black">
              This product is unavailable with this creator
            </Tooltip> */}
            <div className={styles.proposed}>-</div>
            <div className={styles.recommended}>-</div>
          </div>
        );
      }

      let accountSelector = null;
      let selectedAccount = null;
      let selectedIndex = 0;

      if (accounts.length > 1) {
        selectedIndex = findIndex(accounts, (account) => account.selected);
        selectedAccount = accounts[selectedIndex];
        const accountOptions = map(accounts, (account, index) => {
          return {
            label: productDescriptionWithAccount(count, post_type, account.name),
            value: index,
          };
        });
        accountSelector = (
          <Select
            options={accountOptions}
            theme="info"
            onChange={(value) => onSelectedAccountChange(relation.id, index, value)}
            selectedIndex={selectedIndex}
          />
        );
      } else {
        selectedAccount = accounts[0];
        accountSelector = productDescriptionWithAccount(count, post_type, selectedAccount.name);
      }

      return (
        <div className={styles.accountRow} key={index}>
          <div className={styles.accountSelect}>{accountSelector}</div>
          <div className={styles.proposed}>
            {selectedAccount.proposed ? '$' + selectedAccount.proposed : '-'}
          </div>
          <div className={styles.recommended}>
            {selectedAccount.recommended ? '$' + selectedAccount.recommended : '-'}
          </div>
        </div>
      );
    });
  };

  private handleSelectCreatorDetail = (relationId: string, selectedValue: number) => {
    const { onShowDetailOverlay } = this.props;
    onShowDetailOverlay(relationId, selectedValue);
  };
}

import * as React from 'react';
import qs from 'qs';
import { flattenDeep, map } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { IProgram } from 'src/common/models/program';
import { useGet } from 'src/utils/hooks/useGet';

import { useCommunities } from './useCommunities';
import { useInviteContext } from './useInviteContext';

const { useMemo } = React;

/**
 * Fetch programs
 */
export const usePrograms = () => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();
  const {
    isFetching: isFetchingCommunities,
    communities,
  } = useCommunities();

  const communityIdsParam = useMemo(() => {
    if (!isFetchingCommunities && communities) {
      return qs.stringify(
        { community_ids: map(communities, (c) => c.id) },
        { arrayFormat: 'brackets' },
      );
    }
  }, [communities, isFetchingCommunities]);
  const fetchProgramsURL = useMemo(() => {
    if (communityIdsParam) {
      return `${apiEndpoint}/${endpoints.communityProgramsEndpoint}?client_id=${clientId}&${communityIdsParam}`;
    }
  }, [apiEndpoint, clientId, communityIdsParam]);

  const {
    loading: isFetching,
    data: fetchedPrograms,
    error,
  } = useGet({ url: fetchProgramsURL });
  const programs: IProgram[] = useMemo(() => (
    flattenDeep(fetchedPrograms)
  ), [fetchedPrograms]);

  return {
    programs: Object.freeze(programs),
    isFetching,
    error,
  };
};

import qs from 'qs';
import { first, isEmpty } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { useGet } from 'src/utils/hooks/useGet';
import { ISocialAccount } from 'src/common/models/socialAccount';

interface IProps {
  apiEndpoint: string;
  id: ISocialAccount['id'];
  brandInstagramUsername?: string;
}

/**
 * Fetch detailed social account
 */
export const useDetailedSocialAccount = (props: IProps) => {
  const {
    apiEndpoint,
    id,
    brandInstagramUsername,
  } = props;

  const url = apiEndpoint && id
    ? (
      `${apiEndpoint}/${endpoints.socialAccountEndpoint}` +
      qs.stringify({
        social_account_id: id,
        brand_instagram_username: brandInstagramUsername || undefined,
      }, { addQueryPrefix: true })
    )
    : null;

  const {
    loading: isFetching,
    data,
    error,
  } = useGet<{ data: ISocialAccount[] }>({ url });
  const detailedSocialAccount = !isEmpty(data)
    ? Object.freeze(first(data.data))
    : null

  return {
    detailedSocialAccount,
    isFetching,
    error,
  };
};

import * as React from 'react';
import cx from 'classnames';
import { range, padStart, isEmpty } from 'lodash';

import { ClockIcon } from 'src/icons';
import { Select } from 'src/widgets/Select';

import styles from './TimePicker.scss';

export interface ITimeInput {
  hourIn24HourFormat: number;
  minuteIn24HourFormat: number;
}

interface IProps {
  onTimeSelected?(value: ITimeInput);

  hasError?: boolean;
  errorMessage?: string;

  classNames?: string[];
}
type TDefaultProp = 'onTimeSelected' | 'hasError' | 'errorMessage' | 'classNames';
type T12HourFormat = 'AM' | 'PM';

interface IState {
  selectedTime: string;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class TimePicker extends React.PureComponent<IProps, IState> {
  /**
   * @inheritDoc
   */
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    onTimeSelected: () => undefined,

    hasError: false,
    errorMessage: '',

    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedTime: '',
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { hasError, errorMessage, classNames } = this.props;
    const options = this.getTimeOptions();

    return (
      <div className={cx(classNames.concat(styles.TimePicker))}>
        <Select
          options={options}
          onChange={this.onSelectedTimeChange}
          customLabelElement={this.renderCustomLabel()}
        />
        {hasError && !isEmpty(errorMessage) && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    );
  }

  /**
   * @private
   * Create time options in a day.
   * Time format: 12-hour format.
   *
   * @return {Option[]} List of time options.
   */
  private getTimeOptions = () => {
    const halfDayTimeIntervals = range(1, 12).map((i) => padStart(i.toString(), 2, '0'));
    halfDayTimeIntervals.unshift('12');

    const timeIntervalsIn12HourFormat = [
      ...this.timeIntervalsIn12HourFormat(halfDayTimeIntervals, 'AM'),
      ...this.timeIntervalsIn12HourFormat(halfDayTimeIntervals, 'PM'),
    ];

    const options = timeIntervalsIn12HourFormat.map((timeValue) => ({
      label: <div>{timeValue}</div>,
      value: timeValue,
    }));

    return options;
  };

  /**
   * @private
   * Add 30 minute time interval.
   * Add the 12-hour format.
   *
   * @param {String[]} timeIntervals Array of time interval.
   * @param {T12HourFormat} timeofday Time format.
   *
   * @return {String[]} Time intervals with format appended.
   */
  private timeIntervalsIn12HourFormat = (timeIntervals: string[], timeofday: T12HourFormat) => {
    const timeIntervalsIn12HourFormat = [];
    timeIntervals.forEach((timeInterval) => {
      timeIntervalsIn12HourFormat.push(
        `${timeInterval}:00 ${timeofday}`,
        `${timeInterval}:30 ${timeofday}`,
      );
    });

    return timeIntervalsIn12HourFormat;
  };

  /**
   * @private
   * Custom label for the dropdown.
   *
   * @return {JSX.Element} Input element.
   */
  private renderCustomLabel = () => {
    const { selectedTime } = this.state;
    const { hasError } = this.props;

    return (
      <div
        className={cx(styles.timeInput, {
          [styles.timeSelected]: selectedTime !== '',
          [styles.hasError]: hasError,
        })}
      >
        <ClockIcon size={16} className={styles.icon} />
        {selectedTime ? selectedTime : 'Enter Time...'}
      </div>
    );
  };

  /**
   * @private
   * Get hour and minutes in 24 hour format.
   *
   * @param {String} time Time in 12-hour format.
   */
  private getFullDateTime = (timeIn12HourFormat) => {
    const matched = timeIn12HourFormat.match(/(1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm])/);

    let hour = parseInt(matched[1], 10);
    const minute = parseInt(matched[2], 10);
    const tz = matched[3];

    if (tz === 'AM' && hour === 12) {
      hour = 0;
    } else if (tz === 'PM' && hour !== 12) {
      hour = hour + 12;
    }

    return {
      hour,
      minute,
    };
  };

  /**
   * @private
   * Select a time option.
   *
   * @param {String} selectedTime the selected time.
   */
  private onSelectedTimeChange = (selectedTime: string) => {
    const { onTimeSelected } = this.props;

    this.setState({
      selectedTime,
    });

    const { hour, minute } = this.getFullDateTime(selectedTime);

    onTimeSelected({ hourIn24HourFormat: hour, minuteIn24HourFormat: minute });
  };
}

import * as React from 'react';

import { IBarChartProps } from './interfaces';

import { BarChartHorizontal } from './BarChartHorizontal';
import { BarChartVertical } from './BarChartVertical';

export type TBarChartAlignment = 'vertical' | 'horizontal';
interface IProps extends IBarChartProps {
  alignment?: TBarChartAlignment;
}

/**
 * @type {React.FunctionComponent}
 */
export const BarChart: React.FunctionComponent<IProps> = React.memo(props => {
  const { alignment, ...restProps } = props;
  const BarChartComponent =
    alignment === 'horizontal' ? BarChartHorizontal : BarChartVertical;

  return <BarChartComponent height={250} width={500} {...restProps} />;
});

BarChart.defaultProps = {
  alignment: 'horizontal',
  showRawValue: true,
  showPercentage: true,
};

import * as React from 'react';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';

import { UploadIcon, CheckListIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { LoadSpinner } from 'src/widgets/LoadSpinner';

import endpoints from 'src/common/config/endpoints';
import {
  getOrderedRequirements,
  getContentRequirementToContentReviewMap,
} from './utils/contentReviewUtils';
import { ContentReviewGroup } from './ContentReviewGroup';

import { IPostProject } from 'src/common/models/postProject';
import { IContentRequirement } from 'src/common/models/contentRequirement';
import { IContentReview } from 'src/common/models/contentReview';
import { useMobileStatus } from 'src/utils/hooks';

const { useState, useEffect } = React;

import styles from './ContentReviewListPage.scss';

interface IProps {
  apiEndpoint: string;
  projectId: string;
  className?: string;
  isManager: boolean;

  // actions
  onSelectContentReview(contentReviewId: number);
  goToGroupContentReview();

  uploadExtraContent?();
}

type TContentRequirementObjects = {
  [id: string]: IContentRequirement[];
};

type TContentReviewObjects = {
  [id: number]: IContentReview;
};

const fetchProjectData = async (apiEndpoint: string, projectId: string): Promise<IPostProject> => {
  const resp = await fetch(`${apiEndpoint}/${endpoints.projectEndpoint}/${projectId}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    credentials: 'include',
  });

  const json = await resp.json();

  if (json.status && json.status.code === 200) {
    return json.data;
  } else {
    throw new Error();
  }
};

/**
 * @type {React.FunctionComponent}
 */
export const ContentReviewListPage: React.FunctionComponent<IProps> = (props) => {
  const {
    apiEndpoint,
    projectId,
    className,
    onSelectContentReview,
    goToGroupContentReview,
    uploadExtraContent,
    isManager,
  } = props;

  const [dueDateToRequirements, setDueDateToRequirements] = useState<TContentRequirementObjects>(
    {},
  );
  const [requirementToReviewMap, setRequirementToReviewMap] = useState<TContentReviewObjects>({});
  const [brandName, setBrandName] = useState('');
  const [isFetchingProjectData, setIsFetchingProjectData] = useState(true);

  useEffect(() => {
    if (!projectId) {
      throw new Error('No projectId given.');
    }
    fetchProjectData(apiEndpoint, projectId)
      .then((projectData) => {
        setDueDateToRequirements(getOrderedRequirements(projectData));
        setRequirementToReviewMap(getContentRequirementToContentReviewMap(projectData));
        setBrandName(projectData.brand_name);
        setIsFetchingProjectData(false);
      })
      .catch(() => {
        setIsFetchingProjectData(false);
      });
  }, [projectId, apiEndpoint]);

  const hasProjectRequirements = !isEmpty(dueDateToRequirements);

  if (!isFetchingProjectData && !hasProjectRequirements) {
    throw new Error(`Unable to fetch data for project: ${projectId}`);
  }

  const mobileType = useMobileStatus();

  return (
    <div className={cx(styles.ContentReviewListPage, className)}>
      {isFetchingProjectData && <LoadSpinner />}
      {!isFetchingProjectData && !mobileType && (
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>
            <CheckListIcon size={32} className={styles.checkListIcon} />
            <div className={styles.headerInfo}>
              {isManager ? 'Content Review' : 'Upload Content'}
            </div>
          </div>
          {!isManager && (
            <div className={styles.uploadExtraContent}>
              <Button
                className={styles.uploadExtraContentButton}
                icon={<UploadIcon size={16} />}
                theme="info"
                label="Upload Extra Content"
                onClick={uploadExtraContent}
              />
            </div>
          )}
        </div>
      )}
      {!isFetchingProjectData && (
        <div className={styles.mainContent}>
          {hasProjectRequirements && (
            <div className={styles.requirementsList}>
              {map(dueDateToRequirements, (requirements, dueDate) => {
                return (
                  <ContentReviewGroup
                    apiEndpoint={apiEndpoint}
                    projectId={projectId}
                    key={dueDate}
                    requirements={requirements}
                    brandName={brandName}
                    groupingDate={dueDate}
                    requirementToReviewMap={requirementToReviewMap}
                    onSelectContentReview={onSelectContentReview}
                    goToGroupContentReview={goToGroupContentReview}
                    isManager={isManager}
                  />
                );
              })}
            </div>
          )}
          {mobileType && !isManager && (
            <div className={styles.uploadExtraContent}>
              <h2 className={styles.header}>Upload Extra Content</h2>
              <div className={styles.uploadExtraContentText}>
                Have even more content that you think might be relevant for the brand? Upload it
                here and the brand might give you exposure by using it in their own marketing.
              </div>
              <Button
                className={styles.uploadExtraContentButton}
                icon={<UploadIcon size={16} />}
                theme="info"
                label="Upload Extra Content"
                onClick={uploadExtraContent}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import cx from 'classnames';

import { Cell, ICellProps } from './Cell';
import { useLinkableCell } from './hooks/useLinkableCell';

import styles from './LinkCell.scss';

interface IProps extends ICellProps {
  href?: string;
  handler?(): void;
  openInNewTab?: boolean;
  useAnchor?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const LinkCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const { href, handler, openInNewTab, useAnchor, value, ...restProps } = props;
  const { cellValue, onClick } = useLinkableCell({ href, handler, useAnchor, openInNewTab, value });
  const className = cx(styles.LinkCell, {
    [styles.useAnchor]: useAnchor
  });
  return (
    <Cell className={className} onClick={onClick} value={cellValue} {...restProps} />
  );
});

LinkCell.defaultProps = {
  href: null,
  handler: () => undefined,
  openInNewTab: true, 
  useAnchor: false,
};

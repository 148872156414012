import * as React from 'react';
import cx from 'classnames';
import { isBoolean } from 'lodash';

import { CheckboxIcon, CheckboxIndeterminateIcon } from 'src/icons';

const { useState, useCallback } = React;
import styles from './Checkbox.scss';

export interface ICheckboxProps {
  className?: string;
  label?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?(checked: boolean): void;
  onMouseDown?(): void;
  onClick?(): void;
}

export const Checkbox: React.FC<ICheckboxProps> = React.memo((props) => {
  const {
    className,
    disabled,
    indeterminate,
    label,
    onChange,
  } = props;
  const [hover, setHover] = useState(false);
  const [checkedState, setCheckedState] = useState(false);

  /**
   * Returns the 'checked' state based on whether component is controlled or not.
   *
   * @return {Boolean}
   */
  const checked = (() => {
    return isBoolean(props.checked) ? props.checked : checkedState;
  })();

  /**
   * Handler for when the component receives 'mouseenter' event.
   */
  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  /**
   * Handler for when the component receives 'mouseleave' event.
   */
  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  /**
   * Handles mouse down event.
   */
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    props.onMouseDown();
  };

  /**
   * Toggles the checked state.
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // prevent ghost clicks
    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    setCheckedState(!checked);
    props.onClick();
    onChange(!checked);
  };

  return (
    <div
      className={cx(styles.Checkbox, className, {
        [styles.checked]: checked,
        [styles.hover]: hover,
        [styles.noLabel]: !label,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.box}>
        {indeterminate
          ? <CheckboxIndeterminateIcon size={28} className={styles.checkbox} />
          : <CheckboxIcon size={28} className={styles.checkbox} />}
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
});

Checkbox.defaultProps = {
  label: '',
  indeterminate: false,
  onChange: () => undefined,
  onMouseDown: () => undefined,
  onClick: () => undefined,
};

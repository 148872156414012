import * as React from 'react';
import { map, filter, zip, forOwn, find, sumBy, partition, entries } from 'lodash';

import {
  ArrowLeftIcon,
  HelpIcon,
} from 'src/icons';
import { IconButton } from 'src/widgets/Button';
import { CreatorDetailOverlay } from 'src/widgets/CreatorDetailOverlay';
import { TooltipIcon } from 'src/widgets/Icon';
import { Select } from 'src/widgets/Select';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';
import { Notice } from 'src/widgets/Notice';
import addEventLog from 'src/common/utils/addEventLog';
import { MassTermsSummary } from './MassTermsSummary';
import { RelationRowItem } from './RelationRowItem';

import { INetworkInfo, IProductTypeInfo, IRelationState } from './MassTermsTypes';
import { IAgreementIteration } from 'src/common/models/agreementIteration';
import { ICampaign } from '../../common/models/campaign';
import { IRelation } from 'src/common/models/relation';

import endpoints from 'src/common/config/endpoints';

import styles from './MassTermsReview.scss';
import { TRelationStates, TSendingWill } from './MassTermsTypes';

interface IProps {
  apiEndpoint: string;

  onComplete: (projectCount: number) => void;
  relations: IRelation[];
  campaign: ICampaign;
  agreementIteration: IAgreementIteration;
  networkInfo: INetworkInfo[];
  productTypeInfo: IProductTypeInfo[];
  createMassTermsProjects: (selectedRelationStates: IRelationState[]) => any;
  onBack: () => void;
}
type TPricingOption = 'proposed' | 'recommended';
interface IState {
  selectedOption: TPricingOption;

  relationStates: IRelationState[];
  sendingTerms: boolean;
  showDetailOverlay: boolean;
  showDetailRelationId: string;
  selectedSocialAccountId: number | null;
}

/**
 * @class
 * @extends {React.Component}
 */
export class MassTermsReview extends React.Component<IProps, IState> {
  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();

    this.state = {
      selectedOption: 'proposed',

      relationStates: MassTermsReview.getRelationStatesFromProps(this.props),
      sendingTerms: false,
      showDetailOverlay: false,
      selectedSocialAccountId: null,
      showDetailRelationId: null,
    };
  }

  /**
   * Get the state of this relation from details on the project metadata of the relation. This depends on project
   * metadata having 'campaign_id' and 'has_proposal' set, if they aren't bailout and derive the relation state from the
   * campaign stages.
   *
   * @param relation
   * @param campaignId
   */
  private static getStateFromHasProposal(
    relation: IRelation,
    campaignId: number,
  ): [TRelationStates, TSendingWill, number] {
    const existingProjects = [];
    for (const [projectId, metadata] of entries(relation.project_metadata)) {
      // bailout if project metadata doesn't have the campaign_id.
      if (metadata.campaign_id === undefined) {
        return null;
      }
      if (metadata.campaign_id === campaignId) {
        existingProjects.push([projectId, metadata]);
      }
    }

    if (existingProjects.length === 1) {
      const [projectId, existingProjectMetadata] = existingProjects[0];
      // has_proposal actually means 'has_sent_terms'
      // bailout if project metadata doesn't have has_proposal set.
      if (existingProjectMetadata.has_proposal === undefined) {
        return null;
      }
      if (existingProjectMetadata.has_proposal === true) {
        return ['already_collaborating', 'new_project', null];
      } else {
        return ['selected_can_reply', 'reply', projectId];
      }
    } else if (existingProjects.length > 1) {
      return ['force_new_project', 'new_project', null];
    }

    return null;
  }

  /**
   * Get the state of a relation from the campaign stages.
   *
   * @param relation
   * @param campaignId
   */
  private static getStateFromCampaignStages(
    relation: IRelation,
    campaignId: number,
  ): [TRelationStates, TSendingWill, number] {
    let state: TRelationStates;
    let sendingWill: TSendingWill = 'new_project';
    let existingProjectId: number = null;
    const stagesForCampaign = filter(relation.stages, (stage) => stage.campaign_id === campaignId);

    switch (stagesForCampaign.length) {
      case 0:
        state = 'selected';
        break;
      case 1:
        const stage = stagesForCampaign[0];
        if (stage.stage_identifier === 'new_proposal') {
          state = 'selected_can_reply';
          sendingWill = 'reply';
          existingProjectId = stage.project_id;
        } else if (stage.stage_identifier !== 'complete') {
          state = 'already_collaborating';
        }
        break;
      default:
        // It's hard to figure out what to do in this case - so default to forcing a new project.
        state = 'force_new_project';
        break;
    }
    return [state, sendingWill, existingProjectId];
  }

  /**
   * @inheritDoc
   */
  public static getRelationStatesFromProps(props: IProps): IRelationState[] {
    const { relations, agreementIteration, campaign } = props;
    const stateRelations = [];
    let errorCount = 0;
    for (const relation of relations) {
      let stateDetails = MassTermsReview.getStateFromHasProposal(relation, campaign.id);
      if (stateDetails === null) {
        stateDetails = MassTermsReview.getStateFromCampaignStages(relation, campaign.id);
      }

      let state = stateDetails[0];
      const sendingWill = stateDetails[1];
      const existingProjectId = stateDetails[2];

      if (state === 'already_collaborating') {
        errorCount += 1;
      }

      const stagesForCampaign = filter(
        relation.stages,
        (stage) => stage.campaign_id === campaign.id,
      );

      const deliverables = [];
      let allDisabled = true;
      for (const deliverable of agreementIteration.products) {
        // Handle post types that don't correspond to a network separately, add a fake "account" to simplify the logic
        // later on.
        const product = find(
          props.productTypeInfo,
          (productTypeInfo) => productTypeInfo.identifier === deliverable.post_type,
        );
        if (product.network_identifier === '') {
          deliverables.push({
            accounts: [
              {
                id: null,
                selected: true,
                name: relation.publisher.username,
                proposed: null,
                recommended: null,
                reach: 0,
                engagement: 0,
              },
            ],
            post_type: deliverable.post_type,
            count: deliverable.count,
          });
          allDisabled = false;
          continue;
        }

        // if the creator has multiple accounts then choose the one with the greatest reach.
        let maxFollowers = 0;
        let maxAccount = null;
        const stateAccounts = [];
        for (const account of relation.metadata.accounts) {
          if (deliverable.account.network_identifier === account.network_identifier) {
            allDisabled = false;

            let recommended = null;
            forOwn(account.recommended_prices, (price, postType) => {
              if (deliverable.post_type.toLowerCase() === postType) {
                recommended = price;
              }
            });

            let proposed = null;
            if (stagesForCampaign.length === 1) {
              const stage = stagesForCampaign[0];
              const projectMetadata = relation.project_metadata[stage.project_id];
              if (projectMetadata && projectMetadata.post_bundles !== undefined) {
                for (const postBundle of projectMetadata.post_bundles) {
                  if (postBundle.account_id === account.id) {
                    proposed = parseFloat(postBundle.price);
                    break;
                  }
                }
              }
            }

            const stateAccount = {
              id: account.id,
              selected: false,
              name: account.name,
              proposed: proposed,
              recommended: recommended,
              reach: account.reach,
              engagement: account.engagement,
            };
            stateAccounts.push(stateAccount);
            if (account.reach > maxFollowers) {
              maxFollowers = account.reach;
              maxAccount = stateAccount;
            }
          }
        }

        if (!maxAccount) {
          maxAccount = stateAccounts[0];
        }
        if (maxAccount) {
          maxAccount.selected = true;
        }

        deliverables.push({
          accounts: stateAccounts,
          post_type: deliverable.post_type,
          count: deliverable.count,
        });
      }

      // Disable the row entirely if no accounts match.
      if (allDisabled) {
        state = 'no_accounts';
        errorCount += 1;
      }

      const defaultOffer = campaign.offers_payment ? null : 0;

      stateRelations.push({
        id: relation.id,
        publisher_id: relation.publisher.id,
        existing_project_id: existingProjectId,
        state: state,
        sendingWill: sendingWill,
        deliverables: deliverables,
        offer: defaultOffer,
      });
    }

    addEventLog('bulk_send_terms_review', {
      count: stateRelations.length,
      error_count: errorCount,
    });
    return stateRelations;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { sendingTerms, selectedOption, relationStates: relationStates } = this.state;
    const { relations } = this.props;

    const totalSelectedCount = filter(relationStates, (relation) => {
      return (
        relation.state === 'selected' ||
        relation.state === 'selected_can_reply' ||
        relation.state === 'force_new_project' ||
        relation.state === 'already_collaborating'
      );
    }).length;
    const erroredCount = filter(
      relationStates,
      (relation) => relation.state === 'already_collaborating',
    ).length;

    const combinedRelations = zip(relations, relationStates);

    const applyToAllOptions = [
      {
        label: (
          <React.Fragment>
            <div
              style={{
                marginLeft: '10px',
              }}
            >
              Use proposed for all
            </div>
          </React.Fragment>
        ),
        value: 'proposed',
      },
      {
        label: (
          <React.Fragment>
            <div
              style={{
                marginLeft: '10px',
              }}
            >
              Use recommended for all
            </div>
          </React.Fragment>
        ),
        value: 'recommended',
      },
    ];
    const selectedPricingOptionIndex = applyToAllOptions.findIndex(
      (option) => option.value === selectedOption,
    );

    return (
      <div className={styles.MassTermsReview}>
        <IconButton
          icon={<ArrowLeftIcon size={26} />}
          className={styles.arrowBack}
          onClick={this.props.onBack}
        />
        <div className={styles.header}>
          <div className={styles.title}>Review details, select deliverables & set your offers</div>
        </div>
        {erroredCount !== 0 && (
          <Notice type="error" className={styles.errorMessage}>
            Resolve {erroredCount} error found out of {totalSelectedCount}
            <span className={styles.creators}>creator{totalSelectedCount !== 1 ? 's ' : ''}</span>
            you selected
          </Notice>
        )}
        <div className={styles.detailsAndSummary}>
          <div className={styles.details}>
            <div className={styles.countAndApplyAll}>
              <div className={styles.selectedCount}>
                {totalSelectedCount} creator{totalSelectedCount !== 1 ? 's' : ''} selected
              </div>
              <div className={styles.applyAll}>
                <div className={styles.text}>Apply to all pricing:</div>
                <Select
                  options={applyToAllOptions}
                  selectedIndex={selectedPricingOptionIndex}
                  hintText="Select..."
                  theme="info"
                  onChange={this.handleApplyToAll}
                />
              </div>
            </div>
            <div className={styles.rowHeader}>
              <div className={styles.action}>Action</div>
              <div className={styles.products}>Deliverables</div>
              <div className={styles.other}>
                Proposed
                <TooltipIcon
                  icon={<HelpIcon size={18} />}
                  tooltipText="The price the creator submitted for the corresponding deliverable"
                  className={styles.helpIcon}
                />
              </div>
              <div className={styles.other}>
                Recommended
                <TooltipIcon
                  icon={<HelpIcon size={18} />}
                  tooltipText="AspireIQ recommends this price based on data collected from hundreds of thousands of proposals, taking into account factors including creator influence and content quality"
                  className={styles.helpIcon}
                />
              </div>
              <div className={styles.other}>Your Offer</div>
            </div>
            {map(combinedRelations, ([relation, relationState], index) => {
              if (relationState.state === 'not_selected') {
                return null;
              }

              return (
                <RelationRowItem
                  key={index}
                  relation={relation}
                  relationState={relationState}
                  onRemove={this.handleRemove.bind(this, relation.id)}
                  onOfferChange={this.handleOfferChange.bind(this, relation.id)}
                  onSendTermsAnyway={this.handleSendTermsAnyway.bind(this, relation.id)}
                  onShowDetailOverlay={this.handleShowDetailOverlay}
                  onSelectedAccountChange={this.handleAccountSelectedChange}
                />
              );
            })}
          </div>
          <MassTermsSummary
            classNames={[styles.summary]}
            relationStates={relationStates}
            handleSend={this.handleSend}
            sendingTerms={sendingTerms}
            networkInfo={this.props.networkInfo}
            productTypeInfo={this.props.productTypeInfo}
          />
        </div>
        <Toast ref={this.toastRef} />
        {this.renderDetailOverlay()}
      </div>
    );
  }

  private handleSendTermsAnyway = (relationId: string) => {
    this.setStateOfRelation(relationId, { state: 'selected' });
  };

  private handleRemove = (relationId: string) => {
    this.setStateOfRelation(relationId, { state: 'not_selected' });
  };

  private handleAccountSelectedChange = (relationId, deliverableIndex, selectedIndex) => {
    const { relationStates } = this.state;

    this.setState({
      relationStates: map(relationStates, (relationState) => {
        if (relationState.id === relationId) {
          const updatedDeliverables = map(relationState.deliverables, (deliverable, index) => {
            if (index === deliverableIndex) {
              const accounts = map(deliverable.accounts, (account, index) => {
                return {
                  ...account,
                  selected: index === selectedIndex,
                };
              });
              return { ...deliverable, accounts: accounts };
            } else {
              return deliverable;
            }
          });

          return { ...relationState, deliverables: updatedDeliverables };
        } else {
          return relationState;
        }
      }),
    });
  };

  private handleOfferChange = (relationId, value) => {
    if (value.match(/^\d+$/)) {
      this.setStateOfRelation(relationId, { offer: parseInt(value, 10) });
    } else if (value === '') {
      this.setStateOfRelation(relationId, { offer: null });
    }
  };

  private handleApplyToAll = (selectedOption) => {
    const { relationStates } = this.state;

    this.setState({
      selectedOption,
      relationStates: map(relationStates, (relationState) => {
        if (
          relationState.state === 'not_selected' ||
          relationState.state === 'no_accounts' ||
          relationState.state === 'already_collaborating'
        ) {
          return relationState;
        }
        let offer = null;
        for (const deliverable of relationState.deliverables) {
          for (const account of deliverable.accounts) {
            if (account.selected) {
              const proposedOrRecommended =
                selectedOption === 'proposed' ? account.proposed : account.recommended;

              if (proposedOrRecommended) {
                // Only set the offer if there is a value.
                if (offer === null) {
                  offer = 0;
                }

                offer += proposedOrRecommended;
              }
            }
          }
        }

        // If there is no offer, don't overwrite what the user has selected.
        return { ...relationState, offer: offer || relationState.offer };
      }),
    });
  };

  private handleSend = () => {
    const { apiEndpoint } = this.props;
    const { relationStates } = this.state;
    const toast = this.toastRef.current;
    this.setState({ sendingTerms: true });

    const selectedRelationStates = filter(
      relationStates,
      (relation) =>
        relation.state === 'selected' ||
        relation.state === 'force_new_project' ||
        relation.state === 'selected_can_reply',
    );

    // TODO: bring this logic into react.
    const projectParams = this.props.createMassTermsProjects(selectedRelationStates);

    new Promise(async (resolve, reject) => {
      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.postProjectEndpoint}`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ projects: projectParams }),
        });

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          resolve();
        } else {
          const text = await resp.text();
          reject(
            new Error(
              `Creating projects from mass terms failed status: ${resp.status} body: ${text}`,
            ),
          );
        }
      } catch (err) {
        reject(err);
      }
    })
      .then(() => {
        this.setState({ sendingTerms: false });
        const [replies, newProjects] = partition(
          selectedRelationStates,
          (s) => s.state === 'selected_can_reply',
        );

        let recommendedTotal = 0.0;
        let proposedTotal = 0.0;
        for (const selectedState of selectedRelationStates) {
          let selectedRecommended = 0.0;
          let selectedProposed = 0.0;
          for (const deliverable of selectedState.deliverables) {
            for (const account of deliverable.accounts) {
              if (account.selected) {
                if (account.recommended !== null) {
                  selectedRecommended += account.recommended;
                }
                if (account.proposed !== null) {
                  selectedProposed += account.proposed;
                }
                break;
              }
            }
          }

          if (selectedRecommended === selectedState.offer) {
            recommendedTotal += selectedState.offer;
          } else if (selectedProposed === selectedState.offer) {
            proposedTotal += selectedState.offer;
          }
        }

        addEventLog('bulk_send_terms_success', {
          count: selectedRelationStates.length,
          price: sumBy(selectedRelationStates, (s) => s.offer),
          recommended_price: recommendedTotal,
          proposed_price: proposedTotal,
          count_new_projects: newProjects.length,
          count_reply_proposal: replies.length,
        });
        this.props.onComplete(projectParams.length);
      })
      .catch((error) => {
        this.setState({ sendingTerms: false });
        console.log(error);
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to create projects.',
            type: 'error',
          });
        }
      });
  };

  private handleShowDetailOverlay = (
    showDetailRelationId: string,
    selectedSocialAccountId: number,
  ) => {
    this.setState({ showDetailOverlay: true, selectedSocialAccountId, showDetailRelationId });
  };

  private handleCloseDetailOverlay = () => {
    this.setState({ showDetailOverlay: false });
  };

  private renderDetailOverlay = () => {
    const { apiEndpoint, campaign, relations } = this.props;
    const { showDetailOverlay, selectedSocialAccountId, showDetailRelationId } = this.state;

    const relation = find(relations, (state) => state.id === showDetailRelationId);
    const account = find(
      relation && relation.metadata.accounts,
      (account) => account.id === selectedSocialAccountId,
    );

    return (
      <CreatorDetailOverlay
        allowFavorite={false}
        campaign={campaign}
        apiEndpoint={apiEndpoint}
        loadDetail={true}
        loadRelated={false}
        show={showDetailOverlay}
        socialAccount={account}
        onRequestClose={this.handleCloseDetailOverlay}
      />
    );
  };

  /**
   * For a given relation, update it's state with the contents of newRelationState and update component state with the
   * modified relation.
   *
   * @param relationId id of the relation to update
   * @param newRelationState partial state that we use to modify the existing relation.
   */
  private setStateOfRelation(relationId: string, newRelationState: Partial<IRelationState>) {
    const { relationStates } = this.state;

    this.setState({
      relationStates: map(relationStates, (relationState) => {
        if (relationState.id === relationId) {
          return {
            ...relationState,
            ...newRelationState,
          };
        } else {
          return relationState;
        }
      }),
    });
  }
}

import * as React from 'react';
import cx from 'classnames';
import {
  isBoolean,
  isEmpty,
  get,
} from 'lodash';

import { Content } from './Content/Content';
import { Header } from './Header/Header';
import { useDetailedSocialAccount } from './hooks/useDetailedSocialAccount';
import { useSocialProfileContext } from './hooks/useSocialProfileContext';
import { ISocialProfileOverlayProps } from './SocialProfileOverlay';
import { hasEnoughData } from './utils';

const { useEffect } = React;
import styles from './SocialProfile.scss';

type TProps = Pick<ISocialProfileOverlayProps, 'socialAccount' | 'brandInstagramUsername'>;

export const SocialProfile: React.FC<TProps> = (props) => {
  const { 
    socialAccount: socialAccountProp,
    brandInstagramUsername,
  } = props;
  const id = get(socialAccountProp, 'id', null);

  const {
    apiEndpoint,
    loadAccountDetails,
    network,
    reset,
    setDetailedSocialAccount,
    setFetchingDetailForId,
    setSocialAccount,
    show,
    showToastMessage,
    socialAccount,
  } = useSocialProfileContext();
  const {
    isFetching,
    detailedSocialAccount,
    error,
  } = useDetailedSocialAccount({
    apiEndpoint,
    id: loadAccountDetails ? id : null,
    brandInstagramUsername,
  });

  // Reset on hidden state
  useEffect(() => {
    if (isBoolean(show) && !show) {
      reset();
    }
  }, [show]);

  // Update fetching id
  useEffect(() => {
    setFetchingDetailForId(isFetching && !hasEnoughData(socialAccount) ? id : null);
  }, [id, isFetching]);

  // Trigger toast on fetch error
  useEffect(() => {
    if (error) {
      showToastMessage({
        type: 'error',
        content: 'Unable to retrieve social account details',
      });
    }
  }, [error]);

  // Update social account with detailedSocialAccount
  // Get raw 'id' value for a more reliable hook dependency check, instead of checking the whole object
  const detailedSocialAccountId = !isEmpty(detailedSocialAccount) ? detailedSocialAccount.id : null;
  useEffect(() => {
    if (detailedSocialAccountId) {
      setDetailedSocialAccount(detailedSocialAccount);
    }
  }, [detailedSocialAccountId]);

  // Set new socialAccount on prop update
  const username = socialAccountProp?.username;
  useEffect(() => {
    if (username) {
      setSocialAccount(socialAccountProp);
    }
  }, [username]);

  return (
    <div className={cx(styles.SocialProfile, styles[network])}>
      <Header />
      <Content />
    </div>
  );
};

import * as React from 'react';
import { concat } from 'lodash';

import { IntroModal, IIntroModal, IStepConfig } from 'src/widgets/IntroModal';
import { LazyImage } from 'src/widgets/Image';
import failedImage from 'src/common/utils/failedImage';

import styles from './BrandedIntroModal.scss';

const ASSETS = process.env.ASSETS;
const welcomeImage = `${ASSETS}/creator_import_intro_modal_welcome.png`;
const chatImage = `${ASSETS}/creator_import_intro_modal_chat.png`;

const defaultBrandLogo = `${ASSETS}/default_avatar.png`;

const { useMemo } = React;

interface IProps extends IIntroModal {
  brandName: string;
  brandLogoSrc?: string;
  publisherName: string;
}

const BrandedWelcomeBanner: React.FunctionComponent<{ logoSrc?: string }> = (props) => {
  const logoFailed = failedImage.contains(props.logoSrc);

  const handleImageError = () => {
    failedImage.add(props.logoSrc);
  };

  return (
    <div className={styles.welcome}>
      <img src={welcomeImage} className={styles.bkg} />
      <LazyImage
        className={styles.logo}
        src={logoFailed ? null : props.logoSrc}
        fallbackSrc={defaultBrandLogo}
        onError={handleImageError}
        fadeIn={false}
      />
    </div>
  );
};

const BrandedChatBanner: React.FunctionComponent<{ logoSrc?: string }> = (props) => {
  const logoFailed = failedImage.contains(props.logoSrc);

  const handleImageError = () => {
    failedImage.add(props.logoSrc);
  };

  return (
    <div className={styles.chat}>
      <img src={chatImage} className={styles.bkg} />
      <LazyImage
        className={styles.logo}
        src={logoFailed ? null : props.logoSrc}
        fallbackSrc={defaultBrandLogo}
        onError={handleImageError}
        fadeIn={false}
      />
    </div>
  );
};

const BrandedIntroModal: React.FunctionComponent<IProps> = (props) => {
  const {
    brandLogoSrc,
    brandName,
    classNames,
    publisherName,
  } = props;

  const stepConfigs: IStepConfig[] = useMemo(() => [
    {
      title: `${publisherName}, welcome to AspireIQ!`,
      subtitle: `Get ready to collaborate directly with ${brandName} and other brands on AspireIQ`,
      banner: <BrandedWelcomeBanner logoSrc={brandLogoSrc} />,
    },
    {
      title: `Chat directly with ${brandName}`,
      subtitle: `To continue your collaboration with ${brandName}, \
        send a message directly to them through our chat tool`,
      banner: <BrandedChatBanner logoSrc={brandLogoSrc} />,
    },
  ], [publisherName, brandName, brandLogoSrc]);

  return (
    <IntroModal
      {...props}
      classNames={concat(classNames, styles.BrandedIntroModal)}
      steps={stepConfigs}
    />
  );
};

BrandedIntroModal.defaultProps = {
  classNames: [],
};

export default BrandedIntroModal;

export default function(values: string[]) {
  const names = values || [];

  switch (names.length) {
    case 0: {
      return '';
    }
    case 1: {
      return names[0];
    }
    case 2: {
      return `${names[0]} and ${names[1]}`;
    }
    case 3: {
      return `${names[0]}, ${names[1]} and ${names[2]}`;
    }
    default: {
      return `${names[0]}, ${names[1]} and ${names.length - 2} others`;
    }
  }
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const CheckCircleIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10S17.5,22,12,22z M10.2,16.1l0.8,0.8l0.6-0.9
      l5.2-6.9c0.3-0.4,0.2-1-0.2-1.3c-0.4-0.3-1-0.2-1.3,0.2L10.8,14l-2.4-2.5c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3L10.2,16.1z
      "
    />
  </SvgIcon>
));

export { CheckCircleIcon };

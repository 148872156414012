import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const AmbassadorIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d='M8 6C9.62028 5.9997 11.216 6.39589 12.648 7.154L16 3.728V0H0V3.728L3.352 7.154C4.78398 6.39589 6.37972 5.9997 8 6Z'
      fill='#3996E0'
    />
    <path
      d='M8 24C12.4183 24 16 20.4183 16 16C16 11.5817 12.4183 8 8 8C3.58172 8 0 11.5817 0 16C0 20.4183 3.58172 24 8 24Z'
      fill='url(#medal_gradient)'
    />
    <defs>
      <linearGradient
        id='medal_gradient'
        x1='-0.0999986'
        y1='24'
        x2='16.1'
        y2='8.1'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#996515'/>
        <stop offset='1' stopColor='#EACD60'/>
      </linearGradient>
    </defs>
  </SvgIcon>
));

import * as React from 'react';
import cx from 'classnames';
import { map, pull } from 'lodash';

import {
  CloseIcon,
  EditIcon,
} from 'src/icons';
import {
  Button,
  SubmitButton,
} from 'src/widgets/Button';
import { ContentPreviewer } from 'src/widgets/ContentPreviewer';
import { ConfirmationModal } from 'src/widgets/Modal';
import {
  Toast,
  IToastRefHandles,
} from 'src/widgets/Toast';

import { IPublisher } from 'src/common/models/publisher';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IPostProject } from 'src/common/models/postProject';
import { IContentReview } from 'src/common/models/contentReview';
import endpoints from 'src/common/config/endpoints';

import { SelectStoryModal } from './SelectStoryModal';

const { useCallback, useRef, useState } = React;
import styles from './InstagramStoryUploadPage.scss';

interface IProps {
  apiEndpoint: string;
  publisher: IPublisher;
  project: IPostProject;
  contentReview: IContentReview;
  onSubmitStories(storyIds: string[]): Promise<void>;
  onContactSupport();

  classNames?: string[];
}

/**
 * Fetches the updated social account.
 */
const updateSocialAccountInternal = async (
  apiEndpoint: string,
  accountId: number,
): Promise<ISocialAccount> => {
  try {
    const resp = await fetch(`${apiEndpoint}/${endpoints.socialAccountEndpoint}/${accountId}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        update_requested: true,
      }),
    });

    const json = await resp.json();
    if (json.status && json.status.code === 200) {
      const newSocialAccount = json.data;

      return newSocialAccount;
    } else {
      console.log(`Unexpected response:`, json);

      throw new Error(json.data && json.data.message);
    }
  } catch (err) {
    console.log(err);

    throw err;
  }
};

/**
 * @type {React.FunctionComponent}
 */
export const InstagramStoryUploadPage: React.FunctionComponent<IProps> = (props) => {
  const {
    publisher,
    project,
    contentReview,
    apiEndpoint,
    onSubmitStories,
    onContactSupport,
    classNames,
  } = props;

  const toastRef = useRef<IToastRefHandles>(null);

  // for SelectStoryModal
  const [showModal, setShowModal] = useState(false);
  // for clear selection confirmation
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // used for updating social account (stories)
  const [socialAccount, setSocialAccount] = useState<ISocialAccount>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  // selected stories
  const [selectedStoryIds, setSelectedStoryIds] = useState<string[]>([]);
  const [previousSelectedIds, setPreviousSelectedIds] = useState<string[]>([]);
  // used for final submission
  const [isSubmitting, setIsSubmittng] = useState(false);

  const showSelectStoryModal = useCallback(() => {
    setPreviousSelectedIds([...selectedStoryIds]);
    setShowModal(true);
  }, [selectedStoryIds]);

  const hideSelectStoryModal = useCallback((shouldRestore: boolean) => {
    if (shouldRestore) {
      setSelectedStoryIds([...previousSelectedIds]);
    }
    setShowModal(false);
  }, [previousSelectedIds]);

  // publisher should have an Instagram account.
  const accountId = contentReview && contentReview.product.account_id;
  if (!accountId) {
    console.error(`Invalid content review ${contentReview && contentReview.id}`);

    return null;
  }
  const initialAccount = publisher.social_accounts.find((account) => {
    return account.id === accountId;
    // enable this later to display notice
    // return account.id === accountId && account.has_analytics_api_access;
  });
  if (!initialAccount) {
    console.error(`Specified publisher ${publisher.id} does NOT have the account ${accountId}.`);

    return null;
  }

  const onClearSelection = () => {
    hideConfModal();
    setSelectedStoryIds([]);
  };
  const showConfModal = () => setShowConfirmationModal(true);
  const hideConfModal = () => setShowConfirmationModal(false);

  /**
   * Updates the social account. (stories)
   */
  const updateSocialAccount = async () => {
    setSelectedStoryIds([]);
    setPreviousSelectedIds([]);

    try {
      setIsUpdating(true);
      const updatedAccount = await updateSocialAccountInternal(apiEndpoint, initialAccount.id);
      setSocialAccount(updatedAccount);
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);

      const toast = toastRef.current;
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to update the stories.',
          type: 'error',
        });
      }
    }
  };

  const toggleStorySelected = (storyId: string) => {
    selectedStoryIds.includes(storyId)
      ? pull(selectedStoryIds, storyId)
      : selectedStoryIds.push(storyId);

    setSelectedStoryIds([...selectedStoryIds]);
  };

  /**
   * Submits selected stories.
   */
  const submitSelectedStories = async () => {
    const toast = toastRef.current;

    try {
      setIsSubmittng(true);
      await onSubmitStories(selectedStoryIds);
      setSelectedStoryIds([]);
      setIsSubmittng(false);

      toast.showMessage({
        content: (
          <div>
            Successfully submitted <span>{selectedStoryIds.length}</span> stories.
          </div>
        ),
      });
    } catch (err) {
      setIsSubmittng(false);
      console.log('err', err);

      toast.showMessage({
        content: 'There was an error when trying to submit the stories.',
        type: 'error',
      });
    }
  };

  const selectedStories =
    selectedStoryIds.length > 0
      ? (socialAccount || initialAccount).stories.filter((story) => (
        selectedStoryIds.includes(story.media_id)
      ))
      : [];
  const contents = map(selectedStories, (story) => ({
    type: story.media_type,
    src: story.media_type === 'video' ? story.video : story.image,
    text: story.caption || story.text,
  }));

  return (
    <div className={cx(classNames.concat(styles.InstagramStoryUploadPage))}>
      <div className={styles.helpTitle}>{selectedStoryIds.length} Instagram stories selected</div>
      <div className={styles.helpText}>
        Select stories below and send them to {project && project.brand_name}. If you don&apos;t see your
        story below,
        <span className={styles.contactSupport} onClick={onContactSupport}>
          {' '}
          please let us know
        </span>
        .
      </div>
      {selectedStories.length > 0 && (
        <>
          <ContentPreviewer
            contents={contents}
            enableDetailView={true}
            allowDownload={false}
            showContentInfo={false}
          />
          <div className={styles.actions}>
            <Button
              theme="info"
              label="Modify Selection"
              icon={<EditIcon size={16} />}
              onClick={showSelectStoryModal}
              className={cx(styles.button, styles.modifyButton)}
            />
            <Button
              theme="info"
              label="Clear Selection"
              icon={<CloseIcon size={16} />}
              onClick={showConfModal}
              className={styles.button}
            />
          </div>
        </>
      )}
      {selectedStoryIds.length > 0 && (
        <SubmitButton
          label={`Send ${selectedStoryIds.length} stories to ${project && project.brand_name}`}
          className={styles.submitButton}
          onClick={submitSelectedStories}
          isSubmitting={isSubmitting}
          submittingLabel="Sending..."
          disabled={isSubmitting}
        />
      )}
      {selectedStoryIds.length === 0 && (
        <Button
          label="Select Stories..."
          className={styles.selectButton}
          onClick={showSelectStoryModal}
        />
      )}
      <SelectStoryModal
        socialAccount={socialAccount || initialAccount}
        project={project}
        onRequestUpdate={updateSocialAccount}
        isUpdating={isUpdating}
        selectedStoryIds={selectedStoryIds}
        previousSelectedIds={previousSelectedIds}
        toggleStorySelected={toggleStorySelected}
        show={showModal}
        onRequestClose={hideSelectStoryModal}
        onContactSupport={onContactSupport}
      />
      <Toast ref={toastRef} />
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={onClearSelection}
        onRequestClose={hideConfModal}
        title="Clear story selection"
        confirmLabel="Clear Selection"
        theme="danger"
      />
    </div>
  );
};

InstagramStoryUploadPage.defaultProps = {
  classNames: [],
};

import * as React from 'react';
import { DragDropContext, Droppable, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { find, findIndex, isFunction, isNumber, map } from 'lodash';
import uuidv4 from 'uuid/v4';
import update from 'immutability-helper';

import { Checkbox } from 'src/widgets/Checkbox';

import { HeaderCell } from './Cell/HeaderCell';
import { ReorderableCell } from './Cell/ReorderableCell';
import { ResizableCell } from './Cell/ResizableCell';
import { TableContext, IRowData, IColumnSortDir, ITableColumnConfig } from './tableContext';
import { SortedDataList, TSortType } from './utils/SortedDataList';

const { useContext, useImperativeHandle, useMemo, useRef } = React;
import styles from './HeaderRow.scss';

interface IProps {
  cellContainerRef: React.MutableRefObject<HTMLDivElement>;
  checkboxRef: React.MutableRefObject<HTMLDivElement>;
  colSortDirs: IColumnSortDir;
  onColumnReorder?: (
    orderedColumns: ITableColumnConfig[],
    selectedColumn: ITableColumnConfig,
    destinationIndex: number,
  ) => void;
  onColumnResize?: (columnWidths: number[]) => void;
  onSortDirChange: (columnField: string, sortDir: TSortType) => void;
  scrollLeft: number;
  selectedIds: string[];
  sortedDataList: SortedDataList<IRowData>;
  toggleAllSelected: () => void;
}

export const HeaderRow = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    cellContainerRef,
    checkboxRef,
    colSortDirs,
    onColumnReorder,
    onColumnResize,
    onSortDirChange,
    scrollLeft,
    selectedIds,
    sortedDataList,
    toggleAllSelected,
  } = props;
  const {
    columnWidths,
    config,
    orderedColumns,
    setColumnOrder,
    showColumns,
  } = useContext(TableContext);

  const headerRowRef = useRef<HTMLDivElement>();
  useImperativeHandle(ref, () => headerRowRef.current);

  // Used to set this row's height and ResizableCells' heights
  const height = (
    config.headerHeight ||
    config.rowHeight ||
    headerRowRef.current?.getBoundingClientRect().height
  );

  /**
   * Outputs 4 possible scenarios
   *     resizable  reorderable
   *  1️⃣     ❌          ❌
   *  2️⃣     ✅          ❌
   *  3️⃣     ❌          ✅
   *  4️⃣     ✅          ✅
   */
  const renderHeaderCells = () => map(orderedColumns, (column, index) => {
    if (!showColumns[column.field]) {
      return null;
    }

    const isResizable = config.resizableColumns && !column.lockWidth;
    const isReorderable = config.reorderableColumns && !column.lockPosition;

    const headerCell = (
      <HeaderCell
        key={column.field}
        value={column.headerName}
        config={column}
        sortDir={colSortDirs[column.field]}
        onSortChange={(sortDir) => onSortDirChange(column.field, sortDir)}
      />
    );
    if (!isReorderable && !isResizable) {
      return headerCell; // 1️⃣ vanilla header cell
    }

    const resizableCell = (
      <ResizableCell
        key={column.field}
        config={column}
        height={height}
        isHeaderCell={true}
        onResizeStop={handleColumnResize}
      >
        {headerCell}
      </ResizableCell>
    );
    if (!isReorderable) {
      return resizableCell; // 2️⃣ resizable but not reorderable
    }

    return (
      <ReorderableCell
        key={column.field}
        config={column}
        index={isReorderable ? index : undefined}
        xOffset={cellContainerRef.current?.getBoundingClientRect().left}
      >
        {isResizable
          ? resizableCell /* 4️⃣ resizable AND reorderable */
          : headerCell /* 3️⃣ reorderable only */}
      </ReorderableCell>
    );
  });

  /**
   * Callback when dragging ends
   */
  const handleDragEnd = (result: DropResult) => {
    if (isFunction(onColumnReorder) && result.destination) {
      onColumnReorder(
        orderedColumns,
        find(orderedColumns, (c) => c.field === result.draggableId),
        result.destination.index,
      );
    }
  };

  /**
   * Callback when resizing ends
   */
  const handleColumnResize = () => {
    if (isFunction(onColumnResize)) {
      onColumnResize(columnWidths);
    }
  };

  /**
   * Update column orders
   */
  const handleDragUpdate = (initial: DragUpdate) => {
    if (!initial.destination) {
      return;
    }
    const draggedColumn = find(orderedColumns, (c) => c.field === initial.draggableId);
    setColumnOrder(
      update(orderedColumns, {
        $splice: [
          [findIndex(orderedColumns, (c) => c.field === initial.draggableId), 1],
          [initial.destination.index, 0, draggedColumn],
        ],
      }),
    );
  };

  const droppableId = useMemo(() => `droppable-${uuidv4()}`, []);
  return (
    <div
      className={styles.HeaderRow}
      ref={headerRowRef}
      style={{
        height: isNumber(height) ? `${height}px` : undefined,
      }}
    >
      {config?.selectable && (
        <div ref={checkboxRef} className={styles.checkboxWrapper}>
          <Checkbox
            checked={selectedIds.length > 0}
            indeterminate={selectedIds.length !== sortedDataList.getSize()}
            onClick={toggleAllSelected}
          />
        </div>
      )}
      <div
        ref={cellContainerRef}
        className={styles.cellContainer}
        style={{ transform: `translateX(-${scrollLeft}px)` }}
      >
        {config.reorderableColumns
          ? (
            <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
              <Droppable droppableId={droppableId} direction='horizontal'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className={styles.droppableContainer}
                    {...provided.droppableProps}
                  >
                    {renderHeaderCells()}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
          : renderHeaderCells()}
      </div>
    </div>
  );
});

import * as React from 'react';
import cx from 'classnames';
import { map, isNil } from 'lodash';

import { RecentlyLikedIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Popover } from 'src/widgets/Popover';
import { TokenInput, TToken } from 'src/widgets/Input';
import { Toggle } from 'src/widgets/Checkbox';
import countries from 'src/common/config/countries';
import { DragAndDropImageInput } from './DragAndDropImageInput';
import { ImageSelect } from './ImageSelect';
import { FilterSection } from '../FilterSection';
import { IImageSearch } from '../models';

import styles from './ImageSearch.scss';

const { useRef, useState, useEffect } = React;

export interface IImageSearchProps extends IImageSearch {
  countryChoices?: TToken[];
  hideCountryFilter?: boolean;

  onUploadImage(imageFile: File);
  onRemoveImage();
  onChangeCountries(countries: TToken[]);
  onChangeHideInvited(hideInvited: boolean);
  onFetchContentLists();

  className?: string;
  uploadFolder: string;
  setUploadedImageUrl(url: string);
  setUploadedImageError(error: Error);
  setUploadedImageLoading();
}

const DEFAULT_COUNTRY_CHOICES = [...map(countries, (label, code) => ({ label, value: code }))];

const ImageSearchCore: React.FunctionComponent<IImageSearchProps> = (props) => {
  const {
    className,
    contentLists,
    countries,
    countryChoices,
    hasContentListError,
    hideCountryFilter,
    hideInvited,
    hideInvitedToggle,
    isLoadingContentLists,
    onChangeCountries,
    onChangeHideInvited,
    onFetchContentLists,
    onRemoveImage,
    onUploadImage,
    clientId,
    selfServeExperiment
  } = props;

  const {
    isLoadingImage,
    imageURL
  } = props;

  const buttonRef = useRef();
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    onFetchContentLists();
  }, [onFetchContentLists]);

  useEffect(() => {
    if (showPopover && hasContentListError) {
      // Try again when re-opening the image select popover.
      onFetchContentLists();
    }
  }, [showPopover, hasContentListError, onFetchContentLists]);

  const handleSelectImage = (imageURL: string) => {
    if (imageURL) {
      setShowPopover(false);
    }
  };

  const handleTogglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <div className={cx(styles.ImageSearch, className)}>
      <DragAndDropImageInput
        imageSrc={imageURL}
        isLoading={isLoadingImage}
        onSelectImage={onUploadImage}
        onRemoveImage={onRemoveImage}
      />
      { isNil(clientId) &&
        <>
          <div className={styles.divider}>
            <span>or</span>
          </div>
          <Button
            ref={buttonRef}
            icon={<RecentlyLikedIcon size={21} />}
            fullWidth={true}
            theme="info"
            label="Select Liked or Uploaded Images"
            onClick={handleTogglePopover}
          />
        </>
      }
      <FilterSection header="Countries" hide={hideCountryFilter}>
        <TokenInput
          tokens={countries}
          placeholder="Enter a country"
          onChange={onChangeCountries}
          emptyOptionsMessage="Start typing a country name"
          options={countryChoices}
          hideOptionsOnEmptyInput={true}
        />
      </FilterSection>
      {!selfServeExperiment &&
        <FilterSection header="Hide Invited Creators" hide={hideInvitedToggle}>
          <Toggle checked={hideInvited} onChange={onChangeHideInvited} />
        </FilterSection>
      }
      <Popover
        mountRef={buttonRef}
        show={showPopover}
        anchorOrigin="middle"
        arrowPosition="middle"
        placement="right"
        onRequestClose={handleTogglePopover}
        contentClassName={styles.popoverContent}
      >
        <ImageSelect
          contentLists={contentLists}
          isLoading={isLoadingContentLists}
          hasError={hasContentListError}
          onSelectImage={handleSelectImage}
        />
      </Popover>
    </div>
  );
};

// On AX we want to use the object storage service for storing the image to search for, rather than using a handler in
// backend_server. This component replaces the redux onUploadImage function with useImageSearchUpload, which is passed
// in from the aspirex code and uses useUploadContent underneath.
const ImageSearchUploadContentServiceWrapper: React.FunctionComponent<IImageSearchProps> = (props) => {
  const {
    useImageSearchUpload,
    setUploadedImageError,
    setUploadedImageUrl,
    setUploadedImageLoading,
    clientId
  } = props;

  const onUploadImage = useImageSearchUpload(
    clientId,
    setUploadedImageUrl,
    setUploadedImageLoading,
    setUploadedImageError
  );

  return (<ImageSearchCore {...props} onUploadImage={onUploadImage} />);
};

export const ImageSearch: React.FunctionComponent<IImageSearchProps> = React.memo((props) => {
  const {
    useImageSearchUpload,
  } = props;

  if (useImageSearchUpload) {
    return (<ImageSearchUploadContentServiceWrapper {...props} />);
  } else {
    return (<ImageSearchCore {...props} />);
  }
});

ImageSearch.defaultProps = {
  countryChoices: DEFAULT_COUNTRY_CHOICES,
  hideInvited: true,
  isLoadingImage: false,
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ShareIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 20">
    <g transform="translate(-8.000000, -5.000000)">
      <g transform="translate(8.000000, 5.000000)">
        <g>
          <path d="M10.8695652,20 L2.17391304,20 C0.973043478,20 0,19.0269565 0,17.826087 L0,10 C0,8.79956522 0.973043478,7.82608696 2.17391304,7.82608696 L3.91304348,7.82608696 C4.15347826,7.82608696 4.34782609,8.02086957 4.34782609,8.26086957 C4.34782609,8.50086957 4.15347826,8.69565217 3.91304348,8.69565217 L2.17391304,8.69565217 C1.45391304,8.69565217 0.869565217,9.28 0.869565217,10 L0.869565217,17.826087 C0.869565217,18.5465217 1.45391304,19.1304348 2.17391304,19.1304348 L10.8695652,19.1304348 C11.59,19.1304348 12.173913,18.5465217 12.173913,17.826087 L12.173913,10 C12.173913,9.28 11.59,8.69565217 10.8695652,8.69565217 L9.13043478,8.69565217 C8.89,8.69565217 8.69565217,8.50086957 8.69565217,8.26086957 C8.69565217,8.02086957 8.89,7.82608696 9.13043478,7.82608696 L10.8695652,7.82608696 C12.0704348,7.82608696 13.0434783,8.79956522 13.0434783,10 L13.0434783,17.826087 C13.0434783,19.0269565 12.0704348,20 10.8695652,20 Z M9.71043478,4.22391304 L6.95652174,1.47 L6.95652174,12.6086957 C6.95652174,12.8491304 6.76217391,13.0434783 6.52173913,13.0434783 C6.28130435,13.0434783 6.08695652,12.8491304 6.08695652,12.6086957 L6.08695652,1.47 L3.33304348,4.22391304 C3.1673913,4.38956522 2.89869565,4.38956522 2.7326087,4.22391304 C2.56695652,4.05826087 2.56695652,3.78913043 2.7326087,3.62347826 L6.16956522,0.186521739 C6.18347826,0.166086957 6.1926087,0.142608696 6.21086957,0.123913043 C6.29565217,0.0395652174 6.40695652,-0.000869565217 6.5173913,0.000869565217 C6.51913043,0.000869565217 6.52,0 6.52173913,0 C6.52347826,0 6.52478261,0.000869565217 6.52608696,0.000869565217 C6.6373913,-0.000434782609 6.74826087,0.0395652174 6.8326087,0.123913043 C6.85043478,0.142173913 6.85869565,0.164782609 6.87217391,0.184782609 L10.3108696,3.62347826 C10.4765217,3.78913043 10.4765217,4.05826087 10.3108696,4.22391304 C10.1447826,4.38956522 9.87608696,4.38956522 9.71043478,4.22391304 Z"></path>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export { ShareIcon };

import * as React from 'react';
import { filter, pick, map, size, orderBy } from 'lodash';

import {
  BlogIcon,
  InstagramIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from 'src/icons';
import { IconSelect } from 'src/widgets/Select';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { FilterSection } from '../FilterSection';
import { INetworkFilters, INetworkOptions } from '../models';
import HasSponsoredPostsSection from './HasSponsoredPostsSection';

const { useMemo } = React;
import styles from './NetworkSection.scss';

const NetworkTypes: { [key: string]: TNetworkIdentifier } = Object.freeze({
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  BLOG: 'blog',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  TIKTOK: 'tiktok',
});

const pickFilters = (obj: any) => pick(obj, ['hasSponsoredPosts', 'channel']);

const iconForChannel = (channel: TNetworkIdentifier) => {
  switch (channel) {
    case NetworkTypes.INSTAGRAM:
      return <InstagramIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.YOUTUBE:
      return <YoutubeIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.BLOG:
      return <BlogIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.TWITTER:
      return <TwitterIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.PINTEREST:
      return <PinterestIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.TIKTOK:
      return <TiktokIcon size={16} className={styles.networkIcon} />;
    default:
      return null;
  }
};

const rankForChannel = (channel: TNetworkIdentifier) => {
  switch (channel) {
    case NetworkTypes.INSTAGRAM:
      return 0;
    case NetworkTypes.YOUTUBE:
      return 1;
    case NetworkTypes.BLOG:
      return 2;
    case NetworkTypes.TWITTER:
      return 3;
    case NetworkTypes.PINTEREST:
      return 4;
    case NetworkTypes.TIKTOK:
      return 5;
    default:
      return null;
  }
};

interface IProps extends INetworkFilters, INetworkOptions {
  onChange(networkFilters: INetworkFilters);
}

export const NetworkSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    channel,
    channelOptions,
    hasSponsoredPosts,
    hide,
    hideChannels,
    hideHasSponsoredPosts,
    isExpanded,
    onChange,
  } = props;

  const getChannelOptions = useMemo(() => (
    orderBy(
      map(
        filter(channelOptions, (channel) => channel !== 'twitter'), // DT-1092: Temporarily hide Twitter
        (channel: TNetworkIdentifier) => ({
          icon: iconForChannel(channel),
          value: channel,
          rank: rankForChannel(channel)
        })
      ), 'rank', 'asc')
  ), [channelOptions]);

  const handleChangeSponsoredPosts = (hasSponsoredPosts: boolean) => {
    onChange({
      ...pickFilters(props),
      hasSponsoredPosts,
    });
  };

  const handleChangeChannel = (selectedOption: TNetworkIdentifier) => {
    onChange({
      ...pickFilters(props),
      channel: selectedOption,
    });
  };

  const hideChannelsFilterSection = hideChannels || size(getChannelOptions) < 2;
  const hideFilterSection = hide || (hideChannels && hideHasSponsoredPosts);

  return (
    <FilterSection
      header="Network"
      hide={hideFilterSection}
      collapsible={true}
      defaultIsOpen={isExpanded}
      classNames={[styles.NetworkSection]}
    >
      <FilterSection header="Channels" isSubsection={true} hide={hideChannelsFilterSection}>
        <IconSelect
          onChange={handleChangeChannel}
          selectedOption={channel}
          options={getChannelOptions}
          defaultSelectedOption={channelOptions[0]}
        />
      </FilterSection>

      <HasSponsoredPostsSection
        isOn={hasSponsoredPosts}
        onChange={handleChangeSponsoredPosts}
        hide={hideHasSponsoredPosts}
      />
    </FilterSection>
  );
});

NetworkSection.defaultProps = {
  channel: NetworkTypes.INSTAGRAM,
  hasSponsoredPosts: false,
  channelOptions: [NetworkTypes.INSTAGRAM],
};

import { ThunkAction } from 'redux-thunk';
import Bluebird, { CancellationError } from 'bluebird';
import { reduce, isNil, isArray, isEmpty, isFunction, isString } from 'lodash';
import endpoints from 'src/common/config/endpoints';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { ISearchParams } from 'src/common/models/searchParams';
import addEventLog from 'src/common/utils/addEventLog';
import { DEFAULT_GENERIC_ERROR_MSG } from 'src/common/utils/getErrorMessageFromResponse';

import {searchAPIParamsSelector, imageSearchAPIParamsSelector, defaultImageSearchAPIParamsSelector} from './selectors';
import { IConnectSearchPage } from '../models';

export enum SearchResultsActionTypes {
  RESET_SEARCH_RESULTS = '@connectSearch/RESET_SEARCH_RESULTS',

  FETCH_CREATORS_REQUEST = '@connectSearch/FETCH_CREATORS_REQUEST',
  FETCH_CREATORS_SUCCESS = '@connectSearch/FETCH_CREATORS_SUCCESS',
  FETCH_CREATORS_FAILURE = '@connectSearch/FETCH_CREATORS_FAILURE',

  FETCH_FEATURED_CREATORS = '@connectSearch/FETCH_FEATURED_CREATORS',

  FETCH_NEXT_PAGE = '@connectSearch/FETCH_NEXT_PAGE',
  FETCH_PAGE = '@connectSearch/FETCH_PAGE',

  SELECT_TEXT_SEARCH = '@connectSearch/SELECT_TEXT_SEARCH',
  SELECT_IMAGE_SEARCH = '@connectSearch/SELECT_IMAGE_SEARCH',

  APPLY_SEARCH_FILTERS = '@connectSearch/APPLY_SEARCH_FILTERS',
}

export interface ISearchResultsAction {
  type: SearchResultsActionTypes;
  payload?: {
    socialAccounts?: ISocialAccount[];
    page?: number;
    hasNext?: boolean;
    count?: number;
    appendResults?: boolean;
    isImageSearch?: boolean;
    isFeaturedSearch?: boolean;
    promise?: Bluebird<any>;
  };
  meta?: {
    error?: Error;
    errorMessage?: string;
  };
}

type SRThunkAction = ThunkAction<void, IConnectSearchPage, unknown, ISearchResultsAction>;

export const fetchFeaturedCreators = () => ({
  type: SearchResultsActionTypes.FETCH_FEATURED_CREATORS,
});

export const applySearchFilters = () => ({
  type: SearchResultsActionTypes.APPLY_SEARCH_FILTERS,
});

export const fetchPage = (page: number) => ({
  type: SearchResultsActionTypes.FETCH_PAGE,
  payload: {
    page,
  },
});

export const fetchNextPage = () => ({
  type: SearchResultsActionTypes.FETCH_NEXT_PAGE,
});

export const selectTextSearch = () => ({
  type: SearchResultsActionTypes.SELECT_TEXT_SEARCH,
});

export const selectImageSearch = () => ({
  type: SearchResultsActionTypes.SELECT_IMAGE_SEARCH,
});

const isNonEmptyValue = (value: any): boolean =>
  !isNil(value) && !(isArray(value) && isEmpty(value)) && !(isString(value) && !value);

const fetchFeaturedSocialAccounts = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
): Promise<Response> => {
  const query = reduce(
    params,
    (result, value, key) => {
      if (isNonEmptyValue(value)) {
        result += `&${key}=${value}`;
      }
      return result;
    },
    '',
  );
  return fetch(
    `${apiEndpoint}/${endpoints.socialAccountEndpoint}?featured=true&page=${page}${query}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );
};

const fetchSocialAccountsImageSearch = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
): Promise<Response> => {
  return fetch(`${apiEndpoint}/${endpoints.socialAccountImageSearchEndpoint}`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      page,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};

const fetchSocialAccountsSearch = (
  apiEndpoint: string,
  params: ISearchParams,
  page: number = 0,
): Promise<Response> => {
  return fetch(`${apiEndpoint}/${endpoints.socialAccountSearchEndpoint}`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      page,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};

export const fetchCreators = (
  page: number,
  featured: boolean,
  isImageSearch: boolean,
  resetResults = true,
  appendResults = false,
): SRThunkAction => async (dispatch, getState) => {
  if (resetResults) {
    dispatch({
      type: SearchResultsActionTypes.RESET_SEARCH_RESULTS,
    });
  }

  const state = getState();

  const {
    external: { apiEndpointV1: apiEndpoint },
    searchResults: { _promise: runningPromise },
  } = state;

  if (isFunction(runningPromise?.cancel)) {
    runningPromise.cancel();
  }

  let promise;
  let params;

  if (featured) {
    if (state.external.clientId) {
      if (state.external.defaultImageSearchUrl) {
        params = defaultImageSearchAPIParamsSelector(state);
        promise = fetchSocialAccountsImageSearch(apiEndpoint, params, page);
      } else {
        // Fall back to regular search with empty params.
        params = searchAPIParamsSelector(state);
        promise = fetchSocialAccountsSearch(apiEndpoint, params, page);
      }
    } else {
      params = searchAPIParamsSelector(state);
      promise = fetchFeaturedSocialAccounts(apiEndpoint, params, page);
    }
  } else if (isImageSearch) {
    params = imageSearchAPIParamsSelector(state);
    promise = fetchSocialAccountsImageSearch(apiEndpoint, params, page);
  } else {
    params = searchAPIParamsSelector(state);
    promise = fetchSocialAccountsSearch(apiEndpoint, params, page);
  }

  promise = Bluebird.resolve(promise);

  dispatch({
    type: SearchResultsActionTypes.FETCH_CREATORS_REQUEST,
    payload: {
      isImageSearch,
      isFeaturedSearch: featured,
      promise,
    },
  });

  try {
    const resp = await promise;
    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      const count = json.data.count || 0;
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      dispatch({
        type: SearchResultsActionTypes.FETCH_CREATORS_SUCCESS,
        payload: {
          socialAccounts,
          hasNext,
          count,
          page,
          appendResults,
        },
      });
    } else {
      throw new Error(DEFAULT_GENERIC_ERROR_MSG);
    }
  } catch (err) {
    if (err instanceof CancellationError) {
      // Ignore cancellation errors.
      return;
    }
    dispatch({
      type: SearchResultsActionTypes.FETCH_CREATORS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }

  addEventLog('search', {
    term: params.query,
    network: params.network_type,
    image_search: isImageSearch,
    is_image_search: isImageSearch,
  });
};

import * as React from 'react';
import cx from 'classnames';
import { map, flatMap, filter, meanBy, sumBy, compact, toString, isEmpty, isNumber } from 'lodash';

import {
  AddToGroupIcon,
  ClockIcon,
  CloseIcon,
  DownloadIcon,
  EditIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  GroupIcon,
  LikedCircleBlueIcon,
  SendDocumentIcon,
  SendOfferIcon,
  SpinnerIcon,
  StarBorderIcon,
  TrashcanIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
} from 'src/widgets/Button';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Select } from 'src/widgets/Select';
import {
  Table,
  ITableRefHandles,
} from 'src/widgets/Table';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IAdvertiserPublisherRelation } from 'src/common/models/advertiserPublisherRelation';
import { ICreatorGroup } from 'src/common/models/creatorGroup';

import { getColumns } from './columns';
import {
  projectMetadataGuessForCampaignStage,
  allProjectMetadataForCampaignStage,
  stageGuessForCampaignStage,
} from './utils/stageHelper';
import getSource from './utils/getSource';
import getNetworks from './utils/getNetworks';
import getReach from './utils/getReach';
import getEngagement from './utils/getEngagement';
import isUnread from './utils/isUnread';
import removeEmails from './utils/removeEmails';

const { useState, useRef, useEffect, useMemo } = React;
import countries from 'src/common/config/countries';
import styles from './InboxTableDesktop.scss';

type TActionType = 'delete' | 'setActive' | 'save' | 'markRead' | 'markUnread' | 'addToGroup';
export interface IInboxTableDesktopProps {
  isLoading: boolean;
  $state: any;
  brand: IBrand;
  campaign: ICampaign;
  stageIdentifier: string;
  relations: IAdvertiserPublisherRelation[];
  isPublisher: boolean;

  title?: string;
  emptyMessage: string;
  shouldRemoveEmails: boolean;
  shouldShowArchive?: boolean;
  shouldShowSetActive?: boolean;
  shouldShowStash?: boolean;
  shouldShowDownloadCSV?: boolean;
  shouldShowGroups?: boolean;
  shouldShowSendTerms?: boolean;
  shouldShowGroupAction?: boolean;

  // actions
  onDelete: (relationIds: number[]) => Promise<any>;
  onSetActive: (relationIds: number[]) => Promise<any>;
  onSave: (relationIds: number[]) => Promise<any>;
  onMarkRead: (relationIds: number[]) => Promise<any>;
  onMarkUnread: (relationIds: number[]) => Promise<any>;
  onDownloadCSV(relationIds: number[]): void;
  onSendTerms(relationIds: number[]): void;
  onEditGroup(): void;
  onDeleteGroup(): void;

  groups: ICreatorGroup[];
  onCreateGroup(): void;
  onAddToGroup: (group: ICreatorGroup, relationIds: number[]) => Promise<any>;

  onSelectedDataChange(relations: IAdvertiserPublisherRelation[]): void;

  className?: string;
}

function renderNotificationCell(relation: IAdvertiserPublisherRelation) {
  if (!isUnread(relation)) {
    return null;
  }

  return <div className={styles.newDot} />;
}

/**
 * @type {React.FunctionComponent}
 */
export const InboxTableDesktop: React.FunctionComponent<IInboxTableDesktopProps> = React.memo(
  (props) => {
    const {
      isLoading,
      brand,
      campaign,
      stageIdentifier,
      title,
      isPublisher,
      relations,
      emptyMessage,
      shouldRemoveEmails,
      shouldShowArchive,
      shouldShowSetActive,
      shouldShowStash,
      shouldShowDownloadCSV,
      shouldShowGroups,
      shouldShowSendTerms,
      shouldShowGroupAction,
    } = props;
    const tableRef = useRef<ITableRefHandles>(null);
    const toastRef = useRef<IToastRefHandles>(null);
    const columns = useMemo(() => {
      return getColumns(isPublisher);
    }, [isPublisher]);
    const [selectedRelationIds, setSelectedRelationIds] = useState<number[]>([]);
    const [shouldMarkUnread, setShouldMarkUnread] = useState(false);

    const shouldShowLoading = isLoading && isEmpty(relations);

    // actionTaking states
    const [actionTaking, setActionTaking] = useState<TActionType>(null);

    useEffect(() => {
      const table = tableRef && tableRef.current;

      if (table) {
        table.unsetSelectedRows();
      }
    }, [relations, campaign, stageIdentifier]);

    const onSelectedDataChange = (relations: IAdvertiserPublisherRelation[]) => {
      let hasUnread = false;
      const relationIds = map(relations, (relation) => {
        if (relation.notification_count > 0) {
          hasUnread = true;
        }

        return relation.id;
      });
      setSelectedRelationIds(relationIds);
      setShouldMarkUnread(!hasUnread);

      props.onSelectedDataChange(relations);
    };

    const handleRowClicked = (relation, metaKey: boolean) => {
      const url = props.$state.href('manage.relationship.main.mainView', {
        relationId: relation.id,
      });

      if (metaKey) {
        window.open(url, '_blank');
      } else {
        window.location.replace(url);
      }
    };

    const handleMessageClick = () => {
      const url = props.$state.href('manage.message_relations_stage', {
        stage: stageIdentifier,
      });

      window.location.replace(url);
    };

    const handleDelete = () => {
      setActionTaking('delete');
      props
        .onDelete(selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Archived
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'}.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to archive the relations.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const handleSetActive = () => {
      setActionTaking('setActive');
      props
        .onSetActive(selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Set
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'} active.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to set the relations active.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const handleSave = () => {
      setActionTaking('save');
      props
        .onSave(selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Saved
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'}.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to save the relations.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const handleMarkRead = () => {
      setActionTaking('markRead');
      props
        .onMarkRead(selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Marked
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'} as read.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to mark the relations as read.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const handleMarkUnread = () => {
      setActionTaking('markUnread');
      props
        .onMarkUnread(selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Marked
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'} as unread.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to mark the relations as unread.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const handleDownloadCSV = () => {
      props.onDownloadCSV(selectedRelationIds);
    };

    const handleSendTerms = () => {
      props.onSendTerms(selectedRelationIds);

      const url = props.$state.href('mass_terms.select_campaign');

      window.location.replace(url);
    };

    const handleGroupAction = (group: ICreatorGroup) => {
      if (!group) {
        return props.onCreateGroup();
      }

      setActionTaking('addToGroup');
      props
        .onAddToGroup(group, selectedRelationIds)
        .then(() => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: (
                <div>
                  Added
                  <span> {selectedRelationIds.length} </span>
                  {selectedRelationIds.length > 1 ? 'relations' : 'relation'} to group{' '}
                  <span>{group.name}</span>.
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          setActionTaking(null);

          const toast = toastRef.current;
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to add the relations to group.',
              type: 'error',
            });
          }

          console.log(err);
        });
    };

    const data = useMemo(() => {
      const compaignId = campaign && campaign.id;
      const currencyCode = campaign ? campaign.currency_code : brand && brand.currency_code;
      const currencyXRate = campaign ? campaign.currency_xrate : brand && brand.currency_xrate;

      return map(relations, (relation) => {
        const projectMetadata = projectMetadataGuessForCampaignStage(
          relation,
          compaignId,
          stageIdentifier,
        );
        const allProjectMetadata = allProjectMetadataForCampaignStage(
          relation,
          compaignId,
          stageIdentifier,
        );
        const stage = stageGuessForCampaignStage(relation, compaignId, stageIdentifier);
        const instagramReach = getReach(relation, 'instagram');
        const instagramEngagement = getEngagement(relation, 'instagram');
        const engagementRate = instagramReach > 0 ? instagramEngagement / instagramReach : null;

        return {
          id: toString(relation.id),
          new: renderNotificationCell(relation),
          nameImageSrc: {
            src: relation[relation.adjacent_type].profile_picture,
            fallbackSrc: relation[relation.adjacent_type].automatic_profile_image_url,
          },
          displayName: relation[relation.adjacent_type].display_name,
          likedBadge: relation.liked ? <LikedCircleBlueIcon size={27} /> : null,
          lastMessage:
            (shouldRemoveEmails
              ? removeEmails(relation.latest_message)
              : relation.latest_message) || '-',
          lastNote:
            (shouldRemoveEmails ? removeEmails(relation.latest_note) : relation.latest_note) || '-',
          stage: stage ? stage.display_name : 'Unknown',
          lastUpdate: relation.last_update,
          source: getSource(relation),
          networks: getNetworks(relation, props.$state),
          contents: projectMetadata && projectMetadata.images,
          brandContents: projectMetadata && projectMetadata.brand_images,
          reach: getReach(relation),
          engagement: getEngagement(relation),
          engagementRate,
          proposedNetworks: map(
            projectMetadata && projectMetadata.proposed_networks,
            (network) => ({
              type: network,
            }),
          ),
          proposedAmount: projectMetadata && projectMetadata.price_usd,
          creatorRating: relation.publisher && relation.publisher.rating,
          country: (relation.publisher && countries[relation.publisher.country]) || '-',
          agreedAmount: (projectMetadata && projectMetadata.agreed_price_usd) || '-',
          deliverables: compact(
            flatMap(allProjectMetadata, (metadata) => metadata.agreed_products),
          ).map((network) => ({
            type: network,
          })),
          nextPostDue: projectMetadata && projectMetadata.target_ts,
          productChoices: projectMetadata && projectMetadata.publisher_selection,
          address: projectMetadata && projectMetadata.address,
          postsCount: sumBy(allProjectMetadata, (metadata) => metadata.posts_count || 0),
          postImages: compact(flatMap(allProjectMetadata, (metadata) => metadata.post_images)),
          deliveredDate: projectMetadata && projectMetadata.delivered_ts,
          amountOwed: sumBy(allProjectMetadata, (metadata) => metadata.amount_owed_usd || 0) || '-',
          amountSent: sumBy(allProjectMetadata, (metadata) => metadata.amount_sent_usd || 0),
          review: meanBy(
            filter(allProjectMetadata, (metadata) => isNumber(metadata.review)),
            (metadata) => metadata.review,
          ),
          currencyCode: isPublisher ? relation.advertiser.brand.currency_code : currencyCode,
          currencyXRate: isPublisher ? relation.advertiser.brand.currency_xrate : currencyXRate,
          _raw: relation,
          _bodyRowClassName: isUnread(relation)
            ? styles.withNotification
            : styles.withoutNotification,
        };
      });
    }, [relations, isPublisher, campaign, brand, stageIdentifier, props.$state, shouldRemoveEmails]);

    return (
      <div className={cx(styles.InboxTableDesktop, props.className)}>
        <div className={styles.header}>
          {isLoading && <SpinnerIcon className={cx(styles.spinner, styles.loading)} />}
          <div className={styles.title}>{title}</div>
          <div className={styles.count}>{relations.length}</div>
        </div>
        {shouldShowLoading && <LoadSpinner />}
        {!shouldShowLoading && (
          <Table
            ref={tableRef}
            data={data}
            columns={columns}
            onRowClicked={handleRowClicked}
            onSelectedDataChange={onSelectedDataChange}
            disabled={!!actionTaking}
            emptyMessage={emptyMessage}
            paddingBottom={0}
            headerActions={renderActions()}
            config={{
              allowSearch: false,
              configurableColumns: false,
              pageSize: 50,
              rowHeight: 58,
              striped: false,
              rowBorder: true,
            }}
          />
        )}
        <Toast ref={toastRef} />
      </div>
    );

    /**
     * Renders the actions.
     *
     * @return {JSX.Element}
     */
    function renderActions(): JSX.Element {
      return (
        <>
          <div className={styles.iconButtonGroup}>
            {shouldShowArchive && (
              <IconButton
                icon={
                  actionTaking === 'delete' ? (
                    <SpinnerIcon className={styles.spinner} />
                  ) : (
                    <TrashcanIcon />
                  )
                }
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                onClick={handleDelete}
                tooltip="Delete"
                className={styles.iconButton}
              />
            )}
            {shouldShowSetActive && (
              <IconButton
                icon={
                  actionTaking === 'setActive' ? (
                    <SpinnerIcon className={styles.spinner} />
                  ) : (
                    <StarBorderIcon size={20} />
                  )
                }
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                onClick={handleSetActive}
                tooltip="Set Active"
                className={styles.iconButton}
              />
            )}
            <div className={styles.divider} />
            {shouldShowStash && (
              <IconButton
                icon={
                  actionTaking === 'save' ? (
                    <SpinnerIcon className={styles.spinner} />
                  ) : (
                    <ClockIcon />
                  )
                }
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                onClick={handleSave}
                tooltip="Save for Later"
                className={styles.iconButton}
              />
            )}
            {shouldMarkUnread && (
              <IconButton
                icon={
                  actionTaking === 'markUnread' ? (
                    <SpinnerIcon className={styles.spinner} />
                  ) : (
                    <EnvelopeIcon />
                  )
                }
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                onClick={handleMarkUnread}
                tooltip="Mark as Unread"
                className={styles.iconButton}
              />
            )}
            {!shouldMarkUnread && (
              <IconButton
                icon={
                  actionTaking === 'markRead' ? (
                    <SpinnerIcon className={styles.spinner} />
                  ) : (
                    <EnvelopeOpenIcon />
                  )
                }
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                tooltip="Mark as Read"
                onClick={handleMarkRead}
                className={styles.iconButton}
              />
            )}
            {shouldShowDownloadCSV && (
              <IconButton
                icon={<DownloadIcon />}
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                tooltip="Download as CSV"
                onClick={handleDownloadCSV}
                className={styles.iconButton}
              />
            )}
          </div>
          {shouldShowGroups && (
            <>
              <div className={styles.divider} />
              {renderAddToListButton()}
              <Button
                theme="light"
                border={false}
                icon={<SendOfferIcon size={20} />}
                label="Message"
                disabled={selectedRelationIds.length === 0 || !!actionTaking}
                onClick={handleMessageClick}
                className={styles.button}
              />
              {shouldShowSendTerms && (
                <Button
                  theme="light"
                  border={false}
                  icon={<SendDocumentIcon />}
                  label="Create Terms"
                  disabled={selectedRelationIds.length === 0 || !!actionTaking}
                  onClick={handleSendTerms}
                  className={styles.button}
                />
              )}
              {shouldShowGroupAction && (
                <IconButton
                  icon={<EditIcon />}
                  disabled={!!actionTaking}
                  tooltip="Edit Group"
                  onClick={props.onEditGroup}
                  className={styles.iconButton}
                />
              )}
              {shouldShowGroupAction && (
                <IconButton
                  icon={<TrashcanIcon />}
                  disabled={!!actionTaking}
                  tooltip="Delete Group"
                  onClick={props.onDeleteGroup}
                  className={styles.iconButton}
                />
              )}
            </>
          )}
        </>
      );
    }

    function renderAddToListButton() {
      const options = map(props.groups, (group) => ({
        label: (
          <div className={styles.option}>
            <GroupIcon className={styles.icon} />
            {group.name}
          </div>
        ),
        value: group,
      }));
      options.push({
        label: (
          <div className={styles.option}>
            <div className={styles.addIconWrapper}>
              <CloseIcon size={12} className={styles.addIcon} />
            </div>
            Create Group
          </div>
        ),
        value: null,
      });

      return (
        <Select
          className={styles.button}
          popoverProps={{
            className: styles.GroupPopover,
          }}
          options={options}
          onChange={handleGroupAction}
          disabled={selectedRelationIds.length === 0 || !!actionTaking}
          customLabelElement={
            <Button
              theme="light"
              border={false}
              icon={
                actionTaking === 'addToGroup' ? (
                  <SpinnerIcon className={styles.spinner} />
                ) : (
                  <AddToGroupIcon size={28} />
                )
              }
              label={actionTaking === 'addToGroup' ? 'Adding...' : 'Add to Group'}
            />
          }
        />
      );
    }
  },
);

InboxTableDesktop.defaultProps = {
  title: 'In Progress',
  shouldShowArchive: false,
  shouldShowSetActive: false,
  shouldShowStash: false,
  shouldShowDownloadCSV: false,
  shouldShowGroups: false,
  shouldShowSendTerms: false,
};

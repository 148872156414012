import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ArrowLeftIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="M28.8,13.1H4L16.9,1.9c0.5-0.4,0.5-1.1,0.1-1.5c-0.4-0.5-1.1-0.5-1.5-0.1L0.4,13.4
      c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1-0.1,0.1C0,13.9,0,14.1,0,14.2s0,0.3,0.1,0.4c0,0,0,0.1,0.1,0.1
      c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0l15.1,13.1c0.5,0.4,1.1,0.3,1.5-0.1c0.4-0.5,0.3-1.1-0.1-1.5L4,15.3h24.9
      c0.6,0,1.1-0.5,1.1-1.1S29.4,13.1,28.8,13.1z"
    />
  </SvgIcon>
));

export { ArrowLeftIcon };

import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { format, differenceInDays } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import {
  CheckCircleIcon,
  ClockIcon,
  CloseIcon,
  EditIcon,
  KeyboardArrowLeftIcon,
  SendOfferIcon,
  ThumbsUpIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
  SubmitButton,
} from 'src/widgets/Button';
import { ContentPreviewer } from 'src/widgets/ContentPreviewer';
import { Notice } from 'src/widgets/Notice';
import { Textarea } from 'src/widgets/Textarea/Textarea';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';
import { NetworkIcon } from 'src/common/NetworkIcon';

import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import processContentItems from './utils/processContentItems';

import { IClientFeedback } from 'src/common/models/contentReview';
import { IContentInfo } from './model';

import styles from './ClientContentItem.scss';

interface IProps {
  content: IContentInfo;
  feedback: IClientFeedback;
  updateApproved(approved: boolean);
  updateComment(comment: string);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  isVisible: boolean;
  height: number;

  comment: string;
  isRequestingChanges: boolean;

  collapsed: boolean;
  isUpdatingApproved: boolean;
  isUpdatingComment: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class ClientContentItem extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private ref: React.RefObject<HTMLDivElement>;
  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();
    this.toastRef = React.createRef();

    this.state = {
      isVisible: false,
      height: null,

      comment: (props.feedback && props.feedback.comment) || '',
      isRequestingChanges: false,

      collapsed: false,
      isUpdatingApproved: false,
      isUpdatingComment: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    // disable observer for Safari and older browers.
    if (typeof IntersectionObserver === 'undefined') {
      this.setState({
        isVisible: true,
      });

      return;
    }

    const node = this.ref.current;
    const observer = new IntersectionObserver(this.intersectionCallback, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    });

    observer.observe(node);
  }

  /**
   * @private
   * Callback for IntersectionObserver.
   *
   * @param {IntersectionObserverEntry} entries the intersection observer entries.
   */
  private intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const node = this.ref.current;

    if (entry && node) {
      if (entry.intersectionRatio > 0.1) {
        this.setState({
          isVisible: true,
          height: node.offsetHeight,
        });
      } else {
        this.setState({
          isVisible: false,
          height: node.offsetHeight,
        });
      }
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    const { isVisible, height } = this.state;

    return (
      <div
        ref={this.ref}
        className={cx(classNames.concat(styles.ClientContentItem))}
        style={{
          height: !isVisible ? `${height}px` : undefined,
        }}
      >
        {this.renderHeader()}
        {isVisible && this.renderBody()}
        <Toast ref={this.toastRef} />
      </div>
    );
  }

  private renderHeader = () => {
    const { content, feedback } = this.props;
    const { collapsed } = this.state;

    const diffDays = differenceInDays(content.dueTs * 1000, Date.now());
    const rejected = feedback && !isEmpty(feedback.comment);
    const approved = feedback && feedback.approved;

    return (
      <div
        className={cx(styles.header, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.info}>
          <div className={styles.networkAndDescription}>
            <div className={styles.network}>
              <NetworkIcon identifier={getNetworkByPostType(content.postType)} size={24} />
            </div>
            <div className={styles.description}>{content.productDescription}</div>
          </div>
          <div className={styles.campaignAndCreator}>
            for
            <span className={styles.campaign}>{content.campaign}</span>
            {content.creatorName && (
              <React.Fragment>
                from
                <span className={styles.creator}>{content.creatorName}</span>
              </React.Fragment>
            )}
          </div>
        </div>
        {!rejected && !approved && content.dueTs && (
          <div className={styles.timeLeft}>
            {diffDays < 0 && <Notice type="error">Already past due</Notice>}
            {diffDays === 0 && (
              <Notice type="error">
                {formatDistanceToNow(new Date(content.dueTs * 1000))} left to review
              </Notice>
            )}
            {diffDays > 0 && (
              <React.Fragment>
                <ClockIcon className={styles.clockIcon} />
                <div>
                  <span>Review before {format(content.dueTs * 1000, 'MMM dd')} </span>(
                  {formatDistanceToNow(new Date(content.dueTs * 1000))} remaining)
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        {rejected && (
          <Notice className={styles.notice} type="error">
            You rejected this content
          </Notice>
        )}
        {approved && (
          <Notice className={styles.notice} type="success">
            You approved this content
          </Notice>
        )}
        <IconButton
          icon={
            <KeyboardArrowLeftIcon
              className={cx(styles.icon, {
                [styles.collapsed]: collapsed,
              })}
            />
          }
          className={styles.collapseButton}
          onClick={this.toggleCollapsed}
        />
      </div>
    );
  };

  private renderBody = () => {
    const { content, feedback } = this.props;
    const {
      isRequestingChanges,
      comment,
      collapsed,
      isUpdatingApproved,
      isUpdatingComment,
    } = this.state;

    const contents = processContentItems(content);

    const savedComment = feedback && feedback.comment;
    const approved = feedback && feedback.approved;

    return (
      <div
        className={cx(styles.body, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.contents}>
            {!collapsed && (
              <ContentPreviewer
                contents={contents}
                allowExpanding={true}
                enableDetailView={true}
                containerWidth={360}
              />
            )}
          </div>
          <div className={styles.info}>
            {content.caption && (
              <div className={styles.caption}>
                <div className={styles.title}>Caption</div>
                <div className={styles.text}>{content.caption}</div>
              </div>
            )}
            {approved && !isUpdatingApproved && (
              <div className={styles.approvedDisplay}>
                <ThumbsUpIcon className={styles.approvedIcon} />
                <div className={styles.approvedMessage}>You&apos;ve approved this content</div>
                <CloseIcon
                  size={22}
                  className={styles.undoApproveIcon}
                  onClick={this.updateApproved.bind(this, false)}
                />
              </div>
            )}
            {!isEmpty(savedComment) && !isUpdatingComment && !isRequestingChanges && (
              <div className={styles.feedbackDisplay}>
                <SendOfferIcon className={styles.messageIcon} />
                <div className={styles.feedback}>
                  <div className={styles.feedbackTitle}>Your feedback:</div>
                  <div className={styles.feedbackContent}>{savedComment}</div>
                </div>
                <div className={styles.actions}>
                  <EditIcon size={18} className={styles.icon} onClick={this.requestChange} />
                  <CloseIcon size={22} className={styles.icon} onClick={this.clearComment} />
                </div>
              </div>
            )}
            {!isRequestingChanges && (
              <div className={styles.actions}>
                <SubmitButton
                  label={approved ? 'Approved' : 'Approve'}
                  disabled={
                    isUpdatingApproved || approved || isUpdatingComment || !isEmpty(savedComment)
                  }
                  isSubmitting={isUpdatingApproved}
                  submittingLabel="Updating..."
                  icon={<CheckCircleIcon />}
                  className={styles.approveButton}
                  onClick={this.updateApproved.bind(this, true)}
                />
                <SubmitButton
                  label="Request Changes"
                  submittingLabel="Updating..."
                  isSubmitting={isUpdatingComment}
                  theme="info"
                  onClick={this.requestChange}
                  disabled={approved || isUpdatingComment || !isEmpty(savedComment)}
                />
              </div>
            )}
            {isRequestingChanges && (
              <div className={styles.requestingChanges}>
                <div className={styles.label}>Request changes:</div>
                <Textarea
                  className={styles.textarea}
                  defaultValue={comment}
                  placeholder="Describe your request..."
                  onChange={this.handleCommentChange}
                  onPressEnter={this.updateComment}
                  focusOnMount={true}
                />
                <div className={styles.requestActions}>
                  <SubmitButton
                    className={cx(styles.button, styles.left)}
                    label="Send Request"
                    disabled={isUpdatingComment || savedComment === comment}
                    isSubmitting={isUpdatingComment}
                    submittingLabel="Sending..."
                    onClick={this.updateComment}
                  />
                  <Button
                    className={styles.button}
                    theme="info"
                    label="Cancel"
                    disabled={isUpdatingComment}
                    onClick={this.cancelRequestChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  private toggleCollapsed = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed,
    });
  };

  private handleCommentChange = (comment: string) => {
    this.setState({
      comment,
    });
  };

  private requestChange = () => {
    this.setState({
      isRequestingChanges: true,
    });
  };

  private cancelRequestChange = () => {
    const { feedback } = this.props;

    this.setState({
      isRequestingChanges: false,
      comment: (feedback && feedback.comment) || '',
    });
  };

  private clearComment = () => {
    this.setState(
      {
        comment: '',
      },
      this.updateComment,
    );
  };

  private updateComment = () => {
    const { updateComment, content } = this.props;
    const { comment } = this.state;
    const toast = this.toastRef.current;

    this.setState({
      isUpdatingComment: true,
    });
    updateComment(comment)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                {isEmpty(comment) ? 'Updated comment for' : 'Rejected'} content
                {content.creatorName && (
                  <React.Fragment>
                    {' '}
                    by <span>{content.creatorName}</span>
                  </React.Fragment>
                )}
                .
              </div>
            ),
          });
        }

        this.setState({
          isUpdatingComment: false,
          isRequestingChanges: false,
        });
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to update the comment.',
            type: 'error',
          });
        }

        this.setState({
          isUpdatingComment: false,
        });
      });
  };

  private updateApproved = (approved: boolean) => {
    const { updateApproved, content } = this.props;
    const toast = this.toastRef.current;

    this.setState({
      isUpdatingApproved: true,
    });
    updateApproved(approved)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                {approved ? 'Approved' : 'Removed approval for'} content
                {content.creatorName && (
                  <React.Fragment>
                    {' '}
                    by <span>{content.creatorName}</span>
                  </React.Fragment>
                )}
                .
              </div>
            ),
          });
        }

        this.setState({
          isUpdatingApproved: false,
        });
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to approve the content.',
            type: 'error',
          });
        }

        this.setState({
          isUpdatingApproved: false,
        });
      });
  };
}

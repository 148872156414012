import * as React from 'react';
import cx from 'classnames';
import { debounce, isEmpty, isUndefined, isNumber } from 'lodash';

import { AlertIcon } from 'src/icons';

import styles from './Input.scss';

export type TSupportedInputType = 'text' | 'integer' | 'password';
type TTheme = 'light' | 'info';
interface IProps {
  className?: string;
  blurOnEnter?: boolean;
  buffer?: number;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  focusOnMount?: boolean;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  hasError?: boolean;
  icon?: JSX.Element;
  placeholder?: string;
  readOnly?: boolean;
  theme?: TTheme;
  triggerChangeOnBlur?: boolean;
  type?: TSupportedInputType;
  value?: string;

  onChange?(value: string);
  onPressEnter?();
}

type TDefaultProp =
  | 'blurOnEnter'
  | 'onChange'
  | 'type'
  | 'onPressEnter'
  | 'placeholder'
  | 'value'
  | 'defaultValue'
  | 'buffer'
  | 'theme'
  | 'icon'
  | 'triggerChangeOnBlur'
  | 'focusOnMount'
  | 'hasError'
  | 'errorMessage'
  | 'disabled'
  | 'readOnly';

interface IState {
  value: string;
  focus: boolean;
}

/**
 * Returns the 'RegExp' depending on the type.
 *
 * @param {TSupportedInputType} type of input value expected
 *
 * @return {RegExp}
 */
const getValueRegexFromInputType = (type: TSupportedInputType): RegExp => {
  if (type === 'integer') {
    return new RegExp('^[0-9]*$');
  } else {
    return new RegExp('.*');
  }
};

const ForwardedRefInput = React.forwardRef(
  (props: IProps, ref: React.RefObject<HTMLInputElement>) => {
    return <Input {...props} forwardedRef={ref} />;
  },
);

export { ForwardedRefInput as Input };

/**
 * @class
 * @extends {React.Component}
 */
class Input extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    blurOnEnter: false,
    buffer: undefined,
    defaultValue: '',
    disabled: false,
    errorMessage: '',
    focusOnMount: false,
    hasError: false,
    icon: null,
    onChange: () => undefined,
    onPressEnter: () => undefined,
    placeholder: '',
    readOnly: false,
    theme: 'info',
    triggerChangeOnBlur: false,
    type: 'text',
  };

  private controlled: boolean;
  private hasBuffer: boolean;
  private regex: RegExp;
  private inputRef: React.RefObject<HTMLInputElement>;
  private onChange: (value: string) => void;

  /**
   * @inheritdoc
   */
  public constructor(props: IProps) {
    super(props);

    this.controlled = !isUndefined(props.value);
    this.hasBuffer = isNumber(props.buffer) && props.buffer > 0;
    this.regex = getValueRegexFromInputType(props.type);

    this.state = {
      value: this.controlled ? props.value : props.defaultValue,
      focus: false,
    };
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillMount() {
    this.inputRef = this.props.forwardedRef || React.createRef();
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillReceiveProps = (nextProps: IProps) => {
    const { type } = this.props;
    const { value } = this.state;

    if (type !== nextProps.type) {
      this.regex = getValueRegexFromInputType(nextProps.type);
    }

    if (this.controlled && value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  };

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const { focusOnMount } = this.props;
    const input = this.inputRef.current;

    if (input && focusOnMount) {
      // focus input in next loop
      setTimeout(() => {
        input.focus();
      }, 0);
    }

    this.onChange = this.hasBuffer
      ? debounce(
        (value: string) => {
          this.props.onChange(value);
        },
        this.props.buffer,
      )
      : (value: string) => {
        this.props.onChange(value);
      };

    window.addEventListener('keypress', this.handleKeyPress);
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeyPress);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      icon,
      theme,
      hasError,
      errorMessage,
      placeholder,
      disabled,
      readOnly,
      className,
    } = this.props;
    const { focus, value } = this.state;

    return (
      <div className={cx(styles.Input, className)}>
        <div
          className={cx(styles.inputWrapper, styles[theme], {
            [styles.focus]: focus,
            [styles.hasError]: hasError,
          })}
        >
          {icon && <div className={styles.icon}>{icon}</div>}
          <input
            ref={this.inputRef}
            className={styles.input}
            value={value}
            onChange={this.handleChange}
            spellCheck={false}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            type={this.props.type === 'password' ? 'password' : 'text'}
          />
          {hasError && <AlertIcon size={22} className={styles.alertIcon} />}
        </div>
        {hasError && !isEmpty(errorMessage) && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    );
  }

  /**
   * @public
   * Selects the input, used for copying to clipboard.
   */
  public select = () => {
    const input = this.inputRef.current;

    input.select();
  };

  /**
   * @private
   * Triggers a blur when pressing enter.
   */
  private handleKeyPress = (event) => {
    const { blurOnEnter, onPressEnter } = this.props;
    const { focus } = this.state;
    const { keyCode } = event;
    const input = this.inputRef.current;

    // do nothing if not focused
    if (!focus || !input) {
      return;
    }

    // trigger a blur
    if (keyCode === 13) {
      onPressEnter();

      if (blurOnEnter) {
        input.blur();
      } else {
        const { value } = event.target;
        this.onChange(value);
      }
    }
  };
  /**
   * @private
   * Notifies the parent with new value.
   */
  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { triggerChangeOnBlur } = this.props;
    const { value } = event.target;

    if (!this.regex.test(value)) {
      return;
    }

    this.setState({
      value,
    });

    if (!triggerChangeOnBlur) {
      this.onChange(value);
    }
  };

  /**
   * @private
   * Focuses the Input component when input is focused.
   */
  private handleInputFocus = () => {
    this.setState({
      focus: true,
    });
  };

  /**
   * @private
   * Blurs the Input component when input is focused.
   */
  private handleInputBlur = () => {
    const { triggerChangeOnBlur } = this.props;
    const { value } = this.state;

    this.setState({
      focus: false,
    });

    if (triggerChangeOnBlur) {
      this.onChange(value);
    }
  };
}

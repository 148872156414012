import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';

export type TTooltipPlacement = 'top' | 'bottom';
type TTooltipColor = 'black' | 'white';

export interface ITooltipProps {
  mountRef: React.RefObject<Element>;

  autoRegisterListener?: boolean;
  show?: boolean;
  placement?: TTooltipPlacement;
  tooltipColor?: TTooltipColor;
  maxWidth?: number;
  contentClassName?: string;
  className?: string;
}

import styles from './Tooltip.scss';
const { useState, useRef, useEffect, useMemo } = React;
const arrowHeight = 10;

/**
 * @class
 * @extends {React.Component}
 */
export const Tooltip: React.FunctionComponent<ITooltipProps> = React.memo((props) => {
  // skip server side rendering
  if (typeof window === 'undefined') {
    return null;
  }

  const {
    placement,
    tooltipColor,
    maxWidth,
    autoRegisterListener,
    mountRef,
    show,
    children,
    contentClassName,
    className,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const el = useRef(document.createElement('div'));
  const [stateShow, setStateShow] = useState(false);

  // properly clean up to avoid memory leak
  useEffect(() => {
    const current = el.current;
    document.body.appendChild(current);

    return () => {
      document.body.removeChild(current);
    };
  }, []);

  const transformOrigin = useMemo(() => {
    if (placement === 'bottom') {
      return 'top';
    } else {
      return 'bottom';
    }
  }, [placement]);

  useEffect(() => {
    const parent = mountRef && mountRef.current;

    const showTooltip = () => setStateShow(true);
    const hideTooltip = () => setStateShow(false);

    // show/hide tooltip when mouse enter/leave
    // no need to clean up in UNSAFE_componentWillUnmount
    // as parent will be notified first, and mountRef will be null
    if (parent && autoRegisterListener) {
      parent.addEventListener('mouseenter', showTooltip, { passive: true });
      parent.addEventListener('mouseleave', hideTooltip, { passive: true });

      // also hide tooltip on mousedown
      parent.addEventListener('mousedown', hideTooltip, { passive: true });
    }

    return () => {
      if (parent) {
        parent.removeEventListener('mouseenter', showTooltip);
        parent.removeEventListener('mouseleave', hideTooltip);
        parent.removeEventListener('mousedown', hideTooltip);
      }
    };
  }, [mountRef, autoRegisterListener]);

  // can't memorize it because parent/self position changes constantly
  // and put bounding rect in dependency array is expensive
  const { top, left } = calculateMountPosition();

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cx(styles.Tooltip, className, {
        [styles.show]: autoRegisterListener ? stateShow : show,
      })}
      style={{
        transform: `translate(${left}px, ${top}px)`,
      }}
    >
      <div
        className={cx(styles.content, contentClassName, {
          [styles.colorWhite]: tooltipColor === 'white',
          [styles.colorBlack]: tooltipColor === 'black',
          [styles.contentTop]: placement === 'top',
          [styles.contentBottom]: placement === 'bottom',
        })}
        style={{
          transformOrigin,
          maxWidth: `${maxWidth}px`,
        }}
      >
        <div
          className={cx(styles.arrow, {
            [styles.top]: placement === 'bottom',
            [styles.bottom]: placement === 'top',
          })}
        />
        {React.Children.toArray(children)}
      </div>
    </div>,
    el.current,
  );

  /**
   * Calculates the mounting position.
   *
   * @return {Object}
   */
  function calculateMountPosition(): {
    top: number;
    left: number;
  } {
    const parentNode = mountRef && mountRef.current;
    // check if parent's is mounted
    // and ref.current is null during initial render
    if (!parentNode || !ref.current) {
      return {
        top: -2000,
        left: -2000,
      };
    }

    const parentBoundingRect = parentNode.getBoundingClientRect();
    const selfBoundingRect = ref.current.getBoundingClientRect();

    // calculate left and top
    let left: number;
    let top: number;
    if (placement === 'bottom') {
      left = Math.round(
        parentBoundingRect.left + parentBoundingRect.width / 2 - selfBoundingRect.width / 2,
      );
      top = Math.round(parentBoundingRect.top + parentBoundingRect.height + arrowHeight);
    } else {
      left = Math.round(
        parentBoundingRect.left + parentBoundingRect.width / 2 - selfBoundingRect.width / 2,
      );
      top = Math.round(parentBoundingRect.top - selfBoundingRect.height - arrowHeight);
    }

    return {
      left,
      top,
    };
  }
});

Tooltip.defaultProps = {
  placement: 'bottom',
  tooltipColor: 'white',
  autoRegisterListener: true,
  show: false,
  maxWidth: 300,
};

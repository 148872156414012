import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  isArray,
  isEmpty,
  map,
  times,
} from 'lodash';

import { BarChart } from 'src/widgets/charts/BarChart';

import { Card } from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './LanguageEthnicityCard.scss';

interface IProps {
  className?: string;
  displayAsBarChart?: boolean;
  hideLanguage?: boolean;
  hideEthnicity?: boolean;
}

export const LanguageEthnicityCard: React.FC<IProps> = (props) => {
  const {
    className,
    displayAsBarChart,
    hideLanguage,
    hideEthnicity,
  } = props;
  const {
    hasData,
    socialAccount,
  } = useSocialProfileContext();

  const showBoth = !hideLanguage && !hideEthnicity;

  const languageData = (
    hasData &&
    !isEmpty(socialAccount.demographics_report) &&
    isArray(socialAccount.demographics_report.language)
  )
    ? socialAccount.demographics_report.language
    : [];
  const hasLanguageData = languageData.length > 0;

  const ethnicityData = (
    hasData &&
    !isEmpty(socialAccount.demographics_report) &&
    isArray(socialAccount.demographics_report.ethnicity)
  )
    ? socialAccount.demographics_report.ethnicity
    : [];
  const hasEthnicityData = ethnicityData.length > 0;

  // To make sure we'll render equal lengths (only matters if both are to be rendered)
  const length = Math.min(5, Math.max(languageData.length, ethnicityData.length));

  const renderCell = (label: React.ReactText, value: React.ReactText) => (
    // Force height for equal height distribution
    <td style={{ height: `${100 / length}%` }}>
      <div className={styles.valuePair}>
        <span className={styles.percentage}>
          {numeral(value).format('0%')}
        </span>
        <span className={styles.label}>{label}</span>
      </div>
    </td>
  );

  const renderEmptyCell = () => <td rowSpan={length} className={styles.noData} />;

  const renderChart = (data) => (
    <BarChart
      className={styles.audienceLocationBarChart}
      data={map(data, (country: [string, number]) => ({
        label: country[0],
        value: country[1] * 100, // Percent value
      }))}
      maxRecords={5}
      height={200} // Shows 5 (see maxRecords in BarChartHorizontal.tsx)
      showRawValue={false}
    />
  );

  const renderTable = () => (
    <div className={styles.languageEthnicityTable}>
      <table>
        <tbody>
          {times(length).map((index) => {
            const [language, lPercentage] = languageData[index] || [null, null];
            const [ethnicity, ePercentage] = ethnicityData[index] || [null, null];
            return (
              <tr key={`language-ethnicity-${index}`}>
                {hasLanguageData && !hideLanguage
                  ? language && renderCell(language, lPercentage)
                  : index === 0 && !hideLanguage && renderEmptyCell()}
                {hasEthnicityData && !hideEthnicity
                  ? ethnicity && renderCell(ethnicity, ePercentage)
                  : index === 0 && !hideEthnicity && renderEmptyCell()}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <Card
      className={cx(className, styles.LanguageEthnicityCard, {
        [styles.noData]: !hasLanguageData && !hasEthnicityData,
      })}
      header={(
        showBoth && hasLanguageData && hasEthnicityData
          ? <h4 className={styles.cardTitle}>Audience Language & Ethnicity</h4>
          : <>
            {!hideLanguage && <h4 className={styles.cardTitle}>Audience Language</h4>}
            {!hideEthnicity && <h4 className={styles.cardTitle}>Audience Ethnicity</h4>}
          </>
      )}
    >
      {displayAsBarChart
        ? renderChart(languageData) // For now, only used with languageData
        : renderTable()}
    </Card>
  );
};

LanguageEthnicityCard.defaultProps = {
  displayAsBarChart: false,
};

import * as React from 'react';
import cx from 'classnames';

import styles from './InputGroup.scss';

interface IProps {
  prepend?: React.ReactElement;
  append?: React.ReactElement;
  classNames?: string[];
}

export const InputGroup: React.FunctionComponent<IProps> = React.memo((props) => (
  <div className={cx(styles.InputGroup, props.classNames)}>
    {props.prepend && <div className={styles.prepend}>{props.prepend}</div>}

    <div className={styles.input}>{React.Children.only(props.children)}</div>

    {props.append && <div className={styles.append}>{props.append}</div>}
  </div>
));

import * as React from 'react';
import { pick, findIndex } from 'lodash';

import { RadioGroup } from 'src/widgets/RadioGroup';
import { TGender } from 'src/common/models/searchParams';
import { FilterSection } from '../FilterSection';
import AudienceDropdown from './AudienceDropdown';

import { IAudienceGenderFilters } from '../models';

const { useMemo } = React;

const Genders = Object.freeze({
  MALE: 'MALE',
  FEMALE: 'FEMALE',
});

const GenderOptions = [
  { value: Genders.MALE, label: 'Male' },
  { value: Genders.FEMALE, label: 'Female' },
];

export const AUDIENCE_GENDER_FILTERS = ['gender', 'genderLessThan', 'genderPercentage'];

const pickFilters = (obj: any) => pick(obj, AUDIENCE_GENDER_FILTERS);

interface IProps extends IAudienceGenderFilters {
  onChange(genderFilters: IAudienceGenderFilters);
  hide?: boolean;
  hidePercentage?: boolean;
}

const AudienceGenderSection: React.FunctionComponent<IProps> = (props) => {
  const genderIndex = useMemo(() => {
    return findIndex(GenderOptions, { value: props.gender });
  }, [props.gender]);

  const handleChangeGender = (gender: TGender) => {
    const newFilters = {
      ...pickFilters(props),
      gender,
    };
    props.onChange(newFilters);
  };

  const handleChangeAudiencePercentage = (percentage: number) => {
    const newFilters = {
      ...pickFilters(props),
      genderPercentage: percentage,
    };
    props.onChange(newFilters);
  };

  const handleChangeAudienceLessThan = (lessThan: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      genderLessThan: lessThan,
    };
    props.onChange(newFilters);
  };

  const handleClear = () => {
    handleChangeGender('');
  };

  return (
    <FilterSection
      header="Gender"
      hide={props.hide}
      isSubsection={true}
      showClearButton={genderIndex >= 0}
      onClear={handleClear}
    >
      <RadioGroup
        alignment="horizontal"
        onChange={handleChangeGender}
        options={GenderOptions}
        selectedIndex={genderIndex}
      />
      {props.hidePercentage ? null : (
        <AudienceDropdown
          onChangePercentage={handleChangeAudiencePercentage}
          onChangeLessThan={handleChangeAudienceLessThan}
          selectedPercentage={props.genderPercentage}
          lessThan={props.genderLessThan}
          disabled={!props.gender}
        />
      )}
    </FilterSection>
  );
};

AudienceGenderSection.defaultProps = {
  genderLessThan: false,
  genderPercentage: 50,
  hidePercentage: false,
};

export default AudienceGenderSection;

import * as React from 'react';
import { isEmpty, isNumber } from 'lodash';

import { Toast, IToastRefHandles } from 'src/widgets/Toast';
import CreatorList from 'src/common/CreatorList';

import { IExternalVariables, ISearchResultsProps, ISearchResults } from './models';

import styles from './SearchResults.scss';

const { useEffect, useRef } = React;

const PAGE_SIZE = 20;

interface ICreatorListTitleProps {
  isLoadingFirstPage?: boolean;
  isImageSearch?: boolean;
  isFeaturedSearch?: boolean;
  query?: string;
  resultsCount?: number;
}

const CreatorListTitle: React.FunctionComponent<ICreatorListTitleProps> = React.memo((props) => {
  if (props.isLoadingFirstPage) {
    return null;
  }

  return (
    <div className={styles.CreatorListTitle}>
      {(() => {
        if (props.isImageSearch) {
          return 'Showing creators that match your image';
        } else if (props.isFeaturedSearch) {
          return 'Sample creators for your campaign';
        }
        const resultsCount =
          isNumber(props.resultsCount) && props.resultsCount > 0
            ? Number(props.resultsCount).toLocaleString()
            : 'No';
        const subject = 'creator' + (resultsCount === '1' ? '' : 's');
        const predicate = props.query ? 'that mentioned ' + props.query : '';
        return `${resultsCount} ${subject} ${predicate} found`;
      })()}
    </div>
  );
});

const EmptyMessage = React.memo(() => (
  <div className={styles.EmptyMessage}>
    <div className={styles.title}>No Creators Found</div>
    <div>Try a different search or relax your filters to see more creators!</div>
  </div>
));

export interface ISearchResultsComponentProps extends IExternalVariables, ISearchResultsProps, ISearchResults {
  query?: string;
  onSelectPage?(pageNumber: number);
}

export const SearchResults: React.FunctionComponent<ISearchResultsComponentProps> = React.memo((props) => {
  const toastRef = useRef<IToastRefHandles>();

  useEffect(() => {
    if (props.error && toastRef.current) {
      toastRef.current.showMessage({
        content: props.error.message,
        type: 'error',
      });
    }
  }, [props, props.error]);

  const handleSelectPage = (pageNumber: number) => {
    if (props.onSelectPage) {
      props.onSelectPage(pageNumber - 1);
    }
  };

  return (
    <>
      <CreatorList
        classNames={[styles.SearchResults]}
        apiEndpoint={props.apiEndpoint}
        brandId={props.brand && props.brand.id}
        campaign={props.campaign}
        org={props.organization}
        title={
          <CreatorListTitle
            isLoadingFirstPage={props.isLoading && isEmpty(props.socialAccounts)}
            isFeaturedSearch={props.isFeaturedSearch}
            isImageSearch={props.isImageSearch}
            resultsCount={props.resultsCount}
            query={props.query}
          />
        }
        emptyMessage={<EmptyMessage />}
        loadingStatus={{
          isLoading: props.isLoading,
        }}
        initialSocialAccounts={props.socialAccounts}
        isQa={props.isQa}
        $state={props.$state}
        sendBulkOffer={props.sendBulkOffer}
        exportCsv={props.exportCsv}
        reportAsIncorrect={props.reportAsIncorrect}
        showPagination={props.resultsCount ? true : false}
        onSelectPage={handleSelectPage}
        pageCount={Math.ceil(props.resultsCount / PAGE_SIZE)}
        selectedPage={props.currentPage + 1}
        onCreatorSelected={props.onCreatorSelected}
        selectedSocialAccountId={props.selectedSocialAccountId}
        showCreateFeatures={props.showCreateFeatures}
      />
      <Toast ref={toastRef} />
    </>
  );
});

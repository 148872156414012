import * as React from 'react';
import cx from 'classnames';
import { isString } from 'lodash';

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

const { useCallback, useEffect, useRef, useState } = React;
import styles from './Image.scss';

type TBaseProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

interface IImageProps extends TBaseProps {
  fallbackSrc?: string;
  onSizeDetected?(naturalWidth: number, naturalHeight: number);
  onLoad?();
  onError?();
}

export const Image: React.RefForwardingComponent<HTMLImageElement, IImageProps> = React.memo(
  React.forwardRef((props: IImageProps, forwardRef: React.RefObject<HTMLImageElement>) => {
    const {
      className,
      fallbackSrc,
      onError: onErrorProp,
      onLoad: onLoadProp,
      onSizeDetected,
      src,
      ...restProps
    } = props;

    const ref = forwardRef || React.createRef<HTMLImageElement>();

    const [isFailed, setIsFailed] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const poll = useRef<number>(null);

    /**
     * Handler for failed to load image.
     */
    const onError = useCallback(() => {
      setIsFailed(true);
      setIsCompleted(true);
      onErrorProp();
    }, [onErrorProp]);

    /**
     * Callback for when image is loaded.
     */
    const onLoad = useCallback(() => {
      setIsCompleted(true);
      onLoadProp();
    }, [onLoadProp]);

    // New src, set statuses to default
    useEffect(() => {
      setIsFailed(false);
      setIsCompleted(false);
    }, [src]);

    // trigger onLoad if image is already loaded
    // this happens because 'onLoad' handler is attached once js is loaded
    // which could happen after image is loaded for server-side rendering
    // please refer to: https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is
    useEffect(() => {
      const image = ref.current;
      if (image && image.complete) {
        if (image.naturalWidth === 0) {
          onError();
        } else if (image.complete) {
          onLoad();
        }
      }
    }, [onError, onLoad, ref]);

    // Start/clear polling to resize
    useEffect(() => {
      const imageRef = ref.current;

      if (isFailed && poll.current !== null) {
        window.clearInterval(poll.current);
      } else if (!isFailed && poll.current === null) {
        poll.current = window.setInterval(() => {
          // image could be null, when using fallback src.
          if (imageRef && imageRef.naturalWidth && imageRef.naturalHeight) {
            window.clearInterval(poll.current);
            onSizeDetected(imageRef.naturalWidth, imageRef.naturalHeight);
          }
        }, 100);
      }
    }, [isFailed, onSizeDetected, ref]);

    // in case src is not string
    const imageSrc = src || fallbackSrc;

    if (isFailed && isString(fallbackSrc)) {
      return (
        <img
          {...restProps}
          ref={ref}
          src={fallbackSrc}
          className={cx(className, styles.Image, {
            [styles.completed]: isCompleted,
          })}
        />
      );
    }

    return (
      <img
        {...restProps}
        src={imageSrc}
        ref={ref}
        onError={onError}
        onLoad={onLoad}
        className={cx(className, styles.Image, {
          [styles.completed]: isCompleted,
        })}
      />
    );
  })
);

Image.defaultProps = {
  fallbackSrc: defaultImageSrc,
  onSizeDetected: () => undefined,
  onLoad: () => undefined,
  onError: () => undefined,
};

import * as React from 'react';
import cx from 'classnames';
import { times } from 'lodash';

import { ResponsiveGrid } from 'src/widgets/ResponsiveGrid';
import {
  TotalsSection,
  PostTypeSummarySection,
  TMVSection,
  InsightSection,
  // AudienceAuthSection,
  AudienceLocationSection,
  AudienceDemoSection,
  TopPostsSection,
  TopCreatorsSection,
  ROISection,
} from './sections';
import { useAnalyzeCompare } from '../useAnalyzeCompare';

import styles from './SummaryView.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const SummaryView: React.FunctionComponent<IProps> = React.memo(props => {
  const { isComparing } = useAnalyzeCompare();
  const sectionHeights = times(isComparing ? 2 : 7, () => 420);

  const Grid = isComparing ? (
    <ResponsiveGrid className={styles.grid} heights={sectionHeights} itemMinWidth={600}>
      <AudienceDemoSection className={styles.section} />
      {/* <AudienceAuthSection className={styles.section} /> */}
      <AudienceLocationSection className={styles.section} />
    </ResponsiveGrid>
  ) : (
    <ResponsiveGrid className={styles.grid} heights={sectionHeights} itemMinWidth={600}>
      <TMVSection className={styles.section} />
      <InsightSection className={styles.section} />
      <TopPostsSection className={styles.section} />
      <TopCreatorsSection className={styles.section} />
      <AudienceDemoSection className={styles.section} />
      <AudienceLocationSection className={styles.section} />
      {/* <AudienceAuthSection className={styles.section} /> */}
      <ROISection className={styles.section} />
    </ResponsiveGrid>
  );
  return (
    <div className={cx(styles.SummaryView, props.className)}>
      <TotalsSection className={styles.totalsSection} />
      <PostTypeSummarySection className={styles.postTypeSummarySection} />
      {Grid}
    </div>
  );
});

export default SummaryView;

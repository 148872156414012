import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  isArray,
  isEmpty,
  isNumber,
  map,
  times,
  upperFirst,
} from 'lodash';

import {
  ChatIcon,
  EyeIcon,
  FavoriteIcon,
  ThumbsUpIcon,
  UndoIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Image } from 'src/widgets/Image';
import { IMasonryItemProps, MasonryGrid } from 'src/widgets/MasonryGrid';
import { Tooltip } from 'src/widgets/Tooltip';
import { ISocialPost } from 'src/common/models/socialPost';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';
import { useElementResize } from 'src/utils/hooks';
import { getSocialAccountLink } from 'src/utils/socialAccount';
import { tooltipText } from 'src/utils/tooltipsUtils';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

const {
  useLayoutEffect,
  useRef,
  useState,
} = React;
import styles from './Posts.scss';

const COLUMNS: { [network in TNetworkIdentifier]?: number } = {
  facebook: 3,
  instagram: 3,
  pinterest: 3,
  youtube: 2,
  tiktok: 3,
};
const POST_LIMIT: { [network in TNetworkIdentifier]?: number } = {
  facebook: 9,
  instagram: 9,
  pinterest: 15,
  youtube: 8,
  tiktok: 9,
};

interface IPostProps extends IMasonryItemProps {
  post: ISocialPost;
  imagePath?: string;
}

interface IPostsGridProps {
  images?: string[];
  isMasonry: boolean;
  posts: ISocialPost[];
}

type TTab = 'recent' | 'sponsored' | 'popular';

export const Posts: React.FC = () => {
  const {
    network,
    noDataPulled,
    socialAccount,
  } = useSocialProfileContext();
  const [tab, setTab] = useState<TTab>('recent');
  const recentPostsRef = useRef<HTMLDivElement>(null);
  const sponsoredPostsRef = useRef<HTMLDivElement>(null);
  const sponsoredPostsButtonRef = useRef<HTMLDivElement>(null);
  const popularPostsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // Pinterest posts are scrollable, so make sure we scroll to top
    if (network === 'pinterest' || network === 'tiktok') {
      const recentPostsElem = recentPostsRef && recentPostsRef.current;
      if (recentPostsElem) {
        recentPostsElem.scroll({ top: 0, behavior: 'smooth' });
      }
    }
  }, [network, tab]);

  const recentPosts = isArray(socialAccount.posts)
    ? socialAccount.posts
      .filter((post) => !isEmpty(post) && !isEmpty(post.image))
      .sort((a, b) => a.ts > b.ts ? -1 : 1)
    : [];
  const hasRecentPosts = recentPosts.length > 0;
  const sponsoredPosts = isArray(socialAccount.sponsored_posts)
    ? socialAccount.sponsored_posts.filter((post) => !isEmpty(post))
    : [];
  const hasSponsoredPosts = sponsoredPosts.length > 0;
  const mentionedPosts = isArray(socialAccount.mentioned_posts)
    ? socialAccount.mentioned_posts
      .filter((post) => !isEmpty(post) && !isEmpty(post.image))
      .sort((a, b) => a.ts > b.ts ? -1 : 1)
    : [];
  const hasMentionedPosts = mentionedPosts.length > 0;
  const popularPosts = isArray(socialAccount.posts)
    ? socialAccount.posts
      .filter((post) => !isEmpty(post) && !isEmpty(post.image))
      .sort((a, b) => a.view_count > b.view_count ? -1 : 1)
    : [];

  // UI
  const isMasonry = network === 'pinterest' || network === 'tiktok';
  const isCarousel = ['instagram', 'youtube'].includes(network);

  const renderCarouselButtons = () => isCarousel && (
    <div className={styles.carouselButtons}>
      <Button
        className={cx(styles.recentButton, {
          [styles.active]: tab === 'recent',
        })}
        label={hasMentionedPosts ? 'Mentioned' : 'Recent'}
        theme='light'
        onClick={() => setTab('recent')}
      />
      {network === 'instagram' && (
        <Button
          className={cx(styles.sponsoredButton, {
            [styles.active]: tab === 'sponsored',
          })}
          label='Sponsored'
          theme='light'
          onClick={() => setTab('sponsored')}
          ref={sponsoredPostsButtonRef}
        />
      )}
      {network === 'youtube' && (
        <Button
          className={cx(styles.popularButton, {
            [styles.active]: tab === 'popular',
          })}
          label='Most Popular'
          theme='light'
          onClick={() => setTab('popular')}
          ref={popularPostsRef}
        />
      )}
      <Tooltip
        placement='top'
        mountRef={sponsoredPostsButtonRef}
        tooltipColor='black'
        maxWidth={160}
      >
        {tooltipText.sponsoredPosts}
      </Tooltip>
    </div>
  );

  const renderContents = () => (
    <div
      className={cx(styles.content, {
        [styles.showRecent]: tab === 'recent',
        [styles.showSponsored]: tab === 'sponsored',
        [styles.showPopular]: tab === 'popular',
      })}
    >
      <div
        className={cx(styles.recentPosts, {
          [styles.noData]: !hasRecentPosts,
        })}
        ref={recentPostsRef}
      >
        <PostsGrid
          posts={hasMentionedPosts ? mentionedPosts : recentPosts}
          isMasonry={isMasonry}
        />
      </div>
      {network === 'instagram' && (
        <div
          className={cx(styles.sponsoredPosts, {
            [styles.noData]: !hasSponsoredPosts,
          })}
          ref={sponsoredPostsRef}
        >
          <PostsGrid
            posts={sponsoredPosts}
            isMasonry={isMasonry}
          />
        </div>
      )}
      {network === 'youtube' && (
        <div
          className={styles.popularPosts}
          ref={popularPostsRef}
        >
          <PostsGrid
            posts={popularPosts}
            isMasonry={isMasonry}
          />
        </div>
      )}
    </div>
  );

  const renderNoDataPulled = () => {
    const profileUrl = getSocialAccountLink(network, socialAccount?.username);
    return (
      <div className={styles.noDataPulled}>
        <span>
          We were not able to pull data on this creator.<br />
          {profileUrl && <>
            Please view their&nbsp;
            <a
              href={profileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              profile on {upperFirst(network)}
            </a>
            &nbsp;instead.
          </>}
        </span>
      </div>
    );
  };

  return (
    <div
      className={cx(
        styles.Posts,
        styles[network],
        {
          [styles.carousel]: isCarousel,
          [styles.masonry]: isMasonry,
          [styles.grid]: !isMasonry,
        },
      )}
    >
      {noDataPulled
        ? renderNoDataPulled()
        : <>
          {renderCarouselButtons()}
          {renderContents()}
        </>}
    </div>
  );
};

const PostsGrid: React.FC<IPostsGridProps> = (props) => {
  const { isMasonry, posts } = props;
  const { network } = useSocialProfileContext();
  const postsGridRef = useRef<HTMLDivElement>(null);
  const containerRect = useElementResize(postsGridRef);

  const limit = POST_LIMIT[network] || posts.length;
  const filteredPosts = posts.slice(0, limit);

  const renderImages = () => (
    map(filteredPosts, (post, index) => (
      <Post
        post={post}
        key={`post-${index}`}
      />
    ))
  );

  const renderFillers = () => (
    map(times(limit - filteredPosts.length), (index) => (
      <div key={`profile-image-${filteredPosts.length + index}`} />
    ))
  );

  return (
    <div
      className={cx(
        styles.PostsGrid,
        styles[network],
      )}
      ref={postsGridRef}
    >
      {isMasonry && containerRect
        ? (
          <MasonryGrid<IPostProps>
            className={styles.masonryGrid}
            columnWidth={containerRect
              ? Math.floor(containerRect.width / COLUMNS[network])
              : 140} // Approximate expected size
            itemComponent={Post}
            itemProps={filteredPosts.map((post, i) => ({
              post,
              key: `${post}-${i}`,
            }))}
          />
        )
        : <>
          {renderImages()}
          {renderFillers()}
        </>}
    </div>
  );
};

/**
 * Social post
 *
 * NOTE: Used PureComponent here for MasonryGrid
 */
class Post extends React.PureComponent<IPostProps> {
  private ref: React.RefObject<HTMLAnchorElement>;

  constructor(props: IPostProps) {
    super(props);
    this.ref = React.createRef();
  }

  private onMediaDimensionDetected = () => {
    const { onItemHeightChanged = () => undefined } = this.props;
    const node = this.ref.current;
    if (!node) {
      return;
    }
    const height = node.getBoundingClientRect().height;
    onItemHeightChanged(height);
  };

  private renderDetails = (post: ISocialPost) => {
    const network = post.network_identifier;

    const likes = post.like_count || post.stats?.likes;
    const dislikes = post.dislike_count;
    const views = post.view_count || post.stats?.impressions;
    const comments = post.comment_count || post.stats?.comments;
    const shares = post.stats?.shares;

    return (
      <div className={styles.postDetails}>
        <div>
          {(network === 'youtube' || network === 'pinterest' || network === 'tiktok') && isNumber(views) && (
            <span>
              <EyeIcon className={styles.icon} size={20} />
              {numeral(views).format('0.[0]a').toUpperCase()}
            </span>
          )}
          {isNumber(likes) && (
            <span>
              {network === 'instagram' || network === 'tiktok'
                ? <FavoriteIcon className={styles.icon} size={16} />
                : <ThumbsUpIcon className={styles.icon} size={16} />}
              {numeral(likes).format('0.[0]a').toUpperCase()}
            </span>
          )}
          {network === 'youtube' && isNumber(dislikes) && (
            <span>
              {<ThumbsUpIcon className={cx(styles.icon, styles.thumbsDown)} size={16} />}
              {numeral(dislikes).format('0.[0]a').toUpperCase()}
            </span>
          )}
          {isNumber(comments) && (
            <span>
              <ChatIcon className={styles.icon} size={16} />
              {numeral(comments).format('0.[0]a').toUpperCase()}
            </span>
          )}
          {isNumber(shares) && (
            <span>
              <UndoIcon className={styles.icon} size={18} />
              {numeral(shares).format('0.[0]a').toUpperCase()}
            </span>
          )}
        </div>
      </div>
    )
  };

  public render = () => {
    const { post } = this.props;
    const network = post.network_identifier;

    return (
      <a
        className={cx(styles.Post, styles[network])}
        href={post.link}
        title={post.text}
        target='_blank'
        rel='noopener noreferrer'
        ref={this.ref}
      >
        <Image
          className={styles.image}
          src={post.image}
          alt={post.text}
          onSizeDetected={this.onMediaDimensionDetected}
        />
        {network !== 'pinterest' && this.renderDetails(post)}
      </a>
    );
  };
}

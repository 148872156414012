import * as React from 'react';

interface ITableRowContext {
  rowId: string;
  isSelected: boolean;
  onToggleRowSelected();
}

export interface ITableCellRenderContext {
  isEditing: boolean;
  isRowSelected: boolean;
  isCellSelected: boolean;
  onToggleEditing();
  onChangeCellValue(value: any);
  onToggleRowSelected();
}

export const TableRowContext = React.createContext<ITableRowContext>(null);

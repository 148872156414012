import * as React from 'react';
import cx from 'classnames';
import { map, findIndex, slice, startCase, isEmpty } from 'lodash';

import { ICampaign } from 'src/common/models/campaign';
import {
  ImageIcon,
  VideoIcon,
  HelpIcon,
  TagIcon,
  NetworkSvgIcon,
  LockIcon,
  FlagIcon,
  BarcodeIcon,
  SearchIcon,
  FavoriteIcon,
} from 'src/icons';
import { Input, TokenInput } from 'src/widgets/Input';
import { IconSelect, Select } from 'src/widgets/Select';
import { Tooltip } from 'src/widgets/Tooltip';
import { Toggle } from 'src/widgets/Checkbox/Toggle';

import { IMediaType, INetworkOption, IPermissionLevel } from './contentFiltersModel';
import { IContentFilters, IAdditionalFilterOptions } from './redux/models';
import { permissionOptions } from './constants';

const { useMemo, useRef } = React;

import styles from './ContentFilters.scss';

const FIELD_MIN_WIDTH = 275;

interface IProps {
  filters: IContentFilters;
  campaigns: ICampaign[];
  networks: INetworkOption[];
  likedContentIds: string[];
  inputBuffer?: number;
  additionalFilterOptions?: IAdditionalFilterOptions[];

  onChange(filters: IContentFilters);
  className?: string;
}


const reusePermissions: IPermissionLevel[] = [
  { value: null, label: 'All Content' },
  ...map(permissionOptions, (label, value) => ({ label, value })),
];

const mediaTypes: IMediaType[] = [
  { value: null, icon: 'All' },
  { value: 'image', icon: <ImageIcon size={18} /> },
  { value: 'video', icon: <VideoIcon size={18} /> },
];

const HelpTooltip: React.FunctionComponent<{ tooltip: string }> = React.memo((props) => {
  const helpRef = useRef(null);
  return (
    <>
      <span ref={helpRef}>
        <HelpIcon size={22} className={styles.helpIcon} />
      </span>
      <Tooltip mountRef={helpRef} tooltipColor="black" maxWidth={320}>
        {props.tooltip}
      </Tooltip>
    </>
  );
});

const FilterLabel: React.FunctionComponent<{ label: string; tooltip?: string; icon?: JSX.Element }> = React.memo(
  (props) => (
    <div className={styles.filterLabel}>
      <span>{props.label}</span>
      {props.icon || null}
      {props.tooltip ? <HelpTooltip tooltip={props.tooltip} /> : null}
    </div>
  ),
);

const NetworkOption: React.FunctionComponent<INetworkOption> = React.memo((props) => (
  <div className={styles.networkOption}>
    <span>{props.icon}</span>
    <span className={styles.networkName}>{props.name}</span>
    {props.tooltip ? (
      <span>
        <HelpTooltip tooltip={props.tooltip} />
      </span>
    ) : null}
  </div>
));

const ContentFilters: React.FunctionComponent<IProps> = React.memo((props) => {
  const handleChangeQuery = (query: string) => {
    props.onChange({ ...props.filters, query });
  };

  const handleChangeCampaign = (campaignId: any) => {
    props.onChange({ ...props.filters, selectedCampaignId: campaignId });
  };

  const handleDynamicFilterChange = (key: string, identifier: any) => {
    props.onChange({ ...props.filters, [key]: identifier });
  };

  const handleChangeNetwork = (networkId: any) => {
    props.onChange({ ...props.filters, selectedNetworkId: networkId });
  };

  const handleChangeTags = (tags: string[]) => {
    props.onChange({ ...props.filters, selectedTags: tags });
  };

  const handleChangeSKU = (sku: string) => {
    props.onChange({ ...props.filters, sku });
  };

  const handleChangeReusePermissions = (permission: string) => {
    props.onChange({ ...props.filters, selectedPermissionLevel: permission });
  };

  const handleChangeMediaType = (mediaType: string) => {
    props.onChange({ ...props.filters, selectedMediaType: mediaType });
  };

  const handleChangeLikedPosts = (checked: boolean) => {
    props.onChange({ ...props.filters, contentListIds: checked ? props.likedContentIds : [] });
  };

  const campaignOptions = useMemo(() => {
    const result = map(props.campaigns, (campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));
    result.unshift({ value: null, label: 'All' });
    return result;
  }, [props.campaigns]);

  const networksWithAll = useMemo(() => {
    const result = slice(props.networks);
    result.unshift({
      id: null,
      name: 'All',
      icon: <NetworkSvgIcon size={18} />,
    });
    return result;
  }, [props.networks]);

  const networkOptions = useMemo(() => {
    return map(networksWithAll, (network) => ({ value: network.id, label: network.name }));
  }, [networksWithAll]);

  const selectedCampaignIndex = useMemo(() => {
    return Math.max(findIndex(campaignOptions, { value: props.filters.selectedCampaignId }), 0);
  }, [props.filters.selectedCampaignId, campaignOptions]);

  const selectedNetworkIndex = useMemo(() => {
    return Math.max(findIndex(networkOptions, { value: props.filters.selectedNetworkId }), 0);
  }, [props.filters.selectedNetworkId, networkOptions]);

  const selectedReusePermissionIndex = useMemo(() => {
    return Math.max(
      findIndex(reusePermissions, { value: props.filters.selectedPermissionLevel }),
      0,
    );
  }, [props.filters.selectedPermissionLevel]);

  const renderNetworkOption = (_, index) => <NetworkOption {...networksWithAll[index]} />;

  return (
    <div className={cx(styles.ContentFilters, props.className)}>
      <FilterLabel label="Search" />
      <Input
        onChange={handleChangeQuery}
        placeholder="Enter tags, names and more..."
        buffer={props.inputBuffer}
        value={props.filters.query}
        icon={<SearchIcon size={18} />}
      />
      {map(props.additionalFilterOptions, (option, index) => (
        <div key={index}>
          <FilterLabel label={option.title} />
          <Select
            onChange={(value) => handleDynamicFilterChange(option.filterKey, value)}
            options={option.options}
            labelIcon={<FlagIcon size={18} />}
            selectedIndex={undefined}
            round={true}
            popoverProps={{
              className: styles.dropdown,
              minWidth: FIELD_MIN_WIDTH,
            }}
          />
        </div>
      ))}
      {!props.additionalFilterOptions &&
      <>
        <FilterLabel label="Campaign(s)" />
        <Select
          onChange={handleChangeCampaign}
          options={campaignOptions}
          selectedIndex={selectedCampaignIndex}
          labelIcon={<FlagIcon size={18} />}
          popoverProps={{
            className: styles.dropdown,
            minWidth: FIELD_MIN_WIDTH,
          }}
          round={true}
        />
        <FilterLabel label="Network/Source" />
        <Select
          onChange={handleChangeNetwork}
          options={networkOptions}
          renderOptionElement={renderNetworkOption}
          labelIcon={networksWithAll[selectedNetworkIndex].icon}
          selectedIndex={selectedNetworkIndex}
          popoverProps={{
            minWidth: FIELD_MIN_WIDTH,
          }}
          round={true}
        />
      </>
      }
      <FilterLabel label="Filter by tag(s)" />
      <TokenInput
        onChange={handleChangeTags}
        tokens={props.filters.selectedTags}
        placeholder="Enter a tag..."
        icon={<TagIcon size={18} />}
        hidePopover={true}
        className={styles.tagInput}
        transformInputIntoToken={startCase}
      />
      <FilterLabel
        label="Filter by SKU"
        tooltip={`You can associate product SKU's with your \
        content by entering them when you send shipment tracking codes to creators`}
      />
      <Input
        onChange={handleChangeSKU}
        buffer={props.inputBuffer}
        value={props.filters.sku}
        placeholder="Enter SKU..."
        icon={<BarcodeIcon size={18} />}
      />
      <FilterLabel label="Re-use permissions" />
      <Select
        onChange={handleChangeReusePermissions}
        options={reusePermissions}
        selectedIndex={selectedReusePermissionIndex}
        labelIcon={<LockIcon size={18} />}
        popoverProps={{
          minWidth: FIELD_MIN_WIDTH,
        }}
        round={true}
      />
      <FilterLabel label="Media type" />
      <IconSelect
        onChange={handleChangeMediaType}
        options={mediaTypes}
        selectedOption={props.filters.selectedMediaType}
      />
      <FilterLabel label="Liked content" icon={<FavoriteIcon size={18} className={styles.favouriteIcon} />} />
      <Toggle checked={!isEmpty(props.filters.contentListIds)} onChange={handleChangeLikedPosts}/>
    </div>
  );
});

ContentFilters.defaultProps = {
  inputBuffer: 500,
};

export default ContentFilters;

import * as React from 'react';
import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const ColorAddCircleOutline = React.memo((props: ISvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="transparent" stroke="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3583 12.4459H8.75635V11.2875H11.3583V8.61426H12.5346V11.2697H15.1365V12.4459H12.5346V15.1014H11.3583V12.4459Z" fill="#3996E0" />
    </SvgIcon>
  );
});

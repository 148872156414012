import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ErrorBoundary } from 'src/utils';

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';

import { initialState } from './redux/models';
import reducer from './redux/reducer';
import pageMiddlewares from './redux/middlewares';

import WatchListPage, { IOwnProps } from './WatchListPage';

export interface IMentionedListPageProps extends IOwnProps {
  // api endpoint
  apiEndpoint: string;

  isQa: boolean;
  brand: IBrand;
  org: IOrganization;
  campaign: ICampaign;
}

const { useMemo } = React;

/**
 * @type {React.FunctionComponent}
 */
const MentionedListPageWrapper: React.FunctionComponent<IMentionedListPageProps> = React.memo(
  props => {
    const { apiEndpoint, isQa, brand, campaign, org, ...restProps } = props;
    const store = useMemo(() => {
      const middlewares = [thunk, ...pageMiddlewares];

      // only use redux-logger in development
      if (process.env.NODE_ENV !== 'production') {
        const createLogger = require('redux-logger').createLogger;

        middlewares.push(createLogger());
      }

      return createStore(
        reducer,
        {
          ...initialState,
          apiEndpoint,
          isQa,
          brand,
          campaign,
          org,
        },
        compose(applyMiddleware(...middlewares))
      );
    }, [apiEndpoint, brand, campaign, isQa, org]);

    return (
      <Provider store={store}>
        <ErrorBoundary>
          <WatchListPage {...restProps} />
        </ErrorBoundary>
      </Provider>
    );
  }
);

export default MentionedListPageWrapper;

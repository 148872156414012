import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map, mapValues, omitBy } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Tooltip } from 'src/widgets/Tooltip';

import { useAnalyze } from '../../../useAnalyze';
import { useAnalyzeCompare } from '../../../useAnalyzeCompare';
import {
  useFetchSummaryData,
  useFetchSummaryDataCompare,
} from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

const { useRef } = React;
import styles from './TotalsSection.scss';

type TDataKey =
  | 'creator_total'
  | 'member_total'
  | 'post_total'
  | 'impression_total'
  | 'reach_total'
  | 'engagement_total'
  | 'tmv_total'
  | 'roi_pct'
  | 'cost_per_engagement'
  | 'impression_cpm';
export type ITotalsData = {
  [key in TDataKey]: number;
};
const DATA_FIELD_CONFIG: {
  [key in TDataKey]: {
    name: string;
    isDollar?: boolean;
    isPercentage?: boolean;
    showDecimals?: boolean;
    tooltip?: string;
    showAspirex: boolean;
  };
} = {
  creator_total: { 
    name: 'Creators',
    showAspirex: true,
  },
  member_total: { 
    name: 'Members',
    showAspirex: true,
  },
  post_total: { 
    name: 'Posts',
    showAspirex: true,
  },
  impression_total: { 
    name: 'Impressions',
    showAspirex: true,
  },
  reach_total: {
    name: 'Reach',
    tooltip: `Estimated reach by account's number of followers at time of post`,
    showAspirex: true,
  },
  engagement_total: { 
    name: 'Engagements',
    showAspirex: true, 
  },
  tmv_total: {
    name: 'TMV',
    isDollar: true,
    tooltip:
      'Total Media Value (TMV) estimates what an equivalent advertising campaign would cost for the engagement output',
    showAspirex: true,
  },
  roi_pct: {
    name: 'ROI',
    isPercentage: true,
    tooltip: 'Return is calculated by total media value divided by cost ',
    showAspirex: true,
  },
  cost_per_engagement: {
    name: 'CPE',
    isDollar: true,
    showDecimals: true,
    tooltip: 'Cost Per Engagement (CPE) is calculated as total cost divided by number of engagements.',
    showAspirex: false,
  },
  impression_cpm: {
    name: 'Impression CPM',
    isDollar: true,
    showDecimals: true,
    tooltip: 'Impression Cost-Per-Mille (CPM) is calculated as total cost per 1000 impressions.',
    showAspirex: false,
  },
};
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const TotalsSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, setFilters, isAspirex } = useAnalyze();
    const { filters: compareFilters, isComparing } = useAnalyzeCompare();
    const filteredConfig = isAspirex ? omitBy(DATA_FIELD_CONFIG, o => !o.showAspirex) : DATA_FIELD_CONFIG

    const refs = mapValues(filteredConfig, () => useRef<HTMLDivElement>());
    const { loading, data, error } = useFetchSummaryData<ITotalsData>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/totals`,
      isAspirex,
      filters
    );

    /* eslint-disable */
    const {
      loading: loadingCompare,
      data: dataCompare,
    } = useFetchSummaryDataCompare<ITotalsData>(
      isComparing,
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/totals`,
      isAspirex,
      compareFilters
    );
    /* eslint-enable */

    const dataReturnedEmpty =
      data && data.creator_total === 0 && data.post_total === 0;

    React.useEffect(() => {
      if (!data) {
        return;
      }
      if (data.creator_total === 0 && data.post_total === 0) {
        return setFilters({ type: 'NO_DATA_RETURNED' });
      }
      setFilters({ type: 'DATA_RETURNED' });
    }, [data, setFilters]);

    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    return (
      <div className={cx(styles.TotalsSection, props.className)}>
        {loading && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.totalsRow}>
            {map(data, (value, key) => {
              const config = filteredConfig[key];
              const numberFormat = `${config?.isDollar ? '$' : ''}0,0${config?.showDecimals ? '.00' : ''}`;

              return config && (
                <div
                  ref={refs[key]}
                  key={key}
                  className={cx(styles.item, {
                    [styles.withTooltip]: config.tooltip,
                  })}
                >
                  <div className={styles.count}>
                    {numeral(value).format(numberFormat)}
                    {config.isPercentage && '%'}
                  </div>
                  {isComparing &&
                    (loadingCompare ? (
                      <LoadSpinner centered={true} />
                    ) : (
                      <div className={cx(styles.count, styles.compare)}>
                        {dataCompare &&
                          numeral(dataCompare[key]).format(numberFormat)}
                        {config.isPercentage && '%'}
                      </div>
                    ))}
                  <div className={styles.type}>{config.name}</div>
                  {config.tooltip && (
                    <Tooltip
                      mountRef={refs[key]}
                      placement="bottom"
                      tooltipColor="black"
                    >
                      <div className="tooltip_bottomLeft tooltip">
                        <span className="tooltip_txt">{config.tooltip}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

TotalsSection.defaultProps = {
  className: null,
};

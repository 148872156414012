import * as React from 'react';
import cx from 'classnames';
import { isFunction, isUndefined } from 'lodash';

import { ArrowDownFilledIcon } from 'src/icons';

const { useState } = React;
import styles from './Accordion.scss';

export interface IAccordionProps {
  className?: string;
  arrowPlacement?: 'left' | 'right';
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  label: string;
  labelClassName?: string;
  contentsClassName?: string;
  onToggle?(isOpen: boolean);
}

export const Accordion: React.FunctionComponent<IAccordionProps> = (props) => {
  const {
    arrowPlacement,
    children,
    className,
    contentsClassName,
    defaultIsOpen,
    isOpen: isOpenProp,
    label,
    labelClassName,
    onToggle,
  } = props;

  const [isOpenState, setIsOpenState] = useState(defaultIsOpen);

  const isControlled = !isUndefined(isOpenProp);
  const isOpen = isControlled ? isOpenProp : isOpenState;

  const handleClick = () => {
    if (isFunction(onToggle)) {
      onToggle(!isOpen);
    }
    if (!isControlled) {
      setIsOpenState(!isOpen);
    }
  };

  const renderArrow = () => (
    <ArrowDownFilledIcon
      className={styles.arrow}
      size={12}
    />
  );

  return (
    <div
      className={cx(className, styles.Accordion, {
        [styles.closed]: !isOpen,
        [styles.open]: isOpen,
        [styles.rightArrowPlacement]: arrowPlacement === 'right',
      })}
    >
      {/** toggle wrapper for arrow and label */}
      <span onClick={handleClick} className={styles.toggle}>
        {arrowPlacement === 'left' && renderArrow()}
        <span className={cx(styles.label, labelClassName)}>{label}</span>
        {arrowPlacement === 'right' && renderArrow()}
      </span>
      <div className={cx(styles.contents, contentsClassName)}>
        {children}
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  arrowPlacement: 'left',
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const GeoPinIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="
        M14.1716 16.6567C16.9746 15.7432 19 13.1079 19 10C19 6.13379 15.866 3 12 3C8.13403 3 5
        6.13379 5 10C5 13.1079 7.02539 15.7432 9.82837 16.6567L12 21L14.1716 16.6567ZM12 12C13.1045
        12 14 11.1045 14 10C14 8.89551 13.1045 8 12 8C10.8955 8 10 8.89551 10 10C10 11.1045 10.8955
        12 12 12Z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

import * as React from 'react';
import { map, includes, pull } from 'lodash';

import { Checkbox } from 'src/widgets/Checkbox';

import { FilterSection } from '../FilterSection';
import { ICreatorInterestFilters, ICreatorInterestOptions } from '../models';

import styles from './CreatorInterestSection.scss';

export const CREATOR_INTEREST_FILTERS = ['interests'];

interface IProps extends ICreatorInterestFilters, ICreatorInterestOptions {
  onChange(interests: string[]);
  hide?: boolean;
}

// TODO: move these values to backend
const DEFAULT_CREATOR_INTERESTS = [
  'Television & Film',
  'Music',
  'Shopping & Retail',
  'Coffee, Tea & Beverages',
  'Clothes, Shoes, Handbags & Accessories',
  'Beer, Wine & Spirits',
  'Sports',
  'Electronics & Computers',
  'Gaming',
  'Activewear',
  'Art & Design',
  'Travel, Tourism & Aviation',
  'Business & Careers',
  'Beauty & Cosmetics',
  'Healthcare & Medicine',
  'Jewellery & Watches',
  'Restaurants, Food & Grocery',
  'Toys, Children & Baby',
  'Fitness & Yoga',
  'Wedding',
  'Tobacco & Smoking',
  'Pets',
  'Healthy Lifestyle',
  'Luxury Goods',
  'Home Decor, Furniture & Garden',
  'Friends, Family & Relationships',
  'Cars & Motorbikes'
];

const CreatorInterestSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeInterests = (interest: string, checked: boolean) => {
    const newSelectedInterests = [...props.interests];
    if (checked) {
      newSelectedInterests.push(interest);
    } else {
      pull(newSelectedInterests, interest);
    }
    props.onChange(newSelectedInterests);
  };

  return (
    <FilterSection
      header="Interests"
      hide={props.hide}
      classNames={[styles.CreatorInterestSection]}
      isSubsection={true}
      collapsible={true}
    >
      <div className={styles.checkboxes}>
        {map(props.interestChoices, (interest, index) => (
          <div key={index} className={styles.column}>
            <Checkbox
              key={interest}
              className={styles.checkbox}
              label={interest}
              checked={includes(props.interests, interest)}
              onChange={(checked) => handleChangeInterests(interest, checked)}
            />
          </div>
        ))}
      </div>
    </FilterSection>
  );
};

CreatorInterestSection.defaultProps = {
  interests: [],
  interestChoices: DEFAULT_CREATOR_INTERESTS,
};

export default CreatorInterestSection;

import * as React from 'react';
import SectionDataReturnedEmpty from './SectionDataReturnedEmpty';

const ShouldSectionShow = ({ loading, data, dataReturnedEmpty, children }) => {
  if (!dataReturnedEmpty && !loading && data) {
    return <>{children}</>;
  }
  return <SectionDataReturnedEmpty dataReturnedEmpty={dataReturnedEmpty} />;
};

export default ShouldSectionShow;

import * as React from 'react';
import cx from 'classnames';

import { LazyImage } from 'src/widgets/Image';

import { ISocialPost } from 'src/common/models/socialPost';

import styles from './SocialPostTile.scss';

interface IProps {
  socialPost: ISocialPost;
  onImageLoadError(): void;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const SocialPostTile: React.FunctionComponent<IProps> = React.memo((props) => {
  const text = props.socialPost.title || props.socialPost.text || '';

  return (
    <div className={cx(styles.SocialPostTile, props.className)}>
      <LazyImage
        src={props.socialPost.image}
        className={styles.image}
        onError={props.onImageLoadError}
      />
      <div className={styles.text}>{text.length > 200 ? text.substr(0, 197) + '...' : text}</div>
    </div>
  );
});

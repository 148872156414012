import * as React from 'react';
import cx from 'classnames';

import { KeyboardArrowLeftIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Image } from 'src/widgets/Image';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './HistoryBackButton.scss';

export const HistoryBackButton: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    peekHistory,
    popHistory,
    setSocialAccount,
  } = useSocialProfileContext();

  const previousSocialAccount = peekHistory();

  return (
    <div className={cx(className, styles.HistoryBackButton)}>
      {previousSocialAccount && (
        <Button
          className={styles.backButton}
          label={<>
            <KeyboardArrowLeftIcon
              className={styles.leftArrowIcon}
              size={16}
            />
            <Image
              className={styles.image}
              src={previousSocialAccount.profile_image || previousSocialAccount.profile_picture}
              width={24}
              height={24}
            />
            Go back to @{previousSocialAccount.username}
          </>}
          onClick={() => setSocialAccount(popHistory())}
        />
      )}
    </div>
  );
};

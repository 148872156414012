import * as React from 'react';

import { IContentReview } from 'src/common/models/contentReview';
import { SubmitButton } from 'src/widgets/Button';
import { Textarea } from 'src/widgets/Textarea/Textarea';
import { IToastRefHandles, Toast } from 'src/widgets/Toast';

import styles from './RevertRejection.scss';

const { useState, useRef } = React;

interface IProps {
  className?: string;
  contentReview: IContentReview;

  revertRejection(revertReason: string): Promise<void>;
}

/**
 * @type {React.FunctionComponent}
 */
export const RevertRejection: React.FunctionComponent<IProps> = (props) => {
  const { revertRejection } = props;
  const toastRef = useRef<IToastRefHandles>();
  const [revertComment, setRevertComment] = useState('');
  const [isReverting, setIsReverting] = useState(false);

  const sendRevert = async () => {
    try {
      setIsReverting(true);
      await revertRejection(revertComment);
      setRevertComment('');
    } catch (err) {
      console.error(err);
    } finally {
      setIsReverting(false);
    }
  };

  const submitRevert = () => {
    if (isReverting) {
      return;
    } else if (revertComment === '') {
      const toast = toastRef.current;
      if (toast) {
        toast.showMessage({
          content: 'Comment for revert cannot be empty.',
          type: 'error',
        });
      }
    } else {
      sendRevert();
    }
  };

  return (
    <div className={styles.RevertRejection}>
      <Toast ref={toastRef}/>
      <div className={styles.container}>
        <div className={styles.header}>
          Revert Rejection
        </div>
        <div className={styles.commentHeader}>
          Comments
        </div>
        <div className={styles.commentContainer}>
          <Textarea
            placeholder="Enter a reason for reverting..."
            value={revertComment}
            onChange={(value: string) => setRevertComment(value)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <SubmitButton
            isSubmitting={isReverting}
            submittingLabel="Reverting.."
            label="Revert Rejection"
            onClick={submitRevert}
            theme="danger"
          >
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

import endpoints from 'src/common/config/endpoints';
import { ICommunity } from 'src/common/models/community';
import { useGet } from 'src/utils/hooks/useGet';

import { useInviteContext } from './useInviteContext';

/**
 * Fetch communities
 */
export const useCommunities = () => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const fetchCommunitiesURL = apiEndpoint && clientId
    ? `${apiEndpoint}/${endpoints.communitiesEndpoint}?client_id=${clientId}`
    : null;

  const {
    loading: isFetching,
    data: communities,
    error,
  } = useGet<ICommunity[]>({ url: fetchCommunitiesURL });

  return {
    communities: Object.freeze(communities),
    isFetching,
    error,
  };
};

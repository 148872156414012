import { assign, find, unionBy, reject } from 'lodash';
import { SavedSearchesActionTypes, ISavedSearchesAction } from './savedSearchesActions';
import { ISavedSearches } from '../models';

const initialState: ISavedSearches = {
  savedSearches: [],
  isDeleting: false,
  isSaving: false,
};

export const savedSearchesReducer: Redux.Reducer<ISavedSearches> = (
  state = initialState,
  action: ISavedSearchesAction,
) => {
  switch (action.type) {
    case SavedSearchesActionTypes.FETCH_SEARCHES_SUCCESS:
      return {
        ...state,
        savedSearches: action.payload.savedSearches,
      };

    case SavedSearchesActionTypes.SAVE_SEARCH_REQUEST:
      return {
        ...state,
        isSaving: true,
      };

    case SavedSearchesActionTypes.DELETE_SEARCH_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };

    case SavedSearchesActionTypes.SAVE_SEARCH_SUCCESS: {
      const savedSearch = find(state.savedSearches, { id: action.payload.savedSearch.id });
      const mergedSearch = assign({}, savedSearch, action.payload.savedSearch);
      return {
        ...state,
        savedSearches: unionBy([mergedSearch], state.savedSearches, 'id'),
        selectedSearch: mergedSearch,
        isSaving: false,
        toast: {
          type: 'info',
          content: `${mergedSearch.name} saved`,
        },
      };
    }

    case SavedSearchesActionTypes.SAVE_SEARCH_FAILURE:
    case SavedSearchesActionTypes.DELETE_SEARCH_FAILURE:
    case SavedSearchesActionTypes.FETCH_SEARCHES_FAILURE:
      return {
        ...state,
        isSaving: false,
        toast: {
          type: 'error',
          content: action.meta.errorMessage,
        },
      };

    case SavedSearchesActionTypes.SELECT_SAVED_SEARCH:
      return {
        ...state,
        selectedSearch: action.payload.savedSearch,
      };

    case SavedSearchesActionTypes.DELETE_SEARCH_SUCCESS: {
      return {
        ...state,
        savedSearches: reject(state.savedSearches, { id: action.payload.savedSearch.id }),
        isDeleting: false,
        toast: {
          type: 'info',
          content: `${action.payload.savedSearch.name} has been deleted`,
        },
      };
    }

    default:
      return state;
  }
};

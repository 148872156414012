import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { IToastMessage } from 'src/widgets/Toast';
import { IAdvertiserAccountAccessRequirement } from 'src/common/models/advertiserAccountAccessRequirement';
import endpoints from 'src/common/config/endpoints';
import { getErrorMessageFromResponse } from 'src/common/utils/getErrorMessageFromResponse';
import { IStore, ISendAccessPayload } from './models';
import { selectionLimitReachedSelector, publishersSelectedForUseSelector } from './selectors';

export enum ActionTypes {
  FETCH_AGENCY_REQUIREMENTS_REQUEST = '@advertiserAccessPage/FETCH_AGENCY_REQUIREMENTS/REQUEST',
  FETCH_AGENCY_REQUIREMENTS_SUCCESS = '@advertiserAccessPage/FETCH_AGENCY_REQUIREMENTS/SUCCESS',
  FETCH_AGENCY_REQUIREMENTS_FAILURE = '@advertiserAccessPage/FETCH_AGENCY_REQUIREMENTS/FAILURE',

  UPDATE_SELECTED_FOR_USE_REQUEST = '@advertiserAccessPage/UPDATE_SELECTED_FOR_USE_REQUEST/REQUEST',
  UPDATE_SELECTED_FOR_USE_SUCCESS = '@advertiserAccessPage/UPDATE_SELECTED_FOR_USE_REQUEST/SUCCESS',
  UPDATE_SELECTED_FOR_USE_FAILURE = '@advertiserAccessPage/UPDATE_SELECTED_FOR_USE_REQUEST/FAILURE',

  CREATE_ACCESS_REQUEST = '@advertiserAccessPage/CREATE_ACCESS/REQUEST',
  CREATE_ACCESS_SUCCESS = '@advertiserAccessPage/CREATE_ACCESS/SUCCESS',
  CREATE_ACCESS_FAILURE = '@advertiserAccessPage/CREATE_ACCESS/FAILURE',

  SEND_ACCESS_REQUEST = '@advertiserAccessPage/SEND_ACCESS/REQUEST',
  SEND_ACCESS_SUCCESS = '@advertiserAccessPage/SEND_ACCESS/SUCCESS',
  SEND_ACCESS_FAILURE = '@advertiserAccessPage/SEND_ACCESS/FAILURE',
}

export interface IAdvertiserAccessPageAction {
  type: ActionTypes;
  payload?: {
    requirements?: IAdvertiserAccountAccessRequirement[];
    currentMonthlyUsage?: number;
    accessId?: number;
    accessSent?: ISendAccessPayload;
    publisherId?: number;
    selectedForUse?: boolean;
  };
  meta?: {
    error?: Error;
    errorMessage?: string;
    toast?: IToastMessage;
  };
}

type AAPThunkAction = ThunkAction<void, IStore, unknown, IAdvertiserAccessPageAction>;
export type AAPThunkDispatch = ThunkDispatch<IStore, unknown, IAdvertiserAccessPageAction>;

export const fetchAgencyRequirements = (): AAPThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { apiEndpoint, brand } = state;

  dispatch({ type: ActionTypes.FETCH_AGENCY_REQUIREMENTS_REQUEST });

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.advertiserAccessEndpoint}?brand_id=${brand.id}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ActionTypes.FETCH_AGENCY_REQUIREMENTS_SUCCESS,
        payload: {
          requirements: json.data.requirements,
          currentMonthlyUsage: json.data.advertiser_access_current_monthly_usage,
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.FETCH_AGENCY_REQUIREMENTS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const updateSelectedForUse = (
  publisherId: number,
  selectedForUse: boolean,
): AAPThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectionLimitReached = selectionLimitReachedSelector(state);

    if (selectionLimitReached && selectedForUse) {
      setTimeout(() => {
        dispatch({
          type: ActionTypes.UPDATE_SELECTED_FOR_USE_FAILURE,
          meta: {
            toast: {
              content: 'You have reached your monthly quota for advertiser access.',
              type: 'info',
            },
          },
        });
      }, 300);

      return;
    }

    dispatch({
      type: ActionTypes.UPDATE_SELECTED_FOR_USE_REQUEST,
      payload: {
        publisherId,
        selectedForUse,
      },
    });

    const { apiEndpoint, brand } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.advertiserAccessEndpoint}/select_for_use`,
        {
          method: 'POST',
          body: JSON.stringify({
            brand_id: brand.id,
            publisher_ids: publishersSelectedForUseSelector(getState()),
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch({
          type: ActionTypes.UPDATE_SELECTED_FOR_USE_SUCCESS,
          payload: {
            requirements: json.data,
          },
        });
      } else {
        throw new Error(getErrorMessageFromResponse(json));
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_SELECTED_FOR_USE_FAILURE,
        payload: {
          publisherId,
          selectedForUse: !selectedForUse,
        },
        meta: {
          error: err,
          errorMessage: err.message,
          toast: {
            content: 'There was an error while trying to update access to creator.',
            type: 'error',
          },
        },
      });
    }
  };
};

export const createAccess = (): AAPThunkAction => async (dispatch, getState) => {
  const state: IStore = getState();
  const { apiEndpoint, brand } = state;

  dispatch({ type: ActionTypes.CREATE_ACCESS_REQUEST });

  try {
    const resp = await fetch(`${apiEndpoint}/${endpoints.advertiserAccessEndpoint}`, {
      method: 'POST',
      body: JSON.stringify({
        brand_id: brand.id,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      const proposalId: number = json.data.id;

      dispatch({
        type: ActionTypes.CREATE_ACCESS_SUCCESS,
        payload: {
          accessId: proposalId,
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.CREATE_ACCESS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const sendAccess = (emails: string[], note: string): AAPThunkAction => async (
  dispatch,
  getState,
) => {
  const state: IStore = getState();
  const { apiEndpoint, accessId } = state;

  dispatch({ type: ActionTypes.SEND_ACCESS_REQUEST });

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.advertiserAccessEndpoint}/${accessId}/invite`,
      {
        method: 'POST',
        body: JSON.stringify({
          email_addresses: emails,
          note,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ActionTypes.SEND_ACCESS_SUCCESS,
        payload: {
          accessSent: {
            emails,
            note,
          },
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.SEND_ACCESS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

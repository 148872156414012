import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty, isNumber } from 'lodash';

import {
  ArrowLeftIcon,
  DownloadIcon,
} from 'src/icons';
import { IconButton } from 'src/widgets/Button';
import { Image } from 'src/widgets/Image';
import { Overlay } from 'src/widgets/Overlay';

import { TContentSize } from './Content/Content';
import { ImageContent } from './Content/ImageContent';
import { VideoContent } from './Content/VideoContent';
import { VideoContentDetail } from './Content/VideoContentDetail';
import { ApplicationContent } from './Content/ApplicationContent';
import { YouTubeContent } from './Content/YouTubeContent';
import { BlogContent } from './Content/BlogContent';

import { IContent } from './contentPreviewerModel';

import getFilenameFromUrl from 'src/common/utils/getFilenameFromUrl';
import { getDownloadableMedia, getPreviewMedia } from './utils/getContentImage';

import styles from './ContentPreviewer.scss';

interface IProps {
  contents: IContent[];

  allowExpanding?: boolean;
  allowDownload?: boolean;
  showContentInfo?: boolean;

  containerWidth?: number;
  enableDetailView?: boolean;

  classNames?: string[];
}

type TDefaultProp =
  | 'allowExpanding'
  | 'allowDownload'
  | 'showContentInfo'
  | 'containerWidth'
  | 'enableDetailView'
  | 'classNames';

interface IState {
  showDetailView: boolean;
  activeIndex: number;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ContentPreviewer extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    allowExpanding: false,
    containerWidth: null,

    showContentInfo: true,
    allowDownload: true,

    classNames: [],
    enableDetailView: false,
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    if (props.allowExpanding && !isNumber(props.containerWidth)) {
      throw new Error(
        `'containerWidth' cannot be ${props.containerWidth} when 'allowExpanding' is true.`,
      );
    }

    this.state = {
      showDetailView: false,
      activeIndex: null,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames, contents } = this.props;
    const { showDetailView } = this.state;

    if (!contents || contents.length === 0) {
      return null;
    }

    return (
      <div className={cx(classNames.concat(styles.ContentPreviewer))}>
        {this.renderGridView()}
        <Overlay
          show={showDetailView}
          onRequestClose={this.hideDetailView}
          closeOnBackdropClick={true}
          className={cx(classNames.concat(styles.CarouselOverlay))}
        >
          {this.renderDetailView()}
        </Overlay>
      </div>
    );
  }

  /**
   * @private
   * Renders the detail view.
   *
   * @return {JSX}
   */
  private renderDetailView = () => {
    const { contents, showContentInfo, allowDownload } = this.props;
    const { activeIndex } = this.state;

    const content = contents[activeIndex];
    if (!content) {
      return;
    }

    const downloadable = allowDownload && !['youtube', 'blog'].includes(content.type);

    return (
      <div className={styles.detailView} onClick={this.hideDetailView}>
        <div className={styles.contentWrapper}>
          <div
            className={cx(styles.actionButton, {
              [styles.disabled]: activeIndex === 0,
            })}
            onClick={this.goToPrev}
          >
            <ArrowLeftIcon />
          </div>
          <div className={styles.itemWrapper} onClick={this.onContentClick}>
            {this.renderDetailItem(content)}
          </div>
          <div
            className={cx(styles.actionButton, {
              [styles.disabled]: activeIndex >= contents.length - 1,
            })}
            onClick={this.goToNext}
          >
            <ArrowLeftIcon className={styles.rightArrow} />
          </div>
        </div>
        {showContentInfo && (
          <div className={styles.fileName}>
            {downloadable ? getFilenameFromUrl(content.src) : content.src}
            {downloadable && (
              <IconButton
                className={styles.downloadButton}
                icon={<DownloadIcon />}
                theme="light"
                onClick={this.downloadFile.bind(this, getDownloadableMedia(content))}
              />
            )}
          </div>
        )}
        {!isEmpty(content.text) && <div className={styles.text}>{content.text}</div>}
        <div className={styles.indexList}>
          {map(contents, (_, index) => (
            <div
              key={index}
              className={cx(styles.indexItem, {
                [styles.active]: index === activeIndex,
              })}
            />
          ))}
        </div>
      </div>
    );
  };

  /**
   * @private
   * Renders the carousel content item using given content config.
   *
   * @param {IContent} content the content config.
   *
   * @return {JSX}
   */
  private renderDetailItem = (content: IContent) => {
    switch (content.type) {
      case 'video': {
        return (
          <VideoContentDetail
            key={content.src}
            src={content.src}
            showDuration={true}
            controls={true}
            className={styles.item}
          />
        );
      }

      case 'image': {
        return <Image src={getPreviewMedia(content)} className={styles.item} />;
      }

      // hide blog/youtube items
      default: {
        return null;
      }
    }
  };

  /**
   * @private
   * Renders the grid view.
   *
   * @return {JSX}
   */
  private renderGridView = () => {
    const { contents, containerWidth, allowExpanding } = this.props;

    let style;
    let contentSize: TContentSize = 'small';
    if (allowExpanding) {
      if (contents.length === 1) {
        contentSize = 'large';
        style = {
          width: `${containerWidth}px`,
          height: `${containerWidth}px`,
        };
      } else if (contents.length <= 4) {
        contentSize = 'medium';
        style = {
          margin: `${0.025 * containerWidth}px`,
          width: `${0.45 * containerWidth}px`,
          height: `${0.45 * containerWidth}px`,
        };
      } else {
        contentSize = 'small';
        style = {
          margin: `${0.016 * containerWidth}px`,
          width: `${0.3 * containerWidth}px`,
          height: `${0.3 * containerWidth}px`,
        };
      }
    }

    return (
      <div className={styles.gridView}>
        {map(contents, (content, index) => (
          <div key={index} className={styles.gridItem} style={style}>
            {this.renderGridItem(index, content, contentSize)}
          </div>
        ))}
      </div>
    );
  };

  /**
   * @private
   * Renders the content item using given content config.
   *
   * @param {IContent} content the content config.
   *
   * @return {JSX}
   */
  private renderGridItem = (index: number, content: IContent, size: TContentSize) => {
    const { enableDetailView, showContentInfo, allowDownload } = this.props;

    switch (content.type) {
      case 'image': {
        return (
          <ImageContent
            content={content}
            downloadable={allowDownload}
            showInfo={showContentInfo}
            size={size}
            showZoom={enableDetailView}
            onZoomClick={this.showDetailView.bind(this, index)}
          />
        );
      }

      case 'video': {
        return (
          <VideoContent
            content={content}
            downloadable={allowDownload}
            showInfo={showContentInfo}
            size={size}
            showZoom={enableDetailView}
            onZoomClick={this.showDetailView.bind(this, index)}
          />
        );
      }

      case 'application': {
        return (
          <ApplicationContent
            content={content}
            downloadable={allowDownload}
            showInfo={showContentInfo}
            size={size}
            showZoom={enableDetailView}
            onZoomClick={this.showDetailView.bind(this, index)}
          />
        );
      }

      case 'blog': {
        return <BlogContent content={content} size={size} showExternalLink={true} />;
      }

      case 'youtube': {
        return <YouTubeContent content={content} size={size} showExternalLink={true} />;
      }

      default: {
        return null;
      }
    }
  };

  /**
   * @private
   * Shows the carousel view, also sets the active index.
   *
   * @param {Number} activeIndex the active index of carousel item.
   */
  private showDetailView = (activeIndex: number) => {
    const { enableDetailView } = this.props;
    if (!enableDetailView) {
      return;
    }

    this.setState({
      showDetailView: true,
      activeIndex,
    });
  };

  /**
   * @private
   * Hides the carousel view.
   */
  private hideDetailView = () => {
    this.setState({
      showDetailView: false,
      activeIndex: null,
    });
  };

  private onContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  private goToNext = (event: React.MouseEvent<HTMLDivElement>) => {
    const { contents } = this.props;
    const { activeIndex } = this.state;

    event.stopPropagation();
    event.preventDefault();

    this.setState({
      activeIndex: Math.min(contents.length - 1, activeIndex + 1),
    });
  };

  private goToPrev = (event: React.MouseEvent<HTMLDivElement>) => {
    const { activeIndex } = this.state;

    event.stopPropagation();
    event.preventDefault();

    this.setState({
      activeIndex: Math.max(0, activeIndex - 1),
    });
  };

  private downloadFile = (src: string, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const a = document.createElement('a');
    document.body.appendChild(a);

    a.download = getFilenameFromUrl(src);
    a.href = src;
    a.click();

    document.body.removeChild(a);
  };
}

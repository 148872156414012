import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { CloseIcon } from 'src/icons';
import {
  Button,
  SubmitButton,
} from 'src/widgets/Button';
import { Modal } from 'src/widgets/Modal';
import { Toast, IToastRefHandles } from 'src/widgets/Toast';

import { IFavoriteListPage } from './redux/favoriteListPageModel';
import favoriteListPageActions, { FLPThunkDispatch } from './redux/favoriteListPageActions';

import styles from './MultiDeleteButton.scss';

interface IOwnProps {
  elementIds: number[];
  onDeleteElements();

  classNames?: string[];
}
interface IDispatchProps {
  deleteElements(elementIds: number[]): Promise<void>;
}
type IProps = IOwnProps & IDispatchProps;
type TDefaultProp = 'classNames';
interface IState {
  // add to list popover
  showConfirmationModal: boolean;
  isDeleting: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
class MultiDeleteButton extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();

    this.state = {
      showConfirmationModal: false,
      isDeleting: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { elementIds, classNames } = this.props;
    const { showConfirmationModal, isDeleting } = this.state;

    return (
      <React.Fragment>
        <Button
          label="Remove"
          icon={<CloseIcon size={18} />}
          className={cx(classNames.concat(styles.MultiDeleteButton))}
          theme="info"
          disabled={isEmpty(elementIds)}
          onClick={this.openConfirmationModal}
        />
        <Modal
          show={showConfirmationModal}
          onRequestClose={this.closeConfirmationModal}
          title="Delete Creators"
          className={styles.ConfirmationModal}
          footer={
            <React.Fragment>
              <Button
                label="Cancel"
                theme="light"
                className={cx(styles.button, styles.left)}
                onClick={this.closeConfirmationModal}
                disabled={isDeleting}
              />
              <SubmitButton
                label="Yes, delete"
                submittingLabel="Deleting..."
                isSubmitting={isDeleting}
                theme="danger"
                className={styles.button}
                onClick={this.deleteElementsMulti}
                disabled={isDeleting}
              />
            </React.Fragment>
          }
        >
          <div className={styles.modalContent}>
            <div className={styles.text}>
              Are you sure you want to delete
              <span className={styles.count}> {elementIds.length} </span>
              creator{elementIds.length > 1 ? 's' : ''} from list?
            </div>
          </div>
        </Modal>
        <Toast ref={this.toastRef} />
      </React.Fragment>
    );
  }

  private openConfirmationModal = () => {
    this.setState({
      showConfirmationModal: true,
    });
  };

  private closeConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
    });
  };

  private deleteElementsMulti = () => {
    const { elementIds, deleteElements, onDeleteElements } = this.props;

    this.setState({
      isDeleting: true,
    });
    deleteElements(elementIds)
      .then(() => {
        const toast = this.toastRef.current;
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Deleted <span> {elementIds.length} </span>
                creator{elementIds.length > 1 ? 's' : ''} from list.
              </div>
            ),
          });

          this.setState({
            isDeleting: false,
            showConfirmationModal: false,
          });
        }

        // notify parent, remove selected rows
        onDeleteElements();
      })
      .catch(() => {
        const toast = this.toastRef.current;
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to delete the creators from list.',
            type: 'error',
          });

          this.setState({
            isDeleting: false,
            showConfirmationModal: false,
          });
        }
      });
  };
}

const mapStateToProps = (_: IFavoriteListPage, ownProps: IOwnProps): IOwnProps => {
  return {
    elementIds: ownProps.elementIds,
    classNames: ownProps.classNames,

    onDeleteElements: ownProps.onDeleteElements,
  };
};
const mapDispatchToProps = (dispatch: FLPThunkDispatch): IDispatchProps => {
  return {
    deleteElements: (...args) =>
      dispatch(favoriteListPageActions.deleteElementsFromFavoriteList(...args)),
  };
};

export default connect<unknown, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MultiDeleteButton);

import * as React from 'react';
import classnames from 'classnames';
import { forEach, map, size, zip } from 'lodash';
import { format, differenceInCalendarDays } from 'date-fns';

import { IGroupContentReviewMessage, IContentReview } from 'src/common/models/contentReview';
import { IManager } from 'src/common/models/manager';
import { IBrand } from 'src/common/models/brand';
import {
  isRejected,
  isApproved,
  isCompleted,
  isGCRPending,
  isSoftRejected,
  isSoftApproved,
} from 'src/common/utils/contentReviewUtils';
import {
  AlertIcon,
  CheckCircleIcon,
  InfoIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowLeftIcon,
  MessageIcon,
  XCircleIcon,
} from 'src/icons';
import {
  Button,
  IconButton,
  SubmitButton,
} from 'src/widgets/Button';
import { ContentPreviewer } from 'src/widgets/ContentPreviewer';
import {
  AvatarIcon,
  IconSize,
  TooltipIcon,
} from 'src/widgets/Icon';
import { Image } from 'src/widgets/Image';
import { Modal } from 'src/widgets/Modal';
import { Notice } from 'src/widgets/Notice';
import { Popover } from 'src/widgets/Popover';

import { ChatBox } from '../ChatBox/ChatBox';
import { ApproveContent } from '../ApproveContent/ApproveContent';
import { RejectContent } from '../RejectContent/RejectContent';
import { RequestNewContentModal } from '../RequestNewContentModal/RequestNewContentModal';
import { RequestNewContentManaged } from '../RequestNewContentManaged/RequestNewContentManaged';
import { RevertRejection } from '../RevertRejection/RevertRejection';
import { NetworkIcon } from 'src/common/NetworkIcon';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';
import { hasBrandPermission } from '../../utils/managerUtils';
import { getNumberOfUnreadMessages } from '../../utils/contentReviewUtils';

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

const { useState, useRef } = React;

import styles from './ContentReviewItem.scss';
const cx = classnames.bind(styles);

interface IProps {
  user: IManager;
  hasAdminAccess: boolean;
  isBrandUser: boolean;
  isSuperBrandUser: boolean;
  brand: IBrand;
  numBrandUsers: number;
  contentReview: IContentReview;
  isDetailedView: boolean;

  // action
  onContentReviewSelected(contentReviewId: number);

  approveContentReview(
    contentReview: IContentReview,
    approveComment: string,
  ): Promise<void>;

  mtRejectContentReview(
    contentReview: IContentReview,
    rejectedGuidelines: boolean[],
    rejectComment: string,
    bypass: boolean,
  ): Promise<void>;

  brandRejectContentReview(
    contentReview: IContentReview,
    rejectedGuidelines: boolean[],
    rejectComment: string,
  ): Promise<void>;

  commentContentReview(
    contentReview: IContentReview,
    feedback: IGroupContentReviewMessage,
  ): Promise<void>;

  revertReject(
    contentReview: IContentReview,
    revertComment: string,
  ): Promise<void>;

  readBrandComments(contentReview: IContentReview);

  viewRelationship(relationId: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const ContentReviewItem: React.FunctionComponent<IProps> = (props) => {
  const {
    user,
    hasAdminAccess,
    contentReview,
    isDetailedView,
    onContentReviewSelected,
    approveContentReview,
    mtRejectContentReview,
    brandRejectContentReview,
    revertReject,
    commentContentReview,
    numBrandUsers,
    isBrandUser,
    isSuperBrandUser,
    brand,
    readBrandComments,
    viewRelationship,
  } = props;

  const popoverAnchorRef = useRef<HTMLDivElement>(null);
  const approvePopoverAnchorRef = useRef<HTMLDivElement>(null);
  const rejectPopoverAnchorRef = useRef<HTMLDivElement>(null);
  const revertRejectPopoverAnchorRef = useRef<HTMLDivElement>(null);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isApproveFormOpen, setIsApproveFormOpen] = useState(false);
  const [isRevertContainerOpen, setIsRevertContainerOpen] = useState(false);
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const [isRequestNewContentFormOpen, setIsRejectNewContentFormOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const [chatBoxHeight, setChatBoxHeight] = useState('auto');

  const hasWritePermissions = hasBrandPermission(user, brand.id, 3);
  const hasCommentPermissions = hasBrandPermission(user, brand.id, 2);

  let dateSubmitted = format(new Date(contentReview.date_first_upload * 1000), 'MM/dd/yy');
  let dateUploadedLanguage = `Uploaded ${dateSubmitted}`;

  if (contentReview.gcr_brand_has_rejected && contentReview.date_resubmitted) {
    dateSubmitted = format(new Date(contentReview.date_resubmitted * 1000), 'MM/dd/yy');
    dateUploadedLanguage = `Re-submitted ${dateSubmitted}`;
  }

  const daysTillAutoApprove = () => {
    if (contentReview.automatic_approval_expire_time_ts) {
      return differenceInCalendarDays(
        new Date(contentReview.automatic_approval_expire_time_ts * 1000),
        new Date(),
      );
    }
    return null;
  };

  const approve = async (brandApproveComment: string): Promise<void> => {
    try {
      setIsApproving(true);
      await approveContentReview(contentReview, brandApproveComment);
    } catch (err) {
      console.error(err);
    } finally {
      setIsApproving(false);
      setIsApproveFormOpen(false);
    }
  };

  const renderMessageIcon = () => {
    const numUnreadMessages = getNumberOfUnreadMessages(contentReview, user);
    if (numUnreadMessages > 0) {
      const badgeText = numUnreadMessages > 9 ? '9+' : numUnreadMessages.toString();
      return <MessageIcon badgeText={badgeText}/>;
    } else {
      return <MessageIcon/>;
    }
  };

  const brandReject = async (
    brandGuidelineCheckoff: boolean[],
    brandRejectComment: string,
  ): Promise<void> => {
    setIsRejecting(true);
    try {
      await brandRejectContentReview(contentReview, brandGuidelineCheckoff, brandRejectComment);
      setIsRejecting(false);
      setIsRejectFormOpen(false);
    } catch (err) {
      setIsRejecting(false);
      throw err;
    }
  };

  const mtReject = async (
    brandGuidelineCheckoff: boolean[],
    brandRejectComment: string,
  ): Promise<void> => {
    try {
      await mtRejectContentReview(contentReview, brandGuidelineCheckoff, brandRejectComment, isSuperBrandUser);
      setIsRejectNewContentFormOpen(false);
    } catch (err) {
      console.error('Unable to reject content');
      throw err;
    }
  };

  const handleRevertReject = async (comment: string): Promise<void> => {
    try {
      await revertReject(contentReview, comment);
      setIsRevertContainerOpen(false);
    } catch (err) {
      console.error('Unable to revert reject');
      throw err;
    }
  };

  const comment = async (feedback: IGroupContentReviewMessage): Promise<void> => {
    setIsCommenting(true);
    try {
      await commentContentReview(contentReview, feedback);
      setIsCommenting(false);
    } catch (err) {
      setIsCommenting(false);
      throw err;
    }
  };

  const getActionButton = () => {
    if ((!isBrandUser && !hasAdminAccess) && (isGCRPending(contentReview) || isSoftApproved(contentReview))) {
      return <Button label="Pending..." disabled={true} className={styles.buttonStyle}/>;
    } else if (
      isRejected(contentReview) ||
      isSoftRejected(contentReview) ||
      isSoftApproved(contentReview)
    ) {
      return (
        <Notice
          type="alert"
          className={cx(styles.notice, styles.buttonStyle)}
          icon={<InfoIcon className={styles.rejectedBadgeFont}/>}
        >
          <div className={styles.rejectedBadgeFont}>Changes Requested</div>
        </Notice>
      );
    } else if (isApproved(contentReview) || isCompleted(contentReview)) {
      // TODO: make this button greyed out
      return (
        <Notice
          type="success"
          className={cx(styles.notice, styles.buttonStyle)}
          icon={<CheckCircleIcon className={styles.approvedBadgeFont}/>}
        >
          <div className={styles.approvedBadgeFont}>Approved</div>
        </Notice>
      );
    } else {
      return (
        <Button
          label="Review"
          className={styles.buttonStyle}
          onClick={() => onContentReviewSelected(contentReview.id)}
          icon={<KeyboardArrowDownIcon size={14} className={styles.arrowDownIcon}/>}
          iconSide="right"
        />
      );
    }
  };

  const renderOverview = () => {
    const content = contentReview.content;
    const media = content.media || content.saved_media_list;
    let thumbnailMediaUrl = defaultImageSrc;
    if (media && media.length > 0) {
      thumbnailMediaUrl = media[0].url;
    }

    return (
      <div
        className={cx(styles.overviewContainer, {
          [styles.noActionAvailable]: !isBrandUser && isCompleted(contentReview),
        })}
      >
        <div className={styles.collapseButtonContainer}>
          <IconButton
            icon={
              <KeyboardArrowLeftIcon
                className={cx(styles.icon, {
                  [styles.collapsed]: !isDetailedView,
                })}
              />
            }
            className={styles.collapseButton}
            onClick={() => onContentReviewSelected(contentReview.id)}
          />
        </div>
        <Image src={thumbnailMediaUrl} className={styles.thumbnailImage}/>
        <NetworkIcon
          className={styles.networkIcon}
          identifier={getNetworkByPostType(contentReview.product.post_type)}
          size={IconSize.SMALL}
        />
        <div className={styles.infoActionContainer}>
          <div className={styles.contentReviewInfo}>
            <div className={styles.contentReviewDescription}>
              {contentReview.product.product_description}
            </div>
            <div className={styles.contentReviewUploaded}>{dateUploadedLanguage}</div>
          </div>
          <div className={styles.autoApproveTimer}>
            {contentReview.automatic_approval_expire_time_ts && (
              <>
                <AlertIcon className={styles.alertIcon} size={20}/>
                Auto-approved in {daysTillAutoApprove()} days
              </>
            )}
          </div>
          <div className={styles.accountContainer}>
            <div className={styles.accountImage}>
              <AvatarIcon imageUrl={contentReview.publisher_profile_image} size={IconSize.SMALL}/>
            </div>
            {contentReview.publisher_username}
          </div>
          <div className={styles.actionContainer}>
            <IconButton
              ref={popoverAnchorRef}
              icon={renderMessageIcon()}
              onClick={() => setIsChatOpen(!isChatOpen)}
              className={styles.chatButton}
            />
            {getActionButton()}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const mediaList = contentReview.content.media || contentReview.content.saved_media_list;
    const previewMap = contentReview.content.preview_map || {};
    const contentList = [];
    forEach(mediaList, (media) => {
      if (
        !['aiq_preview_resolution_original', 'aiq_preview_resolution'].includes(media.resolution)
      ) {
        contentList.push({
          type: media.media_type,
          src: media.url,
          downloadableUrl: media.url,
          previewUrl: (previewMap[media.url] || {}).aiq_preview_resolution_original || media.url,
          thumbnailUrl: (previewMap[media.url] || {}).aiq_preview_resolution || media.url,
          text: contentReview.content.caption || '',
        });
      }
    });
    return contentList;
  };

  const renderGuidelines = () => {
    let guidelineCheckoff = contentReview.product.brand_guideline_checkoff;
    if (isBrandUser || isSoftRejected(contentReview)) {
      guidelineCheckoff = contentReview.hybrid_rejected_guidelines;
    }

    return map(
      zip(contentReview.product.checkoff_guidelines, guidelineCheckoff),
      ([guideline, checkoff], index) => (
        <div key={index} className={styles.guideline}>
          {checkoff ? (
            <XCircleIcon className={cx(styles.guidelineIcon, styles.xCircleIcon)}/>
          ) : (
            <CheckCircleIcon className={cx(styles.guidelineIcon, styles.circleIcon)}/>
          )}
          {guideline}
        </div>
      ),
    );
  };

  const getBrandRejectedGuidelines = () => {
    return (
      <ul>
        {
          map(contentReview.hybrid_rejected_guidelines, (checked, index) => {
            if (checked) {
              return <li>{contentReview.product.checkoff_guidelines[index]}</li>
            }
          })
        }
      </ul>
    );
  };

  const renderDetailedView = () => {
    return (
      <div className={styles.detailedContainer}>
        {(isSuperBrandUser || hasAdminAccess) && isGCRPending(contentReview) && (
          <div className={styles.infoActionContainer}>
            <div className={styles.infoContainer}>
              <div className={styles.infoContainerHeader}>
                Is this content ready to be posted?
              </div>
              <div className={styles.infoContainerInfo}>
                Approve the content to mark it as ready to be posted. You can add a comment while approving.
                <br />
                You can also reject this content with a comment and the guideline(s) not met.
              </div>
            </div>
            <div className={styles.actionContainer}>
              <div className={styles.actionButtonContainer} ref={approvePopoverAnchorRef}>
                <SubmitButton
                  className={cx(styles.actionButton)}
                  label="Approve"
                  isSubmitting={isApproving}
                  submittingLabel="Approving..."
                  onClick={() => setIsApproveFormOpen(!isApproveFormOpen)}
                />
              </div>
              <div className={styles.actionButtonContainer} ref={rejectPopoverAnchorRef}>
                <SubmitButton
                  className={cx(styles.actionButton)}
                  label="Reject"
                  theme="danger"
                  isSubmitting={isRejecting}
                  submittingLabel="Rejecting..."
                  onClick={() => setIsRejectFormOpen(!isRejectFormOpen)}
                />
              </div>
            </div>
            <div className={styles.warningContainer}>
              <div className={styles.warningText}>
                Your approval or rejection will be passed directly to the creator
              </div>
              <div className={styles.warningTooltip}>
                <TooltipIcon
                  icon={<InfoIcon className={styles.infoTooltip}/>}
                  tooltipText={`You have been assigned Manager permissions by ${brand.name}.
                  This gives you the ability to send your approvals and rejections directly to the creator.`}
                />
              </div>
            </div>
          </div>
        )}
        {!isSuperBrandUser && isBrandUser && hasWritePermissions && isGCRPending(contentReview) && (
          <div className={styles.infoActionContainer}>
            <div className={styles.infoContainer}>
              <div className={styles.infoContainerHeader}>
                Is this content ready to be posted?
              </div>
              <div className={styles.infoContainerInfo}>
                Approve the content to mark it as ready to be posted. You can add a comment while approving.
                <br />
                You can also reject this content with a comment and the guideline(s) not met.
              </div>
            </div>
            <div className={styles.actionContainer}>
              <div className={styles.actionButtonContainer} ref={approvePopoverAnchorRef}>
                <SubmitButton
                  className={cx(styles.actionButton)}
                  label="Approve"
                  isSubmitting={isApproving}
                  submittingLabel="Approving..."
                  onClick={() => setIsApproveFormOpen(!isApproveFormOpen)}
                />
              </div>
              <div className={styles.actionButtonContainer} ref={rejectPopoverAnchorRef}>
                <SubmitButton
                  className={cx(styles.actionButton)}
                  label="Reject"
                  theme="danger"
                  isSubmitting={isRejecting}
                  submittingLabel="Rejecting..."
                  onClick={() => setIsRejectFormOpen(!isRejectFormOpen)}
                />
              </div>
            </div>
          </div>
        )}
        {(isBrandUser || hasAdminAccess) && isSoftRejected(contentReview) && (
          <div className={styles.revertSoftRejectContainer}>
            <div className={styles.revertContainer}>
              <div className={styles.revertHeader}>
                This content was rejected with the following notes:
              </div>
              <div className={styles.revertRejectComment}>
                <div className={styles.revertCommentHeader}>
                  Rejection comment:
                </div>
                <div className={styles.revertComment}>
                  {contentReview.hybrid_rejected_feedback}
                </div>
              </div>
              <div className={styles.revertGuidelinesContainer}>
                <div className={styles.revertGuidelinesHeader}>
                  Guideline(s) not met:
                </div>
                <div className={styles.revertGuidelines}>
                  {getBrandRejectedGuidelines()}
                </div>
              </div>
              <div className={styles.revertActionContainer}>
                <div className={styles.revertRejectButton}>
                  <Button
                    label="Revert Rejection"
                    onClick={() => setIsRevertContainerOpen(!isRevertContainerOpen)}
                    ref={revertRejectPopoverAnchorRef}
                  />
                  <Popover
                    mountRef={revertRejectPopoverAnchorRef}
                    show={isRevertContainerOpen}
                    showArrow={false}
                    onRequestClose={() => setIsRevertContainerOpen(false)}
                    height={500}
                  >
                    <RevertRejection
                      contentReview={contentReview}
                      revertRejection={handleRevertReject}
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        )}
        {(!isBrandUser || hasAdminAccess) && (isSoftRejected(contentReview) || isRejected(contentReview)) && (
          <div className={styles.managedRequestNewContentContainer}>
            <RequestNewContentManaged
              contentReview={contentReview}
              handleRequestNewContent={() => setIsRejectNewContentFormOpen(!isRequestNewContentFormOpen)}
              viewRelationship={viewRelationship}
            />
          </div>
        )}
        {contentReview.content.link &&
        <a target="_blank" rel="noopener noreferrer" href={contentReview.content.link}>
          {contentReview.content.link}
        </a>
        }
        <div className={styles.contentContainer}>
          <ContentPreviewer contents={renderContent()} enableDetailView={true}/>
        </div>
        <div className={styles.caption}>{contentReview.content.caption}</div>
        <div className={styles.contentGuidelines}>
          {size(contentReview.product.checkoff_guidelines) > 0 && (
            <>
              <div className={styles.guidelineHeader}>Guidelines for this content</div>
              {renderGuidelines()}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderRequestNewContentModal = () => {
    return (
      <Modal
        show={isRequestNewContentFormOpen}
        title="Request New Content"
        width={1100}
        onRequestClose={() => setIsRejectNewContentFormOpen(false)}
      >
        <RequestNewContentModal contentReview={contentReview} rejectContent={mtReject}/>
      </Modal>
    );
  };

  const renderApproveForm = () => {
    return (
      <Popover
        mountRef={approvePopoverAnchorRef}
        show={isApproveFormOpen}
        showArrow={false}
        onRequestClose={() => setIsApproveFormOpen(false)}
      >
        <ApproveContent submitApprove={approve} />
      </Popover>
    );
  };

  const renderRejectForm = () => {
    return (
      <Popover
        mountRef={rejectPopoverAnchorRef}
        show={isRejectFormOpen}
        showArrow={false}
        onRequestClose={() => setIsRejectFormOpen(false)}
      >
        <RejectContent contentReview={contentReview} submitReject={isSuperBrandUser ? mtReject : brandReject}/>
      </Popover>
    );
  };

  const renderChatBox = () => {
    return (
      <Popover
        className={styles.chatBoxPopover}
        contentClassName={styles.chatBoxPopoverContent}
        mountRef={popoverAnchorRef}
        show={isChatOpen}
        showArrow={false}
        onRequestClose={() => setIsChatOpen(false)}
        onHeightUpdate={(height) => setChatBoxHeight(`${height}px`)}
        height={400}
      >
        <ChatBox
          className={styles.chatBox}
          height={chatBoxHeight}
          user={user}
          contentReview={contentReview}
          numBrandUsers={numBrandUsers}
          isBrandUser={isBrandUser}
          messages={contentReview.group_content_review_messages}
          isCommenting={isCommenting}
          canComment={hasWritePermissions || hasCommentPermissions}
          addComment={comment}
          readComments={() => readBrandComments(contentReview)}
          isVisible={isChatOpen}
        />
      </Popover>
    );
  };

  return (
    <div className={styles.ContentReviewItem}>
      {renderOverview()}
      {renderChatBox()}
      {renderRequestNewContentModal()}
      {isBrandUser && renderApproveForm()}
      {isSuperBrandUser && renderApproveForm()}
      {isBrandUser && renderRejectForm()}
      {isSuperBrandUser && renderRejectForm()}
      {isDetailedView && renderDetailedView()}
    </div>
  );
};

import * as React from 'react';
import cx from 'classnames';
import { GmailIcon, OutlookIcon } from 'src/icons';

import styles from './EmailIcon.scss';

const AccountTypes = Object.freeze({
  GMAIL: 'gmail',
  OUTLOOK: 'outlook',
});

interface IProps {
  size?: number;
  type: string;
  className?: string;
}

export const EmailIcon: React.FC<IProps> = React.memo((props) => {
  const className = cx(styles.EmailIcon, props.className);

  switch (props.type) {
    case AccountTypes.GMAIL:
      return (
        <div className={className}>
          <GmailIcon size={props.size} />
        </div>
      );

    case AccountTypes.OUTLOOK:
      return (
        <div className={className}>
          <OutlookIcon size={props.size} />
        </div>
      );

    default:
      return null;
  }
});

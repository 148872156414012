import * as React from 'react';
import cx from 'classnames';
import {
  isArray,
  isEmpty,
  map,
} from 'lodash';

import { BarChart } from 'src/widgets/charts/BarChart';

import { Card } from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './CountryCard.scss';

interface IProps {
  className?: string;
  /**
   * Number of countries to be displayed (including "other")
   */
  maxRecords?: number;
}

export const CountryCard: React.FC<IProps> = (props) => {
  const {
    className,
    maxRecords,
  } = props;
  const {
    hasData,
    socialAccount,
  } = useSocialProfileContext();

  const countryData = (
    hasData &&
    !isEmpty(socialAccount.demographics_report) &&
    isArray(socialAccount.demographics_report.country)
  )
    ? socialAccount.demographics_report.country
    : [];
  const hasCountryData = countryData.length > 0;

  return (
    <Card
      className={cx(className, styles.CountryCard, {
        [styles.noData]: !hasCountryData,
      })}
      header="Audience Country"
    >
      {hasCountryData && (
        <BarChart
          className={styles.audienceLocationBarChart}
          data={map(countryData, (country: [string, number]) => ({
            label: country[0],
            value: country[1] * 100, // Percent value
          }))}
          maxRecords={maxRecords}
          height={200} // Shows 5 (see maxRecords in BarChartHorizontal.tsx)
          showRawValue={false}
        />
      )}
    </Card>
  );
};

import * as React from 'react';
import { findIndex } from 'lodash';

import { RadioGroup } from 'src/widgets/RadioGroup';
import { TGender } from 'src/common/models/searchParams';
import { FilterSection } from '../FilterSection';

import { ICreatorGenderFilters } from '../models';

const GENDER_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

const { useMemo } = React;

export const CREATOR_GENDER_FILTERS = ['gender'];

interface IProps extends ICreatorGenderFilters {
  onChangeGender(value: TGender);
  hide?: boolean;
}

const CreatorGenderSection: React.FunctionComponent<IProps> = (props) => {
  const selectedIndex = useMemo(() => {
    if (props.gender) {
      return findIndex(GENDER_OPTIONS, { value: props.gender });
    } else {
      return 0;
    }
  }, [props.gender]);

  return (
    <FilterSection header="Gender" hide={props.hide} isSubsection={true}>
      <RadioGroup
        alignment="horizontal"
        options={GENDER_OPTIONS}
        onChange={props.onChangeGender}
        selectedIndex={selectedIndex}
      />
    </FilterSection>
  );
};

CreatorGenderSection.defaultProps = {
  gender: '',
};

export default CreatorGenderSection;

import * as React from 'react';

import { Image } from 'src/widgets/Image';

import { Content, IContentProps } from './Content';

const ASSETS = process.env.ASSETS;
const defaultBlogImage = `${ASSETS}/blog_image.png`;

import styles from './BlogContent.scss';

/**
 * @class
 * @extends {React.Component}
 */
export class BlogContent extends React.PureComponent<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames, ...props } = this.props;

    return (
      <Content
        content={content}
        classNames={classNames.concat(styles.BlogContent)}
        downloadable={false}
        showInfo={true}
        {...props}
      >
        <Image src={defaultBlogImage} className={styles.image} />
      </Content>
    );
  }
}

const styles = {
  title: 'color: #4CAF50;font-weight: bold',
  name: 'color: #03A9F4;font-weight: bold;',
  time: 'color: #9E9E9E;font-weight: normal;',
};
const repeat = (str, times) => new Array(times + 1).join(str);
const pad = (num, maxLength) => repeat('0', maxLength - num.toString().length) + num;
const formatTime = (time) =>
  `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(
    time.getMilliseconds(),
    3,
  )}`;

/**
 * Adds an event log with given name and parameters.
 *
 * @param {String} eventName the event name.
 * @param {Object} parameters the parameters.
 */
export default function(eventName: string, parameters?: Record<string, any>) {
  if (window.addEventLog) {
    try {
      window.addEventLog(eventName, parameters);
    } catch (err) {
      console.log(`Failed to add event log for '${eventName}'`);
    }
  } else {
    console.group(
      `addEventLog %c${eventName} %c@ ${formatTime(new Date())}`,
      `${styles.name}`,
      `${styles.time}`,
    );
    console.log(`%c params:`, `${styles.title}`);
    console.log(parameters);
    console.groupEnd();
  }
}

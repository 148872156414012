import * as React from 'react';
import qs from 'qs';
import { filter, flatten, forEach, isArray, map } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { IMemberProgram } from 'src/common/models/program';
import { useGet } from 'src/utils/hooks/useGet';

import { useInviteContext } from './useInviteContext';
import { IInviteProps } from '../Invite';
import { getSocialAccountsFromProspects } from '../utils';

const { useEffect } = React;

/**
 * Fetch programs for a specific member
 * All are mapped within `memberPrograms` by respective usernames
 */
export const useUpdateMemberPrograms = (member: IInviteProps['prospect']) => {
  const socialAccounts = isArray(member)
    ? getSocialAccountsFromProspects(...member)
    : getSocialAccountsFromProspects(member);
  const usernames = map(socialAccounts, (socialAccount) => socialAccount.username);

  const {
    apiEndpoint,
    clientId,
    updateFetchingMemberPrograms,
    updateMemberPrograms,
  } = useInviteContext();

  const fetchMemberProgramsURL: string = (() => {
    if (usernames.length > 0) {
      updateFetchingMemberPrograms(...usernames);
      const query = {
        client_id: clientId,
        network_type: socialAccounts[0].network_identifier,
        usernames,
      };
      const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
      return `${apiEndpoint}/${endpoints.programs}/for_social_accounts?${queryString}`;
    }
  })();

  const {
    loading: isFetchingMemberPrograms,
    data: fetchedMemberPrograms,
    error: fetchMemberProgramsError,
    refetch: refetchMemberPrograms,
  } = useGet<IMemberProgram[][]>({ url: fetchMemberProgramsURL });

  useEffect(() => {
    if (isArray(fetchedMemberPrograms)) {
      forEach(usernames, (username) => {
        updateMemberPrograms(
          username,
          filter(flatten(fetchedMemberPrograms), (p) => p.username === username),
        );
      });
    }
  }, [fetchedMemberPrograms]);

  return {
    isFetching: isFetchingMemberPrograms,
    error: fetchMemberProgramsError,
    refetchMemberPrograms,
  };
};

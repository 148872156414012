import * as React from 'react';
import cx from 'classnames';

import styles from './LinkButton.scss';

type TBaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, LinkButtonComponent & HTMLDivElement>;

export interface ILinkButtonProps extends TBaseProps {
  disabled?: boolean;
  forwardedRef?: React.RefObject<HTMLDivElement>;
}

type TDefaultProp = 'disabled' | 'forwardedRef';

// Allow passing ref from parent
export const LinkButton = React.forwardRef((props: ILinkButtonProps, ref: React.RefObject<HTMLDivElement>) => {
  return <LinkButtonComponent {...props} forwardedRef={ref} />;
});

/**
 * @class
 * @extends {React.Component}
 */
class LinkButtonComponent extends React.PureComponent<ILinkButtonProps> {
  public static defaultProps: Pick<ILinkButtonProps, TDefaultProp> = {
    disabled: false,
  };

  private ref: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  public constructor(props: ILinkButtonProps) {
    super(props);

    this.ref = props.forwardedRef || React.createRef();
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      disabled,
      children,
      className,
      forwardedRef, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...props
    } = this.props;

    return (
      <div
        className={cx(styles.LinkButton, className, {
          [styles.disabled]: disabled,
        })}
        ref={this.ref}
        {...props}
      >
        {children}
      </div>
    );
  }
}

import * as React from 'react';
import { map, filter, isEmpty } from 'lodash';
import cx from 'classnames';

import { SearchIcon, SpinnerIcon } from 'src/icons';
import { Checkbox } from 'src/widgets/Checkbox';
import { Input } from 'src/widgets/Input';
import { Popover } from 'src/widgets/Popover';
import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../useAnalyze';
import { fetchFilterData } from '../useFetchFilterData';
import { filtersToQueryString } from '../../useFetchAnalyzeData';

import styles from '../Filters.scss';
const { useRef, useState, useEffect } = React;

export const OwnerFilter = ({ filters, setFilters, removeFilter, isDisabled }) => {
  const [open, setOpen] = useState(true);
  const { apiEndpoint } = useAnalyze();
  const ownerState = filters.owners;
  const [loading, setLoading] = useState(isEmpty(ownerState.owners));

  const url =
    `${apiEndpoint}/${endpoints.reportsEndpoint}/filter/owners` +
    '?' +
    filtersToQueryString({ brands: filters.brands });
  useEffect(() => {
    setLoading(true);
    fetchFilterData(url)
      .then(owners => owners.map(email => ({ email, selected: false })))
      .then(owners => {
        setFilters({ type: 'LOAD_OWNER_VALUES', payload: owners });
        setLoading(false);
      })
      .catch(error =>
        setFilters({
          type: 'ERROR_LOADING_API',
          payload: { error, filter: 'Owner' },
        })
      );
  }, [filters.brands, setFilters, apiEndpoint, url]);

  const [textValue, setTextValue] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  // This filters the results to only the ones it should display
  // based on the text typed in the search bar.
  /*eslint-disable */
  const toDisplay =
    textValue === ''
      ? ownerState.owners
      : filter(ownerState.owners, creator =>
          creator.email.toLowerCase().includes(textValue.toLowerCase())
        );
  /*eslint-enable */
  console.log({ ownerState });
  const pillCircleDisplay =
    ownerState.selectedCount === 0 ? '' : ownerState.selectedCount;
  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {loading ? <SpinnerIcon /> : pillCircleDisplay}
        </span>
        <span className={styles.filterText}>Owner</span>
        <span
          className={cx(styles.filterCircle, styles.right)}
          onClick={() => !isDisabled && removeFilter('owner')}
        >
          ×
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Input
          type="text"
          value={textValue}
          icon={<SearchIcon />}
          placeholder="Search..."
          buffer={300}
          onChange={setTextValue}
          className={styles.input}
        />
        <ul className={styles.MenuList}>
          <li
            className={styles.MenuItem}
            onClick={() => setFilters({ type: 'CLICK_CLEAR_ALL_OWNERS' })}
          >
            Clear All
          </li>
          {map(toDisplay, (owner, idx) => (
            <li
              className={styles.MenuItem}
              key={`${owner.name}-${idx}`}
              onClick={() =>
                setFilters({
                  type: 'CLICK_OWNER',
                  payload: { idx, ...owner },
                })
              }
            >
              <Checkbox
                checked={owner.selected}
                onClick={() =>
                  setFilters({
                    type: 'CLICK_OWNER',
                    payload: { idx, ...owner },
                  })
                }
              />
              {owner.email}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
};

export const ownerReducer = (state, action) => {
  const localState = state.owners;
  switch (action.type) {
    case 'LOAD_OWNER_VALUES': {
      return {
        owners: action.payload,
        selectedCount: 0,
      };
    }
    case 'REMOVE_ADDITIONAL_FILTER': {
      if (action.payload === 'owner') {
        return { owners: [], selectedCount: 0 };
      }
      return localState;
    }
    case 'CLICK_CLEAR_ALL_OWNERS': {
      return {
        ...localState,
        selectedCount: 0,
        owners: map(localState.owners, owner => ({
          ...owner,
          selected: false,
        })),
      };
    }
    case 'CLICK_OWNER': {
      const ownerPayload = action.payload;
      return {
        ...localState,
        selectedCount: ownerPayload.selected
          ? localState.selectedCount - 1
          : localState.selectedCount + 1,
        owners: map(localState.owners, owner =>
          owner.email === action.payload.email
            ? { ...owner, selected: !owner.selected }
            : owner
        ),
      };
    }
    default: {
      return localState;
    }
  }
};

const colors = [ 
  '#3996E0', 
  '#7FD4F4', 
  '#EA92AF', 
  '#EACD60', 
  '#2E426D', 
  '#70397C', 
  '#EF5682', 
  '#C6549E',
  '#A988B0', 
  '#FFB286', 
  '#659661', 
  '#D6F1DF', 
  '#2E426D', 
  '#C5850E', 
  '#F2E1A0',
];

export default colors;

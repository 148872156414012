import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { SettingsIcon } from 'src/icons';
import { IconButton } from 'src/widgets/Button';
import { Checkbox } from 'src/widgets/Checkbox';
import { Popover } from 'src/widgets/Popover';

import { TableContext } from './tableContext';

const { useRef, useState, useCallback, useContext } = React;
import styles from './ColumnConfig.scss';

export interface IShowColumns {
  [column: string]: boolean;
}
interface IProps {
  onChange(showColumns: IShowColumns): void;

  className?: string;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export const ColumnConfig: React.FunctionComponent<IProps> = React.memo((props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const tableContext = useContext(TableContext);
  const { columns, showColumns } = tableContext;

  const openMenu = useCallback(() => {
    console.log('open');

    setShowMenu(true);
  }, []);
  const closeMenu = useCallback(() => setShowMenu(false), []);

  /**
   * @private
   * Shows/hides a column and notifies the parent.
   *
   * @param {String} column the column field.
   * @param {Boolean} show whether should show the column.
   */
  const handleShowColumnChange = (column: string, show: boolean) => {
    const { onChange } = props;
    const { showColumns } = tableContext;

    const newShowColumns = {
      ...showColumns,
      [column]: show,
    };

    // notifies the parent
    onChange(newShowColumns);
  };

  return (
    <div className={cx(styles.ColumnConfig, props.className)}>
      <IconButton
        onClick={openMenu}
        ref={buttonRef}
        icon={<SettingsIcon />}
        className={cx(styles.button, {
          [styles.active]: showMenu,
        })}
      />
      <Popover mountRef={buttonRef} show={showMenu} onRequestClose={closeMenu}>
        {map(columns, (column) => (
          <div key={column.field}>
            <Checkbox
              label={column.headerName}
              checked={showColumns[column.field]}
              onChange={(checked) => handleShowColumnChange(column.field, checked)}
            />
          </div>
        ))}
      </Popover>
    </div>
  );
});

ColumnConfig.defaultProps = {
  className: null,
};

import * as React from 'react';
import cx from 'classnames';
import { map, each } from 'lodash';

import { POST_TYPE_NAME } from 'src/common/models/postType';
import { IPieChartProps, PieChart } from 'src/widgets/charts/PieChart/PieChart';

import { ITMVData } from './TMVSection';

const { useMemo } = React;
import styles from './GraphView.scss';

interface IProps {
  className?: string;
  data: ITMVData[];
  pieChartProps?: Partial<IPieChartProps>;
}
interface IColorMap {[key: string]: string}
/**
 * @type {React.FunctionComponent}
 */
const colorMap: IColorMap = {
  instagram: '#ea92ce',
  instagram_story: '#EA92AF',
  instagram_video: '#e3059c',
  youtube_mention: '#2E426D',
  youtube_dedicated: '#2E426D',
  youtube: '#2E426D',
  facebook_post: '#70397C',
  facebook: '#70397C',
  pinterest: '#7FD4F4',
  blog_mention: '#EACD60',
  blog_dedicated: '#EACD60',
  blog: '#EACD60',
  twitter: '#3996E0',
  twitter_post: '#3996E0',
  tiktok_video: '#A7F5F3',
}

export const GraphView: React.FunctionComponent<IProps> = React.memo(props => {
  const {
    className,
    data,
    pieChartProps = {},
  } = props;

  const records = useMemo(
    () =>
      map(data, record => {
        let tmv = 0;
        each(record.insights, insight => {
          tmv += insight.count * insight.multiplier;
        });

        return {
          label: POST_TYPE_NAME[record.post_type],
          value: tmv,
          color: [colorMap[record.post_type]]
        };
      }),
    [data]
  );

  return (

    <div className={cx(styles.GraphView, className)}>
      <PieChart
        data={records}
        showTotal={true}
        {...pieChartProps}
      />
    </div>
  );
});

GraphView.defaultProps = {
  data: [],
  pieChartProps: {},
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const XCircleIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" fill="#F1515F" />
    <path
      fill="white"
      d="M12.8711 12L16.48 8.39111C16.7289 8.14222 16.7289 7.76888 16.48 7.51999C16.2311 7.27111 15.8578 7.27111 15.6089 7.51999L12 11.1289L8.39109 7.51999C8.1422 7.27111 7.76887 7.27111 7.51998 7.51999C7.27109 7.76888 7.27109 8.14222 7.51998 8.39111L11.1289 12L7.51998 15.6089C7.27109 15.8578 7.27109 16.2311 7.51998 16.48C7.64442 16.6044 7.79998 16.6667 7.95554 16.6667C8.11109 16.6667 8.26665 16.6044 8.39109 16.48L12 12.8711L15.6089 16.48C15.7333 16.6044 15.8889 16.6667 16.0444 16.6667C16.2 16.6667 16.3555 16.6044 16.48 16.48C16.7289 16.2311 16.7289 15.8578 16.48 15.6089L12.8711 12Z"
    />
  </SvgIcon>
));

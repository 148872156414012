export function largeNumberAbbreviator(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num }
  if (num == null) {
    return '0';
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num.toFixed(0);
}

export function percentage(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num }
  return Math.round(num) + '%';
}

export function dollar(num) {
  num = parseInt(num, 10);
  if (isNaN(num)) { return num }
  return '$' + num.toFixed(2);
}

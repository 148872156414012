import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { map, size, isEmpty, toLower, includes } from 'lodash';

import {
  SpinnerIcon,
  SearchIcon,
  LayoutGridIcon,
} from 'src/icons';
import { IconButton } from 'src/widgets/Button';
import { LazyImage } from 'src/widgets/Image';
import { InfiniteList } from 'src/widgets/InfiniteList';
import { Input } from 'src/widgets/Input';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { IAdvertiserPublisherRelation } from 'src/common/models/advertiserPublisherRelation';

import isUnread from './utils/isUnread';
import removeEmails from './utils/removeEmails';

const { useState, useMemo } = React;
import styles from './InboxTableMobile.scss';

export interface IInboxTableMobileProps {
  $state: any;
  isLoading: boolean;
  relations: IAdvertiserPublisherRelation[];
  shouldRemoveEmails: boolean;
  onToggleFilters(): void;

  title?: string;
  className?: string;
}

interface IRowData {
  id: number;
  image: string;
  imageFallback: string;
  name: string;
  message: string;
  lastUpdate: number;
  read: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
const RowItem: React.FunctionComponent<{
  data: IRowData;
  onRowClicked(): void;
  visible?: boolean;
}> = React.memo(({ data, onRowClicked, visible = true }) => {
  return (
    <div
      key={data.id}
      className={cx(styles.item, {
        [styles.read]: data.read,
      })}
      onClick={onRowClicked}
    >
      {visible && (
        <>
          <LazyImage src={data.image} fallbackSrc={data.imageFallback} className={styles.image} />
          <div className={styles.info}>
            <div className={styles.name}>{data.name}</div>
            <div className={styles.message}>
              {data.message.length > 40 ? data.message.substr(0, 37) + '...' : data.message}
            </div>
          </div>
          <div className={styles.date}>{format(data.lastUpdate * 1000, 'MMM dd')}</div>
        </>
      )}
    </div>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const InboxTableMobile: React.FunctionComponent<IInboxTableMobileProps> = React.memo(
  (props) => {
    const { title, isLoading, relations, shouldRemoveEmails } = props;
    const [filter, setFilter] = useState('');
    const shouldShowLoading = isLoading && isEmpty(relations);

    const handleRowClicked = (relationId) => {
      props.$state.go('manage.relationship.main.mainView', {
        relationId,
      });
    };

    const rows: IRowData[] = useMemo(() => {
      return map(relations, (relation) => ({
        id: relation.id,
        image: relation[relation.adjacent_type].profile_picture,
        imageFallback: relation[relation.adjacent_type].automatic_profile_image_url,
        name: relation[relation.adjacent_type].display_name,
        message:
          (shouldRemoveEmails ? removeEmails(relation.latest_message) : relation.latest_message) ||
          '-',
        lastUpdate: relation.last_update,
        read: !isUnread(relation),
      })).filter((row) => {
        if (isEmpty(filter)) {
          return true;
        }

        return (
          includes(toLower(row.message), toLower(filter)) ||
          includes(toLower(row.name), toLower(filter))
        );
      });
    }, [relations, filter, shouldRemoveEmails]);

    return (
      <div className={cx(styles.InboxTableMobile, props.className)}>
        <div className={styles.header}>
          <IconButton
            icon={<LayoutGridIcon />}
            onClick={props.onToggleFilters}
            tooltip="Show Filters"
            className={styles.toggle}
          />
          <Input
            icon={<SearchIcon size={15} />}
            placeholder="Search..."
            onChange={setFilter}
            theme="info"
            className={styles.input}
          />
        </div>
        <div className={styles.title}>
          {isLoading && <SpinnerIcon size={16} className={styles.spinner} />}
          <span>{title}</span>
          <span className={styles.count}>{size(rows)}</span>
        </div>
        {shouldShowLoading && <LoadSpinner />}
        {!shouldShowLoading && (
          <InfiniteList className={styles.list}>
            {map(rows, (row) => (
              <RowItem key={row.id} data={row} onRowClicked={() => handleRowClicked(row.id)} />
            ))}
          </InfiniteList>
        )}
        {!shouldShowLoading && isEmpty(rows) && (
          <Notice type="disabled" className={styles.noResultNotice}>
            {isEmpty(filter)
              ? 'There are no records.'
              : `There are no records that match the applied filter "${filter}"`}
          </Notice>
        )}
      </div>
    );
  },
);

InboxTableMobile.defaultProps = {
  title: 'In Progress',
};

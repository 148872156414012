import { isNumber } from 'lodash';

import { TTooltipPlacement } from 'src/widgets/Tooltip';

import sassVariables from '../../styles/_variables.scss';

export type TIconSize = number | IconSize;

/**
 * Base Icon props
 */
export interface IIconProps {
  className?: string;
  /**
   * Badge color in hex value (See styles/_colors.scss)
   */
  badgeColor?: string;
  /**
   * Badge text
   */
  badgeText?: string;
  /**
   * Icon height in px or `IconSize`
   */
  size?: TIconSize;
  /**
   * Tooltip placement
   */
  tooltipPlacement?: TTooltipPlacement;
  /**
   * Tooltip text when hovering on the `AvatarIcon`
   */
  tooltipText?: string;
}

/**
 * Icon sizing, where the values are the corresponding CSS classes
 */
export enum IconSize {
  /**
   * EXTRA_SMALL = 16px
   */
  EXTRA_SMALL = 'extraSmall',
  /**
   * SMALL = 24px
   */
  SMALL = 'small',
  /**
   * MEDIUM = 40px
   */
  MEDIUM = 'medium',
  /**
   * LARGE = 64px
   */
  LARGE = 'large',
  /**
   * EXTRA_LARGE = 128px
   */
  EXTRA_LARGE = 'extraLarge',
}

/**
 * Helper function for getting CSS sizing based on provided size
 * @param size Size in pixels or IconSize
 */
export const getStyleDimensionsFromSize = (size: TIconSize): React.CSSProperties => {
  const sizeValue = isNumber(size) ? `${size}px` : sassVariables[size + 'IconSize'];
  return {
    width: sizeValue,
    height: sizeValue,
  };
};

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { last, isEmpty } from 'lodash';

import { Histogram } from 'src/widgets/charts';
import { ErrorBoundary } from 'src/utils';
import { DistributionUnit } from './DistributionUnit';

import { TDataField, dataFieldConfig } from './models/channel';
import { IRawMetricsData } from './models/channel';
import { IHistogramData } from './models/histogram';

import processMetricsData from './utils/processMetricsData';

import styles from './ContentDetailPage.scss';

interface IProps {
  histogramData?: IRawMetricsData[];
  rawChannelData?: IRawMetricsData[];
  channelInfo?: any[];
  dataFieldInfo?: any[];
}
type TDefaultProp = 'histogramData' | 'rawChannelData' | 'channelInfo' | 'dataFieldInfo';
interface IState {
  data: IHistogramData[];
  selectedField: TDataField;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ContentDetailPage extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    histogramData: [],
    rawChannelData: [],
    channelInfo: [],
    dataFieldInfo: [],
  };

  /**
   * @inheritdoc
   */
  public constructor(props: IProps) {
    super(props);

    this.state = {
      data: null,
      selectedField: 'ecv',
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps) {
    const { histogramData } = nextProps;

    if (!isEmpty(histogramData)) {
      return {
        data: processMetricsData(histogramData),
      };
    }

    return null;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { channelInfo, dataFieldInfo, rawChannelData } = this.props;
    const { selectedField } = this.state;

    return (
      <div className={styles.ContentDetailPage}>
        <ErrorBoundary>{this.renderHistogram()}</ErrorBoundary>
        <ErrorBoundary>
          <DistributionUnit
            rawData={rawChannelData}
            selectedField={selectedField}
            channelInfo={channelInfo}
            dataFieldInfo={dataFieldInfo}
          />
        </ErrorBoundary>
      </div>
    );
  }

  private renderHistogram = () => {
    const { data, selectedField } = this.state;
    if (isEmpty(data)) {
      return <div className={styles.message}>No histogram data available.</div>;
    }

    const spendSum = last(data).spend;
    const ecvSum = last(data).ecv;
    const contentSum = last(data).content_count;
    const impressionSum = last(data).impressions;

    const selectedData = data.map((d) => ({
      x: d.ts,
      y: d[selectedField],
    }));

    return (
      <React.Fragment>
        <div className={styles.list}>
          <div
            onClick={this.onFieldSelected.bind(this, 'ecv')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'ecv',
            })}
          >
            <div className={styles.value}>{numeral(ecvSum).format('$0,0')}</div>
            <div className={styles.name}>Estimated Content Value</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'impressions')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'impressions',
            })}
          >
            <div className={styles.value}>{numeral(impressionSum).format('0,0')}</div>
            <div className={styles.name}>Total Impressions</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'spend')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'spend',
            })}
          >
            <div className={styles.value}>{numeral(spendSum).format('$0,0')}</div>
            <div className={styles.name}>Estimated Cost</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'content_count')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'content_count',
            })}
          >
            <div className={styles.value}>{numeral(contentSum).format('0,0')}</div>
            <div className={styles.name}>Content Created</div>
          </div>
        </div>
        <Histogram
          title={dataFieldConfig[selectedField].displayName}
          classNames={[styles.histogram]}
          height={400}
          isDollar={selectedField === 'ecv' || selectedField === 'spend'}
          data={selectedData}
        />
      </React.Fragment>
    );
  };

  private onFieldSelected = (selectedField: TDataField) => {
    this.setState({
      selectedField,
    });
  };
}

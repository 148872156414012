import { ITableColumnConfig } from 'src/widgets/Table';

const hideFromManager = ['brandContents'];
const hideFromPublisher = [
  'lastNote',
  'source',
  'networks',
  'contents',
  'reach',
  'engagement',
  'engagementRate',
  'proposedAmount',
  'creatorRating',
  'country',
  'productChoices',
  'address',
  'amountSent',
  'review',
];

/**
 * Returns the columns filtered by user type.
 *
 * @param {Boolean} isPublisher whether the user is a publisher.
 *
 * @return {ITableColumnConfig[]}
 */
export const getColumns = (isPublisher: boolean): ITableColumnConfig[] => {
  if (isPublisher) {
    return allColumns.filter((column) => !hideFromPublisher.includes(column.field));
  }

  return allColumns.filter((column) => !hideFromManager.includes(column.field));
};

const allColumns: ITableColumnConfig[] = [
  {
    headerName: 'New',
    field: 'new',
    width: 80,
  },
  {
    headerName: 'Name',
    cellType: 'name',
    field: 'nameImageSrc',
    nameField: 'displayName',
    badgeField: 'likedBadge',
  },
  {
    headerName: 'Last Message',
    cellType: 'text',
    field: 'lastMessage',
    width: 240,
    grow: true,
  },
  {
    headerName: 'Last Note',
    cellType: 'text',
    field: 'lastNote',
  },
  {
    headerName: 'Stage',
    field: 'stage',
    width: 150,
  },
  {
    headerName: 'Last Update',
    cellType: 'date',
    field: 'lastUpdate',
    showDistance: true,
    width: 150,
  },
  {
    headerName: 'Source',
    field: 'source',
    width: 150,
  },
  {
    headerName: 'Network',
    cellType: 'network',
    field: 'networks',
    width: 210,
  },
  {
    headerName: 'Content Preview',
    cellType: 'media',
    field: 'contents',
  },
  {
    headerName: 'Content Style',
    cellType: 'media',
    field: 'brandContents',
  },
  {
    headerName: 'Reach',
    cellType: 'numeric',
    field: 'reach',
    formatStr: '0,0',
    width: 120,
  },
  {
    headerName: 'Engagement',
    cellType: 'numeric',
    field: 'engagement',
    formatStr: '0,0',
    width: 120,
  },
  {
    headerName: 'Engagement %',
    cellType: 'numeric',
    field: 'engagementRate',
    formatStr: '0.0%',
    width: 150,
  },
  {
    headerName: 'Proposed Network',
    cellType: 'network',
    field: 'proposedNetworks',
  },
  {
    headerName: 'Proposed Amount',
    cellType: 'numeric',
    field: 'proposedAmount',
    isPrice: true,
    currencyCodeField: 'currencyCode',
    currencyXRateField: 'currencyXRate',
    width: 150,
  },
  {
    headerName: `Creator's Rating`,
    cellType: 'rating',
    field: 'creatorRating',
    width: 150,
  },
  {
    headerName: 'Country',
    field: 'country',
  },
  {
    headerName: 'Agreed Amount',
    cellType: 'numeric',
    field: 'agreedAmount',
    isPrice: true,
    currencyCodeField: 'currencyCode',
    currencyXRateField: 'currencyXRate',
    width: 150,
  },
  {
    headerName: 'Deliverables',
    cellType: 'network',
    field: 'deliverables',
    unique: false,
    width: 210,
  },
  {
    headerName: 'Next Post Due',
    cellType: 'date',
    field: 'nextPostDue',
    width: 150,
  },
  {
    headerName: 'Product Choices',
    cellType: 'text',
    field: 'productChoices',
    width: 300,
    grow: true,
  },
  {
    headerName: 'Address',
    cellType: 'text',
    field: 'address',
    width: 300,
    grow: true,
  },
  {
    headerName: 'Posts',
    cellType: 'numeric',
    field: 'postsCount',
    width: 80,
  },
  {
    headerName: 'Preview',
    cellType: 'media',
    field: 'postImages',
  },
  {
    headerName: 'Delivered',
    cellType: 'date',
    field: 'deliveredDate',
    width: 150,
  },
  {
    headerName: 'Amount Owed',
    cellType: 'numeric',
    field: 'amountOwed',
    isPrice: true,
    currencyCodeField: 'currencyCode',
    currencyXRateField: 'currencyXRate',
    width: 120,
  },
  {
    headerName: 'Amount Sent',
    cellType: 'numeric',
    field: 'amountSent',
    isPrice: true,
    currencyCodeField: 'currencyCode',
    currencyXRateField: 'currencyXRate',
    width: 120,
  },
  {
    headerName: 'Your Review',
    cellType: 'rating',
    field: 'review',
    width: 150,
  },
];

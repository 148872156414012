import * as React from 'react';
import { map } from 'lodash';

import { ISocialPost } from './PostCard';
import {
  SearchIcon,
  SendOfferIcon,
  ThumbsUpIcon,
  FavoriteBorderIcon,
  RefreshIcon,
  ViewIcon,
  GroupIcon,
  ClickIcon,
  UniqueImpressions,
} from 'src/icons';
import { largeNumberAbbreviator } from 'src/utils/formatNumbers';

import styles from './EngageList.scss';

interface IProps {
  post: ISocialPost;
}

interface IDisplayStat {
  key: string;
  statValue: string;
}

const EngageList: React.FunctionComponent<IProps> = React.memo(props => {
  const { post } = props;

  const STATS_TO_DISPLAY = {
    instagram: ['likes', 'comments', 'impressions'],
    instagram_story: ['impressions', 'unique_impressions', 'clicks'],
    instagram_video: ['views', 'likes', 'comments'],
    youtube_mention: ['views', 'likes'],
    youtube_dedicated: ['views', 'likes'],
    blog_mention: ['reach'],
    blog_dedicated: ['reach'],
    twitter_post: ['reach', 'likes', 'shares'],
    facebook_post: ['reach', 'likes', 'comments'],
    pinterest: ['reach', 'favorites', 'closeups', 'clicks'],
    tiktok_video: ['views', 'likes', 'comments', 'shares'],
  };

  const statsToDisplayForPost = STATS_TO_DISPLAY[post.post_type];
  const stats: IDisplayStat[] = map(statsToDisplayForPost, stat => {
    return {
      key: stat,
      statValue: post[stat],
    };
  });

  const shouldShowStat = function(stat) {
    return !isNaN(parseInt(stat.statValue, 10))
  };

  return (
    <ul className={styles.engageList}>
      {map(stats, (stat, index) => (shouldShowStat(stat) && (
        <li key={index} className={styles.engageItem}>
          {stat.key === 'comments' && <SendOfferIcon />}
          {stat.key === 'likes' &&
            (post.post_type !== 'facebook_post' ? (
              <FavoriteBorderIcon />
            ) : (
              <ThumbsUpIcon />
            ))}
          {stat.key === 'favorites' && <FavoriteBorderIcon />}
          {(stat.key === 'reposts' || stat.key === 'shares') && <RefreshIcon />}
          {stat.key === 'views' && <ViewIcon />}
          {stat.key === 'impressions' && <ViewIcon />}
          {stat.key === 'clicks' && <ClickIcon />}
          {stat.key === 'reach' && <GroupIcon />}
          {stat.key === 'closeups' && <SearchIcon />}
          {stat.key === 'unique_impressions' && <UniqueImpressions />}
          <div className={styles.engageAmount}>
            {largeNumberAbbreviator(stat.statValue)}
          </div>
        </li>
      )))}
    </ul>
  );
});

export default EngageList;

import { isNumber, isString } from 'lodash';

import { ILicensedContent } from 'src/common/models/licensedContent';

const PREVIEW_RESOLUTION = 'aiq_preview_resolution';

/**
 * Finds the proper image url within licensed content.
 *
 * @param {ILicensedContent} content the licensed content.
 * @param {Number} idealWidth the ideal width of image.
 *
 * @return {String}
 */
export default function(
  content: ILicensedContent,
  idealWidth: number = 300,
  preview: boolean = true,
) {
  // use social post image if possible
  if (content.social_post && isString(content.social_post.image)) {
    return content.social_post.image;
  }

  const medias = (content.social_post && content.social_post.media) || content.media;
  // prioritize media array
  if (medias && medias.length > 0) {
    let minIndex = -1;
    let min = Number.MAX_SAFE_INTEGER;

    // try to find a preview url if indicated
    if (preview) {
      const previewMedia = medias.find(
        (media) => isString(media.url) && media.resolution === PREVIEW_RESOLUTION,
      );

      if (previewMedia) {
        return previewMedia.url;
      }
    }

    // try to find the image in media array
    // which has width close to the ideal width
    medias.forEach((media, index) => {
      if (isString(media.url) && isNumber(media.width)) {
        const diff = Math.abs(media.width - idealWidth);
        if (min > diff) {
          min = diff;
          minIndex = index;
        }
      }
    });

    if (minIndex >= 0) {
      return medias[minIndex].url;
    }
  }

  if (content.image && content.image.length > 0) {
    return content.image;
  }
  if (content.image_link && content.image_link.length > 0) {
    return content.image_link;
  }

  return null;
}

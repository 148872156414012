import * as React from 'react';
import cx from 'classnames';

import { FileTypeAviColored } from 'src/icons';
import { Video } from 'src/widgets/Video';
import { FileTypeIcon } from 'src/common/FileTypeIcon';

import { Content, IContentProps } from './Content';

import getFilenameFromUrl from 'src/common/utils/getFilenameFromUrl';

import styles from './VideoContent.scss';

interface IState {
  loadVideoFailed: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class VideoContent extends React.PureComponent<IContentProps, IState> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);

    this.state = {
      loadVideoFailed: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames, size, ...props } = this.props;
    const { loadVideoFailed } = this.state;

    let fileIconSize = 70;
    if (size === 'medium') {
      fileIconSize = 80;
    } else if (size === 'large') {
      fileIconSize = 140;
    }

    return (
      <Content
        content={content}
        classNames={classNames.concat(
          cx(styles.VideoContent, {
            [styles.failed]: loadVideoFailed,
          }),
        )}
        size={size}
        {...props}
      >
        {!loadVideoFailed && (
          <Video
            classNames={[styles.video]}
            src={content.src}
            showDuration={true}
            onError={this.handleVideoLoadError}
          />
        )}
        {loadVideoFailed && (
          <div className={styles.fileIconWrapper}>
            <FileTypeIcon
              filename={getFilenameFromUrl(content.src)}
              size={fileIconSize}
              defaultIcon={FileTypeAviColored}
            />
          </div>
        )}
      </Content>
    );
  }

  /**
   * @private
   * Handler for when video failed to load.
   */
  private handleVideoLoadError = () => {
    this.setState({
      loadVideoFailed: true,
    });
  };
}

export interface IRawMetricsData {
  date: string;

  subchannel?: TSubChannel;

  impressions?: number;
  clicks?: number;
  spend?: number;
  ecv?: number;
  content_count?: number;

  distribution_unit__url?: string;
  distribution_unit__title?: string;
  distribution_unit__id?: string;
  distribution_unit__text?: string;
  distribution_unit__distributor_display_name?: string;
  distribution_unit__thumbnail_image_url?: string;

  facebookinsights__spend?: number;
  facebookinsights__unique_cliks?: number;
  facebookinsights__offsite_conversion_fb_pixel_purchase?: number;

  socialinsights__share_count?: number;
  socialinsights__favorite_count?: number;
  socialinsights__like_count?: number;
  socialinsights__comment_count?: number;
  socialinsights__view_count?: number;
  socialinsights__distribution_unit__brandsocialpost__estimated_cost?: number;
}

export type TSubChannel = 'fb_ads' | 'social_youtube' | 'social_instagram';
export const subChannelName = {
  fb_ads: 'Facebook Ads',
  social_youtube: 'YouTube Influencer Posts',
  social_instagram: 'Instagram Influencer Posts',
};

export type TDataField = 'ecv' | 'impressions' | 'spend' | 'content_count';
export const dataFieldConfig: {
  [field in TDataField]: {
    displayName: string;
    isDollar: boolean;
  };
} = {
  ecv: {
    displayName: 'Estimated Content Value',
    isDollar: true,
  },
  impressions: {
    displayName: 'Total Impressions',
    isDollar: false,
  },
  spend: {
    displayName: 'Estimated Cost',
    isDollar: true,
  },
  content_count: {
    displayName: 'Content Created',
    isDollar: false,
  },
};

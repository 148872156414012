import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { chain, bind, filter, isArray, isEmpty, isFunction, map, toString } from 'lodash';

import {
  LikedCircleBlueIcon,
  SendOfferIcon,
  ShareIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import { doesAccountBelongToProgram, IInviteContext, Invite, useInviteContext } from 'src/widgets/Invite';
import { ProgramsList } from 'src/widgets/ProgramsList';
import { IToastRefHandles } from 'src/widgets/Toast';
import {
  IRowData,
  ITableConfig,
  ITableRefHandles,
  Table,
} from 'src/widgets/Table';
import countries from 'src/common/config/countries';
import { IProgram } from 'src/common/models/program';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { useForceUpdate } from 'src/utils/hooks';

import CampaignInviteButton from './CampaignInviteButton';
import AddToListButton from './AddToListButton';
import { tableViewColumnConfig } from './tableViewColumnConfig';
import { ICreatorList } from './redux/creatorListModel';

const { useImperativeHandle, useMemo, useRef, useState } = React;
import styles from './TableView.scss';

interface IOwnProps {
  className?: string;
  customConfig?: ITableConfig;

  onCreatorSelected(socialAccount: ISocialAccount);

  sendBulkOffer(socialAccounts: ISocialAccount[]);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  exportCsv(socialAccounts: ISocialAccount[]);

  onReachBottom();

  showCreateFeatures: boolean;
  toastRef?: React.RefObject<IToastRefHandles>;
  forwardedRef?: React.RefObject<ITableViewRef>;
}

interface IStateProps {
  socialAccounts: ISocialAccount[];
}

type TInviteContextProps = Partial<Pick<IInviteContext, 'memberPrograms'>>;

type IProps = IOwnProps & IStateProps & TInviteContextProps;

export interface ITableViewRef {
  container: HTMLDivElement;
  forceUpdate: () => void;
}

const mapStateToProps = (state: ICreatorList): IStateProps => {
  return {
    socialAccounts: state.socialAccounts,
  };
};

const TableViewComponent: React.FC<IProps> = (props) => {
  const {
    className,
    customConfig,
    exportCsv: exportCsvProp,
    forwardedRef,
    goToManage,
    onCreatorSelected,
    onReachBottom,
    sendBulkOffer: sendBulkOfferProp,
    showCreateFeatures = true,
    socialAccounts,
    toastRef,
  } = props;

  // TODO: Handle this one better
  const {
    memberPrograms = null,
    selectedProgramId = null,
  } = showCreateFeatures ? {} : useInviteContext();
  const [selectedSocialAccounts, setSelectedSocialAccount] = useState<ISocialAccount[]>();

  const ref = useRef<HTMLDivElement>();
  const tableRef = useRef<ITableRefHandles>();
  const forceUpdate = useForceUpdate();
  useImperativeHandle(forwardedRef, () => ({
    container: ref.current,
    forceUpdate: () => forceUpdate(),
  }));

  const filterOutSocialAccountsByProgramId = (socialAccounts: ISocialAccount[], programId: IProgram['id']) => (
    filter(
      socialAccounts,
      (socialAccount) => !doesAccountBelongToProgram(socialAccount, programId, memberPrograms),
    )
  );

  const onSelectedRowsChange = (socialAccounts: ISocialAccount[]) => {
    setSelectedSocialAccount(socialAccounts);
  };

  const sendBulkOffer = () => {
    if (isFunction(sendBulkOfferProp)) {
      sendBulkOfferProp(selectedSocialAccounts);
    }
  };

  const exportCsv = () => {
    if (isFunction(exportCsvProp)) {
      exportCsvProp(selectedSocialAccounts);
    }
  };

  /**
   * Columns
   */
  const columnConfig = [...tableViewColumnConfig];
  if (!showCreateFeatures) {
    columnConfig.splice(4, 0, {
      headerName: 'Programs',
      field: 'programs',
      width: 240,
      grow: true,
    });
  }

  // construct data for table
  const data: IRowData[] = map(socialAccounts, (socialAccount) => {
    return {
      id: toString(socialAccount.id),
      username: socialAccount.username || socialAccount.name,
      usernameHandler: isFunction(onCreatorSelected) && bind(onCreatorSelected, {}, socialAccount),
      contents: socialAccount.images,
      reach: socialAccount.reach,
      engagement: socialAccount.engagement,
      engagement_ratio: socialAccount.engagement_ratio,
      networks: [
        {
          type: socialAccount.network_identifier,
        },
      ],
      country: countries[socialAccount.country_code],
      invited:
        socialAccount.invite && socialAccount.invite.approved ? <LikedCircleBlueIcon /> : '',
      relationId: socialAccount.has_relationship && socialAccount.relation_id,
      relationHandler: isFunction(goToManage) && bind(goToManage, {}, socialAccount.relation_id),
      addedBy: socialAccount.favorite_element && socialAccount.favorite_element.by_user_name,
      topAudienceCountry: (
        socialAccount.demographics_report &&
        socialAccount.demographics_report.country &&
        socialAccount.demographics_report.country.length
      )
        ? socialAccount.demographics_report.country[0][0]
        : '',
      topAudienceAge: (
        socialAccount.demographics_report &&
        socialAccount.demographics_report.age &&
        Object.keys(socialAccount.demographics_report.age).length > 0
      )
        ? Object.keys(socialAccount.demographics_report.age)
          .reduce((a, b) => ( // Find key with the most value
            socialAccount.demographics_report.age[a] > socialAccount.demographics_report.age[b] ? a : b
          ), '')
        : '',
      topAudienceInterests: (
        socialAccount.demographics_report &&
        socialAccount.demographics_report.interest &&
        socialAccount.demographics_report.interest.length
      )
        ? socialAccount.demographics_report.interest.slice(0, 3).join(', ')
        : '',
      programs: !showCreateFeatures && (
        <ProgramsList
          className={styles.programsList}
          socialAccount={socialAccount}
          showTooltip
          hideIcon
        />
      ),
      disableSelection: !showCreateFeatures && (
        !socialAccount.can_contact ||
        doesAccountBelongToProgram(socialAccount, selectedProgramId, memberPrograms)
      ),
      _raw: socialAccount,
    };
  });

  const prospect = useMemo(
    () => (
      showCreateFeatures && !isEmpty(selectedSocialAccounts)
        ? chain(filterOutSocialAccountsByProgramId(selectedSocialAccounts, selectedProgramId))
          .filter((socialAccount) => socialAccount.has_email)
          .map((socialAccount) => ({ socialAccount }))
          .value()
        : null
    ),
    [
      filterOutSocialAccountsByProgramId,
      selectedProgramId,
      selectedSocialAccounts,
      showCreateFeatures,
    ],
  );

  const renderActions = () => {
    // TODO: call onchange from data table instead
    const accountIds = map(selectedSocialAccounts, (socialAccount) => socialAccount.id);
    const updatedSelectedSocialAccounts = socialAccounts.filter(
      (socialAccount) => accountIds.includes(socialAccount.id),
    );
    const uninvitedAccounts = updatedSelectedSocialAccounts.filter(
      (socialAccount) => !(socialAccount.invite && socialAccount.invite.approved),
    );

    const getAiqActions = () => <>
      <Button
        label={`Send ${accountIds.length > 1000 ? '999+' : accountIds.length} Offers`}
        icon={<SendOfferIcon size={20} />}
        disabled={isEmpty(accountIds)}
        className={cx(styles.button, styles.sendBulkOffer)}
        onClick={sendBulkOffer}
      />
      <CampaignInviteButton classNames={[styles.button]} socialAccounts={uninvitedAccounts} />
      <Button
        label="Export"
        icon={<ShareIcon size={18} />}
        className={styles.button}
        theme="info"
        disabled={isEmpty(selectedSocialAccounts)}
        onClick={exportCsv}
      />
      <AddToListButton accountIds={accountIds} classNames={[styles.button]} />
    </>;

    const getAxActions = () => (
      <Invite
        prospect={prospect}
        preloadProspects={map(socialAccounts, (socialAccount) => ({ socialAccount }))}
        mode='withButton'
        withBorder
        onInvite={(prospect, programs) => {
          forceUpdate();
          if (toastRef && toastRef.current) {
            const subject = isArray(prospect)
              ? (
                prospect.length > 1
                  ? `${prospect.length} creators`
                  : <span>{prospect[0].socialAccount.username}</span>
              )
              : <span>{prospect.socialAccount.username}</span>
            toastRef.current.showMessage({
              content: <>
                Invited {subject} to&nbsp;
                <span>{map(programs, (p) => p.title).join(', ')}</span>
              </>,
            })
          }
        }}
        onProgramSelect={() => {
          forceUpdate();
        }}
      />
    );

    return showCreateFeatures
      ? getAiqActions()
      : getAxActions();
  };

  return (
    <div
      className={cx(styles.TableView, className)}
      ref={ref}
    >
      {!isEmpty(socialAccounts) && (
        <Table
          ref={tableRef}
          columns={columnConfig}
          config={{
            rowHeight: 56,
            ...customConfig,
          }}
          paddingBottom={0}
          headerActions={!isEmpty(socialAccounts) && renderActions()}
          data={data}
          onSelectedDataChange={onSelectedRowsChange}
          onReachedBottom={onReachBottom}
          className={styles.table}
        />
      )}
    </div>
  );
};

const ConnectedTableView = connect<IStateProps, unknown, IOwnProps>(mapStateToProps)(TableViewComponent);

export const TableView = React.forwardRef((props: IOwnProps, ref: React.RefObject<ITableViewRef>) => (
  <ConnectedTableView {...props} forwardedRef={ref} />
));

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ClickIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/outline/click">
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="5"
          y="2"
          width="14"
          height="20"
        >
          <g id="icon">
            <path
              id="Vector"
              d="M17.1591 22H9.64835C9.43376 22 9.26209 21.9142 9.17625 21.7425L5.39943 16.2489C5.01316 15.691 4.84149 14.8326 5.18484 14.1888C5.39943 13.7597 5.82861 13.5022 6.34363 13.5022C7.33076 13.5022 8.18913 13.8455 9.5196 15.4764V7.23606C9.5196 6.1631 10.4209 5.26181 11.5368 5.26181C12.6526 5.26181 13.5539 6.1631 13.5539 7.23606V12.3004L17.2878 13.5451C18.5754 13.9313 18.9617 15.3906 18.7041 16.5064L17.7599 21.5279C17.6741 21.7854 17.4166 22 17.1591 22ZM9.94878 20.7983H16.6441L17.5024 16.2489C17.6741 15.6052 17.5024 14.8326 16.9016 14.618L12.7385 13.2446C12.481 13.1588 12.3522 12.9442 12.3522 12.6867V7.23606C12.3522 6.80687 11.966 6.46353 11.5368 6.46353C11.1076 6.46353 10.7213 6.80687 10.7213 7.23606V17.279C10.7213 17.5365 10.5496 17.7511 10.2921 17.8369C10.0346 17.9228 9.77711 17.8369 9.64835 17.5794C7.84578 14.8755 7.03033 14.661 6.38655 14.661C6.30071 14.661 6.2578 14.7039 6.2578 14.7039C6.17196 14.8755 6.21488 15.3047 6.38655 15.5622L9.94878 20.7983Z"
              fill="black"
            />
            <path
              id="Vector_2"
              d="M15.3136 6.97854C14.9702 6.97854 14.7127 6.72103 14.7127 6.37768C14.7127 4.61803 13.2535 3.1588 11.4938 3.1588C9.73418 3.1588 8.27495 4.61803 8.27495 6.37768C8.27495 6.72103 8.01744 6.97854 7.67409 6.97854C7.33074 6.97854 7.11615 6.72103 7.11615 6.37768C7.11615 3.97425 9.0904 2 11.5367 2C13.9831 2 15.9574 3.97425 15.9574 6.37768C15.9144 6.72103 15.6569 6.97854 15.3136 6.97854Z"
              fill="black"
            />
          </g>
        </mask>
        <g mask="url(#mask0)">
          <g id="color/black">
            <rect id="Rectangle" width="24" height="24" fill="#1A1818" />
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
));

export { ClickIcon };

import { IContent } from '../contentPreviewerModel';

const getPreviewMedia = (content: IContent): string => {
  return content.previewUrl || content.downloadableUrl || content.src || content.imageLink || '';
};

const getThumbnailMedia = (content: IContent): string => {
  return content.thumbnailUrl || getPreviewMedia(content);
};

const getDownloadableMedia = (content: IContent): string => {
  return content.downloadableUrl || content.src || content.imageLink || '';
};

export { getPreviewMedia, getThumbnailMedia, getDownloadableMedia };

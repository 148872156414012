import { IProspect } from 'src/common/models/member';

export interface IProgram {
  id: number;
  title: string;
  communityId: number;
}

/**
 * aspirex/services/members/types/ProgramMembershipStatus.ts
 */
export type TProspectProgramStatus = 'new' | 'invited' | 'approved' | 'rejected';

export interface IMemberProgram extends IProgram {
  status: TProspectProgramStatus;
  memberId: IProspect['id'];
  memberEmail: IProspect['email'];
  username?: string; // used as identifier only thru backend_server api
}

/**
 * List of programs per member (identified by the username)
 */
export interface IMemberProgramMap {
  [username: string]: IMemberProgram[];
}

export type TProgram = IProgram | IMemberProgram;

/**
 * Duck typing to check if program is `IMemberProgram`
 * @param {TProgram} program
 */
export const isMemberProgram = (program: TProgram): program is IMemberProgram => {
  return (program as IMemberProgram).status !== undefined;
};

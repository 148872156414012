import * as React from 'react';
import numeral from 'numeral';
import { sumBy, sortBy, slice } from 'lodash';
import { BarGroupHorizontal, Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { LinearGradient } from '@vx/gradient';
import { Text } from '@vx/text';

import styles from './BarGroup.scss';

const { useMemo } = React;

const max = (arr, fn) => Math.max(...arr.map(fn));

export interface IBarGroupProps {
  width?: number;
  height?: number;
  data: any;
  margin?: any;
}
// accessors
const y0 = d => d.label;
const x = () => 0;

const BarGroup = ({
  data,
  width,
  height,
  margin = {
    top: 0,
    left: 100,
    right: 120,
    bottom: 0,
  },
}) => {
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - 100;

  const keys = ['value', 'value2'];
  const maxRecords = useMemo(() => Math.floor(height / 50), [height]);
  const [records, totalValue, totalValue2] = useMemo(() => {
    const sortedRawData = sortBy(data, d => -d.value);
    const sum = sumBy(data, 'value');
    const sum2 = sumBy(data, 'value2');
    if (sortedRawData.length > maxRecords) {
      return [[...slice(sortedRawData, 0, maxRecords - 1)], sum, sum2];
    } else {
      return [sortedRawData, sum, sum2];
    }
  }, [data, maxRecords]);
  // scales
  const y0Scale = scaleBand({
    domain: records.map(y0),
    padding: 0.2,
  });
  const y1Scale = scaleBand({
    domain: keys,
    padding: 0.1,
  });

  const xScale = scaleLinear({
    domain: [0, max(records, d => (d.value >= d.value2 ? d.value : d.value2))],
  });
  const color = scaleOrdinal({
    domain: keys,
    range: ['#3996E0', '#EF5682', '#9caff6'],
  });
  y0Scale.rangeRound([0, yMax]);
  y1Scale.rangeRound([0, y0Scale.bandwidth()]);
  xScale.rangeRound([xMax, 0]);

  return (
    <svg className={styles.BarGroup} width={width} height={height}>
      <rect x={0} y={0} width={width} height={height} fill="none" rx={14} />
      <Group top={margin.top} left={margin.left}>
        <BarGroupHorizontal
          data={records}
          keys={keys}
          width={xMax}
          y0={y0}
          x={x}
          y0Scale={y0Scale}
          y1Scale={y1Scale}
          xScale={xScale}
          color={color}
        >
          {barGroups => {
            return barGroups.map(barGroup => {
              return (
                <Group
                  key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                  top={barGroup.y0}
                >
                  <defs>
                    <clipPath id="round-corner">
                      <rect
                        x="3"
                        y="0"
                        width={xMax}
                        height="56"
                        rx="0"
                        ry="5"
                      />
                    </clipPath>
                    <LinearGradient
                      id="blue-gradient"
                      from="#fff"
                      to="#3996e0"
                      vertical={false}
                    />
                    <LinearGradient
                      id="pink-gradient"
                      from="#fff"
                      to="#EF5682"
                      vertical={false}
                    />
                  </defs>
                  {barGroup.bars.map(bar => {
                    return (
                      <Group key={`${barGroup.index}-${bar.index}-${bar.key}`}>
                        <Bar
                          x={bar.x}
                          y={bar.y}
                          width={xMax}
                          height={bar.height}
                          clip-path="url(#round-corner)"
                          fill={'#eff5f9'}
                          rx={5}
                        />
                        <Bar
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          clip-path="url(#round-corner)"
                          fill={`url(#${
                            bar.index === 0 ? 'blue' : 'pink'
                          }-gradient)`}
                          rx={5}
                        />
                        <Group>
                          <Text
                            className={styles.value}
                            x={xMax + 60}
                            y={bar.y}
                            fill={bar.color}
                            verticalAnchor="start"
                            textAnchor="end"
                          >
                            {numeral(bar.value)
                              .format('0.[0]a')
                              .toUpperCase()}
                          </Text>

                          {/* eslint-disable @typescript-eslint/indent */}
                          <Text
                            x={xMax + 72} // This makes it line up perfectly with the end of the header.
                            y={bar.y}
                            fill={'#8f8d91'}
                            verticalAnchor="start"
                            textAnchor="start"
                          >{`(${numeral(
                            bar.value /
                              (bar.key === 'value' ? totalValue : totalValue2)
                          ).format('0.0%')})`}</Text>
                          {/* eslint-enable */}
                        </Group>
                      </Group>
                    );
                  })}
                </Group>
              );
            });
          }}
        </BarGroupHorizontal>
        <AxisLeft
          scale={y0Scale}
          tickLabelProps={() => {
            return {
              fontSize: '11px',
              fontWeight: 'lighter',
              textAnchor: 'end',
              color: '#4F4F4F',
              fill: '#8f8d91',
            };
          }}
          labelClassName={styles.label}
          tickStroke="transparent"
          hideAxisLine
        />
      </Group>
    </svg>
  );
};

export default BarGroup;
export { BarGroup };

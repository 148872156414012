import * as React from 'react';
import { size } from 'lodash';

const { useCallback, useState, useEffect } = React;

export function useMedia(queries: string[], values: any[], defaultValue: any) {
  if (size(queries) !== size(values)) {
    throw new Error(`queries.length !== values.length`);
  }

  const findMatchedValue = useCallback(
    () => {
      if (typeof matchMedia === 'undefined') {
        return defaultValue;
      }
      const firstMatchIndex = queries.findIndex((query) => matchMedia(query).matches);

      return values[firstMatchIndex] || defaultValue;
    },
    [defaultValue, queries, values],
  );
  const [matchedValue, setMatchedValue] = useState(findMatchedValue);

  const handleWindowResize = useCallback(
    () => setMatchedValue(findMatchedValue),
    [findMatchedValue],
  );

  useEffect(() => {
    if (typeof matchMedia !== 'undefined') {
      window.addEventListener('resize', handleWindowResize, { passive: true });
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  if (typeof matchMedia === 'undefined') {
    return defaultValue;
  }

  return matchedValue;
}

import * as React from 'react';
import { IContentFilters } from './redux/models';

import { Button } from 'src/widgets/Button';

import styles from './ContentListEmpty.scss';

interface IProps {
  filters: IContentFilters;
  hideUploadContentButton?: boolean;
  onClickUploadContent();
}

const ContentListEmpty: React.FunctionComponent<IProps> = (props) => {
  const showUploadContentButton = (
    !props.hideUploadContentButton &&
    !props.filters.selectedCampaignId &&
    (!props.filters.selectedNetworkId || props.filters.selectedNetworkId === 'upload')
  );

  return (
    <div className={styles.ContentListEmpty}>
      <div className={styles.title}>No content found for the given filters</div>
      {showUploadContentButton && (
        <Button
          label="Upload Content"
          onClick={props.onClickUploadContent}
          className={styles.button}
        />
      )}
    </div>
  );
};

export default ContentListEmpty;

import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  CloseIcon,
  EditIcon,
  SendOfferIcon,
  SpinnerIcon,
  ThumbsUpIcon,
} from 'src/icons';
import {
  Button,
  LinkButton,
} from 'src/widgets/Button';
import { Input } from 'src/widgets/Input';
import { CreatorDetail } from 'src/common';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IManager } from 'src/common/models/manager';

import styles from './ProposalListItem.scss';

interface IProps {
  socialAccount: ISocialAccount;
  manager: IManager;
  comment: string;
  updateFeedback(comment: string);
  updateApproved(approved: boolean);

  feedback: string;
  approved: boolean;

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  editMode: boolean;
  newFeedback: string;

  isUpdatingFeedback: boolean;
  isUpdatingApproved: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ProposalListItem extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      editMode: false,
      newFeedback: props.feedback,

      isUpdatingApproved: false,
      isUpdatingFeedback: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { socialAccount, manager, comment, classNames } = this.props;

    return (
      <div className={cx(classNames.concat(styles.ProposalListItem))}>
        <CreatorDetail
          socialAccount={socialAccount}
          header={
            comment && (
              <div className={styles.managerNoteSection}>
                <div className={styles.managerName}>
                  {manager.display_name || manager.full_name}&apos;s notes about this creator:
                </div>
                <div className={styles.managerComment}>&quot;{comment}&quot;</div>
              </div>
            )
          }
          footer={
            <React.Fragment>
              {this.renderFeedbackSection()}
              {this.renderActions()}
            </React.Fragment>
          }
          selfServeExperiment={false}
        />
      </div>
    );
  }

  /**
   * @private
   * Renders the feedback section.
   *
   * @return {JSX}
   */
  private renderFeedbackSection = () => {
    const { feedback, approved } = this.props;
    const { editMode, newFeedback, isUpdatingFeedback, isUpdatingApproved } = this.state;

    return (
      <div className={styles.feedbackSection}>
        {approved && !isUpdatingApproved && (
          <div className={styles.approvedDisplay}>
            <ThumbsUpIcon className={styles.approvedIcon} />
            <div className={styles.approvedMessage}>You&apos;ve approved this influencer</div>
            <CloseIcon
              size={22}
              className={styles.undoApproveIcon}
              onClick={this.updateApproved.bind(this, false)}
            />
          </div>
        )}
        {(!isEmpty(feedback) || isUpdatingFeedback) && !editMode && (
          <div className={styles.feedbackDisplay}>
            <SendOfferIcon className={styles.messageIcon} />
            <div className={styles.feedback}>
              {isUpdatingFeedback && (
                <div className={styles.feedbackTitle}>Updating your feedback...</div>
              )}
              {!isUpdatingFeedback && (
                <React.Fragment>
                  <div className={styles.feedbackTitle}>Your feedback:</div>
                  <div className={styles.feedbackContent}>{feedback}</div>
                </React.Fragment>
              )}
            </div>
            <div className={styles.actions}>
              {isUpdatingFeedback && <SpinnerIcon size={20} className={styles.spinnerIcon} />}
              {!isUpdatingFeedback && (
                <React.Fragment>
                  <EditIcon size={18} className={styles.icon} onClick={this.enterEditMode} />
                  <CloseIcon size={22} className={styles.icon} onClick={this.onClearClick} />
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {editMode && (
          <div className={styles.writeFeedback}>
            <div className={styles.feedbackTitle}>Write a feedback:</div>
            <div className={styles.editSection}>
              <Input
                className={styles.input}
                theme="info"
                defaultValue={feedback}
                placeholder="Write your feedback..."
                onPressEnter={this.updateFeedback}
                onChange={this.onFeedbackChange}
                focusOnMount={true}
              />
              <Button
                theme="info"
                label="Leave Feedback"
                round={true}
                className={styles.button}
                disabled={newFeedback === feedback}
                onClick={this.updateFeedback}
              />
              <LinkButton className={styles.button} onClick={this.onCancel}>
                Cancel
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * @private
   * Renders the leave feedback and approve buttons.
   *
   * @return {JSX}
   */
  private renderActions = () => {
    const { feedback, approved } = this.props;
    const { editMode, isUpdatingApproved, isUpdatingFeedback } = this.state;
    const shouldShowApproveButton = isUpdatingApproved || !approved;
    const shouldShowFeedbackButton = !editMode && !isUpdatingFeedback && isEmpty(feedback);
    const actionMessage = [];
    if (shouldShowApproveButton) {
      actionMessage.push('If you like a creator, hit the "Approve" button.');
    }
    if (shouldShowFeedbackButton) {
      actionMessage.push(
        'If you think someone if not quite right, hit the "Leave Feedback" button.',
      );
    }

    return (
      <div className={styles.actionSection}>
        {!isEmpty(actionMessage) && (
          <div className={styles.actionMessage}>{actionMessage.join(' ')}</div>
        )}
        <div className={styles.actionButtons}>
          {shouldShowApproveButton && (
            <Button
              className={styles.approveButton}
              label={isUpdatingApproved ? 'Updating...' : approved ? 'Approved' : 'Approve'}
              icon={
                isUpdatingApproved ? (
                  <SpinnerIcon size={17} className={styles.spinnerIcon} />
                ) : (
                  <ThumbsUpIcon size={16} />
                )
              }
              round={true}
              disabled={approved || isUpdatingApproved}
              onClick={this.updateApproved.bind(this, true)}
            />
          )}
          {shouldShowFeedbackButton && (
            <Button
              className={styles.leaveFeedbackButton}
              label={
                isUpdatingFeedback
                  ? 'Updating...'
                  : isEmpty(feedback)
                    ? 'Leave Feedback'
                    : 'Feedback Sent'
              }
              icon={
                isUpdatingFeedback ? (
                  <SpinnerIcon size={20} className={styles.spinnerIcon} />
                ) : (
                  <SendOfferIcon size={20} />
                )
              }
              theme="info"
              round={true}
              disabled={!isEmpty(feedback) || isUpdatingFeedback}
              onClick={this.enterEditMode}
            />
          )}
        </div>
      </div>
    );
  };

  private enterEditMode = () => {
    this.setState({
      editMode: true,
    });
  };

  private onFeedbackChange = (newFeedback) => {
    this.setState({
      newFeedback,
    });
  };

  private onCancel = () => {
    const { feedback } = this.props;

    this.setState({
      editMode: false,
      newFeedback: feedback,
    });
  };

  private onClearClick = () => {
    this.setState(
      {
        newFeedback: '',
      },
      this.updateFeedback,
    );
  };

  private updateFeedback = () => {
    const { updateFeedback, feedback } = this.props;
    const { newFeedback } = this.state;

    if (feedback !== newFeedback) {
      this.setState({
        isUpdatingFeedback: true,
        editMode: false,
      });
      updateFeedback(newFeedback).finally(() => {
        this.setState({
          isUpdatingFeedback: false,
        });
      });
    }
  };

  private updateApproved = (approved: boolean) => {
    const { updateApproved } = this.props;

    this.setState({
      isUpdatingApproved: true,
    });
    updateApproved(approved).finally(() => {
      this.setState({
        isUpdatingApproved: false,
      });
    });
  };
}

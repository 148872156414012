import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import { CloseIcon, ArrowLeftIcon, SpinnerIcon } from 'src/icons';
import { SubmitButton } from 'src/widgets/Button';
import { Input } from 'src/widgets/Input';
import { Popover } from 'src/widgets/Popover';

import { IFavoriteList } from 'src/common/models/favoriteList';

import styles from './SelectListPopover.scss';

interface IProps {
  mountRef: React.RefObject<HTMLElement>;
  show: boolean;
  onRequestClose();

  favoriteLists: IFavoriteList[];
  addToFavoriteList(listId: number);
  createFavoriteList(name: string);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
type TEditListMode = 'add';
interface IState {
  editMode: TEditListMode;

  newListName: string;

  // duplicate flag
  isEditingFavoriteList: boolean;
  isAddingToList: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class SelectListPopover extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      editMode: null,
      newListName: '',
      isEditingFavoriteList: false,
      isAddingToList: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { show, favoriteLists, mountRef, onRequestClose, classNames } = this.props;
    const { editMode, newListName, isAddingToList, isEditingFavoriteList } = this.state;

    return (
      <Popover
        className={cx(classNames.concat(styles.SelectListPopover))}
        mountRef={mountRef}
        minWidth={300}
        show={show}
        onRequestClose={onRequestClose}
      >
        {!editMode && (
          <React.Fragment>
            <div className={styles.header}>
              {isAddingToList ? 'Adding to List' : 'Add to Your Favorites'}
              {isAddingToList && <SpinnerIcon className={styles.spinner} size={20} />}
            </div>
            <div className={styles.list}>
              {map(favoriteLists, (favoriteList, index) => (
                <div
                  key={index}
                  className={cx(styles.option, {
                    [styles.disabled]: isAddingToList,
                  })}
                  onClick={this.handleOptionClick.bind(this, index)}
                >
                  <div className={styles.label}>{favoriteList.name}</div>
                </div>
              ))}
            </div>
            <div
              className={cx(styles.addList, {
                [styles.disabled]: isAddingToList,
              })}
              onClick={this.setEditModeAdd}
            >
              <div className={styles.addIconWrapper}>
                <CloseIcon size={12} className={styles.addIcon} />
              </div>
              New List...
            </div>
          </React.Fragment>
        )}
        {editMode === 'add' && (
          <div className={styles.editList}>
            <div className={styles.back} onClick={this.unsetEditMode}>
              <ArrowLeftIcon className={styles.arrowIcon} />
              Back to Existing Lists
            </div>
            <Input
              className={styles.editContent}
              onChange={this.onNewListNameChange}
              onPressEnter={this.confirmAddList}
              defaultValue={newListName}
              placeholder="Create and add to list..."
            />
            <SubmitButton
              className={styles.button}
              label="Create"
              submittingLabel="Creating..."
              isSubmitting={isEditingFavoriteList}
              onClick={this.confirmAddList}
              disabled={isEmpty(newListName) || isEditingFavoriteList}
              fullWidth={true}
              round={false}
            />
          </div>
        )}
      </Popover>
    );
  }

  private setEditModeAdd = () => {
    this.setState({
      editMode: 'add',
    });
  };

  private unsetEditMode = () => {
    this.setState({
      editMode: null,
      newListName: '',
    });
  };

  private onNewListNameChange = (newListName) => {
    this.setState({
      newListName,
    });
  };

  private confirmAddList = () => {
    const { createFavoriteList, favoriteLists } = this.props;
    const { newListName } = this.state;
    const newIndex = favoriteLists.length;

    this.setState({
      isEditingFavoriteList: true,
    });
    createFavoriteList(newListName).finally(() => {
      this.setState({
        editMode: null,
        newListName: '',
        isEditingFavoriteList: false,
      });

      this.handleOptionClick(newIndex);
    });
  };

  /**
   * @private
   * SelectListPopover an option and notifies parent.
   *
   * @param {Number} index the selected index.
   */
  private handleOptionClick = (index: number) => {
    const { favoriteLists, addToFavoriteList, onRequestClose } = this.props;
    const selectedList = favoriteLists[index];

    this.setState({
      isAddingToList: true,
    });
    addToFavoriteList(selectedList.id).finally(() => {
      this.setState({
        isAddingToList: false,
      });

      onRequestClose();
    });
  };
}

import * as React from 'react';

import { IntroModal, IIntroModal, IStepConfig } from 'src/widgets/IntroModal';

const ASSETS = process.env.ASSETS;
const welcomeImage = `${ASSETS}/creator_import_intro_modal_welcome.png`;
const campaignImage = `${ASSETS}/creator_import_intro_modal_campaigns.png`;
const workflowImage = `${ASSETS}/creator_import_intro_modal_workflow.png`;

const StepConfigs: IStepConfig[] = [
  {
    title: 'Welcome to AspireIQ!',
    subtitle: 'Get rewarded for creating content',
    bannerSrc: welcomeImage,
  },
  {
    title: 'Find amazing campaigns',
    subtitle: 'Access to 100+ collaboration opportunities',
    bannerSrc: campaignImage,
  },
  {
    title: 'Structured workflow & results',
    subtitle: 'Seamless communication and track your results',
    bannerSrc: workflowImage,
  },
];

const AspireIntroModal: React.FunctionComponent<IIntroModal> = (props) => {
  return <IntroModal {...props} steps={StepConfigs} />;
};

export default AspireIntroModal;

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const SendDocumentIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M15.87,13.16c-2.44,0-4.42,1.98-4.42,4.42c0,2.44,1.98,4.42,4.42,4.42s4.42-1.98,4.42-4.42
      C20.29,15.14,18.31,13.16,15.87,13.16z M15.87,20.92c-1.84,0-3.34-1.5-3.34-3.34s1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34
      S17.72,20.92,15.87,20.92z"
    />
    <path
      d="M15.2,9.69c0-0.3-0.24-0.54-0.54-0.54H7.48c-0.3,0-0.54,0.24-0.54,0.54s0.24,0.54,0.54,0.54h7.18
      C14.96,10.23,15.2,9.99,15.2,9.69z"
    />
    <path
      d="M7.48,7.13h3.08c0.3,0,0.54-0.24,0.54-0.54s-0.24-0.54-0.54-0.54H7.48c-0.3,0-0.54,0.24-0.54,0.54
      S7.18,7.13,7.48,7.13z"
    />
    <path
      d="M7.48,12.25c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54h4c0.3,0,0.54-0.24,0.54-0.54
      c0-0.3-0.24-0.54-0.54-0.54H7.48z"
    />
    <path
      d="M9.78,15.34h-2.3c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54h2.3c0.3,0,0.54-0.24,0.54-0.54
      C10.32,15.58,10.08,15.34,9.78,15.34z"
    />
    <path
      d="M9.39,18.98H5.9c-0.62,0-1.12-0.5-1.12-1.12V4.19c0-0.62,0.5-1.12,1.12-1.12h7.62v1.9
      c0,0.8,0.67,1.46,1.48,1.46h1.77v4.8c0,0.3,0.24,0.54,0.54,0.54c0.3,0,0.54-0.24,0.54-0.54V5.89c0-1.85-1.99-3.89-3.8-3.89H5.9
      C4.69,2,3.71,2.98,3.71,4.19v13.67c0,1.21,0.98,2.2,2.19,2.2h3.48c0.3,0,0.54-0.24,0.54-0.54C9.93,19.23,9.68,18.98,9.39,18.98z
      M16.69,5.35H15c-0.22,0-0.41-0.17-0.41-0.38v-1.8C15.49,3.48,16.41,4.43,16.69,5.35z"
    />
    <path
      d="M17.89,17.07C17.89,17.07,17.89,17.07,17.89,17.07l-1.34,0l0.03-1.3c0.01-0.3-0.23-0.54-0.53-0.55
      c0,0-0.01,0-0.01,0c-0.29,0-0.53,0.23-0.54,0.53l-0.03,1.33l-1.46,0c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
      c0,0,0,0,0,0l1.43,0l-0.03,1.39c-0.01,0.3,0.23,0.54,0.53,0.55c0,0,0.01,0,0.01,0c0.29,0,0.53-0.23,0.54-0.53l0.03-1.42l1.36,0
      c0.3,0,0.54-0.24,0.54-0.54C18.43,17.31,18.19,17.07,17.89,17.07z"
    />
  </SvgIcon>
));

export { SendDocumentIcon };

import * as React from 'react';
import { pick, indexOf, map } from 'lodash';

import { RadioGroup } from 'src/widgets/RadioGroup';
import { FilterSection } from '../FilterSection';
import AudienceDropdown from './AudienceDropdown';

import { IAudienceAgeFilters, IAudienceAgeOptions } from '../models';

const { useMemo } = React;

export const AUDIENCE_AGE_FILTERS = ['ageRange', 'ageLessThan', 'agePercentage'];

const DEFAULT_AUDIENCE_AGE_RANGES = ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'];

const pickFilters = (obj: any) => pick(obj, AUDIENCE_AGE_FILTERS);

interface IProps extends IAudienceAgeFilters, IAudienceAgeOptions {
  onChange(ageFilters: IAudienceAgeFilters);
  hide?: boolean;
  hidePercentage?: boolean;
}

const AudienceAgeSection: React.FunctionComponent<IProps> = (props) => {
  const {
    ageLessThan,
    agePercentage,
    ageRange,
    ageRangeChoices: ageRangeChoicesProp,
    hide,
    hidePercentage,
    onChange,
  } = props;

  const ageRangeChoices = useMemo(() => {
    return map(ageRangeChoicesProp, (range) => ({ value: range, label: range }));
  }, [ageRangeChoicesProp]);

  const ageRangeIndex = useMemo(() => {
    return indexOf(ageRangeChoicesProp, ageRange);
  }, [ageRangeChoicesProp, ageRange]);

  const handleChangeAgeRange = (ageRange: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageRange,
    };
    onChange(newFilters);
  };

  const handleChangeAudiencePercentage = (percentage: number) => {
    const newFilters = {
      ...pickFilters(props),
      agePercentage: percentage,
    };
    onChange(newFilters);
  };

  const handleChangeAudienceLessThan = (lessThan: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      ageLessThan: lessThan,
    };
    onChange(newFilters);
  };

  const handleClear = () => {
    handleChangeAgeRange('');
  };

  return (
    <FilterSection
      header="Age"
      hide={hide}
      isSubsection={true}
      showClearButton={ageRangeIndex >= 0}
      onClear={handleClear}
    >
      <RadioGroup
        alignment="vertical"
        onChange={handleChangeAgeRange}
        options={ageRangeChoices}
        selectedIndex={ageRangeIndex}
        columns={2}
      />
      {hidePercentage ? null : (
        <AudienceDropdown
          onChangePercentage={handleChangeAudiencePercentage}
          onChangeLessThan={handleChangeAudienceLessThan}
          selectedPercentage={agePercentage}
          lessThan={ageLessThan}
          disabled={!ageRange}
        />
      )}
    </FilterSection>
  );
};

AudienceAgeSection.defaultProps = {
  ageRangeChoices: DEFAULT_AUDIENCE_AGE_RANGES,
  ageLessThan: false,
  agePercentage: 50,
  hidePercentage: false,
  hide: false,
};

export default AudienceAgeSection;

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { ISocialListeningWatchList } from 'src/common/models/socialListeningWatchList';
import { ActionTypes, IWatchListPageAction, IWatchListPage } from './models';

type WLPThunkAction<T> = ThunkAction<Promise<T>, IWatchListPage, unknown, IWatchListPageAction>;
export type WLPThunkDispatch = ThunkDispatch<IWatchListPage, unknown, IWatchListPageAction>;

import endpoints from 'src/common/config/endpoints';

/***************************
 ***** Private actions *****
 **************************/
const setWatchLists = (watchlists: ISocialListeningWatchList[]): IWatchListPageAction => {
  return {
    type: ActionTypes.SET_WATCH_LISTS,
    payload: {
      watchlists,
    },
  };
};

const setSocialAccounts = (
  socialAccounts: ISocialAccount[],
  count: number,
): IWatchListPageAction => {
  return {
    type: ActionTypes.SET_SOCIAL_ACCOUNTS,
    payload: {
      socialAccounts,
      count,
    },
  };
};

/***************************
 ***** public actions *****
 **************************/
const fetchSocialAccountsForPage = (page: number): WLPThunkAction<boolean> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state: IWatchListPage = getState();
    const { campaign, apiEndpoint, selectedListId, socialAccounts: existingSocialAccounts } = state;

    // reset count
    if (page === 0) {
      dispatch(setSocialAccounts([], 0));
    }

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialAccountEndpoint}?watch_list_id=${selectedListId}&campaign_id=${campaign.id}&page=${page}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      // abort if switched selected list during fetch call
      const { selectedListId: updatedSelectedListId } = getState();
      if (selectedListId !== updatedSelectedListId) {
        return false;
      }

      const json = await resp.json();
      const count = json.data.count || 0;
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      dispatch(
        setSocialAccounts(
          page === 0 ? socialAccounts : [...existingSocialAccounts, ...socialAccounts],
          count,
        ),
      );

      return hasNext;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const fetchWatchLists = (): WLPThunkAction<ISocialListeningWatchList[]> => {
  return async (dispatch, getState): Promise<ISocialListeningWatchList[]> => {
    const state: IWatchListPage = getState();
    const { apiEndpoint, brand } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialListeningWatchList}?brand_id=${brand.id}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      const watchlists = json.data || [];

      dispatch(setWatchLists(watchlists));

      return watchlists;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const setSelectedListId = (selectedListId: number): IWatchListPageAction => {
  return {
    type: ActionTypes.SET_SELECTED_LIST_ID,
    payload: {
      selectedListId,
    },
  };
};

export default {
  fetchWatchLists,
  setSelectedListId,
  fetchSocialAccountsForPage,
};

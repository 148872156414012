import * as React from 'react';
import cx from 'classnames';

import { ArrowDownFilledIcon } from 'src/icons';

import { ITableCellType } from '../../tableContext';

import styles from './EditableHover.scss';

const { useCallback } = React;

interface IProps {
  className?: string;
  cellType?: ITableCellType;
  onClick?();
}

export const EditableHover: React.FC<IProps> = (props) => {
  const {
    className,
    cellType,
    onClick,
  } = props;

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  }, [onClick]);

  return (
    <div
      className={cx(className, styles.EditableHover, styles[cellType])}
      onClick={handleClick}
    >
      {props.children}
      {cellType === 'boolean' && (
        <div className={styles.dropDownArrow}>
          <ArrowDownFilledIcon size={10} />
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import cx from 'classnames';
import {
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  map,
  some,
} from 'lodash';

import { SubmitButton } from 'src/widgets/Button';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';
import { ICommunity } from 'src/common/models/community';
import { IProspect } from 'src/common/models/member';
import { TProgram } from 'src/common/models/program';
import { InviteIcon } from 'src/icons';
import { IOption, ISelectProps, Select } from 'src/widgets/Select/Select';
import { Loading } from 'src/widgets/Skeleton/Loading';


import { useInvite, useInviteContext } from './hooks';
import {
  getProgramStatusOfAccount,
  getCommunitiesAndProgramsOfProspect,
  getProgramStatus,
  getRecentlyCreatedProgramId,
  getSocialAccountsFromProspects,
  mapProgramsToCommunities,
} from './utils';

const { useEffect, useRef, useState } = React;
import styles from './Invite.scss';

type TInviteMode = 'default' | 'withButton';

enum Reason {
  IsFetching = 'IsFetching',
  NoProspect = 'NoProspect',
  NoPrograms = 'NoPrograms',
  NoResource = 'NoResource',
  NoEmail = 'NoEmail',
  HasStatus = 'HasStatus',
  Inviting = 'Inviting',
  InvitingThisProspect = 'InvitingThisProspect', // Used for main button
  InvitingThisProspectToSelectedProgram = 'InvitingThisProspectToSelectedProgram', // Used for button in list
}

export interface IInviteProps {
  className?: string;
  /**
   * Prospect
   */
  prospect: IProspect | IProspect[];
  /**
   * Pre-load all programs of these prospects
   */
  preloadProspects?: IProspect | IProspect[];
  /**
   * Invite mode
   */
  mode?: TInviteMode;
  /**
   * Show border
   */
  withBorder?: boolean;
  /**
   * Props forwarded to <Select>
   */
  selectProps?: Omit<Partial<ISelectProps>, 'options'>;
  /**
   * Ref to Toast
   * if not provided, Invite will have its own Toast
   */
  toastRef?: React.RefObject<IToastRefHandles>;
  /**
   * Callback when popover is shown/hidden
   */
  onPopoverShown?: (shown: boolean) => void;
  /**
   * Callback when a prospect is invited
   */
  onInvite?: (prospect: IProspect | IProspect[], programs: TProgram[]) => void;
  /**
   * Callback when a program is selected
   */
  onProgramSelect?: (programId: TProgram['id']) => void;
}

interface IInviteStatus {
  className?: string;
  message: React.ReactChild;
}

/**
 * Invite a member to a program
 */
export const Invite: React.FC<IInviteProps> = (props: IInviteProps) => {
  const {
    className,
    mode = 'default',
    onInvite: onInviteProp,
    onPopoverShown = () => undefined,
    onProgramSelect = () => undefined,
    preloadProspects = props.prospect,
    prospect,
    selectProps = {},
    toastRef: toastRefProp,
    withBorder = false,
  } = props;

  // Force rerender on invite
  const onInvite = (prospect: IProspect | IProspect[], programs: TProgram[]) => {
    if (isFunction(onInviteProp)) {
      onInviteProp(prospect, programs);
    }
    forceUpdate((x) => !x);
  };

  const {
    memberPrograms,
    selectedProgramId,
    updateSelectedProgramId,
  } = useInviteContext();
  const {
    communities,
    sendInvite,
    error,
    inviteStatus,
    isFetching,
    programs,
    resourceId,
  } = useInvite({
    prospect,
    preloadProspects,
    onInvite,
  });
  const [, forceUpdate] = useState(false);
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const selectRef = useRef<Select>(); // Used to control the selected option
  const mainContainerRef = useRef<HTMLDivElement>(); // Used to make popover mount on this component's root container
  const toastRef = useRef<IToastRefHandles>(toastRefProp ? toastRefProp.current : null);

  // Get social accounts of provided prospect/s
  const prospectAccounts = isArray(prospect)
    ? getSocialAccountsFromProspects(...prospect)
    : getSocialAccountsFromProspects(prospect);

  // Only used for 'default' (single account) mode
  const programStatusOfProspect = prospectAccounts.length > 0
    ? getProgramStatusOfAccount(
      prospectAccounts[0],
      selectedProgramId,
      memberPrograms,
    )
    : null;

  // Used to disable invite button when inviting
  const blockedReasons: Reason[] = (() => {
    const r: Reason[] = [];
    if (isFetching) {
      r.push(Reason.IsFetching);
    }
    if (isEmpty(prospect)) {
      r.push(Reason.NoProspect);
    }
    if (isEmpty(programs)) {
      r.push(Reason.NoPrograms);
    }
    if (!isNumber(resourceId)) {
      r.push(Reason.NoResource);
    }
    if (some(prospectAccounts, (p) => !p.can_contact)) {
      r.push(Reason.NoEmail);
    }
    if (!isNil(programStatusOfProspect)) {
      r.push(Reason.HasStatus);
    }
    if (inviteStatus) {
      if (inviteStatus.isInviting) {
        r.push(Reason.Inviting);
        if (isEqual(inviteStatus.prospect, prospect)) {
          r.push(Reason.InvitingThisProspect);
          if (selectedProgramId === inviteStatus.programId) {
            r.push(Reason.InvitingThisProspectToSelectedProgram);
          }
        }
      }
    }
    return r;
  })();
  const isInviteBlocked = blockedReasons.length > 0; // If you only care about blocking for any reason at all

  // Update whichever's listening to popover opened/closed status
  useEffect(() => {
    onPopoverShown(isPopoverShown);
  }, [isPopoverShown, onPopoverShown]);

  /**
   * On 'withButton' or mass invite mode, show default list
   * On solo prospect mode, show its own programs with respective statuses
   */
  const communitiesAndPrograms: readonly ICommunity[] = (
    mode === 'withButton' || isArray(prospect) || isEmpty(prospect)
      ? mapProgramsToCommunities(communities, programs)
      : getCommunitiesAndProgramsOfProspect(communities, memberPrograms, programs, prospect)
  );

  /**
   * Show error message
   */
  useEffect(() => {
    if (error) {
      toastRef.current.showMessage({
        type: 'error',
        content: error.toString(),
      })
    }
  }, [error]);

  /**
   * Status message shown at the top of the list
   */
  const statusMessage = ((): IInviteStatus => {
    if (!isNumber(resourceId)) {
      return {
        message: 'Please complete your onboarding before sending invites.',
      };
    } else if (isEmpty(communitiesAndPrograms)) {
      return {
        message: 'There are no communities yet',
        className: styles.noCommunities,
      };
    }

    if (mode === 'default' && !isArray(prospect)) {
      if (!isEmpty(prospectAccounts[0]) && !prospectAccounts[0].can_contact) {
        return {
          message: <>
            Creator cannot be invited because their email address is not provided.
            Instead, you may send a direct message to&nbsp;
            <a
              className={styles.accountLink}
              href={prospectAccounts[0].link}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                window.open(prospectAccounts[0].link, '_blank');
              }}
            >
              @{prospectAccounts[0].username}
            </a>.
          </>,
          className: styles.ineligible,
        };
      }
    }
  })();

  /**
   * Generate the list option for a program
   */
  const getProgramOption = (program: TProgram): IOption => {
    const status = getProgramStatus(program);
    const isInvitingToThisProgram = inviteStatus.isInviting && program.id === inviteStatus.programId;

    const actions: React.ReactChild[] = [];
    if (mode === 'default') {
      if (status === 'invited' || status === 'new' || status === 'approved') {
        // Member status ('rejected' not being shown)
        actions.push(
          <div key={status} className={styles[status]}>
            {(() => {
              if (status === 'approved') {
                return 'Member';
              } else if (status === 'new') {
                return 'New';
              }
              return status;
            })()}
          </div>
        );
      } else if (!status) {
        // Invite button
        actions.push(
          <SubmitButton
            className={cx(styles.inviteButton, {
              [styles.inviting]: isInvitingToThisProgram,
              [styles.wait]: includes(blockedReasons, Reason.Inviting),
            })}
            key={'inviteButton'}
            isSubmitting={isInvitingToThisProgram}
            label={'Invite'}
            submittingLabel={''}
            icon={<InviteIcon />}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!includes(blockedReasons, Reason.Inviting)) {
                sendInvite(program.id);
              }
            }}
          />
        );
      }
    }

    return {
      value: program.id,
      label: program.title,
      optionClass: cx(styles.programOption, styles.option, {
        [styles.noSelect]: !isEmpty(statusMessage),
      }),
      showActions: mode === 'default' && (isInvitingToThisProgram || !isNil(status)),
      actions,
    };
  };

  /**
   * Build all items for <Select>
   */
  const getSelectOptions = () => {
    const options: IOption[] = [];

    if (!isEmpty(statusMessage)) {
      options.push({
        value: null,
        label: <span>{statusMessage.message}</span>,
        optionClass: cx(statusMessage.className, styles.message, styles.option, styles.noSelect),
        onSelect: () => false,
      });
    }

    forEach(communitiesAndPrograms, (community) => {
      options.push({
        value: null,
        label: community.title,
        optionClass: cx(styles.communityOption, styles.option, styles.noSelect),
        onSelect: () => false,
      });

      if (isEmpty(community.programs)) {
        options.push({
          value: null,
          label: 'No programs yet',
          optionClass: cx(styles.noPrograms, styles.programOption, styles.option, styles.noSelect),
        });
      } else {
        options.push(
          ...map(community.programs, (program) => (
            getProgramOption(program)
          ))
        );
      }
    });
    return options;
  };

  /**
   * Render select element
   */
  const selectOptions = getSelectOptions();
  const defaultProgramId = selectedProgramId || getRecentlyCreatedProgramId(programs);
  const defaultSelectedIndex = isNumber(defaultProgramId)
    ? findIndex(selectOptions, (option) => option.value === defaultProgramId)
    : null

  // Update <Select>'s state if needed
  useEffect(() => {
    if (
      isNumber(defaultSelectedIndex) &&
      selectRef.current &&
      selectRef.current.state.selectedIndex !== defaultSelectedIndex
    ) {
      selectRef.current.selectOptionAtIndex(defaultSelectedIndex);
    }
  }, [defaultSelectedIndex]);

  const renderSelect = () => <>
    <Select
      {...selectProps} // Extend to other props below as needed
      ref={selectRef}
      className={cx(styles.select, selectProps.className)}
      buttonClassName={styles.selectButton}
      hintText='Invite to ...'
      options={selectOptions}
      defaultSelectedIndex={defaultSelectedIndex}
      onChange={(value) => {
        if (selectProps && isFunction(selectProps.onChange)) {
          selectProps.onChange();
        }
        onProgramSelect(value);
        updateSelectedProgramId(value);
        setIsPopoverShown(false);
      }}
      round={true}
      anchorLocation={selectProps.anchorLocation || 'button'}
      onMenuOpen={() => setIsPopoverShown(true)}
      onMenuClose={() => setIsPopoverShown(false)}
      popoverProps={{
        className: cx(styles.popover, {
          [styles.shown]: isPopoverShown,
        }),
        contentWrapperClassName: styles.popoverContentWrapper,
        contentClassName: styles.popoverContent,
        anchorOrigin: 'middle',
        showArrow: false,
        shadowSize: 'large',
        minWidth: styles.popoverWidth,
        maxWidth: styles.popoverWidth,
        mountRef: mainContainerRef,
        ...selectProps.popoverProps,
      }}
    />
  </>;

  /**
   * Render button element
   */
  const renderButton = () => {
    const isButtonDisabled = (
      includes(blockedReasons, Reason.IsFetching) ||
      includes(blockedReasons, Reason.NoProspect) ||
      includes(blockedReasons, Reason.NoPrograms) ||
      includes(blockedReasons, Reason.NoResource) ||
      includes(blockedReasons, Reason.NoEmail) ||
      includes(blockedReasons, Reason.HasStatus)
    );
    return (
      <SubmitButton
        className={cx(styles.inviteButton, {
          [styles.inviting]: includes(blockedReasons, Reason.InvitingThisProspect),
          [styles.wait]: includes(blockedReasons, Reason.Inviting),
          [styles.disabled]: isButtonDisabled,
        })}
        disabled={isButtonDisabled}
        isSubmitting={inviteStatus && inviteStatus.isInviting}
        submittingLabel={''}
        label={(() => {
          if (programStatusOfProspect === 'invited') {
            return 'Invited';
          }
          return 'Invite';
        })()}
        title={(() => {
          if (includes(blockedReasons, Reason.InvitingThisProspect)) {
            return 'Inviting...';
          }
          if (includes(blockedReasons, Reason.NoEmail)) {
            if (mode === 'default') {
              return 'The account does not have an email address';
            } else {
              return 'At least one of the selected accounts do not have an email address';
            }
          }
          if (mode === 'default') {
            if (isNil(selectedProgramId)) {
              return 'Please select a program first';
            } else if (programStatusOfProspect === 'approved') {
              return 'This creator is already a member of the program';
            } else if (programStatusOfProspect === 'rejected') {
              return 'This creator has been rejected to the program';
            } else if (programStatusOfProspect === 'invited') {
              return 'This creator is already invited to the program';
            }
          }
          return 'Invite to the program';
        })()}
        onClick={() => {
          if (!isInviteBlocked) {
            sendInvite();
          }
        }}
      />
    );
  };

  return (
    <div
      className={cx(
        styles.Invite,
        styles.placeholderShimmy,
        className,
        {
          [styles.hideButton]: isPopoverShown,
          [styles.withButton]: mode === 'withButton',
          [styles.withBorder]: withBorder,
        },
      )}
      ref={mainContainerRef}
    >
      <Loading
        show={isFetching}
        theme='blue'
        rounded
      />
      {renderSelect()}
      {renderButton()}
      {!toastRefProp ? <Toast ref={toastRef} /> : null}
    </div>
  );
};

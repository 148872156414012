import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { ActionTypes, IInvitedListPageAction, IInvitedListPage } from './models';

type ILPThunkAction<T> = ThunkAction<Promise<T>, IInvitedListPage, unknown, IInvitedListPageAction>;
export type ILPThunkDispatch = ThunkDispatch<IInvitedListPage, unknown, IInvitedListPageAction>;

import endpoints from 'src/common/config/endpoints';

/***************************
 ***** Private actions *****
 **************************/
const setSocialAccounts = (
  socialAccounts: ISocialAccount[],
  count: number,
): IInvitedListPageAction => {
  return {
    type: ActionTypes.SET_SOCIAL_ACCOUNTS,
    payload: {
      socialAccounts,
      count,
    },
  };
};

/***************************
 ***** public actions *****
 **************************/
const fetchSocialAccountsForPage = (
  page: number,
  totalCount: number | null,
): ILPThunkAction<[boolean, number]> => {
  return async (dispatch, getState): Promise<[boolean, number]> => {
    const state: IInvitedListPage = getState();
    const { campaign, apiEndpoint, socialAccounts: existingSocialAccounts } = state;

    try {
      let url = `${apiEndpoint}/${endpoints.socialAccountEndpoint}?approved=true&campaign_id=${campaign.id}&page=${page}`;

      if (totalCount !== null) {
        url += `&totalCount=${totalCount}`;
      }
      const resp = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();
      const count = totalCount !== null ? totalCount : json.data.count || 0;
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      // decide whether to reset or append
      dispatch(
        setSocialAccounts(
          page === 0 ? socialAccounts : [...existingSocialAccounts, ...socialAccounts],
          count,
        ),
      );

      return [hasNext, count];
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

export default {
  fetchSocialAccountsForPage,
};

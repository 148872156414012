import * as React from 'react';
import cx from 'classnames';
import AccountSection from './AccountSection';
import ValueSection from './ValueSection';
import EngageList from './EngageList';
import { Image } from 'src/widgets/Image';

import styles from './PostCard.scss';

export interface ISocialPost {
  post_id: string;
  post_type: string;
  network: string;
  mention_id: number;
  reach: string;
  datetime_posted?: string;
  profile_image_url?: string;
  social_account_name?: string;
  social_account_link?: string;
  image_url?: string;
  link?: string;
  creator: string;
  post_text?: string;
  post_image_tags?: string[];
  clicks?: string;
  closeups?: string;
  comments?: string;
  dislikes?: string;
  exits?: string;
  favorites?: string;
  impressions?: string;
  likes?: string;
  shares?: string;
  taps?: string;
  unique_impressions?: string;
  views?: string;
  tmv?: string;
  roi_pct?: string;
  cost?: string;
  product_cost?: string;
}

interface IProps {
  post: ISocialPost;
  isQa: boolean;
  hideValueSection?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const PostCard: React.FunctionComponent<IProps> = React.memo((props) => {
  const { post, hideValueSection, isQa } = props;
  return (
    <a
      className={styles.ClearLink}
      href={post.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.PostCard}>
        <section className={styles.contentImageContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.TopShadow} />
            <Image src={post.image_url} className={styles.imgContent}></Image>
            <div className={styles.captionContainer}>
              <span className={cx(styles.captionTxt, styles.truncate)}>
                {post.post_text}
              </span>
            </div>
          </div>
        </section>
        <section className={styles.InfoContainer}>
          <EngageList post={post} />
          {!hideValueSection && <ValueSection post={post} />}
          <AccountSection isQa={isQa} post={post} />
        </section>
      </div>
    </a>
  );
});

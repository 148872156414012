import { ITableColumnConfig } from 'src/widgets/Table';

export const tableViewColumnConfig: ITableColumnConfig[] = [
  {
    cellType: 'link',
    headerName: 'Name',
    field: 'username',
    handlerField: 'usernameHandler',
    width: 150,
  },
  {
    cellType: 'media',
    headerName: 'Content Preview',
    field: 'contents',
    width: 158,
  },
  {
    cellType: 'numeric',
    headerName: 'Reach',
    field: 'reach',
    formatStr: '0,0',
    width: 120,
  },
  {
    cellType: 'numeric',
    headerName: 'Engagement',
    field: 'engagement',
    formatStr: '0,0',
    width: 120,
  },
  {
    cellType: 'network',
    headerName: 'Network',
    field: 'networks',
    width: 90,
  },
  {
    cellType: 'numeric',
    headerName: 'Engagement %',
    field: 'engagement_ratio',
    formatStr: '0.0%',
    width: 125,
  },
  {
    headerName: 'Country',
    field: 'country',
    width: 150,
  },
  {
    headerName: 'Top Aud. Country',
    field: 'topAudienceCountry',
    width: 150,
    grow: true,
  },
  {
    headerName: 'Top Aud. Age',
    field: 'topAudienceAge',
    width: 120,
    grow: true,
  },
  {
    cellType: 'text',
    headerName: 'Top Aud. Interests',
    field: 'topAudienceInterests',
    width: 300,
    grow: true,
  },
  {
    headerName: 'Invited',
    field: 'invited',
  },
  {
    cellType: 'link',
    headerName: 'Relationship',
    field: 'relationId',
    linkText: 'Manage Relationship',
    handlerField: 'relationHandler',
  },
  {
    headerName: 'Added by',
    field: 'addedBy',
  },
];

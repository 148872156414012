import * as React from 'react';
import cx from 'classnames';
import { defer, every, map } from 'lodash';

import { Input } from 'src/widgets/Input';
import { IManager } from 'src/common/models/manager';
import {
  IContentReview,
  IGroupContentReviewMessage,
  GroupContentReviewMessageType,
} from 'src/common/models/contentReview';
import { UserMessage } from '../UserMessage/UserMessage';
import { SystemMessage } from '../SystemMessage/SystemMessage';

const { useCallback, useRef, useState } = React;

import styles from './ChatBox.scss';

interface IProps {
  className?: string;
  user: IManager;
  contentReview: IContentReview;
  isBrandUser: boolean;
  numBrandUsers: number;
  messages: IGroupContentReviewMessage[];
  isCommenting?: boolean;
  canComment: boolean;
  isVisible: boolean;
  height?: string;

  // actions
  addComment(feedback: IGroupContentReviewMessage): Promise<void>;

  readComments();
}

/**
 * TODO: Antonn: Make this component more generic
 * @type {React.FunctionComponent}
 */
export const ChatBox: React.FunctionComponent<IProps> = (props) => {
  const {
    user,
    contentReview,
    messages,
    addComment,
    numBrandUsers,
    readComments,
    canComment,
    isVisible,
    height,
    className,
  } = props;

  const [comment, setComment] = useState('');
  const [isSendingComment, setIsSendingComment] = useState(false);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const sendComment = async () => {
    try {
      setIsSendingComment(true);
      await addComment({
        message_type: GroupContentReviewMessageType.USER,
        user,
        comment,
        read_by: [user.id],
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setComment('');
      setIsSendingComment(false);
      scrollChatBoxToBottom();
      inputRef.current.focus();
    }
  };

  const scrollChatBoxToBottom = () => {
    const chatBoxElem = chatBoxRef.current;
    if (chatBoxElem) {
      chatBoxElem.scrollTop = chatBoxElem.scrollHeight;
    }
  };

  const renderComments = useCallback(
    () =>
      map(messages, (message, index) => {
        if (message.message_type === GroupContentReviewMessageType.USER) {
          return <UserMessage key={index} message={message} />;
        } else {
          return <SystemMessage key={index} message={message} contentReview={contentReview} />;
        }
      }),
    [messages, contentReview],
  );

  const onPressEnter = () => {
    if (isSendingComment) {
      return;
    }

    // Only send a non-empty comment
    if (comment.trim()) {
      sendComment();
    }

    // Back to focus on input regardless
    defer(() => {
      inputRef.current.focus();
    });
  };

  // Mark message as read
  if (isVisible) {
    // TODO: Antonn: Scroll until the first unread message
    // TODO: Antonn: CSS animation to mark new messages

    const isAllRead = every(messages, (feedback) => {
      return feedback.read_by.includes(user.id);
    });

    if (!isAllRead) {
      readComments();
    }
  }

  return (
    <div className={cx(className, styles.ChatBox)} style={height ? { maxHeight: height } : {}}>
      <div className={styles.chatHeader}>Comment with {numBrandUsers} members</div>
      <div className={styles.chatBox} ref={chatBoxRef}>
        {renderComments()}
      </div>
      {canComment && (
        <div className={styles.actionContainer}>
          <div className={styles.textContainer}>
            <Input
              placeholder="Enter a reply..."
              value={comment}
              className={styles.input}
              onChange={(value: string) => setComment(value)}
              onPressEnter={onPressEnter}
              ref={inputRef}
              disabled={isSendingComment}
            />
          </div>
        </div>
      )}
    </div>
  );
};

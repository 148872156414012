import * as React from 'react';
import { isNil, inRange } from 'lodash';

const { useCallback, useState, useEffect } = React;

const PHONE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 1200;

export type TMobileType = 'tablet' | 'phone';

/**
 * Checks if it's mobile.
 *
 * @return {Boolean}
 */
export function useMobileStatus(): TMobileType {
  const [mobileType, setMobileType] = useState<TMobileType>(
    isNil(window)
      ? null
      : inRange(window.innerWidth, 0, PHONE_MAX_WIDTH)
        ? 'phone'
        : inRange(window.innerWidth, PHONE_MAX_WIDTH, TABLET_MAX_WIDTH)
          ? 'tablet'
          : null,
  );

  // avoid triggering status change often
  const handleWindowSizeChange = useCallback(() => {
    if (!isNil(window)) {
      if (inRange(window.innerWidth, 0, PHONE_MAX_WIDTH) && mobileType !== 'phone') {
        setMobileType('phone');
      } else if (
        inRange(window.innerWidth, PHONE_MAX_WIDTH, TABLET_MAX_WIDTH) &&
        mobileType !== 'tablet'
      ) {
        setMobileType('tablet');
      } else if (window.innerWidth >= TABLET_MAX_WIDTH && mobileType !== null) {
        setMobileType(null);
      }
    }
  }, [mobileType]);

  // sub/unsub window's resize event
  // pass in mobileType as dependency, because we're using it in callback
  useEffect(() => {
    if (!isNil(window)) {
      window.addEventListener('resize', handleWindowSizeChange, { passive: true });
    }

    return () => {
      if (!isNil(window)) {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    };
  }, [mobileType, handleWindowSizeChange]);

  return isNil(window) ? null : mobileType;
}

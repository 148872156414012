import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const ChatSmileIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="
        M2 6C2 4.34326 3.34326 3 5 3H19C20.6567 3 22 4.34326 22 6V15V15.0098L22.0249 15V19.5857C22.0249
        20.4768 20.9478 20.9229 20.3179 20.293L18.0249 18H5C3.34326 18 2 16.6567 2 15V6ZM6.44531
        9.16797C5.98584 9.47437 5.86182 10.0952 6.16797 10.5547C7.67773 12.8193 9.64575 14 12 14C14.3545
        14 16.3225 12.8193 17.8323 10.5547C18.1384 10.0952 18.0144 9.47437 17.5547 9.16797C17.0952 8.86182
        16.4744 8.98584 16.168 9.44531C15.011 11.1809 13.6458 12 12 12C10.3545 12 8.98926 11.1809 7.83228
        9.44531C7.52588 8.98584 6.90503 8.86182 6.44531 9.16797Z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

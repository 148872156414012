import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Promise from 'bluebird';

if (typeof window !== 'undefined') {
  if (typeof IntersectionObserver === 'undefined') {
    require('intersection-observer');
  }
  require('formdata-polyfill');
}
Promise.config({
  cancellation: true,
  warnings: false,
});

// Components
export * from './widgets';
export * from './common';
export * from './icons';
export * from './pages';

// Render method
export * from './render';

// Expose widgets git rev and version
const REV = process.env.REV;
const VER = process.env.VER;
const NODE_ENV = process.env.NODE_ENV;
export { REV, VER, NODE_ENV };

import { ILicensedContent } from 'src/common/models/licensedContent';

/**
 * Checks if a licensed content is uploaded.
 *
 * @param {ILicensedContent} content the licensed content.
 *
 * @return {Boolean}
 */
export default function(content: ILicensedContent) {
  return ['connect_upload', 'upload'].includes(content.source);
}

import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { bind, debounce, defer, isFunction, map } from 'lodash';

import { CreatorTile, ICreatorTileProps } from 'src/widgets/CreatorTile';
import { MasonryGrid } from 'src/widgets/MasonryGrid';
import { ICampaign } from 'src/common/models/campaign';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { useForceUpdate } from 'src/utils/hooks';
import { browserHasScrollbar } from 'src/utils/uiUtils';

import { ICreatorList } from './redux/creatorListModel';

const { useImperativeHandle, useLayoutEffect, useRef, useState } = React;
import styles from './GridView.scss';

interface IOwnProps {
  className?: string;
  apiEndpoint: string;
  campaign: ICampaign;

  allowFavorite: boolean;
  showCreateFeatures: boolean;
  selfServeExperiment: boolean;
  showRelevantPostImage?: boolean;

  onCreatorSelected(socialAccount: ISocialAccount);
  inviteToCampaign(socialAccount: ISocialAccount);
  sendOffer(accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  reportAsIncorrect(accountName: string);
  onSelectPage?(pageNumber: number);
  onReachBottom();

  forwardedRef?: React.RefObject<IGridViewRef>;
}
interface IStateProps {
  isQa: boolean;
  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}
type TProps = IOwnProps & IStateProps;

export interface IGridViewRef {
  container: HTMLDivElement;
  forceUpdate: () => void;
}

const mapStateToProps = (state: ICreatorList): IStateProps => {
  return {
    isQa: state.isQa,
    socialAccounts: state.socialAccounts,
    favoriteLists: state.favoriteLists,
  };
};

const GridViewComponent: React.FC<TProps> = (props) => {
  const {
    allowFavorite,
    apiEndpoint,
    campaign,
    className,
    favoriteLists,
    forwardedRef,
    goToManage,
    inviteToCampaign,
    isQa,
    onCreatorSelected,
    onReachBottom,
    reportAsIncorrect,
    sendOffer,
    showCreateFeatures = true,
    showRelevantPostImage,
    socialAccounts,
    selfServeExperiment,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const forceUpdate = useForceUpdate();
  useImperativeHandle(forwardedRef, () => ({
    container: ref.current,
    forceUpdate: () => forceUpdate(),
  }));

  // Adjust column width on resize
  const [columnWidth, setColumnWidth] = useState<number>(null);
  useLayoutEffect(() => {
    if (typeof window !== 'object') {
      return;
    }

    const onWindowResize = () => {
      const node = ref.current;
      if (!node) {
        return;
      }

      // Consider browser's scrollbar width (usually 15px)
      const defaultColumnWidth = 330;
      const nodeWidth = node.offsetWidth - (browserHasScrollbar() ? 15 : 0);
      const minColumnWidth = Math.max(
        Math.floor(nodeWidth / Math.ceil(nodeWidth / defaultColumnWidth)),
        280,
      );
      const maxColumnWidth = Math.min(
        Math.floor(nodeWidth / Math.floor(nodeWidth / defaultColumnWidth)),
        380,
      );

      // choose column width closer to default column width
      if (
        Math.abs(minColumnWidth - defaultColumnWidth) <=
        Math.abs(maxColumnWidth - defaultColumnWidth)
      ) {
        setColumnWidth(minColumnWidth);
      } else {
        setColumnWidth(maxColumnWidth);
      }
    }

    window.addEventListener('resize', debounce(onWindowResize, 200), { passive: true });
    defer(onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  const itemProps: ICreatorTileProps[] = map(
    socialAccounts,
    (socialAccount): ICreatorTileProps => ({
      key: socialAccount.id,
      allowFavorite,
      apiEndpoint,
      isQa,
      campaign,
      socialAccount,
      favoriteLists,
      showReview: true,
      showRelevantPostImage,
      showCreateFeatures: showCreateFeatures,
      showInviteToProgram: !showCreateFeatures,
      inviteToCampaign: isFunction(inviteToCampaign) && bind(inviteToCampaign, {}, socialAccount),
      sendOffer: isFunction(sendOffer) && bind(sendOffer, {}, socialAccount.id),
      goToManage: isFunction(goToManage) && bind(goToManage, {}, socialAccount.relation_id),
      reportAsIncorrect: isFunction(reportAsIncorrect) && bind(reportAsIncorrect, {}, socialAccount.name),
      onCreatorSelected: isFunction(onCreatorSelected) && bind(onCreatorSelected, {}, socialAccount),
      selfServeExperiment,
    }),
  );

  return (
    <div
      className={cx(className, styles.GridView)}
      ref={ref}
    >
      {/* only mount MasonryGrid after column width is calculated */}
      {columnWidth && (
        <MasonryGrid
          columnWidth={columnWidth}
          itemComponent={CreatorTile}
          itemProps={itemProps}
          defaultItemHeight={CreatorTile.defaultHeight}
          onReachBottom={onReachBottom}
        />
      )}
    </div>
  );
};

const ConnectedGridView = connect<IStateProps, unknown, IOwnProps>(mapStateToProps)(GridViewComponent);

export const GridView = React.forwardRef((props: IOwnProps, ref: React.RefObject<IGridViewRef>) => (
  <ConnectedGridView {...props} forwardedRef={ref} />
));

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const BlogIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M17.4,3.3C17.4,3.3,17.4,3.3,17.4,3.3C15.5,1.2,12.9,0,10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10
      C20,7.4,19,5.1,17.4,3.3z M16.3,3.8c-0.2,0.5-0.6,1.7-1.5,2.3c-1.4-0.2-2.9,0.5-3.3,1c-0.3,0.3-0.2,0.6-0.1,0.7
      c0.1,0.2,0.2,0.3,0.4,0.3c0.1,0.2,0.3,0.7,0.4,1.2c0,0.1,0.1,0.2,0.2,0.3c0.4,0.4,0.9,0.4,1.2,0.3c-0.2,0.4-0.8,1.1-1.1,1.4
      c-0.5,0.5-0.7,0.8-0.7,1.1c0,0.4,0.3,0.7,0.5,0.9c0,0,0,0,0,0c-0.5,0.4-0.9,0.6-0.9,0.6C11.2,14,11,14.2,11,14.5
      c0,0.3,0,1.4-1.7,1.4c-0.3-0.3-0.7-1.2-0.8-1.4c0-0.1,0.1-0.2,0.1-0.3C8.8,13.9,9,13.6,9,13.3c0-0.5-0.5-1.1-0.8-1.5
      c0-0.5-0.2-0.9-0.5-1.2c-0.5-0.5-1.2-0.4-2-0.4c-0.2,0-0.4,0-0.6,0c-0.6,0-0.6-1-0.6-1c0,0,0-2.4,1.6-2.7c0.9-0.2,1.3-0.1,1.3,0
      c0,0.1,0.1,0.2,0.2,0.3c0.8,0.8,2,0.5,2.9,0.3C10.7,7,11,6.9,11.2,6.9c0.3,0,0.5-0.2,0.6-0.5c0.2-1.7,0.1-2.7-0.4-3
      c-0.3-0.2-0.6-0.2-0.9,0c-0.2,0.1-0.5,0.1-0.6,0.1C9.8,3.3,9.8,3.1,9.8,3c0-0.2,1-0.9,2.9-1.4C14.1,2.1,15.3,2.8,16.3,3.8z M10,18.8
      c-4.9,0-8.8-4-8.8-8.8s4-8.8,8.8-8.8c0.1,0,0.1,0,0.2,0C9.3,1.6,8.5,2.3,8.6,3.1c0.1,0.6,0.3,1.1,0.8,1.3c0.4,0.2,0.9,0.2,1.3,0.1
      c0,0.3,0,0.8,0,1.2c-0.2,0-0.3,0.1-0.5,0.1C9.6,6,8.9,6.2,8.5,6C8.4,5.8,8.3,5.7,8.2,5.6C7.7,5.2,6.9,5.1,5.8,5.3
      C3.3,5.8,3.3,9,3.3,9.2c0,0.8,0.4,2.2,1.8,2.2c0.2,0,0.4,0,0.7,0c0.4,0,0.9-0.1,1.1,0.1C6.9,11.6,7,11.8,7,12c0,0.2,0.1,0.3,0.2,0.4
      c0.3,0.3,0.6,0.7,0.6,0.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.5-0.3,0.9c0,0.5,0.9,2.6,1.8,2.6c1.8,0,2.9-0.8,3-2.2
      c0.3-0.1,0.7-0.4,1.1-0.8c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.3-0.7-0.5-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.8-0.8,2.2-2.3,1.1-3.4
      c-0.2-0.2-0.5-0.2-0.7-0.1c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2-0.6-0.3-0.9-0.5-1.2c0.4-0.2,1.1-0.4,1.9-0.2c0.1,0,0.3,0,0.4,0
      c1.1-0.5,1.7-1.5,2-2.3c1,1.4,1.6,3.2,1.6,5.1C18.8,14.9,14.9,18.8,10,18.8z"
    />
  </SvgIcon>
));

export { BlogIcon };

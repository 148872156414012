import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  find,
  findIndex,
  isEmpty,
  isFunction,
  isNumber,
  map,
} from 'lodash';

import { CreatorDetailOverlay } from 'src/widgets/CreatorDetailOverlay';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Select } from 'src/widgets/Select';
import { SocialProfileOverlay } from 'src/widgets/SocialProfile';
import { IToastRefHandles, Toast } from 'src/widgets/Toast';
import CreatorList from 'src/common/CreatorList';
import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { ISocialListeningWatchList } from 'src/common/models/socialListeningWatchList';
import { willShowSPv2 } from 'src/utils/connectUtils';

import actions, { WLPThunkDispatch } from './redux/actions';
import { IWatchListPage } from './redux/models';

const {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
} = React;
import styles from './WatchListPage.scss';

export interface IOwnProps {
  $state: any;

  onCreatorSelected?(socialAccount: ISocialAccount);
  sendBulkOffer(socialAccounts: ISocialAccount[]): void;
  exportCsv(socialAccounts: ISocialAccount[]): void;
  reportAsIncorrect(accountName: string): void;

  initialListId?: number;

  className?: string;
}
interface IStateProps {
  apiEndpoint: string;
  isQa: boolean;
  brand: IBrand;
  campaign: ICampaign;
  org: IOrganization;

  watchlists: ISocialListeningWatchList[];
  selectedListId: number;

  count: number;
  socialAccounts: ISocialAccount[];
}
interface IDispatchProps {
  fetchWatchLists(): Promise<ISocialListeningWatchList[]>;
  setSelectedListId(selectedListId: number): void;
  fetchSocialAccountsForPage(page: number): Promise<boolean>;
}
export type TWatchListPageProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * @type {React.FunctionComponent}
 */
const WatchListPage: React.FunctionComponent<TWatchListPageProps> = React.memo((props) => {
  const {
    $state,
    apiEndpoint,
    brand,
    campaign,
    className,
    count,
    exportCsv,
    fetchSocialAccountsForPage,
    fetchWatchLists,
    initialListId,
    isQa,
    org,
    onCreatorSelected: onCreatorSelectedProp,
    reportAsIncorrect,
    selectedListId,
    sendBulkOffer,
    setSelectedListId,
    socialAccounts,
    watchlists,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const toastRef = useRef<IToastRefHandles>(null);
  const [selectedSocialAccountId, setSelectedSocialAccountId] = useState<number>(null);
  const [isFetchingLists, setIsFetchingLists] = useState(false);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [fetchFailed, setFetchFailed] = useState(false);
  const fetchAccountsPromise = useRef<Promise<boolean>>(null);

  const watchlistDisabled = !hasFeature(org, 'watch_list');
  const options = useMemo(
    () =>
      map(watchlists, (watchlist) => ({
        label: watchlist.name,
        value: watchlist.id,
      })),
    [watchlists],
  );
  const selectedIndex = useMemo(
    () => findIndex(watchlists, (watchlist) => watchlist.id === selectedListId),
    [watchlists, selectedListId],
  );

  // so that the "no watchlists" notice will not be displayed
  useLayoutEffect(() => {
    if (!watchlistDisabled) {
      setIsFetchingLists(true);
      fetchWatchLists()
        .then((watchlists) => {
          if (watchlists.length > 0) {
            if (
              initialListId &&
              !!watchlists.find((watchlist) => watchlist.id === initialListId)
            ) {
              setSelectedListId(initialListId);
            } else {
              setSelectedListId(watchlists[0].id);
            }
          }
        })
        .catch(() => setFetchFailed(true))
        .finally(() => setIsFetchingLists(false));
    }
  }, [watchlistDisabled, fetchWatchLists, initialListId, setSelectedListId]);

  useEffect(() => {
    if (selectedListId) {
      (async () => {
        // cancel existing promise
        if (fetchAccountsPromise.current) {
          fetchAccountsPromise.current = null;
        }

        let page = 0;
        let hasNext = true;
        setIsFetchingAccounts(true);
        while (ref.current && hasNext) {
          try {
            fetchAccountsPromise.current = fetchSocialAccountsForPage(page);
            hasNext = await fetchAccountsPromise.current;

            if (hasNext) {
              page++;
            }
          } catch (err) {
            setFetchFailed(true);

            break;
          }
        }

        setIsFetchingAccounts(false);
      })();
    }
  }, [selectedListId, fetchSocialAccountsForPage]);

  const onCreatorSelected = (socialAccount: ISocialAccount) => {
    setSelectedSocialAccountId(socialAccount.id);

    if (isFunction(onCreatorSelectedProp)) {
      onCreatorSelectedProp(socialAccount);
    }
  };

  const closeDetailView = () => {
    setSelectedSocialAccountId(null);
  };

  const renderSocialProfileOverlay = () => {
    const selectedSocialAccount = find(
      socialAccounts,
      (socialAccount) => socialAccount.id === selectedSocialAccountId,
    );
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const show = isNumber(selectedSocialAccountId) || !isEmpty(selectedSocialAccount);

    return willShowSPv2(selectedSocialAccount)
      ? (
        <SocialProfileOverlay
          campaign={campaign}
          socialAccount={selectedSocialAccount}
          show={show}
          isQa={isQa}
          apiEndpoint={apiEndpoint}
          loadAccountDetails={true}
          showSimilarCreators={true}
          toastRef={toastRef}
          onRequestClose={closeDetailView}
          selfServeExperiment={selfServeExperiment}
          brandInstagramUsername={brand.instagram_username}
        />
      )
      : (
        <CreatorDetailOverlay
          org={org}
          campaign={campaign}
          apiEndpoint={apiEndpoint}
          loadDetail={true}
          loadRelated={true}
          show={show}
          socialAccount={selectedSocialAccount}
          onRequestClose={closeDetailView}
          isQa={isQa}
          selfServeExperiment={selfServeExperiment}
        />
      );
  };

  const renderWatchlist = () => watchlists.length === 0
    ? (
      <Notice showDivider={true} className={styles.errorNotice} type="disabled">
        No watch lists have been enabled. Contact your account representative to set up a
        watchlist.
      </Notice>
    )
    : <>
      {!watchlistDisabled && !fetchFailed && (
        <CreatorList
          apiEndpoint={apiEndpoint}
          brandId={brand.id}
          campaign={campaign}
          org={org}
          title={
            <Select
              options={options}
              selectedIndex={selectedIndex}
              onChange={setSelectedListId}
              theme="info"
              className={styles.select}
            />
          }
          emptyMessage={`No creators that have mentioned an account in this watchlist.`}
          loadingStatus={{
            isLoading: isFetchingAccounts,
            total: count,
            showProgress: true,
          }}
          initialSocialAccounts={socialAccounts}
          isQa={isQa}
          $state={$state}
          sendBulkOffer={sendBulkOffer}
          exportCsv={exportCsv}
          reportAsIncorrect={reportAsIncorrect}
          onCreatorSelected={onCreatorSelected}
          selectedSocialAccountId={null}
          initialDisplayMode={'grid'}
        />
      )}
      {watchlistDisabled && (
        <div className={styles.featureDisabled}>
          <div className={styles.title}>Subscription Upgrade Required.</div>
          <div className={styles.text}>
            Contact your account representative to invite influencers that mention your
            competitors or other custom watchlists!
          </div>
        </div>
      )}
      {!watchlistDisabled && fetchFailed && (
        <Notice showDivider={true} className={styles.errorNotice} type="error">
          There was an error when trying to fetch the data. If this continues to happen, please
          email support@aspireiq.com.
        </Notice>
      )}
    </>;

  return (
    <div ref={ref} className={cx(styles.WatchListPage, className)}>
      {isFetchingLists
        ? <LoadSpinner />
        : renderWatchlist()}
      {renderSocialProfileOverlay()}
      <Toast ref={toastRef} />
    </div>
  );
});

const mapStateToProps = (state: IWatchListPage): IStateProps => {
  return {
    apiEndpoint: state.apiEndpoint,
    isQa: state.isQa,
    brand: state.brand,
    campaign: state.campaign,
    org: state.org,

    watchlists: state.watchlists,
    selectedListId: state.selectedListId,

    count: state.count,
    socialAccounts: state.socialAccounts,
  };
};
const mapDispatchToProps = (dispatch: WLPThunkDispatch): IDispatchProps => {
  return {
    fetchSocialAccountsForPage: (...args) => dispatch(actions.fetchSocialAccountsForPage(...args)),
    fetchWatchLists: (...args) => dispatch(actions.fetchWatchLists(...args)),
    setSelectedListId: (...args) => dispatch(actions.setSelectedListId(...args)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WatchListPage);

import * as React from 'react';
import cx from 'classnames';

import { Button } from 'src/widgets/Button';
import { Input } from 'src/widgets/Input';
import { Toast, IToastRefHandles } from 'src/widgets/Toast';

const { useRef, useCallback } = React;
import styles from './CopyLinkInput.scss';

interface IProps {
  shareLink: string;

  header?: string;
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const CopyLinkInput: React.FunctionComponent<IProps> = React.memo((props) => {
  const linkRef = useRef<HTMLInputElement>();
  const toastRef = useRef<IToastRefHandles>();

  const visitLink = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { metaKey } = event;

      if (metaKey) {
        window.open(props.shareLink, '_blank');
      } else {
        window.location.replace(props.shareLink);
      }
    },
    [props.shareLink],
  );
  const copyLink = useCallback(() => {
    const input = linkRef.current;
    const toast = toastRef.current;

    input.select();
    document.execCommand('copy');

    if (toast) {
      toast.showMessage({
        content: 'Link copied to clipboard.',
      });
    }
  }, []);

  return (
    <div className={cx(styles.CopyLinkInput, props.className)}>
      {props.header && <div className={styles.header}>{props.header}</div>}
      <div className={styles.linkWrapper}>
        <Input
          className={styles.linkInput}
          theme="info"
          value={props.shareLink}
          readOnly={true}
          ref={linkRef}
        />
        <Button theme="info" label="View" className={styles.viewButton} onClick={visitLink} />
        <Button
          theme="info"
          label="Copy Link"
          className={styles.copyButton}
          onClick={copyLink}
        />
        <Toast ref={toastRef} />
      </div>
    </div>
  );
});

CopyLinkInput.defaultProps = {
  header: 'Share by link (anyone with the link can view and edit):',
  className: null,
};

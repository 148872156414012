import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { chain } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { Toast, IToastRefHandles } from 'src/widgets/Toast';

import { SuggestedAccountHeader } from './SuggestedAccountHeader';
import { TActionTaking } from './ActionButtonGroup';
import { SocialPostTile } from './SocialPostTile';

import failedImage from 'src/common/utils/failedImage';
import addEventLog from 'src/common/utils/addEventLog';
import { hasFeature } from 'src/common/utils/organizationHasFeature';

import { useMedia, useForceUpdate, useMobileStatus } from 'src/utils/hooks';

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';

import actions, { ILPThunkDispatch } from './redux/actions';
import { IRecommendedPage } from './redux/models';

const { useEffect, useState, useMemo, useRef } = React;
import styles from './RecommendedPage.scss';

export interface IOwnProps {
  $state: any;

  className?: string;
}
interface IStateProps {
  apiEndpoint: string;
  org: IOrganization;
  brand: IBrand;
  campaign: ICampaign;

  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}
interface IDispatchProps {
  fetchSocialAccountsForPage(page: number): Promise<boolean>;
  fetchFavoriteList(): Promise<IFavoriteList[]>;
  addToFavoriteList(accountId: number, listId: number): Promise<void>;
  createFavoriteList(name: string): Promise<void>;
  updateCampaignInvite(accountId: number, approved: boolean): Promise<void>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * @type {React.FunctionComponent}
 */
const RecommendedPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    $state,
    addToFavoriteList: addToFavoriteListProp,
    apiEndpoint,
    campaign,
    className,
    createFavoriteList: createFavoriteListProp,
    favoriteLists,
    fetchFavoriteList,
    fetchSocialAccountsForPage,
    org,
    socialAccounts,
    updateCampaignInvite,
  } = props;
  const forceUpdate = useForceUpdate();
  const mobileType = useMobileStatus();
  const columns = useMedia(
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
    [4, 3, 2],
    1,
  );
  const ref = useRef<HTMLDivElement>(null);
  const toastRef = useRef<IToastRefHandles>(null);
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [actionTaking, setActionTaking] = useState<TActionTaking>(null);
  const [hasError, setHasError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [remainingCount, setRemainingCount] = useState(campaign.recommended_actions_remaining);

  const allowFavorite = hasFeature(org, 'advanced_connect');
  const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
  // add creator to newly created list relies on latest favorites list
  const latestFavoriteLists = useRef(favoriteLists);
  latestFavoriteLists.current = favoriteLists;

  useEffect(() => {
    if (allowFavorite) {
      fetchFavoriteList();
    }
  }, [allowFavorite, fetchFavoriteList]);

  useEffect(() => {
    if (selectedIndex >= socialAccounts.length - 1) {
      (async () => {
        if (!hasNext || isLoading) {
          return;
        }

        setIsLoading(true);
        try {
          const newHasNext = await fetchSocialAccountsForPage(page);

          setHasNext(newHasNext);
          setPage(page + 1);
        } catch (err) {
          setHasError(true);
        }

        setIsLoading(false);
      })();
    }
  }, [selectedIndex, socialAccounts, hasNext, isLoading, fetchSocialAccountsForPage, page]);

  const selectedAccount = useMemo(() => socialAccounts[selectedIndex], [
    selectedIndex,
    socialAccounts,
  ]);

  const takeAction = async (approved: boolean) => {
    try {
      await updateCampaignInvite(selectedAccount.id, approved);

      setSelectedIndex(selectedIndex + 1);
      setRemainingCount(remainingCount - 1);

      const node = ref && ref.current;
      if (node) {
        node.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    } catch (err) {
      console.error(err);

      throw err;
    }
  };

  const skipCreator = async (offensive: boolean, reason?: string) => {
    if (offensive) {
      addEventLog('offensive', {
        account_id: selectedAccount.id,
      });
    }

    const toast = toastRef.current;

    setActionTaking(offensive ? 'reporting' : 'skipping');

    try {
      await takeAction(false);

      setActionTaking(null);

      if (toast) {
        toast.showMessage({
          content: (
            <div>
              {offensive ? 'Reported' : 'Skipped'} creator
              <span> {selectedAccount.name || selectedAccount.username}</span>
              {offensive ? ` for ${reason}.` : ' for campaign.'}
            </div>
          ),
        });
      }
    } catch (_) {
      setActionTaking(null);

      if (toast) {
        toast.showMessage({
          content: `There was an error when trying to ${
            offensive ? 'report' : 'skip'
          } the creator.`,
          type: 'error',
        });
      }
    }
  };

  const inviteCreator = async () => {
    const toast = toastRef.current;

    setActionTaking('inviting');

    try {
      await takeAction(true);

      setActionTaking(null);

      if (toast) {
        toast.showMessage({
          content: (
            <div>
              Invited creator
              <span> {selectedAccount.name || selectedAccount.username}</span> to campaign.
            </div>
          ),
        });
      }
    } catch (_) {
      setActionTaking(null);

      if (toast) {
        toast.showMessage({
          content: `There was an error when trying to invite the creator to campaign.`,
          type: 'error',
        });
      }
    }
  };

  const sendOffer = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { metaKey } = event;

    const url = $state.href('new_project.terms', {
      offerType: 'offer',
      offerableId: selectedAccount.id,
      campaignId: campaign.id,
    });

    if (metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  const editCampaignRecommendation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { metaKey } = event;

    const url = $state.href('campaign_edit.recommendation', {
      campaignId: campaign.id,
    });

    if (metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  const goToSearch = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { metaKey } = event;

    const url = $state.href('connect.browse_creators');

    if (metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  const handleImageLoadError = (url: string) => {
    failedImage.add(url);

    forceUpdate();
  };

  const createFavoriteList = async (name: string) => {
    const toast = toastRef.current;

    try {
      await createFavoriteListProp(name);

      if (toast) {
        toast.showMessage({
          content: (
            <div>
              Created new favorite list <span>{name}</span>.
            </div>
          ),
        });
      }
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to create new favorite list.',
          type: 'error',
        });
      }

      console.log(err);
    }
  };

  const addToFavoriteList = async (accountId: number, listId: number) => {
    const toast = toastRef.current;

    try {
      await addToFavoriteListProp(accountId, listId);

      const socialAccount = socialAccounts.find(
        (socialAccount) => socialAccount.id === accountId,
      );
      const favoriteList = latestFavoriteLists.current.find(
        (favoriteList) => favoriteList.id === listId,
      );

      if (toast) {
        toast.showMessage({
          content: (
            <div>
              Added creator
              {socialAccount && <span> {socialAccount.name || socialAccount.username} </span>} to
              favorite list <span>{favoriteList.name}</span>.
            </div>
          ),
        });
      }
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to add creator to the favorite list.',
          type: 'error',
        });
      }

      console.log(err);
    }
  };

  const showNoMoreRecommendedCreatorsNotice = (
    !hasError &&
    remainingCount > 0 &&
    (campaign.potential_recommended_count <= 0 || (!selectedAccount && !isLoading))
  );

  return (
    <div ref={ref} className={cx(styles.RecommendedPage, className)}>
      {!selectedAccount && isLoading && <LoadSpinner className={styles.loadSpinner} />}
      {selectedAccount && <>
        <SuggestedAccountHeader
          mobileType={mobileType}
          apiEndpoint={apiEndpoint}
          campaign={campaign}
          org={org}
          onEditCampaign={editCampaignRecommendation}
          socialAccount={selectedAccount}
          remainingCount={remainingCount}
          actionTaking={actionTaking}
          onSkip={skipCreator}
          onInvite={inviteCreator}
          onOffer={sendOffer}
          allowFavorite={allowFavorite}
          favoriteLists={favoriteLists}
          addToFavoriteList={addToFavoriteList}
          createFavoriteList={createFavoriteList}
          selfServeExperiment={selfServeExperiment}
        />
        <div
          className={styles.posts}
          style={{
            columnCount: columns,
          }}
        >
          {chain(selectedAccount.posts)
            .uniqBy((post) => post.image)
            .filter((post) => !failedImage.contains(post.image))
            .slice(0, 12)
            .value()
            .map((socialPost) => (
              <SocialPostTile
                key={socialPost.image}
                className={styles.post}
                socialPost={socialPost}
                onImageLoadError={() => handleImageLoadError(socialPost.image)}
              />
            ))}
        </div>
      </>}
      {!hasError && remainingCount <= 0 && (
        <Notice showDivider={true} className={styles.notice} type="info">
          <div>
            <div className={styles.title}>You&apos;ll have more creators to invite tomorrow.</div>
            <div className={styles.text}>
              Every day we’ll send 20 great creators your way. Check back tomorrow for more!
            </div>
          </div>
        </Notice>
      )}
      {showNoMoreRecommendedCreatorsNotice && (
        <Notice showDivider={true} className={styles.notice} type="disabled">
          <div>
            <div className={styles.title}>
              There are no more recommended creators available for you to view.
            </div>
            {campaign.setup_completed && hasFeature(org, 'disable_filters') && (
              <div className={styles.text}>
                Try editing your campaign
                <span className={styles.link} onClick={editCampaignRecommendation}>
                  &nbsp;preferences&nbsp;
                </span>
                  to see more creators or
                <span className={styles.link} onClick={goToSearch}>
                  &nbsp;search for creators&nbsp;
                </span>
                .
              </div>
            )}
          </div>
        </Notice>
      )}
      {hasError && (
        <Notice showDivider={true} className={styles.notice} type="error">
          There was an error when trying to fetch the accounts. If this continues to happen, please
          email support@aspireiq.com.
        </Notice>
      )}
      <Toast ref={toastRef} />
    </div>
  );
});

const mapStateToProps = (state: IRecommendedPage): IStateProps => {
  return {
    apiEndpoint: state.apiEndpoint,
    org: state.org,
    brand: state.brand,
    campaign: state.campaign,

    socialAccounts: state.socialAccounts,
    favoriteLists: state.favoriteLists,
  };
};
const mapDispatchToProps = (dispatch: ILPThunkDispatch): IDispatchProps => {
  return {
    fetchSocialAccountsForPage: (...args) => dispatch(actions.fetchSocialAccountsForPage(...args)),
    fetchFavoriteList: (...args) => dispatch(actions.fetchFavoriteList(...args)),
    addToFavoriteList: (...args) => dispatch(actions.addToFavoriteList(...args)),
    createFavoriteList: (...args) => dispatch(actions.createFavoriteList(...args)),
    updateCampaignInvite: (...args) => dispatch(actions.updateCampaignInvite(...args)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendedPage);

import * as React from 'react';
import cx from 'classnames';

import styles from './Progress.scss';

type IProgressType = 'info' | 'success' | 'error';
interface IProps {
  percentage: number;

  label?: string;
  labelClassName?: string;
  type?: IProgressType;
  className?: string;
}
type TDefaultProp = 'label' | 'labelClassName' | 'type';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class Progress extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    label: '',
    labelClassName: '',
    type: 'info',
  };

  private ref: React.RefObject<HTMLDivElement>;
  private percentageThreshold: number;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();
    this.percentageThreshold = 30;
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const node = this.ref.current;
    const { clientWidth } = node;

    this.percentageThreshold = Math.round(32 * (100 / clientWidth));
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { type, label, labelClassName, percentage, className } = this.props;
    const adjustedPercentage = Math.max(0, Math.min(100, percentage || 0));

    return (
      <div ref={this.ref} className={cx(styles.Progress, className)}>
        <div className={styles.background}>
          <div
            className={cx(styles.progress, styles[type])}
            style={{
              width: `${adjustedPercentage}%`,
            }}
          >
            <div
              className={cx(styles.text, {
                [styles.right]: adjustedPercentage < this.percentageThreshold,
              })}
            >
              {adjustedPercentage}%
            </div>
          </div>
        </div>
        {label && <div className={cx(styles.label, labelClassName)}>{label}</div>}
      </div>
    );
  }
}

import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';
import { isSameDay } from 'date-fns';

import { ArrowDownFilledIcon, FastForwardIcon } from 'src/icons';
import { Popover } from 'src/widgets/Popover';

import {
  IDateTuple,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  getNextMonth,
  getPreviousMonth,
  getCalendarDates,
} from './utils';

const { useState, useEffect, useMemo } = React;

import styles from './DatePicker.scss';

interface IProps {
  show: boolean;
  onRequestClose?();
  mountRef: React.RefObject<HTMLElement>;
  onDateSelected(date: Date);
  selectedDate: Date;
}

export const DatePickerDropdown: React.FC<IProps> = React.memo((props) => {
  const defaultDate = useMemo(() => {
    return new Date();
  }, []);

  const selectedDate = useMemo(() => {
    return props.selectedDate || defaultDate;
  }, [props.selectedDate, defaultDate]);

  const [year, setYear] = useState<number>(selectedDate.getFullYear());
  const [month, setMonth] = useState<number>(selectedDate.getMonth() + 1);

  useEffect(() => {
    setYear(selectedDate.getFullYear());
    setMonth(selectedDate.getMonth() + 1);
  }, [selectedDate]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * @private
   * Selectes a date.
   *
   * @param {IDateTuple} date the selected date.
   */
  const selectDate = (date: IDateTuple, e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    const selectedDate = new Date(date.year, date.month - 1, date.day);
    props.onDateSelected(selectedDate);
  };

  /**
   * @private
   */
  const goToPrevYear = (e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    setYear(year - 1);
  };

  /**
   * @private
   */
  const goToNextYear = (e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    setYear(year + 1);
  };

  /**
   * @private
   */
  const goToPrevMonth = (e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    const { year: newYear, month: newMonth } = getPreviousMonth(year, month);
    setYear(newYear);
    setMonth(newMonth);
  };

  /**
   * @private
   */
  const goToNextMonth = (e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    const { year: newYear, month: newMonth } = getNextMonth(year, month);
    setYear(newYear);
    setMonth(newMonth);
  };

  /**
   * @private
   * Renders the year and month.
   *
   * @return {JSX.Element}
   */
  const renderYearAndMonth = (): JSX.Element => {
    const monthName = CALENDAR_MONTHS[month - 1];

    return (
      <div className={styles.yearAndMonth}>
        <div className={cx(styles.action, styles.prevYear)} onClick={goToPrevYear}>
          <FastForwardIcon size={14} className={styles.icon} />
        </div>
        <div className={cx(styles.action, styles.prevMonth)} onClick={goToPrevMonth}>
          <ArrowDownFilledIcon size={14} className={styles.icon} />
        </div>
        <div className={styles.label}>
          {monthName} {year}
        </div>
        <div className={cx(styles.action, styles.nextMonth)} onClick={goToNextMonth}>
          <ArrowDownFilledIcon size={14} className={styles.icon} />
        </div>
        <div className={cx(styles.action, styles.nextYear)} onClick={goToNextYear}>
          <FastForwardIcon size={14} className={styles.icon} />
        </div>
      </div>
    );
  };

  /**
   * @private
   * Renders the week days.
   *
   * @return {JSX.Element}
   */
  const renderWeekDays = (): JSX.Element => {
    return (
      <div className={styles.weekdaysLabel}>
        {map(WEEK_DAYS, (dayName) => (
          <div key={dayName} className={styles.labelColumn}>
            {dayName.substr(0, 2)}
          </div>
        ))}
      </div>
    );
  };

  /**
   * @private
   * Renders the calendar dates.
   *
   * @return {JSX.Element}
   */
  const renderCalendarDates = (): JSX.Element => {
    const calendarDates = getCalendarDates(year, month);

    return (
      <div className={styles.calendarDates}>
        {map(calendarDates, (date, index) => {
          const calendarYear = date.year;
          const calendarMonth = date.month;
          const calendarDay = date.day;

          const selected = isSameDay(
            new Date(calendarYear, date.month - 1, date.day),
            selectedDate,
          );

          return (
            <div
              key={index}
              className={cx(styles.calendarDate, {
                [styles.disabled]: calendarMonth !== month,
                [styles.active]: selected,
              })}
              onClick={(e) => selectDate(date, e)}
            >
              {calendarDay}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Popover
      className={styles.Popover}
      mountRef={props.mountRef}
      minWidth={330}
      show={props.show}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.content}>
        {renderYearAndMonth()}
        {renderWeekDays()}
        {renderCalendarDates()}
      </div>
    </Popover>
  );
});

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { ActionTypes, IRecommendedPageAction, IRecommendedPage } from './models';

type RPThunkAction<T> = ThunkAction<Promise<T>, IRecommendedPage, unknown, IRecommendedPageAction>;
export type ILPThunkDispatch = ThunkDispatch<IRecommendedPage, unknown, IRecommendedPageAction>;

import endpoints from 'src/common/config/endpoints';
import addEventLog from 'src/common/utils/addEventLog';

/***************************
 ***** Private actions *****
 **************************/
const setSocialAccounts = (socialAccounts: ISocialAccount[]): IRecommendedPageAction => {
  return {
    type: ActionTypes.SET_SOCIAL_ACCOUNTS,
    payload: {
      socialAccounts,
    },
  };
};

const setFavoriteLists = (favoriteLists: IFavoriteList[]): IRecommendedPageAction => {
  return {
    type: ActionTypes.SET_FAVORITE_LISTS,
    payload: {
      favoriteLists,
    },
  };
};

const updateFavoriteList = (id: number, favoriteList: IFavoriteList): IRecommendedPageAction => {
  return {
    type: ActionTypes.UPDATE_FAVORITE_LIST,
    payload: {
      id,
      favoriteList,
    },
  };
};

const addFavoriteList = (favoriteList: IFavoriteList): IRecommendedPageAction => {
  return {
    type: ActionTypes.ADD_FAVORITE_LIST,
    payload: {
      favoriteList,
    },
  };
};

/***************************
 ***** public actions *****
 **************************/
const fetchSocialAccountsForPage = (page: number): RPThunkAction<boolean> => {
  return async (dispatch, getState): Promise<boolean> => {
    const state: IRecommendedPage = getState();
    const { campaign, apiEndpoint, socialAccounts: existingSocialAccounts } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.socialAccountEndpoint}?recommended=true&campaign_id=${campaign.id}&page=${page}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      const socialAccounts = json.data.data || [];
      const hasNext = json.data.has_next;

      // decide whether to reset or append
      dispatch(
        setSocialAccounts(
          page === 0 ? socialAccounts : [...existingSocialAccounts, ...socialAccounts],
        ),
      );

      return hasNext;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const updateCampaignInvite = (accountId: number, approved: boolean): RPThunkAction<void> => {
  return async (_, getState): Promise<void> => {
    const state: IRecommendedPage = getState();
    const { campaign, apiEndpoint } = state;

    const payload: any = {
      approved: !!approved,
      rejected: !approved,
      campaign_id: campaign.id,
      account_id: accountId,
      source: 'connect.invite_creators',
    };

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.campaignInviteEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const fetchFavoriteList = (): RPThunkAction<IFavoriteList[]> => {
  return async (dispatch, getState): Promise<IFavoriteList[]> => {
    const state = getState();
    const { brand, apiEndpoint } = state;

    try {
      const resp = await fetch(
        `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}?brand_id=${brand.id}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      );

      const json = await resp.json();
      const list: IFavoriteList[] = json.data.data || [];
      dispatch(setFavoriteLists(list));

      return list;
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const addToFavoriteList = (accountId: number, listId: number): RPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IRecommendedPage = getState();
    const { campaign, favoriteLists, apiEndpoint } = state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    const payload: any = {
      account_id: accountId,
      list_id: listId,
      campaign_id: campaign.id,
    };
    const count = 1;

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.favoritesListElementEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(
          updateFavoriteList(listId, {
            ...favoriteList,
            element_count: favoriteList.element_count + count,
          }),
        );

        if (count === 1) {
          addEventLog('add_to_favorites_list', {
            state: 'connect.browse_creators_v2',
          });
        } else if (count > 1) {
          addEventLog('bulk_add_to_favorites_list', {
            count,
            state: 'connect.browse_creators_v2',
          });
        }

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

const createFavoriteList = (name: string): RPThunkAction<void> => {
  return async (dispatch, getState): Promise<void> => {
    const state: IRecommendedPage = getState();
    const { brand, apiEndpoint } = state;

    const payload = {
      brand_id: brand.id,
      name,
    };

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch(addFavoriteList(json.data));

        return;
      } else {
        throw new Error(json.status && json.status.error_msg);
      }
    } catch (err) {
      console.log(err);

      throw err;
    }
  };
};

export default {
  fetchSocialAccountsForPage,
  updateCampaignInvite,
  fetchFavoriteList,
  createFavoriteList,
  addToFavoriteList,
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const LayoutGridIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M9.02,1.99H3.91C2.86,1.99,2,2.85,2,3.9v5.11c0,1.05,0.86,1.91,1.91,1.91h5.11c1.05,0,1.91-0.86,1.91-1.91V3.9
      C10.93,2.85,10.07,1.99,9.02,1.99z M9.82,9.01c0,0.44-0.36,0.8-0.8,0.8H3.91c-0.44,0-0.8-0.36-0.8-0.8V3.9c0-0.44,0.36-0.8,0.8-0.8
      h5.11c0.44,0,0.8,0.36,0.8,0.8V9.01z"
    />
    <path
      d="M20.09,1.97h-5.11c-1.05,0-1.91,0.86-1.91,1.91v5.11c0,1.05,0.86,1.91,1.91,1.91h5.11
      c1.05,0,1.91-0.86,1.91-1.91V3.88C22,2.83,21.14,1.97,20.09,1.97z M20.89,8.99c0,0.44-0.36,0.8-0.8,0.8h-5.11
      c-0.44,0-0.8-0.36-0.8-0.8V3.88c0-0.44,0.36-0.8,0.8-0.8h5.11c0.44,0,0.8,0.36,0.8,0.8V8.99z"
    />
    <path
      d="M9.02,13.1H3.91C2.86,13.1,2,13.95,2,15.01v5.11c0,1.05,0.86,1.91,1.91,1.91h5.11c1.05,0,1.91-0.86,1.91-1.91
      v-5.11C10.93,13.95,10.07,13.1,9.02,13.1z M9.82,20.12c0,0.44-0.36,0.8-0.8,0.8H3.91c-0.44,0-0.8-0.36-0.8-0.8v-5.11
      c0-0.44,0.36-0.8,0.8-0.8h5.11c0.44,0,0.8,0.36,0.8,0.8V20.12z"
    />
    <path
      d="M20.09,13.08h-5.11c-1.05,0-1.91,0.86-1.91,1.91v5.11c0,1.05,0.86,1.91,1.91,1.91h5.11
      c1.05,0,1.91-0.86,1.91-1.91v-5.11C22,13.94,21.14,13.08,20.09,13.08z M20.89,20.1c0,0.44-0.36,0.8-0.8,0.8h-5.11
      c-0.44,0-0.8-0.36-0.8-0.8v-5.11c0-0.44,0.36-0.8,0.8-0.8h5.11c0.44,0,0.8,0.36,0.8,0.8V20.1z"
    />
  </SvgIcon>
));

export { LayoutGridIcon };

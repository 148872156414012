import { sumBy } from 'lodash';

import getSocialAccounts from './getSocialAccounts';

import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';
import { IAdvertiserPublisherRelation } from 'src/common/models/advertiserPublisherRelation';

export default function getReach(
  relation: IAdvertiserPublisherRelation,
  network?: TNetworkIdentifier,
) {
  const accounts = getSocialAccounts(relation);

  return sumBy(accounts, (account) => {
    if (!network || account.network_identifier === network) {
      return account.reach;
    }

    return 0;
  });
}

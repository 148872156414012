import * as React from 'react';
import numeral from 'numeral';
import {
  capitalize,
  defaultsDeep,
  first,
  isArray,
  isEmpty,
  isNumber,
  isString,
  keys,
  map,
  reduce,
  upperFirst,
} from 'lodash';

import {
  BookmarkIcon,
  ChatSmileIcon,
  CheckCircleIcon,
  EyeIcon,
  FavoriteIcon,
  GeoPinIcon,
  GlobeIcon,
  ImageNoBorderIcon,
  NavigationIcon,
  PersonCheckIcon,
  PersonIcon,
  SmileIcon,
  ThumbsUpIcon,
  TimerIcon,
  TypingMessageIcon,
  UndoIcon,
  VideoFilledIcon,
} from 'src/icons';
import { ISvgIconProps, TSvgIcon } from 'src/widgets/Icon';
import networkConfig from 'src/common/config/networkConfig';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';
import {
  IPinterestAccount,
  ISocialAccount,
  ITiktokAccount,
  IYoutubeAccount,
} from 'src/common/models/socialAccount';
import { tooltipText } from 'src/utils/tooltipsUtils';
import { ITooltipProps } from 'src/widgets/Tooltip/Tooltip';

import { getAggregatedGenderData } from './getAggregatedGenderData';

import colors from '../../../styles/_colors.scss';

const EMPTY = '--';

export interface ISocialProfileDetailConfig {
  icon?: {
    component: TSvgIcon;
    props?: ISvgIconProps;
  };
  network: TNetworkIdentifier[];
  title: string;
  tooltipProps?: ITooltipProps;
  tooltipText?: React.ReactChild;
  type: DetailConfigType;
  value?: React.ReactChild;
}

/**
 * Keys represent the title;
 * values represent the socialAccount field;
 * both are arbitrary but kept accurate to avoid confusion
 */
export enum DetailConfigType {
  AgeRange = 'demographics_report.age',
  AudienceAuthenticity = 'follower_score',
  AverageClicksPerPost = 'average_clicks',
  AverageCloseupsPerPin = 'closeups', // specific to Pinterest
  AverageCommentsPerPost = 'average_comments_count',
  AverageEngagementPerPost = 'engagement',
  AverageImpressionsPerPost = 'impressions',
  AverageLikesPerPost = 'recent_average_likes',
  AverageSavesPerPost = 'saves',
  BounceRate = 'bounce_rate',
  CommentSentiment = 'report.comment_sentiment.score',
  CommentsPerPost = 'recent_average_comment_count',
  DislikesPerPost = 'recent_average_dislikes',
  EngagementRate = 'engagement_ratio',
  Followers = 'reach',
  Gender = 'gender',
  ImpressionPerStory = 'recent_stories_impressions',
  Interests = 'interests',
  Likes = 'likes',
  LikesPerPost = 'recent_average_likes',
  LikesToCommentsRatio = 'likes_comments_ratio',
  OriginalContentRatio = 'original_pins_percentage',
  PageViewsPerVisit = 'page_views',
  PostImpressionRatio = 'impressions_ratio',
  SecondsOnSite = 'time_on_site',
  SharesPerPost = 'shares',
  StoriesImpressionRatio = 'stories_impressions_ratio',
  TopCountry = 'demographics_report.country',
  TopState = 'demographics_report.state',
  ViewsPerPost = 'views_count',
}

const getDetailConfig = (params: {
  socialAccount: ISocialAccount | IPinterestAccount;
  network: TNetworkIdentifier;
  detailType: DetailConfigType;
  styles?: { [key: string]: any };
}): ISocialProfileDetailConfig => {
  const {
    detailType,
    network,
    socialAccount,
    styles,
  } = params;

  const netConfig = networkConfig[network];
  const contentName = upperFirst(netConfig.contentName);
  const engagementName = upperFirst(netConfig.engagementName);

  const extendConfig = (config: DeepPartial<ISocialProfileDetailConfig>) => (
    defaultsDeep(config, {
      type: detailType,
      icon: {
        props: {
          fill: colors.darkBlue,
        },
      },
    })
  );

  // Sorted by key so it's easy to find something
  switch (detailType) {
    case DetailConfigType.AgeRange:
      return extendConfig({
        title: 'Age Range',
        network: ['instagram'],
        icon: {
          component: ChatSmileIcon,
          props: { size: 20 },
        },
        value: (() => {
          if (!isEmpty(socialAccount.demographics_report) && !isEmpty(socialAccount.demographics_report.age)) {
            const ageData = socialAccount.demographics_report.age;
            // Find the key with the highest value
            return reduce(
              keys(ageData),
              (a, b) => ageData[a] > ageData[b] ? a : b,
              '',
            );
          }
          return EMPTY;
        })(),
      });

    case DetailConfigType.AudienceAuthenticity:
      return extendConfig({
        title: 'Aud. Authenticity',
        network: ['instagram'],
        icon: {
          component: PersonCheckIcon,
          props: { size: 20 },
        },
        tooltipText: !socialAccount.is_ambassador
          ? tooltipText.audienceAuthenticity
          : tooltipText.audienceAuthenticityReviewed,
        tooltipProps: {
          maxWidth: 400,
        },
        value: (() => {
          if (socialAccount.is_ambassador) {
            return (
              <span>
                <CheckCircleIcon
                  className={styles.reviewedIcon}
                  size={18}
                />
                Reviewed by AspireIQ
              </span>
            );
          }

          const score = isNumber(socialAccount.follower_score)
            ? numeral(socialAccount.follower_score / 100).format('0%')
            : null;
          const label = isString(socialAccount.audience_authenticity_label)
            ? socialAccount.audience_authenticity_label
            : null;

          return score !== null || label !== null
            ? (
              <span className={styles && label ? styles[label.toLowerCase()] : ''}>
                {score} {label && <strong>{label}</strong>}
              </span>
            )
            : EMPTY;
        })(),
      });

    case DetailConfigType.AverageCloseupsPerPin:
      return extendConfig({
        title: `Avg. Closeups per ${contentName}`,
        network: ['pinterest'],
        icon: {
          component: EyeIcon,
          props: { size: 18 },
        },
        value: isNumber((socialAccount as IPinterestAccount).closeups)
          ? numeral((socialAccount as IPinterestAccount).closeups).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.AverageCommentsPerPost:
      return extendConfig({
        title: `Avg. Comments per ${contentName}`,
        network: ['instagram', 'pinterest'],
        icon: {
          component: ChatSmileIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.average_comments_count)
          ? numeral(socialAccount.average_comments_count).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.AverageClicksPerPost:
      return extendConfig({
        title: `Avg. Clicks per ${contentName}`,
        network: ['pinterest'],
        icon: {
          component: NavigationIcon,
          props: { size: 18 },
        },
        value: isNumber((socialAccount as IPinterestAccount).average_clicks)
          ? numeral((socialAccount as IPinterestAccount).average_clicks).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.AverageEngagementPerPost:
      return extendConfig({
        title: `Avg. ${engagementName} per ${contentName}`,
        network: ['instagram', 'pinterest'],
        icon: {
          component: SmileIcon,
          props: { size: 20 },
        },
        value: isNumber(socialAccount.engagement)
          ? numeral(socialAccount.engagement).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.AverageImpressionsPerPost:
      return extendConfig({
        title: `Avg. Impressions per ${contentName}`,
        network: ['instagram', 'pinterest'],
        icon: {
          component: BookmarkIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.impressions)
          ? numeral(socialAccount.impressions).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.AverageLikesPerPost:
      return extendConfig({
        title: `Likes per ${contentName}`,
        network: ['instagram', 'tiktok'],
        icon: {
          component: FavoriteIcon,
          props: { size: 20 },
        },
        value: (() => {
          const val = network === 'tiktok'
            ? (socialAccount as ITiktokAccount).recent_average_likes
            : socialAccount.likes;
          return isNumber(val)
            ? numeral(val).format('0,0')
            : EMPTY;
        })(),
      });

    case DetailConfigType.AverageSavesPerPost:
      return extendConfig({
        title: `Avg. Saves per ${contentName}`,
        network: ['instagram', 'pinterest'],
        icon: {
          component: EyeIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.saves)
          ? numeral(socialAccount.saves).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.BounceRate:
      return extendConfig({
        title: 'Bounce Rate',
        network: ['blog'],
        icon: {
          component: UndoIcon,
          props: { size: 18 },
        },
        value: socialAccount.bounce_rate || EMPTY,
      });

    case DetailConfigType.CommentsPerPost:
      return extendConfig({
        title: `Comments per ${contentName}`,
        network: ['tiktok', 'youtube'],
        icon: {
          component: TypingMessageIcon,
          props: { size: 22 },
        },
        value: isNumber((socialAccount as IYoutubeAccount).recent_average_comment_count)
          ? numeral((socialAccount as IYoutubeAccount).recent_average_comment_count).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.CommentSentiment:
      return extendConfig({
        title: 'Comment Sentiment',
        network: ['instagram'],
        icon: {
          component: ImageNoBorderIcon,
          props: { size: 20 },
        },
        tooltipText: tooltipText.commentSentiment,
        value: isNumber(socialAccount.report?.comment_sentiment?.score)
          ? ((score: number) => {
            if (score > 0.6) {
              return <span className={styles.positive}>Very Positive</span>;
            } else if (score > 0.2) {
              return <span className={styles.positive}>Mostly Positive</span>;
            } else if (score > -0.2) {
              return <span className={styles.neutral}>Neutral</span>;
            } else {
              return <span className={styles.negative}>Negative</span>;
            }
          })(socialAccount.report.comment_sentiment.score)
          : EMPTY,
      });

    case DetailConfigType.DislikesPerPost:
      return extendConfig({
        title: 'Dislikes per Video',
        network: ['youtube'],
        icon: {
          component: ThumbsUpIcon,
          props: {
            size: 18,
            className: styles.thumbsDownIcon,
          },
        },
        value: isNumber((socialAccount as IYoutubeAccount).recent_average_dislikes)
          ? numeral((socialAccount as IYoutubeAccount).recent_average_dislikes).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.EngagementRate:
      return extendConfig({
        title: 'Engagement Rate',
        network: ['instagram', 'pinterest'],
        icon: {
          component: BookmarkIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.engagement_ratio)
          ? numeral(socialAccount.engagement_ratio).format('0.00%')
          : EMPTY,
      });

    case DetailConfigType.Gender:
      return extendConfig({
        title: 'Gender',
        network: ['instagram'],
        icon: {
          component: PersonIcon,
          props: { size: 20 },
        },
        value: (() => {
          const genderData = getAggregatedGenderData(socialAccount);
          if (!isEmpty(genderData)) {
            // Find the key (gender) with the highest value
            return capitalize(
              reduce(
                keys(genderData),
                (a, b) => genderData[a] > genderData[b] ? a : b,
                '',
              )
            );
          }
          return EMPTY;
        })(),
      });

    case DetailConfigType.ImpressionPerStory:
      return extendConfig({
        title: 'Impression per Story',
        network: ['instagram'],
        icon: {
          component: ImageNoBorderIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.recent_stories_impressions)
          ? numeral(socialAccount.recent_stories_impressions).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.Interests:
      return extendConfig({
        title: 'Audience Interests',
        network: ['instagram'],
        icon: {
          component: FavoriteIcon,
          props: { size: 18 },
        },
        value: !isEmpty(socialAccount.interests)
          ? first(socialAccount.interests)
          : EMPTY,
      });

    case DetailConfigType.Likes:
      return extendConfig({
        title: 'Total Likes',
        network: ['facebook', 'tiktok'],
        icon: {
          component: ThumbsUpIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.likes)
          ? numeral(socialAccount.likes).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.LikesPerPost:
      return extendConfig({
        title: `Likes per ${contentName}`,
        network: ['youtube'],
        icon: {
          component: ThumbsUpIcon,
          props: { size: 18 },
        },
        value: isNumber((socialAccount as IYoutubeAccount).recent_average_likes)
          ? numeral((socialAccount as IYoutubeAccount).recent_average_likes).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.LikesToCommentsRatio:
      return extendConfig({
        title: 'Likes to Comments Ratio',
        network: ['instagram'],
        icon: {
          component: FavoriteIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.likes_comments_ratio)
          ? `${numeral(socialAccount.likes_comments_ratio / 100).format('0,0')} : 1`
          : EMPTY,
      });

    case DetailConfigType.OriginalContentRatio:
      return extendConfig({
        title: 'Original Content Ratio',
        network: ['pinterest'],
        icon: {
          component: ImageNoBorderIcon,
          props: { size: 18 },
        },
        value: isNumber((socialAccount as IPinterestAccount).original_pins_percentage)
          ? numeral((socialAccount as IPinterestAccount).original_pins_percentage).format('0%')
          : EMPTY,
      });

    case DetailConfigType.PageViewsPerVisit:
      return extendConfig({
        title: 'Page Views Per Visit',
        network: ['blog'],
        icon: {
          component: EyeIcon,
          props: { size: 22 },
        },
        value: isNumber(socialAccount.page_views)
          ? numeral(socialAccount.page_views).format('0.00')
          : EMPTY,
      });

    case DetailConfigType.PostImpressionRatio:
      return extendConfig({
        title: 'Post Impression Ratio',
        network: ['instagram'],
        icon: {
          component: PersonCheckIcon,
          props: { size: 20 },
        },
        tooltipText: tooltipText.postImpressionRatio,
        tooltipProps: {
          maxWidth: 260,
        },
        value: isNumber(socialAccount.impressions_ratio)
          ? `${numeral(socialAccount.impressions_ratio).format('0,0')} : 1`
          : EMPTY,
      });

    case DetailConfigType.Followers:
      return extendConfig({
        title: 'Followers',
        network: ['instagram', 'pinterest', 'tiktok'],
        icon: {
          component: PersonIcon,
          props: { size: 18 },
        },
        value: isNumber(socialAccount.follower_count)
          ? numeral(socialAccount.follower_count).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.SecondsOnSite:
      return extendConfig({
        title: 'Seconds on Site',
        network: ['blog'],
        icon: {
          component: TimerIcon,
          props: { size: 20 },
        },
        value: isString(socialAccount.time_on_site)
          ? numeral(socialAccount.time_on_site).format('0.[0]a')
          : EMPTY,
      });

    case DetailConfigType.SharesPerPost:
      return extendConfig({
        title: `Shares per ${contentName}`,
        network: ['youtube'],
        icon: {
          component: UndoIcon,
          props: { size: 22 },
        },
        value: isNumber((socialAccount as IYoutubeAccount).shares)
          ? numeral((socialAccount as IYoutubeAccount).shares).format('0,0')
          : EMPTY,
      });

    case DetailConfigType.StoriesImpressionRatio:
      return extendConfig({
        title: 'Stories Impression Ratio',
        network: ['instagram'],
        icon: {
          component: VideoFilledIcon,
          props: { size: 18 },
        },
        tooltipText: tooltipText.storyImpressionRatio,
        tooltipProps: {
          maxWidth: 260,
        },
        value: isNumber(socialAccount.stories_impressions_ratio)
          ? numeral(socialAccount.stories_impressions_ratio / 100).format('0%')
          : EMPTY,
      });

    case DetailConfigType.TopCountry:
      return extendConfig({
        title: 'Top Country',
        network: ['instagram'],
        icon: {
          component: GlobeIcon,
          props: { size: 20 },
        },
        value: isArray(socialAccount.demographics_report?.country)
          ? `${first(first(socialAccount.demographics_report.country))}` // force string
          : EMPTY,
      });

    case DetailConfigType.TopState:
      return extendConfig({
        title: 'Top State',
        network: ['instagram'],
        icon: {
          component: GeoPinIcon,
          props: { size: 22 },
        },
        value: isArray(socialAccount.demographics_report?.state)
          ? `${first(first(socialAccount.demographics_report.state))}` // force string
          : EMPTY,
      });

    case DetailConfigType.ViewsPerPost:
      return extendConfig({
        title: `Views per ${contentName}`,
        network: ['tiktok', 'youtube'],
        icon: {
          component: EyeIcon,
          props: { size: 22 },
        },
        value: isNumber((socialAccount as ITiktokAccount).recent_geometric_mean_view_count)
          ? numeral((socialAccount as ITiktokAccount).recent_geometric_mean_view_count).format('0,0')
          : EMPTY,
      });
  }
};

/**
 * Get detail config, ordered by detailTypes param
 */
export const getDetailConfigs = (params: {
  detailTypes: DetailConfigType[];
  socialAccount: ISocialAccount;
  styles?: { [key: string]: any };
}): ISocialProfileDetailConfig[] => (
  !isEmpty(params.socialAccount)
    ? map(params.detailTypes, (detailType) => getDetailConfig({
      detailType,
      network: params.socialAccount.network_identifier,
      styles: params.styles,
      socialAccount: params.socialAccount,
    }))
    : []
);

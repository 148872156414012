import { chain, size } from 'lodash';
import { IAdvertiserAccountAccessRequirement } from 'src/common/models/advertiserAccountAccessRequirement';

export const getPublishersSelectedForUse = (requirements: IAdvertiserAccountAccessRequirement[]) =>
  chain(requirements)
    .keyBy('publisher_id')
    .filter('selected_for_use')
    .map('publisher_id')
    .value();

export const getNumberOfPublishersSelectedForUse = (
  requirements: IAdvertiserAccountAccessRequirement[],
) => size(getPublishersSelectedForUse(requirements));

export const getAccessIsPending = (
  selectedForUse: boolean,
  readyForUse: boolean,
  limitReached: boolean,
) => {
  if (selectedForUse) {
    return !readyForUse && limitReached;
  } else {
    return readyForUse;
  }
};

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import {
  ViewIcon,
  SettingsIcon,
  WarningIcon,
  SpinnerIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import { CreatorDetailOverlay } from 'src/widgets/CreatorDetailOverlay';
import { LazyImage } from 'src/widgets/Image';
import { Select } from 'src/widgets/Select';
import { Tooltip } from 'src/widgets/Tooltip';
import countries from 'src/common/config/countries';
import networkConfig from 'src/common/config/networkConfig';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { TMobileType } from 'src/utils/hooks/useMobileStatus';
import { FavoriteButton } from 'src/widgets/FavoriteButton/FavoriteButton';
import { SocialProfileOverlay } from 'src/widgets/SocialProfile/SocialProfileOverlay';
import { willShowSPv2 } from 'src/utils/connectUtils';

import { ActionButtonGroup, TActionTaking } from './ActionButtonGroup';

const { useState, useRef } = React;
import styles from './SuggestedAccountHeader.scss';
const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

interface IProps {
  mobileType: TMobileType;
  apiEndpoint: string;
  campaign: ICampaign;
  org: IOrganization;
  socialAccount: ISocialAccount;
  remainingCount: number;
  actionTaking: TActionTaking;
  onEditCampaign(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onSkip(offensive: boolean): void;
  onInvite(): void;
  onOffer(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;

  allowFavorite: boolean;
  favoriteLists: IFavoriteList[];
  addToFavoriteList(accountId: number, listId: number): Promise<void>;
  createFavoriteList(name: string): Promise<void>;

  className?: string;
  selfServeExperiment: boolean;
}

const ActionGroup: React.FunctionComponent<{
  actionTaking: TActionTaking;
  showDetails(): void;
  onEditCampaign(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onSkip(offensive: boolean): void;
}> = React.memo(({ actionTaking, showDetails, onEditCampaign, onSkip }) => {
  const reportOptions = [
    {
      label: 'Content is offensive',
      value: 'offensive content',
    },
    {
      label: 'Not a legitimate creator',
      value: 'not a legitimate creator',
    },
  ];

  return (
    <>
      <Button
        className={styles.detailsButton}
        label="More Details"
        icon={<ViewIcon />}
        theme="light"
        border={false}
        disabled={!!actionTaking}
        onClick={showDetails}
      />
      <Button
        className={styles.preferencesButton}
        label="Preferences"
        icon={<SettingsIcon size={20} />}
        theme="light"
        border={false}
        disabled={!!actionTaking}
        onClick={onEditCampaign}
      />
      <Select
        className={styles.reportButton}
        disabled={!!actionTaking}
        customLabelElement={
          <Button
            label="Report Creator"
            icon={
              actionTaking === 'reporting' ? <SpinnerIcon size={20} /> : <WarningIcon size={20} />
            }
            theme="light"
            border={false}
          />
        }
        options={reportOptions}
        onChange={() => onSkip(true)}
      />
    </>
  );
});

/**
 * @type {React.FunctionComponent}
 */
export const SuggestedAccountHeader: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    allowFavorite,
    mobileType,
    apiEndpoint,
    campaign,
    onEditCampaign,
    socialAccount,
    remainingCount,
    actionTaking,
    onSkip,
    onInvite,
    onOffer,
    selfServeExperiment,
  } = props;

  const { audienceNamePlural } = networkConfig[socialAccount.network_identifier];
  const bioRef = useRef<HTMLDivElement>(null);
  const [showDetailOverlay, setShowDetailOverlay] = useState(false);

  const bio = socialAccount.bio || socialAccount.description || '';
  const shouldShowBioTooltip = bio.length > 200;

  const showDetails = () => setShowDetailOverlay(true);

  if (!mobileType) {
    return renderDesktopView();
  }

  return renderMobileView();

  function renderDesktopView() {
    return (
      <div className={cx(styles.SuggestedAccountHeader, props.className)}>
        <a
          className={styles.avatarWrapper}
          href={socialAccount.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LazyImage
            className={styles.avatar}
            key={socialAccount.id}
            src={socialAccount.profile_image || socialAccount.profile_picture}
            fallbackSrc={defaultAvatar}
          />
        </a>
        <div className={styles.info}>
          <div className={styles.title}>
            What do you think of
            <a
              className={styles.username}
              href={socialAccount.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {socialAccount.username || socialAccount.name}
            </a>
            ?
            {allowFavorite && (
              <FavoriteButton
                className={styles.favoriteButton}
                apiEndpoint={apiEndpoint}
                campaign={campaign}
                socialAccount={socialAccount}
                size={24}
              />
            )}
          </div>
          <div className={styles.infoAndActions}>
            <div className={styles.address}>{countries[socialAccount.country_code]}</div>
            <div className={styles.reach}>
              {`${numeral(socialAccount.reach)
                .format('0.[0]a')
                .toUpperCase()} ${audienceNamePlural}`}
            </div>
            <ActionGroup
              actionTaking={actionTaking}
              showDetails={showDetails}
              onEditCampaign={onEditCampaign}
              onSkip={onSkip}
            />
          </div>
          <div ref={bioRef} className={styles.bio}>
            {bio.length > 200 ? bio.substr(0, 197) + '...' : bio}
          </div>
          {shouldShowBioTooltip && (
            <Tooltip
              mountRef={bioRef}
              maxWidth={600}
              className={styles.Tooltip}
              contentClassName={styles.content}
            >
              {bio}
            </Tooltip>
          )}
        </div>
        <ActionButtonGroup
          className={styles.actionButtonGroup}
          isMobile={false}
          actionTaking={actionTaking}
          campaign={campaign}
          remainingCount={remainingCount}
          onSkip={onSkip}
          onInvite={onInvite}
          onOffer={onOffer}
        />
        {willShowSPv2(socialAccount)
          ? (
            <SocialProfileOverlay
              campaign={campaign}
              socialAccount={socialAccount}
              allowFavorite={allowFavorite}
              show={showDetailOverlay}
              apiEndpoint={apiEndpoint}
              showSimilarCreators={false}
              onRequestClose={() => setShowDetailOverlay(false)}
              selfServeExperiment={selfServeExperiment}
            />
          )
          : (
            <CreatorDetailOverlay
              allowFavorite={false}
              loadDetail={true}
              loadRelated={false}
              campaign={campaign}
              apiEndpoint={apiEndpoint}
              show={showDetailOverlay}
              socialAccount={socialAccount}
              onRequestClose={() => setShowDetailOverlay(false)}
              selfServeExperiment={selfServeExperiment}
            />
          )}
      </div>
    );
  }

  function renderMobileView() {
    return (
      <div className={cx(styles.SuggestedAccountHeaderMobile, props.className)}>
        <div className={styles.leftContent}>
          <div className={styles.avatarAndInfo}>
            <a
              className={styles.avatarWrapper}
              href={socialAccount.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyImage
                className={styles.avatar}
                key={socialAccount.id}
                src={socialAccount.profile_image || socialAccount.profile_picture}
                fallbackSrc={defaultAvatar}
              />
            </a>
            <div className={styles.info}>
              <div className={styles.title}>
                <a
                  className={styles.username}
                  href={socialAccount.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialAccount.username || socialAccount.name}
                </a>
                {allowFavorite && (
                  <FavoriteButton
                    className={styles.favoriteButton}
                    apiEndpoint={apiEndpoint}
                    campaign={campaign}
                    socialAccount={socialAccount}
                    size={24}
                  />
                )}
              </div>
              <div className={styles.addressAndReach}>
                <div className={styles.address}>{countries[socialAccount.country_code]}</div>
                <div>
                  {`${numeral(socialAccount.reach)
                    .format('0.[0]a')
                    .toUpperCase()} ${audienceNamePlural}`}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <ActionGroup
              actionTaking={actionTaking}
              showDetails={showDetails}
              onEditCampaign={onEditCampaign}
              onSkip={onSkip}
            />
          </div>
        </div>
        <ActionButtonGroup
          className={cx(styles.actionButtonGroup, {
            [styles.phone]: mobileType === 'phone',
          })}
          isMobile={mobileType === 'phone'}
          actionTaking={actionTaking}
          campaign={campaign}
          remainingCount={remainingCount}
          onSkip={onSkip}
          onInvite={onInvite}
          onOffer={onOffer}
        />
        {willShowSPv2(socialAccount)
          ? (
            <SocialProfileOverlay
              campaign={campaign}
              socialAccount={socialAccount}
              allowFavorite={allowFavorite}
              show={showDetailOverlay}
              apiEndpoint={apiEndpoint}
              showSimilarCreators={false}
              onRequestClose={() => setShowDetailOverlay(false)}
              selfServeExperiment={selfServeExperiment}
            />
          )
          : (
            <CreatorDetailOverlay
              allowFavorite={false}
              campaign={campaign}
              apiEndpoint={apiEndpoint}
              loadDetail={true}
              loadRelated={false}
              show={showDetailOverlay}
              socialAccount={socialAccount}
              onRequestClose={() => setShowDetailOverlay(false)}
              selfServeExperiment={selfServeExperiment}
            />
          )}
      </div>
    );
  }
});

SuggestedAccountHeader.defaultProps = {
  className: null,
};

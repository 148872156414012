import * as React from 'react';
import cx from 'classnames';
import { filter, isEmpty } from 'lodash';

import { SpinnerIcon, SearchIcon } from 'src/icons';
import { Input } from 'src/widgets/Input';
import { Popover } from 'src/widgets/Popover';
import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../useAnalyze';
import { fetchFilterData } from '../useFetchFilterData';
import { filtersToQueryString } from '../../useFetchAnalyzeData';

import { BrandItem } from './BrandItem';

import styles from '../Filters.scss';
const { useRef, useState, useEffect } = React;

export const BrandFilter = ({ filters, setFilters, isDisabled, onNoData }) => {
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [loading, setLoading] = useState(false)
  const ref = useRef<HTMLDivElement>(null);
  const { apiEndpoint } = useAnalyze();

  const url =
    `${apiEndpoint}/${endpoints.reportsEndpoint}/filter/available-values` +
    '?' +
    filtersToQueryString({ organizationId: filters.organizationId });

  useEffect(() => {
    fetchFilterData(url)
      .then(({ brands_campaigns: brands }) => {
        if (brands.length === 0) {
          onNoData('no data')
          return brands
        }
        brands[0].selected = true;
        brands[0].campaigns.map((campaign) => (campaign.selected = true));
        return brands;
      })
      .then((payload) => {
        setLoading(false)
        setFilters({
          type: 'LOAD_BRAND_VALUES',
          payload: payload,
        });
      })
      .catch((error) => {
        setLoading(false)
        setFilters({
          type: 'ERROR_LOADING_API',
          payload: { error, filter: 'Brands' },
        });
      });
  }, [setFilters, apiEndpoint, url, onNoData]);

  const isSelected = (item) => item.selected;
  const countOfSelectedCampaigns = filter(filters.brands, isSelected).reduce(
    (sum, brand) => {
      return sum + filter(brand.campaigns, isSelected).length;
    },
    0
  );

  /* eslint-disable @typescript-eslint/indent */
  const brandsWithCampaignsFiltered = filters.brands
    ? filters.brands.map((brand) => {
        return {
          ...brand,
          campaigns: brand.campaigns.filter((campaign) =>
            campaign.name.toLowerCase().includes(textValue.toLowerCase())
          ),
        };
      })
    : [];
  /* eslint-enable */

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {loading ? <SpinnerIcon /> : countOfSelectedCampaigns}
        </span>
        <span className={styles.filterText}>Brands & Campaigns</span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Input
          type="text"
          value={textValue}
          icon={<SearchIcon />}
          placeholder="Search..."
          buffer={300}
          onChange={setTextValue}
          className={styles.input}
        />
        <ul className={styles.MenuList}>
          {brandsWithCampaignsFiltered.map((brand) => (
            <BrandItem key={brand.id} brand={brand} dispatch={setFilters} />
          ))}
        </ul>
      </Popover>
    </>
  );
};

export const brandReducer = (state, action) => {
  const localState = state.brands;
  switch (action.type) {
    case 'LOAD_BRAND_VALUES':
      return action.payload;
    case 'TOGGLE_BRAND': {
      const newState = localState.map((brand) => {
        if (brand.id === action.payload.id) {
          return {
            ...brand,
            selected: !brand.selected,
            campaigns: brand.campaigns.map((camp) => ({
              ...camp,
              selected: !brand.selected,
            })),
          };
        }
        return brand;
      });
      return newState;
    }
    case 'TOGGLE_CAMPAIGN':
      console.log('cap toggle: ', action);

      return localState.map((brand) => {
        const newCampaigns = brand.campaigns.map((camp) =>
          camp.id === action.payload.id
            ? { ...camp, selected: !camp.selected }
            : camp
        );
        if (brand.id === action.payload.brandId) {
          return {
            ...brand,
            selected: !isEmpty(newCampaigns.filter((camp) => camp.selected)),
            campaigns: newCampaigns,
          };
        }
        return brand;
      });
    default:
      return localState;
  }
};

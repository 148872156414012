import * as React from 'react';
import { map, isEmpty } from 'lodash';

import { IGroupContentReviewMessage, IContentReview } from 'src/common/models/contentReview';
import { ICampaign } from 'src/common/models/campaign';
import { IManager } from 'src/common/models/manager';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { IToastRefHandles, Toast } from 'src/widgets/Toast';

import { ContentReviewItem } from '../ContentReviewItem/ContentReviewItem';
import {
  sortContentReviews,
  fetchContentReviews,
  approveContentReview,
  mtRejectContentReview,
  brandRejectContentReview,
  revertReject,
  commentContentReview,
  markContentReviewCommentsRead,
  TRejectFunction,
} from '../../utils/contentReviewUtils';
import { hasAspireEmail, hasBrandPermission } from '../../utils/managerUtils';

const { useState, useEffect, useRef } = React;

import styles from './CampaignDetailList.scss';
import { SubmitButton } from 'src/widgets';

interface IProps {
  apiEndpoint: string;
  user: IManager;
  campaign: ICampaign;

  viewRelationship(relationId: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const CampaignDetailList: React.FunctionComponent<IProps> = (props) => {
  const { apiEndpoint, user, campaign, viewRelationship } = props;

  const toastRef = useRef<IToastRefHandles>();

  const hasAdminAccess = hasBrandPermission(user, campaign.brand.id, 4);
  const isManagedCampaign = campaign.has_campaign_manager;
  const isAspireEmployee = hasAspireEmail(user);

  const isBrandUser = (!isAspireEmployee && isManagedCampaign) ||
    (!isAspireEmployee && !isManagedCampaign && !hasAdminAccess);

  const isSuperBrandUser = !isAspireEmployee && hasAdminAccess && !isManagedCampaign;

  const [selectedContentReviewId, setSelectedContentReviewId] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const [page, setPage] = useState(0);
  const [showPagingButton, setShowPagingButton] = useState(true);
  const [contentReviews, setContentReviews] = useState([]);
  const [isFetchingContentReviews, setIsFetchingContentReviews] = useState(false);
  
  useEffect(() => {
    setPage(0);
    setShowPagingButton(true);
    setContentReviews([]);
  }, [campaign.id]);

  // TODO: should we use useMemo here?
  useEffect(() => {
    if (isFetchingContentReviews && abortController) {
      abortController.abort();
    }
    const controller = new AbortController();
    setAbortController(controller)
    setIsFetchingContentReviews(true);
    const signal = controller.signal;
    fetchContentReviews(apiEndpoint, campaign.id, page, signal)
      .then((fetchedReviews) => {
        if (isEmpty(fetchedReviews)) {
          setShowPagingButton(false);
        }
        if (page > 0) {
          setContentReviews([...contentReviews, ...fetchedReviews]);
        } else {
          setContentReviews(fetchedReviews);
        }
        setIsFetchingContentReviews(false);
      })
      .catch((error) => {
        console.error('Unable to fetch content reviews', error);
        if (error.name !== 'AbortError') { // if aborted it means another fetch has started
          setIsFetchingContentReviews(false);
        }
      });
  }, [apiEndpoint, campaign.id, page]);

  const fetchAndUpdateContentReview = (updatedContentReview: IContentReview) => {
    const contentReviewIndex = contentReviews.findIndex(
      (review) => review.id === updatedContentReview.id,
    );
    setContentReviews([
      ...contentReviews.slice(0, contentReviewIndex),
      updatedContentReview,
      ...contentReviews.slice(contentReviewIndex + 1),
    ]);
  };

  const handleApproveContentReview = async (
    contentReview: IContentReview,
    brandApproveComment: string,
  ): Promise<void> => {
    const toast = toastRef.current;
    try {
      const updatedContentReview: IContentReview = await approveContentReview(
        apiEndpoint,
        contentReview,
        brandApproveComment,
      );
      fetchAndUpdateContentReview(updatedContentReview);
      if (toast) {
        toast.showMessage({
          content: (
            <div>
              Approval submitted{' '}
              {contentReview.product.account_name && (
                <React.Fragment>
                  {' '}
                  for <span>{contentReview.product.account_name}</span>
                </React.Fragment>
              )}
              {!contentReview.product.account_name && (
                <React.Fragment>
                  {' '}
                  for <span>{contentReview.publisher_username}</span>
                </React.Fragment>
              )}
              .
            </div>
          ),
        });
      }
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to approve the content.',
          type: 'error',
        });
      }
      throw err;
    }
  };

  const handleRevertReject = async (
    contentReview: IContentReview,
    comment: string,
  ): Promise<void> => {
    const toast = toastRef.current;
    try {
      const updatedContentReview: IContentReview = await revertReject(
        apiEndpoint,
        contentReview,
        comment,
      );
      fetchAndUpdateContentReview(updatedContentReview);
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error trying to revert the rejection.',
          type: 'error',
        });
      }
      throw err;
    }
  };

  const handleRejectContentReview = async (
    contentReview: IContentReview,
    brandGuidelineCheckoff: boolean[],
    brandRejectComment: string,
    bypass: boolean,
    rejectFunction: TRejectFunction,
  ): Promise<void> => {
    const toast = toastRef.current;
    try {
      const updatedContentReview: IContentReview = await rejectFunction(
        apiEndpoint,
        contentReview,
        brandGuidelineCheckoff,
        brandRejectComment,
        bypass,
      );
      fetchAndUpdateContentReview(updatedContentReview);
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to reject the content.',
          type: 'error',
        });
      }
      throw err;
    }
  };

  const handleBrandRejectContentReview = async (
    contentReview: IContentReview,
    brandGuidelineCheckoff: boolean[],
    brandRejectComment: string,
  ): Promise<void> => {
    await handleRejectContentReview(
      contentReview,
      brandGuidelineCheckoff,
      brandRejectComment,
      false,
      brandRejectContentReview,
    );
    const toast = toastRef.current;
    if (toast) {
      toast.showMessage({
        content: (
          <div>
            Rejection submitted{' '}
            {contentReview.product.account_name && (
              <React.Fragment>
                {' '}
                for <span>{contentReview.product.account_name}</span>
              </React.Fragment>
            )}
            {!contentReview.product.account_name && (
              <React.Fragment>
                {' '}
                for <span>{contentReview.publisher_username}</span>
              </React.Fragment>
            )}
            .
          </div>
        ),
      });
    }
  };

  const handleMTRejectContentReview = async (
    contentReview: IContentReview,
    brandGuidelineCheckoff: boolean[],
    brandRejectComment: string,
    bypass: boolean,
  ): Promise<void> => {
    await handleRejectContentReview(
      contentReview,
      brandGuidelineCheckoff,
      brandRejectComment,
      bypass,
      mtRejectContentReview,
    );
    const toast = toastRef.current;
    if (toast) {
      toast.showMessage({
        content: (
          <div>
            Requested new content{' '}
            {contentReview.product.account_name && (
              <React.Fragment>
                {' '}
                from <span>{contentReview.product.account_name}</span>
              </React.Fragment>
            )}
            {!contentReview.product.account_name && (
              <React.Fragment>
                {' '}
                from <span>{contentReview.publisher_username}</span>
              </React.Fragment>
            )}
            .
          </div>
        ),
      });
    }
  };

  const handleCommentContentReview = async (
    contentReview: IContentReview,
    message: IGroupContentReviewMessage,
  ): Promise<void> => {
    const toast = toastRef.current;
    try {
      const updatedContentReview: IContentReview = await commentContentReview(
        apiEndpoint,
        contentReview,
        message.message_type,
        message.comment,
        isBrandUser,
        campaign.brand.id,
        user,
      );
      fetchAndUpdateContentReview(updatedContentReview);
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to comment on the content.',
          type: 'error',
        });
      }
      throw err;
    }
  };

  const handleCommentContentReviewRead = async (contentReview: IContentReview): Promise<void> => {
    const toast = toastRef.current;
    try {
      const updatedContentReview: IContentReview = await markContentReviewCommentsRead(
        apiEndpoint,
        contentReview,
        user.id,
      );
      fetchAndUpdateContentReview(updatedContentReview);
    } catch (err) {
      if (toast) {
        toast.showMessage({
          content: 'There was an error when trying to comment on the content.',
          type: 'error',
        });
      }
      throw err;
    }
  };

  const onContentReviewClick = (contentReviewId) => {
    if (contentReviewId === selectedContentReviewId) {
      setSelectedContentReviewId(null);
    } else {
      setSelectedContentReviewId(contentReviewId);
    }
  };

  return (
    <div className={styles.CampaignDetailList}>
      <Toast ref={toastRef} />
      {isFetchingContentReviews && page === 0 ? (
        <LoadSpinner />
      ) : (
        <>
          {map(sortContentReviews(contentReviews, isBrandUser), (contentReview, index) => (
            <ContentReviewItem
              key={index}
              user={user}
              hasAdminAccess={hasAdminAccess}
              isBrandUser={isBrandUser}
              isSuperBrandUser={isSuperBrandUser}
              brand={campaign.brand}
              numBrandUsers={campaign.brand_users ? campaign.brand_users.length : 0}
              contentReview={contentReview}
              isDetailedView={contentReview.id === selectedContentReviewId}
              onContentReviewSelected={onContentReviewClick}
              approveContentReview={handleApproveContentReview}
              mtRejectContentReview={handleMTRejectContentReview}
              brandRejectContentReview={handleBrandRejectContentReview}
              commentContentReview={handleCommentContentReview}
              readBrandComments={handleCommentContentReviewRead}
              revertReject={handleRevertReject}
              viewRelationship={viewRelationship}
            />
          ))}

          {showPagingButton &&
            <SubmitButton
              label="Load More"
              disabled={isFetchingContentReviews}
              submittingLabel="Loading..."
              isSubmitting={isFetchingContentReviews}
              className={styles.buttonStyle}
              onClick={() => setPage(page + 1)}
            />
          }
        </>
      )}

    </div>
  );
};

import { connect } from 'react-redux';

import {
  IConnectSearchPage,
  IExternalVariables,
  ISearchResultsProps,
  ISearchResults,
} from '../models';
import { fetchPage } from '../redux/searchResultsActions';
import { SearchResults } from '../SearchResults';

type TState = IExternalVariables & ISearchResults;

interface IDispatchProps {
  onSelectPage(page: number);
}

const mapStateToProps = (state: IConnectSearchPage) => ({
  ...state.external,
  ...state.searchResults,
  query: state.textSearch.query,
});

const mapDispatchToProps: IDispatchProps = {
  onSelectPage: fetchPage,
};

export const ConnectedSearchResults = connect<TState, IDispatchProps, ISearchResultsProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResults);

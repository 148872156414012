import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map, keys, pull, times, find, omit, mapValues } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';
import { ResponsiveGrid } from 'src/widgets/ResponsiveGrid';
import { Tooltip } from 'src/widgets/Tooltip';
import { NetworkIcon } from 'src/common';
import endpoints from 'src/common/config/endpoints';
import { POST_TYPE_NAME_PLURAL, TPostType } from 'src/common/models/postType';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';

import { useAnalyze } from '../../../useAnalyze';
import { useAnalyzeCompare } from '../../../useAnalyzeCompare';
import {
  useFetchSummaryData,
  useFetchSummaryDataCompare,
} from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

import styles from './PostTypeSummarySection.scss';
const { useRef } = React;

export interface ISummaryData {
  post_type: TPostType;
  tmv: number;
  impressions?: number;
  likes?: number;
  comments?: number;
  favorites?: number;
  exits?: number;
  taps?: number;
  clicks?: number;
  views?: number;
  dislikes?: number;
  shares?: number;
  reactions?: number;
  closeups?: number;
  unique_visitors?: number;
  roi_pct: number;
}
const SPECICIAL_NAME_MAP: {
  [key in keyof ISummaryData]?: string;
} = {
  roi_pct: 'ROI',
  unique_visitors: 'Unique Visitors',
  favorites: 'Saves',
};
const DATA_DOLLAR_FIELD: {
  [key in keyof ISummaryData]?: boolean;
} = {
  tmv: true,
};
const DATA_PERCENTAGE_FIELD: {
  [key in keyof ISummaryData]?: boolean;
} = {
  roi_pct: true,
};
interface IProps {
  className?: string;
}
/**
 * @type {React.FunctionComponent}
 */

// Order from doc https://aspireiq.atlassian.net/wiki/spaces/AIQ/pages/71041088/Summary#Network-Cards

const POST_TYPE_TOOLTIPS = {
  instagram: {
    impressions: 'Total number of times the post has been seen',
  },
  instagram_video: {
    views: 'Total number of times the video has been seen',
  },
  instagram_story: {
    impressions: 'Total number of times the post has been seen',
    taps: `Total number of taps to see the Instagram story's previous or next photo or video`,
    clicks: 'Estimated number of swipeups based on Bitly clicks',
  },
  facebook_post: {
    reactions:
      'Number of reactions on a Facebook post, including likes, loves and cares',
  },
  pinterest: {
    impressions: 'Total number of times a Pin was shown',
    closeups:
      'Total number of times people viewed a close-up version of the Pin',
    clicks:
      'Total number of times people have clicked on the Pin to a destination on or off of Pinterest',
    favorites: 'Total number of times people saved a Pin to a board',
  },
};

const postOrder = [
  'instagram',
  'instagram_video',
  'instagram_story',
  'facebook_post',
  'youtube_mention',
  'pinterest',
  'twitter_post',
  'blog_mention',
  'tiktok_video',
];

const facebookReactions = (record: ISummaryData) => {
  const facebook_post: TPostType = 'facebook_post';
  return record.post_type === facebook_post
    ? { ...omit(record, ['likes']), reactions: record.likes }
    : record;
};

export const PostTypeSummarySection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const { filters: compareFilters, isComparing } = useAnalyzeCompare();

    const refs = mapValues(POST_TYPE_TOOLTIPS, value =>
      mapValues(value, () => useRef<HTMLDivElement>())
    );

    const { loading, data, error } = useFetchSummaryData<ISummaryData[]>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/post-type`,
      isAspirex,
      filters
    );
    /* eslint-disable */
    const {
      loading: loadingCompare,
      data: dataCompare,
    } = useFetchSummaryDataCompare<ISummaryData[]>(
      isComparing,
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/post-type`,
      isAspirex,
      compareFilters
    );
    /* eslint-enable */
    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const dataReturnedEmpty = data && data.length === 0;

    const newData = map(data, facebookReactions);
    const newCompareData = map(dataCompare, facebookReactions);

    const records = map(postOrder, postType => {
      return newData.find(r => r.post_type === postType);
    }).filter(record => record);

    const itemHeights = times(data && records.length, () => 336);
    return (
      <div className={cx(styles.PostTypeSummarySection, props.className)}>
        {(loading || loadingCompare) && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading || loadingCompare}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <ResponsiveGrid padding={40} heights={itemHeights} itemMinWidth={300}>
            {map(records, record => {
              const infoKeys = pull(keys(record), 'post_type');

              /* eslint-disable */
              const compareRecord = isComparing
                ? find(
                    newCompareData,
                    item => item.post_type === record.post_type
                  )
                : null;

              /* eslint-enable */
              return (
                <div className={styles.item} key={record.post_type}>
                  <NetworkIcon
                    identifier={getNetworkByPostType(record.post_type)}
                    size={32}
                    className={styles.icon}
                  />
                  <div className={styles.name}>
                    {POST_TYPE_NAME_PLURAL[record.post_type]}
                  </div>

                  <div className={styles.info}>
                    {map(infoKeys, key => {
                      const hasTooltip =
                        POST_TYPE_TOOLTIPS[record.post_type] &&
                        POST_TYPE_TOOLTIPS[record.post_type][key];

                      return (
                        <div key={key} className={styles.infoItem}>
                          <div
                            ref={
                              hasTooltip ? refs[record.post_type][key] : null
                            }
                            className={cx(styles.key, {
                              [styles.withTooltip]: hasTooltip,
                            })}
                          >
                            {SPECICIAL_NAME_MAP[key] || key}
                          </div>

                          {hasTooltip && (
                            <Tooltip
                              mountRef={refs[record.post_type][key]}
                              placement="bottom"
                              tooltipColor="black"
                            >
                              <div className={styles.tooltipBody}>
                                <span className="">
                                  {POST_TYPE_TOOLTIPS[record.post_type][key]}
                                </span>
                              </div>
                            </Tooltip>
                          )}
                          {/* !eslint is mad at prettier */}
                          {/* eslint-disable */}
                          <div className={styles.value}>
                            {DATA_PERCENTAGE_FIELD[key]
                              ? numeral(record[key] / 100).format('0%')
                              : numeral(record[key]).format(
                                  `${DATA_DOLLAR_FIELD[key] ? '$' : ''}0,0`
                                )}
                          </div>
                          {isComparing && compareRecord && (
                            <div className={cx(styles.value, styles.compare)}>
                              {DATA_PERCENTAGE_FIELD[key]
                                ? numeral(compareRecord[key] / 100).format('0%')
                                : numeral(compareRecord[key]).format(
                                    `${DATA_DOLLAR_FIELD[key] ? '$' : ''}0,0`
                                  )}
                              {/* eslint-enable */}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </ResponsiveGrid>
        </ShouldSectionShow>
      </div>
    );
  }
);
PostTypeSummarySection.defaultProps = {
  className: null,
};

import * as React from 'react';
import cx from 'classnames';
import { chain, isEmpty, map } from 'lodash';
import { format } from 'date-fns';

import { Select } from 'src/widgets/Select';
import { CopyLinkInput } from 'src/common';

import { ISharedPage } from 'src/common/models/manager';

const { useState, useMemo } = React;
import styles from './SharedLinkPage.scss';

type TStandaloneAppRoute =
  | 'proposal'
  | 'client_advertiser_access'
  | 'client_content_review'
  | 'content_analyze'
  | 'content'
  | 'fb_analyze.reports';

type TFilterType = TStandaloneAppRoute | 'all';
const routeToPageName: {
  [key in TStandaloneAppRoute]: string;
} = {
  proposal: 'Creator Proposal',
  client_advertiser_access: 'Advertiser Access',
  client_content_review: 'Content Reviews',
  content_analyze: 'Content Analyze',
  content: 'Content Library',
  'fb_analyze.reports': 'Facebook Analyze',
};

const routeToStandaloneApp: {
  [key in TStandaloneAppRoute]: string;
} = {
  proposal: 'agency_proposal',
  client_advertiser_access: 'client_advertiser_access',
  client_content_review: 'client_content_review',
  content_analyze: 'content_analyze_handler',
  content: 'content_library',
  'fb_analyze.reports': 'facebook_analyze',
};

const sortOptions = [
  {
    label: 'Newest',
    value: 0,
  },
  {
    label: 'Oldest',
    value: 1,
  },
];

interface IProps {
  sharedPages: ISharedPage[];

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const SharedLinkPage: React.FunctionComponent<IProps> = React.memo((props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const [filterType, setFilterType] = useState<TFilterType>('all');
  const onFilterTypeChange = (newFilterType: TFilterType) => setFilterType(newFilterType);
  // use sort value as selected index
  const [sortDir, setSortDir] = useState<number>(0);
  // to bypass "Hooks don't support the second callback argument"
  const onSortDirChange = (newSortDir: number) => setSortDir(newSortDir);

  const typeOptions = useMemo(
    () => [
      {
        label: 'All',
        value: 'all',
      },
      ...chain(props.sharedPages)
        .map((page) => page.route)
        .uniq()
        .map((route) => ({
          label: routeToPageName[route],
          value: route,
        }))
        .value(),
    ],
    [props.sharedPages],
  );
  const selectedTypeIndex = typeOptions.findIndex((option) => option.value === filterType);

  const sharedPages = useMemo(
    () =>
      chain(props.sharedPages)
        .filter((page) => filterType === 'all' || page.route === filterType)
        .sortBy((page) => (sortDir === 0 ? -page.created_ts : page.created_ts))
        .value(),
    [props.sharedPages, filterType, sortDir],
  );

  return (
    <div className={cx(styles.SharedLinkPage, props.className)}>
      <div className={styles.header}>Shared Links</div>
      {isEmpty(sharedPages) && (
        <div className={styles.text}>No links have been shared with you.</div>
      )}
      {!isEmpty(sharedPages) && (
        <>
          <div className={styles.text}>
            Use the links below to access content & creators that have been submitted for your
            approval.
          </div>
          <div className={styles.filterSection}>
            <Select
              selectedIndex={selectedTypeIndex}
              options={typeOptions}
              onChange={onFilterTypeChange}
              round={true}
              className={styles.typeSelect}
            />
            <Select
              selectedIndex={sortDir}
              options={sortOptions}
              onChange={onSortDirChange}
              round={true}
              className={styles.sortSelect}
            />
          </div>
          <div className={styles.divider} />
          {map(sharedPages, (page, index) => (
            <div key={index} className={styles.item}>
              <div className={styles.title}>
                {routeToPageName[page.route]}
                <span>
                  {page.brand_name &&
                    ` for ${page.campaign_name ? `${page.campaign_name},` : ''} ${page.brand_name}`}
                </span>
              </div>
              <CopyLinkInput
                shareLink={`${window.location.origin}/brands/${
                  routeToStandaloneApp[page.route]
                }?${map(page.page_params, (value, key) => `${key}=${value}`).join('&')}`}
                className={styles.linkSection}
                header={null}
              />
              <div className={styles.timestamp}>
                {format(page.created_ts * 1000, 'MMM dd, yyyy hh:mm a')}
              </div>
              {page.shared_by && (
                <div className={styles.text}>
                  <span className={styles.pre}>Shared by:</span>
                  {page.shared_by}
                </div>
              )}
              {page.page_params.count > 0 && (
                <div className={styles.text}>
                  <span className={styles.pre}>
                    {page.route === 'proposal' ? 'Creators:' : 'Pieces of content:'}
                  </span>
                  {page.page_params.count}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
});

SharedLinkPage.defaultProps = {
  className: null,
};

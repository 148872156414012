import * as React from 'react';

const { useRef, useState } = React;

interface IFetchingResult {
  [url: string]: {
    loading: boolean;
    data: any;
    error: Error;
  };
}

interface IFetching {
  [url: string]: boolean;
}

interface IFetchContext {
  fetching: IFetching;
  fetchingResult: IFetchingResult;
  updateFetchingResult: React.Dispatch<React.SetStateAction<IFetchingResult>>;
}

export const FetchContext = React.createContext<IFetchContext>(null);

export const FetchContextProvider: React.FC = (props) => {
  const fetchingRef = useRef<IFetching>({});
  const [fetchingResult, setFetchingResult] = useState<IFetchingResult>({});

  const value: IFetchContext = {
    fetching: fetchingRef.current,
    fetchingResult,
    updateFetchingResult: setFetchingResult,
  };

  return (
    <FetchContext.Provider value={value}>
      {props.children}
    </FetchContext.Provider>
  );
};

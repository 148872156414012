import { IManager } from 'src/common/models/manager';
import { some } from 'lodash';

function getACLAccess(manager: IManager, predicate: Record<string, any>): boolean {
  return some(manager.acl || [], predicate);
}

export function hasAccessToBrand(manager: IManager, brandId: number): boolean {
  return getACLAccess(manager, { kind: 'Brand', entity: brandId, role: 'GroupContentReview' });
}

export function hasBrandPermission(manager: IManager, brandId: number, level: number): boolean {
  return getACLAccess(manager, {
    kind: 'Brand',
    entity: brandId,
    role: 'GroupContentReview',
    level,
  });
}

export function hasAspireEmail(manager: IManager): boolean {
  return manager.email.endsWith('@aspireiq.com') || manager.email.endsWith('@revfluence.com');
}

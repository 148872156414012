import { map, union, difference, without, size, join, toLower, indexOf, invertBy } from 'lodash';
import mime from 'mime';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import endpoints from 'src/common/config/endpoints';

import { IContent } from 'src/widgets/ContentUploader/redux/contentUploaderModel';
import { ICampaign } from 'src/common/models/campaign';
import { ILicensedContent } from 'src/common/models/licensedContent';
import addEventLog from 'src/common/utils/addEventLog';
import getFilenameFromUrl from 'src/common/utils/getFilenameFromUrl';
import getFileExtension from 'src/common/utils/getFileExtension';
import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { getErrorMessageFromResponse } from 'src/common/utils/getErrorMessageFromResponse';

import { IContentFilters, IStore, TSortType } from './models';
import { getCommonTags } from '../utils/tags';
import { getDownloadableMediaURL, getImage } from 'src/common/utils/media';

import { RAW_IMAGE_EXTENSIONS } from 'src/common/config/fileExtensions';
mime.define(invertBy(RAW_IMAGE_EXTENSIONS), true);

export enum ActionTypes {
  CHANGE_FILTERS = '@contentPage/CHANGE_FILTERS',
  SELECT_CONTENT = '@contentPage/SELECT_CONTENT',
  TOGGLE_SELECT_ALL_CONTENT = '@contentPage/TOGGLE_SELECT_ALL_CONTENT',
  TOGGLE_LIKED_CONTENT = '@contentPage/TOGGLE_LIKED_CONTENT',
  CHANGE_SORT = '@contentPage/CHANGE_SORT',
  TOGGLE_CONTENT_DETAIL = '@contentPage/TOGGLE_CONTENT_DETAIL',

  FETCH_CAMPAIGNS_REQUEST = '@contentPage/FETCH_CAMPAIGNS/REQUEST',
  FETCH_CAMPAIGNS_SUCCESS = '@contentPage/FETCH_CAMPAIGNS/SUCCESS',
  FETCH_CAMPAIGNS_FAILURE = '@contentPage/FETCH_CAMPAIGNS/FAILURE',

  FETCH_CONTENT_REQUEST = '@contentPage/FETCH_CONTENT/REQUEST',
  FETCH_CONTENT_SUCCESS = '@contentPage/FETCH_CONTENT/SUCCESS',
  FETCH_CONTENT_FAILURE = '@contentPage/FETCH_CONTENT/FAILURE',

  CREATE_CONTENT_SUCCESS = '@contentPage/CREATE_CONTENT/SUCCESS',
  CREATE_CONTENT_FAILURE = '@contentPage/CREATE_CONTENT/FAILURE',

  SAVE_MULTI_CONTENT_REQUEST = '@contentPage/SAVE_MULTI_CONTENT/REQUEST',
  SAVE_MULTI_CONTENT_SUCCESS = '@contentPage/SAVE_MULTI_CONTENT/SUCCESS',
  SAVE_MULTI_CONTENT_FAILURE = '@contentPage/SAVE_MULTI_CONTENT/FAILURE',

  DELETE_CONTENT_SUCCESS = '@contentPage/DELETE_CONTENT/SUCCESS',
  DELETE_CONTENT_FAILURE = '@contentPage/DELETE_CONTENT/FAILURE',

  DOWNLOAD_CONTENT_REQUEST = '@contentPage/DOWNLOAD_CONTENT/REQUEST',
  DOWNLOAD_CONTENT_SUCCESS = '@contentPage/DOWNLOAD_CONTENT/SUCCESS',
  DOWNLOAD_CONTENT_FAILURE = '@contentPage/DOWNLOAD_CONTENT/FAILURE',

  UPDATE_TAGS_SUCCESS = '@contentPage/UPDATE_TAGS/SUCCESS',
  UPDATE_TAGS_FAILURE = '@contentPage/UPDATE_TAGS/FAILURE',

  REMOVE_TAG_SUCCESS = '@contentPage/REMOVE_TAG/SUCCESS',
  REMOVE_TAG_FAILURE = '@contentPage/REMOVE_TAG/FAILURE',

  UPDATE_PERMISSIONS_SUCCESS = '@contentPage/UPDATE_PERMISSIONS/SUCCESS',
  UPDATE_PERMISSIONS_FAILURE = '@contentPage/UPDATE_PERMISSIONS/FAILURE',
}

export interface IContentPageAction {
  type: ActionTypes;
  payload?: {
    filters?: IContentFilters;
    campaigns?: ICampaign[];
    content?: ILicensedContent[];
    selected?: boolean;
    contentId?: any;
    tag?: string;
    sort?: TSortType;
    contentIndex?: number;
  };
  meta?: {
    error: Error;
    errorMessage: string;
    hasNext: boolean;
    count: number;
    page: number;
    previousCursor: string;
    cursor: string;
    totalCount: number;
    xhr: XMLHttpRequest;
  };
}

const DEFAULT_GENERIC_ERROR_MSG =
  'An unexpected error occurred. \
Please contact us at support@aspireiq.com if the issue persists!';

const DEFAULT_UPLOAD_ERROR_MSG =
  'An unexpected error occurred \
while uploading your content. Please contact us at support@aspireiq.com if the issue persists!';

const DEFAULT_UPDATE_ERROR_MSG =
  'An unexpected error occurred \
while updating your content. Please contact us at support@aspireiq.com if the issue persists!';

const DEFAULT_DELETE_ERROR_MSG =
  'An unexpected error occurred while deleting your content. \
Please contact us at support@aspireiq.com if the issue persists!';

const DEFAULT_DOWNLOAD_ERROR_MSG =
  'An unexpected error occurred while downloading \
your content. Please contact us at support@aspireiq.com if the issue persists!';

export const changeFilters = (filters: IContentFilters) => ({
  type: ActionTypes.CHANGE_FILTERS,
  payload: {
    filters,
  },
});

export const changeSort = (sort: TSortType) => ({
  type: ActionTypes.CHANGE_SORT,
  payload: {
    sort,
  },
});

export const toggleContentDetail = (contentIndex: number) => ({
  type: ActionTypes.TOGGLE_CONTENT_DETAIL,
  payload: {
    contentIndex,
  },
});

export const fetchCampaigns = (page: number = 1) => async (dispatch, getState) => {
  const state: IStore = getState();
  const { activeBrand, apiEndpoint } = state;

  dispatch({ type: ActionTypes.FETCH_CAMPAIGNS_REQUEST });

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.campaignEndpoint}?page=${page}&brand_id=${activeBrand.id}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ActionTypes.FETCH_CAMPAIGNS_SUCCESS,
        payload: {
          campaigns: json.data.data,
        },
        meta: {
          hasNext: json.data.has_next,
          count: json.data.count,
          page,
        },
      });

      if (json.data.has_next) {
        dispatch(fetchCampaigns(page + 1));
      }
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.FETCH_CAMPAIGNS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });

    setTimeout(() => {
      // try again after a couple seconds
      dispatch(fetchCampaigns(page));
    }, 2000);
  }
};


export const fetchNextContentPage = () => (dispatch, getState) => {
  const state: IStore = getState();
  const { cursor, hasNext } = state;
  if (hasNext && cursor) {
    dispatch(fetchContent(cursor));
  }
};

export const fetchContent = (cursor: string = null) => async (dispatch, getState) => {
  const state: IStore = getState();
  const { apiEndpoint, activeBrand, filters, sort, _xhr } = state;

  // Cancel previous request in progress.
  if (_xhr) {
    _xhr.onreadystatechange = null;
    _xhr.abort();
  }

  let query = `content_library=true`;

  if (activeBrand) {
    query += `&brand_id=${activeBrand.id}`;
  }

  if (filters.query) {
    query += `&query=${filters.query}`;
  }

  if (filters.selectedCampaignId) {
    query += `&campaign_id=${filters.selectedCampaignId}`;
  }

  if (filters.selectedNetworkId) {
    query += `&source=${filters.selectedNetworkId}`;
  }

  if (size(filters.selectedTags) > 0) {
    query += `&tags=${toLower(join(filters.selectedTags, ','))}`;
  }

  if (filters.selectedPermissionLevel) {
    query += `&permission_level=${filters.selectedPermissionLevel}`;
  }

  if (filters.sku) {
    query += `&sku=${filters.sku}`;
  }

  if (filters.selectedMediaType) {
    query += `&content_type=${filters.selectedMediaType}`;
  }

  if (sort) {
    query += `&sort=${sort}&sort_direction=desc`;
  }

  if (cursor) {
    query += `&page=${cursor}`;
  }

  if (filters.memberId) {
    query += `&member_id=${filters.memberId}`;
  }

  if (state.clientId) {
    query += `&client_id=${state.clientId}`;
  }

  if (filters.activationId) {
    query += `&activation_id=${filters.activationId}`;
  }

  if (filters.programId) {
    query += `&program_id=${filters.programId}`;
  }

  if (filters.communityId) {
    query += `&community_id=${filters.communityId}`;
  }

  if (size(filters.contentListIds) > 0) {
    query += `&content_list_ids=${toLower(join(filters.contentListIds, ','))}`;
  }

  const xhr = new XMLHttpRequest();

  xhr.open('GET', `${apiEndpoint}/${endpoints.licensedContentEndpoint}/search?${query}`);
  xhr.send();

  dispatch({
    type: ActionTypes.FETCH_CONTENT_REQUEST,
    meta: {
      cursor,
      xhr,
    },
  });

  const request = new Promise<any>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        // response could be empty
        // or check for xhr.status != 200
        if (!xhr.response || xhr.status !== 200) {
          reject(new Error(DEFAULT_GENERIC_ERROR_MSG));
          return;
        }

        try {
          resolve(JSON.parse(xhr.response));
        } catch (err) {
          reject(err);
        }
      }
    };
  });

  try {
    const json = await request;

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ActionTypes.FETCH_CONTENT_SUCCESS,
        payload: {
          content: json.data.data,
        },
        meta: {
          hasNext: json.data.has_next,
          count: json.data.count,
          previousCursor: cursor,
          cursor: json.data.cursor,
          totalCount: json.data.total_count,
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.FETCH_CONTENT_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const selectContent = (contentId: string, selected: boolean) => ({
  type: ActionTypes.SELECT_CONTENT,
  payload: {
    contentId,
    selected,
  },
});

export const toggleSelectAllContent = () => ({
  type: ActionTypes.TOGGLE_SELECT_ALL_CONTENT,
});

export const toggleLikedContent = (content: ILicensedContent) => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TOGGLE_LIKED_CONTENT,
    payload: {
      contentId: content.id,
    },
  });

  const state: IStore = getState();
  const { apiEndpoint, likedContent, activeBrand, $state } = state;

  try {
    let contentListIds;
    if (likedContent[content.id]) {
      addEventLog('like_content', {
        source: $state.current.name,
      });

      contentListIds = union(content.content_list_ids, [activeBrand.liked_content_list_id]);
    } else {
      contentListIds = without(content.content_list_ids, activeBrand.liked_content_list_id);
    }

    await fetch(`${apiEndpoint}/${endpoints.licensedContentEndpoint}/${content.id}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        content_list_ids: contentListIds,
      }),
    });
  } catch (err) {
    // Do nothing.
    console.log(err);
  }
};

export const createContent = (contents: IContent[]) => async (dispatch, getState) => {
  const state: IStore = getState();
  const { activeBrand } = state;

  const contentToUpload = map(contents, (content) => {
    return {
      content_type: content.type,
      media: [
        {
          url: content.fileUrl,
          preview_url: content.previewUrl,
          media_type: content.type,
          content_type: content.file.type,
          size: content.size,
        },
      ],
      brand_id: activeBrand.id,
      source: 'upload',
      permission_level: 'full',
    };
  });

  const newContent = await dispatch(saveContent([], contentToUpload));

  const nextState: IStore = getState();
  const { saveContentErrorMessage } = nextState;

  if (saveContentErrorMessage) {
    dispatch({
      type: ActionTypes.CREATE_CONTENT_FAILURE,
      meta: {
        errorMessage: DEFAULT_UPLOAD_ERROR_MSG,
      },
    });
  } else {
    addEventLog('upload-content', {
      content_ids: map(newContent, 'id').join(','),
      count: size(newContent),
    });

    dispatch({
      type: ActionTypes.CREATE_CONTENT_SUCCESS,
      payload: {
        content: newContent,
      },
    });
  }
};

export const saveContent = (content: ILicensedContent[], params: any[]) => async (
  dispatch,
  getState,
) => {
  const updatedContent = map(content, (c, i) => {
    return {
      ...c,
      ...params[i],
    };
  });

  dispatch({
    type: ActionTypes.SAVE_MULTI_CONTENT_REQUEST,
    payload: {
      content: updatedContent,
    },
  });

  const contentIds = map(content, 'id');

  const state: IStore = getState();
  const { apiEndpoint } = state;

  const body: any = {};
  if (size(contentIds) > 0) {
    body.content_ids = contentIds;
    body.content = map(params, (p, i) => ({ ...p, id: contentIds[i] }));
  } else {
    body.content_ids = [];
    body.content = params;
  }

  // Needed for AspireX requests
  if (state.clientId) {
    body.client_id = state.clientId;
  }

  try {
    const resp = await fetch(`${apiEndpoint}/${endpoints.licensedContentEndpoint}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
    });

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ActionTypes.SAVE_MULTI_CONTENT_SUCCESS,
        payload: {
          content: json.data.data,
        },
      });

      return json.data.data;
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.SAVE_MULTI_CONTENT_FAILURE,
      payload: {
        content,
      },
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const deleteContent = (content: ILicensedContent[]) => async (dispatch, getState) => {
  const params = map(content, () => ({ deleted: true }));

  await dispatch(saveContent(content, params));

  const nextState: IStore = getState();
  const { saveContentErrorMessage } = nextState;

  if (saveContentErrorMessage) {
    dispatch({
      type: ActionTypes.DELETE_CONTENT_FAILURE,
      meta: {
        errorMessage: DEFAULT_DELETE_ERROR_MSG,
      },
    });
  } else {
    addEventLog('delete-contents', {
      content_ids: map(content, 'id').join(','),
      count: size(content),
    });

    dispatch({
      type: ActionTypes.DELETE_CONTENT_SUCCESS,
      payload: {
        content,
      },
    });
  }
};

export const downloadContent = (contents: ILicensedContent[]) => async (dispatch) => {
  dispatch({ type: ActionTypes.DOWNLOAD_CONTENT_REQUEST });

  addEventLog('download-content', {
    count: size(contents),
  });

  const zip = new JSZip();

  const promises = map(contents, async (content) => {
    const mediaUrl = getDownloadableMediaURL(content);

    if (mediaUrl) {
      const originalFilename = getFilenameFromUrl(mediaUrl);
      let extension = getFileExtension(originalFilename);
      if (!extension || !mime.getType(extension)) {
        // Guess if the url doesn't have the file extension
        extension = content.content_type === 'video' ? 'mp4' : 'png';
      }
      const filename = `${content.id}.${extension}`;
      try {
        const res = await fetch(mediaUrl);
        const blob = await res.blob();
        zip.file(filename, blob);
      } catch (err) {
        return;
      }
    }
  });

  try {
    const results = await Promise.all(promises);

    const count = size(zip.files);
    const expectedCount = size(results);

    if (count < expectedCount) {
      console.warn(`Unable to download ${expectedCount - count} files`);
      dispatch({
        type: ActionTypes.DOWNLOAD_CONTENT_FAILURE,
        meta: {
          errorMessage: `Unable to download ${expectedCount - count} files`,
        },
      });
    }

    if (count > 0) {
      const date = new Date();

      const zippedContent = await zip.generateAsync({ type: 'blob' });

      saveAs(
        zippedContent,
        `content_\
${date.getFullYear()}\
${date.getMonth()}\
${date.getDate()}\
${date.getHours()}\
${date.getMinutes()}\
${date.getSeconds()}.zip`,
      );
    }

    if (count === expectedCount) {
      dispatch({
        type: ActionTypes.DOWNLOAD_CONTENT_SUCCESS,
        payload: {
          content: contents,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: ActionTypes.DOWNLOAD_CONTENT_FAILURE,
      meta: {
        errorMessage: DEFAULT_DOWNLOAD_ERROR_MSG,
      },
    });
  }
};

export const saveTags = (contents: ILicensedContent[], tags: string[]) => async (
  dispatch,
  getState,
) => {
  const originalTags = getCommonTags(contents);

  const addedTags = difference(tags, originalTags);
  const removedTags = difference(originalTags, tags);

  const params = map(contents, (content) => {
    return {
      tags: union(difference(content.tags, removedTags), addedTags),
    };
  });

  await dispatch(saveContent(contents, params));

  const state: IStore = getState();
  const { saveContentErrorMessage } = state;

  if (saveContentErrorMessage) {
    dispatch({
      type: ActionTypes.UPDATE_TAGS_FAILURE,
      payload: {
        content: contents,
      },
      meta: {
        errorMessage: DEFAULT_UPDATE_ERROR_MSG,
      },
    });
  } else {
    addEventLog('update-content-tags', {
      content_ids: map(contents, 'id').join(','),
      count: size(contents),
    });

    dispatch({
      type: ActionTypes.UPDATE_TAGS_SUCCESS,
      payload: {
        content: contents,
      },
    });
  }
};

export const removeTag = (content: ILicensedContent, tag: string) => async (dispatch, getState) => {
  const newTags = without(content.tags, tag);
  const params = {
    tags: newTags,
  };

  await dispatch(saveContent([content], [params]));

  const state: IStore = getState();
  const { saveContentErrorMessage } = state;

  if (saveContentErrorMessage) {
    dispatch({
      type: ActionTypes.REMOVE_TAG_FAILURE,
      payload: {
        content,
        tag,
      },
      meta: {
        errorMessage: DEFAULT_UPDATE_ERROR_MSG,
      },
    });
  } else {
    addEventLog('remove-content-tag', {
      content_id: content.id,
      tag,
    });

    dispatch({
      type: ActionTypes.REMOVE_TAG_SUCCESS,
      payload: {
        tag,
      },
    });
  }
};

interface IPermissionUpdate {
  level: string;
  adsPermission: boolean;
  notes: string;
}

export const savePermissions = (contents: ILicensedContent[], update: IPermissionUpdate) => async (
  dispatch,
  getState,
) => {
  const params = map(contents, () => {
    return {
      permission_level: update.level,
      permission_notes: update.notes,
      limited_permissions: update.adsPermission ? ['ads'] : [],
    };
  });

  await dispatch(saveContent(contents, params));

  const state: IStore = getState();
  const { saveContentErrorMessage } = state;

  if (saveContentErrorMessage) {
    dispatch({
      type: ActionTypes.UPDATE_PERMISSIONS_FAILURE,
      payload: {
        content: contents,
      },
      meta: {
        errorMessage: DEFAULT_UPDATE_ERROR_MSG,
      },
    });
  } else {
    addEventLog('update-content-permissions', {
      content_ids: map(contents, 'id').join(','),
      count: size(contents),
    });

    dispatch({
      type: ActionTypes.UPDATE_PERMISSIONS_SUCCESS,
      payload: {
        content: contents,
      },
    });
  }
};

const go = (path: string, options: any) => (_, getState) => {
  const state: IStore = getState();
  const { $state } = state;
  $state.go(path, options);
};

export const searchSimilarContent = (content: ILicensedContent) => (dispatch, getState) => {
  const state: IStore = getState();
  const { $state } = state;

  addEventLog('search_content', {
    source: $state.current.name,
  });

  dispatch(
    go('connect', { imageUrl: getImage(content), redirectState: 'connect.browse_creators_v2' }),
  );
};

export const visitProfilePage = (content: ILicensedContent) => (dispatch) => {
  dispatch(go('profile', { publisherId: content.publisher.id }));
};

export const visitManagePage = (content: ILicensedContent) => (dispatch) => {
  dispatch(go('manage.relationship.main.mainView', { publisherId: content.publisher.id }));
};

export const visitAdminPage = (content: ILicensedContent) => () => {
  window.open(`/admin/licensed_content/${content.id}`, '_blank');
};

export const viewContent = (content: ILicensedContent) => (dispatch, getState) => {
  const state: IStore = getState();

  if (hasFeature(state.organization, 'content_dashboard')) {
    dispatch(go('content.licensed_content.performance', { licensedContentId: content.id }));
  } else {
    const contentIndex = indexOf(state.content, content);
    dispatch(toggleContentDetail(contentIndex));
  }
};

import * as React from 'react';
import cx from 'classnames';

import { useMobileStatus } from 'src/utils/hooks';
import { Button } from 'src/widgets/Button';

import { Modal, IModalProps } from './Modal';

import styles from './ConfirmationModal.scss';

type TTheme = 'primary' | 'danger';
interface IProps extends IModalProps {
  onConfirm();
  confirmLabel?: string;
  theme?: TTheme;

  classNames?: string[];
}

/**
 * @type {React.FunctionComponent}
 */
export const ConfirmationModal: React.FunctionComponent<IProps> = (props) => {
  const mobileType = useMobileStatus();

  const { theme, confirmLabel, onRequestClose, onConfirm, width, classNames, ...restProps } = props;

  return (
    <Modal
      className={cx(classNames.concat(styles.ConfirmationModal))}
      onRequestClose={onRequestClose}
      footer={
        <>
          <Button
            label={confirmLabel}
            theme={theme}
            className={cx(styles.button, styles.left)}
            onClick={onConfirm}
          />
          <Button
            label="Cancel"
            theme="light"
            className={styles.button}
            onClick={onRequestClose}
          />
        </>
      }
      {...restProps}
      width={mobileType ? null : width}
    />
  );
};

ConfirmationModal.defaultProps = {
  theme: 'primary',
  confirmLabel: 'Yes',
  classNames: [],
};

import * as React from 'react';
import cx from 'classnames';

import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { BlogContent } from './BlogContent';
import { FacebookContent } from './FacebookContent';
import { InstagramContent } from './InstagramContent';
import { PinterestContent } from './PinterestContent';
import { PlaceholderContent } from './PlaceholderContent';
import { TiktokContent } from './TiktokContent';
import { TwitterContent } from './TwitterContent';
import { YoutubeContent } from './YoutubeContent';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './Content.scss';

export const Content: React.FC = () => {
  const {
    hasData,
    network,
  } = useSocialProfileContext();

  const supportedNetworks: TNetworkIdentifier[] = [
    'blog',
    'facebook',
    'instagram',
    'pinterest',
    'tiktok',
    'twitter',
    'youtube',
  ];

  const renderContent = () => {
    if (!hasData || !supportedNetworks.includes(network)) {
      return <PlaceholderContent />;
    }
    switch (network) {
      case 'blog': return <BlogContent />;
      case 'facebook': return <FacebookContent />;
      case 'instagram': return <InstagramContent />;
      case 'pinterest': return <PinterestContent />;
      case 'tiktok': return <TiktokContent />;
      case 'twitter': return <TwitterContent />;
      case 'youtube': return <YoutubeContent />;
    }
  };

  return (
    <div
      className={cx(
        styles.Content,
        styles[supportedNetworks.includes(network) ? network : 'placeholder'],
      )}
    >
      {renderContent()}
    </div>
  );
};

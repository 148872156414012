import * as React from 'react';
import cx from 'classnames';
import { map, clone, pull, isUndefined } from 'lodash';

import { ArrowDownFilledIcon } from 'src/icons';
import { Checkbox } from 'src/widgets/Checkbox';
import { Popover } from 'src/widgets/Popover';

import styles from './MultiSelect.scss';

type TOptionValue = string | number;
interface IOption {
  label: string | JSX.Element;
  value: TOptionValue;
  actions?: JSX.Element[];
}
type TTheme = 'light' | 'info';
interface IProps {
  options: IOption[];
  onChange(value: TOptionValue[], index: number[]);

  selectedIndices?: number[];
  label?: string;
  hintText?: string;
  hideOptions?: boolean;
  onMenuClose?();
  theme?: TTheme;
  classNames?: string[];
}

type TDefaultProp =
  | 'hintText'
  | 'label'
  | 'hideOptions'
  | 'selectedIndices'
  | 'onMenuClose'
  | 'theme'
  | 'classNames';

interface IState {
  showMenu: boolean;
  selectedIndices: number[];
  hoveredIndex: number;
}

/**
 * @class
 * @extends {React.Component}
 */
export class MultiSelect extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    hintText: 'Select...',
    label: null,
    hideOptions: false,
    selectedIndices: [],
    onMenuClose: () => undefined,
    theme: 'light',
    classNames: [],
  };

  private arrowRef: React.RefObject<HTMLDivElement>;
  private controlled: boolean;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.arrowRef = React.createRef();

    // so passing 'null' as selectedIndices makes it controlled as well
    this.controlled = !isUndefined(props.selectedIndices);

    this.state = {
      showMenu: false,
      selectedIndices: [],
      hoveredIndex: null,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { options, hintText, label, hideOptions, theme, classNames, children } = this.props;
    const { showMenu, hoveredIndex } = this.state;
    const selectedIndices = this.getSelectedIndices();
    const selectedOptions = options.filter((_, index) => selectedIndices.includes(index));

    return (
      <div className={cx(classNames.concat(styles.MultiSelect))}>
        <div
          onClick={this.toggleMenu}
          className={cx(styles.button, styles[theme], {
            [styles.active]: showMenu,
          })}
        >
          <div className={styles.label}>
            {selectedOptions
              ? label
                ? label
                : `Selected ${selectedOptions.length} options`
              : hintText}
          </div>
          <div ref={this.arrowRef} className={styles.arrow}>
            <ArrowDownFilledIcon size={12} />
          </div>
        </div>
        <Popover
          className={styles.Popover}
          mountRef={this.arrowRef}
          minWidth={350}
          show={showMenu}
          onRequestClose={this.toggleMenu}
        >
          {!hideOptions && (
            <div className={styles.list}>
              {map(options, (option, index) => {
                const optionSelected = selectedIndices.includes(index);

                return (
                  <div
                    key={index}
                    className={cx(styles.option, {
                      [styles.active]: optionSelected,
                    })}
                    onClick={this.handleOptionClick.bind(this, index)}
                    onMouseEnter={this.setHoveredIndex.bind(this, index)}
                    onMouseLeave={this.unsetHoveredIndex}
                  >
                    <div className={styles.label}>
                      <Checkbox checked={optionSelected} className={styles.checkbox} />
                      <div className={styles.labelText}>{option.label}</div>
                    </div>
                    <div className={styles.actions}>
                      {hoveredIndex === index &&
                        map(option.actions, (option, index) => (
                          <div
                            className={cx(styles.item)}
                            key={index}
                            onClick={this.onActionClick}
                          >
                            {option}
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {children}
        </Popover>
      </div>
    );
  }

  /**
   * @private
   * Returns the selected indices based on whether component is controlled or not.
   *
   * @return {Boolean}
   */
  private getSelectedIndices = () => {
    return this.controlled ? this.props.selectedIndices : this.state.selectedIndices;
  };

  /**
   * @private
   * Toggles the menu.
   */
  private toggleMenu = () => {
    const { onMenuClose } = this.props;
    const { showMenu } = this.state;

    // notifies parent if it's closing menu
    if (showMenu) {
      onMenuClose();
    }

    this.setState({
      showMenu: !showMenu,
    });
  };

  /**
   * @private
   * Toggles an option and notifies parent.
   *
   * @param {Number} index the selected index.
   */
  private handleOptionClick = (index: number) => {
    const { options, onChange } = this.props;
    const selectedIndices = this.getSelectedIndices();

    const newSelectedIndices = clone(selectedIndices);
    if (newSelectedIndices.includes(index)) {
      pull(newSelectedIndices, index);
    } else {
      newSelectedIndices.push(index);
    }

    const selectedValues = options
      .filter((_, index) => newSelectedIndices.includes(index))
      .map((option) => option.value);

    onChange(selectedValues, newSelectedIndices);

    this.setState({
      selectedIndices: newSelectedIndices,
    });
  };

  /**
   * @private
   * Stop event propagation when action buttons are clicked.
   *
   * @param {React.MouseEvent<HTMLDivElement>} event the event object.
   */
  private onActionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * @private
   * Sets the hovered index.
   *
   * @param {Number} hoveredIndex the hovered option index.
   */
  private setHoveredIndex = (hoveredIndex: number) => {
    this.setState({
      hoveredIndex,
    });
  };

  /**
   * @private
   */
  private unsetHoveredIndex = () => {
    this.setState({
      hoveredIndex: null,
    });
  };
}

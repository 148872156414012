import * as React from 'react';
import { brandReducer } from '../AnalyzePage/Filters/BrandFilter/BrandFilter';
import { countryReducer } from '../AnalyzePage/Filters/CountryFilter/CountryFilter';
import { countries } from '../AnalyzePage/Filters/CountryFilter/countries';
import { creatorReducer } from './Filters/CreatorFilter/CreatorFilter';
import { ownerReducer } from './Filters/OwnerFilter/OwnerFilter';
import { paymentReducer } from './Filters/MonetaryFilters/PaymentFilter';
import { networkReducer, initialNetworkState } from './Filters/NetworkFilter';
import { dateReducer, initialDateState } from './Filters/DateFilter';
import { productCostReducer } from './Filters/MonetaryFilters/ProductCostFilter';
import { paymentAndProductCostReducer } from './Filters/MonetaryFilters/PaymentAndProductCostFilter';
import { AnalyzeContext } from './useAnalyze';
import { AnalyzeCompareContext } from './useAnalyzeCompare';

export { AnalyzeContext, AnalyzeCompareContext };

const { useReducer } = React;

type TFilter = any;
type TAnalyzePage = 'summary' | 'breakdown' | 'posts';

interface IAnalyzeProviderProps {
  apiEndpoint: string;
  organizationId?: string;
  clientId?: string;
  currentPage: TAnalyzePage;
  isAspirex: boolean;
  isQa: boolean;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
}

export const AnalyzeProvider: React.FunctionComponent<IAnalyzeProviderProps> = props => {
  const defaultState = {
    brands: [],
    countries: { countries, selected: 0 },
    creators: { creators: {}, selected: 0 },
    networks: { networks: initialNetworkState, selected: 0 },
    datePosted: initialDateState,
    owners: { owners: [], selectedCount: 0 },
    payment: { option: { label: '', index: null }, amountOne: 0, amountTwo: 0 },
    productCost: {
      option: { label: '', index: null },
      amountOne: 0,
      amountTwo: 0,
    },
    paymentAndProductCost: {
      option: { label: '', index: null },
      amountOne: 0,
      amountTwo: 0,
    },
    meta: {
      additionalFilters: [],
      error: null,
      noData: false,
      isComparing: false,
    },
    organizationId: props.organizationId,
    clientId: props.clientId,
    generic: {},
  };
  const [filters, setFilters] = useReducer(rootReducer, defaultState);
  const [filtersCompare, setFiltersCompare] = useReducer(
    rootReducer,
    defaultState
  );

  return (
    <AnalyzeContext.Provider
      value={{
        apiEndpoint: props.apiEndpoint,
        filters,
        setFilters,
        organizationId: props.organizationId,
        currentPage: props.currentPage,
        isAspirex: props.isAspirex,
        isQa: props.isQa,
        aspirexAnalytics: props.aspirexAnalytics,
      }}
    >
      <AnalyzeCompareContext.Provider
        value={{
          apiEndpoint: props.apiEndpoint,
          filters: filtersCompare,
          setFilters: setFiltersCompare,
          organizationId: props.organizationId,
          isComparing: filtersCompare.meta.isComparing,
        }}
      >
        {props.children}
      </AnalyzeCompareContext.Provider>
    </AnalyzeContext.Provider>
  );
};

const rootReducer = (state, action) => {
  return {
    brands: brandReducer(state, action),
    countries: countryReducer(state, action),
    creators: creatorReducer(state, action),
    networks: networkReducer(state, action),
    datePosted: dateReducer(state, action),
    owners: ownerReducer(state, action),
    payment: paymentReducer(state, action),
    productCost: productCostReducer(state, action),
    paymentAndProductCost: paymentAndProductCostReducer(state, action),
    meta: metaReducer(state, action),
    organizationId: state.organizationId,
    clientId: state.clientId,
    generic: genericFilterReducer(state, action),
  };
};

const genericFilterReducer = (state, action) => {
  const localState = state.generic;
  switch (action.type) {
    case 'SET_FILTER': {
      return {
        ...localState,
        [action.payload.filterKey]: action.payload.values,
      };
    }
    default:
      return localState;
  }
};

const metaReducer = (state, action) => {
  const localState = state.meta;
  const filters = localState.additionalFilters;
  switch (action.type) {
    case 'ADD_ADDITIONAL_FILTER': {
      if (filters.includes(action.payload)) {
        return localState;
      }
      return { ...localState, additionalFilters: [...filters, action.payload] };
    }
    case 'REMOVE_ADDITIONAL_FILTER': {
      return {
        ...localState,
        additionalFilters: filters.filter(filt => filt !== action.payload),
      };
    }
    case 'ERROR_LOADING_API': {
      return {
        ...localState,
        error: { ...action.payload },
      };
    }
    case 'NO_DATA_RETURNED': {
      return { ...localState, noData: true };
    }
    case 'DATA_RETURNED': {
      return { ...localState, noData: false };
    }
    case 'TOGGLE_COMPARE': {
      return { ...localState, isComparing: !localState.isComparing };
    }
    default:
      return localState;
  }
};

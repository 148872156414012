import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const FastForwardIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 488.07 488.071">
    <path
      d="M238.986,244.034c0,6.895-3.683,13.276-9.654,16.723L28.958,427.954c-5.971,3.452-13.333,3.452-19.306,0
			C3.68,424.5,0,418.13,0,411.23V76.832c0-6.892,3.675-13.27,9.652-16.723c2.988-1.723,6.321-2.583,9.655-2.583
			c3.341,0,6.663,0.864,9.655,2.583l200.379,167.2C235.304,230.759,238.986,237.137,238.986,244.034z M478.429,227.314
			l-200.386-167.2c-2.978-1.724-6.312-2.583-9.651-2.583s-6.653,0.864-9.654,2.583c-5.973,3.453-9.652,9.825-9.652,16.723v334.4
			c0,6.893,3.68,13.271,9.652,16.721c5.976,3.447,13.33,3.447,19.306,0l200.373-167.202c5.976-3.441,9.654-9.816,9.654-16.717
			C488.08,237.142,484.392,230.759,478.429,227.314z"
    />
  </SvgIcon>
));

export { FastForwardIcon };

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { isNumber } from 'lodash';

import { formatCurrency } from 'src/common/utils/formatCurrency';
import { TCurrencyCode } from 'src/common/models/campaign';

import { Cell, ICellProps } from './Cell';

const { useCallback, useMemo } = React;

import styles from './NumericCell.scss';

const DEFAULT_NUMBER_FORMAT = '0,0[.][00]';

interface IProps extends ICellProps {
  formatStr?: string;
  isPrice?: boolean;
  currencyCode?: TCurrencyCode;
  currencyXRate?: number;
}

/**
 * @type {React.FunctionComponent}
 */
export const NumericCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    config,
    currencyCode,
    currencyXRate,
    formatStr,
    isPrice,
    value,
    ...restProps
  } = props;

  const number = useMemo(() => {
    return isNumber(value) ? value : parseFloat(value);
  }, [value]);

  const formatValue = useCallback((value: number) => {
    if (!value && value !== 0) {
      return null;
    } else if (isPrice) {
      return formatCurrency(value, currencyCode, currencyXRate);
    } else if (formatStr) {
      return numeral(value).format(formatStr);
    }
    return value.toString();
  }, [currencyCode, currencyXRate, formatStr, isPrice]);

  const cellConfig = useMemo(() => {
    const copy = { ...config };
    copy.formatValue = copy.formatValue || formatValue;
    return copy;
  }, [formatValue, config]);

  return (
    <Cell
      {...restProps}
      className={cx(styles.NumericCell, className)}
      contentClassName={styles.content}
      config={cellConfig}
      value={number}
    />
  );
});

NumericCell.defaultProps = {
  formatStr: DEFAULT_NUMBER_FORMAT,
  isPrice: false,
};

import * as React from 'react';
import { size, map, sortBy, includes, pull, slice } from 'lodash';

import { Checkbox } from 'src/widgets/Checkbox';
import { FilterSection } from '../FilterSection';

import { ICreatorSkillset, ICreatorSkillsetFilters, ICreatorSkillsetOptions } from '../models';

const { useMemo } = React;

interface IProps extends ICreatorSkillsetFilters, ICreatorSkillsetOptions {
  onChange(skillsetFilters: ICreatorSkillsetFilters);
}

const CreatorSkillsetsSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeSkillsets = (skillset: ICreatorSkillset, checked: boolean) => {
    const newSelectedIdentifiers = slice(props.selectedIdentifiers, 0);
    if (checked) {
      newSelectedIdentifiers.push(skillset.identifier);
    } else {
      pull(newSelectedIdentifiers, skillset.identifier);
    }

    props.onChange({ selectedIdentifiers: newSelectedIdentifiers });
  };

  const skillsetChoices = useMemo(() => sortBy(props.choices, 'order'), [props.choices]);

  const hide = props.hide || size(skillsetChoices) === 0;

  return (
    <FilterSection header="Creator Skillsets:" hide={hide}>
      {map(skillsetChoices, (skillset) => (
        <Checkbox
          key={skillset.identifier}
          label={skillset.displayName}
          checked={includes(props.selectedIdentifiers, skillset.identifier)}
          onChange={(checked) => handleChangeSkillsets(skillset, checked)}
        />
      ))}
    </FilterSection>
  );
};

CreatorSkillsetsSection.defaultProps = {
  selectedIdentifiers: [],
  choices: [],
  hide: false,
};

export default CreatorSkillsetsSection;

import * as React from 'react';
import cx from 'classnames';

import { useDragging } from 'src/utils/hooks';

import { valueInRange } from './util';

const { useCallback, useEffect, useRef } = React;
import styles from './RangeSlider.scss';

interface IHandleProps {
  min: number;
  max: number;
  position: number;
  onChangePosition(newPosition: number);
  onStartDragging();
  onStoppedDragging();

  trackMin?: number;
  trackMax?: number;
  trackRef?: React.RefObject<HTMLDivElement>;
  isFocused?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const Handle: React.FunctionComponent<IHandleProps> = (props) => {
  const {
    max,
    min,
    onChangePosition,
    onStartDragging,
    onStoppedDragging,
    position,
    trackMax,
    trackMin,
    trackRef,
  } = props;

  const rawPosition = useRef(position);
  const previousClientX = useRef(null);
  const ref = useRef();
  const [isDragging, mousePosition] = useDragging(ref, { throttleWait: 100 });

  useEffect(() => {
    if (isDragging) {
      onStartDragging();
    }
    if (!isDragging && previousClientX.current !== null) {
      onStoppedDragging();
    }
  }, [isDragging, onStartDragging, onStoppedDragging]);

  const calculateNewPosition = useCallback((clientX: number) => {
    const dx = clientX - previousClientX.current;
    const trackNode = trackRef.current;
    const positionChange = (dx / trackNode.clientWidth) * (trackMax - trackMin);

    return valueInRange(rawPosition.current + positionChange, min, max);
  }, [trackMin, trackMax, trackRef, min, max]);

  useEffect(() => {
    if (isDragging) {
      const { clientX } = mousePosition;

      if (previousClientX.current === null) {
        // mousedown
        previousClientX.current = clientX;
        return;
      }

      const newPosition = calculateNewPosition(clientX);

      if (newPosition !== rawPosition.current) {
        previousClientX.current = clientX;
        rawPosition.current = newPosition;
        onChangePosition(newPosition);
      }
    } else {
      previousClientX.current = mousePosition.clientX;
      rawPosition.current = position;
    }
  }, [isDragging, mousePosition, onChangePosition, onStartDragging, position, calculateNewPosition]);

  // Calculate style
  const ratio = (position - trackMin) / (trackMax - trackMin);
  const left = valueInRange(100 * ratio, 0, 100);
  const style = { left: `${left}%` };

  return (
    <div
      ref={ref}
      className={cx(styles.Handle, {
        [styles.focused]: props.isFocused,
        [styles.grabbed]: isDragging,
      })}
      style={style}
    />
  );
};

import * as React from 'react';
import { pick } from 'lodash';

import { FilterSection } from '../FilterSection';
import AudienceLocationSection, { AUDIENCE_LOCATION_FILTERS } from './AudienceLocationSection';
import AudienceGenderSection, { AUDIENCE_GENDER_FILTERS } from './AudienceGenderSection';
import AudienceAgeSection, { AUDIENCE_AGE_FILTERS } from './AudienceAgeSection';
import AudienceEthnicitySection, { AUDIENCE_ETHNICITY_FILTERS } from './AudienceEthnicitySection';

import {
  IAudienceDemographicFilters,
  IAudienceDemographicOptions,
  IAudienceAgeFilters,
  IAudienceEthnicityFilters,
  IAudienceGenderFilters,
  IAudienceLocationFilters,
} from '../models';

const FILTERS = [
  ...AUDIENCE_LOCATION_FILTERS, ...AUDIENCE_GENDER_FILTERS, ...AUDIENCE_AGE_FILTERS, ...AUDIENCE_ETHNICITY_FILTERS
];

const pickFilters = (obj: any): IAudienceDemographicFilters => pick(obj, FILTERS);

interface IProps extends IAudienceDemographicFilters, IAudienceDemographicOptions {
  onChange(demographics: IAudienceDemographicFilters);
}

const AudienceDemographicsSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeAgeRange = (ageFilters: IAudienceAgeFilters) => {
    const newFilters = {
      ...pickFilters(props),
      ...ageFilters,
    };
    props.onChange(newFilters);
  };

  const handleChangeEthnicity = (ethnicityFilters: IAudienceEthnicityFilters) => {
    const newFilters = {
      ...pickFilters(props),
      ...ethnicityFilters,
    };
    props.onChange(newFilters);
  };

  const handleChangeGender = (genderFilters: IAudienceGenderFilters) => {
    const newFilters = {
      ...pickFilters(props),
      ...genderFilters,
    };
    props.onChange(newFilters);
  };

  const handleChangeLocation = (locationFilters: IAudienceLocationFilters) => {
    const newFilters = {
      ...pickFilters(props),
      ...locationFilters,
    };
    props.onChange(newFilters);
  };

  const hide =
    props.hide ||
    (props.hideAudienceEthnicity && props.hideAudienceAge && props.hideAudienceGender && props.hideAudienceLocation);

  return (
    <FilterSection
      header="Audience Demographics"
      hide={hide}
      collapsible={true}
      defaultIsOpen={props.isExpanded}
    >
      <AudienceAgeSection
        {...pick(props, AUDIENCE_AGE_FILTERS)}
        ageRangeChoices={props.ageRangeChoices}
        onChange={handleChangeAgeRange}
        hide={props.hideAudienceAge}
        hidePercentage={props.hideAudiencePercentage}
      />
      <AudienceEthnicitySection
        {...pick(props, AUDIENCE_ETHNICITY_FILTERS)}
        ethnicityChoices={props.ethnicityChoices}
        onChange={handleChangeEthnicity}
        hide={props.hideAudienceEthnicity}
      />
      <AudienceGenderSection
        {...pick(props, AUDIENCE_GENDER_FILTERS)}
        onChange={handleChangeGender}
        hide={props.hideAudienceGender}
        hidePercentage={props.hideAudiencePercentage}
      />
      <AudienceLocationSection
        {...pick(props, AUDIENCE_LOCATION_FILTERS)}
        countryChoices={props.countryChoices}
        regionChoices={props.regionChoices}
        onChange={handleChangeLocation}
        hide={props.hideAudienceLocation}
        hidePercentage={props.hideAudiencePercentage}
      />
    </FilterSection>
  );
};

export default AudienceDemographicsSection;

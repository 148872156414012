import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';
import { isFunction, isNumber } from 'lodash';

import { useElementResize } from 'src/utils/hooks';

import { calculateMountPosition, flipPlacement } from './utils';

const { useMemo, useRef, useCallback, useState, useEffect } = React;
import styles from './Popover.scss';

// horizontal anchor origin
export type TPopoverAnchorOrigin = 'start' | 'middle' | 'end';
export type TPopoverArrowPosition = 'start' | 'middle' | 'end';
export type TPopoverPlacement = 'left' | 'right' | 'top' | 'bottom';
export interface IPopoverOffset {
  x: number;
  y: number;
}

export interface IPopoverProps {
  className?: string;
  mountRef: React.RefObject<HTMLElement>;
  show: boolean;

  // Box model and positioning
  minWidth?: number;
  maxWidth?: number;
  height?: number; // max height
  onRequestClose?();
  onHeightUpdate?(height: number);
  renderMask?: boolean;
  showArrow?: boolean;
  anchorOrigin?: TPopoverAnchorOrigin;
  arrowPosition?: TPopoverArrowPosition;
  placement?: TPopoverPlacement;
  offset?: IPopoverOffset;
  shadowSize?: 'small' | 'large';

  // Styling
  contentClassName?: string;
  contentWrapperClassName?: string;
}

export const Popover: React.FunctionComponent<IPopoverProps> = (props) => {
  const {
    anchorOrigin,
    arrowPosition,
    children,
    className,
    contentClassName,
    contentWrapperClassName,
    height,
    maxWidth,
    minWidth,
    mountRef,
    offset,
    onHeightUpdate,
    onRequestClose,
    placement,
    renderMask,
    shadowSize = 'small',
    show,
    showArrow,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  useElementResize(ref);
  useElementResize(props.mountRef);

  // Used to make sure component and parent are mounted before calculating position.
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleRequestClose = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    handleClick(e);
    if (onRequestClose) {
      onRequestClose();
    }
  }, [onRequestClose]);

  const {
    top,
    left,
    arrowRelativeLeft,
    arrowRelativeTop,
    doFlip,
    maxHeight = height,
  } = useMemo(() => (
    calculateMountPosition({
      anchorOrigin,
      arrowPosition,
      mountRef,
      placement,
      offset,
      ref,
      show, // Not used but forces value refresh on show
    })
  ), [anchorOrigin, arrowPosition, mountRef, placement, offset, ref, show, mounted]);

  // skip server-side rendering
  if (typeof window === 'undefined') {
    return null;
  }

  if (isFunction(onHeightUpdate)) {
    onHeightUpdate(maxHeight);
  }

  const rootInlineStyle: React.CSSProperties = {
    top: `${top}px`,
    left: `${left}px`,
    minWidth: `${minWidth}px`,
  };

  if (maxWidth) {
    rootInlineStyle.maxWidth = `${maxWidth}px`;
  }

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cx(
        className,
        styles.Popover,
        styles[doFlip ? flipPlacement(placement) : placement],
        {
          [styles.active]: show && mounted,
        },
      )}
      style={rootInlineStyle}
      onClick={handleClick}
    >
      {renderMask && <div className={styles.mask} onClick={handleRequestClose} />}
      <div className={cx(styles.contentWrapper, styles[`shadow-${shadowSize}`], contentWrapperClassName)}>
        {showArrow && (
          <div
            className={styles.arrow}
            style={{
              left: isNumber(arrowRelativeLeft) ? `${arrowRelativeLeft}px` : undefined,
              top: isNumber(arrowRelativeTop) ? `${arrowRelativeTop}px` : undefined,
            }}
          />
        )}
        <div
          className={cx(styles.content, contentClassName)}
          style={{ maxHeight: `${maxHeight}px` }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};

Popover.defaultProps = {
  minWidth: 100,
  onRequestClose: () => undefined,
  anchorOrigin: 'end',
  arrowPosition: 'middle',
  placement: 'bottom',
  contentClassName: null,
  contentWrapperClassName: null,
  showArrow: true,
  renderMask: true,
};

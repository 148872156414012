import * as React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import { Button } from 'src/widgets/Button';
import { Input, InputGroup } from 'src/widgets/Input';
import { Modal } from 'src/widgets/Modal';

import styles from './ShareContentLibraryModal.scss';

const { useState, useEffect } = React;

interface IProps {
  show: boolean;
  contentShareURL: string;

  onRequestClose();
}

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const ShareContentLibraryModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const [copied, setCopied] = useState(false);

  const handleClickCopy = () => {
    copyToClipboard(props.contentShareURL);
    setCopied(true);
  };

  useEffect(() => {
    if (!props.show) {
      setTimeout(() => {
        setCopied(false);
      }, 400);
    }
  }, [props.show]);

  return (
    <Modal title="Share Content Library" show={props.show} onRequestClose={props.onRequestClose}>
      <div className={styles.shareModalContent}>
        <div className={styles.body}>
          Share content & collaborate with coworkers! Copy the following link and give it to
          coworkers that you&apos;d like to have access to your organization&apos;s content library:
        </div>
        <InputGroup
          append={<Button label="Copy" onClick={handleClickCopy} />}
          classNames={[styles.group]}
        >
          <Input value={props.contentShareURL} onChange={noop} readOnly={true} />
        </InputGroup>
        <div className={cx(styles.copied, { [styles.show]: copied })}>Copied!</div>
        <div className={styles.note}>
          Note: This link will share access only to the content libraries for the brands on your
          account.
        </div>
      </div>
    </Modal>
  );
});

export default ShareContentLibraryModal;

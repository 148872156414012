import * as React from 'react';
import { isNaN, isNil, isFunction } from 'lodash';

import { TableContext, ITableColumnConfig } from '../tableContext';
import { TableRowContext, ITableCellRenderContext } from '../rowContext';

import { EditableHover, EditInput } from './Editable';

const { useContext, useMemo, useState, useEffect, useRef } = React;

interface IProps {
  config: ITableColumnConfig;
  value: any;
}

const isEmptyValue = (value: any) => isNil(value) || isNaN(value) || value === '';

export const CellContent: React.FC<IProps> = React.memo((props) => {
  const { value: valueProp, config: columnConfig } = props;

  const [isCellSelected /*, setIsCellSelected */] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const prevEditing = useRef<boolean>(isEditing);
  const [valueState, setValueState] = useState<any>(null);

  // So we can show edited value after editing has ended.
  const value = valueState === null ? valueProp : valueState;

  useEffect(() => {
    setValueState(null);
  }, [valueProp]);

  const {
    cellType,
    field,
    render,
    formatValue,
  } = columnConfig;

  const tableContext = useContext(TableContext);
  const {
    config: tableConfig,
    onChangeCellValue,
    editing: isEditingTable,
    updateIsEditing: updateTableIsEditing,
  } = tableContext;
  const { renderEmptyCellContent } = tableConfig;

  const rowContext = useContext(TableRowContext);
  const {
    rowId,
    onToggleRowSelected,
    isSelected: isRowSelected,
  } = rowContext;

  // cellRenderContext is used to give extra context information and callbacks when using a custom render function.
  const cellRenderContext = useMemo<ITableCellRenderContext>(() => {
    return {
      // is in edit mode.
      isEditing,
      // is cell selected (TODO).
      isCellSelected,
      // is row selected.
      isRowSelected,
      // used to notify cell contents have changed due to editing.
      onChangeCellValue: (data: any) => isFunction(onChangeCellValue) && onChangeCellValue(rowId, field, data),
      // used to toggle isEditing state.
      onToggleEditing: () => setIsEditing(!isEditing),
      // used if we want to have a custom checkbox in a cell to select a row.
      onToggleRowSelected: () => isFunction(onToggleRowSelected) && onToggleRowSelected(),
    };
  }, [
    onToggleRowSelected,
    onChangeCellValue,
    setIsEditing,
    isCellSelected,
    isRowSelected,
    isEditing,
    rowId,
    field,
  ]);

  const cellCustomRenderedContent = useMemo(() => {
    if (isFunction(render)) {
      return render(value, cellRenderContext);
    }
  }, [render, value, cellRenderContext]);

  const formattedValue = useMemo(() => {
    if (isFunction(formatValue)) {
      return formatValue(value);
    }
  }, [formatValue, value]);

  const cellContent = useMemo(() => {
    if (isFunction(renderEmptyCellContent) && isEmptyValue(value)) {
      return renderEmptyCellContent(cellType, field, value);
    } else if (formattedValue) {
      return formattedValue;
    } else {
      return value;
    }
  }, [value, renderEmptyCellContent, formattedValue, field, cellType]);

  // Update table global state with which table cell is currently being edited.
  useEffect(() => {
    if (isEditing && !isEditingTable) {
      updateTableIsEditing(true);
    } else if (!isEditing && prevEditing.current) {
      updateTableIsEditing(false);
    }
    prevEditing.current = isEditing;
  }, [isEditing, prevEditing, updateTableIsEditing, isEditingTable]);

  // Always render custom cell content if "render" was provided.
  if (cellCustomRenderedContent) {
    return cellCustomRenderedContent;
  }

  // Show default string edit input if editing.
  if (isEditing) {
    const handleBlur = (newValue: any) => {
      setIsEditing(false);
      setValueState(newValue);

      // Don't fire onChange callback if values haven't changed.
      if (newValue === value) {
        return;
      }

      // Consider all "empty" values as the same.
      if (isEmptyValue(newValue) && isEmptyValue(value)) {
        return;
      }

      if (onChangeCellValue) {
        onChangeCellValue(rowId, field, newValue);
      }
    };
    return (
      <EditInput
        cellType={columnConfig.cellType}
        defaultValue={value}
        onBlur={handleBlur}
        onCancel={() => setIsEditing(false)}
        formattedValue={formattedValue}
        nullStr={columnConfig.nullStr}
        trueStr={columnConfig.trueStr}
        falseStr={columnConfig.falseStr}
      />
    );
  }

  // Show a border around the cell content when hovering if this cell is editable.
  if (columnConfig.editable) {
    return (
      <EditableHover
        onClick={() => setIsEditing(true)}
        cellType={cellType}
      >
        {cellContent}
      </EditableHover>
    );
  }

  return cellContent;
});

import * as React from 'react';
import cx from 'classnames';
import { compact, map, filter, isEmpty } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { IClientContentReview } from 'src/common/models/clientContentReview';
import { IContentReview, IClientFeedback } from 'src/common/models/contentReview';
import { IOrganization } from 'src/common/models/organization';
import { InviteIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { LoadSpinner } from 'src/widgets/LoadSpinner';

import { AgencyContentItem } from './AgencyContentItem';
import { IContentInfo } from './model';
import { ShareModal } from './ShareModal';

import contentReviewCompareFn from './utils/contentReviewCompareFn';
import processContentReview from './utils/processContentReview';

import styles from './AgencyContentReviewPage.scss';

interface IProps {
  // api endpoint
  apiEndpoint: string;
  brandId: number;
  org: IOrganization;
  managerId: number;

  goToRelationView(relationId: number);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  contentReviews: IContentReview[];
  clientFeedbacks: IClientFeedback[];
  isFetchingContentReviews: boolean;

  showShareModal: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class AgencyContentReviewPage extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    // check ids
    if (!props.brandId) {
      throw new Error('Brand id is needed.');
    }
    if (!props.org) {
      throw new Error('Organization is needed.');
    }

    this.state = {
      contentReviews: [],
      clientFeedbacks: [],
      isFetchingContentReviews: false,

      showShareModal: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    this.setState({
      isFetchingContentReviews: true,
    });
    this.fetchContentReviews()
      .then((contentReviews) => {
        this.setState({
          contentReviews: (contentReviews || []).sort(contentReviewCompareFn),
          isFetchingContentReviews: false,
        });
      })
      .catch(() => {
        this.setState({
          isFetchingContentReviews: false,
        });
      });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { brandId, org, managerId, goToRelationView, classNames } = this.props;
    const { isFetchingContentReviews, contentReviews, showShareModal } = this.state;

    const contents = compact(map(contentReviews, processContentReview));
    const hasContents = !isEmpty(contents);
    const contentReviewCount = filter(contents, (content) => !content.rejected).length;

    return (
      <div className={cx(classNames.concat(styles.AgencyContentReviewPage))}>
        {isFetchingContentReviews
          ? <LoadSpinner />
          : (
            <div className={styles.mainContent}>
              {hasContents && (
                <>
                  <div className={styles.header}>
                    <div className={styles.title}>
                      {contentReviewCount} pieces of content need review
                    </div>
                    <div className={styles.text}>
                      Please review that the following content has met your agreed upon guidelines
                    </div>
                    <Button
                      theme="info"
                      icon={<InviteIcon />}
                      label="Share with Others"
                      onClick={this.showShareModal}
                    />
                    <ShareModal
                      brandId={brandId}
                      org={org}
                      managerId={managerId}
                      contents={contents}
                      show={showShareModal}
                      onRequestClose={this.closeShareModal}
                      createClientContentReview={this.createClientContentReview}
                      sendEmailInvite={this.sendEmailInvite}
                    />
                  </div>
                  <div className={styles.contentList}>
                    {map(contents, (content, index) => (
                      <AgencyContentItem
                        key={index}
                        content={content}
                        classNames={[styles.contentItem]}
                        onApprove={this.approveContent.bind(this, content)}
                        onReject={this.rejectContent.bind(this, content)}
                        goToRelationView={() => goToRelationView(content.relationId)}
                      />
                    ))}
                  </div>
                </>
              )}
              {!hasContents && (
                <div className={styles.noContents}>No content needs to be reviewed.</div>
              )}
            </div>
          )}
      </div>
    );
  }

  private showShareModal = () => {
    this.setState({
      showShareModal: true,
    });
  };

  private closeShareModal = () => {
    this.setState({
      showShareModal: false,
    });
  };

  /**
   * @private
   * Fetches the content reviews.
   *
   * @return {Promise}
   */
  private fetchContentReviews = (): Promise<IContentReview[]> =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint, brandId } = this.props;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.contentReviewEndpoint}?brand_id=${brandId}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          resolve(json.data);
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Approves the content.
   *
   * @param {IContentInfo} content the content info.
   *
   * @return {Promise}
   */
  private approveContent = (content: IContentInfo) =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint } = this.props;
      const { projectId, iteration, id } = content;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.projectEndpoint}/${projectId}/content_review/${id}/approve`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              iteration,
              project_id: projectId,
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const updatedContentReview = json.data;
          const { contentReviews } = this.state;
          const contentReviewIndex = contentReviews.findIndex(
            (contentReview) => contentReview.id === id,
          );
          const contentReview = contentReviews[contentReviewIndex];

          // update content review state
          this.setState(
            {
              contentReviews: [
                ...contentReviews.slice(0, contentReviewIndex),
                {
                  ...contentReview,
                  automatic_approval_expire_time_ts:
                    updatedContentReview.automatic_approval_expire_time_ts,
                  state: updatedContentReview.state,
                },
                ...contentReviews.slice(contentReviewIndex + 1),
              ],
            },
            resolve,
          );
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Rejects the content.
   *
   * @return {Promise}
   */
  private rejectContent = (content: IContentInfo, checkoff: boolean[], comment: string) =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint } = this.props;
      const { projectId, iteration, id } = content;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.projectEndpoint}/${projectId}/content_review/${id}/reject`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              iteration,
              project_id: projectId,
              brand_guideline_checkoff: checkoff,
              brand_reject_comment: comment,
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const updatedContentReview = json.data;
          const { contentReviews } = this.state;
          const contentReviewIndex = contentReviews.findIndex(
            (contentReview) => contentReview.id === id,
          );
          const contentReview = contentReviews[contentReviewIndex];

          // update content review state
          this.setState(
            {
              contentReviews: [
                ...contentReviews.slice(0, contentReviewIndex),
                {
                  ...contentReview,
                  automatic_approval_expire_time_ts:
                    updatedContentReview.automatic_approval_expire_time_ts,
                  state: updatedContentReview.state,
                  product: {
                    ...contentReview.product,
                    brand_guideline_checkoff: checkoff,
                  },
                },
                ...contentReviews.slice(contentReviewIndex + 1),
              ],
            },
            resolve,
          );
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Creates a new client content review.
   *
   * @param {String[]} contentReviewIds a list of content review ids.
   * @param {Boolean} realtime whether to share the contents in realtime.
   *
   * @return {Promise}
   */
  private createClientContentReview = (
    contentReviewIds: number[],
    realtime: boolean,
  ): Promise<IClientContentReview> =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint, brandId } = this.props;

      try {
        const resp = await fetch(`${apiEndpoint}/${endpoints.clientContentReviewEndpoint}`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            brand_id: brandId,
            content_review_ids: contentReviewIds,
            realtime,
          }),
        });

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const clientReview: IClientContentReview = json.data;

          resolve(clientReview);
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Rejects the content.
   *
   * @return {Promise}
   */
  private sendEmailInvite = (reviewId: number, emails: string[], note: string) =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint } = this.props;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.clientContentReviewEndpoint}/${reviewId}/invite`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              email_addresses: emails,
              note,
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          resolve();
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });
}

import { TNetworkIconType } from 'src/common/models/networkIdentifier';
import { TPostType } from 'src/common/models/postType';

/**
 * Returns the network identifier from given post type.
 *
 * @param {TPostType} postType the given post type.
 *
 * @return {TNetworkIconType}
 */
export default function getNetworkByPostType(postType: TPostType): TNetworkIconType {
  switch (postType) {
    case 'youtube_mention':
    case 'youtube_dedicated':
      return 'youtube';
    case 'instagram':
    case 'instagram_video':
    case 'instagram_story':
      return 'instagram';
    case 'twitter_post':
      return 'twitter';
    case 'facebook_post':
      return 'facebook';
    case 'blog_mention':
    case 'blog_dedicated':
      return 'blog';
    case 'pinterest':
      return 'pinterest';
    case 'tiktok_video':
      return 'tiktok';
    case 'additional_videos':
      return 'additional_videos';
    case 'additional_images':
      return 'additional_images';
    case 'other':
      return 'other';
  }
}

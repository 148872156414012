import * as React from 'react';

import { SubmitButton } from 'src/widgets/Button';
import { Textarea } from 'src/widgets/Textarea/Textarea';

const { useState } = React;
import styles from './ApproveContent.scss';

interface IProps {
  // actions
  submitApprove(comment: string): Promise<void>;
}

/**
 * @type {React.FunctionComponent}
 */
export const ApproveContent: React.FunctionComponent<IProps> = (props) => {
  const { submitApprove } = props;

  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCommentChange = (value: string) => setComment(value);

  const handleSubmitApprove = async () => {
    try {
      setIsSubmitting(true);
      await submitApprove(comment);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
      setComment('');
    }
  };

  return (
    <div className={styles.ApproveContent}>
      <div className={styles.header}>Approve this content</div>
      <div className={styles.commentBox}>
        <div className={styles.commentHeader}>Write a comment (optional):</div>
        <Textarea className={styles.textArea} onChange={handleCommentChange}/>
      </div>
      <div className={styles.actionContainer}>
        <SubmitButton
          className={styles.submitButton}
          isSubmitting={isSubmitting}
          submittingLabel="Submitting"
          label="Submit Approval"
          onClick={handleSubmitApprove}
        />
      </div>
    </div>
  );
};

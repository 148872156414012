import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const SearchIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.76,20.59l-6.91-6.91c0.98-1.23,1.57-2.78,1.57-4.48C16.41,5.23,13.18,2,9.21,2C5.23,2,2,5.23,2,9.21
      s3.23,7.21,7.21,7.21c1.69,0,3.24-0.59,4.47-1.57l6.91,6.91c0.16,0.16,0.37,0.24,0.58,0.24c0.21,0,0.42-0.08,0.58-0.24
      C22.08,21.44,22.08,20.92,21.76,20.59z M3.65,9.21c0-3.07,2.49-5.56,5.56-5.56s5.56,2.49,5.56,5.56c0,3.07-2.49,5.56-5.56,5.56
      S3.65,12.27,3.65,9.21z"
    />
  </SvgIcon>
));

export { SearchIcon };

import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Notice } from 'src/widgets/Notice';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Table, ITableColumnConfig } from 'src/widgets/Table';

import { AccessUsageCell } from './AccessUsageCell';
import { IAdvertiserAccessTableRowData } from './redux/models';

const { useMemo } = React;
import styles from './ContentTable.scss';

interface IProps {
  isLoading: boolean;
  data: IAdvertiserAccessTableRowData[];
  hasError?: boolean;
  headerActions?: JSX.Element;
  limitReached?: boolean;
  hideAccessColumn?: boolean;
  onContactSupport();
  onToggleAccess?(publisherId: number, selectedForUse: boolean);

  className?: string;
}

const columns: ITableColumnConfig[] = [
  {
    field: 'publisher',
    headerName: 'Creators',
  },
  {
    cellType: 'network',
    field: 'accounts',
    headerName: 'Accounts',
    showAccountName: true,
  },
  {
    cellType: 'date',
    field: 'startTs',
    headerName: 'Start date',
  },
  {
    cellType: 'date',
    field: 'endTs',
    headerName: 'End Date',
  },
];

/**
 * @type {React.FunctionComponent}
 */
export const ContentTable: React.FunctionComponent<IProps> = React.memo((props: IProps) => {
  const {
    className,
    data,
    hasError,
    headerActions,
    hideAccessColumn,
    isLoading,
    onContactSupport,
    onToggleAccess,
  } = props;

  const renderTable = useMemo(() => {
    if (isLoading) {
      return <LoadSpinner />;
    }
    if (hasError) {
      return (
        <Notice showDivider={true} className={cx(styles.notice, styles.center)} type="error">
          <div>
            There was an error when trying to fetch the accounts. If this continues to happen,
            please
            <span className={styles.contactSupport} onClick={onContactSupport}>
              {' '}
              contact support
            </span>
            .
          </div>
        </Notice>
      );
    }
    if (data) {
      if (data.length === 0) {
        return (
          <Notice showDivider={true} className={cx(styles.notice, styles.center)} type="info">
            You don&apos;t have advertiser access to any creator social accounts right now.
          </Notice>
        );
      } else {
        const tableData = map(data, (row, index) => ({
          ...row,
          id: row.publisher,
          accessUsage: hideAccessColumn ? null : (
            <AccessUsageCell
              key={index + row.publisher}
              selectedForUse={row.selectedForUse}
              pending={row.pending}
              onToggle={() => onToggleAccess(row.publisher_id, !row.selectedForUse)}
            />
          ),
          _raw: row.publisher,
        }));

        const tableColumns = (() => hideAccessColumn
          ? columns
          : [
            ...columns,
            {
              field: 'accessUsage',
              headerName: 'Access',
              width: null,
            },
          ]
        )();

        return (
          <>
            <Table
              data={tableData}
              columns={tableColumns}
              config={{
                selectable: false,
                scrollable: false,
              }}
              classNames={styles.table}
              headerActions={headerActions}
            />
            <Notice showDivider={true} className={styles.notice} type="alert">
              <div>
                Need help?
                <span className={styles.contactSupport} onClick={onContactSupport}>
                  {' '}
                  Contact support
                </span>
                .
              </div>
            </Notice>
          </>
        );
      }
    }

    return null;
  }, [data, hasError, headerActions, hideAccessColumn, isLoading, onContactSupport, onToggleAccess]);

  return <div className={cx(styles.ContentTable, className)}>{renderTable}</div>;
});

ContentTable.defaultProps = {
  hideAccessColumn: true,
};

import * as React from 'react';
import { filter, keys, map, mapValues } from 'lodash';

import { NetworkIcon } from 'src/common';
import { NetworkSvgIcon } from 'src/icons';
import { Checkbox } from 'src/widgets/Checkbox';
import { Popover } from 'src/widgets/Popover';

import cx from 'classnames';
import styles from './Filters.scss';
import { TNetworkIconType } from 'src/common/models/networkIdentifier';

const { useRef, useState } = React;

export const networkReducer = (state, action) => {
  const localState = state.networks;
  switch (action.type) {
    case 'CLEAR_ALL_NETWORKS': {
      const deselected = mapValues(localState.networks, () => false);
      return { networks: deselected, selected: 0 };
    }
    case 'TOGGLE_NETWORK': {
      return {
        ...localState,
        networks: {
          ...localState.networks,
          [action.payload]: !localState.networks[action.payload],
        },
        selected: localState.networks[action.payload]
          ? localState.selected - 1
          : localState.selected + 1,
      };
    }
    default: {
      return localState;
    }
  }
};

type TNetworkKey =
  | 'instagram'
  | 'instagram_video'
  | 'instagram_story'
  | 'pinterest'
  | 'youtube'
  | 'blog'
  | 'twitter'
  | 'facebook'
  | 'tiktok';

export const initialNetworkState: {
  [key in TNetworkKey]: boolean
} = {
  instagram: false,
  instagram_video: false,
  instagram_story: false,
  pinterest: false,
  youtube: false,
  blog: false,
  twitter: false,
  facebook: false,
  tiktok: false,
};

const NETWORK_CONFIG: {
  [key in TNetworkKey]: {
    id: string;
    icon: TNetworkIconType;
    label: string;
  };
} = {
  instagram: {
    id: 'instagram',
    icon: 'instagram',
    label: 'Instagram Posts'
  },
  instagram_video: {
    id: 'instagram_video',
    icon: 'instagram',
    label: 'Instagram Videos'
  },
  instagram_story: {
    id: 'instagram_story',
    icon: 'instagram',
    label: 'Instagram Stories'
  },
  tiktok: {
    id: 'tiktok',
    icon: 'tiktok',
    label: 'TikTok'
  },
  pinterest: {
    id: 'pinterest',
    icon: 'pinterest',
    label: 'Pinterest'
  },
  youtube: {
    id: 'youtube',
    icon: 'youtube',
    label: 'YouTube'
  },
  blog: {
    id: 'blog',
    icon: 'blog',
    label: 'Blog'
  },
  twitter: {
    id: 'twitter',
    icon: 'twitter',
    label: 'Twitter'
  },
  facebook: {
    id: 'facebook',
    icon: 'facebook',
    label: 'Facebook'
  },
}

export const NetworkFilter = ({ filters, setFilters, isDisabled }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  // const { filters, setFilters } = useAnalyze();

  const { networks } = filters.networks;

  const toggleValue = value => {
    setFilters({ type: 'TOGGLE_NETWORK', payload: value });
  };
  const justSelected = filter(networks, s => s === true);
  const clearAll = () => {
    setFilters({ type: 'CLEAR_ALL_NETWORKS' });
  };

  const renderNetwork = (networkId: string, iconName: TNetworkIconType, label: string) => {
    return (
      <Checkbox
        className={styles.MenuItem}
        checked={networks[networkId]}
        onClick={() => toggleValue(networkId)}
        label={(
          <div className={styles.NetworkFilterLabelContainer}>
            <div className={cx(styles.NetworkFilterIcons, styles[iconName] || {})}>
              <NetworkIcon identifier={iconName} />
            </div>
            <span className={styles.NetworkFilterIconLabels}>
              { label }
            </span>
          </div>
        )}
      />
    )
  }

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {justSelected.length === 0 ? (
            <NetworkSvgIcon size={16} />
          ) : (
            justSelected.length
          )}
        </span>
        <span className={styles.filterText}>
          {justSelected.length === keys(networks).length ? 'All' : ''} Networks
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <ul className={styles.MenuList}>
          <li className={styles.MenuItem} onClick={() => clearAll()}>
            Clear All
          </li>
          {
            map(NETWORK_CONFIG, (network) => {
              return (
                <li>
                  { 
                    renderNetwork(
                      network.id,
                      network.icon,
                      network.label
                    )
                  }
                </li>
              )
            })
          }
        </ul>
      </Popover>
    </>
  );
};

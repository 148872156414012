import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import { Tooltip, TTooltipPlacement } from 'src/widgets/Tooltip/Tooltip';

import styles from './IconButton.scss';

type TTheme = 'primary' | 'black' | 'light' | 'info';

type TBaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, IconButtonComponent & HTMLDivElement>;

export interface IIconButtonProps extends TBaseProps {
  icon: JSX.Element;

  theme?: TTheme;
  round?: boolean;
  border?: boolean;
  tooltip?: JSX.Element | string;
  tooltipPlacement?: TTooltipPlacement;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  className?: string;
}

type TDefaultProp =
  | 'theme'
  | 'round'
  | 'border'
  | 'tooltip'
  | 'forwardedRef'
  | 'disabled'
  | 'tooltipPlacement';

// Allow passing ref from parent
export const IconButton = React.forwardRef((props: IIconButtonProps, ref: React.RefObject<HTMLDivElement>) => {
  return <IconButtonComponent {...props} forwardedRef={ref} />;
});

/**
 * @class
 * @extends {React.PureComponent}
 */
class IconButtonComponent extends React.PureComponent<IIconButtonProps> {
  public static defaultProps: Pick<IIconButtonProps, TDefaultProp> = {
    round: true,
    theme: 'black',
    border: false,
    tooltip: null,
    tooltipPlacement: 'top',
    disabled: false,
  };

  private ref: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  public constructor(props: IIconButtonProps) {
    super(props);

    this.ref = props.forwardedRef || React.createRef();
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      forwardedRef, // eslint-disable-line @typescript-eslint/no-unused-vars
      icon,
      theme,
      round,
      border,
      tooltip,
      tooltipPlacement,
      disabled,
      className,
      ...props
    } = this.props;

    return (
      <div
        className={cx(styles.IconButton, className, styles[theme], {
          [styles.round]: round,
          [styles.border]: border,
          [styles.disabled]: disabled,
        })}
        ref={this.ref}
        {...props}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
      >
        {icon}
        {disabled && <div className={styles.mask} />}
        {tooltip && (
          <Tooltip
            placement={tooltipPlacement}
            tooltipColor="black"
            mountRef={this.ref}
            className={styles.Tooltip}
          >
            {tooltip}
          </Tooltip>
        )}
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onClick } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  private handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onMouseDown } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (isFunction(onMouseDown)) {
      onMouseDown(event);
    }
  };
}

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { last, isEmpty } from 'lodash';

import { Histogram } from 'src/widgets/charts';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ErrorBoundary } from 'src/utils';
import { ILicensedContent } from 'src/common/models/licensedContent';

import { BestPerformingContent } from './BestPerformingContent';
import { ChannelBreakdown } from './ChannelBreakdown';

import { TDataField, dataFieldConfig } from './models/channel';
import { IRawMetricsData } from './models/channel';
import { IHistogramData } from './models/histogram';

import processMetricsData from './utils/processMetricsData';

import styles from './AnalyzeSummaryPage.scss';

interface IProps {
  histogramData?: IRawMetricsData[];
  contents?: ILicensedContent[];
  channels?: any[];
  isLoadingHistogramData?: boolean;
  isLoadingContentData?: boolean;
  isLoadingChannelData?: boolean;
  channelInfo?: any[];
  dataFieldInfo?: any[];
  onContentClick?(id: string);
  onSearchClick?(imageUrl: string);
}

type TDefaultProp =
  | 'histogramData'
  | 'contents'
  | 'channels'
  | 'isLoadingHistogramData'
  | 'isLoadingContentData'
  | 'isLoadingChannelData'
  | 'channelInfo'
  | 'dataFieldInfo'
  | 'onContentClick'
  | 'onSearchClick';
interface IState {
  data: IHistogramData[];
  selectedField: TDataField;
}

/**
 * @class
 * @extends {React.Component}
 */
export class AnalyzeSummaryPage extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    histogramData: [],
    contents: [],
    channels: [],
    isLoadingHistogramData: false,
    isLoadingContentData: false,
    isLoadingChannelData: false,
    channelInfo: [],
    dataFieldInfo: [],
    onSearchClick: () => undefined,
    onContentClick: () => undefined,
  };

  /**
   * @inheritdoc
   */
  public constructor(props: IProps) {
    super(props);

    this.state = {
      data: null,
      selectedField: 'ecv',
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps) {
    const { histogramData } = nextProps;

    if (!isEmpty(histogramData)) {
      console.log(histogramData);
      return {
        data: processMetricsData(histogramData),
      };
    }

    return null;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      contents,
      channels,
      channelInfo,
      dataFieldInfo,
      onContentClick,
      onSearchClick,
      isLoadingHistogramData,
      isLoadingContentData,
      isLoadingChannelData,
    } = this.props;
    const { selectedField } = this.state;

    return (
      <div className={styles.AnalyzeSummaryPage}>
        <ErrorBoundary>
          {!isLoadingHistogramData && this.renderHistogram()}
          {isLoadingHistogramData && <LoadSpinner />}
        </ErrorBoundary>
        <ErrorBoundary>
          <BestPerformingContent
            selectedField={selectedField}
            contents={contents}
            isLoading={isLoadingContentData}
            onContentClick={onContentClick}
            onSearchClick={onSearchClick}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ChannelBreakdown
            channels={channels}
            channelInfo={channelInfo}
            dataFieldInfo={dataFieldInfo}
            isLoading={isLoadingChannelData}
          />
        </ErrorBoundary>
      </div>
    );
  }

  private renderHistogram = () => {
    const { data, selectedField } = this.state;
    if (isEmpty(data)) {
      return <div className={styles.message}>No histogram data available.</div>;
    }

    const spendSum = last(data).spend;
    const ecvSum = last(data).ecv;
    const contentSum = last(data).content_count;
    const impressionSum = last(data).impressions;

    const selectedData = data.map(d => ({
      x: d.ts,
      y: d[selectedField],
    }));

    return (
      <React.Fragment>
        <div className={styles.list}>
          <div
            onClick={this.onFieldSelected.bind(this, 'ecv')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'ecv',
            })}
          >
            <div className={styles.value}>{numeral(ecvSum).format('$0,0')}</div>
            <div className={styles.name}>Estimated Content Value</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'impressions')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'impressions',
            })}
          >
            <div className={styles.value}>
              {numeral(impressionSum).format('0,0')}
            </div>
            <div className={styles.name}>Total Impressions</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'spend')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'spend',
            })}
          >
            <div className={styles.value}>
              {numeral(spendSum).format('$0,0')}
            </div>
            <div className={styles.name}>Estimated Cost</div>
          </div>
          <div
            onClick={this.onFieldSelected.bind(this, 'content_count')}
            className={cx(styles.item, {
              [styles.active]: selectedField === 'content_count',
            })}
          >
            <div className={styles.value}>
              {numeral(contentSum).format('0,0')}
            </div>
            <div className={styles.name}>Content Created</div>
          </div>
        </div>
        <Histogram
          title={dataFieldConfig[selectedField].displayName}
          classNames={[styles.histogram]}
          height={400}
          isDollar={selectedField === 'ecv' || selectedField === 'spend'}
          data={selectedData}
        />
      </React.Fragment>
    );
  };

  private onFieldSelected = (selectedField: TDataField) => {
    this.setState({
      selectedField,
    });
  };
}

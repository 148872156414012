import cx from 'classnames';
import * as React from 'react';

import { CloseIcon } from 'src/icons';
import { AvatarIcon, IconSize, IAvatarIconProps } from 'src/widgets/Icon';
import { Tooltip } from 'src/widgets/Tooltip';

import styles from './AvatarList.scss';
const { useRef } = React;

const OVERFLOW_LIMIT = 9;

interface IAvatarListProps {
  className?: string;
  /**
   * List of AvatarIcon data
   */
  avatarIconDataList: IAvatarIconProps[];

  /**
   * Size of the `AvatarIcon`
   * Default value
   */
  avatarIconSize?: IconSize;
  /**
   * Maximum number of displayed avatars before it gets truncated
   */
  numDisplayedAvatars?: number;
  /**
   * onClick callback
   */
  onClick?: React.MouseEventHandler;
  /**
   * onMouseEnter callback
   */
  onMouseEnter?(event: React.MouseEvent<SVGElement>);
  /**
   * onMouseLeave callback
   */
  onMouseLeave?(event: React.MouseEvent<SVGElement>);
  /**
   * Show add more icon
   */
  showAddMoreIcon?: boolean;
  /**
   * Tooltip for the 'Add More' icon
   */
  addMoreIconTooltipText?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const AvatarList: React.FunctionComponent<IAvatarListProps> = (props) => {
  const {
    addMoreIconTooltipText,
    avatarIconDataList,
    avatarIconSize,
    className,
    numDisplayedAvatars,
    showAddMoreIcon,
  } = props;

  const addMoreIconTooltipRef = useRef();

  const overflowCount = avatarIconDataList.length - numDisplayedAvatars;
  const overflowText =
    Math.min(overflowCount, OVERFLOW_LIMIT) + (overflowCount > OVERFLOW_LIMIT ? '+' : '');

  return (
    <div className={cx(className, styles.AvatarList)}>
      <ul className={styles.list}>
        {/* Avatars */}
        {avatarIconDataList.slice(0, numDisplayedAvatars).map((avatarIconData, i) => (
          <li
            key={i}
            className={cx(styles.item, styles[avatarIconSize])}
            style={{
              zIndex: getZIndex(i, props),
            }}
          >
            <AvatarIcon
              imageUrl={avatarIconData.imageUrl}
              size={avatarIconSize}
              tooltipText={avatarIconData.tooltipText}
              tooltipPlacement={avatarIconData.tooltipPlacement}
            />
          </li>
        ))}

        {/* Overflow icon, if the total number of avatars exceed the numDisplayedAvatars */}
        {overflowCount > 0 && (
          <li
            className={cx(styles.item, styles[avatarIconSize])}
            style={{
              zIndex: getZIndex(numDisplayedAvatars, props),
            }}
          >
            <AvatarIcon
              className={styles.overflowIcon}
              imageUrl={null}
              size={avatarIconSize}
              initials={overflowText}
            />
          </li>
        )}

        {/* Add more icon */}
        {showAddMoreIcon && (
          <li
            className={cx(styles.item, styles[avatarIconSize])}
            ref={addMoreIconTooltipRef}
            style={{
              zIndex: getZIndex(numDisplayedAvatars + (overflowCount > 0 ? 1 : 0), props),
              border: 'none',
            }}
          >
            <div className={styles.addMoreIcon}>
              <CircleWithDashedBorder className={styles.addMoreIconBorder} />
              <CloseIcon className={styles.addMoreIconPlus} />
            </div>
          </li>
        )}
        {addMoreIconTooltipText && (
          <Tooltip mountRef={addMoreIconTooltipRef} placement={'top'} tooltipColor={'black'}>
            {addMoreIconTooltipText}
          </Tooltip>
        )}
      </ul>
    </div>
  );
};

const CircleWithDashedBorder: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = React.memo((props) => (
  <div {...props}>
    <svg viewBox="0 0 100 100">
      <circle r="48.5" cx="50" cy="50" />
    </svg>
  </div>
));

AvatarList.defaultProps = {
  avatarIconSize: AvatarIcon.defaultProps.size,
  numDisplayedAvatars: 3,
  showAddMoreIcon: false,
  addMoreIconTooltipText: 'Add more',
};

function getZIndex(index: number, props: IAvatarListProps) {
  return (
    props.numDisplayedAvatars +
    (props.showAddMoreIcon ? 1 : 0) - // max z-index
    index +
    1
  );
}

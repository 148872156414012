import * as React from 'react';
import { isFunction } from 'lodash';

import { IToastMessage, IToastRefHandles } from 'src/widgets/';
import { IOverlayProps, Overlay } from 'src/widgets/Overlay';
import { Toast } from 'src/widgets/Toast';
import { ICampaign } from 'src/common/models/campaign';
import { ISocialAccount } from 'src/common/models/socialAccount';

import { HistoryBackButton } from './HistoryBackButton/HistoryBackButton';
import { SimilarCreatorList } from './SimilarCreatorList/SimilarCreatorList';
import { SocialProfile } from './SocialProfile';
import { SocialProfileContextProvider } from './context/SocialProfileContext';

const { useCallback, useRef } = React;
import styles from './SocialProfile.scss';

type TOverlayProps = Partial<Pick<IOverlayProps, 'onRequestClose' | 'showCloseIcon'>>;

export interface ISocialProfileOverlayProps extends TOverlayProps {
  // Create
  apiEndpoint?: string;
  campaign?: ICampaign;
  socialAccount?: ISocialAccount;
  brandInstagramUsername?: string;

  // Create actions
  goToManage?: (relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  inviteToCampaign?: (socialAccount: ISocialAccount) => void;
  sendOffer?: (accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  // Actions
  onCreatorSelected?: (socialAccount: ISocialAccount) => void; // When a similar creator is selected

  // Flags
  allowFavorite?: boolean;
  isQa?: boolean;
  loadAccountDetails?: boolean; // Extra fetch for full account details
  selfServeExperiment?: boolean;
  show?: boolean;
  showCreateFeatures?: boolean;
  showSimilarCreators?: boolean;
  hideActions?: boolean;

  // Toast
  toastRef?: React.RefObject<IToastRefHandles>; // If not provided, Social Profile will have its own Toast
  showToastMessage?(message: IToastMessage);

  // TODO
  reportAsIncorrect?(accountName: string);
}

export const SocialProfileOverlay: React.FC<ISocialProfileOverlayProps> = (props) => {
  const {
    apiEndpoint,
    loadAccountDetails,
    onCreatorSelected: onCreatorSelectedProp,
    onRequestClose,
    showCloseIcon,
    showSimilarCreators,
    show,
    socialAccount,
    toastRef: toastRefProp,
    brandInstagramUsername,
  } = props;

  const toastRef = useRef<IToastRefHandles>(toastRefProp ? toastRefProp.current : null);
  const overlayRef = useRef<HTMLDivElement>(null);

  // Check apiEndpoint
  if (loadAccountDetails && !apiEndpoint) {
    throw new Error('`apiEndpoint` is required for fetching full account details');
  }

  // Show toast message -- fire and forget
  const showToastMessage = useCallback((message: IToastMessage) => {
    toastRef.current.showMessage(message);
  }, [toastRef]);

  // Scroll to top when creator is selected
  const onCreatorSelected = (account: ISocialAccount) => {
    const overlayElem = overlayRef && overlayRef.current;
    if (overlayElem) {
      overlayElem.scroll({ top: 0, behavior: 'smooth' });
    }

    if (isFunction(onCreatorSelectedProp)) {
      onCreatorSelectedProp(account);
    }
  };

  return (
    <Overlay
      className={styles.SocialProfileOverlay}
      onRequestClose={onRequestClose}
      showCloseIcon={showCloseIcon}
      ref={overlayRef}
      show={show}
      closeOnBackdropClick
    >
      <SocialProfileContextProvider
        {...props}
        showToastMessage={showToastMessage}
        onCreatorSelected={onCreatorSelected}
      >
        {showSimilarCreators && <HistoryBackButton />}
        <SocialProfile socialAccount={socialAccount} brandInstagramUsername={brandInstagramUsername} />
        {showSimilarCreators && <SimilarCreatorList />}
      </SocialProfileContextProvider>
      {!toastRefProp ? <Toast ref={toastRef} /> : null}
    </Overlay>
  );
};

SocialProfileOverlay.defaultProps = {
  allowFavorite: false,
  hideActions: false,
  loadAccountDetails: false,
  onRequestClose: () => undefined,
  selfServeExperiment: false,
  show: false,
  showCreateFeatures: true,
  showCloseIcon: true,
  showSimilarCreators: false,
  socialAccount: null,
};

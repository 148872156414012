import * as React from 'react';

import {
  BlogIcon, FacebookIcon, ImageIcon, InstagramIcon,
  PinterestIcon, SnapchatIcon, ToolsIcon,
  TwitterIcon, VideoIcon, YoutubeIcon, TiktokIcon
} from 'src/icons';
import { TIconSize } from 'src/widgets/Icon';
import { TNetworkIconType } from 'src/common/models/networkIdentifier';
import { IconSize } from 'src/widgets/Icon/Icon';

interface INetworkIconProps {
  className?: string;
  identifier: TNetworkIconType;
  size?: TIconSize;
}

/**
 * @class
 * @extends {React.FunctionComponent}
 *
 * @param {TNetworkIdentifier} identifier the network identifier.
 * @param {TIconSize} size the icon size.
 *
 * @return {SvgIcon}
 */
export const NetworkIcon: React.FunctionComponent<INetworkIconProps> = (props) => {
  const { className, identifier, size } = props;

  let Icon = null;
  switch (identifier) {
    case 'blog': {
      Icon = BlogIcon;
      break;
    }
    case 'instagram': {
      Icon = InstagramIcon;
      break;
    }
    case 'youtube': {
      Icon = YoutubeIcon;
      break;
    }
    case 'facebook': {
      Icon = FacebookIcon;
      break;
    }
    case 'pinterest': {
      Icon = PinterestIcon;
      break;
    }
    case 'twitter': {
      Icon = TwitterIcon;
      break;
    }
    case 'snapchat': {
      Icon = SnapchatIcon;
      break;
    }
    case 'tiktok': {
      Icon = TiktokIcon;
      break;
    }
    case 'additional_images': {
      Icon = ImageIcon;
      break;
    }
    case 'additional_videos': {
      Icon = VideoIcon;
      break;
    }
    case 'other': {
      Icon = ToolsIcon;
      break;
    }
  }

  return Icon ? <Icon className={className} size={size} /> : null;
};

NetworkIcon.defaultProps = {
  size: IconSize.SMALL,
};

import * as React from 'react';
import cx from 'classnames';

import {
  CloseIcon,
  OfferBlueIcon,
  SpinnerIcon,
  ThumbsUpIcon,
} from 'src/icons';
import { IconButton } from 'src/widgets/Button';

import { ICampaign } from 'src/common/models/campaign';

import styles from './ActionButtonGroup.scss';

export type TActionTaking = 'reporting' | 'skipping' | 'inviting';

interface IProps {
  isMobile: boolean;
  campaign: ICampaign;
  actionTaking: TActionTaking;
  remainingCount: number;

  onSkip(offensive: boolean): void;
  onInvite(): void;
  onOffer(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ActionButtonGroup: React.FunctionComponent<IProps> = React.memo((props) => {
  const { isMobile, campaign, actionTaking, remainingCount, onSkip, onInvite, onOffer } = props;

  return (
    <div
      className={cx(styles.ActionButtonGroup, props.className, {
        [styles.mobile]: isMobile,
      })}
    >
      <div className={styles.actions}>
        <div className={cx(styles.item, styles.skip)}>
          <IconButton
            className={styles.iconButton}
            icon={actionTaking === 'skipping' ? <SpinnerIcon size={32} /> : <CloseIcon size={32} />}
            theme="light"
            border={true}
            disabled={!!actionTaking}
            onClick={() => onSkip(false)}
            tooltip="Skip this creator"
          />
          {!isMobile && <div className={styles.label}>skip</div>}
        </div>
        <div className={cx(styles.item, styles.invite)}>
          <IconButton
            className={styles.iconButton}
            icon={
              actionTaking === 'inviting' ? <SpinnerIcon size={32} /> : <ThumbsUpIcon size={32} />
            }
            theme="light"
            border={true}
            disabled={!!actionTaking}
            onClick={onInvite}
            tooltip="Invite this creator to campaign"
          />
          {!isMobile && <div className={styles.label}>invite</div>}
        </div>
        {campaign && campaign.offers_payment && (
          <div className={cx(styles.item, styles.offer)}>
            <IconButton
              className={styles.iconButton}
              icon={<OfferBlueIcon size={32} />}
              disabled={!!actionTaking}
              theme="primary"
              onClick={onOffer}
              tooltip="Send offer to this creator"
            />
            {!isMobile && <div className={styles.label}>offer</div>}
          </div>
        )}
      </div>
      {!isMobile && <div className={styles.remaining}>{remainingCount} creators remaining</div>}
    </div>
  );
});

ActionButtonGroup.defaultProps = {
  className: null,
};

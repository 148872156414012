import * as React from 'react';
import qs from 'qs';
import { isArray, isEmpty, isNumber, map, uniqBy } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { TProgram } from 'src/common/models/program';
import { getErrorMessageFromResponse, IAPIErrorResponse } from 'src/common/utils/getErrorMessageFromResponse';
import { IFetchResult } from 'src/utils/hooks/useGet';

import { useInviteContext } from './useInviteContext';
import { useResources } from './useResources';
import { IInviteProps } from '../Invite';
import { getSocialAccountsFromProspects } from '../utils';

const { useEffect, useState } = React;

interface IInviteRequest extends Pick<IInviteProps, 'prospect'> {
  programId: TProgram['id'];
  url: string;
}

interface IInviteState extends Partial<Pick<IInviteProps, 'prospect'>> {
  isInviting?: boolean;
  isInvited?: boolean;
  programId?: TProgram['id'];
  error?: string | Error;
}

export const useInviteMember = (props: Pick<IInviteProps, 'prospect'>) => {
  const { prospect } = props;
  const {
    apiEndpoint,
    clientId,
    selectedProgramId,
  } = useInviteContext();

  const { resourceId } = useResources();
  const [inviteState, setInviteState] = useState<IInviteState>(null);

  /**
   *
   */
  const sendInvite = (id?: TProgram['id']) => {
    if (isEmpty(inviteState) || !inviteState.isInviting) {
      setInviteState(() => ({
        programId: id || selectedProgramId,
        prospect,
      }));
    }
  };

  const request: IInviteRequest = (() => {
    if (
      isNumber(resourceId) &&
      !isEmpty(inviteState) &&
      isNumber(inviteState.programId) &&
      !isEmpty(inviteState.prospect)
    ) {
      const socialAccounts = uniqBy(
        isArray(prospect)
          ? getSocialAccountsFromProspects(...prospect)
          : getSocialAccountsFromProspects(prospect),
        'username',
      );

      if (isEmpty(socialAccounts)) {
        return null;
      }

      const query = {
        client_id: clientId,
        resource_id: resourceId,
        source: 'creator_search',
        program_id: inviteState.programId,
        network_type: socialAccounts[0].network_identifier, // Always assume that all accounts are in the same network
        usernames: map(socialAccounts, (socialAccount) => socialAccount.username),
        names: map(socialAccounts, (socialAccount) => (
          socialAccount.full_display_name || socialAccount.display_name || socialAccount.username
        )),
      };
      const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
      return {
        programId: inviteState.programId,
        prospect,
        url: `${apiEndpoint}/${endpoints.members}/invite?${queryString}`,
      };
    }
  })();

  useEffect(() => {
    // Fetch invite based on the passed request, not on the current state
    async function fetchInvite(req: IInviteRequest) {
      if (isEmpty(req)) {
        setInviteState(null);
        return;
      }

      try {
        setInviteState((state) => ({
          ...state,
          isInviting: true,
        }));
        const abortController = new AbortController();
        const response = await fetch(req.url, {
          method: 'POST',
          signal: abortController.signal,
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        });
        const json = await response.json() as IFetchResult<any>;
        if (json.status && json.status.code === 200 && json.data === true) {
          setInviteState({
            isInvited: true,
            programId: req.programId,
            prospect: req.prospect,
          });
        } else {
          setInviteState({
            error: getErrorMessageFromResponse(json as IAPIErrorResponse),
          });
        }
      } catch (error) {
        setInviteState({ error });
      }
    }

    if (
      request &&
      !isEmpty(inviteState) &&
      !inviteState.isInviting &&
      !inviteState.isInvited &&
      inviteState.programId &&
      inviteState.prospect
    ) {
      fetchInvite(request);
    }
  }, [inviteState, request]);

  return {
    ...inviteState,
    sendInvite,
  };
};

import * as React from 'react';
import cx from 'classnames';
import { chain, clone, isEmpty, map, pullAt } from 'lodash';

import {
  ArrowLeftIcon,
  ExportIcon,
} from 'src/icons';
import {
  IconButton,
  SubmitButton,
} from 'src/widgets/Button';
import {
  Toast,
  IToastRefHandles,
  TOAST_CLOSE_DURATION,
} from 'src/widgets/Toast';
import { ShareModal } from 'src/common/ShareModal';
import { ProposalListItem } from './ProposalListItem';

import { IClientProposal } from 'src/common/models/clientProposal';
import { IFavoriteList } from 'src/common/models/favoriteList';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';

import styles from './ProposalView.scss';

interface IProps {
  brandId: number;
  org: IOrganization;
  managerId: number;

  favoriteList: IFavoriteList;
  socialAccounts: ISocialAccount[];
  goBackToCreatorView();
  createProposal(listId: number, comments: IComment[]): Promise<IClientProposal>;
  sendProposal(proposalId: number, emails: string[], note: string): Promise<void>;

  classNames?: string[];
}
type TDefaultProp = 'classNames';
export interface IComment {
  accountId: number;
  text: string;
}
interface IState {
  comments: IComment[];
  isCreatingProposal: boolean;
  showShareModal: boolean;
  proposalId: number;
  campaignId: number;

  // send proposal
  isSendingProposal: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ProposalView extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private listRef: React.RefObject<HTMLDivElement>;
  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.listRef = React.createRef();
    this.toastRef = React.createRef();

    this.state = {
      comments: [],
      isCreatingProposal: false,
      showShareModal: false,
      proposalId: null,
      campaignId: null,

      isSendingProposal: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { goBackToCreatorView, favoriteList, classNames } = this.props;
    const { isCreatingProposal } = this.state;

    return (
      <div className={cx(classNames.concat(styles.ProposalView))}>
        <IconButton
          icon={<ArrowLeftIcon size={26} />}
          className={styles.arrowBack}
          onClick={goBackToCreatorView}
        />
        <div className={styles.title}>Leave Notes & Share &quot;{favoriteList.name}&quot;</div>
        <div className={styles.message}>
          You can fill out your notes on each creator below before sending. Click on the &quot;Share&quot;
          button when you&apos;re ready!
        </div>
        <SubmitButton
          label="Share"
          submittingLabel="Sharing..."
          isSubmitting={isCreatingProposal}
          disabled={isCreatingProposal}
          className={styles.shareButton}
          icon={<ExportIcon size={18} />}
          onClick={this.createProposal}
        />
        {this.renderList()}
        {this.renderShareModal()}
        <Toast ref={this.toastRef} />
      </div>
    );
  }

  private renderList = () => {
    const { socialAccounts } = this.props;

    return (
      <div ref={this.listRef} className={styles.list}>
        {map(socialAccounts, (socialAccount) => (
          <ProposalListItem
            key={socialAccount.id}
            classNames={[styles.item]}
            socialAccount={socialAccount}
            onCommentChange={this.onCommentChange.bind(this, socialAccount.id)}
          />
        ))}
      </div>
    );
  };

  /**
   * @private
   * Renders the share Modal.
   *
   * @return {JSX}
   */
  private renderShareModal = () => {
    const { brandId, org, managerId, socialAccounts } = this.props;
    const { showShareModal, isSendingProposal, proposalId, campaignId } = this.state;

    const suggestedRecipients = chain(org.managers)
      .filter((manager) => !!(manager.email && manager.full_name))
      .map((manager) => ({
        email: manager.email,
        name: manager.full_name,
      }))
      .value();

    return (
      <ShareModal
        shareLink={`${window.location.origin}/brands/agency_proposal?proposal_id=${proposalId}&bid=${brandId}&token=${org.id}&mid=${managerId}&cid=${campaignId}&count=${socialAccounts.length}`}
        show={showShareModal}
        suggestedRecipients={suggestedRecipients}
        isSubmitting={isSendingProposal}
        onRequestClose={this.closeShareModal}
        onRequestShare={this.sendProposal}
      />
    );
  };

  private sendProposal = (emails: string[], note: string) => {
    const { sendProposal, goBackToCreatorView } = this.props;
    const { proposalId } = this.state;
    const toast = this.toastRef.current;

    this.setState({
      isSendingProposal: true,
    });

    sendProposal(proposalId, emails, note)
      .then(() => {
        toast.showMessage({
          content: (
            <div>
              Proposal has been sent to <span>{emails.length} </span>
              client{emails.length > 1 ? 's' : ''}.
            </div>
          ),
          duration: 1000,
        });

        setTimeout(() => {
          this.setState({
            isSendingProposal: false,
            showShareModal: false,
          });

          goBackToCreatorView();
        }, 1000 + TOAST_CLOSE_DURATION);
      })
      .catch(() => {
        this.setState({
          isSendingProposal: false,
        });

        toast.showMessage({
          content: 'There was an error when trying to send the proposal.',
          type: 'error',
        });
      });
  };

  private onCommentChange = (accountId: number, text: string) => {
    const { comments } = this.state;
    const commentIndex = comments.findIndex((comment) => comment.accountId === accountId);
    const newComments = clone(comments);

    if (isEmpty(text)) {
      pullAt(newComments, commentIndex);
    } else if (commentIndex !== -1) {
      newComments[commentIndex].text = text;
    } else {
      newComments.push({
        accountId,
        text: text && text.length >= 1500 ? text.substr(0, 1496) + '...' : text,
      });
    }

    this.setState({
      comments: newComments,
    });
  };

  private createProposal = () => {
    const { createProposal, favoriteList } = this.props;
    const { comments } = this.state;
    const toast = this.toastRef.current;

    this.setState({
      isCreatingProposal: true,
    });
    createProposal(favoriteList.id, comments)
      .then((proposal) => {
        console.log(proposal);
        this.setState({
          showShareModal: true,
          isCreatingProposal: false,
          proposalId: proposal.id,
          campaignId: proposal.campaign.id,
        });
      })
      .catch(() => {
        this.setState({
          isCreatingProposal: false,
        });

        toast.showMessage({
          content: 'There was an error when trying to share the proposal.',
          type: 'error',
        });
      });
  };

  private closeShareModal = () => {
    this.setState({
      showShareModal: false,
      proposalId: null,
    });
  };
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const RoundAddCircleIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0,0h24v24H0V0z" />
    <path
      d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M16,13h-3v3c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1
		v-3H8c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h3V8c0-0.55,0.45-1,1-1h0c0.55,0,1,0.45,1,1v3h3c0.55,0,1,0.45,1,1v0
		C17,12.55,16.55,13,16,13z"
    />
  </SvgIcon>
));

export { RoundAddCircleIcon };

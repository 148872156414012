import * as React from 'react';
import { Popover } from 'src/widgets/Popover';
import cx from 'classnames';
import styles from './Filters.scss';
const { useRef, useState } = React;

const additionalFilters = [
  { name: 'country', label: 'Country' },
  { name: 'creator', label: 'Creator' },
  { name: 'owner', label: 'Owner' },
  { name: 'payment', label: 'Payment' },
  { name: 'paymentAndProductCost', label: 'Payment + Product cost' },
  { name: 'productCost', label: 'Product cost' },
];

export const AddFilter = ({ addFilter, isCompare, isDisabled, selected }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleSelect = selection => {
    setOpen(false);
    addFilter(selection);
  };
  return (
    <>
      <div
        ref={ref}
        className={cx(styles.addFilterButton, {
          [styles.compare]: isCompare,
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        +
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
        placement={'right'}
        anchorOrigin="start"
      >
        <ul className={styles.MenuList}>
          {additionalFilters.map(filter => (
            <li
              key={filter.name}
              className={cx(styles.MenuItem, {
                [styles.isDisabled]: selected.some(
                  filterName => filterName === filter.name
                ),
              })}
              onClick={() => handleSelect(filter.name)}
            >
              {filter.label}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
};

import * as React from 'react';
import cx from 'classnames';
import Promise from 'bluebird';
import { map, isEmpty, toString } from 'lodash';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { IClientProposal } from 'src/common/models/clientProposal';
import {
  ChatIcon,
  SpinnerIcon,
  ThumbsUpIcon,
} from 'src/icons';
import { Button } from 'src/widgets/Button';
import { LazyImage } from 'src/widgets/Image';
import { Overlay } from 'src/widgets/Overlay';
import { Progress } from 'src/widgets/Progress';
import {
  ITableColumnConfig,
  Table,
} from 'src/widgets/Table';
import {
  IToastRefHandles,
  Toast,
} from 'src/widgets/Toast';

import { ProposalListItem } from './ProposalListItem';

import styles from './ProposalReviewPage.scss';

const emptyFunc = () => undefined;
const ASSETS = process.env.ASSETS;
const aiqLogo = `${ASSETS}/aiq_logo.svg`;

interface IProps {
  proposal: IClientProposal;

  updateFeedback(socialAccountId: number, feedback: string);
  updateApproved(socialAccountId: number, approved: boolean);

  socialAccounts: ISocialAccount[];
  isFetchingSocialAccounts: boolean;
  reviews: {
    [socialAccountId: number]: IReview;
  };

  classNames?: string[];
}
type TDefaultProp = 'classNames';
export interface IReview {
  comment?: string;
  approved?: boolean;
}
type TMode = 'detail' | 'list';
interface IState {
  selectedMode: TMode;

  isSendingProposal: boolean;
  showShareModal: boolean;
  proposalId: string;

  selectedSocialAccounts: ISocialAccount[];
  isUpdatingApproved: boolean;

  // detail view
  selectedSocialAccountId: number | null;
  showDetailView: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ProposalReviewPage extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private columnConfig: ITableColumnConfig[];
  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.columnConfig = [
      {
        headerName: 'Creator name',
        field: 'username',
      },
      {
        headerName: 'Your feedback',
        field: 'feedback',
      },
      {
        headerName: 'Comments',
        field: 'comment',
        cellType: 'text',
      },
      {
        headerName: 'Content preview',
        field: 'contents',
        cellType: 'media',
      },
      {
        headerName: 'Reach',
        field: 'reach',
        cellType: 'numeric',
        formatStr: '0,0',
      },
      {
        headerName: 'Engagement',
        field: 'engagement',
        cellType: 'numeric',
        formatStr: '0,0',
      },
      {
        headerName: 'Engagement %',
        field: 'engagement_ratio',
        cellType: 'numeric',
        formatStr: '0.0%',
      },
      {
        headerName: 'Network',
        field: 'networks',
        cellType: 'network',
      },
    ];

    this.toastRef = React.createRef();

    this.state = {
      selectedMode: 'detail',
      isSendingProposal: false,
      showShareModal: false,
      proposalId: null,

      selectedSocialAccounts: [],
      isUpdatingApproved: false,

      selectedSocialAccountId: null,
      showDetailView: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { proposal, reviews, socialAccounts, isFetchingSocialAccounts, classNames } = this.props;
    const { selectedMode, selectedSocialAccountId, showDetailView } = this.state;
    const { comments, user: manager, campaign } = proposal;

    const selectedSocialAccount = socialAccounts.find(
      (socialAccount) => socialAccount.id === selectedSocialAccountId,
    );
    const comment = comments.find(
      (comment) => comment.social_account_id === selectedSocialAccountId,
    );
    const review = reviews[selectedSocialAccountId] || {
      approved: false,
      comment: '',
    };

    return (
      <div className={cx(classNames.concat(styles.ProposalReviewPage))}>
        <div className={styles.logo}>
          <LazyImage
            className={styles.logoImage}
            src={campaign.brand.logo_url}
            fallbackSrc={aiqLogo}
          />
        </div>
        <div className={styles.title}>Creators for {campaign.name}</div>
        <div className={styles.message}>
          {manager.display_name || manager.full_name} has shared this list of creators with you for{' '}
          {campaign.name}. If you like a creator, click the &quot;Approve&quot; button. If you think someone
          is not quite right, click the &quot;Write Feedback&quot; button.
        </div>
        <div className={styles.selectedMode}>
          <div
            className={cx(styles.modeItem, {
              [styles.active]: selectedMode === 'detail',
            })}
            onClick={this.selectMode.bind(this, 'detail')}
          >
            Detail View
          </div>
          <div
            className={cx(styles.modeItem, {
              [styles.active]: selectedMode === 'list',
            })}
            onClick={this.selectMode.bind(this, 'list')}
          >
            List View
          </div>
        </div>
        {isFetchingSocialAccounts && (
          <Progress
            percentage={Math.round(
              (socialAccounts.length / proposal.favorite_list.element_count) * 100,
            )}
            type="info"
            label={`Fetching creators...(${socialAccounts.length}/${proposal.favorite_list.element_count})`}
            className={styles.progress}
          />
        )}
        {selectedMode === 'detail' && this.renderDetailView()}
        {selectedMode === 'list' && this.renderListView()}
        <Toast ref={this.toastRef} />
        <Overlay show={showDetailView} onRequestClose={this.closeDetailView}>
          {selectedSocialAccount && (
            <ProposalListItem
              classNames={[styles.item]}
              socialAccount={selectedSocialAccount}
              approved={review.approved}
              feedback={review.comment}
              manager={manager}
              comment={comment && comment.comment}
              updateFeedback={this.updateFeedback.bind(this, selectedSocialAccount.id)}
              updateApproved={this.updateApproved.bind(this, selectedSocialAccount.id)}
            />
          )}
        </Overlay>
      </div>
    );
  }

  /**
   * @private
   * Renders the list view.
   *
   * @return {JSX}
   */
  private renderDetailView = () => {
    const { proposal, socialAccounts, reviews } = this.props;
    const comments = proposal.comments;
    const manager = proposal.user;

    return (
      <div className={styles.detailView}>
        {map(socialAccounts, (socialAccount) => {
          const comment = comments.find(
            (comment) => comment.social_account_id === socialAccount.id,
          );
          const review = reviews[socialAccount.id] || {
            approved: false,
            comment: '',
          };

          return (
            <ProposalListItem
              key={socialAccount.id}
              classNames={[styles.item]}
              socialAccount={socialAccount}
              approved={review.approved}
              feedback={review.comment}
              manager={manager}
              comment={comment && comment.comment}
              updateFeedback={this.updateFeedback.bind(this, socialAccount.id)}
              updateApproved={this.updateApproved.bind(this, socialAccount.id)}
            />
          );
        })}
      </div>
    );
  };

  /**
   * @private
   * Renders the table view.
   *
   * @return {JSX}
   */
  private renderListView = () => {
    const { proposal, socialAccounts, reviews } = this.props;
    const { selectedSocialAccounts, isUpdatingApproved } = this.state;

    const comments = proposal.comments;
    const selectedUnapprovedAccounts = selectedSocialAccounts.filter(
      (socialAccount) => !(reviews[socialAccount.id] && reviews[socialAccount.id].approved),
    );

    // construct data for table
    const data = socialAccounts.map((socialAccount) => {
      const comment = comments.find((comment) => comment.social_account_id === socialAccount.id);
      const review = reviews[socialAccount.id] || {
        approved: false,
        comment: '',
      };

      return {
        id: toString(socialAccount.id),
        username: socialAccount.username || socialAccount.name,
        feedback: this.renderFeedbackCell(review, socialAccount),
        comment: comment && comment.comment,
        contents: socialAccount.images,
        reach: socialAccount.reach,
        engagement: socialAccount.engagement,
        engagement_ratio: socialAccount.engagement_ratio,
        networks: [{ type: socialAccount.network_identifier }],
        _raw: socialAccount,
      };
    });

    return (
      <div className={styles.listView}>
        <Table
          columns={this.columnConfig}
          data={data}
          onSelectedDataChange={this.onSelectedRowsChange}
          headerActions={
            <Button
              className={styles.approveButton}
              label={
                isUpdatingApproved
                  ? 'Approving...'
                  : `Approve ${selectedUnapprovedAccounts.length} Creators`
              }
              icon={isUpdatingApproved ? <SpinnerIcon size={17} /> : <ThumbsUpIcon size={16} />}
              disabled={isEmpty(selectedUnapprovedAccounts) || isUpdatingApproved}
              onClick={this.updatedApprovedMulti}
              round={true}
            />
          }
          config={{
            rowHeight: 56,
          }}
          paddingBottom={0}
          classNames={styles.table}
        />
      </div>
    );
  };

  private renderFeedbackCell = (review: IReview, socialAccount: ISocialAccount) => {
    const smallerIcon = review.approved && !isEmpty(review.comment);
    const message = !isEmpty(review.comment)
      ? review.comment.length > 40
        ? review.comment.substr(0, 37) + '...'
        : review.comment
      : review.approved
        ? 'You Approved'
        : '';

    return (
      <div
        className={cx(styles.feedbackCell, {
          [styles.clickable]: !isEmpty(message),
        })}
        onClick={
          !isEmpty(message) ? this.onCreatorSelected.bind(this, socialAccount) : emptyFunc
        }
      >
        {review.approved && (
          <ThumbsUpIcon size={smallerIcon ? 14 : 18} className={styles.feedbackCellIcon} />
        )}
        {!isEmpty(review.comment) && (
          <ChatIcon size={smallerIcon ? 14 : 18} className={styles.feedbackCellIcon} />
        )}
        {!isEmpty(message) && <div className={styles.feedbackMessage}>{message}</div>}
        {isEmpty(message) && (
          <Button
            className={styles.reviewButton}
            label="Review"
            onClick={this.onCreatorSelected.bind(this, socialAccount)}
            round={true}
          />
        )}
      </div>
    );
  };

  private onCreatorSelected = (socialAccount: ISocialAccount) => {
    this.setState({
      selectedSocialAccountId: socialAccount.id,
      showDetailView: true,
    });
  };

  private closeDetailView = () => {
    this.setState({
      showDetailView: false,
    });
  };

  private selectMode = (selectedMode: TMode) => {
    this.setState({
      selectedMode,
    });
  };

  /**
   * @private
   * Updates the feedback of given social account id.
   */
  private updateFeedback = (socialAccountId: number, feedback: string) => {
    const { updateFeedback, socialAccounts } = this.props;
    const toast = this.toastRef.current;

    const socialAccount = socialAccounts.find(
      (socialAccount) => socialAccount.id === socialAccountId,
    );

    return updateFeedback(socialAccountId, feedback)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Updated feedback for creator
                <span> {socialAccount.name || socialAccount.username}</span>.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to update feedback for creator.',
            type: 'error',
          });
        }
      });
  };

  /**
   * @private
   * Updates the feedback of given social account id.
   */
  private updateApproved = (socialAccountId: number, approved: boolean, hideToast?: boolean) => {
    const { updateApproved, socialAccounts } = this.props;
    const toast = this.toastRef.current;

    const socialAccount = socialAccounts.find(
      (socialAccount) => socialAccount.id === socialAccountId,
    );

    return updateApproved(socialAccountId, approved)
      .then(() => {
        if (toast && !hideToast) {
          toast.showMessage({
            content: (
              <div>
                Updated the approval status for creator
                <span> {socialAccount.name || socialAccount.username}</span>.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast && !hideToast) {
          toast.showMessage({
            content: 'There was an error when trying to update the approval status for creator.',
            type: 'error',
          });
        }
      });
  };

  /**
   * @private
   * Set selected social accounts when selected rows changed.
   *
   * @param {ISocialAccount[]} selectedSocialAccounts the selected social accounts.
   */
  private onSelectedRowsChange = (selectedSocialAccounts: ISocialAccount[]) => {
    this.setState({
      selectedSocialAccounts,
    });
  };

  private updatedApprovedMulti = () => {
    const { reviews } = this.props;
    const { selectedSocialAccounts } = this.state;
    const toast = this.toastRef.current;

    const selectedUnapprovedAccounts = selectedSocialAccounts.filter(
      (socialAccount) => !(reviews[socialAccount.id] && reviews[socialAccount.id].approved),
    );
    const promises = selectedUnapprovedAccounts.map((socialAccount) =>
      this.updateApproved(socialAccount.id, true, true),
    );

    this.setState({
      isUpdatingApproved: true,
    });
    Promise.all(promises)
      .then(() => {
        if (toast) {
          toast.showMessage({
            content: (
              <div>
                Updated the approval status for <span>{selectedUnapprovedAccounts.length}</span>{' '}
                creators.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: `There was an error when trying to update the approval status for ${selectedUnapprovedAccounts.length} creators.`,
            type: 'error',
          });
        }
      })
      .finally(() => {
        this.setState({
          isUpdatingApproved: false,
        });
      });
  };
}

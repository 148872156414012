import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const SmileIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    viewBox='0 0 18 18'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='
        M9.00033 0.666504C4.39795 0.666504 0.666992 4.39746 0.666992 8.99984C0.666992 13.6022 4.39795 17.3332 9.00033
        17.3332C13.6027 17.3332 17.3337 13.6022 17.3337 8.99984C17.3337 4.39746 13.6027 0.666504 9.00033
        0.666504ZM4.37117 9.14005C3.98823 9.39534 3.88475 9.91273 4.14005 10.2957C5.39807 12.1827 7.03815 13.1668
        9.00009 13.1668C10.962 13.1668 12.6021 12.1827 13.8601 10.2957C14.1154 9.91273 14.0119 9.39534 13.629
        9.14005C13.2461 8.88475 12.7287 8.98823 12.4734 9.37117C11.5092 10.8175 10.3715 11.5001 9.00009 11.5001C7.6287
        11.5001 6.49099 10.8175 5.5268 9.37117C5.2715 8.98823 4.75411 8.88475 4.37117 9.14005Z
      '
      fill={props.fill}
    />
  </SvgIcon>
));

export { SmileIcon };

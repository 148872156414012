import * as React from 'react';
import { get, debounce } from 'lodash';

import { SearchIcon } from 'src/icons';
import { Input } from 'src/widgets/Input';
import { FilterSection } from '../FilterSection';
import CreatorSkillsetsSection from './CreatorSkillsetsSection';
import EngagementSection from './EngagementSection';
import { engagementRatioLimits } from '../utils/searchParams';
import OtherSection from './OtherSection';
import { NetworkSection } from './NetworkSection';
import CreatorDemographicsSection from './CreatorDemographicsSection';
import AudienceDemographicsSection from './AudienceDemographicsSection';
import {
  ICreatorSearchFilters,
  ICreatorSearchOptions,
  ICreatorSkillsetFilters,
  INetworkFilters,
  IEngagementFilters,
  ICreatorDemographicFilters,
  IAudienceDemographicFilters,
  IOtherFilters,
} from '../models';
import { sanitizeRange } from '../utils/sanitizeRange';

const { useCallback, useState, useEffect } = React;

export interface ICreatorSearchFiltersProps {
  filters: ICreatorSearchFilters;
  options: ICreatorSearchOptions;
  onChange(newFilters: ICreatorSearchFilters);
  className?: string;
  selfServeExperiment: boolean;
}

export const CreatorSearchFilters: React.FunctionComponent<ICreatorSearchFiltersProps> = React.memo((props) => {
  const { className, filters, onChange, options, selfServeExperiment } = props;

  const [query, setQuery] = useState(filters.query || '');
  const { buffer = 500 } = options.query || {};

  const debouncedOnChangeQuery = useCallback(
    debounce((value: string) => {
      onChange({
        ...filters,
        query: value,
      });
    }, buffer),
    [buffer, filters, onChange],
  );

  const handleChangeSearchInput = (value: string) => {
    setQuery(value);
    debouncedOnChangeQuery(value);
  };

  useEffect(() => {
    setQuery(filters.query || '');
  }, [filters.query]);

  const handleChangeSkillsets = (skillsetFilters: ICreatorSkillsetFilters) => {
    const newFilters = {
      ...filters,
      creatorSkillsets: skillsetFilters,
    };
    onChange(newFilters);
  };

  const handleChangeNetwork = (network: INetworkFilters) => {
    let newFilters = {
      ...filters,
      network,
    };

    const newNetwork = get(newFilters.network, 'channel');
    const oldNetwork = get(filters.network, 'channel');

    if (newNetwork !== oldNetwork) {
      const {
        engagement: { reachLimitsByNetwork = {}, engagementLimitsByNetwork = {}, impressionLimitsByNetwork = {} } = {},
      } = options;

      newFilters = {
        ...newFilters,
        engagement: {
          ...newFilters.engagement,
          reachRange: sanitizeRange(reachLimitsByNetwork[newNetwork]),
          engagementRange: sanitizeRange(engagementLimitsByNetwork[newNetwork]),
          impressionRange: sanitizeRange(impressionLimitsByNetwork[newNetwork]),
          engagementRatioRange: sanitizeRange(engagementRatioLimits),
        },
      };
    }

    onChange(newFilters);
  };

  const handleChangeEngagement = (engagement: IEngagementFilters) => {
    const newFilters = {
      ...filters,
      engagement,
    };
    onChange(newFilters);
  };

  const handleChangeCreatorDemographics = (demographics: ICreatorDemographicFilters) => {
    const newFilters = {
      ...filters,
      creatorDemographics: demographics,
    };
    onChange(newFilters);
  };

  const handleChangeAudienceDemographics = (demographics: IAudienceDemographicFilters) => {
    const newFilters = {
      ...filters,
      audienceDemographics: demographics,
    };
    onChange(newFilters);
  };

  const handleChangeOtherFilters = (otherFilters: IOtherFilters) => {
    const newFilters = {
      ...filters,
      other: otherFilters,
    };
    onChange(newFilters);
  };

  return (
    <div className={className}>
      <FilterSection>
        <Input
          placeholder="Search username, keywords & more…"
          {...options.query}
          buffer={undefined}
          value={query}
          onChange={handleChangeSearchInput}
          icon={<SearchIcon />}
        />
      </FilterSection>

      <CreatorSkillsetsSection
        {...filters.creatorSkillsets}
        {...options.creatorSkillsets}
        onChange={handleChangeSkillsets}
      />

      <NetworkSection
        {...filters.network}
        {...options.network}
        onChange={handleChangeNetwork}
      />

      <EngagementSection
        {...options.engagement}
        {...filters.engagement}
        network={get(filters, 'network.channel')}
        onChange={handleChangeEngagement}
      />

      <CreatorDemographicsSection
        {...options.creatorDemographics}
        {...filters.creatorDemographics}
        onChange={handleChangeCreatorDemographics}
      />

      <AudienceDemographicsSection
        {...options.audienceDemographics}
        {...filters.audienceDemographics}
        onChange={handleChangeAudienceDemographics}
      />

      <OtherSection
        {...options.other}
        {...filters.other}
        onChange={handleChangeOtherFilters}
        selfServeExperiment={selfServeExperiment}
      />
    </div>
  );
});

import * as React from 'react';
import { Provider } from 'react-redux';

import { InviteContextProvider } from 'src/widgets/Invite/context/InviteContext';

import { ConnectedSearchPage } from './connected/ConnectedSearchPage';
import { IExternalVariables, IImageSearchUpload, ISearchResultsProps } from './models';
import { configureStore } from './redux/store';
import { fetchFeaturedCreators } from './redux/searchResultsActions';
import { getInitialImageSearchFilters, getInitialTextSearchFilters } from './utils/initialFilters';

const { useMemo, useEffect } = React;

type TEmailType = 'gmail' | 'outlook';

export interface IConnectSearchPageProps extends ISearchResultsProps, IExternalVariables {
  className?: string;
  networkTypes: string[];
  acceptedPlaceIds: any[];
  acceptedCountryCodes: string[];
  acceptedLanguageCodes: string[];
  useImageSearchUpload?: IImageSearchUpload;
  defaultImageSearchUrl?: string;
  onRequestAddEmail?(type: TEmailType);
}

export const ConnectSearchPage: React.FunctionComponent<IConnectSearchPageProps> = (props) => {
  const {
    $state,
    sendBulkOffer,
    exportCsv,
    reportAsIncorrect,
    className,
    onCreatorSelected,
    selectedSocialAccountId,
    networkTypes,
    acceptedPlaceIds,
    acceptedCountryCodes,
    acceptedLanguageCodes,
    useImageSearchUpload,
    ...external
  } = props;

  const store = useMemo(() => {
    const { campaign } = external;
    let options;
    if (campaign) {
      options = {
        networkTypes: campaign.network_types,
        placesIds: campaign.accepted_place_ids,
        acceptedCountryCodes: campaign.accepted_country_codes,
        acceptedLanguageCodes: campaign.accepted_language_codes
      }
    } else {
      options = {
        networkTypes,
        placesIds: acceptedPlaceIds,
        acceptedCountryCodes,
        acceptedLanguageCodes
      }
    }
    const initialTextSearchFilters = getInitialTextSearchFilters(options);
    const initialImageSearchFilters = getInitialImageSearchFilters(options.acceptedCountryCodes);
    initialImageSearchFilters.useImageSearchUpload = useImageSearchUpload;
    return configureStore({
      external,
      textSearch: initialTextSearchFilters,
      imageSearch: initialImageSearchFilters,
    });
  }, [external, acceptedCountryCodes, networkTypes, acceptedPlaceIds, acceptedLanguageCodes, useImageSearchUpload]);

  useEffect(() => {
    store.dispatch(fetchFeaturedCreators());
  }, [store]);

  return (
    <Provider store={store}>
      <InviteContextProvider
        apiEndpoint={props.apiEndpoint}
        userId={props.userId}
        clientId={props.clientId}
        onRequestAddEmail={props.onRequestAddEmail}
      >
        <ConnectedSearchPage
          className={className}
          $state={$state}
          sendBulkOffer={sendBulkOffer}
          exportCsv={exportCsv}
          reportAsIncorrect={reportAsIncorrect}
          onCreatorSelected={onCreatorSelected}
          selectedSocialAccountId={selectedSocialAccountId}
        />
      </InviteContextProvider>
    </Provider>
  );
};

import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ErrorBoundary } from 'src/utils';

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';

import { initialState } from './redux/models';
import reducer from './redux/reducer';
import pageMiddlewares from './redux/middlewares';

import MentionedListPage, { IOwnProps } from './MentionedListPage';

export interface IMentionedListPageWrapperProps extends IOwnProps {
  // api endpoint
  apiEndpoint: string;

  isQa: boolean;
  brand: IBrand;
  org: IOrganization;
  campaign: ICampaign;
}

const { useMemo } = React;

/**
 * @type {React.FunctionComponent}
 */
const MentionedListPageWrapper: React.FunctionComponent<IMentionedListPageWrapperProps> = React.memo((props) => {
  const {
    $state,
    apiEndpoint,
    isQa,
    brand,
    campaign,
    org,
    ...restProps
  } = props;

  const store = useMemo(() => {
    const middlewares = [thunk, ...pageMiddlewares];

    // only use redux-logger in development
    if (process.env.NODE_ENV !== 'production') {
      const createLogger = require('redux-logger').createLogger;

      middlewares.push(createLogger());
    }

    return createStore(
      reducer,
      {
        ...initialState,
        apiEndpoint,
        isQa,
        brand,
        campaign,
        org,
      },
      compose(applyMiddleware(...middlewares)),
    );
  }, [apiEndpoint, isQa, brand, campaign, org]);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <MentionedListPage $state={$state} {...restProps} />
      </ErrorBoundary>
    </Provider>
  );
});

export default MentionedListPageWrapper;

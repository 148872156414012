import * as React from 'react';
import { pick, omit, isUndefined, capitalize } from 'lodash';

import { Checkbox, Toggle } from 'src/widgets/Checkbox';
import { TokenInput } from 'src/widgets/Input';
import { FilterSection } from '../FilterSection';
import ActivitySection from './ActivitySection';

import { IOtherFilters, IOtherOptions } from '../models';

const FILTERS = ['lastPostActivity', 'hideInvited', 'tags', 'canContact'];

const pickFilters = (obj: any): IOtherFilters => pick(obj, FILTERS);

interface IProps extends IOtherFilters, IOtherOptions {
  onChange(otherFilters: IOtherFilters);
  selfServeExperiment: boolean;
}

const OtherSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeTags = (newTags: string[]) => {
    const newFilters = {
      ...pickFilters(props),
      tags: newTags,
    };
    props.onChange(newFilters);
  };

  const handleChangeLastPostActivity = (value: number) => {
    const oldFilters = pickFilters(props);
    const newFilters = isUndefined(value)
      ? omit(oldFilters, 'lastPostActivity')
      : {
        ...oldFilters,
        lastPostActivity: value,
      };
    props.onChange(newFilters);
  };

  const handleChangeHideInvited = (hideInvited: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      hideInvited,
    };
    props.onChange(newFilters);
  };

  const handleChangeCanContact = (canContact: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      canContact,
    };
    props.onChange(newFilters);
  };

  const hide =
    props.hide ||
    (props.hideInvitedToggle && props.hideActivity && props.hideTags && props.hideCanContact);

  return (
    <FilterSection header="Other" hide={hide} collapsible={true} defaultIsOpen={props.isExpanded}>
      {!props.selfServeExperiment &&
        <FilterSection
          header="Hide Invited Creators"
          hide={props.hideInvitedToggle}
          isSubsection={true}
        >
          <Toggle checked={props.hideInvited} onChange={handleChangeHideInvited}/>
        </FilterSection>
      }

      <FilterSection header="Tags" hide={props.hideTags} isSubsection={true}>
        <TokenInput
          tokens={props.tags}
          onChange={handleChangeTags}
          options={props.tagOptions}
          transformInputIntoToken={capitalize}
        />
      </FilterSection>

      <ActivitySection
        lastPostActivity={props.lastPostActivity}
        onChangeLastPostActivity={handleChangeLastPostActivity}
        hide={props.hideActivity}
      />

      <FilterSection header="Can Contact" hide={props.hideCanContact} isSubsection={true}>
        <Checkbox
          checked={props.canContact}
          label="Has contact info"
          onChange={handleChangeCanContact}
        />
      </FilterSection>
    </FilterSection>
  );
};

OtherSection.defaultProps = {
  hideInvited: true,
  canContact: false,
};

export default OtherSection;

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const LayoutCompareIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2.5H15C16.3783 2.5 17.5 3.62167 17.5 5V7.34833V7.65167V15C17.5 16.3783 16.3783 17.5 15 17.5H5C3.62167 17.5 2.5 16.3783 2.5 15V7.65167V7.34833V5C2.5 3.62167
      3.62167 2.5 5 2.5ZM5 4.16667H15C15.4592 4.16667 15.8333 4.54 15.8333 5V6.66667H4.16667V5C4.16667 4.54 4.54083 4.16667 5 4.16667ZM4.16667 8.33333V15C4.16667 15.46
      4.54083 15.8333 5 15.8333H9.16667V8.33333H4.16667ZM10.8333 15.8333H15C15.4592 15.8333 15.8333 15.46 15.8333 15V8.33333H10.8333V15.8333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2.5H15C16.3783 2.5 17.5 3.62167 17.5 5V7.34833V7.65167V15C17.5 16.3783 16.3783 17.5 15 17.5H5C3.62167 17.5 2.5 16.3783 2.5 15V7.65167V7.34833V5C2.5 3.62167
      3.62167 2.5 5 2.5ZM5 4.16667H15C15.4592 4.16667 15.8333 4.54 15.8333 5V6.66667H4.16667V5C4.16667 4.54 4.54083 4.16667 5 4.16667ZM4.16667 8.33333V15C4.16667 15.46
      4.54083 15.8333 5 15.8333H9.16667V8.33333H4.16667ZM10.8333 15.8333H15C15.4592 15.8333 15.8333 15.46 15.8333 15V8.33333H10.8333V15.8333Z"
      fill="none"
    />
  </SvgIcon>
));

export { LayoutCompareIcon };

import * as React from 'react';
import cx from 'classnames';
import { map, some, isEmpty } from 'lodash';

import { IContentReview } from 'src/common/models/contentReview';
import { SubmitButton } from 'src/widgets/Button';
import { Checkbox } from 'src/widgets/Checkbox';
import { Notice } from 'src/widgets/Notice';
import { Textarea } from 'src/widgets/Textarea/Textarea';

import styles from './RequestNewContentModal.scss';

const { useState } = React;

interface IProps {
  contentReview: IContentReview;

  // actions
  rejectContent(guidelineCheckoff: boolean[], comment: string): Promise<void>;
}

/**
 * @type {React.FunctionComponent}
 */
export const RequestNewContentModal: React.FunctionComponent<IProps> = (props) => {
  const { contentReview, rejectContent } = props;

  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectComment, setRejectComment] = useState('');
  const checkoffLength = contentReview.hybrid_rejected_guidelines.length;
  const [rejectedGuidelines, setRejectedGuidelines] = useState(
    new Array(checkoffLength).fill(false),
  );

  const handleCommentChange = (value: string) => {
    setRejectComment(value);
  };

  const handleGuidelineCheckChanged = (index, checked) => {
    setRejectedGuidelines([
      ...rejectedGuidelines.slice(0, index),
      checked,
      ...rejectedGuidelines.slice(index + 1),
    ]);
  };

  const submitRejectContent = async () => {
    if (rejectComment && (isEmpty(rejectedGuidelines) || some(rejectedGuidelines))) {
      setIsRejecting(true);
      try {
        await rejectContent(rejectedGuidelines, rejectComment);
        setIsRejecting(false);
      } catch (err) {
        setIsRejecting(false);
      }
    }
  };

  const renderBrandRejectedGuidelines = () => {
    return map(contentReview.hybrid_rejected_guidelines, (guideline, index) => {
      if (guideline) {
        return (
          <li key={index} className={styles.brandRejectedGuideline}>
            {`${contentReview.product.checkoff_guidelines[index]}`}
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const renderGuidelines = () => {
    return map(contentReview.product.checkoff_guidelines, (guideline, index) => {
      return (
        <div key={index} className={styles.mtGuideline}>
          <div className={styles.guidelineCheckbox}>
            <Checkbox
              className={styles.checkbox}
              onChange={(checked) => handleGuidelineCheckChanged(index, checked)}
            />
          </div>
          <div
            className={cx(styles.guidelineText, {
              [styles.rejected]: contentReview.hybrid_rejected_guidelines[index],
            })}
          >
            {guideline}
          </div>
        </div>
      );
    });
  };

  const brandRejectGuidelines = renderBrandRejectedGuidelines();

  return (
    <div className={styles.RequestNewContentModal}>
      <div className={styles.container}>
        {
          some(brandRejectGuidelines) &&
          <div className={styles.brandInfoContainer}>
            <div className={styles.brandInfoHeader}>
              <strong>Rejection details:</strong>
            </div>
            <Notice type="alert" showDivider={true}>
              <div className={styles.brandInfo}>
                <div className={styles.brandGuidelines}>
                  <div className={styles.brandGuidelinesHeader}>
                    <strong>Guideline(s) not met</strong>
                  </div>
                  <div className={styles.brandGuidelinesContent}>
                    <ul>{brandRejectGuidelines}</ul>
                  </div>
                </div>
                <div className={styles.brandComment}>
                  <div className={styles.brandCommentHeader}>
                    <strong>Comments:</strong>
                  </div>
                  <div className={styles.brandCommentContent}>
                    {contentReview.hybrid_rejected_feedback}
                  </div>
                </div>
              </div>
            </Notice>
          </div>
        }
        <div className={styles.mtActionContainer}>
          <div className={styles.mtGuidelineCheckoff}>
            <div className={styles.mtGuidelineCheckoffHeader}>
              <strong>Select guideline(s) not met:</strong>
            </div>
            <div className={styles.mtGuidelineCheckoffContent}>{renderGuidelines()}</div>
          </div>
          <div className={styles.mtComments}>
            <div className={styles.mtCommentsHeader}>
              <strong>Reason for rejection:</strong>
            </div>
            <div className={styles.mtCommentsContent}>
              <Textarea onChange={handleCommentChange} />
            </div>
          </div>
          <SubmitButton
            isSubmitting={isRejecting}
            submittingLabel="Requesting..."
            label="Request New Content"
            className={styles.mtSubmitButton}
            onClick={submitRejectContent}
          />
        </div>
      </div>
    </div>
  );
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ClockIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,22C6.49,22,2,17.51,2,12S6.49,2,12,2c5.51,0,10,4.49,10,10S17.51,22,12,22z M12,3.28
		c-4.81,0-8.72,3.91-8.72,8.72s3.91,8.72,8.72,8.72s8.72-3.91,8.72-8.72S16.81,3.28,12,3.28z"
    />
    <path
      d="M16.48,17.12c-0.15,0-0.31-0.06-0.43-0.17l-4.88-4.48c-0.13-0.12-0.21-0.29-0.21-0.47V7.52
      c0-0.35,0.29-0.64,0.64-0.64s0.64,0.29,0.64,0.64v4.2L16.91,16c0.26,0.24,0.28,0.64,0.04,0.9C16.82,17.05,16.65,17.12,16.48,17.12z
      "
    />
  </SvgIcon>
));

export { ClockIcon };

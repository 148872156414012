import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ArrowDownFilledIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <g>
      <clipPath id="ARROW_DOWN">
        <path
          d="M0.6,8.9l12,15.9c1,1.3,2.9,1.6,4.2,0.6c0.2-0.2,0.4-0.4,0.6-0.6l12-15.9c1-1.3,0.7-3.2-0.6-4.2
          c-0.5-0.4-1.2-0.6-1.8-0.6H3c-1.7,0-3,1.3-3,3C0,7.7,0.2,8.4,0.6,8.9z"
        />
      </clipPath>
      <rect x="-20" y="-16" clipPath="url(#ARROW_DOWN)" width="70.1" height="61.9" />
    </g>
  </SvgIcon>
));

export { ArrowDownFilledIcon };

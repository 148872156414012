import { assign } from 'lodash';
import { ActionTypes, IMentionedListPage, IMentionedListPageAction } from './models';

const reducer = (
  state: IMentionedListPage,
  action: IMentionedListPageAction,
): IMentionedListPage => {
  switch (action.type) {
    case ActionTypes.SET_SOCIAL_ACCOUNTS: {
      const { socialAccounts, count = 0 } = action.payload;

      return assign({}, state, {
        count,
        socialAccounts,
      });
    }

    default:
      return state;
  }
};

export default reducer;

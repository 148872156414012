import * as React from 'react';
import { isNil } from 'lodash';

import { Cell, ICellProps } from './Cell';

const { useCallback, useMemo } = React;

interface IProps extends ICellProps {
  nullStr?: string;
  trueStr?: string;
  falseStr?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const BooleanCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    config,
    falseStr,
    nullStr,
    trueStr,
    value,
    ...restProps
  } = props;

  const formatValue = useCallback((value: any) => {
    if (isNil(value)) {
      return nullStr;
    }
    return value ? trueStr : falseStr;
  }, [nullStr, trueStr, falseStr]);

  const cellConfig = useMemo(() => {
    const copy = { ...config };
    copy.formatValue = copy.formatValue || formatValue;
    return copy;
  }, [formatValue, config]);

  return (
    <Cell
      {...restProps}
      config={cellConfig}
      value={isNil(value) ? value : !!value}
    />
  );
});

BooleanCell.defaultProps = {
  nullStr: 'No value',
  trueStr: 'True',
  falseStr: 'False',
};

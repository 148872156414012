import * as React from 'react';
import { map, includes, pull } from 'lodash';

import { Checkbox } from 'src/widgets/Checkbox';
import { partitionArrayIntoEqualSubArrays } from 'src/utils/arrays';
import { FilterSection } from '../FilterSection';

import { ICreatorAgeFilters, ICreatorAgeOptions } from '../models';

import styles from './CreatorAgeSection.scss';

export const CREATOR_AGE_FILTERS = ['ageRanges'];

interface IProps extends ICreatorAgeFilters, ICreatorAgeOptions {
  onChange(ageRanges: string[]);
  hide?: boolean;
}

const DEFAULT_CREATOR_AGE_RANGES = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

const CreatorAgeSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeAgeRanges = (ageRange: string, checked: boolean) => {
    const newSelectedAgeRanges = [...props.ageRanges];
    if (checked) {
      newSelectedAgeRanges.push(ageRange);
    } else {
      pull(newSelectedAgeRanges, ageRange);
    }

    props.onChange(newSelectedAgeRanges);
  };

  const columns = partitionArrayIntoEqualSubArrays(props.ageRangeChoices, 2);

  return (
    <FilterSection
      header="Age"
      hide={props.hide}
      classNames={[styles.CreatorAgeSection]}
      isSubsection={true}
    >
      <div className={styles.checkboxes}>
        {map(columns, (column, index) => (
          <div key={index} className={styles.column}>
            {map(column, (ageRange) => (
              <Checkbox
                key={ageRange}
                className={styles.checkbox}
                label={ageRange}
                checked={includes(props.ageRanges, ageRange)}
                onChange={(checked) => handleChangeAgeRanges(ageRange, checked)}
              />
            ))}
          </div>
        ))}
      </div>
    </FilterSection>
  );
};

CreatorAgeSection.defaultProps = {
  ageRanges: [],
  ageRangeChoices: DEFAULT_CREATOR_AGE_RANGES,
};

export default CreatorAgeSection;

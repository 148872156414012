import * as React from 'react';
import { uniqueId } from 'lodash';

import {
  ISvgIconProps,
  SvgIcon,
} from 'src/widgets/Icon';

export const TypingMessageIcon = React.memo((props: ISvgIconProps) => {
  const {
    fill,
    ...restProps
  } = props;

  const circleId = uniqueId('TypingMessageIcon_circle');
  const bubbleId = uniqueId('TypingMessageIcon_bubble');
  const maskId = uniqueId('TypingMessageIcon_mask');
  const dotsId = uniqueId('TypingMessageIcon_dots');

  return (
    <SvgIcon
      viewBox='0 0 16 16'
      {...restProps}
    >
      <g id={circleId}>
        <path
          id={bubbleId}
          fillRule='evenodd'
          clipRule='evenodd'
          d='
            M6.93172 1.41628C9.08439 1.08361 11.1917 1.76294 12.7144 3.28561C14.2377 4.80894 14.9191 6.91628 14.5837
            9.06828C14.1597 11.7863 11.9704 14.0396 9.25906 14.5483C7.95439 14.7923 6.62506 14.6523 5.41572
            14.1409C5.30972 14.0969 5.15039 14.0509 4.98639 14.0823L2.13106 14.6529C2.08772 14.6623 2.04372 14.6663
            2.00039 14.6663C1.82506 14.6663 1.65506 14.5969 1.52906 14.4709C1.37172 14.3136 1.30306 14.0876 1.34706
            13.8689L1.91839 11.0103C1.94439 10.8816 1.92439 10.7376 1.85906 10.5843C1.34772 9.37561 1.20706 8.04628
            1.45172 6.74094C1.96039 4.02961 4.21372 1.84028 6.93172 1.41628ZM5.33359 8.66633C4.96559 8.66633 4.66693
            8.36766 4.66693 7.99966C4.66693 7.63166 4.96559 7.33299 5.33359 7.33299C5.70159 7.33299 6.00026 7.63166
            6.00026 7.99966C6.00026 8.36766 5.70159 8.66633 5.33359 8.66633ZM7.33359 7.99966C7.33359 8.36766 7.63226
            8.66633 8.00026 8.66633C8.36826 8.66633 8.66693 8.36766 8.66693 7.99966C8.66693 7.63166 8.36826 7.33299
            8.00026 7.33299C7.63226 7.33299 7.33359 7.63166 7.33359 7.99966ZM10.0003 7.99966C10.0003 8.36766 10.2989
            8.66633 10.6669 8.66633C11.0349 8.66633 11.3336 8.36766 11.3336 7.99966C11.3336 7.63166 11.0349 7.33299
            10.6669 7.33299C10.2989 7.33299 10.0003 7.63166 10.0003 7.99966Z
          '
          fill={fill}
        />
        <mask
          id={maskId}
          mask-type='alpha'
          maskUnits='userSpaceOnUse'
          x='1'
          y='1'
          width='14'
          height='14'
        >
          <path
            id={dotsId}
            fillRule='evenodd'
            clipRule='evenodd'
            d='
              M6.93172 1.41628C9.08439 1.08361 11.1917 1.76294 12.7144 3.28561C14.2377 4.80894 14.9191 6.91628 14.5837
              9.06828C14.1597 11.7863 11.9704 14.0396 9.25906 14.5483C7.95439 14.7923 6.62506 14.6523 5.41572
              14.1409C5.30972 14.0969 5.15039 14.0509 4.98639 14.0823L2.13106 14.6529C2.08772 14.6623 2.04372 14.6663
              2.00039 14.6663C1.82506 14.6663 1.65506 14.5969 1.52906 14.4709C1.37172 14.3136 1.30306 14.0876 1.34706
              13.8689L1.91839 11.0103C1.94439 10.8816 1.92439 10.7376 1.85906 10.5843C1.34772 9.37561 1.20706 8.04628
              1.45172 6.74094C1.96039 4.02961 4.21372 1.84028 6.93172 1.41628ZM5.33359 8.66633C4.96559 8.66633 4.66693
              8.36766 4.66693 7.99966C4.66693 7.63166 4.96559 7.33299 5.33359 7.33299C5.70159 7.33299 6.00026 7.63166
              6.00026 7.99966C6.00026 8.36766 5.70159 8.66633 5.33359 8.66633ZM7.33359 7.99966C7.33359 8.36766 7.63226
              8.66633 8.00026 8.66633C8.36826 8.66633 8.66693 8.36766 8.66693 7.99966C8.66693 7.63166 8.36826 7.33299
              8.00026 7.33299C7.63226 7.33299 7.33359 7.63166 7.33359 7.99966ZM10.0003 7.99966C10.0003 8.36766 10.2989
              8.66633 10.6669 8.66633C11.0349 8.66633 11.3336 8.36766 11.3336 7.99966C11.3336 7.63166 11.0349 7.33299
              10.6669 7.33299C10.2989 7.33299 10.0003 7.63166 10.0003 7.99966Z
            '
            fill='white'
          />
        </mask>
        <g mask={`url(#${maskId})`} />
      </g>
    </SvgIcon>
  );
});

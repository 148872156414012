import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const ExternalLinkIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.2,13.7c-0.4,0-0.8,0.3-0.8,0.8v4.9c0,0.6-0.5,1.1-1.1,1.1H4.6c-0.6,0-1.1-0.5-1.1-1.1V4.6C3.5,4,4,3.5,4.6,3.5h4.8
      c0.4,0,0.8-0.3,0.8-0.8S9.9,2,9.5,2H4.6C3.2,2,2,3.2,2,4.6v14.8C2,20.8,3.2,22,4.6,22h14.8c1.4,0,2.6-1.2,2.6-2.6v-4.9
      C22,14,21.7,13.7,21.2,13.7z"
    />
    <path
      d="M21.9,2.4C21.7,2.2,21.5,2,21.2,2h-5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h3.2L12,11c-0.3,0.3-0.3,0.8,0,1.1
      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l7.4-7.4v3.3c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-5
      C22,2.7,21.9,2.5,21.9,2.4z"
    />
  </SvgIcon>
));

export { ExternalLinkIcon };

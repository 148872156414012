import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ErrorBoundary } from 'src/utils';

import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { initialState } from './redux/favoriteListPageModel';
import favoriteListPageReducer from './redux/favoriteListPageReducer';

import FavoriteListPage, { IOwnProps } from './FavoriteListPage';

interface IProps extends IOwnProps {
  // api endpoint
  apiEndpoint: string;

  isQa: boolean;
  brandId: number;
  managerId: number;
  org: IOrganization;
  campaign: ICampaign;
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export default class FavoriteListPageWrapper extends React.Component<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private favoriteListPageStore;

  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    // check ids
    if (!props.brandId) {
      throw new Error('Brand id is needed.');
    }
    if (!props.campaign) {
      throw new Error('Campaign is needed.');
    }

    // check endpoint
    if (!props.apiEndpoint) {
      throw new Error('API endpoint is needed.');
    }
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillMount() {
    const { brandId, managerId, campaign, org, isQa, apiEndpoint } = this.props;
    const middleWares = [thunk];

    // only use redux-logger in development
    if (process.env.NODE_ENV !== 'production') {
      const createLogger = require('redux-logger').createLogger;

      middleWares.push(createLogger());
    }

    this.favoriteListPageStore = createStore(
      favoriteListPageReducer,
      {
        ...initialState,
        brandId,
        managerId,
        campaign,
        org,
        isQa,
        apiEndpoint,
      },
      compose(applyMiddleware(...middleWares)),
    );
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      sendOffer,
      goToManage,
      goToSearch,
      initialListId,
      allowSharing,
      sendBulkOffer,
      exportCsv,
      uploadCsv,
      reportAsIncorrect,
      classNames,
    } = this.props;

    return (
      <Provider store={this.favoriteListPageStore}>
        <ErrorBoundary>
          <FavoriteListPage
            initialListId={initialListId}
            allowSharing={allowSharing}
            sendOffer={sendOffer}
            sendBulkOffer={sendBulkOffer}
            goToManage={goToManage}
            goToSearch={goToSearch}
            exportCsv={exportCsv}
            uploadCsv={uploadCsv}
            reportAsIncorrect={reportAsIncorrect}
            classNames={classNames}
          />
        </ErrorBoundary>
      </Provider>
    );
  }
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const InfoIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M21.735 11.698c.095 5.45-4.416 9.946-9.867 10.041-5.449.094-9.772-4.247-9.867-9.697-.095-5.449 4.418-9.946 9.867-10.04 5.45-.096 9.773 4.248 9.867 9.696z" />
    <path
      fill="#FFF"
      d="M11.729 8.726a1.21 1.21 0 0 1-1.2-1.2c0-.663.553-1.2 1.2-1.2a1.2 1.2 0 1 1 0 2.4zm-.995 8.748V9.847h2.005v7.627h-2.005z"
    />
  </SvgIcon>
));

export { InfoIcon };

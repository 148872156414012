import { isEmpty, last, set, size } from 'lodash';

import { Action, initialState, IState, TAction } from '../models';

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {

    case Action.HISTORY_CLEAR:
      return {
        ...state,
        history: [],
      };

    case Action.HISTORY_POP:
      return {
        ...state,
        history: [...state.history.slice(0, history.length - 1)],
      };

    case Action.HISTORY_PUSH:
      // Will not push if latest item has the same id
      if (
        !isEmpty(action.socialAccount) &&
        (size(state.history) === 0 || last(state.history).id !== action.socialAccount.id)
      ) {
        set(state, 'history', [...state.history, action.socialAccount])
      }
      return state;

    case Action.RESET:
      return initialState;

    case Action.SET_ACCOUNT:
      return {
        ...initialState,
        history: state.history,
        socialAccount: action.socialAccount,
      };

    case Action.SET_DETAILED_ACCOUNT:
      if (isEmpty(state.socialAccount)) {
        set(state, 'socialAccount', action.socialAccount);
      }
      if (state.socialAccount.id === action.socialAccount.id) {
        set(state, 'detailedSocialAccount', action.socialAccount);
      }
      return {
        ...state,
        fetchingDetailForId: null,
      };

    case Action.SET_FETCHING_FOR_ID:
      if (!isEmpty(state.socialAccount) && action.id && state.socialAccount.id !== action.id) {
        set(state, 'socialAccount', null);
        set(state, 'detailedSocialAccount', null);
      }
      return {
        ...state,
        fetchingDetailForId: action.id,
      };

    case Action.SET_INVITING:
      return {
        ...state,
        isInviting: action.isInviting,
      };

    case Action.SET_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };

  }
  throw new Error('Unhandled action: ' + action);
};

import * as React from 'react';
import cx from 'classnames';
import { concat, keys, map } from 'lodash';

import { UploadCloudIcon } from 'src/icons';
import { Tooltip } from 'src/widgets/Tooltip';

import { TContentType } from './redux/contentUploaderModel';
import { RAW_IMAGE_EXTENSIONS } from 'src/common/config/fileExtensions';

import styles from './FileSelector.scss';
const { useCallback, useImperativeHandle, useMemo, useRef } = React;

export interface IFileSelectorProps {
  accept?: string;
  acceptTypes?: TContentType[];
  className?: string;
  disabled?: boolean;
  fileSelectorRef?: React.RefObject<IFileSelectorRefs>;
  inputRef?: React.RefObject<HTMLInputElement>;
  multiple?: boolean;
  onFilesSelected(files: FileList);
  unstyled?: boolean;
}

export interface IFileSelectorRefs {
  openFileSelector();
}

type TProps = React.PropsWithChildren<IFileSelectorProps>;

export const FileSelector = React.forwardRef<HTMLDivElement, TProps>((props, forwardedRef) => {
  const {
    accept,
    acceptTypes = [],
    children,
    className,
    disabled,
    fileSelectorRef,
    inputRef: inputRefProp,
    multiple = false,
    onFilesSelected,
    unstyled = false,
  } = props;

  const ref = useRef<HTMLDivElement>();
  useImperativeHandle(forwardedRef, () => ref.current);

  const inputRef = useRef<HTMLInputElement>();
  useImperativeHandle(inputRefProp, () => inputRef.current);

  useImperativeHandle(fileSelectorRef, () => ({
    openFileSelector,
  }));

  const acceptString = useMemo(() => concat(
    accept,
    // also adds raw image extensions if image is accepted
    ...(acceptTypes.includes('image')
      ? map(keys(RAW_IMAGE_EXTENSIONS), (ext) => `.${ext}`)
      : []),
    ...acceptTypes.map((acceptType) => `${acceptType}/*`),
  ).join(', '), [accept, acceptTypes]);

  const openFileSelector = useCallback(() => {
    const fileInput = inputRef.current;

    // do not open file selector if disabled
    if (disabled) {
      return;
    }

    // trigger a click
    if (fileInput) {
      fileInput.click();
    }
  }, [disabled]);

  const handleSelectedFilesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    onFilesSelected(files);
    event.target.value = '';
  }, [onFilesSelected]);

  return <>
    <div
      ref={ref}
      className={cx(
        className,
        {
          [styles.disabled]: disabled,
          [styles.ContentUploaderFileSelector]: !unstyled,
        },
      )}
      onClick={openFileSelector}
    >
      <input
        type="file"
        ref={inputRef}
        className={styles.hideInput}
        onChange={handleSelectedFilesChange}
        multiple={multiple}
        accept={acceptString}
      />
      {children || (
        !unstyled && (
          <div className={styles.label}>
            <UploadCloudIcon />
            <div className={styles.text}>
              <div>Upload a File</div>
              <div className={styles.supportedFile}>(Drag and Drop)</div>
            </div>
          </div>
        )
      )}
    </div>
    {disabled && (
      <Tooltip placement="top" mountRef={ref}>
        Maximum number of items reached.
      </Tooltip>
    )}
  </>;
});

import { split, last } from 'lodash';
/**
 * Gets the file extension from given filename.
 *
 * @param {String} filename the filename.
 *
 * @return {String}
 */
const getFileExtension = (filename: string): string => {
  if (!filename.includes('.')) {
    return null;
  }
  return last(split(filename, '.'));
};

export default getFileExtension;

export const tooltipText = Object.freeze({
  ambassador: 'AspireIQ Ambassador verified by our community team',
  audienceAuthenticity: `Audience Authenticity is based on multiple factors, such as the follower account's avatar and
  bio description, number of posts, number of account's follower vs. following ratio, and number of likes received vs.
  number of likes given ratio`,
  audienceAuthenticityReviewed: 'Manually reviewed by our support team based on our creator policy',
  collabsCompleted: 'Number of completed collaborations on AspireIQ',
  collabsRating: 'Average collaboration rating of previous collaborations',
  commentSentiment: 'The overall sentiment of the comments analyzed by the natural language processing',
  engagementRateComparison: 'Engagement Rate = engagements per post divided by number of followers',
  postImpressionRatio: 'Average impression per post divided by number of followers',
  sponsoredPosts: 'Posts with hashtags for sponsorship (e.g., #ads)',
  storyImpressionRatio: 'Average impression per story divided by number of followers',
});

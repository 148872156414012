import * as React from 'react';
import { isNaN } from 'lodash';

import { Input } from './Input';

import styles from './NumberEditInput.scss';

const { useCallback, useState } = React;

export interface IProps {
  defaultValue: number;
  onBlur?(value: number);
}

export const NumberEditInput: React.FC<IProps> = (props) => {
  const { onBlur, defaultValue, ...restProps } = props;
  const [value, setValue] = useState<string>(String(defaultValue));

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, [setValue]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      const newValue = !isNaN(parseFloat(value)) ? value : undefined;
      onBlur(Number(newValue));
    }
  }, [onBlur, value]);

  return (
    <Input
      {...restProps}
      className={styles.input}
      type="number"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

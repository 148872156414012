import * as React from 'react';
import cx from 'classnames';

import { ImageIcon, TextIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Tabs, Tab } from 'src/widgets/Tabs';
import { InviteAddressPicker } from 'src/widgets/Invite/AddressPicker';

import { ISearchResultsProps } from './models';
import { ConnectedTextSearch } from './connected/ConnectedTextSearch';
import { ConnectedImageSearch } from './connected/ConnectedImageSearch';
import { ConnectedSearchResults } from './connected/ConnectedSearchResults';
import { ConnectedSavedSearches } from './connected/ConnectedSavedSearches';

import styles from './SearchPage.scss';

const { useCallback } = React;

export interface ISearchPageProps extends ISearchResultsProps {
  isImageSearchSelected?: boolean;
  imageSearchEnabled?: boolean;
  showCreateFeatures?: boolean;

  onSelectTextSearch();
  onSelectImageSearch();
  onClickApplySearch();

  className?: string;
}

const ImageTabTitle = React.memo(() => (
  <div className={styles.tabTitle}>
    <ImageIcon size={18} />
    <span>Image Search</span>
  </div>
));

const TextTabTitle = React.memo(() => (
  <div className={styles.tabTitle}>
    <TextIcon size={22} />
    <span>Text Search</span>
  </div>
));

export const SearchPage: React.FunctionComponent<ISearchPageProps> = React.memo((props) => {
  const {
    onSelectImageSearch,
    onSelectTextSearch,
  } = props;

  const handleChangeTab = useCallback((index: number) => {
    switch (index) {
      case 0:
        onSelectTextSearch();
        break;
      case 1:
        onSelectImageSearch();
        break;
      default:
        break;
    }
  }, [onSelectImageSearch, onSelectTextSearch]);

  return (
    <div className={cx(styles.SearchPage, props.className)}>
      <div className={styles.filters}>
        {!props.showCreateFeatures && (
          <InviteAddressPicker className={styles.inviteAddressPicker} />
        )}
        {props.imageSearchEnabled ? (
          <>
            <ConnectedSavedSearches />
            <Tabs onChangeTab={handleChangeTab}>
              <Tab title={<TextTabTitle />} selected={!props.isImageSearchSelected}>
                <ConnectedTextSearch />
              </Tab>
              <Tab title={<ImageTabTitle />} selected={props.isImageSearchSelected}>
                <ConnectedImageSearch />
              </Tab>
            </Tabs>
          </>
        ) : <ConnectedTextSearch />}
        <div className={styles.floatingButton}>
          <Button
            label="Apply Search & Filters"
            fullWidth={true}
            onClick={props.onClickApplySearch}
          />
        </div>
      </div>
      <div className={styles.results}>
        <ConnectedSearchResults
          $state={props.$state}
          sendBulkOffer={props.sendBulkOffer}
          exportCsv={props.exportCsv}
          reportAsIncorrect={props.reportAsIncorrect}
          onCreatorSelected={props.onCreatorSelected}
          selectedSocialAccountId={props.selectedSocialAccountId}
          showCreateFeatures={props.showCreateFeatures}
        />
      </div>
    </div>
  );
});

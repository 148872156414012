import * as React from 'react';
import cx from 'classnames';
import memoize from 'memoize-one';
import {
  assign,
  each,
  find,
  get,
  includes,
  isEmpty,
  isFunction,
  isNumber,
  isUndefined,
  keys,
  map,
  pick,
  pull,
  toLower,
  values,
} from 'lodash';

import { SearchIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { Input } from 'src/widgets/Input';
import { Notice } from 'src/widgets/Notice';
import { InfiniteList } from 'src/widgets/InfiniteList';

import { TablePagination } from './Pagination';
import { ColumnConfig } from './ColumnConfig';
import { HeaderRow } from './HeaderRow';
import { BodyRow } from './BodyRow';
import { ScrollableMask } from './ScrollableMask';
export { EditInput as TableCellEditInput } from './Cell/Editable';

import { SortedDataList, SortTypes, TSortType } from './utils/SortedDataList';
import {
  TableContextProvider,
  ITableCellType,
  ITableConfig,
  defaultTableConfig,
  ITableColumnConfig,
  IRowData,
  IShowColumns,
  IColumnSortDir,
  INetworkCellConfig,
  ISelectableCellConfig,
} from './tableContext';
import { ExportToCsv } from 'export-to-csv';

import { useScrollableStatus, useHover } from 'src/utils/hooks';

const {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  useMemo,
} = React;

import styles from './Table.scss';

const cellValueCompareFn = (
  cellType: ITableCellType,
  valueA: any,
  valueB: any
) => {
  let sortableValueA = valueA;
  let sortableValueB = valueB;

  if (cellType === 'network') {
    sortableValueA = map(
      valueA as INetworkCellConfig[],
      network => network.type
    )
      .sort()
      .join(',');
    sortableValueB = map(
      valueB as INetworkCellConfig[],
      network => network.type
    )
      .sort()
      .join(',');
  } else if (cellType === 'selectable') {
    sortableValueA = (valueA as ISelectableCellConfig).value;
    sortableValueB = (valueB as ISelectableCellConfig).value;
  }

  let order = 0;
  if (sortableValueA > sortableValueB) {
    order = 1;
  } else if (sortableValueA < sortableValueB) {
    order = -1;
  }

  return order;
};

/**
 * Extracts the "sortable" value for a given row and column.
 * Checks for any explicit "sortableField" property, falling back to using the value
 * directly in the case where no sortableField is specified.
 * @param data IRowData[] The data frame.
 * @param index number The row index.
 * @param key string The column key.
 * @return Returns the sortable value for the given row and column.
 */
const extractSortableValue = (data: IRowData[], index: number, key: string): any => (
  get(data, [index, '_sortableFields', key], get(data, [index, key], ''))
);

/**
 * @private
 * Creates a new SortedDataList based on current state.
 *
 * @return {SortedDataList}
 */
const createSortedDataList = memoize(
  (
    data: IRowData[],
    columns: ITableColumnConfig[],
    colSortDirs: IColumnSortDir,
    showColumns: IShowColumns,
    filter: string
  ): SortedDataList<IRowData> => {
    const columnKeys = keys(showColumns);

    // contruct the index map by applying the filter
    const indexMap = [];
    each(data, (d, index) => {
      let foundMatch = false;

      // apply filter
      each(columnKeys, columnKey => {
        // skips the hidden columns or if match has been found
        if (!showColumns[columnKey] || foundMatch) {
          return;
        }

        const value = d[columnKey];

        if (includes(toLower(value), toLower(filter))) {
          foundMatch = true;
        }
      });

      if (foundMatch) {
        indexMap.push(index);
      }
    });

    // if no sort
    if (isEmpty(colSortDirs)) {
      return new SortedDataList<IRowData>(indexMap, data);
    }

    // use first key/value as sort direction for now
    const columnKey = keys(colSortDirs)[0];
    const sortDir = values(colSortDirs)[0];
    const column = find(columns, column => column.field === columnKey);

    if (column) {
      const { cellType } = column;

      indexMap.sort((indexA, indexB) => {
        // get data values
        const valueA = extractSortableValue(data, indexA, columnKey);
        const valueB = extractSortableValue(data, indexB, columnKey);
        // sort value
        let order = cellValueCompareFn(cellType, valueA, valueB);
        // revert the direction if sort descending
        if (sortDir === SortTypes.DESC) {
          order = order * -1;
        }

        return order;
      });
    }

    return new SortedDataList<IRowData>(indexMap, data);
  }
);

interface IProps {
  disabled?: boolean;
  emptyMessage?: string;
  className?: string;

  // data
  data: IRowData[];
  columns: ITableColumnConfig[];
  rowDisplayName?: string;

  // callback on click
  onRowClicked?(rowData: IRowData, metaKey: boolean): void;

  // table body related config
  config?: ITableConfig;
  headerActions?: JSX.Element;
  tableHeader?: JSX.Element;
  onSelectedDataChange?(selectedData: any[]): void;

  // whether to leave some empty space when calculating ideal table height
  paddingBottom?: number;
  onReachedBottom?(): void;

  // for tables that are paged and sorted by remote query
  page?: number;
  onPageChangeCallback?(page: number): void;
  onSortDirChangeCallback?(columnField: string, sortDir: TSortType): void;
  totalRowCount?: number;
  isDataForCurrentPage?: boolean;

  // whether or not to display the export button
  exportable?: boolean;
  // function to call after exporting
  onExportCallback?(exportOptions: Record<string, any>): void;

  // editable cells onChange callback
  onChangeCellValue?(rowId: string, field: string, value: any);

  // column reordering
  onColumnReorder?: (
    orderedColumns: ITableColumnConfig[],
    selectedColumn: ITableColumnConfig,
    destinationIndex: number,
  ) => void;

  // column resizing
  onColumnResize?: (columnWidths: number[]) => void;
}

export interface ITableRefHandles {
  unsetSelectedRows(): void;
}

/**
 * @type {React.RefForwardingComponent}
 */
const TableComponent: React.RefForwardingComponent<ITableRefHandles, IProps> = (
  props,
  forwardedRef
) => {
  const {
    className,
    columns,
    config: initialConfig,
    data,
    disabled = false,
    emptyMessage = 'There are no records.',
    exportable = false,
    onChangeCellValue,
    onColumnReorder,
    onColumnResize,
    onExportCallback,
    onPageChangeCallback,
    onReachedBottom,
    onSelectedDataChange,
    onSortDirChangeCallback,
    paddingBottom = 140,
    rowDisplayName,
    totalRowCount,
  } = props;
  const config = assign({}, defaultTableConfig, initialConfig);

  const [localPage, setLocalPage] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const controlledPage = !isUndefined(props.page);
  const page = controlledPage ? props.page : localPage;

  // used for calculating ideal table height
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(0);

  // used for checking if table body has scrolled to bottom
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const [tableBodyHovered, listeners] = useHover();
  const { canScrollDown } = useScrollableStatus(tableBodyRef);

  useLayoutEffect(() => {
    if (config.scrollable) {
      const node = ref.current;
      const { top } = node.getBoundingClientRect();
      const idealHeight = window.innerHeight - top - paddingBottom;

      setTableHeight(idealHeight);
    }
  }, [config.scrollable, paddingBottom]);

  // notify parent if table body has reached bottom
  useEffect(() => {
    if (canScrollDown === false && isFunction(onReachedBottom)) {
      onReachedBottom();
    }
  }, [canScrollDown, onReachedBottom]);

  // sorting and filtering
  const [colSortDirs, setColSortDirs] = useState<IColumnSortDir>({});

  const defaultShowColumns = useMemo<IShowColumns>(() => {
    const cols = {};
    each(columns, column => {
      cols[column.field] = true;
    });
    return cols;
  }, [columns]);

  const [showColumnsState, setShowColumnsState] = useState<IShowColumns>({});
  const [filter, setFilter] = useState<string>('');

  const showColumns = useMemo(() => {
    return assign({}, defaultShowColumns, showColumnsState);
  }, [defaultShowColumns, showColumnsState]);

  // used for manually scrolling horizontally
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const scrollLeftRef = useRef<number>(scrollLeft);
  scrollLeftRef.current = scrollLeft;
  const headerRowRef = useRef<HTMLDivElement>(null);
  const checkboxRef = useRef<HTMLDivElement>(null);
  const cellContainerRef = useRef<HTMLDivElement>(null);

  const { innerWidth } = window;
  useLayoutEffect(() => {
    const headerRow = headerRowRef.current;
    const checkbox = checkboxRef.current;
    const cellContainer = cellContainerRef.current;

    if (!headerRow) {
      return;
    }

    // checkbox could be hidden
    const checkboxWidth = (checkbox && checkbox.clientWidth) || 0;

    const containerWidth = headerRow.clientWidth - checkboxWidth;
    setContainerWidth(containerWidth);

    const maxScroll = cellContainer.clientWidth - containerWidth;

    if (scrollLeftRef.current > maxScroll) {
      setScrollLeft(maxScroll);
    }

    setMaxScrollLeft(maxScroll);
  }, [innerWidth, data, headerRowRef.current, showColumns, scrollLeftRef]);

  // create sorted data list based on current state
  const sortedDataList = createSortedDataList(
    data,
    columns,
    colSortDirs,
    showColumns,
    filter
  );

  // selected rows, only works when selectable is true
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useImperativeHandle(forwardedRef, () => ({
    unsetSelectedRows: () => {
      setSelectedIds([]);

      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange([]);
      }
    },
  }));

  /**
   * Toggles check all/none.
   */
  const toggleAllSelected = useCallback(() => {
    let newSelectedIds: string[];
    if (selectedIds.length === sortedDataList.getSize()) {
      newSelectedIds = [];
    } else {
      newSelectedIds = sortedDataList.getAllIds();
    }

    if (isFunction(onSelectedDataChange)) {
      onSelectedDataChange(sortedDataList.getSelectedDataByIds(newSelectedIds));
    }

    setSelectedIds(newSelectedIds);
  }, [selectedIds, sortedDataList, onSelectedDataChange]);

  /**
   * Highlight/Unhighlight a row when selected.
   */
  const toggleRowSelected = useCallback(
    (id: string) => {
      if (selectedIds.includes(id)) {
        pull(selectedIds, id);
      } else {
        selectedIds.push(id);
      }

      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange(sortedDataList.getSelectedDataByIds(selectedIds));
      }

      setSelectedIds([...selectedIds]);
    },
    [selectedIds, onSelectedDataChange, sortedDataList]
  );

  /**
   * Set the page when user clicks on paging control
   */
  const setPage = useCallback(
    (page: number) => {
      if (onPageChangeCallback) {
        onPageChangeCallback(page);
      }

      if (!controlledPage) {
        setLocalPage(page);
      }

      tableBodyRef.current.scrollTop = 0;
    },
    [onPageChangeCallback, controlledPage]
  );

  /**
   * Sorts by selected column.
   *
   * @param {String} columnField the selected column field.
   * @param {TSortType} sortDir sort direction.
   */
  const onSortDirChange = useCallback(
    (columnField: string, sortDir: TSortType) => {
      if (onSortDirChangeCallback) {
        onSortDirChangeCallback(columnField, sortDir);
      }

      // for now, only support sort by one column
      const newColSortDirs = {};
      if (sortDir) {
        newColSortDirs[columnField] = sortDir;
      }

      setColSortDirs(newColSortDirs);
    },
    [onSortDirChangeCallback]
  );

  const onShowColumnsChange = useCallback(
    (showColumns: IShowColumns) => setShowColumnsState(showColumns),
    []
  );

  // because of useLayoutEffect
  if (typeof window === 'undefined') {
    return null;
  }

  const tableData =
    isNumber(config.pageSize) && !props.isDataForCurrentPage
      ? sortedDataList.getObjects(page * config.pageSize, config.pageSize)
      : sortedDataList.getObjects();

  return (
    <div
      ref={ref}
      className={cx(styles.Table, className, {
        [styles.scrollable]: config.scrollable,
      })}
      style={{
        maxHeight: config.scrollable ? `${tableHeight}px` : undefined,
      }}
    >
      <TableContextProvider
        config={config}
        columns={columns}
        showColumns={showColumns}
        onChangeCellValue={onChangeCellValue}
        editing={isEditing}
        updateIsEditing={setIsEditing}
      >
        {renderTableHeader()}
        {sortedDataList.getSize() === 0 && (
          <Notice type="disabled" className={styles.noResultNotice}>
            {isEmpty(filter)
              ? emptyMessage
              : `There are no records that match the applied filter "${filter}"`}
          </Notice>
        )}
        {sortedDataList.getSize() > 0 && (
          <>
            <HeaderRow
              cellContainerRef={cellContainerRef}
              checkboxRef={checkboxRef}
              colSortDirs={colSortDirs}
              onSortDirChange={onSortDirChange}
              scrollLeft={scrollLeft}
              selectedIds={selectedIds}
              sortedDataList={sortedDataList}
              toggleAllSelected={toggleAllSelected}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
              ref={headerRowRef}
            />
            <InfiniteList
              ref={tableBodyRef}
              className={styles.tableBody}
              {...listeners}
            >
              {map(tableData, (rowData, index) => (
                <BodyRow
                  // use index for key
                  // intersectionRatio is 0 if use rowData.id for key when sorting
                  key={index}
                  scrollLeft={scrollLeft}
                  selected={selectedIds.includes(rowData.id)}
                  rowData={rowData}
                  onRowClicked={props.onRowClicked}
                  toggleRowSelected={toggleRowSelected}
                  className={cx(
                    styles.bodyRow,
                    {
                      [styles.odd]: config.striped && index % 2 === 0,
                      [styles.border]: config.rowBorder,
                      [styles.clickable]: true,
                      [styles.highlightOnHover]: config.highlightOnHover,
                    },
                    rowData._bodyRowClassName
                  )}
                />
              ))}
            </InfiniteList>
            {config.scrollable && (
              <ScrollableMask
                scrollLeft={scrollLeft}
                maxScrollLeft={maxScrollLeft}
                containerWidth={containerWidth}
                shouldCaptureWheel={tableBodyHovered}
                onScrollLeftChange={setScrollLeft}
              />
            )}
          </>
        )}
      </TableContextProvider>
      {disabled && <div className={styles.mask} />}
    </div>
  );

  /**
   * Renders the table mask, contains scroll left/right action buttons.
   *
   * @return {JSX.Element}
   */
  function renderTableHeader(): JSX.Element {
    const { headerActions, tableHeader } = props;

    const shouldRenderPagination =
      isNumber(config.pageSize) && sortedDataList.getSize() > 0;

    const shouldRenderTableHeader =
      tableHeader ||
      headerActions ||
      config.allowSearch ||
      config.configurableColumns ||
      shouldRenderPagination;

    if (!shouldRenderTableHeader) {
      return null;
    }

    const headersToExport = columns.filter(column => showColumns[column.field]);

    const tableDataToExport = sortedDataList.getObjects().map(rowData => {
      const row = pick(
        rowData,
        headersToExport.map(header => header.field)
      );
      return map(row, item => (item ? item : ''));
    });

    const options = {
      headers: headersToExport.map(header => header.headerName),
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Export',
    };

    const csvExporter = new ExportToCsv(options);

    const onClickExport = () => {
      csvExporter.generateCsv(tableDataToExport);
      if (onExportCallback) {
        onExportCallback(options);
      }
    }

    return (
      <>
        <div className={styles.tableHeader}>
          {headerActions && (
            <div className={styles.headerActions}>{headerActions}</div>
          )}
          <div className={styles.right}>
            {config.allowSearch && (
              <Input
                icon={<SearchIcon size={15} />}
                placeholder="Search..."
                onChange={setFilter}
                theme="info"
                className={styles.searchInput}
              />
            )}
            {exportable && (
              <Button
                onClick={() => onClickExport()}
                label="Export"
                theme="info"
              />
            )}
            {shouldRenderPagination && (
              <TablePagination
                total={totalRowCount || sortedDataList.getSize()}
                page={page}
                onPageChange={setPage}
                className={styles.pagination}
                rowDisplayName={rowDisplayName}
              />
            )}
            {config.configurableColumns && (
              <ColumnConfig
                className={styles.columnConfig}
                onChange={onShowColumnsChange}
              />
            )}
          </div>
        </div>
        {tableHeader}
      </>
    );
  }
};

export const Table = React.forwardRef(TableComponent);

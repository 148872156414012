import * as React from 'react';
import cx from 'classnames';
import { defer, forEach, uniqueId } from 'lodash';

import { LinearGradient } from '@vx/gradient';
import { Group } from '@vx/group';
import { ParentSize } from '@vx/responsive';
import { scaleBand, scaleLinear } from '@vx/scale';


const { useCallback, useRef, useState } = React;
import styles from './ThickBarChart.scss';
const {
  barInfoMargin,
  borderRadius,
} = styles;

// TODO: unify models for charts
interface IData {
  /**
   * Data value
   */
  value: number;
  /**
   * Simple plaintext label
   */
  label: string;
  /**
   * Custom JSX.Element label
   */
  customLabel?: React.ReactNode;
}

type TThickBarChartType = 'number' | 'percentage' | 'string';

interface IThickBarChartProps {
  className?: string;
  data: IData[];
  height?: number | string;
  valueType?: TThickBarChartType;
  sortBy?: false | TThickBarChartType;
  margins?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

// TODO: Sortable data
export const ThickBarChart: React.FunctionComponent<IThickBarChartProps> = (props) => {
  const {
    data,
    className,
    height: heightProp = '100%',
    margins: marginsProp,
  } = props;
  const margins = {
    top: 64,
    bottom: 16,
    left: 0,
    right: 0,
    ...marginsProp,
  };

  const [gradientId] = useState<string>(uniqueId('aspire-gradient-'));
  const labelRefs = useRef<React.RefObject<SVGForeignObjectElement>[]>(null);

  const renderGraph = useCallback(({ width, height }) => {
    // Prepare refs for labels
    labelRefs.current = Array(data.length)
      .fill(null)
      .map((_, i) => (labelRefs.current && labelRefs.current.length)
        ? labelRefs.current[i]
        : React.createRef<SVGForeignObjectElement>()
      );

    // Bounds
    const xMax = width - margins.left - margins.right;
    const yMax = height - margins.top - margins.bottom;

    // Scales
    const xScale = scaleBand({
      rangeRound: [0, xMax],
      domain: data.map(d => d.label),
      padding: 0.25,
    });
    const yScale = scaleLinear({
      rangeRound: [yMax, 0],
      domain: [0, Math.max(...data.map(d => d.value))],
    });

    const renderCustomLabel = (options: {
      d: IData;
      barWidth: number;
      i: number;
    }) => options.d.customLabel && (
      <foreignObject
        className={styles.customLabel}
        width={options.barWidth}
        height={1}
        x={0}
        y={0}
        ref={labelRefs.current[options.i]}
      >
        <div className={styles.customLabelContainer}>
          {options.d.customLabel}
        </div>
      </foreignObject>
    );

    const renderBars = () => data.map((d, i) => {
      const barWidth = xScale.bandwidth();
      const barHeight = yMax - yScale(d.value);
      const barX = xScale(d.label);
      const barY = yMax - barHeight;
      const br = Number(borderRadius);

      return (
        <Group
          key={`bar-${d.label}-${i}`}
          className={styles.barGroup}
          top={barY}
          left={barX}
        >
          {renderCustomLabel({ d, barWidth, i })}
          <path
            className={styles.bar}
            width={barWidth}
            height={barHeight}
            x={barX}
            y={barY}
            // Draw top-left and top-right border radius
            // https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
            // See border-radius value in ./ThickBarChart.scss
            d={`
              M0,${barHeight}
              v${-barHeight + br}
              q0,${-br} ${br},${-br}
              h${barWidth - br * 2}
              q${br},0 ${br},${br}
              v${barHeight - br}
              z
            `}
            fill={`url(#${gradientId})`}
          />
        </Group>
      );
    });

    return (
      <svg width={width} height={heightProp || height}>
        <LinearGradient
          id={gradientId}
          from="rgba(57, 150, 224, 0.6)"
          to="rgba(255, 233, 217, 0.3)"
        />
        <Group top={margins.top} left={margins.left}>
          {renderBars()}
        </Group>
      </svg>
    );
  }, [data, gradientId, margins, heightProp]);

  // Align custom labels
  defer(() => {
    forEach(labelRefs.current, (labelRef) => {
      if (labelRef.current) {
        const container = labelRef.current.querySelector<HTMLElement>(`.${styles.customLabelContainer}`);
        const setY = (ref) => {
          const height: number = container.clientHeight || container.offsetHeight;
          ref.setAttribute('y', `${-height - barInfoMargin}`);
          ref.setAttribute('height', `${height}`);
        };
        setY(labelRef.current);

        // Animate later -- this helps other browsers catch up with render to prevent positioning bugs
        if (!labelRef.current.classList.contains(`${styles.visible}`)) {
          ((ref) => {
            setTimeout(() => {
              if (ref) {
                ref.classList.add(`${styles.visible}`);
                setY(ref);
              }
            }, 600);
          })(labelRef.current);
        }
      }
    });
  });

  return (
    <div className={cx(className, styles.ThickBarChart)}>
      <ParentSize className={styles.chart}>
        {({ width: parentWidth, height: parentHeight }) => (
          renderGraph({ width: parentWidth, height: parentHeight })
        )}
      </ParentSize>
    </div>
  );
};

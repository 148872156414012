import * as React from 'react';
import cx from 'classnames';
import { SortableElement } from 'react-sortable-hoc';

import AutosizingTextField from 'src/common/AutosizingTextField';
import { DragHandleIcon } from 'src/icons';

import {
  IContentGuideline,
  IContentGuidelineSection,
  IGuidelinePosition,
} from './contentGuidelineModel';

import styles from './ContentGuidelines.scss';

const { useState } = React;

const ENTER_KEY = 'Enter';

interface IGuidelineProps {
  onPaste(pastedText: string, position: IGuidelinePosition);
  onClick(guideline: IContentGuideline, position: IGuidelinePosition);
  onChange(newGuideline: IContentGuideline, position: IGuidelinePosition);
  onBlur(position: IGuidelinePosition);
  onEnterKeyDown(position: IGuidelinePosition);

  section: IContentGuidelineSection;
  position: IGuidelinePosition;
  guideline?: IContentGuideline;
  isSelected: boolean;
}

const Guideline: React.FunctionComponent<IGuidelineProps> = (props) => {
  const [inputDefaultHeight, setInputDefaultHeight] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const { guideline, section, position, isSelected } = props;
  const hasGuideline = !!guideline;

  const handleClickGuideline = (e: React.MouseEvent<HTMLDivElement>) => {
    if (guideline) {
      setInputValue(guideline.text);
    }
    setInputDefaultHeight(e.currentTarget.clientHeight);
    props.onClick(guideline, position);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === ENTER_KEY) {
      e.preventDefault();
      e.stopPropagation();
      (e.target as HTMLTextAreaElement).blur();
      props.onEnterKeyDown(position);
    }
  };

  const handleBlur = () => {
    props.onBlur(position);
    if (guideline && guideline.text === inputValue) {
      return;
    }
    props.onChange({ text: inputValue }, position);
    setInputValue('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (!inputValue) {
      const text = e.clipboardData.getData('text');
      props.onPaste(text, position);
    }
  };

  return (
    <div
      className={cx(
        {
          [styles.showingInput]: isSelected,
          [styles.emptyGuideline]: !hasGuideline,
        },
        styles.listItemWrapper,
      )}
    >
      {hasGuideline ? <DragHandleIcon className={styles.dragHandle} size={16} /> : null}
      <div className={styles.hoverBox}>
        <div className={styles.listItem}>
          {isSelected ? (
            <div className={styles.inputWrapper}>
              <AutosizingTextField
                key={`${position.sectionIndex},${position.listIndex}`}
                className={styles.input}
                placeholder={section.guidelineSuggestion}
                onBlur={handleBlur}
                autoFocus={true}
                value={inputValue}
                onChange={handleChange}
                defaultHeight={inputDefaultHeight}
                rows={1}
                spellCheck={false}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              />
            </div>
          ) : (
            <div className={styles.guidelineWrapper}>
              <div
                className={cx({ [styles.suggestion]: !hasGuideline }, styles.guideline)}
                onClick={handleClickGuideline}
              >
                {hasGuideline ? guideline.text : section.guidelineSuggestion}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SortableGuideline = SortableElement(Guideline);

export default Guideline;

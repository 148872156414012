import * as React from 'react';
import numeral from 'numeral';

import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { LinearGradient } from '@vx/gradient';

const { useMemo } = React;
import styles from './GaugeChart.scss';

interface IData {
  label: string;
  value: number;
}
interface IGradient {
  from: string;
  to: string;
}
export interface IGaugeChartProps {
  percentage: number;
  color?: IGradient;
  width?: number;
  height?: number;
}
export const GaugeChart: React.FunctionComponent<IGaugeChartProps> = React.memo(
  props => {
    const {
      percentage,
      width,
      height,
      color = { from: '#ffe9d9', to: '#3996e0' },
    } = props;
    const radius = Math.min(width / 2, height / 2);
    const records = useMemo(
      () => [
        {
          value: percentage,
        },
        {
          value: 100 - percentage,
        },
      ],
      [percentage]
    );

    return (
      <div className={styles.GaugeChart}>
        <svg className={styles.graph} width={radius * 2} height={radius * 2}>
          <LinearGradient
            id={`linear-${color.from}-${color.to}`}
            rotate={100}
            from="#ffe9d9"
            to={color.to}
            toOffset="50%"
            vertical={false}
          />
          <Group top={radius} left={radius}>
            <Pie
              data={records}
              pieValue={(d: IData) => d.value}
              outerRadius={radius}
              innerRadius={radius - 20}
              cornerRadius={100}
              padAngle={0}
            >
              {pie => {
                const firstArc = {
                  data: { value: 100 },
                  endAngle: 2 * Math.PI,
                  index: 1,
                  padAngle: 0,
                  startAngle: 0,
                  value: 100,
                };
                const secondArc = pie.arcs[0];

                return [
                  <path key={0} d={pie.path(firstArc)} fill="#eff5f9" />,
                  <path
                    key={1}
                    d={pie.path(secondArc)}
                    fill={`url(#linear-${color.from}-${color.to})`}
                  />,
                ];
              }}
            </Pie>
          </Group>
          <text
            dx={radius}
            dy={radius + 6}
            style={{
              textAnchor: 'middle',
              fontSize: '20px',
              fontWeight: 'bold',
              letterSpacing: '0.15px',
              fill: '#2E426D',
            }}
          >
            {numeral(percentage / 100).format('0%')}
          </text>
        </svg>
      </div>
    );
  }
);

import * as React from 'react';

import { uniqueId } from 'lodash';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const GlobeIcon = React.memo((props: ISvgIconProps) => {
  const maskId = uniqueId('GlobeIconMask');
  return (
    <SvgIcon {...props}>
      <g>
        <path id={uniqueId('GlobeIcon')}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99976 12.0004C1.99976 6.4864 6.48576 2.0004 11.9998 2.0004C17.5138 2.0004 21.9998 6.4864
          21.9998 12.0004C21.9998 17.5134 17.5138 22.0004 11.9998 22.0004C6.48576 22.0004 1.99976 17.5134
          1.99976 12.0004ZM11.9998 4.0004C12.6158 4.0004 13.2108 4.0764 13.7868 4.2084C13.6178 4.5804 13.3568
          4.9194 13.0128 5.1884C12.8092 5.34903 12.582 5.48437 12.355 5.61966L12.3068 5.6484C11.6558 6.0364
          10.9188 6.4764 10.4568 7.3224C9.84076 8.4524 9.84076 9.6064 9.84076 10.6244C9.84076 11.9794 9.79676
          12.7804 8.89276 13.4874C7.52376 14.5604 5.42876 13.9614 4.13276 13.4164C4.05076 12.9554 3.99976 12.4834
          3.99976 12.0004C3.99976 7.5894 7.58876 4.0004 11.9998 4.0004ZM16.8888 18.3184C16.7168 17.8854 16.4728
          17.5074 16.2588 17.1844L16.2411 17.1579C16.1387 17.0044 16.0345 16.8481 15.9428 16.6874C15.5538 16.0114
          15.6878 15.7374 16.3188 14.6804L16.4208 14.5074C16.9302 13.6491 16.9596 12.8255 16.9855 12.0992L16.9858
          12.0924C16.9978 11.7344 17.0098 11.3974 17.0788 11.0804C17.2398 10.3484 18.7868 10.1534 19.7458 10.0384C19.9068
          10.6674 19.9998 11.3224 19.9998 12.0004C19.9998 14.5694 18.7778 16.8534 16.8888 18.3184Z"
          fill={props.fill} />
        <mask id={maskId} mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="2" width="21" height="21">
          <path id={uniqueId('GlobeIcon')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.99976 12.0004C1.99976 6.4864 6.48576 2.0004 11.9998 2.0004C17.5138 2.0004 21.9998 6.4864 21.9998
            12.0004C21.9998 17.5134 17.5138 22.0004 11.9998 22.0004C6.48576 22.0004 1.99976 17.5134 1.99976 12.0004ZM11.9998
            4.0004C12.6158 4.0004 13.2108 4.0764 13.7868 4.2084C13.6178 4.5804 13.3568 4.9194 13.0128 5.1884C12.8092 5.34903
            12.582 5.48437 12.355 5.61966L12.3068 5.6484C11.6558 6.0364 10.9188 6.4764 10.4568 7.3224C9.84076 8.4524 9.84076
            9.6064 9.84076 10.6244C9.84076 11.9794 9.79676 12.7804 8.89276 13.4874C7.52376 14.5604 5.42876 13.9614 4.13276
            13.4164C4.05076 12.9554 3.99976 12.4834 3.99976 12.0004C3.99976 7.5894 7.58876 4.0004 11.9998 4.0004ZM16.8888
            18.3184C16.7168 17.8854 16.4728 17.5074 16.2588 17.1844L16.2411 17.1579C16.1387 17.0044 16.0345 16.8481 15.9428
            16.6874C15.5538 16.0114 15.6878 15.7374 16.3188 14.6804L16.4208 14.5074C16.9302 13.6491 16.9596 12.8255 16.9855
            12.0992L16.9858 12.0924C16.9978 11.7344 17.0098 11.3974 17.0788 11.0804C17.2398 10.3484 18.7868 10.1534 19.7458
            10.0384C19.9068 10.6674 19.9998 11.3224 19.9998 12.0004C19.9998 14.5694 18.7778 16.8534 16.8888 18.3184Z"
            fill={props.fill || '#FFFFFF'} />
        </mask>
        <g mask={`url(#${maskId}`}>
        </g>
      </g>
    </SvgIcon>
  );
});

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const FileTypeImage = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 516.375 516.375">
    <path d="M344.25,133.875h95.625L325.125,0v114.75C325.125,126.225,334.688,133.875,344.25,133.875z" />
    <path
      d="M229.5,405.45l68.85-61.2l65.025,57.375v-114.75H153V420.75l47.812-38.25L229.5,405.45z M191.25,325.125
			c0-11.475,7.65-19.125,19.125-19.125s19.125,7.65,19.125,19.125s-7.65,19.125-19.125,19.125S191.25,336.6,191.25,325.125z"
    />
    <path
      d="M306,114.75V0H114.75C93.712,0,76.5,17.212,76.5,38.25v439.875c0,21.037,17.212,38.25,38.25,38.25h286.875
			c21.037,0,38.25-17.213,38.25-38.25V153H344.25C323.213,153,306,135.788,306,114.75z M382.5,459H133.875V267.75H382.5V459z"
    />
  </SvgIcon>
));

export { FileTypeImage };

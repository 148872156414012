import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { has, isString, isEmpty, filter, map } from 'lodash';

import { Image } from 'src/widgets/Image';
import { Tooltip } from 'src/widgets/Tooltip';

import { IRawMetricsData, TDataField, subChannelName } from './models/channel';

import styles from './DistributionUnit.scss';

interface IProps {
  rawData: IRawMetricsData[];
  channelInfo: any[];
  dataFieldInfo: any[];
  selectedField: TDataField;

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  fieldDisplayName: {
    [identifier: string]: string;
  };
  channelFields: {
    [identifier: string]: string[];
  };
  channels: IRawMetricsData[];
}

/**
 * @class
 * @extends {React.Component}
 */
export class DistributionUnit extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      fieldDisplayName: null,
      channelFields: null,
      channels: [],
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps) {
    const { channelInfo, dataFieldInfo, rawData } = nextProps;

    const fieldDisplayName = {};
    const channelFields = {};

    if (!isEmpty(dataFieldInfo)) {
      dataFieldInfo.forEach((info) => {
        fieldDisplayName[info.identifier] = info.name;
      });
    } else {
      console.error('Missing data field info.');
    }

    if (!isEmpty(channelInfo)) {
      channelInfo.forEach((channel) => {
        channelFields[channel.identifier] = (channel.key_columns_identifiers || '').split('|');
      });
    } else {
      console.error('Missing sub channel info.');
    }

    // filters out un-recognized channels
    const channels = filter(rawData, (data) => has(channelFields, data.subchannel));

    return {
      fieldDisplayName,
      channelFields,
      channels,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    const { channels } = this.state;

    return (
      <div className={cx(classNames.concat(styles.DistributionUnit))}>
        <div className={styles.header}>
          <div className={styles.divider} />
          <div className={styles.text}>Best Performing Content</div>
          <div className={styles.divider} />
        </div>
        {!isEmpty(channels) && this.renderChannels()}
        {isEmpty(channels) && <div className={styles.message}>No contents available.</div>}
      </div>
    );
  }

  /**
   * @private
   * Renders the best performing channels.
   *
   * @return {JSX}
   */
  private renderChannels = () => {
    const { channels } = this.state;

    return (
      <div className={styles.channelList}>
        {React.Children.toArray(map(channels, (channel) => this.renderChannel(channel)))}
      </div>
    );
  };

  /**
   * @private
   * Renders each individual channel.
   *
   * @return {JSX}
   */
  private renderChannel = (channel: IRawMetricsData) => {
    const { fieldDisplayName, channelFields } = this.state;
    const captionTextRef = React.createRef<HTMLDivElement>();
    const renderCaptionTooltip = (channel.distribution_unit__text || '').length >= 200;

    return (
      <div className={styles.channel}>
        <div
          className={styles.cover}
          onClick={this.handleChannelImageClick.bind(this, channel)}
        >
          <div
            className={cx(styles.banner, {
              [styles.facebook]: channel.subchannel === 'fb_ads',
              [styles.youtube]: channel.subchannel === 'social_youtube',
              [styles.instagram]: channel.subchannel === 'social_instagram',
            })}
          >
            {subChannelName[channel.subchannel]}
          </div>
          <Image className={styles.image} src={channel.distribution_unit__thumbnail_image_url} />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <div
              className={cx(styles.icon, {
                [styles.instagram]: channel.subchannel === 'social_instagram',
                [styles.facebook]: channel.subchannel === 'fb_ads',
                [styles.youtube]: channel.subchannel === 'social_youtube',
              })}
            />
            <div className={styles.name}>{channel.distribution_unit__distributor_display_name}</div>
          </div>
          <div className={styles.titleSection}>
            <span className={styles.title}>Title: </span>
            <span className={styles.text}>{channel.distribution_unit__title || 'None.'}</span>
          </div>
          <div className={styles.captionSection}>
            <span className={styles.title}>Caption: </span>
            <span className={styles.text} ref={captionTextRef}>
              {channel.distribution_unit__text &&
                (channel.distribution_unit__text.length < 200
                  ? channel.distribution_unit__text
                  : channel.distribution_unit__text.substr(0, 197) + '...')}
              {!channel.distribution_unit__text && 'None.'}
            </span>
            {renderCaptionTooltip && (
              <Tooltip placement="top" mountRef={captionTextRef} maxWidth={450}>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: 1.4,
                    color: '#313131',
                  }}
                >
                  {channel.distribution_unit__text}
                </div>
              </Tooltip>
            )}
          </div>
          {/* <div className={styles.stats}>
            <span className={styles.amount}>
            {
                numeral(channel[selectedField]).format(
                  dataFieldConfig[selectedField].isDollar ? '$0,0' : '0,0',
                )
            }
            </span>
            <span className={styles.displayName}>
              {dataFieldConfig[selectedField].displayName}
            </span>
          </div> */}
          <div className={styles.breakdown}>
            {map(channelFields[channel.subchannel], (field) => {
              if (!channel[field]) {
                return null;
              }

              return (
                <div key={field} className={styles.field}>
                  <div className={styles.value}>
                    {numeral(channel[field])
                      .format('0.[0]a')
                      .toUpperCase()}
                  </div>
                  <div className={styles.key}>{fieldDisplayName[field]}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  /**
   * @private
   * Opens a new tab when the cover image is clicked.
   */
  private handleChannelImageClick = (channel: IRawMetricsData) => {
    const { distribution_unit__url } = channel;

    if (isString(distribution_unit__url)) {
      window.open(distribution_unit__url, '_blank');
    }
  };
}

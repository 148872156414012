import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const SnapchatIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <circle style={{ fill: '#FFDF55' }} cx="256" cy="256" r="247.916" />
    <path
      style={{ fill: '#FFCE00' }}
      d="M256,8.084c-10.96,0-21.752,0.72-32.337,2.099C345.304,26.029,439.242,130.04,439.242,256
      s-93.939,229.971-215.579,245.817c10.585,1.379,21.377,2.099,32.337,2.099c136.921,0,247.916-110.996,247.916-247.916
      S392.921,8.084,256,8.084z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M421.429,319.926c-42.555-10.211-60.178-39.149-66.895-55.49c-1.774-4.316,0.084-9.302,4.229-11.351
      l22.142-10.949c5.835-2.886,10.105-8.156,11.719-14.462c1.613-6.307,0.397-12.981-3.336-18.313
      c-5.485-7.835-15.337-11.297-24.521-8.617l-16.161,4.721v-43.025c0-0.143-0.003-0.288-0.012-0.43
      C344.551,86.23,277.186,76.61,256.51,75.47c-0.193-0.011-0.83-0.011-0.95-0.004c-20.605,1.122-88.109,10.682-92.156,86.543
      c-0.008,0.143-0.012,0.287-0.012,0.43v43.025l-16.161-4.721c-9.182-2.682-19.036,0.781-24.521,8.618
      c-3.733,5.332-4.949,12.007-3.336,18.313c1.614,6.306,5.884,11.578,11.72,14.463l22.142,10.949
      c4.146,2.049,6.003,7.035,4.229,11.351c-6.716,16.341-24.34,45.28-66.895,55.49c-4.115,0.987-6.773,4.983-6.092,9.16
      c0.193,1.184,4.998,27.702,46.009,29.683c1.912,7.179,4.881,16.699,8.021,20.624c2.153,2.69,5.773,3.725,9.02,2.568
      c0.195-0.068,19.729-6.895,37.565-3.523c0.211,0.042,21.251,4.336,34.258,17.139c6.284,6.185,13.746,10.483,21.581,12.426
      c4.839,1.202,9.908,1.811,15.069,1.811s10.231-0.609,15.068-1.81c7.835-1.945,15.296-6.242,21.582-12.427
      c12.913-12.711,34.058-17.099,34.185-17.125c17.783-3.362,37.451,3.444,37.636,3.509c3.246,1.154,6.867,0.123,9.02-2.568
      c3.14-3.926,6.108-13.446,8.021-20.624c41.004-1.984,45.816-28.5,46.009-29.683C428.201,324.909,425.544,320.913,421.429,319.926z"
    />
    <path
      d="M437.019,74.981C388.667,26.628,324.379,0,256,0S123.333,26.628,74.981,74.981S0,187.62,0,256
      s26.628,132.667,74.981,181.019S187.62,512,256,512s132.667-26.628,181.019-74.981S512,324.379,512,256
      S485.372,123.333,437.019,74.981z M256,495.832C123.756,495.832,16.168,388.244,16.168,256S123.756,16.168,256,16.168
      S495.832,123.756,495.832,256S388.244,495.832,256,495.832z"
    />
    <path
      d="M421.429,319.926c-42.555-10.211-60.178-39.149-66.895-55.49c-1.774-4.316,0.084-9.302,4.229-11.351l21.757-10.758
      c4.353-2.153,8.042-5.535,10.272-9.848c3.906-7.557,3.198-16.402-1.504-23.12c-5.485-7.835-15.337-11.297-24.521-8.617
      l-16.161,4.721v-25.381c0-4.465-3.62-8.084-8.084-8.084l0,0c-4.465,0-8.084,3.62-8.084,8.084v35.927
      c0,1.745,0.465,3.487,1.479,4.905c2.07,2.894,5.641,4.032,8.872,3.091l26.511-7.746c2.524-0.738,5.234,0.216,6.742,2.369
      c1.405,2.007,1.177,4.016,0.917,5.035c-0.261,1.019-1.026,2.891-3.222,3.976l-22.142,10.949
      c-11.728,5.799-17.006,19.852-12.016,31.992c7.344,17.866,25.723,48.534,68.178,62.285c-4.36,4.65-13.527,10.098-32.472,9.84
      c-3.777-0.042-7.114,2.544-7.98,6.239c-1.336,5.697-3.061,11.661-4.477,15.715c-8.661-2.181-24.025-4.926-39.07-2.081
      c-1.055,0.209-26.034,5.316-42.45,21.474c-4.222,4.154-9.109,7.01-14.135,8.258c-3.565,0.885-7.324,1.333-11.173,1.333
      s-7.609-0.449-11.173-1.334c-5.025-1.247-9.913-4.102-14.134-8.258c-16.416-16.158-41.395-21.265-42.522-21.488
      c-14.99-2.836-30.333-0.089-38.99,2.092c-1.428-4.072-3.151-10.024-4.485-15.714c-0.867-3.693-4.157-6.329-7.98-6.238
      c-18.949,0.274-28.086-5.165-32.456-9.845c42.442-13.753,60.819-44.417,68.162-62.28c4.99-12.139-0.289-26.192-12.016-31.992
      l-22.141-10.948c-2.197-1.087-2.962-2.958-3.222-3.976c-0.261-1.019-0.488-3.028,0.917-5.034c1.508-2.156,4.217-3.105,6.742-2.369
      l26.512,7.746c3.228,0.941,6.798-0.196,8.87-3.088c1.016-1.419,1.482-3.16,1.482-4.905v-53.356
      c3.325-60.124,54.181-69.723,76.437-71.021c19.537,1.14,61.136,8.686,73.236,51.222c0.994,3.495,4.153,5.931,7.786,5.931h0.077
      c5.387,0,9.214-5.156,7.765-10.345c-15.164-54.308-70.049-61.965-88.352-62.974c-0.193-0.011-0.83-0.011-0.95-0.004
      c-20.605,1.122-88.109,10.682-92.156,86.543c-0.008,0.143-0.012,0.287-0.012,0.43v43.025l-16.161-4.721
      c-9.182-2.682-19.036,0.781-24.521,8.618c-4.702,6.716-5.41,15.563-1.503,23.12c2.23,4.313,5.92,7.695,10.272,9.848l21.756,10.758
      c4.146,2.049,6.003,7.035,4.229,11.351c-6.716,16.341-24.34,45.28-66.895,55.49c-4.115,0.987-6.773,4.983-6.092,9.16
      c0.193,1.184,4.998,27.702,46.009,29.683c1.912,7.179,4.881,16.699,8.021,20.624c2.153,2.69,5.773,3.725,9.02,2.568
      c0.195-0.068,19.729-6.895,37.565-3.523c0.211,0.042,21.251,4.336,34.258,17.139c6.284,6.185,13.746,10.483,21.581,12.426
      c4.839,1.202,9.908,1.811,15.069,1.811s10.231-0.609,15.068-1.81c7.835-1.945,15.296-6.242,21.582-12.427
      c12.913-12.711,34.058-17.099,34.185-17.124c17.783-3.362,37.451,3.444,37.636,3.509c3.246,1.154,6.867,0.123,9.02-2.568
      c3.14-3.926,6.108-13.446,8.021-20.624c41.004-1.984,45.816-28.5,46.009-29.683C428.201,324.909,425.544,320.913,421.429,319.926z"
    />
  </SvgIcon>
));

export { SnapchatIcon };

import { reduce, includes, isEmpty } from 'lodash';

import {
  AIQ_PREVIEW_RESOLUTIONS,
  PREVIEW_RESOLUTION,
  PREVIEW_RESOLUTION_ORIGINAL,
} from 'src/common/utils/media';
import { IContentInfo } from '../model';
import { IContent } from 'src/widgets/ContentPreviewer/contentPreviewerModel';
import getNetworkByPostType from 'src/common/utils/getNetworkByPostType';

export default (content: IContentInfo): IContent[] => {
  const previewMap = content.previewMap;
  const contentClass = content.contentClass;

  const contents = reduce(
    content.contents,
    (result, content) => {
      if (!includes(AIQ_PREVIEW_RESOLUTIONS, content.resolution)) {
        let thumbnailUrl = content.url;
        let previewUrl = content.url;
        const downloadableUrl = content.url;
        if (content.url in previewMap) {
          if (PREVIEW_RESOLUTION in previewMap[content.url]) {
            thumbnailUrl = previewMap[content.url][PREVIEW_RESOLUTION];
          }
          if (PREVIEW_RESOLUTION_ORIGINAL in previewMap[content.url]) {
            previewUrl = previewMap[content.url][PREVIEW_RESOLUTION_ORIGINAL];
          }
        }

        let type;
        if (contentClass === 'WebContent') {
          type = 'blog';
        } else if (contentClass === 'YoutubeVideoContent') {
          type = 'youtube';
        } else {
          type = content.media_type;
        }

        result.push({
          src: content.url,
          type,
          thumbnailUrl,
          previewUrl,
          downloadableUrl,
        });
      }
      return result;
    },
    [],
  );

  if (isEmpty(contents) && content.link) {
    const network = getNetworkByPostType(content.postType);

    // only handles and blog for now.
    if (network === 'youtube') {
      contents.push({
        src: content.link,
        type: 'youtube',
        imageLink: content.imageLink,
      });
    } else if (network === 'blog') {
      contents.push({
        src: content.link,
        type: 'blog',
      });
    }
  }

  return contents;
};

import * as React from 'react';
import cx from 'classnames';
import { get, has, indexOf, isFunction, map, sortBy } from 'lodash';

import { Tooltip } from 'src/widgets/Tooltip';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { UniqueImpressions } from 'src/icons';
import { useInviteContext } from 'src/widgets/Invite/hooks';
import { Loading } from 'src/widgets/Skeleton';

const { useEffect, useRef, useState } = React;
import styles from './ProgramsList.scss';

interface IProps {
  className?: string;
  /**
   * Social account
   */
  socialAccount: ISocialAccount;
  /**
   * Render without container
   */
  inline?: boolean;
  /**
   * Tooltip
   */
  showTooltip?: boolean;
  /**
   * Hide icon
   */
  hideIcon?: boolean;
  /**
   * Callback when programs are loaded
   */
  onProgramsLoaded?: () => void;
}

/**
 * List of programs
 * Only used for AspireX
 */
export const ProgramsList: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    hideIcon = false,
    inline = false,
    onProgramsLoaded,
    showTooltip = false,
    socialAccount,
  } = props;
  const [isFetching, setIsFetching] = useState(false);
  const containerRef = useRef<HTMLDivElement>();

  const {
    fetchingMemberPrograms,
    memberPrograms,
  } = useInviteContext();
  const programs = sortBy(
    get(memberPrograms, socialAccount.username),
    (program) => indexOf(['approved', 'new', 'invited'], program.status),
  );

  const programsIds = map(programs, (p) => p.id).sort().join(); // Used for useEffect comparison below
  useEffect(() => {
    if (has(memberPrograms, socialAccount.username) && isFunction(onProgramsLoaded)) {
      console.debug('Updating programs list for', socialAccount.username);
      onProgramsLoaded();
    }
  }, [programsIds]);

  useEffect(() => {
    if (indexOf(fetchingMemberPrograms, socialAccount.username) >= 0 && !isFetching) {
      setIsFetching(true);
    } else if (has(memberPrograms, socialAccount.username)) {
      setIsFetching(false);
    }
  }, [fetchingMemberPrograms]);

  const listElem = isFetching
    ? (
      <div className={styles.placeholder}>
        <Loading
          show
          theme='grey'
          borderRadius={4}
        />
      </div>
    )
    : <>
      {!hideIcon && !inline && programs.length > 0 && (
        <UniqueImpressions
          size={20}
          className={cx(styles.icon, styles[programs[0].status])}
        />
      )}
      {map(programs, (program, i) => (
        <span
          key={`program-${socialAccount.username}-${i}`}
          className={cx(styles.program, styles[program.status])}
        >
          {program.title + (i + 1 < programs.length ? ',' : '')}
        </span>
      ))}
    </>;

  const tooltipElem = showTooltip && !inline && (
    <Tooltip
      mountRef={containerRef}
      maxWidth={600}
      className={styles.Tooltip}
      contentClassName={styles.content}
    >
      {map(programs, (p) => {
        if (p.status === 'invited') {
          return `${p.title} (Invited)`;
        }
        return p.title;
      }).join(', ')}
    </Tooltip>
  );

  return <>
    {inline
      ? listElem
      : (
        <div
          className={cx(styles.ProgramsList, className, {
            [styles.hideIcon]: hideIcon,
          })}
          ref={containerRef}
        >
          {listElem}
        </div>
      )}
    {tooltipElem}
  </>;
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const SendOfferIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="M14.9,1.1C6.7,1.1,0,6.6,0,13.3c0,3.2,1.5,6.2,4.1,8.4l-3,5.9C1,28,1.1,28.2,1.2,28.4
			c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1l8-3.7c1.6,0.5,3.2,0.7,4.9,0.7c8.2,0,14.9-5.5,14.9-12.2C29.8,6.6,23.1,1.1,14.9,1.1
			z M14.9,24.3c-1.7,0-3.3-0.2-4.8-0.7c-0.1,0-0.3,0-0.4,0l-6.6,3l2.4-4.8c0.1-0.3,0.1-0.6-0.2-0.8c-2.6-2.1-4-4.8-4-7.8
			c0-6,6.1-11,13.6-11c7.5,0,13.6,4.9,13.6,11C28.5,19.4,22.4,24.3,14.9,24.3z"
    />
    <path
      d="M11.2,9.8h7.3c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-7.3c-0.3,0-0.6,0.3-0.6,0.6
			C10.6,9.5,10.8,9.8,11.2,9.8z"
    />
    <path
      d="M18.5,17h-7.3c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h7.3c0.3,0,0.6-0.3,0.6-0.6
			S18.8,17,18.5,17z"
    />
    <path
      d="M21.2,12.8H8.5c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h12.7c0.3,0,0.6-0.3,0.6-0.6
			S21.6,12.8,21.2,12.8z"
    />
  </SvgIcon>
));

export { SendOfferIcon };

import * as React from 'react';
import cx from 'classnames';

import genericAddEventLog from 'src/common/utils/genericAddEventLog';
import { ArrowLeftIcon } from 'src/icons';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ErrorBoundary } from 'src/utils';
import { useIsServer } from 'src/utils/hooks';

import { FilterBox, ISelectFilterOptions } from './Filters/FilterBox';
import SummaryView from './SummaryView/SummaryView';
import BreakdownView from './BreakdownView/BreakdownView';
import PostsView from './PostsView/PostsView';
import { Notice } from 'src/widgets/Notice';

import { AnalyzeProvider, AnalyzeContext } from './AnalyzeContext';

const { useState } = React;
import styles from './AnalyzePage.scss';

type TAnalyzeState = 'summary' | 'breakdown' | 'posts';
export interface IAnalyzePageProps {
  apiEndpoint: string;
  isQa: boolean;
  className?: string;
  organizationId?: string;
  clientId?: string;
  aspirexFilterOptions?: ISelectFilterOptions[];
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
}

/**
 * @type {React.FunctionComponent}
 */
export const AnalyzePage: React.FunctionComponent<IAnalyzePageProps> = (props) => {
  const {
    aspirexFilterOptions,
    apiEndpoint,
    organizationId,
    clientId,
    isQa,
    aspirexAnalytics,
  } = props;
  const isServer = useIsServer();
  const [state, setState] = useState<TAnalyzeState>('summary');

  if (isServer) {
    return <LoadSpinner centered={true} />;
  }
  const getView = (state) => {
    if (state === 'summary') {
      return <SummaryView />;
    }
    if (state === 'breakdown') {
      return <BreakdownView />;
    }
    if (state === 'posts') {
      return <PostsView />;
    }
    if (state === 'no data') {
      return (
        <Notice type="disabled" className={styles.noDataNotice}>
          There are no campaigns to report on.
          Once creators start completing collaborations, you will see the posts here.
        </Notice>
      )
    }
  };

  const changePage = (page: TAnalyzeState) => {
    const previousState = state;
    setState(page);
    genericAddEventLog(
      'analyze_change_page',
      {
        previous_page: previousState,
        current_page: page,
      },
      aspirexAnalytics
    );
  };

  const getOldAnalyzeLink = () => window.location.href.replace('analyze2', 'analyze/campaign//summary');

  return (
    <div className={cx(styles.AnalyzePage, props.className)}>
      <ErrorBoundary>
        <AnalyzeProvider
          apiEndpoint={apiEndpoint}
          organizationId={organizationId}
          clientId={clientId}
          currentPage={state}
          isQa={isQa}
          isAspirex={!!props.aspirexFilterOptions}
          aspirexAnalytics={aspirexAnalytics}
        >
          <AnalyzeContext.Consumer>
            {() => {
              return (
                <>
                  <FilterBox aspirexFilterOptions={aspirexFilterOptions} onNoData={setState} />
                  <div className={styles.alignContent}>
                    <div className={styles.header}>
                      <div className={styles.navbar}>
                        <div
                          className={cx(styles.navItem, {
                            [styles.active]: state === 'summary',
                          })}
                          onClick={() => changePage('summary')}
                        >
                          Summary
                        </div>
                        <div
                          className={cx(styles.navItem, {
                            [styles.active]: state === 'breakdown',
                          })}
                          onClick={() => changePage('breakdown')}
                        >
                          Breakdown
                        </div>
                        <div
                          className={cx(styles.navItem, {
                            [styles.active]: state === 'posts',
                          })}
                          onClick={() => changePage('posts')}
                        >
                          Posts
                        </div>
                      </div>
                      {!props.aspirexFilterOptions &&
                      <div>
                        <a className={styles.linkToOldAnalyze} target="_blank" rel="noopener noreferrer" href={getOldAnalyzeLink()}>
                          View previous Analyze <ArrowLeftIcon size={10} className={styles.arrowIcon} />
                        </a>
                      </div>
                      }
                    </div>
                    <div className={styles.children}>{getView(state)}</div>
                  </div>
                </>
              );
            }}
          </AnalyzeContext.Consumer>
        </AnalyzeProvider>
      </ErrorBoundary>
    </div>
  );
};

import * as React from 'react';
import cx from 'classnames';
import { map, sortBy, reverse, isEmpty } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ContentTile } from './ContentTile';

import getLicensedContentImage from 'src/common/utils/getLicensedContentImage';
import failedImage from 'src/common/utils/failedImage';

import { ILicensedContent } from 'src/common/models/licensedContent';
import { TDataField } from './models/channel';

import styles from './BestPerformingContent.scss';

interface IProps {
  selectedField: TDataField;
  onContentClick(id: string);
  onSearchClick(imageUrl: string);

  contents?: ILicensedContent[];
  isLoading?: boolean;
  classNames?: string[];
}
type TDefaultProp = 'contents' | 'isLoading' | 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class BestPerformingContent extends React.Component<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    contents: [],
    isLoading: false,
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      classNames,
      contents,
      isLoading,
      onContentClick,
      selectedField,
      onSearchClick,
    } = this.props;

    const filteredContents = reverse(
      sortBy<ILicensedContent>(contents, (content) => content.metrics[selectedField]),
    )
      // remove contents that don't have image
      .filter((content) => {
        const imageUrl = getLicensedContentImage(content);

        // skip
        if (isEmpty(imageUrl) || failedImage.contains(imageUrl)) {
          return false;
        }

        return true;
      })
      // get top 6
      .slice(0, 6);

    return (
      <div className={cx(classNames.concat(styles.BestPerformingContent))}>
        <div className={styles.header}>
          <div className={styles.divider} />
          <div className={styles.text}>Best Performing Content</div>
          <div className={styles.divider} />
        </div>
        {isLoading && <LoadSpinner />}
        {!isLoading && !isEmpty(contents) && (
          <div className={styles.list}>
            {map(filteredContents, (content) => {
              const imageUrl = getLicensedContentImage(content);

              return (
                <ContentTile
                  key={content.id}
                  content={content}
                  onContentClick={onContentClick}
                  onSearchClick={onSearchClick}
                  selectedField={selectedField}
                  onImageError={this.handleImageError.bind(this, imageUrl)}
                  classNames={[styles.content]}
                />
              );
            })}
          </div>
        )}
        {!isLoading && isEmpty(contents) && (
          <div className={styles.message}>No contents available.</div>
        )}
      </div>
    );
  }

  /**
   * @private
   * Handler for when image load failed.
   *
   * @param {String} url the image url.
   */
  private handleImageError = (url: string) => {
    failedImage.add(url);

    this.forceUpdate();
  };
}

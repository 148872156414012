import * as React from 'react';
import cx from 'classnames';

import { KeyboardArrowLeftIcon } from 'src/icons';

import { Cell, ICellProps } from './Cell';
import { SortTypes, TSortType } from '../utils/SortedDataList';
import { UnsortableCellTypes } from '../tableContext';

const { useCallback } = React;
import styles from './HeaderCell.scss';

const nextSortDirection = (sortDir: TSortType) => {
  if (!sortDir) {
    return SortTypes.DESC;
  } else if (sortDir === SortTypes.DESC) {
    return SortTypes.ASC;
  }
  return null;
};

interface IProps extends ICellProps {
  sortDir?: TSortType;
  onSortChange?: (sortDir: TSortType) => void;
}

/**
 * @type {React.FunctionComponent}
 */
export const HeaderCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    config,
    onSortChange,
    sortDir,
    value,
    ...restProps
  } = props;

  /**
   * Sort
   */
  const sortable = !UnsortableCellTypes.includes(config.cellType);
  const toggleSortDir = useCallback(() => {
    if (sortable) {
      onSortChange(nextSortDirection(sortDir));
    }
  }, [sortable, sortDir, onSortChange]);

  const content = (
    <>
      {value}{' '}
      {sortDir ? (
        sortDir === SortTypes.ASC ? (
          <KeyboardArrowLeftIcon size={12} className={cx(styles.arrowIcon, styles.arrowUp)} />
        ) : (
          <KeyboardArrowLeftIcon size={12} className={cx(styles.arrowIcon, styles.arrowDown)} />
        )
      ) : null}
    </>
  );

  return (
    <Cell
      className={cx(styles.HeaderCell, className, {
        [styles.sortable]: sortable,
        [styles.active]: !!sortDir,
      })}
      value={content}
      config={config}
      onClick={toggleSortDir}
      isHeaderCell={true}
      {...restProps}
    />
  );
});

HeaderCell.defaultProps = {
  sortDir: null,
  onSortChange: () => undefined,
};

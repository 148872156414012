import * as React from 'react';
import cx from 'classnames';
import { compact, map, head, isEmpty } from 'lodash';

import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ClientContentItem } from './ClientContentItem';

import { IClientContentReview } from 'src/common/models/clientContentReview';
import { IContentReview, IClientFeedback } from 'src/common/models/contentReview';
import { IContentInfo } from './model';

import endpoints from 'src/common/config/endpoints';

import processContentReview from './utils/processContentReview';
import contentReviewCompareFn from './utils/contentReviewCompareFn';

import styles from './ClientContentReviewPage.scss';

interface IProps {
  // api endpoint
  apiEndpoint: string;
  clientReviewId: number;

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  clientReview: IClientContentReview;
  contentReviews: IContentReview[];
  isFetchingClientReview: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class ClientContentReviewPage extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      clientReview: null,
      contentReviews: [],
      isFetchingClientReview: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    this.setState({
      isFetchingClientReview: true,
    });
    this.fetchClientContentReview()
      .then((clientReview) => {
        console.log(clientReview);
        this.setState({
          clientReview,
          contentReviews: (clientReview.content_reviews || []).sort(contentReviewCompareFn),
          isFetchingClientReview: false,
        });
      })
      .catch(() => {
        this.setState({
          isFetchingClientReview: false,
        });
      });
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    const { isFetchingClientReview, contentReviews } = this.state;

    console.log(contentReviews);
    const contents = compact(map(contentReviews, processContentReview));
    const hasContents = !isEmpty(contents);
    console.log(contents);

    return (
      <div className={cx(classNames.concat(styles.ClientContentReviewPage))}>
        {isFetchingClientReview && <LoadSpinner />}
        {!isFetchingClientReview && (
          <div className={styles.mainContent}>
            {hasContents && (
              <React.Fragment>
                <div className={styles.header}>
                  <div className={styles.title}>
                    {contents.length} pieces of content need review
                  </div>
                  <div className={styles.text}>
                    Please review the quality of the content below to get them live
                  </div>
                </div>
                <div className={styles.contentList}>
                  {map(contents, (content, index) => {
                    const feedback = head(content.clientFeedbacks) || {
                      user: null,
                      comment: '',
                      approved: false,
                    };

                    return (
                      <ClientContentItem
                        key={index}
                        content={content}
                        feedback={feedback}
                        classNames={[styles.contentItem]}
                        updateApproved={this.updateApproved.bind(this, content)}
                        updateComment={this.updateComment.bind(this, content)}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            )}
            {!hasContents && (
              <div className={styles.noContents}>No content needs to be reviewed.</div>
            )}
          </div>
        )}
      </div>
    );
  }

  /**
   * @private
   * Fetches the content reviews.
   *
   * @return {Promise}
   */
  private fetchClientContentReview = (): Promise<IClientContentReview> =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint, clientReviewId } = this.props;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.clientContentReviewEndpoint}/${clientReviewId}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          resolve(json.data);
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Updates the approved status of a content.
   *
   * @param {IContentInfo} content the content info.
   *
   * @return {Promise}
   */
  private updateApproved = (content: IContentInfo, approved: boolean) =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint } = this.props;
      const { clientReview } = this.state;
      const { id } = content;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.clientContentReviewEndpoint}/${clientReview.id}`,
          {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              id,
              approved,
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const { contentReviews } = this.state;
          const contentReviewIndex = contentReviews.findIndex(
            (contentReview) => contentReview.id === id,
          );
          const contentReview = contentReviews[contentReviewIndex];
          const existingFeedback = head(contentReview.client_feedbacks);

          if (existingFeedback) {
            existingFeedback.approved = approved;

            this.setState(
              {
                contentReviews: [
                  ...contentReviews.slice(0, contentReviewIndex),
                  {
                    ...contentReview,
                    client_feedbacks: [existingFeedback],
                  },
                  ...contentReviews.slice(contentReviewIndex + 1),
                ],
              },
              resolve,
            );
          } else {
            const newFeedback: IClientFeedback = {
              user: null,
              approved,
              comment: '',
            };

            this.setState(
              {
                contentReviews: [
                  ...contentReviews.slice(0, contentReviewIndex),
                  {
                    ...contentReview,
                    client_feedbacks: [newFeedback],
                  },
                  ...contentReviews.slice(contentReviewIndex + 1),
                ],
              },
              resolve,
            );
          }
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });

  /**
   * @private
   * Rejects the content.
   *
   * @return {Promise}
   */
  private updateComment = (content: IContentInfo, comment: string) =>
    new Promise(async (resolve, reject) => {
      const { apiEndpoint } = this.props;
      const { clientReview } = this.state;
      const { id } = content;

      try {
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.clientContentReviewEndpoint}/${clientReview.id}`,
          {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              id,
              comment,
            }),
          },
        );

        const json = await resp.json();

        if (json.status && json.status.code === 200) {
          const { contentReviews } = this.state;
          const contentReviewIndex = contentReviews.findIndex(
            (contentReview) => contentReview.id === id,
          );
          const contentReview = contentReviews[contentReviewIndex];
          const existingFeedback = head(contentReview.client_feedbacks);

          if (existingFeedback) {
            existingFeedback.comment = comment;

            this.setState(
              {
                contentReviews: [
                  ...contentReviews.slice(0, contentReviewIndex),
                  {
                    ...contentReview,
                    client_feedbacks: [existingFeedback],
                  },
                  ...contentReviews.slice(contentReviewIndex + 1),
                ],
              },
              resolve,
            );
          } else {
            const newFeedback: IClientFeedback = {
              user: null,
              approved: false,
              comment,
            };

            this.setState(
              {
                contentReviews: [
                  ...contentReviews.slice(0, contentReviewIndex),
                  {
                    ...contentReview,
                    client_feedbacks: [newFeedback],
                  },
                  ...contentReviews.slice(contentReviewIndex + 1),
                ],
              },
              resolve,
            );
          }
        } else {
          reject();
        }
      } catch (err) {
        console.log(err);

        reject();
      }
    });
}

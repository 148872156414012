import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { debounce, defer, flatten, map } from 'lodash';

import { MasonryGrid } from 'src/widgets/MasonryGrid';
import { SocialPostTile, ISocialPostTileProps } from 'src/common/SocialPostTile';
import { browserHasScrollbar } from 'src/utils/uiUtils';

import { ISocialAccount } from 'src/common/models/socialAccount';
import { ICreatorList } from './redux/creatorListModel';

import styles from './SocialPostView.scss';

const defaultColumnWidth = 330;

interface IOwnProps {
  classNames?: string[];
  showPagination?: boolean;
  onSelectPage?(pageNumber: number);
}
type TDefaultProp = 'classNames';
interface IStateProps {
  socialAccounts: ISocialAccount[];
  selectedPage?: number;
  pageCount?: number;
}
type IProps = IOwnProps & IStateProps;

interface IState {
  columnWidth: number;
}

/**
 * @class
 * @extends {React.Component}
 */
class SocialPostView extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private ref: React.RefObject<HTMLDivElement>;

  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      columnWidth: null,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidMount() {
    window.addEventListener('resize', debounce(this.onWindowResize, 200), { passive: true });

    // trigger a window resize to calculate the columns
    defer(this.onWindowResize);
  }

  /**
   * @inheritDoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  private onWindowResize = () => {
    const node = this.ref.current;

    if (node) {
      // Consider browser's scrollbar width (usually 15px)
      const nodeWidth = node.offsetWidth - (browserHasScrollbar() ? 15 : 0);
      const minColumnWidth = Math.max(
        Math.floor(nodeWidth / Math.ceil(nodeWidth / defaultColumnWidth)),
        280,
      );
      const maxColumnWidth = Math.min(
        Math.floor(nodeWidth / Math.floor(nodeWidth / defaultColumnWidth)),
        380,
      );

      let columnWidth;
      // choose column width closer to default column width
      if (
        Math.abs(minColumnWidth - defaultColumnWidth) <=
        Math.abs(maxColumnWidth - defaultColumnWidth)
      ) {
        columnWidth = minColumnWidth;
      } else {
        columnWidth = maxColumnWidth;
      }

      this.setState({
        columnWidth,
      });
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { socialAccounts, classNames } = this.props;
    const { columnWidth } = this.state;

    const socialPosts = flatten(map(socialAccounts, (socialAccount) => socialAccount.posts));

    const itemProps: ISocialPostTileProps[] = map(socialPosts, (socialPost) => {
      const account = socialAccounts.find(
        (socialAccount) => socialAccount.id === socialPost.account_id,
      );

      return {
        key: socialPost.id,
        socialPost,
        avatarImage: account && (account.profile_image || account.profile_picture),
        name: account && (account.username || account.name),
      };
    });

    return (
      <div className={cx(classNames.concat(styles.SocialPostView))} ref={this.ref}>
        {/* only mounts masonry grid after column width is calculated */}
        {columnWidth && (
          <MasonryGrid
            columnWidth={columnWidth}
            itemComponent={SocialPostTile}
            itemProps={itemProps}
            defaultItemHeight={SocialPostTile.defaultHeight}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ICreatorList, ownProps: IOwnProps): IOwnProps & IStateProps => {
  return {
    socialAccounts: state.socialAccounts,

    classNames: ownProps.classNames,
    onSelectPage: ownProps.onSelectPage,
  };
};

export default connect<IStateProps, unknown, IOwnProps>(
  mapStateToProps,
  {},
)(SocialPostView);

import * as React from 'react';

import { uniqueId } from 'lodash';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

export const PersonIcon = React.memo((props: ISvgIconProps) => {
  const maskId = uniqueId('PersonIconMask');
  return (
    <SvgIcon {...props}>
      <g>
        <path id={uniqueId('PersonIcon')}
          fillRule="evenodd"
          clipRule="evenodd"
          d="
            M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16
            4.794 16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12
            13C15.859 13 19 16.14 19 20Z
          "
          fill={props.fill}
        />
        <mask id={maskId} mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="3" width="14" height="18">
          <path id={uniqueId('PersonIcon')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="
              M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794
              16 7ZM19 20C19 20.552 18.553 21 18 21H6C5.447 21 5 20.552 5 20C5 16.14 8.141 13 12 13C15.859
              13 19 16.14 19 20Z
            "
            fill={props.fill || '#FFFFFF'}
          />
        </mask>
        <g mask={`url(#${maskId}`} />
      </g>
    </SvgIcon>
  );
});

/**
 * Replaces http with https.
 *
 * @param {String} url the url.
 *
 * @return {String}
 */
const getSafeURL = (url: string) => {
  return url.replace(/^http:\/\//i, 'https://');
};

export default getSafeURL;

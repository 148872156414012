import { minBy, maxBy } from 'lodash';
import { format, parse, subDays, addDays } from 'date-fns';

import { IRawMetricsData } from '../models/channel';
import { IHistogramData } from '../models/histogram';

/**
 * @private
 * Process the raw metrics data, returns histogram data.
 *
 * @param {IRawMetricsData[]} histogramData the raw histogram data.
 *
 * @return {IHistogramData[]}
 */
export default function(histogramData: IRawMetricsData[]): IHistogramData[] {
  // generates continuous dates
  // this is not the most efficient way
  // but it's easy to understand
  const minDate = parse(minBy(histogramData, (d) => parse(d.date, 'yyyy-MM-dd', new Date())).date, 'yyyy-MM-dd', new Date());
  const maxDate = parse(maxBy(histogramData, (d) => parse(d.date, 'yyyy-MM-dd', new Date())).date, 'yyyy-MM-dd', new Date());
  const dates = [];
  // at least show stats for 30 days
  let currDate = minBy([minDate, subDays(maxDate, 30)], (d) => d.getTime());
  while (currDate <= maxDate) {
    dates.push(format(currDate, 'yyyy-MM-dd'));

    currDate = addDays(currDate, 1);
  }
  const data: IHistogramData[] = [];
  dates.forEach((date) => {
    const d = histogramData.find((d) => d.date === date);

    if (d) {
      data.push({
        spend: d.spend || 0,
        ecv: d.ecv || 0,
        content_count: d.content_count || 0,
        impressions: d.impressions || 0,
        ts: parse(d.date, 'yyyy-MM-dd', new Date()).getTime(),
      });
    } else {
      data.push({
        spend: 0,
        ecv: 0,
        content_count: 0,
        impressions: 0,
        ts: parse(date, 'yyyy-MM-dd', new Date()).getTime(),
      });
    }
  });
  data.reduce(
    (sum, curr) => {
      sum.spend += curr.spend;
      sum.ecv += curr.ecv;
      sum.content_count += curr.content_count;
      sum.impressions += curr.impressions;

      curr.spend = sum.spend;
      curr.ecv = sum.ecv;
      curr.content_count = sum.content_count;
      curr.impressions = sum.impressions;

      return sum;
    },
    { spend: 0, ecv: 0, content_count: 0, impressions: 0 },
  );

  return data;
}

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const FileTypeVideo = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 516.375 516.375">
    <rect x="344.25" y="382.5" width="19.125" height="19.125" />
    <rect x="344.25" y="420.75" width="19.125" height="19.125" />
    <rect x="344.25" y="344.25" width="19.125" height="19.125" />
    <rect x="344.25" y="306" width="19.125" height="19.125" />
    <rect x="191.25" y="363.375" width="133.875" height="76.5" />
    <path d="M344.25,133.875h95.625L325.125,0v114.75C325.125,126.225,334.688,133.875,344.25,133.875z" />
    <rect x="344.25" y="267.75" width="19.125" height="19.125" />
    <rect x="191.25" y="267.75" width="133.875" height="76.5" />
    <path
      d="M306,114.75V0H114.75C93.712,0,76.5,17.212,76.5,38.25v439.875c0,21.037,17.212,38.25,38.25,38.25h286.875
			c21.037,0,38.25-17.213,38.25-38.25V153H344.25C323.213,153,306,135.788,306,114.75z M382.5,459H133.875V248.625H382.5V459z"
    />
    <rect x="153" y="420.75" width="19.125" height="19.125" />
    <rect x="153" y="267.75" width="19.125" height="19.125" />
    <rect x="153" y="382.5" width="19.125" height="19.125" />
    <rect x="153" y="306" width="19.125" height="19.125" />
    <rect x="153" y="344.25" width="19.125" height="19.125" />
  </SvgIcon>
));

export { FileTypeVideo };

import { assign, sortBy, uniqBy } from 'lodash';
import { Middleware, Dispatch } from 'redux';

import { ActionTypes, IMentionedListPage, IMentionedListPageAction } from './models';

const processSocialAccounts: Middleware<unknown, IMentionedListPage> = () => (
  next: Dispatch<IMentionedListPageAction>,
) => (action: IMentionedListPageAction) => {
  if (action.type === ActionTypes.SET_SOCIAL_ACCOUNTS) {
    const { socialAccounts } = action.payload;
    const sortedSocialAccounts = sortBy(uniqBy(socialAccounts, 'id'), (socialAccount) => {
      if (socialAccount.relevant_post) {
        return -socialAccount.relevant_post.created_ts;
      }

      return 0;
    });

    action.payload = assign(
      {},
      {
        ...action.payload,
        socialAccounts: sortedSocialAccounts,
      },
    );
  }

  return next(action);
};

export default [processSocialAccounts];

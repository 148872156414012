import * as React from 'react';
import cx from 'classnames';
import { bind, clone, isArray, isEmpty, isNil, map, isFunction, find } from 'lodash';

import { CreatorTile, ICreatorTileProps } from 'src/widgets/CreatorTile';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { MasonryGrid } from 'src/widgets/MasonryGrid';
import { Overlay } from 'src/widgets/Overlay';
import { CreatorDetail } from 'src/common/CreatorDetail';

import { IOrganization } from 'src/common/models/organization';
import { ICampaign } from 'src/common/models/campaign';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';

import endpoints from 'src/common/config/endpoints';
import { hasFeature } from 'src/common/utils/organizationHasFeature';
import { ErrorBoundary } from 'src/utils';

import styles from './CreatorDetailOverlay.scss';

interface IProps {
  className?: string;
  socialAccount: ISocialAccount;
  /**
   * If provided with an empty socialAccount, fetch details
   * See fetchDetailedSocialAccount
   */
  socialAccountId?: number;

  show: boolean;
  onRequestClose();

  header?: JSX.Element;
  footer?: JSX.Element;

  // used for CreatorDetail
  org?: IOrganization;
  // load detail?
  campaign?: ICampaign;
  apiEndpoint?: string;
  loadDetail?: boolean;
  loadRelated?: boolean;
  // whether to show favorite list button or not
  allowFavorite?: boolean;

  // related accounts
  favoriteLists?: IFavoriteList[];
  isQa?: boolean;

  hideCreator?(accountId: number);
  sendOffer?(accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage?(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  reportAsIncorrect?(accountName: string);
  inviteToCampaign?(socialAccount: ISocialAccount);
  addToFavoriteList?(accountId: number, listId: number);
  createFavoriteList?(name: string);

  additionalSocialAccounts?: ISocialAccount[];
  selfServeExperiment: boolean;
  showCreateFeatures?: boolean;
}
type TDefaultProp =
  | 'addToFavoriteList'
  | 'allowFavorite'
  | 'apiEndpoint'
  | 'campaign'
  | 'createFavoriteList'
  | 'favoriteLists'
  | 'footer'
  | 'goToManage'
  | 'header'
  | 'hideCreator'
  | 'inviteToCampaign'
  | 'isQa'
  | 'loadDetail'
  | 'loadRelated'
  | 'org'
  | 'reportAsIncorrect'
  | 'sendOffer'
  | 'additionalSocialAccounts'
  | 'selfServeExperiment'
  | 'showCreateFeatures';

interface IState {
  socialAccount: ISocialAccount;

  detailedSocialAccount: ISocialAccount;
  relatedSocialAccounts: ISocialAccount[];

  socialAccountChanged: boolean;
  isFetchingDetail: boolean;
  detailsFetched: boolean;
  tabChanged: boolean;
  isFetchingRelated: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class CreatorDetailOverlay extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    header: null,
    footer: null,

    loadDetail: false,
    allowFavorite: true,
    showCreateFeatures: true,

    hideCreator: () => undefined,
    sendOffer: () => undefined,
    goToManage: () => undefined,
    reportAsIncorrect: () => undefined,
    addToFavoriteList: () => undefined,
    createFavoriteList: () => undefined,
    // set inviteToCampaign as null to hide the invite button in CreatorDetail
    inviteToCampaign: null,
    additionalSocialAccounts: [],
    selfServeExperiment: false,
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    if (props.loadDetail || props.loadRelated) {
      if (!props.apiEndpoint) {
        throw new Error(
          `'apiEndpoint' is needed for fetching detail/related accounts.`,
        );
      }
    }

    this.state = {
      socialAccount: props.socialAccount,
      detailsFetched: false,
      tabChanged: false,
      detailedSocialAccount: null,
      relatedSocialAccounts: [],
      socialAccountChanged: false,
      isFetchingDetail: false,
      isFetchingRelated: false,
    };
    this.updateId = this.updateId.bind(this)

  }

  public updateId(id: number) {
    const acc = find(
      this.props.additionalSocialAccounts,
      (socialAccount) => socialAccount.id === id,
    );
    this.setState({
      socialAccount: acc,
      detailedSocialAccount: null,
      detailsFetched: false,
      tabChanged: true,
    })
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { show, socialAccount, socialAccountId } = nextProps;

    const id = (socialAccount && socialAccount.id) || socialAccountId;
    // check if should load detail
    if (prevState.detailsFetched) {
      return {
        socialAccountChanged: false,
        detailsFetched: false,
      };
    }
    if (show) {
      return {
        socialAccount: prevState.socialAccount || socialAccount,
        socialAccountChanged: !prevState.socialAccount || prevState.socialAccount.id !== id || prevState.tabChanged,
        tabChanged: false,
      };
    } else {
      // if it's hiding, do nothing
      return null;
    }
  }

  /**
   * @inheritdoc
   */
  public async componentDidUpdate() {
    const { loadDetail, loadRelated } = this.props;
    const { socialAccountChanged, isFetchingDetail, isFetchingRelated, detailsFetched } = this.state;

    if (socialAccountChanged) {
      // fetch detail if indicated
      if (this.props.show && loadDetail && !isFetchingDetail && !detailsFetched) {
        this.setState({
          isFetchingDetail: true,
        });

        const show = this.props.show // this.props.show can change while awaiting the call on the next line
        const socialAccount = await this.fetchDetailedSocialAccount();

        if (show) {
          this.setState({
            isFetchingDetail: false,
            detailsFetched: true,
            // TODO: figure out why /social_account endpoint is returning an array in this case
            detailedSocialAccount: isArray(socialAccount) ? socialAccount[0] : socialAccount,
          });
        }
      }

      // fetch related
      if (this.props.show && loadRelated && !isFetchingRelated) {
        this.setState({
          isFetchingRelated: true,
        });
        const relatedSocialAccounts = await this.fetchRelatedSocialAccount();
        if (this.props.show) {
          this.setState({
            detailsFetched: true,
            isFetchingRelated: false,
            relatedSocialAccounts,
          });
        }
      }

      // fetch related
      if (this.props.show && loadRelated && !isFetchingRelated) {
        this.setState({
          isFetchingRelated: true,
        });
        const relatedSocialAccounts = await this.fetchRelatedSocialAccount();
        if (this.props.show) {
          this.setState({
            detailsFetched: true,
            isFetchingRelated: false,
            relatedSocialAccounts,
          });
        }
      }
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      addToFavoriteList,
      allowFavorite,
      campaign,
      className,
      createFavoriteList,
      favoriteLists,
      footer,
      header,
      hideCreator,
      inviteToCampaign,
      org,
      sendOffer,
      show,
      additionalSocialAccounts,
      showCreateFeatures = true,
      selfServeExperiment,
    } = this.props;
    const { isFetchingDetail } = this.state;

    // merge detailed account with props.socialAccount
    // so that `invite` field is picked up
    const socialAccount = this.state.detailedSocialAccount
      ? {
        ...this.state.socialAccount,
        ...this.state.detailedSocialAccount,
      }
      : this.state.socialAccount;

    return (
      <Overlay
        show={show && !isNil(socialAccount)}
        onRequestClose={this.onRequestClose}
        closeOnBackdropClick={true}
        contentClassName={styles.content}
        className={cx(styles.CreatorDetailOverlay, className)}
      >
        {socialAccount && (
          <ErrorBoundary>
            <CreatorDetail
              addToFavoriteList={bind(addToFavoriteList, this, socialAccount.id)}
              allowFavorite={allowFavorite}
              campaign={campaign}
              className={styles.creatorDetail}
              createFavoriteList={createFavoriteList}
              favoriteLists={favoriteLists}
              footer={footer}
              header={header}
              updateSelectedSocialAccountID={this.updateId}
              hideCreator={bind(hideCreator, this, socialAccount.id)}
              inviteToCampaign={inviteToCampaign}
              isFetchingDetail={isFetchingDetail}
              org={org}
              sendOffer={isFunction(sendOffer) ? bind(sendOffer, this, socialAccount.id) : sendOffer}
              socialAccount={socialAccount}
              additionalSocialAccounts={additionalSocialAccounts}
              showCreateFeatures={showCreateFeatures}
              selfServeExperiment={selfServeExperiment}
            />
          </ErrorBoundary>
        )}
        {this.renderRelated()}
      </Overlay>
    );
  }

  /**
   * @private
   * Renders the related accounts.
   *
   * @return {JSX}
   */
  private renderRelated = () => {
    const {
      addToFavoriteList,
      apiEndpoint,
      campaign,
      createFavoriteList,
      favoriteLists,
      goToManage,
      isQa,
      org,
      reportAsIncorrect,
      sendOffer,
    } = this.props;
    const { isFetchingDetail, socialAccount, isFetchingRelated, relatedSocialAccounts } = this.state;

    const allowFavorite = hasFeature(org, 'advanced_connect');

    if (
      isFetchingRelated ||
      (!socialAccount && isFetchingDetail) // Wait for detail to be fetched too
    ) {
      return <LoadSpinner className={styles.container} />;
    }
    if (isEmpty(relatedSocialAccounts)) {
      return null;
    }

    const itemProps: ICreatorTileProps[] = map(relatedSocialAccounts, (socialAccount) => {
      return {
        key: socialAccount.id,
        apiEndpoint,
        allowFavorite,
        isQa,
        campaign,
        socialAccount,
        favoriteLists,
        inviteToCampaign: bind(this.inviteCreatorToCampaign, this, socialAccount),
        addToFavoriteList: bind(addToFavoriteList, this, socialAccount.id),
        createFavoriteList,
        sendOffer: bind(sendOffer, this, socialAccount.id),
        goToManage: bind(goToManage, this, socialAccount.relation_id),
        reportAsIncorrect: bind(reportAsIncorrect, this, socialAccount.name),
        showCreateFeatures: !isNil(org)
      };
    });

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          Creators mentioning {socialAccount.display_name || socialAccount.username}
        </div>
        <MasonryGrid
          columnWidth={386}
          itemComponent={CreatorTile}
          itemProps={itemProps}
          defaultItemHeight={CreatorTile.defaultHeight}
        />
      </div>
    );
  };

  /**
   * @private
   * Requests close overlay and unset detailed social account.
   */
  private onRequestClose = () => {
    const { onRequestClose } = this.props;

    this.setState({
      socialAccount: null,
      detailedSocialAccount: null,
      relatedSocialAccounts: [],
      isFetchingDetail: false,
      isFetchingRelated: false,
    });

    onRequestClose();
  };

  /**
   * @private
   * Invites a related creator to campaign, mark it as invited if succeeded.
   */
  private inviteCreatorToCampaign = (socialAccount: ISocialAccount) => {
    const { inviteToCampaign } = this.props;
    const { relatedSocialAccounts } = this.state;

    return inviteToCampaign(socialAccount).then(() => {
      const socialAccounts = clone(relatedSocialAccounts);
      const socialAccountIndex = socialAccounts.findIndex(
        (account) => account.id === socialAccount.id,
      );
      socialAccounts[socialAccountIndex].invite = {
        approved: true,
      };

      this.setState({
        relatedSocialAccounts: socialAccounts,
      });
    });
  };

  /**
   * @private
   * Fetches the detailed social account.
   *
   * @return {Promise}
   */
  private fetchDetailedSocialAccount = () =>
    new Promise<ISocialAccount>(async (resolve, reject) => {
      const { socialAccountId, campaign, apiEndpoint } = this.props;
      const { socialAccount } = this.state;

      const id = (socialAccount && socialAccount.id) || socialAccountId;
      if (isNil(id)) {
        reject('Cannot retrieve social account id');
      }

      try {
        let campaign_param = '';
        if (campaign) {
          campaign_param = `&campaign_id=${campaign.id}`;
        }
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.socialAccountEndpoint}?page=1&social_account_id=${id}${campaign_param}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          },
        );

        const json = await resp.json();
        if (json.status && json.status.code === 200) {
          resolve(json.data.data);
        } else {
          reject();
        }
      } catch (err) {
        console.error(err);
        reject();
      }
    });

  /**
   * @private
   * Fetches the detailed social account.
   *
   * @return {Promise}
   */
  private fetchRelatedSocialAccount = () =>
    new Promise<ISocialAccount[]>(async (resolve, reject) => {
      const { socialAccount, socialAccountId, campaign, apiEndpoint } = this.props;

      const id = (socialAccount && socialAccount.id) || socialAccountId;
      if (isNil(id)) {
        reject('Cannot retrieve social account id');
      }

      try {
        let campaign_param = '';
        if (campaign) {
          campaign_param = `&campaign_id=${campaign.id}`;
        }
        const resp = await fetch(
          `${apiEndpoint}/${endpoints.socialAccountEndpoint}?page=1&related_account_id=${id}${campaign_param}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          },
        );

        const json = await resp.json();
        if (json.status && json.status.code === 200) {
          resolve(json.data.data);
        } else {
          reject();
        }
      } catch (err) {
        console.error(err);
        reject();
      }
    });
}

import { assign, clone } from 'lodash';
import { IFavoriteListPage, IFavoriteListPageAction } from './favoriteListPageModel';

const reducer = (state: IFavoriteListPage, action: IFavoriteListPageAction): IFavoriteListPage => {
  switch (action.type) {
    case '@favoriteListPage/SET_FAVORITE_LISTS': {
      const { favoriteLists } = action.payload;

      return assign({}, state, {
        favoriteLists,
      });
    }

    case '@favoriteListPage/ADD_FAVORITE_LIST': {
      const { favoriteList } = action.payload;
      const { favoriteLists } = state;

      return assign({}, state, {
        favoriteLists: [...favoriteLists, favoriteList],
      });
    }

    case '@favoriteListPage/UPDATE_FAVORITE_LIST': {
      const { id, favoriteList } = action.payload;
      const { favoriteLists } = state;
      const index = favoriteLists.findIndex((favoriteList) => favoriteList.id === id);

      return assign({}, state, {
        favoriteLists: [
          ...favoriteLists.slice(0, index),
          {
            ...favoriteList,
          },
          ...favoriteLists.slice(index + 1),
        ],
      });
    }

    case '@favoriteListPage/REMOVE_FAVORITE_LIST': {
      const { id } = action.payload;
      const { favoriteLists } = state;
      const index = favoriteLists.findIndex((favoriteList) => favoriteList.id === id);

      return assign({}, state, {
        favoriteLists: [...favoriteLists.slice(0, index), ...favoriteLists.slice(index + 1)],
      });
    }

    case '@favoriteListPage/SET_SOCIAL_ACCOUNTS': {
      const { socialAccounts } = action.payload;

      return assign({}, state, {
        socialAccounts,
      });
    }

    case '@favoriteListPage/SET_IS_FETCHING_FAVORITE_LISTS': {
      const { isFetchingFavoriteLists } = action.payload;

      return assign({}, state, {
        isFetchingFavoriteLists,
      });
    }

    case '@favoriteListPage/SET_IS_EDITING_FAVORITE_LIST': {
      const { isEditingFavoriteList } = action.payload;

      return assign({}, state, {
        isEditingFavoriteList,
      });
    }

    case '@favoriteListPage/SET_SELECTED_LIST_ID': {
      const { id } = action.payload;

      return assign({}, state, {
        selectedListId: id,
      });
    }

    case '@favoriteListPage/INVITED_TO_CAMPAIGN': {
      const { accountIds } = action.payload;
      const { socialAccounts } = state;

      // update invite field
      const newSocialAccounts = clone(socialAccounts).map((socialAccount) => {
        if (!accountIds.includes(socialAccount.id)) {
          return socialAccount;
        }

        return {
          ...socialAccount,
          invite: {
            ...socialAccount.invite,
            approved: true,
          },
        };
      });

      return assign({}, state, {
        socialAccounts: newSocialAccounts,
      });
    }

    case '@favoriteListPage/REMOVE_FROM_FAVORITE_LIST': {
      const { elementIds } = action.payload;
      const { socialAccounts } = state;

      const newSocialAccounts = socialAccounts.filter(
        (socialAccount) =>
          socialAccount.favorite_element &&
          !elementIds.includes(socialAccount.favorite_element.id),
      );

      return assign({}, state, {
        socialAccounts: newSocialAccounts,
      });
    }

    default:
      return state;
  }
};

export default reducer;

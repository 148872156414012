/**
 * Removes emails from given string.
 *
 * @param {String} str the raw string.
 *
 * @return {String}
 */
export default function removeEmailsFromStr(str: string) {
  if (!str) {
    return str;
  }

  return str.replace(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/gi, '**Removed**');
}

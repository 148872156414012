import * as React from 'react';
import { chain, defaultsDeep, isEmpty, last } from 'lodash';

import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { FetchContextProvider } from 'src/utils/context/FetchContext';

import { Action, initialState, IState, Tab } from '../models';
import { reducer } from './reducer';
import { ISocialProfileOverlayProps } from '../SocialProfileOverlay';

const { createContext, useReducer } = React;

type TProps = Partial<ISocialProfileOverlayProps>;

export interface ISocialProfileContext extends IState, Omit<TProps, 'socialAccount'> {
  /**
   * null means we don't know yet -- data is still loading
   * no need for setter as this is implied from `socialAccount` and `isLoadingDetail`
   */
  hasData: boolean | null;

  /**
   * We only know about the username and network
   * Show the user that we are unable to pull data
   */
  noDataPulled: boolean;

  /**
   * Social account data
   */
  network: TNetworkIdentifier;
  setSocialAccount: (socialAccount: ISocialAccount) => void;
  setDetailedSocialAccount: (socialAccount: ISocialAccount) => void;
  setFetchingDetailForId: (id: ISocialAccount['id']) => void;

  /**
   * Interface
   */
  setActiveTab: (tab: Tab) => void;
  setIsInviting: (isInviting: boolean) => void;

  /**
   * Navigation history
   */
  clearHistory: () => void;
  peekHistory: () => ISocialAccount;
  pushHistory: (socialAccount: ISocialAccount) => void;
  popHistory: () => ISocialAccount;

  /**
   * Util
   */
  reset: () => void;
}

export const SocialProfileContext = createContext<ISocialProfileContext>(null);

export const SocialProfileContextProvider: React.FC<TProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const socialAccount: ISocialAccount = defaultsDeep(state.detailedSocialAccount, state.socialAccount);
  const hasData = !isEmpty(socialAccount);
  const noDataPulled = chain(socialAccount)
    .keys()
    .sort()
    .isEqual(['network_identifier', 'username'])
    .value();
  const value: ISocialProfileContext = {
    ...props,
    ...state,

    socialAccount,
    hasData,
    noDataPulled,
    network: hasData ? socialAccount.network_identifier : null,

    setSocialAccount: (socialAccount: ISocialAccount) => (
      dispatch({
        type: Action.SET_ACCOUNT,
        socialAccount,
      })
    ),
    setDetailedSocialAccount: (socialAccount: ISocialAccount) => (
      dispatch({
        type: Action.SET_DETAILED_ACCOUNT,
        socialAccount,
      })
    ),
    setFetchingDetailForId: (id: ISocialAccount['id']) => (
      dispatch({
        type: Action.SET_FETCHING_FOR_ID,
        id,
      })
    ),

    setActiveTab: (tab: Tab) => (
      dispatch({
        type: Action.SET_TAB,
        tab,
      })
    ),
    setIsInviting: (isInviting: boolean) => (
      dispatch({
        type: Action.SET_INVITING,
        isInviting,
      })
    ),

    history: state.history,
    clearHistory: () => dispatch({ type: Action.HISTORY_CLEAR }),
    peekHistory: (): ISocialAccount => last(state.history) || null,
    popHistory: (): ISocialAccount => {
      const socialAccount = last(state.history) || null;
      dispatch({ type: Action.HISTORY_POP });
      return socialAccount;
    },
    pushHistory: (socialAccount: ISocialAccount) => (
      dispatch({
        type: Action.HISTORY_PUSH,
        socialAccount,
      })
    ),

    reset: () => dispatch({ type: Action.RESET }),
  };

  return (
    <FetchContextProvider>
      <SocialProfileContext.Provider value={value}>
        {props.children}
      </SocialProfileContext.Provider>
    </FetchContextProvider>
  );
};

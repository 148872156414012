import * as React from 'react';
import cx from 'classnames';
import { isArray } from 'lodash';

import { ListCard } from './ListCard';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './AudienceInterestsCard.scss';

export const AudienceInterestsCard: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    socialAccount,
    hasData,
  } = useSocialProfileContext();

  const audienceInterests = hasData && isArray(socialAccount.interests)
    ? socialAccount.interests
    : [];

  return (
    <ListCard
      className={cx(className, styles.AudienceInterestsCard)}
      flow='inline'
      items={audienceInterests}
      itemsClass={styles.items}
      title='Audience Interests'
    />
  );
};

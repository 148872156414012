import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ErrorBoundary } from 'src/utils';

import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';

import { initialState } from './redux/models';
import reducer from './redux/reducer';
import pageMiddlewares from './redux/middlewares';

import InvitedListPage, { IOwnProps } from './InvitedListPage';

export interface IInvitedListPageWrapperProps extends IOwnProps {
  // api endpoint
  apiEndpoint: string;

  isQa: boolean;
  brandId: string;
  org: IOrganization;
  campaign: ICampaign;
}

const { useMemo } = React;

/**
 * @type {React.FunctionComponent}
 */
const InvitedListPageWrapper: React.FunctionComponent<IInvitedListPageWrapperProps> = React.memo((props) => {
  const { apiEndpoint, isQa, brandId, campaign, org, ...restProps } = props;

  const store = useMemo(() => {
    const middlewares = [thunk, ...pageMiddlewares];

    // only use redux-logger in development
    if (process.env.NODE_ENV !== 'production') {
      const createLogger = require('redux-logger').createLogger;

      middlewares.push(createLogger());
    }

    return createStore(
      reducer,
      {
        ...initialState,
        apiEndpoint,
        isQa,
        brandId,
        campaign,
        org,
      },
      compose(applyMiddleware(...middlewares)),
    );
  }, [apiEndpoint, isQa, brandId, campaign, org]);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <InvitedListPage {...restProps} />
      </ErrorBoundary>
    </Provider>
  );
});

export default InvitedListPageWrapper;

import { each, map, isEmpty, compact, find } from 'lodash';

import {
  IAdvertiserPublisherRelation,
  IProjectMetadata,
} from 'src/common/models/advertiserPublisherRelation';

export const projectMetadataGuessForCampaignStage = (
  relation: IAdvertiserPublisherRelation,
  campaignId: number,
  stageIdentifier: string,
): IProjectMetadata => {
  const projectIds = projectIdsForCampaignStage(relation, campaignId, stageIdentifier);
  const projectIdGuess = projectIdGuessForProjectIds(relation, projectIds);

  return relation.project_metadata[projectIdGuess];
};

export const allProjectMetadataForCampaignStage = (
  relation: IAdvertiserPublisherRelation,
  campaignId: number,
  stageIdentifier: string,
): IProjectMetadata[] => {
  const projectIds = projectIdsForCampaignStage(relation, campaignId, stageIdentifier);

  return compact(map(projectIds, (id) => relation.project_metadata[id]));
};

export const stageGuessForCampaignStage = (
  relation: IAdvertiserPublisherRelation,
  campaignId: number,
  stageIdentifier: string,
) => {
  const projectIds = projectIdsForCampaignStage(relation, campaignId, stageIdentifier);
  const projectIdGuess = projectIdGuessForProjectIds(relation, projectIds);

  for (const stage of relation.stages) {
    if (stage.project_id === projectIdGuess) {
      return stage;
    }
  }

  return null;
};

const projectIdsForCampaignStage = (
  relation: IAdvertiserPublisherRelation,
  campaignId: number,
  stageIdentifier: string,
): number[] => {
  const projectIds = [];

  each(relation.stages, (stage) => {
    if (campaignId && stage.campaign_id !== campaignId) {
      return;
    }
    if (stageIdentifier && stage.stage_identifier !== stageIdentifier) {
      return;
    }

    projectIds.push(stage.project_id);
  });

  return projectIds;
};

const projectIdGuessForProjectIds = (
  relation: IAdvertiserPublisherRelation,
  projectIds: number[],
): number => {
  if (isEmpty(projectIds)) {
    return null;
  }

  const sortedProjectIds = [...projectIds].sort((projectIdA, projectIdB) => {
    if (relation.project_metadata && relation.project_metadata[projectIdA] && relation.project_metadata[projectIdB]) {
      const projectAMetadata = relation.project_metadata[projectIdA];
      const projectBMetadata = relation.project_metadata[projectIdB];
      const projectATs = projectAMetadata.proposal_created_ts ? projectAMetadata.proposal_created_ts : 0;
      const projectBTs = projectBMetadata.proposal_created_ts ? projectBMetadata.proposal_created_ts : 0;
      const projectAStage = find(relation.stages, ['project_id', projectIdA]);
      const projectBStage = find(relation.stages, ['project_id', projectIdB]);
      const isProjectAInProgress = ['dismissed', 'complete'].includes(projectAStage.stage_identifier);
      const isProjectBInProgress = ['dismissed', 'complete'].includes(projectBStage.stage_identifier);

      if (isProjectAInProgress && !isProjectBInProgress) {
        return -1;
      } else if (!isProjectAInProgress && isProjectBInProgress) {
        return 1;
      } else {
        if (projectATs > projectBTs) {
          return -1;
        } else if (projectATs < projectBTs) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  });

  return sortedProjectIds[0];
};

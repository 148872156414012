import { IContentReview } from 'src/common/models/contentReview';
import { IContentInfo } from '../model';

import productDescription from 'src/common/utils/productDescription';

/**
 * @private
 * Creates content info object based on given content review object.
 *
 * @param {IContentReview} contentReview the content review object.
 *
 * @return {IContentInfo}
 */
export default (contentReview: IContentReview): IContentInfo => {
  if (!contentReview.content) {
    return null;
  }

  return {
    id: contentReview.id,
    approved:
      ['ACCEPTED', 'COMPLETED_WITHOUT_PAYMENT', 'COMPLETED'].includes(contentReview.state),
    rejected: contentReview.state === 'REJECTED',
    projectId: contentReview.project_id,
    relationId: contentReview.relation_id,
    iteration: contentReview.iteration,
    clientFeedbacks: contentReview.client_feedbacks,
    campaign: contentReview.campaign_name,
    postType: contentReview.product.post_type,
    creatorName: contentReview.product.account_name,
    productDescription: contentReview.collapse
      ? contentReview.product.product_description
      : productDescription(1, contentReview.product.post_type),
    dueTs: contentReview.automatic_approval_expire_time_ts,
    caption: contentReview.content.caption,
    guidelines: contentReview.product.checkoff_guidelines,
    brandGuidelineCheckoff: contentReview.product.brand_guideline_checkoff,
    creatorGuidelineCheckoff: contentReview.product.creator_guideline_checkoff,
    contents: contentReview.content.media || contentReview.content.saved_media_list,
    previewMap: contentReview.content.preview_map || {},
    contentClass: contentReview.content.content_class,
    link: contentReview.content.link,
    imageLink: contentReview.content.image_link,
  };
};

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { startCase } from 'lodash';

import { SearchIcon } from 'src/icons';
import { Image } from 'src/widgets/Image';

import { ILicensedContent } from 'src/common/models/licensedContent';
import { TDataField, dataFieldConfig } from './models/channel';

import getLicensedContentImage from 'src/common/utils/getLicensedContentImage';

import styles from './ContentTile.scss';

const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

interface IProps {
  selectedField: TDataField;
  content: ILicensedContent;
  onContentClick(id: string);
  onSearchClick(imageUrl: string);

  onImageError?();
  classNames?: string[];
}
type TDefaultProp = 'onImageError' | 'classNames';
interface IState {
  hover: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ContentTile extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    onImageError: () => undefined,
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, selectedField, classNames, onImageError } = this.props;
    const { hover } = this.state;
    const imageUrl = getLicensedContentImage(content);

    return (
      <div
        className={cx(classNames.concat(styles.ContentTile), {
          [styles.hover]: hover,
        })}
        onClick={this.handleContentClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Image className={styles.image} src={imageUrl} onError={onImageError} />
        <div className={styles.overlay}>
          {imageUrl && (
            <SearchIcon onClick={this.handleSearchClick} className={styles.search} />
          )}
          <div className={styles.created}>
            <div className={styles.text}>Created by</div>
            <div className={styles.avatarWrapper}>
              <Image
                className={styles.avatar}
                src={content.publisher && content.publisher.profile_picture}
                fallbackSrc={defaultAvatar}
              />
            </div>
            <div className={cx(styles.text, styles.author)}>
              {content.publisher ? startCase(content.publisher.username) : 'Unknown'}
            </div>
          </div>
          <div className={styles.amount}>
            <div className={styles.value}>
              {numeral(content.metrics[selectedField]).format(
                dataFieldConfig[selectedField].isDollar ? '$0,0' : '0,0',
              )}
            </div>
            <div className={styles.type}>{dataFieldConfig[selectedField].displayName}</div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * @private
   * Handler for 'mouseenter' event.
   */
  private handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  /**
   * @private
   * Handler for 'mouseleave' event.
   */
  private handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  /**
   * @private
   * Handler for when content is clicked.
   *
   * @param {React.MouseEvent<SVGElement>} event the event object.
   */
  private handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { onContentClick, content } = this.props;

    event.stopPropagation();

    onContentClick(content.id);
  };

  /**
   * @private
   * Handler for when search icon is clicked.
   *
   * @param {React.MouseEvent<SVGElement>} event the event object.
   */
  private handleSearchClick = (event: React.MouseEvent<SVGElement>) => {
    const { onSearchClick, content } = this.props;

    event.stopPropagation();

    onSearchClick(getLicensedContentImage(content));
  };
}

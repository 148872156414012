import * as React from 'react';
import cx from 'classnames';
import { map, capitalize } from 'lodash';

import { PieChart, BarChart } from 'src/widgets/charts';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { Notice } from 'src/widgets/Notice';

import endpoints from 'src/common/config/endpoints';

import { useAnalyze } from '../../../useAnalyze';
import { useAnalyzeCompare } from '../../../useAnalyzeCompare';
import {
  useFetchSummaryData,
  useFetchSummaryDataCompare,
} from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

const { useState, useMemo } = React;
import styles from './AudienceDemoSection.scss';

const colors = ['#FFE9D9', '#EA92AF'];
export interface IAudienceDemoData {
  gender: {
    male: number;
    female: number;
  };
  age: {
    [key: string]: number;
  };
}
type TDisplayMode = 'age' | 'gender';
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const AudienceDemoSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const { filters: compareFilters, isComparing } = useAnalyzeCompare();
    const { loading, data, error } = useFetchSummaryData<IAudienceDemoData>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-demo`,
      isAspirex,
      filters
    );

    const {
      data: dataCompare,
      loading: loadingCompare,
    } = useFetchSummaryDataCompare<IAudienceDemoData>(
      isComparing,
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-demo`,
      isAspirex,
      compareFilters
    );

    const [displayMode, setDisplayMode] = useState<TDisplayMode>('gender');
    const records = useMemo(
      () =>
        map(data && data[displayMode], (count, label) => ({
          value: count,
          label: label,
        })),
      [data, displayMode]
    );
    const recordsCompare = useMemo(
      () =>
        map(dataCompare && dataCompare[displayMode], (count, label) => ({
          value: count,
          label: label,
        })),
      [dataCompare, displayMode]
    );
    const onDisplayModeSelect = displayMode => setDisplayMode(displayMode);

    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    const dataReturnedEmpty =
      data && data.gender.male === null && data.gender.male === null;

    return (
      <div className={cx(styles.AudienceDemoSection, props.className)}>
        {(loading || loadingCompare) && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading || loadingCompare}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>Audience {capitalize(displayMode)}</h3>
              </div>
              <div className={styles.select}>
                {!isComparing && (
                  <div
                    className={cx(styles.item, {
                      [styles.active]: displayMode === 'age',
                    })}
                    onClick={() => onDisplayModeSelect('age')}
                  >
                    Age
                  </div>
                )}
                <div
                  className={cx(styles.item, {
                    [styles.active]: displayMode === 'gender',
                  })}
                  onClick={() => onDisplayModeSelect('gender')}
                >
                  Gender
                </div>
              </div>
            </div>
            <div className={styles.main}>
              {displayMode === 'gender' && (
                <PieChart
                  showTotal={false}
                  innerRadius={0}
                  data={records}
                  vertical={true}
                />
              )}
              {displayMode === 'gender' && isComparing && (
                <PieChart
                  showTotal={false}
                  innerRadius={0}
                  data={recordsCompare.map((record, idx) => ({
                    ...record,
                    color: colors[idx],
                  }))}
                  vertical={true}
                />
              )}
              {displayMode === 'age' && (
                <BarChart data={records} alignment="vertical" />
              )}
            </div>
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

AudienceDemoSection.defaultProps = {
  className: null,
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const TrashcanIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M20.34,4.88h-4.16V4.2c0-1.21-0.99-2.2-2.2-2.2h-3.96c-1.21,0-2.2,0.99-2.2,2.2v0.68H3.66
			c-0.31,0-0.56,0.25-0.56,0.56c0,0.31,0.25,0.56,0.56,0.56h1v13.04C4.66,20.67,6,22,7.63,22h8.73c1.64,0,2.97-1.33,2.97-2.97V5.99
			h1c0.31,0,0.56-0.25,0.56-0.56C20.89,5.12,20.65,4.88,20.34,4.88z M8.93,4.2c0-0.6,0.49-1.09,1.09-1.09h3.96
			c0.6,0,1.09,0.49,1.09,1.09v0.68H8.93V4.2z M18.23,19.03c0,1.02-0.83,1.86-1.86,1.86H7.63c-1.02,0-1.86-0.83-1.86-1.86V5.99h12.45
			L18.23,19.03L18.23,19.03z"
    />
    <path
      d="M12,18.9c0.31,0,0.56-0.25,0.56-0.56V8.53c0-0.31-0.25-0.56-0.56-0.56c-0.31,0-0.56,0.25-0.56,0.56v9.81
			C11.44,18.65,11.69,18.9,12,18.9z"
    />
    <path
      d="M8.38,18.29c0.31,0,0.56-0.25,0.56-0.56V9.14c0-0.31-0.25-0.56-0.56-0.56c-0.31,0-0.56,0.25-0.56,0.56v8.59
			C7.82,18.04,8.07,18.29,8.38,18.29z"
    />
    <path
      d="M15.62,18.29c0.31,0,0.56-0.25,0.56-0.56V9.14c0-0.31-0.25-0.56-0.56-0.56c-0.31,0-0.56,0.25-0.56,0.56v8.59
			C15.07,18.04,15.31,18.29,15.62,18.29z"
    />
  </SvgIcon>
));

export { TrashcanIcon };

import { size, slice } from 'lodash';

export const partitionArrayIntoEqualSubArrays = (array: any[], numberOfSubArrays: number) => {
  const len = size(array);
  const r = len % numberOfSubArrays;
  const k = (len - r) / numberOfSubArrays;
  const result = [];
  let remainder = array;
  for (let i = 0; i < r; i++) {
    result.push(slice(remainder, 0, k + 1));
    remainder = slice(remainder, k + 1);
  }
  for (let i = r; i < numberOfSubArrays; i++) {
    result.push(slice(remainder, 0, k));
    remainder = slice(remainder, k);
  }
  return result;
};

import * as React from 'react';


import PlacesInput, { TPlace } from './PlacesInput';

interface IProps {
  className?: string;
  onChange(newLocations: TPlace[]);

  locations?: TPlace[];
  defaultLocations?: TPlace[];
  placeholder?: string;
  debounceWait?: number;
}

const OPTIONS = {
  types: ['(regions)'],
};

const formatter = (prediction: google.maps.places.AutocompletePrediction): TPlace => ({
  label: prediction.description,
  value: prediction.place_id,
});

export const LocationInput: React.FunctionComponent<IProps> = (props) => {
  return (
    <PlacesInput
      className={props.className}
      places={props.locations}
      defaultPlaces={props.defaultLocations}
      placeholder={props.placeholder}
      onChange={props.onChange}
      emptyOptionsMessage="Start typing location name"
      options={OPTIONS}
      formatPrediction={formatter}
      debounceWait={props.debounceWait}
    />
  );
};

LocationInput.defaultProps = {
  placeholder: 'Enter a location',
};

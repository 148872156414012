import * as React from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames';
import { map, includes, lowerCase, join } from 'lodash';

import endpoints from 'src/common/config/endpoints';
import { SearchIcon } from 'src/icons';
import { Input } from 'src/widgets/Input';
import { LoadSpinner } from 'src/widgets/LoadSpinner';
import { ISocialPost, PostCard } from 'src/widgets/PostCard';
import { Select } from 'src/widgets/Select';

import { useAnalyze } from '../useAnalyze';
import { useFetchSummaryData } from '../useFetchAnalyzeData';

import styles from './PostsView.scss';

enum SortFields {
  postDate = 'Post Date',
  totalMediaValue = 'Total Media Value',
}

const SORT_FIELD_OPTIONS = [
  {
    label: SortFields.postDate,
    value: 'postDate',
  },
  {
    label: SortFields.totalMediaValue,
    value: 'totalMediaValue',
  }
];

const SORT_DIRECTION_OPTIONS = [
  {
    label: 'Descending',
    value: 'descending',
  },
  {
    label: 'Ascending',
    value: 'ascending',
  },
];

/**
 * @type {React.FunctionComponent}
 */
const PostsView: React.FunctionComponent<{ className?: string }> = React.memo((props) => {
  const { filters, apiEndpoint, isQa, isAspirex } = useAnalyze();

  const [isFiltering, setIsFiltering] = useState(false);
  const [posts, setPosts] = useState<ISocialPost[]>([]);

  const [searchString, setSearchString] = useState('');
  const [sortFieldIndex, setSortFieldIndex] = useState(0);
  const [sortDirectionIndex, setSortDirectionIndex] = useState(0);

  const { data, loading: isLoadingData } = useFetchSummaryData(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/posts`,
    isAspirex,
    filters,
  );

  const onSortFieldChange = (sortField) => {
    const newIndex = SORT_FIELD_OPTIONS.findIndex(
      (option) => option.value === sortField,
    );
    setSortFieldIndex(newIndex);
  };

  const onSortDirectionChange = (sortDirection) => {
    const newIndex = SORT_DIRECTION_OPTIONS.findIndex(
      (option) => option.value === sortDirection,
    );
    setSortDirectionIndex(newIndex);
  };

  useEffect(() => {
    if (data) {
      setIsFiltering(true);

      const newPosts = data.data.filter((post: ISocialPost) => {
        if (!searchString) {
          return true;
        }

        const searchTerms = lowerCase(searchString).split(/\b\s+/);

        return searchTerms.every((searchTerm) => {
          const allPostString = lowerCase(
            join(
              [
                post.social_account_name ? post.social_account_name : '',
                post.creator ? post.creator : '',
                post.post_text ? post.post_text : '',
                post.post_image_tags ? join(post.post_image_tags, ' ') : '',
              ],
              ' '
            )
          );

          return includes(allPostString, searchTerm);
        });
      });

      newPosts.sort((a, b) => {
        switch (SORT_FIELD_OPTIONS[sortFieldIndex].value) {
          case 'postDate':
            if (a.datetime_posted && !b.datetime_posted) {
              return -1;
            } else if (!a.datetime_posted && b.datetime_posted) {
              return 1;
            } else if (a.datetime_posted && b.datetime_posted) {
              return (new Date(a.datetime_posted) > new Date(b.datetime_posted)) ? -1 : 1;
            } else {
              return 0;
            }
          case 'totalMediaValue':
            if (a.tmv && !b.tmv) {
              return -1;
            } else if (!a.tmv && b.tmv) {
              return 1;
            } else if (a.tmv && b.tmv) {
              return parseFloat(a.tmv) > parseFloat(b.tmv) ? -1 : 1;
            } else {
              return 0;
            }
        }
      });

      if (SORT_DIRECTION_OPTIONS[sortDirectionIndex].value === 'ascending') {
        newPosts.reverse();
      }

      setPosts(newPosts);
      setIsFiltering(false);
    }
  }, [data, searchString, sortFieldIndex, sortDirectionIndex]);

  const isLoading = isLoadingData || isFiltering;

  return (
    <div className={cx(styles.PostsView, props.className)}>
      <div className={styles.postFilters}>
        <Input
          className={styles.searchInput}
          icon={<SearchIcon />}
          onChange={setSearchString}
          placeholder={'Search usernames, phrases, etc...'}
        />
        <Select
          className={styles.selectSort}
          options={SORT_FIELD_OPTIONS}
          onChange={onSortFieldChange}
          selectedIndex={sortFieldIndex}
          round
        />
        <Select
          className={styles.selectSort}
          options={SORT_DIRECTION_OPTIONS}
          onChange={onSortDirectionChange}
          selectedIndex={sortDirectionIndex}
          round
        />
      </div>
      {isLoading ?
        <LoadSpinner centered={true} />
        :
        <div className={styles.postsGrid}>
          {map(posts, (postData, index) => (
            <PostCard isQa={isQa} post={postData} key={index} />
          ))}
        </div>
      }
    </div>
  );
});

export default PostsView;

import * as React from 'react';

import {
  addDragSupportToElement,
  IMousePosition,
} from 'src/utils/hooks/helpers/addDragSupportToElement';

const { useState, useEffect } = React;

interface IOptions {
  throttleWait?: number;
}

/**
 * Attaches event listeners to a ref in order to get the mouse position while dragging it.
 * Can only use it inside the body of a function component.
 *
 * @param {React.RefObject<HTMLDivElement>} ref the target ref.
 * @param {{ throttleWait: number }} options options hash.
 *
 * @return [boolean, IMousePosition]
 */
export const useDragging = (
  ref: React.RefObject<Element>,
  { throttleWait }: IOptions = {},
): [boolean, IMousePosition] => {
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState<IMousePosition>({
    clientX: null,
    clientY: null,
  });

  useEffect(() => {
    return addDragSupportToElement(
      ref.current,
      (_, position, isDragging) => {
        setIsDragging(isDragging);
        setMousePosition(position);
      },
      { throttleWait },
    );
  }, [ref, throttleWait]);

  return [isDragging, mousePosition];
};

import * as React from 'react';
import { pick, map } from 'lodash';

import { TokenInput } from 'src/widgets/Input';
import { LocationInput } from 'src/widgets/PlacesInput';
import countries from 'src/common/config/countries';
import languages from 'src/common/config/languages';
import { TGender } from 'src/common/models/searchParams';
import { ILocation } from 'src/pages/ConnectPage/SearchPage/models'

import { FilterSection } from '../FilterSection';
import CreatorAgeSection, { CREATOR_AGE_FILTERS } from './CreatorAgeSection';
import CreatorInterestSection, { CREATOR_INTEREST_FILTERS } from './CreatorInterestSection';
import CreatorGenderSection, { CREATOR_GENDER_FILTERS } from './CreatorGenderSection';

import {
  ICreatorDemographicFilters,
  ICreatorDemographicOptions,
  ILanguageOption,
} from '../models';

const FILTERS = [
  ...CREATOR_AGE_FILTERS,
  ...CREATOR_INTEREST_FILTERS,
  ...CREATOR_GENDER_FILTERS,
  'countries',
  'languages',
  'locations',
];

const pickFilters = (obj: any): ICreatorDemographicFilters => pick(obj, FILTERS);

const DEFAULT_CREATOR_DEMOGRAPHICS_COUNTRY_CHOICES = [
  ...map(countries, (label, code) => ({ label, value: code })),
];

const DEFAULT_CREATOR_DEMOGRAPHICS_LANGUAGE_CHOICES = [
  ...map(languages, (label, code) => ({ label, value: code })),
];

interface IProps extends ICreatorDemographicOptions, ICreatorDemographicFilters {
  onChange(demographics: ICreatorDemographicFilters);
}

const CreatorDemographicsSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeAgeRanges = (ageRanges: string[]) => {
    const newFilters = {
      ...pickFilters(props),
      ageRanges,
    };
    props.onChange(newFilters);
  };

  const handleChangeInterests = (interests: string[]) => {
    const newFilters = {
      ...pickFilters(props),
      interests,
    };
    props.onChange(newFilters);
  };

  const handleChangeGender = (gender: TGender) => {
    const newFilters = {
      ...pickFilters(props),
      gender,
    };
    props.onChange(newFilters);
  };

  const handleChangeLocations = (locations: ILocation[]) => {
    const newFilters = {
      ...pickFilters(props),
      locations,
    };
    props.onChange(newFilters);
  };

  const handleChangeLanguages = (languages: ILanguageOption[]) => {
    const newFilters = {
      ...pickFilters(props),
      languages,
    };
    props.onChange(newFilters);
  };

  const hide =
    props.hide ||
    (props.hideCreatorAge &&
      props.hideCreatorLocations &&
      props.hideCreatorCountries &&
      props.hideCreatorGender &&
      props.hideCreatorLanguages &&
      props.hideCreatorInterests);

  return (
    <FilterSection
      header="Creator Demographics"
      hide={hide}
      collapsible={true}
      defaultIsOpen={props.isExpanded}
    >
      <CreatorAgeSection
        ageRanges={props.ageRanges}
        ageRangeChoices={props.ageRangeChoices}
        onChange={handleChangeAgeRanges}
        hide={props.hideCreatorAge}
      />
      <CreatorInterestSection
        interests={props.interests}
        interestChoices={props.interestChoices}
        onChange={handleChangeInterests}
        hide={props.hideCreatorInterests}
      />

      <CreatorGenderSection
        gender={props.gender}
        onChangeGender={handleChangeGender}
        hide={props.hideCreatorGender}
      />

      <FilterSection header="Locations" isSubsection={true} hide={props.hideCreatorLocations}>
        <LocationInput onChange={handleChangeLocations} locations={props.locations} />
      </FilterSection>

      <FilterSection header="Languages" hide={props.hideCreatorLanguages} isSubsection={true}>
        <TokenInput
          tokens={props.languages}
          placeholder="Enter a language"
          onChange={handleChangeLanguages}
          emptyOptionsMessage="Start typing a language name"
          options={props.languageChoices}
          hideOptionsOnEmptyInput={true}
        />
      </FilterSection>
    </FilterSection>
  );
};

CreatorDemographicsSection.defaultProps = {
  countryChoices: DEFAULT_CREATOR_DEMOGRAPHICS_COUNTRY_CHOICES,
  languageChoices: DEFAULT_CREATOR_DEMOGRAPHICS_LANGUAGE_CHOICES,
  ageRanges: [],
  locations: [],
  countries: [],
  interests: [],
  languages: [],
};

export default CreatorDemographicsSection;

import { defaults } from 'lodash';

/**
 * Fetch options
 */
interface IFetchOptions<T> extends RequestInit {
  /**
   * URL of the fetch request
   */
  url: string;
  /**
   * Default returned value when fetch has failed
   * This will override the fetch error response
   */
  defaultValue?: T;
}

/**
 * Wrapper to Fetch API
 * @param {IFetchOptions} options Fetch options
 */
export const fetchAPI = <T extends any>(options: IFetchOptions<T>) => new Promise<T>(async (resolve, reject) => {
  const {
    url,
    defaultValue = undefined,
    ...restProps
  } = options;

  const fetchParams: RequestInit = defaults(
    restProps,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  try {
    const response = await fetch(url, fetchParams);
    const json = await response.json();

    if (json.status && json.status.code === 200) {
      resolve(json.data);
    } else {
      console.error(json);
      if (defaultValue !== undefined) {
        reject(defaultValue);
      } else if (json.status && json.status.error_msg) {
        reject(json.status.error_msg);
      } else {
        reject(json.data || json);
      }
    }
  } catch (err) {
    console.error(err);
    reject(err);
  }
});

import * as React from 'react';

import { ISelectableCellConfig } from '../tableContext';
import { Cell, ICellProps } from './Cell';

interface IProps extends ICellProps {
  value: ISelectableCellConfig;
  selected: boolean;
  onSelect: () => void;
}

/**
 * @type {React.FunctionComponent}
 */
export const SelectableCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const { value, selected, onSelect, ...restProps } = props;
  const content = value.render(selected, onSelect);
  return <Cell value={content} {...restProps} />;
});

export enum ActionTypes {
  SET_SOCIAL_ACCOUNTS = '@recommendedPage/SET_SOCIAL_ACCOUNTS',
  SET_FAVORITE_LISTS = '@recommendedPage/SET_FAVORITE_LISTS',
  ADD_FAVORITE_LIST = '@recommendedPage/ADD_FAVORITE_LIST',
  UPDATE_FAVORITE_LIST = '@recommendedPage/UPDATE_FAVORITE_LIST',
}

import { IBrand } from 'src/common/models/brand';
import { ICampaign } from 'src/common/models/campaign';
import { IOrganization } from 'src/common/models/organization';
import { ISocialAccount } from 'src/common/models/socialAccount';
import { IFavoriteList } from 'src/common/models/favoriteList';

/**
 * Action.
 */
export interface IRecommendedPageAction {
  type: ActionTypes;
  payload?: {
    id?: number;
    socialAccounts?: ISocialAccount[];
    favoriteList?: IFavoriteList;
    favoriteLists?: IFavoriteList[];
  };
}

/* States */
export interface IRecommendedPage {
  apiEndpoint: string;
  org: IOrganization;
  brand: IBrand;
  campaign: ICampaign;

  // social accounts
  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}

/**
 * Initial state.
 */
export const initialState: IRecommendedPage = {
  apiEndpoint: null,
  org: null,
  brand: null,
  campaign: null,

  socialAccounts: [],
  favoriteLists: [],
};

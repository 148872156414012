import * as React from 'react';
import { merge, each, clone } from 'lodash';

const { useCallback, useEffect, useRef, useState } = React;

interface IOptions {
  rootMargin?: string;
  threshold?: number | number[];
}

interface IItemsVisible {
  [id: string]: boolean;
}

// hard coded for now
const DATA_KEY = 'data-key';

/**
 * Returns the visible status for item list.
 *
 * @return {Boolean}
 */
export const useElementsVisible = (
  itemList: HTMLCollection,
  deps: readonly any[] = [],
  options: IOptions = {},
): IItemsVisible => {
  const [itemsVisible, setItemsVisible] = useState<IItemsVisible>({});
  const prevItemsVisible = useRef(itemsVisible);
  prevItemsVisible.current = itemsVisible;

  /**
   * Callback for IntersectionObserver.
   *
   * @param {IntersectionObserverEntry} entries the intersection observer entries.
   */
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    const newItemsVisible = clone(prevItemsVisible.current);
    each(entries, (entry) => {
      if (entry.isIntersecting) {
        newItemsVisible[entry.target.getAttribute(DATA_KEY)] = true;
      } else {
        newItemsVisible[entry.target.getAttribute(DATA_KEY)] = false;
      }
    });

    setItemsVisible(newItemsVisible);
  }, []);

  useEffect(() => {
    if (!itemList || typeof IntersectionObserver === 'undefined') {
      return;
    }

    // it's better to just use document(root: null) as root
    const observer = new IntersectionObserver(
      intersectionCallback,
      merge(
        {
          root: null,
          rootMargin: '0px',
          threshold: [0],
        },
        options,
      ),
    );

    // observes each item
    for (let i = 0; i < itemList.length; i++) {
      observer.observe(itemList.item(i));
    }

    return () => {
      observer.disconnect();
    };
  }, [itemList, intersectionCallback, options, ...deps]); // eslint-disable-line

  return itemsVisible;
};

import * as React from 'react';

import { SvgIcon, ISvgIconProps } from 'src/widgets/Icon';

const GroupIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g>
      <g>
        <g>
          <ellipse cx="12" cy="8.9" rx="2.69" ry="2.87" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M16.31,17.96c-0.67,0-2.66,0-4.31,0c-1.98,0-3.64,0-4.31,0c0-1.97,1.25-4.58,4.31-4.58
            S16.22,15.87,16.31,17.96z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M22,16.55c-0.87-0.01-2.9,0-2.9,0c0-0.63-0.67-1.86-0.95-2.21c-0.28-0.35,0.16-0.6,1.12-0.6
            C20.78,13.73,21.92,14.73,22,16.55z"
          />
        </g>
      </g>
      <g>
        <g>
          <ellipse cx="19.27" cy="10.01" rx="1.92" ry="1.93" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M2,16.55c0.87-0.01,2.9,0,2.9,0c0-0.63,0.67-1.86,0.95-2.21c0.28-0.35-0.16-0.6-1.12-0.6
            C3.22,13.73,2.08,14.73,2,16.55z"
          />
        </g>
      </g>
      <g>
        <g>
          <ellipse cx="4.73" cy="10.01" rx="1.92" ry="1.93" />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export { GroupIcon };

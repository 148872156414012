import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ISavedSearch } from 'src/common/models/searchParams';
import { IConnectSearchPage, ISavedSearches } from '../models';
import {
  fetchSavedSearches,
  saveSearch,
  selectSavedSearch,
  deleteSearch,
} from '../redux/savedSearchesActions';
import { SavedSearches } from '../SavedSearches';

const { useEffect } = React;

interface IDispatchProps {
  onSaveNewSearch(searchName: string);
  onUpdateSearch(savedSearch: ISavedSearch);
  onSelectSavedSearch(savedSearch: ISavedSearch);
  onDeleteSavedSearch(savedSearch: ISavedSearch);
  onFetchSavedSearches();
}

const mapStateToProps = (state: IConnectSearchPage): ISavedSearches => ({
  ...state.savedSearches,
});

const mapDispatchToProps = (dispatch): IDispatchProps => {
  const boundActions = bindActionCreators(
    {
      onFetchSavedSearches: fetchSavedSearches,
      onSelectSavedSearch: selectSavedSearch,
      onDeleteSavedSearch: deleteSearch,
    },
    dispatch,
  );
  return {
    ...boundActions,
    onSaveNewSearch: (searchName: string) => dispatch(saveSearch(null, searchName)),
    onUpdateSearch: (savedSearch: ISavedSearch) =>
      dispatch(saveSearch(savedSearch.id, savedSearch.name)),
  };
};

const SavedSearchesWrapper = (props) => {
  const { onFetchSavedSearches } = props;
  useEffect(() => {
    onFetchSavedSearches();
  }, [onFetchSavedSearches]);
  return <SavedSearches {...props} />;
};

export const ConnectedSavedSearches = connect<ISavedSearches, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SavedSearchesWrapper);

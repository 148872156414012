import { ITableColumnConfig } from 'src/widgets/Table';

export enum GroupBySetting {
  post = 'post',
  creator = 'creator',
  network = 'network',
  aspirexPost = 'aspirex_post',
  aspirexNetwork = 'aspirex_network',
  member = 'aspirex_member',
}

const allColumns: Array<ITableColumnConfig & {
  valid_for_grouping_by?: GroupBySetting[];
}> = [
  {
    headerName: 'Creator',
    cellType: 'text',
    field: 'creator',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.creator],
  },
  {
    headerName: 'Social Account',
    cellType: 'text',
    field: 'social_account_name',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.member,
      GroupBySetting.aspirexPost,
    ],
  },
  // Commenting out until we get the data from the API
  /* {
    headerName: 'Social Accounts',
    cellType: 'text',
    field: 'social_account_names',
    valid_for_grouping_by: [GroupBySetting.creator],
  }, */
  // Commenting out GroupBySetting.creator grouping until we get the data from the API
  {
    headerName: 'Creator Country',
    cellType: 'text',
    field: 'country_code',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Brand',
    cellType: 'text',
    field: 'brand_name',
    valid_for_grouping_by: [GroupBySetting.post],
  },
  // Commenting out until we get the data from the API
  /* {
    headerName: 'Brands',
    cellType: 'text',
    field: 'brands',
    valid_for_grouping_by: [GroupBySetting.creator],
  }, */
  {
    headerName: 'Campaign',
    cellType: 'text',
    field: 'campaign_name',
    valid_for_grouping_by: [GroupBySetting.post],
  },
  // Commenting out until we get the data from the API
  /* {
    headerName: 'Campaigns',
    cellType: 'text',
    field: 'campaigns',
    valid_for_grouping_by: [GroupBySetting.creator],
  }, */
  {
    headerName: 'Network',
    cellType: 'text',
    field: 'network',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Network',
    cellType: 'text',
    field: 'post_type',
    valid_for_grouping_by: [
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
    ],
  },
  {
    headerName: 'Networks',
    cellType: 'text',
    field: 'networks',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.member],
  },
  {
    headerName: 'Post Type',
    cellType: 'text',
    field: 'post_type',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Post',
    cellType: 'media',
    field: 'media',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Creators',
    cellType: 'numeric',
    field: 'creator_count',
    valid_for_grouping_by: [
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
    ],
  },
  {
    headerName: 'Engagements',
    cellType: 'numeric',
    field: 'engagements',
    justify: 'flex-start',
  },
  {
    headerName: 'Engagement %',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'engagement_pct',
    justify: 'flex-start',
  },
  {
    headerName: 'Reach',
    cellType: 'numeric',
    field: 'reach',
    justify: 'flex-start',
  },
  {
    headerName: 'Impressions',
    cellType: 'numeric',
    field: 'impressions',
    justify: 'flex-start',
  },
  {
    headerName: 'Unique Impressions',
    cellType: 'numeric',
    field: 'unique_impressions',
    justify: 'flex-start',
  },
  {
    headerName: 'Unique Engagement %',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'unique_engagement_rate',
    justify: 'flex-start',
  },
  {
    headerName: 'Views',
    cellType: 'numeric',
    field: 'views',
    justify: 'flex-start',
  },
  {
    headerName: 'Likes',
    field: 'likes',
    cellType: 'numeric',
    justify: 'flex-start',
  },
  {
    headerName: 'Comments',
    cellType: 'numeric',
    field: 'comments',
    justify: 'flex-start',
  },
  {
    headerName: 'Taps',
    field: 'taps',
    cellType: 'numeric',
    justify: 'flex-start',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
  },
  {
    headerName: 'Exits',
    field: 'exits',
    cellType: 'numeric',
    justify: 'flex-start',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
  },
  {
    headerName: 'Clicks',
    cellType: 'numeric',
    field: 'clicks',
    justify: 'flex-start',
  },
  {
    headerName: 'Saves',
    cellType: 'numeric',
    field: 'favorites',
    justify: 'flex-start',
  },
  {
    headerName: 'Closeups',
    cellType: 'numeric',
    field: 'closeups',
    justify: 'flex-start',
  },
  {
    headerName: 'TMV',
    cellType: 'numeric',
    field: 'tmv',
    justify: 'flex-start',
  },
  {
    headerName: 'ROI %',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'roi_pct',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'CPE $',
    cellType: 'numeric',
    field: 'cost_per_engagement',
    isPrice: true,
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Impression CPM $',
    cellType: 'numeric',
    field: 'impression_cpm',
    isPrice: true,
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Post Count',
    cellType: 'numeric',
    field: 'post_count',
    valid_for_grouping_by: [
      GroupBySetting.creator,
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
      GroupBySetting.member,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Coupon Codes',
    cellType: 'text',
    field: 'coupons',
    valid_for_grouping_by: [GroupBySetting.post],
    justify: 'flex-start',
  },
  {
    headerName: 'Sales Count',
    cellType: 'numeric',
    field: 'sales',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Sales $',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'sales_amount',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Payment',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'cost',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Product Cost',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'product_cost',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Date Posted',
    cellType: 'text',
    field: 'datetime_posted',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
    justify: 'flex-start',
  },
  {
    headerName: 'Last Posted',
    cellType: 'text',
    field: 'last_posted',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.member],
    justify: 'flex-start',
  },
  {
    headerName: 'Owners',
    cellType: 'text',
    field: 'owners',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.post],
    justify: 'flex-start',
  },
  {
    headerName: 'Caption',
    cellType: 'text',
    field: 'text',
    justify: 'flex-start',
  },
];

/**
 * Returns the columns filtered by the group by type.
 *
 * @param {BreakdownGroupBySetting} groupBy string of the group by type. Must be BreakdownGroupBySetting
 *
 * @return {ITableColumnConfig[]}
 */
export const getColumns = (groupBy: GroupBySetting): ITableColumnConfig[] => {
  return allColumns.filter(column => {
    return (
      !column.valid_for_grouping_by ||
      column.valid_for_grouping_by.includes(groupBy)
    );
  });
};

import * as React from 'react';
import cx from 'classnames';
import { map, mapValues, filter } from 'lodash';

import { SearchIcon } from 'src/icons';
import { Checkbox } from 'src/widgets/Checkbox';
import { Input } from 'src/widgets/Input';
import { Popover } from 'src/widgets/Popover';

import styles from '../Filters.scss';
const { useRef, useState } = React;

export const CountryFilter = ({ filters, setFilters, removeFilter, isDisabled }) => {
  const [open, setOpen] = useState(true);
  const [textValue, setTextValue] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const { countries } = filters.countries;

  // eslint disable because prettier wants to format it this way.

  /*eslint-disable */
  const toDisplay =
    textValue === ''
      ? countries
      : filter(countries, country =>
          country.value.toLowerCase().includes(textValue.toLowerCase())
        );
  /*eslint-enable */

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {filters.countries.selected === 0 ? '' : filters.countries.selected}
        </span>
        <span className={styles.filterText}>Countries</span>
        <span
          className={cx(styles.filterCircle, styles.right)}
          onClick={() => !isDisabled && removeFilter('country')}
        >
          ×
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Input
          type="text"
          value={textValue}
          icon={<SearchIcon />}
          placeholder="Search..."
          buffer={300}
          onChange={setTextValue}
          className={styles.input}
        />
        <ul className={styles.MenuList}>
          <li
            className={styles.MenuItem}
            onClick={() => setFilters({ type: 'CLEAR_ALL_COUNTRIES' })}
          >
            <div>Clear All</div>
          </li>
          {map(toDisplay, (country, key) => (
            <li
              className={styles.MenuItem}
              key={key}
              onClick={() =>
                setFilters({ type: 'CLICK_COUNTRY', payload: country })
              }
            >
              <Checkbox
                checked={country.selected}
                onClick={() =>
                  setFilters({ type: 'CLICK_COUNTRY', payload: country })
                }
              />
              {country.value}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
};

export const countryReducer = (state, action) => {
  const localState = state.countries;
  switch (action.type) {
    case 'REMOVE_ADDITIONAL_FILTER': {
      if (action.payload === 'country') {
        return {
          countries: mapValues(localState.countries, country => ({
            ...country,
            selected: false,
          })),
          selected: 0,
        };
      }
      return localState;
    }
    case 'CLEAR_ALL_COUNTRIES': {
      return {
        countries: mapValues(localState.countries, country => ({
          ...country,
          selected: false,
        })),
        selected: 0,
      };
    }
    case 'CLICK_COUNTRY': {
      const newCountries = {
        ...localState.countries,
        [action.payload.code]: {
          ...action.payload,
          selected: !action.payload.selected,
        },
      };

      return {
        countries: newCountries,
        selected: action.payload.selected
          ? localState.selected - 1
          : localState.selected + 1,
      };
    }
    default:
      return localState;
  }
};

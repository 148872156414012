import * as React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { filter, isFunction, isString, isUndefined, map } from 'lodash';

import { ArrowDownFilledIcon } from 'src/icons';
import { Button } from 'src/widgets/Button';
import { IPopoverProps, Popover } from 'src/widgets/Popover/Popover';

import styles from './Select.scss';

type TOptionValue = any;
export interface IOption {
  label: string | JSX.Element;
  value: TOptionValue;
  actions?: React.ReactChild[];
  showActions?: boolean;
  optionClass?: string;
  onSelect?();
}
type TTheme = 'light' | 'info';
export interface ISelectProps {
  className?: string;
  options: IOption[];
  onChange(value: TOptionValue, index: number);

  selectedIndex?: number; // Controlled
  defaultSelectedIndex?: number; // Initial selected index; not necessarily controlled
  hintText?: JSX.Element | string;
  hideOptions?: boolean;
  customLabelElement?: JSX.Element;
  labelIcon?: JSX.Element;
  renderOptionElement?: (option: IOption, index: number) => any;
  onMenuOpen?();
  onMenuClose?();
  theme?: TTheme;
  round?: boolean;
  disabled?: boolean;
  anchorLocation?: 'button' | 'arrow';
  popoverProps?: Partial<IPopoverProps>;
  buttonClassName?: string;
}

type TDefaultProp =
  | 'hintText'
  | 'hideOptions'
  | 'selectedIndex'
  | 'defaultSelectedIndex'
  | 'customLabelElement'
  | 'onMenuOpen'
  | 'onMenuClose'
  | 'theme'
  | 'disabled'
  | 'popoverProps'
  | 'renderOptionElement'
  | 'round'
  | 'anchorLocation';

interface IState {
  showMenu: boolean;
  selectedIndex: number;
  hoveredIndex: number;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Select extends React.Component<ISelectProps, IState> {
  public static defaultProps: Pick<ISelectProps, TDefaultProp> = {
    hintText: 'Select...',
    hideOptions: false,
    selectedIndex: undefined,
    defaultSelectedIndex: null,
    customLabelElement: null,
    renderOptionElement: (option) => option.label,
    onMenuOpen: () => undefined,
    onMenuClose: () => undefined,
    theme: 'light',
    disabled: false,
    round: false,
    anchorLocation: 'arrow',
    popoverProps: {
      minWidth: 300,
      showArrow: true,
    },
  };

  private popoverAnchorRef: React.RefObject<HTMLDivElement>;
  private controlled: boolean;

  /**
   * @inheritDoc
   */
  constructor(props: ISelectProps) {
    super(props);

    this.popoverAnchorRef = React.createRef();

    this.controlled = !isUndefined(props.selectedIndex);

    this.state = {
      showMenu: false,
      selectedIndex: props.defaultSelectedIndex,
      hoveredIndex: null,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      anchorLocation,
      children,
      customLabelElement,
      disabled,
      hideOptions,
      hintText,
      labelIcon,
      options,
      popoverProps,
      renderOptionElement,
      round,
      theme,
      buttonClassName,
    } = this.props;
    const { showMenu } = this.state;
    const selectedIndex = this.getSelectedIndex();
    const selectedOption = options[selectedIndex];
    const labelText = selectedOption ? selectedOption.label : hintText;

    return (
      <div className={cx(styles.Select, this.props.className)}>
        {!customLabelElement && (
          <div
            onClick={this.toggleMenu}
            className={cx(styles.button, styles[theme], {
              [styles.active]: showMenu,
              [styles.round]: round,
            }, buttonClassName)}
            ref={anchorLocation === 'button' ? this.popoverAnchorRef : null}
          >
            <div className={styles.label}>
              {labelIcon ? <div className={styles.icon}>{labelIcon}</div> : null}
              <div className={cx(styles.text, { [styles.jsxLabel]: !isString(labelText) })}>
                {labelText}
              </div>
            </div>
            <div
              className={styles.arrow}
              ref={anchorLocation === 'arrow' ? this.popoverAnchorRef : null}
            >
              <ArrowDownFilledIcon size={10} />
            </div>
          </div>
        )}
        {customLabelElement && (
          <div
            className={styles.customLabel}
            onClick={this.toggleMenu}
            ref={this.popoverAnchorRef}
          >
            {customLabelElement}
          </div>
        )}
        {disabled && <div className={styles.mask} />}
        <Popover
          {...popoverProps}
          className={cx(styles.Popover, popoverProps.className)}
          mountRef={popoverProps.mountRef || this.popoverAnchorRef}
          show={showMenu}
          onRequestClose={this.toggleMenu}
        >
          {!hideOptions && (
            <div className={styles.list}>
              {map(options, (option, index) => (
                <div
                  key={index}
                  className={cx(styles.option, option.optionClass, {
                    [styles.active]: selectedIndex === index,
                  })}
                  onClick={this.handleOptionClick.bind(this, index)}
                  onMouseEnter={this.setHoveredIndex.bind(this, index)}
                  onMouseLeave={this.unsetHoveredIndex}
                >
                  <div className={styles.label}>{renderOptionElement(option, index)}</div>
                  {this.renderActions(option, index)}
                </div>
              ))}
            </div>
          )}
          {children}
        </Popover>
      </div>
    );
  }

  private renderActions(option: IOption, index: number) {
    const { hoveredIndex } = this.state;
    return (
      <div className={styles.actions}>
        <CSSTransition
          in={option.showActions || hoveredIndex === index}
          classNames={{
            enter: styles['actions-enter'],
            enterActive: styles['actions-enter-active'],
            exit: styles['actions-exit'],
            exitActive: styles['actions-exit-active'],
          }}
          timeout={150} // $transition-duration-quick: 0.15s
          unmountOnExit
        >
          <>
            {map(
              filter(option.actions, (action) => action),
              (option, index) => (
                typeof option === 'string' || typeof option === 'number'
                  ? (
                    <Button
                      key={index}
                      className={styles.actionItem}
                      label={option}
                      theme='light'
                      onClick={this.onActionClick}
                      round
                    />
                  )
                  : option
              ),
            )}
          </>
        </CSSTransition>
      </div>
    )
  }

  /**
   * @private
   * Returns the selected index based on whether component is controlled or not.
   *
   * @return {Boolean}
   */
  private getSelectedIndex = () => {
    return this.controlled ? this.props.selectedIndex : this.state.selectedIndex;
  };

  public resetSelectedIndex = () => {
    this.setState({
      selectedIndex: null,
    });
  }

  /**
   * @private
   * Toggles the menu.
   */
  private toggleMenu = () => {
    const { onMenuClose, onMenuOpen } = this.props;
    const { showMenu } = this.state;

    // notifies parent if it's closing menu
    if (showMenu) {
      onMenuClose();
    } else {
      onMenuOpen();
    }

    this.setState({
      showMenu: !showMenu,
    });
  };

  /**
   * @private
   * Select an option and notifies parent.
   *
   * @param {Number} index the selected index.
   */
  private handleOptionClick = (index: number) => {
    const { options } = this.props;
    const selectedOption = options[index];

    // Proceed with selection if `onSelect` isn't provided
    // Or if it is and its return value is truthy
    if (
      !isFunction(selectedOption.onSelect) ||
      isFunction(selectedOption.onSelect) && selectedOption.onSelect()
    ) {
      this.selectOptionAtIndex(index);
    }
  };

  public selectOptionAtIndex = (index: number) => {
    const selectedIndex = this.getSelectedIndex();
    const { options, onChange } = this.props;
    const selectedOption = options[index];

    if (selectedIndex !== index) {
      onChange(selectedOption.value, index);
    }
    this.setState({
      selectedIndex: index,
      showMenu: false,
    });
  }

  /**
   * @private
   * Stop event propagation when action buttons are clicked.
   *
   * @param {React.MouseEvent<HTMLDivElement>} event the event object.
   */
  private onActionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * @private
   * Sets the hovered index.
   *
   * @param {Number} hoveredIndex the hovered option index.
   */
  private setHoveredIndex = (hoveredIndex: number) => {
    this.setState({
      hoveredIndex,
    });
  };

  /**
   * @private
   */
  private unsetHoveredIndex = () => {
    this.setState({
      hoveredIndex: null,
    });
  };
}

import * as React from 'react';

import { LazyImage } from 'src/widgets/Image';

import { Content, IContentProps } from './Content';

import styles from './ImageContent.scss';

import { getThumbnailMedia } from '../utils/getContentImage';

/**
 * @class
 * @extends {React.Component}
 */
export class ImageContent extends React.Component<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames, ...props } = this.props;

    return (
      <Content content={content} classNames={classNames.concat(styles.ImageContent)} {...props}>
        <LazyImage className={styles.image} src={getThumbnailMedia(content)} key={content.src} />
      </Content>
    );
  }
}

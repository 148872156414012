import * as React from 'react';
import cx from 'classnames';

import { Button } from 'src/widgets/Button';
import { FileSelector } from 'src/widgets/ContentUploader';

interface IProps {
  uploadCsv(file: File);

  classNames?: string[];
}
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class CsvSelector extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    return (
      <div className={cx(classNames)}>
        <FileSelector
          accept='text/csv'
          onFilesSelected={this.handleFilesSelected}
          unstyled
        >
          <Button label="Import CSV" theme="info" round={true} />
        </FileSelector>
      </div>
    );
  }

  /**
   * @private
   * Invokes callback with the first uploaded file from filelist.
   */
  private handleFilesSelected = (filelist: FileList) => {
    const { uploadCsv } = this.props;
    const file = filelist.item(0);

    uploadCsv(file);
  };
}

import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { NetworkIcon } from 'src/common';
import { LazyImage } from 'src/widgets/Image';
import { Notice } from 'src/widgets/Notice';
import { LoadSpinner } from 'src/widgets/LoadSpinner';

import endpoints from 'src/common/config/endpoints';
import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

import { useAnalyze } from '../../../useAnalyze';
import { useFetchSummaryData } from '../../../useFetchAnalyzeData';
import ShouldSectionShow from '../ShouldSectionShow';

import styles from './TopCreatorsSection.scss';

export interface ITopCreatorsData {
  username: string;
  network: TNetworkIdentifier;
  link: string;
  profile_image_url: string;
  rating: number;
  engagements: number;
  engagement_rate: number;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const TopCreatorsSection: React.FunctionComponent<IProps> = React.memo(
  props => {
    const { apiEndpoint, filters, isAspirex } = useAnalyze();
    const { loading, data, error } = useFetchSummaryData<ITopCreatorsData[]>(
      `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/top-creators`,
      isAspirex,
      filters
    );
    if (error) {
      return (
        <Notice className={styles.notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const percentify = (value: number) => {
      return ((value * 100).toFixed(2))
    };
    const dataReturnedEmpty = data && data.length === 0;
    return (
      <div className={cx(styles.TopCreatorsSection, props.className)}>
        {loading && <LoadSpinner centered={true} />}
        <ShouldSectionShow
          loading={loading}
          data={data}
          dataReturnedEmpty={dataReturnedEmpty}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>Top Creator{data && data.length > 1 ? 's' : ''}</h3>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.container}>
                {map(data, (record) => (
                  <div key={record.link} className={styles.item}>
                    <a
                      className={styles.imageSection}
                      href={record.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyImage
                        className={styles.image}
                        src={record.profile_image_url}
                      />
                      <div className={styles.network}>
                        <NetworkIcon
                          className={styles.networkIcon}
                          identifier={record.network}
                          size={20}
                        />
                      </div>
                    </a>
                    <div className={styles.info}>
                      <div className={styles.infoItem}>
                        <a
                          className={styles.username}
                          href={record.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {record.username}
                        </a>
                      </div>
                      {/* TODO: PC-741: Uncomment when rating becomes ready on the back end}
                      {/* {record.rating && (
                        <div className={styles.infoItem}>
                          {record.rating}
                          <StarIcon className={styles.ratingIcon} size={18} />
                        </div>
                      )} */}
                      <div className={styles.infoItem}>
                        <div className={styles.type}>Engmt Rate</div>
                        <div className={styles.count}>{percentify(record.engagement_rate)}%</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ShouldSectionShow>
      </div>
    );
  }
);

TopCreatorsSection.defaultProps = {
  className: null,
};

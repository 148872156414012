import { assign } from 'lodash';
import { ActionTypes, IWatchListPage, IWatchListPageAction } from './models';

const reducer = (state: IWatchListPage, action: IWatchListPageAction): IWatchListPage => {
  switch (action.type) {
    case ActionTypes.SET_SOCIAL_ACCOUNTS: {
      const { socialAccounts, count = 0 } = action.payload;

      return assign({}, state, {
        count,
        socialAccounts,
      });
    }

    case ActionTypes.SET_WATCH_LISTS: {
      const { watchlists } = action.payload;

      return assign({}, state, {
        watchlists,
      });
    }

    case ActionTypes.SET_SELECTED_LIST_ID: {
      const { selectedListId } = action.payload;

      return assign({}, state, {
        selectedListId,
      });
    }

    default:
      return state;
  }
};

export default reducer;

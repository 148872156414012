import * as React from 'react';
import cx from 'classnames';

import { SpinnerIcon } from 'src/icons';
import { IconSize } from '../Icon';

import styles from './LoadSpinner.scss';

interface ILoadSpinnerProps {
  className?: string;
  centered?: boolean;
  boxed?: boolean;
  type?: 'classic' | 'spinner';
}

/**
 * @type {React.FunctionComponent}
 */
export const LoadSpinner: React.FunctionComponent<ILoadSpinnerProps> = React.memo((props) => {
  const {
    className,
    centered = true,
    boxed = false,
    type = 'classic',
  } = props;

  const spinnerElem = (() => {
    switch (type) {
      case 'classic':
        return <div className={styles.img} />;
      case 'spinner':
        return (
          <SpinnerIcon
            className={styles.spinnerIcon}
            size={IconSize.MEDIUM}
          />
        );
    }
  })();

  return (
    <div className={cx(styles.LoadSpinner, className, {
      [styles.centered]: centered,
      [styles.boxed]: boxed,
    })}>
      {spinnerElem}
    </div>
  );
});

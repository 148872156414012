const endpoints = {
  // Create
  advertiserAccessEndpoint: 'advertiser_access',
  campaignEndpoint: 'campaign',
  campaignInviteEndpoint: 'campaign_invite',
  clientContentReviewEndpoint: 'client_content_review',
  contentListEndpoint: 'content_list',
  contentReviewEndpoint: 'content_review',
  creatorFavoritesListEndpoint: 'creator_favorites_list',
  favoritesListElementEndpoint: 'favorites_list_element',
  licensedContentEndpoint: 'licensed_content',
  mentionedListOauthEndpoint: 'facebook/oauth',
  postProjectEndpoint: 'post_project',
  projectEndpoint: 'project',
  proposalEndpoint: 'client_proposal',
  proposalInviteEndpoint: 'client_proposal_invite',
  publisherEndpoint: 'publisher',
  reportsEndpoint: 'reports',
  savedSearchParams: 'saved_search_params',
  shopifyDiscountCoupon: 'discount_coupon',
  socialAccountEndpoint: 'social_account',
  socialAccountImageSearchEndpoint: 'social_account_image_search',
  socialAccountSearchEndpoint: 'social_account_search',
  socialListeningWatchList: 'social_listening_watch_list',
  uploadFileEndpoint: 'upload_file',

  // Elevate
  communitiesEndpoint: 'communities',
  communityProgramsEndpoint: 'community_programs',
  members: 'members',
  programs: 'programs',
  resourcesEndpoint: 'resources',
};

export default endpoints;

import * as React from 'react';
import { isNil } from 'lodash';

import { Input } from './Input';

const { useCallback, useState } = React;

export interface IProps {
  defaultValue: string;
  onBlur?(value: string);
}

export const StringEditInput: React.FC<IProps> = (props) => {
  const { onBlur, defaultValue, ...restProps } = props;
  const [value, setValue] = useState<string>(isNil(defaultValue) ? '' : defaultValue);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, [setValue]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur(value);
    }
  }, [onBlur, value]);

  return (
    <Input {...restProps} value={value} onBlur={handleBlur} onChange={handleChange} />
  );
};

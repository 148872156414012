import { TNetworkIdentifier } from 'src/common/models/networkIdentifier';

/**
 * Generates a link to the social account
 * @param network Social network
 * @param username Username
 */
export const getSocialAccountLink = (network: TNetworkIdentifier, username: string) => {
  if (!username) {
    return undefined;
  }
  switch (network) {
    case 'instagram':
      return `https://instagram.com/${username}`;
    case 'youtube':
      return `https://youtube.com/user/${username}`;
    case 'facebook':
      return `https://facebook.com/${username}`;
    case 'pinterest':
      return `https://pinterest.com/${username}`;
    case 'twitter':
      return `https://twitter.com/${username}`;
    default:
      return username;
  }
};
